import { ArrowLeftOutlined,
  CheckOutlined,
  CloseOutlined,
  InfoCircleOutlined,
  OrderedListOutlined } from '@ant-design/icons';
import { Button, Card, notification, Space } from 'antd';
import _ from 'lodash';
import React from 'react';
import nextId from 'react-id-generator';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { useTypedSelector } from '../../../store';
import { impExternalReset, impExternalStepUpdated } from '../../../store/impExternal';
import { interestDataChanged, paymentDataChanged } from '../../../store/interestStorage';
import { interestDataProviderUpdated,
  MANUAL_PAYMENTS_ASSIGNMENTS,
  shouldAutoAssignUpdated } from '../../../store/lawsuitUI';
import { RowExt, RowMd } from '../../../styles';
import { resetLawsuit } from '../../../utils/helpers/resetLawsuit';
import useImpExternalValidate from '../../../utils/hooks/useImpExternalValidate';
import ImpCard from '../ImpCard';
import { IMP_EXTERNAL } from '../ImpCommonData/impCommonData';
import ImpStats from '../ImpCommonData/ImpSumUp/ImpStats';
import ImpSumUpOpts from '../ImpCommonData/ImpSumUp/ImpSumUpOpts';
import ImpStatsMoney from '../ImportOcr/ImpOrcSumUp/ImpStatsMoney';
import ImpExternalItemIntr, { ImpExternalFatalIntr } from './ImpExternaltemIntr';
import ImpExternalItemPay, { ImpExternalFatalPay } from './ImpExternaltemPay';


const ImpExternalSumUp = () => {
  const {
    interestData,
    paymentData,
    paymentDataWarnings,
    interestDataWarnings,
    sumOfPaymentData,
    sumOfInterestData
  } = useImpExternalValidate();

  const history = useHistory();

  const dispatch = useDispatch();

  const shouldAutoAssign = useTypedSelector(state =>
    state.impOcr.shouldAutoAssign);

  const onFinish = () => {
    if ((interestData.length + paymentData.length) > 0){
      resetLawsuit(dispatch);
      dispatch(interestDataChanged(interestData));
      dispatch(paymentDataChanged(paymentData));
      if (!_.isEmpty(paymentData)) dispatch(interestDataProviderUpdated(MANUAL_PAYMENTS_ASSIGNMENTS));
      if (!_.isEmpty(paymentData) && shouldAutoAssign) dispatch(shouldAutoAssignUpdated(true));
      notification.info({
        duration: 6,
        message: 'Dane zaimportowane',
        description: 'Ustaw pozostałe parametry aby uzyskać tekst'
      });
      dispatch(impExternalReset());
      history.push('/lawsuit');
    }
  };
  const onBack = () => {
    dispatch(impExternalStepUpdated(1));
  };

  const onReset = () => {
    dispatch(impExternalReset());
    history.push('/dashboard');
  };

  const fatalInterestData = interestDataWarnings.filter(value =>
    value.fatalErrorItem);

  const fatalPaymentData = paymentDataWarnings.filter(value =>
    value.fatalErrorItem);

  return (
    <>
      <ImpCard
        title="Podsumowanie importu"
        icon={<InfoCircleOutlined />}
        desc="Zobacz podsumowanie danych i ich ewentualnych modyfikacji. Jeśli chcesz coś zmienić możesz się jeszcze cofnąć."
      >
        <RowExt
          justify="space-between"
          align='middle'
          marginBottom={24}
        >
          <Button
            type="dashed"
            icon={<ArrowLeftOutlined />}
            onClick={onBack}
          />
          <Space>
            {_.isEmpty(interestData) ? (
              <Button
                type='dashed'
                icon={<CloseOutlined />}
                onClick={onReset}
              >
                Anuluj
              </Button>
            )
              : null}
            <Button
              type="primary"
              icon={<CheckOutlined />}
              onClick={onFinish}
              disabled={(interestData.length) <= 0 && paymentData.length <= 0}
            >
              Zacznij obliczenia i tworzenie tesktu
            </Button>
          </Space>
        </RowExt>
        <ImpStats
          warningData={[...paymentDataWarnings, ...interestDataWarnings]}
          numberOfImports={interestData.length + paymentData.length}
        />
        <RowMd />
        <Card
          title="Lista importowanych danych"
          bordered={false}
          extra={(
            <OrderedListOutlined />
)}
        >
          {interestData.map((interestItem, index) =>
            (
              <ImpExternalItemIntr
                key={nextId()}
                {...{
                  interestItem,
                  index,
                  interestDataWarnings,
                }}
              />
            ))}
          {fatalInterestData.map((value, index) =>
            (
              <ImpExternalFatalIntr
                key={nextId()}
                {...{
                  warnings: value.warnings,
                  fatalErrorItem: value.fatalErrorItem,
                  index
                }}
              />
            ))}
          {paymentData.map((paymentItem, index) =>
            (
              <ImpExternalItemPay
                key={nextId()}
                {...{
                  paymentItem,
                  index,
                  paymentDataWarnings,
                }}
              />
            ))}
          {fatalPaymentData.map((value, index) =>
            (
              <ImpExternalFatalPay
                key={nextId()}
                {...{
                  warnings: value.warnings,
                  fatalErrorItem: value.fatalErrorItem,
                  index
                }}
              />
            ))}
          <ImpStatsMoney
            {...{
              sumOfInterestData,
              sumOfPaymentData,
              numberOfImports: interestData.length + paymentData.length,
            }}
          />
        </Card>
        <ImpSumUpOpts
          impType={IMP_EXTERNAL}
        />
      </ImpCard>
    </>
  );
};

export default ImpExternalSumUp;
