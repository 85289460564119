import moment, { Moment } from 'moment';
import { useMemo } from 'react';

const usePartialSumHelp = ({
  startingDate,
  dueDate,
  endDate,
}: {
  startingDate: Moment | null;
  dueDate: Moment | null;
  endDate: Moment | null;
}) => {
  const help = useMemo(() => {
    if (moment(startingDate).isSameOrBefore(moment(dueDate).toDate()))
      return 'Data początkowa jest wcześniejsza niż termin spełnienia świadczenia';
    if (moment(startingDate).isSameOrAfter(moment(endDate).toDate()))
      return 'Data końcowa jest wcześniejsza lub równa początkowej';
    return '';
  }, [endDate, dueDate, startingDate]);

  return help;
};

export default usePartialSumHelp;
