import { Col, Row } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { AboutContainer, DashedColLeft } from '../../styles';

const SubSpaceRow = () => {
  return (
    <AboutContainer>
      <SpaceRow>
        <Col
          xl={23}
          xs={0}
        />
        <DashedColLeft
          xl={1}
          xs={0}
        />
      </SpaceRow>
    </AboutContainer>
  );
};

export default SubSpaceRow;

const SpaceRow = styled(Row)`
    height: 48px;
`;
