import { LoginOutlined } from '@ant-design/icons';
import { Button, Divider, Space } from 'antd';
import React from 'react';
import { useHistory } from 'react-router';
import { useAppDispatch, useTypedSelector } from '../../store';
import { saveModalClosed } from '../../store/saveLawsuitModal';


const NoUserSave = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const userExists = useTypedSelector(state =>
    state.user.isNonAnonymousUserLoggedIn);
  const  isSaveFromResult  = useTypedSelector(state =>
    state.saveLawsuitModal.isSaveFromResult);
  const returnTo = isSaveFromResult ? '/resultLsLocal' : '/lawsuit';
  const onLogin = () => {
    dispatch(saveModalClosed());
    history.push('/login', { returnTo });
  };

  const onSignUp = () => {
    dispatch(saveModalClosed());
    history.push('/signUp', { returnTo });
  };

  if (!userExists){
    return (
      <Space>
        <Button
          type="primary"
          icon={<LoginOutlined />}
          onClick={onLogin}
        >
          Zaloguj się
        </Button>
        <Divider type="vertical" />
        <Button
          type="link"
          onClick={onSignUp}
        >
          Utwórz konto
        </Button>
      </Space>
    );
  }
  return null;
};

export default NoUserSave;
