import { EditOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button, Col } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { AppDispatch, RootState } from '../../../store';
import { editReset } from '../../../store/edit';
import { interestDataChanged } from '../../../store/interestStorage';
import { GRID_MD, GRID_SM, GRID_SM_PX, RowExt } from '../../../styles';
import { displayEditInfo } from '../../../utils/helpers/displayFormInfo';
import useWindowSize from '../../../utils/hooks/useWindowSize';


const changeInterestItem = () =>
  (dispatch:AppDispatch, getState: () => RootState) => {
    const state = getState();
    const {
      legalBasis,
      factualBasis,
      accountingDoc,
      startingDate,
      endDate,
      partialSum,
      maturityDate,
      dueDate,
    } = state.edit;
    const editKey = state.edit.key;
    const { compensationOn } = state.lawsuitOpts;
    const { interestData } = state.interestStorage;

    if (startingDate
    && endDate
    && partialSum
    && dueDate
    && (compensationOn ? maturityDate : true) ){
      const interestDataWithNewItem  = interestData.map(interestItem =>
        interestItem.key === editKey ? {
          legalBasis,
          factualBasis,
          accountingDoc,
          startingDate,
          endDate,
          partialSum,
          maturityDate,
          dueDate,
          key: interestItem.key,
          isClaimFromFulfilled: interestItem.isClaimFromFulfilled,
        } : interestItem
      );
      dispatch(interestDataChanged(interestDataWithNewItem));
      dispatch(editReset());
    } else {
      displayEditInfo({
        dispatch,
        infoMsg: 'Aby dodać, wpisz co najmniej: termin spełnienia świadczenia, okres naliczania odsetek i kwotę.',
        infoOut: false,
      });
    }
  };


const EditActions = (
  { onCancel }: { onCancel:()=>void }
) => {
  const { width } = useWindowSize();

  const dispatch = useDispatch();

  const onBack = () => {
    dispatch(editReset());
  };

  const onEdit = () => {
    dispatch(changeInterestItem());
  };


  return (
    <RowExt
      justify="end"
      marginBottom="md"
    >
      <Col
        sm={{
          span:5, order: 1
        }}
        xs={{
          span: 24, order: 3
        }}
      >
        <ButtonResp
          onClick={onBack}
        >
          {texts.goBack}
        </ButtonResp>
      </Col>
      <Col
        sm={{
          span:2, order:2
        }}
        xs={0}
      />
      <Col
        sm={{
          span:5, order:3
        }}
        xs={{
          span: 24, order: 2
        }}
      >
        <ButtonResp
          icon={<ReloadOutlined />}
          onClick={onCancel}
        >
          {width > GRID_MD || width < GRID_SM ?  texts.reset : null}
        </ButtonResp>
      </Col>
      <Col
        sm={{
          span:2, order:4
        }}
        xs={0}
      />
      <Col
        sm={{
          span:10, order:5
        }}
        xs={{
          span: 24, order: 1
        }}
      >
        <ButtonResp
          type='primary'
          icon={<EditOutlined />}
          onClick={onEdit}
        >
          {texts.save}
        </ButtonResp>
      </Col>
    </RowExt>
  );
};


export default EditActions;

const texts = {
  goBack: 'Wróc',
  reset:'Cofnij zmiany',
  justification: 'uzasadnienie',
  details: 'szczegóły wyliczeń',
  save: 'Zmień',
};

const ButtonResp = styled(Button)`
    width: 100%;
    @media only screen and (max-width: ${GRID_SM_PX}) {
    margin-top: 24px;
	}
`;