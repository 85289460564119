import { ArrowLeftOutlined } from '@ant-design/icons';
import { Col, Divider } from 'antd';
import React from 'react';

import { useAppDispatch } from '../../../store';
import { isImportPaymentVisibleUpdated } from '../../../store/lawsuitUI';
import { ButtonFullWidth, RowExt } from '../../../styles';
import StepCard from '../StepCard/StepCard';
import ImportCsvPayments from './ImportPayments/ImportCsvPayments';
import ImportExPayments from './ImportPayments/ImportExPayments';


const ImportPayments = () => {
  const dispatch = useAppDispatch();
  return (
    <StepCard
      title="Importuj wpłaty z pliku excel lub csv"
      desc="Importuj plik csv z banku z historią właściwych wpłat lub wgraj plik excel"
    >
      <ImportCsvPayments />
      <Divider>
        lub
      </Divider>
      <ImportExPayments />
      <RowExt marginTop={32} justify="space-between" align="middle" marginBottom="bg" gutter={[{ sm:16 }, { xs:24 }]}>
        <Col sm={8} xs={24}>
          <ButtonFullWidth
            icon={<ArrowLeftOutlined />}
            onClick={() =>
              dispatch(isImportPaymentVisibleUpdated(false))}
          >
            Wróć
          </ButtonFullWidth>
        </Col>
        <Col sm={16} xs={0} />
      </RowExt>
    </StepCard>
  );
};

export default ImportPayments;