import _ from 'lodash';
import { DocPage } from '../../store/impOcr';
import { extractPageNumber } from './DataExtraction/pageNumbers';
import { DocTypes } from './docTypes';

type Docs = {
  doc: string,
  pages: DocPage[]
}[];

type OcrData = {
  input: string;
  output: DocTypes;
  image: string;
}[];


export const createMultiPage = (ocrData: OcrData) =>
  ocrData.reduce((prev:Docs, { input, image }, index) => {
    const { pageNumber } = extractPageNumber(input);
    const defaultItem:Docs = [
      ...prev, {
        doc: input,
        pages:[{
          input,
          image,
          pageNumber
        }]
      } ];
    if (pageNumber === 1) {
      return defaultItem;
    }
    const lastItem = _.last(prev);
    if (_.isNumber(pageNumber) && pageNumber !== 1 && lastItem){
      const lastImage = _.last(lastItem.pages);
      if (!_.isUndefined(lastImage)
        && _.isNumber(lastImage.pageNumber)
        && pageNumber - lastImage.pageNumber === 1){
        const newLast = {
          doc: lastItem.doc + input,
          pages: [
            ...lastItem.pages,
            {
              input,
              image,
              pageNumber
            }
          ]
        };
        const newPrev = prev;
        newPrev[index - 1] = newLast;
        return newPrev;
      }
      return defaultItem;
    }
    return defaultItem;
  }, []);