import Dinero from 'dinero.js';
import moment, { Moment } from 'moment';
import * as R from 'ramda';
import { InterestItemFulfilled, SudoInterestItem } from '../../@types';
import { InterestItem } from '../../store/interestStorage';
import { InterestTypes, INTEREST_AGREE, INTEREST_CAPITAL, INTEREST_LATE, INTEREST_MED_PUBLIC, INTEREST_TRANSACTION, LawsuitOpts } from '../../store/lawsuitOpts';
import * as typesOfInterest from './interestsData';
import { InterestLawData } from './interestsData';

export type PeriodResult = {
  days: number
  periodSum: number,
  periodStart: Moment | string,
  periodEnd: Moment | string | Date,
  interestRate: number,
};

export type InterestCalcData = {
  interestPartialSum: number;
  periodResults: PeriodResult[]
  key: string;
};

export type Lawsuit = {
  lawsuitOpts: LawsuitOpts,
  interestData: typesOfInterest.InterestLawData[],
};

export const isInterestAgree = ({ typeOfInterest }:{
  typeOfInterest: InterestTypes;
}) =>
  typeOfInterest === INTEREST_AGREE;

export const isCompensationOn = (lawsuit: Lawsuit)  =>
  lawsuit.lawsuitOpts.compensationOn;


export const getInterestTypeData = (typeOfInterest: InterestTypes) => {
  switch (typeOfInterest) {
    case INTEREST_LATE:
      return typesOfInterest.INTEREST_LATE_PERIODS;
    case INTEREST_CAPITAL:
      return typesOfInterest.INTEREST_CAPITAL_PERIODS;
    case INTEREST_TRANSACTION:
      return typesOfInterest.INTEREST_TRANSACTION_PERIODS;
    case INTEREST_MED_PUBLIC:
      return typesOfInterest.INTEREST_MED_PUBLIC_PERIODS;
    default:
      return [];
  }
};

type PeriodCheck = {
  interestDataObj: InterestItem | InterestItemFulfilled,
  interestLawDataObj: InterestLawData
};

const isStartingDataInPeriod = ({ interestDataObj, interestLawDataObj }: PeriodCheck)  =>
  interestDataObj.startingDate.isSameOrAfter(interestLawDataObj.start)
  && interestDataObj.startingDate.isBefore(interestLawDataObj.end);

const isEndDataInPeriod = ({ interestDataObj, interestLawDataObj }: PeriodCheck)  =>
  interestDataObj.endDate.isSameOrBefore(interestLawDataObj.end)
    && interestDataObj.endDate.isSameOrAfter(interestLawDataObj.start);

const isStartingDataBeforePeriod = ({ interestDataObj, interestLawDataObj }: PeriodCheck) =>
  interestDataObj.startingDate.isBefore(interestLawDataObj.start);

const isEndDateIsAfterPeriod = ({ interestDataObj, interestLawDataObj }: PeriodCheck) =>
  interestDataObj.endDate.isAfter(interestLawDataObj.end);

const selectLawPeriods = (periodCheck: PeriodCheck) =>
  /* ------------------------------ | sd>  <ed | ----------------------------- */
  /* ------------------------------- | sd> | <ed ------------------------------ */
  isStartingDataInPeriod(periodCheck) ||
  /* ------------------------------- sd> | <ed | ------------------------------ */
  (isStartingDataBeforePeriod(periodCheck) && isEndDataInPeriod(periodCheck)) ||
  /* ------------------------------- sd> | | <ed ------------------------------ */
  (isStartingDataBeforePeriod(periodCheck) && isEndDateIsAfterPeriod(periodCheck));


const getInterestPeriods = (interestLawData: InterestLawData[], interestDataObj: InterestItem | InterestItemFulfilled) =>
  interestLawData.filter(interestLawDataObj =>
    selectLawPeriods({
      interestDataObj, interestLawDataObj
    }));

export const calcPeriodResult = ({
  endDate,
  startDate,
  interestRate,
  partialSum,
}:{
  endDate:Moment | string,
  startDate:Moment | string,
  interestRate:number,
  partialSum:number,
}) => {
  const days = moment(endDate).diff(startDate, 'days') + 1;
  const periodResult = {
    days,
    periodSum: Dinero({ amount: partialSum })
      .multiply(interestRate)
      .multiply(days)
      .divide(365)
      .getAmount(),
    periodStart: startDate,
    periodEnd: endDate,
    interestRate,
  };
  return periodResult;
};

const setPartialResult = R.cond([
  [isStartingDataInPeriod,
    ({ interestDataObj, interestLawDataObj }) =>
      isEndDataInPeriod({
        interestDataObj, interestLawDataObj
      }) ?
        /* ------------------------------ | sd>  <ed | ----------------------------- */
      // end date is over in this period = days till endDate
        calcPeriodResult({
          startDate: interestDataObj.startingDate,
          endDate: interestDataObj.endDate,
          interestRate: interestLawDataObj.interestRate,
          partialSum: interestDataObj.partialSum
        }) :
      /* ------------------------------- | sd> | <ed ------------------------------ */
      // end date is over in this period = all days from startingDate till over of period
        calcPeriodResult({
          startDate: interestDataObj.startingDate,
          endDate: interestLawDataObj.end,
          interestRate: interestLawDataObj.interestRate,
          partialSum: interestDataObj.partialSum
        })
  ],
  /* ------------------------------- sd> | <ed | ------------------------------ */
  // end date is over in this period = all days from period start till endDate
  [R.allPass([isStartingDataBeforePeriod, isEndDataInPeriod]),  ({ interestDataObj, interestLawDataObj }) =>
    calcPeriodResult({
      startDate: interestLawDataObj.start,
      endDate: interestDataObj.endDate,
      interestRate: interestLawDataObj.interestRate,
      partialSum: interestDataObj.partialSum
    })
  ],
  /* ------------------------------- sd> | | <ed ------------------------------ */

  // end date is over in this period = all days from period start till over of period
  [R.allPass([isStartingDataBeforePeriod, isEndDateIsAfterPeriod]),  ({ interestDataObj, interestLawDataObj }) =>
    calcPeriodResult({
      startDate: interestLawDataObj.start,
      endDate: interestLawDataObj.end,
      interestRate: interestLawDataObj.interestRate,
      partialSum: interestDataObj.partialSum
    })
  ],
]);

export const calcPeriodResults = (
  interestLawData: InterestLawData[],
  interestDataObj: InterestItem | InterestItemFulfilled
) => {
  const interestPeriods = getInterestPeriods(interestLawData, interestDataObj);
  return interestPeriods.map(interestLawDataObj =>
    setPartialResult({
      interestDataObj,
      interestLawDataObj,
    }));
};

export const sumUpPeriods = (periodResults:PeriodResult[]) => {
  return periodResults.reduce((acc:number, currentValue:PeriodResult) => {
    return acc + currentValue.periodSum;
  }, 0);
};


export const sumUpToInterestSum = (interestCalcData:InterestCalcData[]) => {
  return interestCalcData.reduce((acc:number, currentValue:InterestCalcData) => {
    return acc + currentValue.interestPartialSum;
  }, 0);
};


/* -------------------------------------------------------------------------- */
/*                                interest act                                */
/* -------------------------------------------------------------------------- */


export const calcInterestsAct = ({
  interestData,
  typeOfInterest,
}:{
  interestData: InterestItem[] | SudoInterestItem[];
  typeOfInterest: InterestTypes;
}) => {

  // get array InterestLawData[] of dates and rates
  const interestLawData = getInterestTypeData(typeOfInterest);

  const interestCalcData = interestData.map(interestDataObj => {

    // filter and then map trough InterestLawData[] to check InterestItem against law dates
    const periodResults = calcPeriodResults(interestLawData, interestDataObj);

    // get sum of all periods
    const interestPartialSum = sumUpPeriods(periodResults);

    return {
      interestPartialSum,
      periodResults,
      key: interestDataObj.key,
    };
  });
  // get
  const interestsSum = sumUpToInterestSum(interestCalcData);
  return {
    interestsSum, interestCalcData
  };
};

/* -------------------------------------------------------------------------- */
/*                               interest agree                               */
/* -------------------------------------------------------------------------- */

export const calcInterestsAgree = ({
  interestData,
  agreementInterestRate,
}:{
  interestData: InterestItem[] | SudoInterestItem[];
  agreementInterestRate: number;
}) => {
  const interestCalcData = interestData.map(interestDataObj => {
    const periodResults = [calcPeriodResult({
      endDate:interestDataObj.endDate,
      startDate: interestDataObj.startingDate,
      interestRate: agreementInterestRate / 100,
      partialSum: interestDataObj.partialSum,
    })];
    return {
      interestPartialSum: periodResults[0].periodSum,
      periodResults,
      key: interestDataObj.key,
    };
  });
  const interestsSum = sumUpToInterestSum(interestCalcData);
  return {
    interestsSum, interestCalcData
  };
};

export const numberToPercent = (percent: number) => {
  return (`${(percent * 100).toFixed(2)}%`);
};

/* -------------------------------------------------------------------------- */
/*                               interest calc                                */
/* -------------------------------------------------------------------------- */


export const calcInterest = ({
  lawsuitOpts,
  interestData,
}:{
  lawsuitOpts: LawsuitOpts,
  interestData: InterestItem[] | SudoInterestItem[];
}) =>
  lawsuitOpts.typeOfInterest === INTEREST_AGREE ?
    calcInterestsAgree({
      interestData,
      agreementInterestRate:lawsuitOpts.agreementInterestRate,
    }) :
    calcInterestsAct({
      interestData,
      typeOfInterest: lawsuitOpts.typeOfInterest,
    });


export const calcOneInterest = ({
  lawsuitOpts,
  interestItem,
}:{
  lawsuitOpts: LawsuitOpts,
  interestItem: InterestItem | SudoInterestItem,
}) => {
  const { typeOfInterest } = lawsuitOpts;

  if (lawsuitOpts.typeOfInterest === INTEREST_AGREE){
    const periodResults = [calcPeriodResult({
      endDate:interestItem.endDate,
      startDate: interestItem.startingDate,
      interestRate: lawsuitOpts.agreementInterestRate / 100,
      partialSum: interestItem.partialSum,
    })];
    return {
      interestSum: periodResults[0].periodSum,
      periodResults,
    };
  }

  const interestLawData = getInterestTypeData(typeOfInterest);

  const periodResults = calcPeriodResults(interestLawData, interestItem);

  return {
    interestSum: sumUpPeriods(periodResults),
    periodResults,
  };

};