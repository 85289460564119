import { Col, Row, Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';

import { DashedDivider } from '../../routes/About';
import { AboutContainer, DashedColLeft, RowExt } from '../../styles';

interface Props {
  title: string | React.ReactNode,
  color: string,
  id?: string,
  children: React.ReactNode
}

const DeveloperSection = (
  {
    title,
    color,
    id,
    children
  }: Props
) => {
  return (
    <>
      <AboutContainer>
        <RowExt
          id={id}
        >
          <Col
            lg={{
              span: 6,
              offset: 2
            }}
            md={{
              offset: 2,
              span: 8
            }}
            xs={{
              offset: 2,
              span: 20,
            }}
          >
            <TitleWrapper
              color={color}
            >
              <Col span={18} offset={2}>
                <div>
                  <Typography.Title
                    level={3}
                  >
                    {title}
                  </Typography.Title>
                </div>
              </Col>
            </TitleWrapper>
          </Col>
          <Col
            lg={{
              offset: 0,
              span: 12,
            }}
            md={{
              offset: 0,
              span: 12,
            }}
            xs={{
              offset: 2,
              span: 20,
            }}
          >
            <RowExt paddingTop="bg" paddingBottom={24}>
              <Col span={20} offset={2}>
                {children}
              </Col>
            </RowExt>
          </Col>
          <DashedColLeft
            xl={{ span: 2 }}
            lg={{ span: 1 }}
            xs={0}
          />
          <DashedColLeft
            xl={{ span: 2 }}
            lg={{ span: 3 }}
            xs={0}
          />
        </RowExt>
      </AboutContainer>
      <DashedDivider />
    </>
  );
};

export default DeveloperSection;


const TitleWrapper = styled(Row)<{ color?: string }>`
    border-left: 1px solid ${({ color }) =>
    color};
    margin-top: 52px;
`;