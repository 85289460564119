import { notification } from 'antd';
import firebase from 'firebase/compat/app';
import { nanoid } from 'nanoid';
import { AppDispatch, RootState } from '../store';
import { appLoadingStopped, appLoadingTextChanged } from '../store/appLoading';
import { lsShouldUpdateLsCodeChanged, shouldCreateLsCodeUpdated } from '../store/lawsuitUI';
import { sumUpLsCodeChanged } from '../store/sumUp';
import { formatInterestDataToSave } from '../utils/helpers/formatInterestData';
import { formatPaymentDataToSave } from '../utils/helpers/formatPaymentData';
import _firebase from '../_firebase';
import { LawsuitSave } from './lawsuitSave';

export interface LawsuitSaveCode extends LawsuitSave{
  userId?: string,
}

export const writeLsCode = () =>
  async (dispatch: AppDispatch, getState: () => RootState) => {

    const state = getState();

    const { lawsuitOpts } = state;
    const { interestData, paymentData } = state.interestStorage;
    const { shouldCreateLsCode } = state.lawsuitUI;
    const { isNonAnonymousUserLoggedIn } = state.user;
    const { lsCodeToBeUpdated } = state.lawsuitUI;

    const onFinish = () => {
      dispatch(appLoadingStopped());
      dispatch(shouldCreateLsCodeUpdated(false));
    };

    const db = _firebase.firestore();
    if (shouldCreateLsCode){
      try {
        dispatch(appLoadingTextChanged('zapisywanie kodu wyliczeń'));
        const interestDataFirebase = formatInterestDataToSave(interestData);
        const paymentDataFirebase = formatPaymentDataToSave(paymentData);

        // https://stackoverflow.com/questions/56487578/how-do-i-implement-a-write-rate-limit-in-cloud-firestore-security-rules

        const root = _firebase.firestore();
        const writeLimit = root.collection('writeLimit');

        const saveId = lsCodeToBeUpdated ?? nanoid(12);

        const saveRef = db
          .collection('ls')
          .doc(saveId);
        if (!isNonAnonymousUserLoggedIn && !_firebase.auth().currentUser){
          await _firebase.auth().signInAnonymously();
        }

        const writeLimitDoc = writeLimit.doc(`${_firebase.auth().currentUser?.uid }`);

        const limitExists  = (await writeLimitDoc.get()).exists;

        const lsCodeData = {
          lawsuitOpts,
          interestData: interestDataFirebase,
          paymentData: paymentDataFirebase,
          createDate: firebase.firestore.FieldValue.serverTimestamp(),
          editDate: firebase.firestore.Timestamp.now(),
          type: 'lawsuit',
          ver: process.env.REACT_APP_VERSION,
          userId: _firebase.auth().currentUser?.uid,
        };

        if (lsCodeToBeUpdated){
          await saveRef.update(lsCodeData);
        } else if (!limitExists){
          await writeLimitDoc.set({ timestamp: firebase.firestore.FieldValue.serverTimestamp() });
          setTimeout(async () => {
            await saveRef.set(lsCodeData);
          }, 5500);
        } else {
          await saveRef.set(lsCodeData);
        }

        await writeLimitDoc.set({ timestamp: firebase.firestore.FieldValue.serverTimestamp() });

        dispatch(sumUpLsCodeChanged(saveId));
        dispatch(lsShouldUpdateLsCodeChanged(saveId));

        onFinish();

      } catch (e){
        notification.info({ message: 'Kod nie został wygenerowany. Prawdopodobnie próbujesz go wygenerować w bardzo małych odstępach czasu. (np. co parę sekund przyciskasz przycisk "Oblicz").' });
        // eslint-disable-next-line no-console
        console.log(e);
        onFinish();
      }
    }
    dispatch(appLoadingStopped());
  };