import { CheckOutlined, DownloadOutlined, HomeOutlined, SaveOutlined } from '@ant-design/icons';
import { Col, Popconfirm } from 'antd';
import moment from 'moment';
import React from 'react';
import { shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { logToAnalytics } from '../../../services/firebase/logToAnalytics';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { lsDecreaseStep } from '../../../store/lawsuitUI';
import { saveModalOpened } from '../../../store/saveLawsuitModal';
import { GRID_MD, RowExt } from '../../../styles';
import { ButtonFullWidth, LinkFullWidth } from '../../../styles/ui';
import { convertToJSON } from '../../../utils/helpers/date';
import { formatInterestDataToSave } from '../../../utils/helpers/formatInterestData';
import { formatPaymentDataToSave } from '../../../utils/helpers/formatPaymentData';
import { resetLawsuit } from '../../../utils/helpers/resetLawsuit';
import useWindowSize from '../../../utils/hooks/useWindowSize';
import CopyButton from '../../UI/Button/CopyButton';
import BackButton from '../StepCard/BackButton';


const SumUpActions = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { width } = useWindowSize();

  const  lsCode = useTypedSelector(state =>
    state.sumUp.lsCode);

  const lawsuitOpts = useTypedSelector(state =>
    state.lawsuitOpts, shallowEqual);

  const interestStorage = useTypedSelector(state =>
    state.interestStorage, shallowEqual);

  const userExists = useTypedSelector(state =>
    state.user.isNonAnonymousUserLoggedIn);


  const handleSaveToPC = () => {
    const fileData = JSON.stringify({
      lawsuitOpts: { ...lawsuitOpts },
      interestData: formatInterestDataToSave(interestStorage.interestData),
      paymentData: formatPaymentDataToSave(interestStorage.paymentData),
      editDate: convertToJSON(moment.now()),
      createDate: convertToJSON(moment.now()),
      recordName: `Wyliczenia ${moment(Date.now()).format('YYYY-MM-DD hhhh:mm')}`,
      type: 'lawsuit',
      ver: process.env.REACT_APP_VERSION
    });
    const blob = new Blob([fileData], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.download = 'pozew-app.json';
    link.href = url;
    link.click();
    logToAnalytics('Saved_to_local');
  };

  return (
    <>
      <RowExt marginTop={32} justify="space-between" align="middle" gutter={[{ sm:16 }, { xs:24 }]}>
        <Col sm={8} xs={24}>
          <BackButton
            onBack={() =>
              dispatch(lsDecreaseStep())}
            showHome={false}
            fullWidth
          />
        </Col>
        <Col sm={8} xs={24}>
          <LinkFullWidth
            to={{ pathname: userExists ? '/dashboard' : '/' }}
          >
            <ButtonFullWidth
              icon={<HomeOutlined />}
            >
              {width >= GRID_MD ? 'Wróć' : null}
            </ButtonFullWidth>
          </LinkFullWidth>
        </Col>
        <Col sm={8} xs={24}>
          <Popconfirm
            title="Wszystkie dane z formularz zostaną usunięte"
            onConfirm={() => {
              history.push(userExists ? '/dashboard' : '/');
              resetLawsuit(dispatch);
            }}
          >
            <ButtonFullWidth
              icon={<CheckOutlined />}
              type="primary"
            >
              Zakończ
            </ButtonFullWidth>
          </Popconfirm>
        </Col>
      </RowExt>
      <RowExt marginTop={24} justify="end" align="middle" marginBottom="bg" gutter={[{ sm:16 }, { xs:24 }]}>
        {lsCode ? (
          <Col sm={8} xs={24}>
            <CopyButton
              value={lsCode ?? ''}
              womargin
              fullWidth
            >
              Kopiuj KOD WYLICZEŃ
            </CopyButton>
          </Col>
        ) : null}
        <Col sm={8} xs={24}>
          <ButtonFullWidth
            icon={<DownloadOutlined />}
            onClick={handleSaveToPC}
          >
            Pobierz plik zapisu
          </ButtonFullWidth>
        </Col>
        <Col sm={8} xs={24}>
          <ButtonFullWidth
            type='primary'
            icon={<SaveOutlined />}
            onClick={() =>
              dispatch(saveModalOpened({
                isSaveModalVisible: true,
                isSaveFromResult: false,
              }))}
          >
            {texts.save}
          </ButtonFullWidth>
        </Col>
      </RowExt>
    </>
  );
};


export default SumUpActions;

const texts = {
  goBack: 'wróc',
  reset:'resetuj',
  wps:'wps i opłata sądowa',
  payment: 'wynagrodzenie',
  justification: 'wygeneruj tekst',
  details: 'szczegóły wyliczeń',
  save: 'Zapisz'
};