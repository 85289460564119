/* eslint-disable react/no-array-index-key */
import { Descriptions, Divider } from 'antd';
import React from 'react';
import nextId from 'react-id-generator';
import styled from 'styled-components';
import _ from 'lodash';
import { formatDate } from '../../../utils/helpers/date';
import { formatPLN } from '../../../utils/helpers/formatNumber';
import { numberToPercent, PeriodResult } from '../../../services/calc/interestsCalc';
import { PartialResult } from '../../../store/sumUp';
import { ClaimItem } from '../../../services/calc/claim';
import { RowExt, DescFullWidth } from '../../../styles';

const { Item: DescItem } = Descriptions;


export const DetailsPeriodResult = ({ periodResult, children }:{ periodResult:PeriodResult, children?: React.ReactNode }) => {
  if (_.isEmpty(periodResult)) return null;
  return (
    <Container>
      {children}
      <Descriptions
        layout="vertical"
        column={{
          xxl: 4,
          xl: 4,
          lg: 4,
          md: 4,
          sm: 2,
          xs: 1,
        }}
        bordered
      >
        <DescItem label="Okres naliczania">
          {`od ${formatDate(periodResult.periodStart)} do ${formatDate(periodResult.periodEnd)}`}
        </DescItem>
        <DescItem label="Ilość dni">{periodResult.days}</DescItem>
        <DescItem label="Wysokość odsetek">{numberToPercent(periodResult.interestRate)}</DescItem>
        <DescItem label="Kwota">
          <strong>{`${formatPLN(periodResult.periodSum)}`}</strong>
        </DescItem>
      </Descriptions>
    </Container>
  );

};

const DetailsInterestsPeriods = ({ partialResult }: { partialResult: PartialResult | ClaimItem }) => {
  return (
    <>
      <Divider>
        {_.isEmpty(partialResult.assignedPayments) ?
          'Odsetki' :
          'Odsetki od niezaspokojonej części kwoty głównej'}
      </Divider>
      <RowExt marginBottom="md">
        <DescFullWidth layout="horizontal" bordered key="d">
          <DescItem label="Podstawa naliczania" span={3}><strong>{`${formatPLN(partialResult.claimSum)}`}</strong></DescItem>
        </DescFullWidth>
      </RowExt>
      {partialResult.periodResults?.map((periodResult) =>
        (
          <>
            <DetailsPeriodResult
              key={nextId()}
              periodResult={periodResult}
            />
          </>
        )
			  )}
    </>
  );
};

export default DetailsInterestsPeriods;

const Container = styled.div`
	margin-bottom: 24px;
`;
