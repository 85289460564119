/* eslint-disable @typescript-eslint/no-explicit-any */
import Holidays from 'date-holidays';
import firebase from 'firebase/compat/app';
import { Timestamp } from 'firebase/firestore';
import _ from 'lodash';
import moment, { isMoment, Moment } from 'moment';

const hd = new Holidays('PL');


export const checkWeekendHoliday = (incDate: any) => {
  let date = incDate;
  const alertArr: string[] = [];
  do {
    if (moment(date).day() === 6) {
      if (alertArr.length === 0) {
        alertArr[0] = `${moment(date).format('DD-MM-YYYY')} jest sobotą`;
      }
      date = moment(date).add(2, 'day');
    }
    if (moment(date).day() === 0) {
      if (alertArr.length === 0) {
        alertArr[0] = `${moment(date).format('DD-MM-YYYY')} jest niedzielą`;
      }
      date = moment(date).add(1, 'day');
    }
    if (hd.isHoliday(moment(date).toDate())) {
      if (alertArr.length === 0) {
        alertArr[0] = `${moment(date).format('DD-MM-YYYY')} jest świętem`;
      }
      date = moment(date).add(1, 'day');
    }
  } while (
    moment(date).day() === 6 ||
		moment(date).day() === 0 ||
		hd.isHoliday(moment(date).toDate())
  );
  return {
    date, alert: alertArr[0] ?? null,
  };
};

export const checkSundayHoliday = (incDate: any) => {
  let date = incDate;
  const alertArr: string[] = [];
  do {
    if (moment(date).day() === 0) {
      if (alertArr.length === 0) {
        alertArr[0] = `${moment(date).format('DD-MM-YYYY')} jest niedzielą`;
      }
      date = moment(date).add(1, 'day');
    }
    if (hd.isHoliday(moment(date).toDate())) {
      if (alertArr.length === 0) {
        alertArr[0] = `${moment(date).format('DD-MM-YYYY')} jest świętem`;
      }
      date = moment(date).add(1, 'day');
    }
  } while (
    moment(date).day() === 0 ||
		hd.isHoliday(moment(date).toDate())
  );
  return {
    date, alert: alertArr[0] ?? null,
  };
};

export function disabledDateImport(current: Moment) {
  return current < moment().startOf('day') || current > moment().add(60, 'days');
}

export const checkDueDate = (incDate: any) =>
  moment(incDate).isBefore('01-01-2017') ?
    checkSundayHoliday(incDate) :
    checkWeekendHoliday(incDate);

export function disabledDate(current: Moment) {
  return current < moment('2015-12-31') || current > moment().add(60, 'days');
}
export function disabledDateCurrency(current: Moment) {
  return current < moment('2015-12-31') || current > moment().endOf('day');
}

export function disabledDateCurrencyOutsideLawsuit(current: Moment) {
  return current < moment('2002-12-31') || current > moment().endOf('day');
}

export function disabledUntilToday(current: Moment) {
  return current < moment('2015-12-31') || current > moment().endOf('day');
}

export function disabledStatementDate(current: Moment, paymentDate: Moment | null) {
  if (_.isNull(paymentDate)) return current < moment('2015-12-31') || current > moment().endOf('day');
  return current < paymentDate || current > moment().endOf('day');
}

export function disabledPaymentDate(current: Moment, statementDate: Moment | null) {
  if (_.isNull(statementDate)) return current < moment('2015-12-31') || current > moment().endOf('day');
  return current < moment('2015-12-31') || current >= statementDate;
}

export const isHolidayCheck = (date: Moment) => {
  const isHoliday = hd.isHoliday(date.toDate());
  return isHoliday !== false;
};

export const isSundayCheck = (date: Moment) => {
  // If the value given is from 0 to 6,
  // the resulting date will be within the current (Sunday-to-Saturday) week.
  const dayOfWeek = moment(date).day();
  return dayOfWeek === 0;
};


export const convertToTimestamp = (date: any) => {
  if (isMoment(date)){
    return firebase.firestore.Timestamp.fromDate(date.toDate());
  }
  return null;
};

export const convertDateToString = (date: any) => {
  if (isMoment(date)){
    return date.format('YYYY-MM-DD');
  }
  return null;
};


const setConversionTime = (date: string, startOfaDay?: boolean) =>
  startOfaDay ? moment(date).startOf('day') : moment(date).endOf('day');

export const convertToMoment = (date:  string | Timestamp, startOfaDay?: boolean) =>
  date instanceof Timestamp ? moment(date.toDate()) : setConversionTime(date, startOfaDay);

export const convertToJSON = (date: number) =>
  moment(date).toDate().toJSON();

export const formatDate = (date:Moment | string | Date | undefined) =>
  (moment(date).format('DD-MM-YYYY'));