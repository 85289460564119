/* eslint-disable */
import * as React from 'react';
import styled from 'styled-components';
import * as iconTypes from './iconTypes';

interface IiconProps {
  style?: object;
  fill: string;
  width: string;
  className?: string;
  viewBox?: string;
  stroke?: string;
  strokeWidth?: string;
  iconType: string;
  height: string;
  hoverFill?: string;
}

const IconComponent: React.FunctionComponent<IiconProps> = ({
  style = {},
  fill = 'black',
  width = '100%',
  className = '',
  height = '100%',
  viewBox = '0 0 24 24',
  stroke = '',
  strokeWidth = 0,
  iconType = '',
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    stroke={stroke}
    strokeWidth={strokeWidth}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    // eslint-disable-next-line no-use-before-define
    {getPath(iconType)}
  </svg>
);


const Icon = styled(IconComponent)<{hoverFill?: string}>`
  :hover{
    fill:${p => (p.hoverFill !== '' && p.hoverFill)} ;
  }
`;

export default Icon;

const getPath = (iconType: string) => {
  switch (iconType) {
    case (iconTypes.SPEECH_BUBBLE):
      return (
        <path d="M12,3C5.383,3,0,7.038,0,12c0,2.19,1.04,4.266,2.944,5.asf905L0.187,20.11c-0.166,0.133-0.23,0.356-0.159,0.556

        S0.288,21,0.5,21H12c6.617,0,12-4.038,12-9C24,7.038,18.617,3,12,3z M12,20H1.926l2.127-1.702c0.121-0.096,0.19-0.242,0.188-0.396
			c-0.002-0.154-0.074-0.299-0.196-0.392C2.081,16.01,1,14.054,1,12c0-4.411,4.935-8,11-8s11,3.589,11,8S18.065,20,12,20z"
        />
      );
    case (iconTypes.SHARE):
      return (
        <g>
          <g>
            <path d="M20,8c-2.206,0-4-1.794-4-4s1.794-4,4-4s4,1.794,4,4S22.206,8,20,8z M20,1c-1.654,0-3,1.346-3,3s1.346,3,3,3s3-1.346,3-3
            S21.654,1,20,1z"
            />
          </g>
          <g>
            <path d="M20,24c-2.206,0-4-1.794-4-4s1.794-4,4-4s4,1.794,4,4S22.206,24,20,24z M20,17c-1.654,0-3,1.346-3,3s1.346,3,3,3
            s3-1.346,3-3S21.654,17,20,17z"
            />
          </g>
          <g>
            <path d="M4,16c-2.206,0-4-1.794-4-4s1.794-4,4-4s4,1.794,4,4S6.206,16,4,16z M4,9c-1.654,0-3,1.346-3,3s1.346,3,3,3s3-1.346,3-3
            S5.654,9,4,9z"
            />
          </g>
          <g>
            <path d="M7.131,10.935c-0.183,0-0.359-0.101-0.447-0.276c-0.123-0.247-0.023-0.547,0.224-0.671l9.738-4.869
            c0.248-0.122,0.546-0.024,0.671,0.224c0.123,0.247,0.023,0.547-0.224,0.671l-9.738,4.869C7.282,10.917,7.206,10.935,7.131,10.935
            z"
            />
          </g>
          <g>
            <path d="M16.869,18.935c-0.075,0-0.151-0.017-0.224-0.053l-9.738-4.869c-0.247-0.124-0.347-0.424-0.224-0.671
            c0.124-0.248,0.425-0.344,0.671-0.224l9.738,4.869c0.247,0.124,0.347,0.424,0.224,0.671
            C17.229,18.833,17.052,18.935,16.869,18.935z"
            />
          </g>
        </g>
      );
    case (iconTypes.CALENDAR):
      return (
        <g>
          <g>
            <path d="M20.5,24h-17C1.57,24,0,22.43,0,20.5v-15C0,3.57,1.57,2,3.5,2h1C4.776,2,5,2.224,5,2.5S4.776,3,4.5,3h-1
          C2.121,3,1,4.121,1,5.5v15C1,21.879,2.121,23,3.5,23h17c1.379,0,2.5-1.121,2.5-2.5v-15C23,4.121,21.879,3,20.5,3h-1
          C19.224,3,19,2.776,19,2.5S19.224,2,19.5,2h1C22.43,2,24,3.57,24,5.5v15C24,22.43,22.43,24,20.5,24z"
            />
          </g>
          <g>
            <path d="M15.5,3h-7C8.224,3,8,2.776,8,2.5S8.224,2,8.5,2h7C15.776,2,16,2.224,16,2.5S15.776,3,15.5,3z" />
          </g>
          <g>
            <path d="M23.5,8h-23C0.224,8,0,7.776,0,7.5S0.224,7,0.5,7h23C23.776,7,24,7.224,24,7.5S23.776,8,23.5,8z" />
          </g>
          <g>
            <path d="M6.5,5C6.224,5,6,4.776,6,4.5v-4C6,0.224,6.224,0,6.5,0S7,0.224,7,0.5v4C7,4.776,6.776,5,6.5,5z" />
          </g>
          <g>
            <path d="M17.5,5C17.224,5,17,4.776,17,4.5v-4C17,0.224,17.224,0,17.5,0S18,0.224,18,0.5v4C18,4.776,17.776,5,17.5,5z" />
          </g>
          <g>
            <path d="M8.5,20C7.121,20,6,18.879,6,17.5C6,17.224,6.224,17,6.5,17S7,17.224,7,17.5C7,18.327,7.673,19,8.5,19s1.5-0.673,1.5-1.5
          S9.327,16,8.5,16h-1C7.224,16,7,15.776,7,15.5S7.224,15,7.5,15h1c0.827,0,1.5-0.673,1.5-1.5S9.327,12,8.5,12S7,12.673,7,13.5
          C7,13.776,6.776,14,6.5,14S6,13.776,6,13.5C6,12.121,7.121,11,8.5,11s2.5,1.121,2.5,2.5c0,0.816-0.394,1.544-1.002,2
          c0.608,0.456,1.002,1.184,1.002,2C11,18.879,9.879,20,8.5,20z"
            />
          </g>
          <g>
            <path d="M15.5,20c-0.276,0-0.5-0.224-0.5-0.5v-6.793l-1.146,1.146c-0.195,0.195-0.512,0.195-0.707,0s-0.195-0.512,0-0.707l2-2
          c0.144-0.144,0.359-0.185,0.545-0.108C15.878,11.115,16,11.298,16,11.5v8C16,19.776,15.776,20,15.5,20z"
            />
          </g>
          <g>
            <path d="M17.5,20h-4c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h4c0.276,0,0.5,0.224,0.5,0.5S17.776,20,17.5,20z" />
          </g>
        </g>
      );
    case (iconTypes.MINIMIZE):
      return (
        <g>
          <g>
            <path d="M12.5,12c-0.128,0-0.256-0.049-0.354-0.146c-0.195-0.195-0.195-0.512,0-0.707l7-7c0.195-0.195,0.512-0.195,0.707,0
            s0.195,0.512,0,0.707l-7,7C12.756,11.951,12.628,12,12.5,12z"
            />
          </g>
          <g>
            <path d="M12.5,12c-0.276,0-0.5-0.224-0.5-0.5v-6C12,5.224,12.224,5,12.5,5S13,5.224,13,5.5v6C13,11.776,12.776,12,12.5,12z" />
          </g>
          <g>
            <path d="M18.5,12h-6c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h6c0.276,0,0.5,0.224,0.5,0.5S18.776,12,18.5,12z" />
          </g>
          <g>
            <path d="M3.5,21c-0.128,0-0.256-0.049-0.354-0.146c-0.195-0.195-0.195-0.512,0-0.707l7-7c0.195-0.195,0.512-0.195,0.707,0
            s0.195,0.512,0,0.707l-7,7C3.756,20.951,3.628,21,3.5,21z"
            />
          </g>
          <g>
            <path d="M10.5,20c-0.276,0-0.5-0.224-0.5-0.5v-6c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v6C11,19.776,10.776,20,10.5,20z" />
          </g>
          <g>
            <path d="M10.5,14h-6C4.224,14,4,13.776,4,13.5S4.224,13,4.5,13h6c0.276,0,0.5,0.224,0.5,0.5S10.776,14,10.5,14z" />
          </g>
          <g>
            <path d="M21.5,24h-20C0.673,24,0,23.327,0,22.5v-20C0,1.673,0.673,1,1.5,1h20C22.327,1,23,1.673,23,2.5v20
            C23,23.327,22.327,24,21.5,24z M1.5,2C1.224,2,1,2.225,1,2.5v20C1,22.775,1.224,23,1.5,23h20c0.276,0,0.5-0.225,0.5-0.5v-20
            C22,2.225,21.776,2,21.5,2H1.5z"
            />
          </g>
        </g>
      );
    case (iconTypes.CUP):
      return (
        <g>
          <g>
            <path d="M11.5,15C7.855,15,5,8.631,5,0.5C5,0.224,5.224,0,5.5,0S6,0.224,6,0.5C6,7.818,8.519,14,11.5,14S17,7.818,17,0.5
            C17,0.224,17.224,0,17.5,0S18,0.224,18,0.5C18,8.631,15.145,15,11.5,15z"
            />
          </g>
          <g>
            <path d="M15.5,24h-8C7.224,24,7,23.776,7,23.5C7,22.122,8.122,21,9.5,21h4c1.378,0,2.5,1.122,2.5,2.5
            C16,23.776,15.776,24,15.5,24z M8.085,23h6.829c-0.207-0.582-0.763-1-1.415-1h-4C8.848,22,8.292,22.418,8.085,23z"
            />
          </g>
          <g>
            <path d="M11.5,22c-0.276,0-0.5-0.224-0.5-0.5v-7c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v7C12,21.776,11.776,22,11.5,22z" />
          </g>
          <g>
            <path d="M18.5,1h-14C4.224,1,4,0.776,4,0.5S4.224,0,4.5,0h14C18.776,0,19,0.224,19,0.5S18.776,1,18.5,1z" />
          </g>
          <g>
            <path d="M14.75,13c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5C18.196,12,21,9.196,21,5.75C21,4.785,20.215,4,19.25,4
            c-0.675,0-1.297,0.396-1.585,1.007C17.547,5.257,17.25,5.364,17,5.247c-0.25-0.118-0.357-0.416-0.24-0.666
            C17.211,3.621,18.189,3,19.25,3C20.767,3,22,4.233,22,5.75C22,9.748,18.748,13,14.75,13z"
            />
          </g>
          <g>
            <path d="M8.25,13C4.252,13,1,9.748,1,5.75C1,4.233,2.233,3,3.75,3c1.061,0,2.039,0.621,2.49,1.582
            C6.357,4.832,6.25,5.129,6,5.247c-0.251,0.117-0.548,0.009-0.666-0.24C5.047,4.396,4.425,4,3.75,4C2.785,4,2,4.785,2,5.75
            C2,9.196,4.804,12,8.25,12c0.276,0,0.5,0.224,0.5,0.5S8.526,13,8.25,13z"
            />
          </g>
        </g>
      );
    case (iconTypes.USER):
      return (
        <g>
          <g>
            <path d="M22.5,24.031c-0.276,0-0.5-0.224-0.5-0.5c0-5.79-4.71-10.5-10.5-10.5S1,17.741,1,23.531c0,0.276-0.224,0.5-0.5,0.5
            S0,23.807,0,23.531c0-6.341,5.159-11.5,11.5-11.5S23,17.189,23,23.531C23,23.807,22.776,24.031,22.5,24.031z"
            />
          </g>
          <g>
            <path d="M11.5,10.969c-3.032,0-5.5-2.467-5.5-5.5s2.468-5.5,5.5-5.5s5.5,2.467,5.5,5.5S14.532,10.969,11.5,10.969z M11.5,0.969
            c-2.481,0-4.5,2.019-4.5,4.5s2.019,4.5,4.5,4.5s4.5-2.019,4.5-4.5S13.981,0.969,11.5,0.969z"
            />
          </g>
        </g>
      );
    case (iconTypes.CHEVRON_DOWN):
      return (
        <g>
          <path d="M12,18c-0.128,0-0.256-0.049-0.354-0.146l-11-11c-0.195-0.195-0.195-0.512,0-0.707s0.512-0.195,0.707,0L12,16.793
		L22.646,6.146c0.195-0.195,0.512-0.195,0.707,0s0.195,0.512,0,0.707l-11,11C12.256,17.951,12.128,18,12,18z"
          />
        </g>
      );
    case (iconTypes.PHOTO):
      return (
        <g>
          <g>
            <path d="M20.5,24h-17C2.121,24,1,22.878,1,21.5v-19C1,1.122,2.121,0,3.5,0h17C21.879,0,23,1.122,23,2.5v19
				C23,22.878,21.879,24,20.5,24z M3.5,1C2.673,1,2,1.673,2,2.5v19C2,22.327,2.673,23,3.5,23h17c0.827,0,1.5-0.673,1.5-1.5v-19
				C22,1.673,21.327,1,20.5,1H3.5z"
            />
          </g>
          <g>
            <path d="M19.5,16h-15C4.224,16,4,15.776,4,15.5v-12C4,3.224,4.224,3,4.5,3h15C19.776,3,20,3.224,20,3.5v12
				C20,15.776,19.776,16,19.5,16z M5,15h14V4H5V15z"
            />
          </g>
          <g>
            <path d="M14.5,13c-0.143,0-0.285-0.061-0.384-0.18L9.5,7.281L4.884,12.82c-0.177,0.213-0.492,0.239-0.704,0.064
				c-0.212-0.177-0.24-0.492-0.063-0.705l5-6c0.189-0.228,0.578-0.228,0.768,0l5,6c0.177,0.212,0.148,0.528-0.063,0.705
				C14.727,12.962,14.613,13,14.5,13z"
            />
          </g>
          <g>
            <path d="M19.5,14.5c-0.136,0-0.271-0.055-0.37-0.164l-4.647-5.112l-1.129,1.129c-0.195,0.195-0.512,0.195-0.707,0
				s-0.195-0.512,0-0.707l1.5-1.5C14.243,8.05,14.381,7.997,14.512,8c0.137,0.003,0.267,0.063,0.358,0.164l5,5.5
				c0.186,0.205,0.171,0.521-0.034,0.707C19.74,14.457,19.62,14.5,19.5,14.5z"
            />
          </g>
        </g>
      );
    case (iconTypes.PAPERCLIP):
      return (
        <g>
          <g>
            <path d="M10.5,24C8.019,24,6,21.981,6,19.5v-14C6,2.467,8.468,0,11.5,0S17,2.467,17,5.5v11c0,0.276-0.224,0.5-0.5,0.5
            S16,16.776,16,16.5v-11C16,3.019,13.981,1,11.5,1S7,3.019,7,5.5v14c0,1.93,1.57,3.5,3.5,3.5s3.5-1.57,3.5-3.5v-12
            C14,6.122,12.879,5,11.5,5S9,6.122,9,7.5v9C9,16.776,8.776,17,8.5,17S8,16.776,8,16.5v-9C8,5.57,9.57,4,11.5,4S15,5.57,15,7.5v12
            C15,21.981,12.981,24,10.5,24z"
            />
          </g>
        </g>
      );
    case (iconTypes.DOWNLOAD):
      return (
        <g>
          <g>
            <path d="M21.5,24h-20C0.673,24,0,23.327,0,22.5v-5C0,17.224,0.224,17,0.5,17S1,17.224,1,17.5v5C1,22.775,1.224,23,1.5,23h20
            c0.276,0,0.5-0.225,0.5-0.5v-5c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v5C23,23.327,22.327,24,21.5,24z"
            />
          </g>
          <g>
            <path d="M11.5,20c-0.276,0-0.5-0.224-0.5-0.5v-19C11,0.224,11.224,0,11.5,0S12,0.224,12,0.5v19C12,19.776,11.776,20,11.5,20z" />
          </g>
          <g>
            <path d="M11.5,20c-0.128,0-0.256-0.049-0.354-0.146l-6-6c-0.195-0.195-0.195-0.512,0-0.707s0.512-0.195,0.707,0l5.646,5.646
            l5.646-5.646c0.195-0.195,0.512-0.195,0.707,0s0.195,0.512,0,0.707l-6,6C11.756,19.951,11.628,20,11.5,20z"
            />
          </g>
        </g>
      );
    case (iconTypes.MAXIMIZE):
      return (
        <g>
          <g>
            <path d="M12.5,12c-0.128,0-0.256-0.049-0.354-0.146c-0.195-0.195-0.195-0.512,0-0.707l7-7c0.195-0.195,0.512-0.195,0.707,0
            s0.195,0.512,0,0.707l-7,7C12.756,11.951,12.628,12,12.5,12z"
            />
          </g>
          <g>
            <path d="M19.5,11c-0.276,0-0.5-0.224-0.5-0.5v-6C19,4.224,19.224,4,19.5,4S20,4.224,20,4.5v6C20,10.776,19.776,11,19.5,11z" />
          </g>
          <g>
            <path d="M19.5,5h-6C13.224,5,13,4.776,13,4.5S13.224,4,13.5,4h6C19.776,4,20,4.224,20,4.5S19.776,5,19.5,5z" />
          </g>
          <g>
            <path d="M3.5,21c-0.128,0-0.256-0.049-0.354-0.146c-0.195-0.195-0.195-0.512,0-0.707l7-7c0.195-0.195,0.512-0.195,0.707,0
            s0.195,0.512,0,0.707l-7,7C3.756,20.951,3.628,21,3.5,21z"
            />
          </g>
          <g>
            <path d="M3.5,21C3.224,21,3,20.776,3,20.5v-6C3,14.224,3.224,14,3.5,14S4,14.224,4,14.5v6C4,20.776,3.776,21,3.5,21z" />
          </g>
          <g>
            <path d="M9.5,21h-6C3.224,21,3,20.776,3,20.5S3.224,20,3.5,20h6c0.276,0,0.5,0.224,0.5,0.5S9.776,21,9.5,21z" />
          </g>
          <g>
            <path d="M21.5,24h-20C0.673,24,0,23.327,0,22.5v-20C0,1.673,0.673,1,1.5,1h20C22.327,1,23,1.673,23,2.5v20
            C23,23.327,22.327,24,21.5,24z M1.5,2C1.225,2,1,2.225,1,2.5v20C1,22.775,1.225,23,1.5,23h20c0.275,0,0.5-0.225,0.5-0.5v-20
            C22,2.225,21.775,2,21.5,2H1.5z"
            />
          </g>
        </g>
      );
    case (iconTypes.LINK):
      return (
        <g>
          <g>
            <path d="M16.853,11.991c-1.202,0-2.332-0.468-3.183-1.318l-0.343-0.343c-1.754-1.754-1.754-4.609,0-6.364l2.646-2.646
            c0.85-0.85,1.979-1.318,3.182-1.318s2.332,0.468,3.183,1.318l0,0l0.343,0.343c1.754,1.754,1.754,4.609,0,6.364l-2.646,2.646
            C19.185,11.523,18.055,11.991,16.853,11.991z M19.155,1.001c-0.935,0-1.813,0.364-2.475,1.025l-2.646,2.646
            c-1.364,1.365-1.364,3.585,0,4.95l0.343,0.343c1.322,1.322,3.628,1.323,4.95,0l2.646-2.646c1.364-1.365,1.364-3.585,0-4.95
            l-0.343-0.343C20.97,1.365,20.091,1.001,19.155,1.001z"
            />
          </g>
          <g>
            <path d="M4.845,23.999c-1.202,0-2.332-0.468-3.183-1.318l-0.343-0.343c-1.754-1.754-1.754-4.609,0-6.364l2.646-2.646
            c0.85-0.85,1.979-1.318,3.182-1.318s2.332,0.468,3.183,1.318l0,0l0.343,0.343c1.754,1.754,1.754,4.609,0,6.364l-2.646,2.646
            C7.177,23.531,6.047,23.999,4.845,23.999z M7.147,13.009c-0.935,0-1.813,0.364-2.475,1.025l-2.646,2.646
            c-1.364,1.365-1.364,3.585,0,4.95l0.343,0.343c1.322,1.322,3.628,1.323,4.95,0l2.646-2.646c1.364-1.365,1.364-3.585,0-4.95
            l-0.343-0.343C8.962,13.373,8.083,13.009,7.147,13.009z"
            />
          </g>
          <g>
            <path d="M7.511,16.989c-0.127,0-0.255-0.048-0.352-0.145c-0.196-0.194-0.198-0.511-0.004-0.707l8.917-9
            c0.193-0.196,0.513-0.198,0.707-0.003c0.196,0.194,0.198,0.511,0.004,0.707l-8.917,9C7.769,16.939,7.64,16.989,7.511,16.989z"
            />
          </g>
        </g>
      );
    case (iconTypes.ARROW_LEFT):
      return (
        <g>
          <g>
            <path d="M9.5,21.5c-0.128,0-0.256-0.049-0.354-0.146l-9-9c-0.195-0.195-0.195-0.512,0-0.707l9-9c0.195-0.195,0.512-0.195,0.707,0
			s0.195,0.512,0,0.707L1.207,12l8.646,8.646c0.195,0.195,0.195,0.512,0,0.707C9.756,21.451,9.628,21.5,9.5,21.5z"
            />
          </g>
          <g>
            <path d="M23.5,12.5h-23C0.224,12.5,0,12.276,0,12s0.224-0.5,0.5-0.5h23c0.276,0,0.5,0.224,0.5,0.5S23.776,12.5,23.5,12.5z" />
          </g>
        </g>
      );
    case (iconTypes.APPSTORE):
      return (
        <g>
          <g>
            <path d="M5.396,18.983c-0.177,0-0.35-0.046-0.506-0.138L3.55,18.067c-0.484-0.281-0.651-0.904-0.372-1.389L8.76,6.765
			c0.133-0.238,0.353-0.409,0.617-0.481c0.264-0.072,0.538-0.035,0.773,0.103l1.338,0.775c0.482,0.281,0.649,0.903,0.374,1.389
			L6.28,18.469c-0.138,0.24-0.358,0.41-0.622,0.48C5.571,18.972,5.483,18.983,5.396,18.983z M9.647,7.251l-5.6,9.922l1.345,0.809
			l5.599-9.922c0-0.001,0-0.001,0.001-0.002c0.006-0.011,0.002-0.026-0.006-0.03L9.647,7.251z"
            />
          </g>
          <g>
            <path d="M2.173,22.344c-0.16,0-0.28-0.056-0.342-0.094c-0.116-0.069-0.379-0.283-0.324-0.764l0.232-2.041
			c0.035-0.307,0.197-0.554,0.446-0.679c0.247-0.125,0.542-0.107,0.806,0.046l1.339,0.777l0,0c0.27,0.157,0.43,0.406,0.439,0.686
			c0.01,0.279-0.131,0.539-0.387,0.713l-1.729,1.191C2.472,22.304,2.309,22.344,2.173,22.344z M2.706,19.803l-0.14,1.223
			l1.028-0.708L2.706,19.803z"
            />
          </g>
          <g>
            <path d="M18.641,18.982c-0.208,0-0.452-0.053-0.749-0.204c-0.985-0.5-7.502-15.339-7.499-17.073
			c0.001-0.404,0.213-0.57,0.339-0.634c0.618-0.313,1.103-0.557,9.202,14.98c0.212,0.408,0.352,0.675,0.397,0.759c0,0,0,0,0,0
			c0.167,0.308,0.195,0.641,0.083,0.961c-0.173,0.493-0.647,0.843-1,0.992l-0.105,0.045C19.125,18.891,18.914,18.982,18.641,18.982z
			 M18.41,17.919c0.212,0.101,0.265,0.078,0.498-0.025l0.119-0.051c0.17-0.072,0.39-0.247,0.444-0.401
			c0.016-0.045,0.018-0.086-0.019-0.154l0,0c-0.046-0.085-0.188-0.357-0.405-0.773c-4.03-7.732-6.109-11.384-7.182-13.095
			C13.314,7.465,17.637,16.934,18.41,17.919z"
            />
          </g>
          <g>
            <path d="M21.973,23.012c-0.217,0-0.412-0.141-0.477-0.352c-0.045-0.055-0.335-0.181-0.527-0.264
			c-0.471-0.204-1.056-0.457-1.439-1.003c-0.536-0.761-0.487-1.457,0.145-2.069c0.03-0.029,0.081-0.071,0.117-0.092
			c0.865-0.492,1.447-0.338,1.783-0.12c1.102,0.714,0.94,2.997,0.896,3.448c-0.023,0.237-0.209,0.425-0.446,0.449
			C22.007,23.011,21.99,23.012,21.973,23.012z M20.34,20.072c-0.18,0.176-0.292,0.321,0.008,0.746
			c0.222,0.316,0.626,0.491,1.019,0.661c0.043,0.019,0.086,0.037,0.129,0.056c-0.031-0.693-0.167-1.391-0.465-1.583
			C20.857,19.841,20.569,19.949,20.34,20.072z"
            />
          </g>
          <g>
            <path d="M14,15.012h-4c-0.173,0-0.334-0.09-0.425-0.237s-0.1-0.332-0.022-0.486l2-4c0.17-0.339,0.725-0.339,0.895,0l2,4
			c0.078,0.155,0.069,0.339-0.022,0.486S14.173,15.012,14,15.012z M10.809,14.012h2.382L12,11.63L10.809,14.012z"
            />
          </g>
          <g>
            <path d="M2.5,15.012H1c-0.276,0-0.5-0.224-0.5-0.5v-4c0-0.276,0.224-0.5,0.5-0.5h4c0.182,0,0.349,0.099,0.438,0.258
			c0.088,0.159,0.083,0.353-0.014,0.507l-2.5,4C2.833,14.923,2.672,15.012,2.5,15.012z M1.5,14.012h0.723l1.875-3H1.5V14.012z"
            />
          </g>
          <g>
            <path d="M23,15.012h-2c-0.189,0-0.362-0.107-0.447-0.276l-2-4c-0.078-0.155-0.069-0.339,0.022-0.486s0.252-0.237,0.425-0.237h4
			c0.276,0,0.5,0.224,0.5,0.5v4C23.5,14.789,23.276,15.012,23,15.012z M21.309,14.012H22.5v-3h-2.691L21.309,14.012z"
            />
          </g>
        </g>
      );
    case (iconTypes.GOOGLE_PLAY):
      return (
        <g>
          <g>
            <path d="M3.713,23.015c-0.34,0-0.679-0.091-0.986-0.273c-0.594-0.352-0.949-0.975-0.949-1.666V2.924
            c0-0.691,0.355-1.314,0.949-1.666c0.595-0.352,1.312-0.364,1.917-0.032l16.573,9.076c0.63,0.345,1.006,0.98,1.006,1.698
            s-0.376,1.354-1.006,1.698L4.644,22.774C4.35,22.934,4.031,23.015,3.713,23.015z M3.713,1.987c-0.165,0-0.329,0.044-0.477,0.132
            C2.948,2.289,2.777,2.59,2.777,2.924v18.151c0,0.334,0.171,0.635,0.459,0.806c0.288,0.169,0.634,0.175,0.927,0.016l16.573-9.076
            c0.305-0.167,0.487-0.474,0.487-0.821s-0.182-0.654-0.487-0.821L4.163,2.103C4.022,2.025,3.867,1.987,3.713,1.987z"
            />
          </g>
          <g>
            <path d="M16.777,15.5c-0.125,0-0.25-0.046-0.347-0.14l-13.5-13C2.731,2.168,2.725,1.852,2.917,1.653
            c0.192-0.199,0.51-0.204,0.707-0.014l13.5,13c0.199,0.192,0.205,0.508,0.014,0.707C17.039,15.449,16.908,15.5,16.777,15.5z"
            />
          </g>
          <g>
            <path d="M3.277,22.5c-0.131,0-0.262-0.051-0.36-0.153c-0.191-0.199-0.186-0.515,0.014-0.707l13.5-13
            c0.198-0.19,0.516-0.186,0.707,0.014c0.191,0.199,0.186,0.515-0.014,0.707l-13.5,13C3.527,22.454,3.402,22.5,3.277,22.5z"
            />
          </g>
        </g>
      );
    case (iconTypes.REFRESH):
      return (
        <g>
          <g>
            <path d="M9.5,6C9.372,6,9.244,5.951,9.146,5.854c-0.195-0.195-0.195-0.512,0-0.707L10.793,3.5L9.146,1.854
            c-0.195-0.195-0.195-0.512,0-0.707s0.512-0.195,0.707,0l2,2c0.195,0.195,0.195,0.512,0,0.707l-2,2C9.756,5.951,9.628,6,9.5,6z"
            />
          </g>
          <g>
            <path d="M13.5,24c-0.128,0-0.256-0.049-0.354-0.146l-2-2c-0.195-0.195-0.195-0.512,0-0.707l2-2c0.195-0.195,0.512-0.195,0.707,0
            s0.195,0.512,0,0.707L12.207,21.5l1.646,1.646c0.195,0.195,0.195,0.512,0,0.707C13.756,23.951,13.628,24,13.5,24z"
            />
          </g>
          <g>
            <path d="M9.522,21.775c-0.036,0-0.073-0.004-0.11-0.012C5.048,20.783,2,16.974,2,12.5C2,7.262,6.262,3,11.5,3
            C11.776,3,12,3.224,12,3.5S11.776,4,11.5,4C6.813,4,3,7.813,3,12.5c0,4.002,2.727,7.41,6.631,8.287
            c0.27,0.061,0.439,0.328,0.378,0.597C9.957,21.617,9.75,21.775,9.522,21.775z"
            />
          </g>
          <g>
            <path d="M11.5,22c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5c4.687,0,8.5-3.813,8.5-8.5c0-4.002-2.727-7.41-6.631-8.287
            c-0.27-0.061-0.439-0.328-0.378-0.597c0.061-0.27,0.329-0.44,0.597-0.378C17.952,4.217,21,8.026,21,12.5
            C21,17.738,16.738,22,11.5,22z"
            />
          </g>
        </g>
      );
    case (iconTypes.QUESTION_MARK):
      return (
        <g>
          <g>
            <path d="M11.5,24C5.159,24,0,18.841,0,12.5S5.159,1,11.5,1S23,6.159,23,12.5S17.841,24,11.5,24z M11.5,2C5.71,2,1,6.71,1,12.5
            S5.71,23,11.5,23S22,18.29,22,12.5S17.29,2,11.5,2z"
            />
          </g>
          <g>
            <path d="M11.5,17c-0.276,0-0.5-0.224-0.5-0.5v-0.757c0-0.935,0.364-1.813,1.025-2.475l1.242-1.242
            C13.74,11.553,14,10.925,14,10.257V9.5C14,8.122,12.879,7,11.5,7S9,8.122,9,9.5v1C9,10.776,8.776,11,8.5,11S8,10.776,8,10.5v-1
            C8,7.57,9.57,6,11.5,6S15,7.57,15,9.5v0.757c0,0.935-0.364,1.813-1.025,2.475l-1.242,1.242C12.26,14.447,12,15.075,12,15.743
            V16.5C12,16.776,11.776,17,11.5,17z"
            />
          </g>
          <g>
            <path d="M11.5,20c-0.552,0-1-0.449-1-1s0.448-1,1-1s1,0.449,1,1S12.052,20,11.5,20z M11.5,18.999C11.5,19,11.5,19,11.5,18.999
            L12,19L11.5,18.999z"
            />
          </g>
        </g>
      );
    case (iconTypes.HOME):
      return (
        <g>
          <path d="M19.5,24h-5c-0.276,0-0.5-0.224-0.5-0.5V16H9v7.5C9,23.776,8.776,24,8.5,24h-5C3.224,24,3,23.776,3,23.5V13H0.5
			c-0.198,0-0.378-0.117-0.458-0.299c-0.08-0.182-0.045-0.393,0.089-0.539l11-12c0.189-0.207,0.548-0.207,0.737,0l11,12
			c0.134,0.146,0.169,0.357,0.089,0.539C22.878,12.883,22.698,13,22.5,13H20v10.5C20,23.776,19.776,24,19.5,24z M15,23h4V12.5
			c0-0.276,0.224-0.5,0.5-0.5h1.863L11.5,1.24L1.637,12H3.5C3.776,12,4,12.224,4,12.5V23h4v-7.5C8,15.224,8.224,15,8.5,15h6
			c0.276,0,0.5,0.224,0.5,0.5V23z"
          />
        </g>
      );
    case (iconTypes.INSTAGRAM):
      return (
        <g>
          <g>
            <path d="M20.5,24.002h-17c-1.93,0-3.5-1.57-3.5-3.5v-17c0-1.93,1.57-3.5,3.5-3.5h17c1.93,0,3.5,1.57,3.5,3.5v17
			C24,22.432,22.43,24.002,20.5,24.002z M3.5,1.002c-1.379,0-2.5,1.122-2.5,2.5v17c0,1.378,1.121,2.5,2.5,2.5h17
			c1.379,0,2.5-1.122,2.5-2.5v-17c0-1.378-1.121-2.5-2.5-2.5H3.5z"
            />
          </g>
          <g>
            <path d="M8.5,8.002h-8c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h8c0.276,0,0.5,0.224,0.5,0.5S8.776,8.002,8.5,8.002z" />
          </g>
          <g>
            <path d="M23.5,8.002h-8c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h8c0.276,0,0.5,0.224,0.5,0.5S23.776,8.002,23.5,8.002z" />
          </g>
          <g>
            <path d="M20.5,6.002h-3c-0.276,0-0.5-0.224-0.5-0.5v-3c0-0.276,0.224-0.5,0.5-0.5h3c0.276,0,0.5,0.224,0.5,0.5v3
			C21,5.778,20.776,6.002,20.5,6.002z M18,5.002h2v-2h-2V5.002z"
            />
          </g>
          <g>
            <path d="M2.5,5.002c-0.276,0-0.5-0.224-0.5-0.5v-3.5c0-0.276,0.224-0.5,0.5-0.5S3,0.726,3,1.002v3.5
			C3,4.778,2.776,5.002,2.5,5.002z"
            />
          </g>
          <g>
            <path d="M4.5,5.002c-0.276,0-0.5-0.224-0.5-0.5v-4c0-0.276,0.224-0.5,0.5-0.5S5,0.226,5,0.502v4C5,4.778,4.776,5.002,4.5,5.002z" />
          </g>
          <g>
            <path d="M6.5,5.002c-0.276,0-0.5-0.224-0.5-0.5v-4c0-0.276,0.224-0.5,0.5-0.5S7,0.226,7,0.502v4C7,4.778,6.776,5.002,6.5,5.002z" />
          </g>
          <g>
            <path d="M12,18.002c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S15.309,18.002,12,18.002z M12,7.002c-2.757,0-5,2.243-5,5
			s2.243,5,5,5s5-2.243,5-5S14.757,7.002,12,7.002z"
            />
          </g>
          <g>
            <path d="M12,15.002c-1.654,0-3-1.346-3-3s1.346-3,3-3s3,1.346,3,3S13.654,15.002,12,15.002z M12,10.002c-1.103,0-2,0.897-2,2
			s0.897,2,2,2s2-0.897,2-2S13.103,10.002,12,10.002z"
            />
          </g>
        </g>
      );
    case (iconTypes.FACEBOOK):
      return (
        <g>
          <path d="M21.5,24.003h-5c-0.276,0-0.5-0.224-0.5-0.5v-9c0-0.276,0.224-0.5,0.5-0.5h2.64l0.667-2H16.5c-0.276,0-0.5-0.224-0.5-0.5
          v-3c0-1.378,1.122-2.5,2.5-2.5H20v-2h-1.5c-2.481,0-4.5,2.019-4.5,4.5v3c0,0.276-0.224,0.5-0.5,0.5H11v2h2.5
          c0.276,0,0.5,0.224,0.5,0.5v9c0,0.276-0.224,0.5-0.5,0.5h-11c-1.378,0-2.5-1.122-2.5-2.5v-19c0-1.378,1.122-2.5,2.5-2.5h19
          c1.378,0,2.5,1.122,2.5,2.5v19C24,22.881,22.878,24.003,21.5,24.003z M17,23.003h4.5c0.827,0,1.5-0.673,1.5-1.5v-19
          c0-0.827-0.673-1.5-1.5-1.5h-19c-0.827,0-1.5,0.673-1.5,1.5v19c0,0.827,0.673,1.5,1.5,1.5H13v-8h-2.5c-0.276,0-0.5-0.224-0.5-0.5
          v-3c0-0.276,0.224-0.5,0.5-0.5H13v-2.5c0-3.033,2.467-5.5,5.5-5.5h2c0.276,0,0.5,0.224,0.5,0.5v3c0,0.276-0.224,0.5-0.5,0.5h-2
          c-0.827,0-1.5,0.673-1.5,1.5v2.5h3.5c0.161,0,0.312,0.077,0.406,0.208c0.094,0.13,0.12,0.298,0.068,0.451l-1,3
          c-0.068,0.204-0.259,0.342-0.474,0.342H17V23.003z"
          />
        </g>
      );
    case (iconTypes.TWITTER):
      return (
        <g>
          <g>
            <path d="M8.145,21.449c-2.15,0-4.638-0.552-7.39-2.097c-0.208-0.117-0.304-0.364-0.23-0.59s0.297-0.375,0.533-0.342
            c1.814,0.208,3.795-0.217,5.275-1.086c-1.695-0.398-3.065-1.499-3.788-3.098c-0.081-0.179-0.05-0.389,0.08-0.536
            c0.13-0.148,0.334-0.205,0.522-0.148c0.218,0.067,0.435,0.113,0.644,0.138c-1.287-0.768-2.665-2.238-2.665-4.441
            c0-0.193,0.111-0.369,0.285-0.452C1.585,8.715,1.792,8.738,1.94,8.86c0.16,0.13,0.331,0.239,0.508,0.325
            c-0.368-0.5-0.687-1.117-0.869-1.817C1.247,6.086,1.44,4.786,2.136,3.612C2.22,3.47,2.368,3.38,2.532,3.368
            c0.156-0.01,0.322,0.058,0.425,0.187c1.472,1.839,4.088,4.061,8.294,4.466c-0.078-1.331,0.322-3.512,2.16-4.585
            c2.261-1.32,4.436-1.15,6.305,0.487c0.828-0.184,2.135-0.752,2.406-0.941c0.172-0.12,0.401-0.121,0.573,0
            c0.172,0.121,0.251,0.335,0.197,0.538c-0.125,0.471-0.408,0.995-0.76,1.463c0.279-0.083,0.514-0.167,0.639-0.231
            c0.2-0.103,0.445-0.06,0.598,0.106s0.174,0.415,0.054,0.605c-0.588,0.93-1.539,1.796-2.174,2.266
            c0.343,4.225-2.126,8.946-6.089,11.577C13.69,20.281,11.274,21.449,8.145,21.449z M3.15,19.406
            c5.351,2.195,9.403,0.428,11.456-0.935c3.756-2.494,6.067-6.988,5.62-10.929c-0.021-0.188,0.064-0.371,0.222-0.474
            c0.269-0.177,0.68-0.502,1.094-0.905c-0.407,0.088-0.802,0.147-1.089,0.148c-0.276-0.033-0.447-0.169-0.492-0.405
            c-0.046-0.236,0.083-0.471,0.306-0.559c0.283-0.113,0.665-0.454,0.995-0.872c-0.566,0.219-1.178,0.413-1.628,0.478
            c-0.149,0.023-0.302-0.027-0.413-0.131c-1.578-1.482-3.362-1.658-5.306-0.524c-1.745,1.02-1.784,3.397-1.6,4.148
            c0.038,0.154,0,0.316-0.101,0.438c-0.101,0.122-0.255,0.187-0.412,0.181C7.331,8.829,4.427,6.719,2.68,4.773
            C2.322,5.704,2.398,6.539,2.548,7.117C2.842,8.25,3.582,9.119,4.144,9.447c0.18,0.104,0.276,0.31,0.242,0.514
            c-0.034,0.205-0.191,0.368-0.395,0.408c-0.569,0.113-1.182,0.044-1.752-0.185c0.521,2.085,2.689,3.067,3.299,3.135
            c0.199,0.022,0.365,0.16,0.424,0.351c0.058,0.191-0.003,0.399-0.156,0.528c-0.437,0.369-1.091,0.547-1.828,0.51
            c0.836,1.114,2.132,1.754,3.681,1.788c0.205,0.005,0.387,0.134,0.458,0.327c0.071,0.192,0.018,0.409-0.135,0.545
            C6.754,18.468,4.992,19.191,3.15,19.406z"
            />
          </g>
        </g>
      );
    case (iconTypes.COPYRIGHTS):
      return (
        <g>
          <path d="M12,0.5C5.659,0.5,0.5,5.659,0.5,12S5.659,23.5,12,23.5S23.5,18.341,23.5,12S18.341,0.5,12,0.5z M12,22.5
		C6.21,22.5,1.5,17.79,1.5,12S6.21,1.5,12,1.5S22.5,6.21,22.5,12S17.79,22.5,12,22.5z"
          />
          <path d="M12.5,6.5h-1c-1.654,0-3,1.346-3,3v5c0,1.654,1.346,3,3,3h1c1.654,0,3-1.346,3-3V14c0-0.276-0.224-0.5-0.5-0.5
		s-0.5,0.224-0.5,0.5v0.5c0,1.103-0.897,2-2,2h-1c-1.103,0-2-0.897-2-2v-5c0-1.103,0.897-2,2-2h1c1.103,0,2,0.897,2,2V10
		c0,0.276,0.224,0.5,0.5,0.5s0.5-0.224,0.5-0.5V9.5C15.5,7.846,14.154,6.5,12.5,6.5z"
          />
        </g>
      );
    case (iconTypes.FACEBOOK_SOLID):
      return (
        <path d="M21.5,0h-19C1.122,0,0,1.122,0,2.5v19C0,22.878,1.122,24,2.5,24h11c0.276,0,0.5-0.224,0.5-0.5v-9c0-0.276-0.224-0.5-0.5-0.5
        H11v-2h2.5c0.276,0,0.5-0.224,0.5-0.5v-3C14,6.019,16.019,4,18.5,4H20v2h-1.5C17.122,6,16,7.122,16,8.5v3c0,0.276,0.224,0.5,0.5,0.5
        h3.306l-0.667,2H16.5c-0.276,0-0.5,0.224-0.5,0.5v9c0,0.276,0.224,0.5,0.5,0.5h5c1.378,0,2.5-1.122,2.5-2.5v-19
        C24,1.122,22.878,0,21.5,0z"
        />
      );
    case (iconTypes.INSTAGRAM_SOLID):
      return (
        <g>
          <path d="M17.5,6h3C20.776,6,21,5.776,21,5.5v-3C21,2.224,20.776,2,20.5,2h-3C17.224,2,17,2.224,17,2.5v3C17,5.776,17.224,6,17.5,6z
          "
          />
          <path d="M12,18c3.309,0,6-2.691,6-6s-2.691-6-6-6s-6,2.691-6,6S8.691,18,12,18z M12,10c1.103,0,2,0.897,2,2s-0.897,2-2,2
          s-2-0.897-2-2S10.897,10,12,10z"
          />
          <path d="M20.5,0h-17C1.57,0,0,1.57,0,3.5v5v12C0,22.43,1.57,24,3.5,24h17c1.93,0,3.5-1.57,3.5-3.5v-12v-5C24,1.57,22.43,0,20.5,0z
           M2,1.513V4.5C2,4.776,2.224,5,2.5,5S3,4.776,3,4.5V1.051C3.162,1.018,3.329,1,3.5,1H4v3.5C4,4.776,4.224,5,4.5,5S5,4.776,5,4.5V1
          h1v3.5C6,4.776,6.224,5,6.5,5S7,4.776,7,4.5V1h13.5C21.879,1,23,2.122,23,3.5V8h-4.372c-0.176,0-0.338,0.092-0.429,0.242
          c-0.09,0.15-0.095,0.337-0.013,0.492C18.727,9.752,19,10.851,19,12c0,3.86-3.141,7-7,7s-7-3.14-7-7
          c0-1.149,0.273-2.248,0.813-3.266c0.082-0.155,0.077-0.342-0.013-0.492C5.71,8.092,5.548,8,5.372,8H1V3.5
          C1,2.686,1.396,1.969,2,1.513z"
          />
        </g>
      );
    case (iconTypes.TWITTER_SOLID):
      return (
        <path d="M23.369,4.855c-0.152-0.167-0.397-0.209-0.598-0.106c-0.125,0.064-0.36,0.148-0.639,0.231
	c0.352-0.468,0.635-0.992,0.76-1.463c0.054-0.203-0.025-0.417-0.197-0.538c-0.172-0.12-0.401-0.12-0.573,0
	c-0.271,0.189-1.579,0.757-2.406,0.941c-1.869-1.637-4.044-1.807-6.305-0.487c-1.838,1.073-2.238,3.255-2.16,4.585
	C7.044,7.613,4.429,5.391,2.957,3.552C2.854,3.423,2.688,3.355,2.532,3.365C2.368,3.377,2.22,3.468,2.136,3.609
	C1.44,4.784,1.247,6.083,1.58,7.366c0.182,0.7,0.5,1.317,0.869,1.817C2.272,9.096,2.101,8.987,1.94,8.857
	C1.792,8.735,1.585,8.712,1.41,8.793C1.236,8.876,1.125,9.052,1.125,9.245c0,2.203,1.378,3.673,2.665,4.441
	c-0.208-0.025-0.425-0.071-0.644-0.138c-0.188-0.057-0.392,0-0.522,0.148c-0.13,0.147-0.161,0.357-0.08,0.536
	c0.723,1.599,2.093,2.699,3.788,3.098c-1.48,0.869-3.461,1.295-5.275,1.086c-0.235-0.033-0.459,0.116-0.533,0.342
	s0.023,0.474,0.23,0.59c2.752,1.545,5.24,2.097,7.39,2.097c3.129,0,5.544-1.168,7.014-2.145c3.963-2.63,6.433-7.352,6.089-11.577
	c0.635-0.469,1.585-1.335,2.174-2.266C23.543,5.269,23.521,5.021,23.369,4.855z"
        />
      );
    case (iconTypes.GOOGLE_PLAY_SOLID):
      return (
        <g>
          <path d="M12.786,12.316c-0.001-0.134-0.056-0.262-0.152-0.355L2.705,2.352c-0.124-0.12-0.3-0.166-0.466-0.126
		C2.072,2.267,1.937,2.39,1.883,2.553C1.812,2.767,1.777,2.967,1.777,3.167v18.151c0,0.376,0.106,0.729,0.314,1.049
		c0.082,0.126,0.217,0.209,0.367,0.225c0.018,0.002,0.035,0.003,0.052,0.003c0.132,0,0.259-0.052,0.354-0.146l9.775-9.775
		C12.734,12.578,12.787,12.449,12.786,12.316z"
          />
          <path d="M13.352,11.265c0.097,0.094,0.222,0.141,0.348,0.141c0.128,0,0.256-0.049,0.354-0.146l2.525-2.525
		c0.111-0.111,0.164-0.269,0.142-0.424c-0.022-0.156-0.117-0.292-0.255-0.368L4.624,1.458C4.404,1.347,4.135,1.416,3.992,1.616
		C3.85,1.817,3.876,2.093,4.053,2.265L13.352,11.265z"
          />
          <path d="M21.217,10.544l-2.924-1.602c-0.195-0.106-0.437-0.071-0.594,0.085l-2.915,2.915c-0.095,0.095-0.147,0.224-0.146,0.357
		c0.001,0.134,0.056,0.262,0.152,0.355l2.906,2.813c0.096,0.092,0.221,0.141,0.348,0.141c0.082,0,0.165-0.02,0.24-0.062l2.933-1.606
		c0.63-0.345,1.006-0.98,1.006-1.698S21.847,10.889,21.217,10.544z"
          />
          <path d="M14.071,13.351c-0.196-0.189-0.508-0.188-0.701,0.006l-8.32,8.32c-0.176,0.176-0.196,0.454-0.046,0.653
		c0.097,0.13,0.247,0.2,0.4,0.2c0.082,0,0.164-0.02,0.24-0.062l10.804-5.916c0.139-0.076,0.234-0.214,0.255-0.372
		c0.021-0.158-0.034-0.316-0.148-0.426L14.071,13.351z"
          />
        </g>
      );
    case (iconTypes.APPSTORE_SOLID):
      return (
        <g>
          <path d="M11.488,7.162L10.15,6.387C9.916,6.249,9.642,6.212,9.377,6.284C9.113,6.356,8.894,6.527,8.76,6.765l-5.582,9.913
		c-0.279,0.485-0.112,1.108,0.372,1.389l1.339,0.778c0.156,0.091,0.33,0.138,0.506,0.138c0.087,0,0.176-0.011,0.263-0.035
		c0.264-0.07,0.484-0.24,0.622-0.48l5.582-9.917C12.138,8.065,11.971,7.443,11.488,7.162z"
          />
          <path d="M4.331,19.589l-1.339-0.777c-0.264-0.153-0.559-0.171-0.806-0.046c-0.249,0.125-0.411,0.373-0.446,0.679l-0.232,2.041
		c-0.055,0.481,0.208,0.695,0.324,0.764c0.063,0.038,0.182,0.094,0.342,0.094c0.135,0,0.299-0.04,0.481-0.165l1.729-1.191
		c0.256-0.174,0.397-0.434,0.387-0.713C4.761,19.996,4.601,19.746,4.331,19.589z"
          />
          <path d="M19.414,18.765c0.354-0.149,0.828-0.499,1-0.992c0.112-0.32,0.083-0.653-0.083-0.961c0,0,0,0,0,0
		c-0.045-0.083-0.185-0.351-0.397-0.759C11.835,0.515,11.35,0.759,10.732,1.072c-0.126,0.063-0.338,0.23-0.339,0.634
		c-0.004,1.734,6.513,16.573,7.499,17.073c0.297,0.151,0.542,0.204,0.749,0.204c0.273,0,0.484-0.092,0.668-0.172L19.414,18.765z"
          />
          <path d="M21.574,19.113c-0.336-0.218-0.918-0.372-1.783,0.12c-0.037,0.021-0.087,0.063-0.117,0.092
		c-0.632,0.612-0.681,1.308-0.145,2.069c0.383,0.546,0.969,0.8,1.439,1.003c0.192,0.083,0.481,0.208,0.527,0.264
		c0.065,0.211,0.26,0.352,0.477,0.352c0.017,0,0.034-0.001,0.052-0.002c0.237-0.024,0.423-0.212,0.446-0.449
		C22.515,22.11,22.676,19.827,21.574,19.113z"
          />
          <path d="M12.447,10.289c-0.17-0.339-0.725-0.339-0.895,0l-2,4c-0.078,0.155-0.069,0.339,0.022,0.486S9.827,15.012,10,15.012h4
		c0.173,0,0.334-0.09,0.425-0.237s0.1-0.332,0.022-0.486L12.447,10.289z"
          />
          <path d="M2.924,14.777l2.5-4c0.097-0.154,0.102-0.349,0.014-0.507C5.349,10.111,5.182,10.012,5,10.012H1
		c-0.276,0-0.5,0.224-0.5,0.5v4c0,0.276,0.224,0.5,0.5,0.5h1.5C2.672,15.012,2.833,14.923,2.924,14.777z"
          />
          <path d="M23,10.012h-4c-0.173,0-0.334,0.09-0.425,0.237s-0.1,0.332-0.022,0.486l2,4c0.085,0.169,0.258,0.276,0.447,0.276h2
		c0.276,0,0.5-0.224,0.5-0.5v-4C23.5,10.236,23.276,10.012,23,10.012z"
          />
        </g>
      );
    case (iconTypes.ARROW_RIGHT):
      return (
        <g>
          <g>
            <path d="M14.5,21.5c-0.128,0-0.256-0.049-0.354-0.146c-0.195-0.195-0.195-0.512,0-0.707L22.793,12l-8.646-8.646
			c-0.195-0.195-0.195-0.512,0-0.707s0.512-0.195,0.707,0l9,9c0.195,0.195,0.195,0.512,0,0.707l-9,9
			C14.756,21.451,14.628,21.5,14.5,21.5z"
            />
          </g>
          <g>
            <path d="M23.5,12.5h-23C0.224,12.5,0,12.276,0,12s0.224-0.5,0.5-0.5h23c0.276,0,0.5,0.224,0.5,0.5S23.776,12.5,23.5,12.5z" />
          </g>
        </g>
      );
    case (iconTypes.CLOSE):
      return (
        <g>
          <g>
            <path d="M20,20.5c-0.128,0-0.256-0.049-0.354-0.146l-17-17c-0.195-0.195-0.195-0.512,0-0.707s0.512-0.195,0.707,0l17,17
            c0.195,0.195,0.195,0.512,0,0.707C20.256,20.451,20.128,20.5,20,20.5z"
            />
          </g>
          <g>
            <path d="M3,20.5c-0.128,0-0.256-0.049-0.354-0.146c-0.195-0.195-0.195-0.512,0-0.707l17-17c0.195-0.195,0.512-0.195,0.707,0
            s0.195,0.512,0,0.707l-17,17C3.256,20.451,3.128,20.5,3,20.5z"
            />
          </g>
        </g>
      );
    case (iconTypes.EYE):
      return (
        <g>
          <g>
            <path d="M12,19c-6.161,0-11.651-6.404-11.882-6.677c-0.157-0.187-0.157-0.46,0-0.646C0.349,11.404,5.839,5,12,5
            c7.18,0,11.722,6.441,11.911,6.716c0.129,0.186,0.116,0.435-0.029,0.607C23.651,12.596,18.161,19,12,19z M1.172,12
            C2.365,13.29,7.061,18,12,18c4.981,0,9.704-4.778,10.854-6.027C21.858,10.703,17.779,6,12,6C7.054,6,2.363,10.709,1.172,12z"
            />
          </g>
          <g>
            <path d="M12,16c-2.206,0-4-1.794-4-4s1.794-4,4-4s4,1.794,4,4S14.206,16,12,16z M12,9c-1.654,0-3,1.346-3,3s1.346,3,3,3
            s3-1.346,3-3S13.654,9,12,9z"
            />
          </g>
        </g>
      );
    case (iconTypes.EYE_CLOSED):
      return (
        <g>
          <g>
            <g>
              <path d="M12,16c-1.069,0-2.074-0.416-2.828-1.172c-0.195-0.195-0.195-0.513,0-0.707c0.195-0.195,0.512-0.195,0.707,0
              C10.445,14.688,11.198,15,12,15c1.654,0,3-1.346,3-3c0-0.802-0.313-1.555-0.879-2.121c-0.195-0.194-0.195-0.512,0-0.707
              s0.512-0.195,0.707,0C15.584,9.926,16,10.931,16,12C16,14.206,14.206,16,12,16z"
              />
            </g>
            <g>
              <path d="M8.561,13.11c-0.237,0-0.448-0.171-0.491-0.413C8.029,12.47,8,12.238,8,12c0-2.206,1.794-4,4-4
              c0.238,0,0.47,0.029,0.697,0.069c0.271,0.048,0.453,0.308,0.405,0.579s-0.313,0.459-0.579,0.405C12.353,9.023,12.18,9,12,9
              c-1.654,0-3,1.346-3,3c0,0.18,0.023,0.353,0.054,0.523c0.048,0.271-0.134,0.531-0.405,0.579
              C8.619,13.108,8.59,13.11,8.561,13.11z"
              />
            </g>
          </g>
          <g>
            <g>
              <path d="M12,19c-1.829,0-3.667-0.438-5.462-1.305c-0.249-0.12-0.354-0.419-0.233-0.667c0.12-0.249,0.421-0.354,0.667-0.233
              C8.631,17.595,10.322,18,12,18c4.945,0,9.636-4.709,10.828-6c-0.785-0.848-3.081-3.17-5.955-4.658
              c-0.246-0.127-0.342-0.429-0.215-0.674c0.127-0.246,0.432-0.342,0.674-0.215c3.731,1.933,6.436,5.09,6.549,5.224
              c0.158,0.187,0.159,0.46,0.001,0.646C23.651,12.596,18.161,19,12,19z"
              />
            </g>
            <g>
              <path d="M4.953,16.719c-0.096,0-0.191-0.027-0.276-0.084c-2.865-1.903-4.518-4.25-4.587-4.349
              c-0.13-0.187-0.118-0.437,0.028-0.609C0.349,11.404,5.839,5,12,5c1.039,0,2.143,0.193,3.281,0.576
              c0.262,0.088,0.402,0.371,0.314,0.633c-0.087,0.263-0.371,0.408-0.633,0.314C13.928,6.176,12.931,6,12,6
              c-4.981,0-9.703,4.777-10.854,6.027c0.539,0.687,1.979,2.376,4.083,3.775c0.23,0.152,0.293,0.463,0.14,0.692
              C5.273,16.641,5.114,16.719,4.953,16.719z"
              />
            </g>
          </g>
          <g>
            <path d="M3.5,21c-0.128,0-0.256-0.049-0.354-0.146c-0.195-0.195-0.195-0.512,0-0.707l17-17c0.195-0.195,0.512-0.195,0.707,0
            s0.195,0.512,0,0.707l-17,17C3.756,20.951,3.628,21,3.5,21z"
            />
          </g>
        </g>
      );
    case (iconTypes.CHECK):
      return (
        <g>
          <path d="M8.5,20c-0.128,0-0.256-0.049-0.354-0.146l-8-8c-0.195-0.195-0.195-0.512,0-0.707s0.512-0.195,0.707,0L8.5,18.793
          L23.146,4.146c0.195-0.195,0.512-0.195,0.707,0s0.195,0.512,0,0.707l-15,15C8.756,19.951,8.628,20,8.5,20z"
          />
        </g>
      );
    case (iconTypes.ARROW_DOWN_DECOR):
      return (
<g>
	<path d="M12,22c-0.179,0-0.344-0.095-0.433-0.25l-11-19c-0.09-0.155-0.09-0.345-0.001-0.5C0.656,2.095,0.821,2,1,2h22
		c0.179,0,0.344,0.095,0.434,0.25c0.089,0.155,0.089,0.345-0.001,0.5l-11,19C12.344,21.905,12.179,22,12,22z M1.867,3L12,20.502
		L22.133,3H1.867z"/>
</g>
      );
    case (iconTypes.GEAR):
      return (
<g>
	<g>
		<path d="M15.883,23.207c-0.328,0-0.652-0.082-0.948-0.245c-0.521-0.287-0.875-0.788-0.973-1.375
			c-0.083-0.495-0.54-0.824-1.075-0.771c-0.584,0.062-1.192,0.062-1.775,0c-0.53-0.055-0.992,0.276-1.075,0.771
			c-0.098,0.587-0.452,1.087-0.973,1.375c-0.531,0.292-1.157,0.325-1.722,0.087c-0.919-0.387-1.789-0.889-2.587-1.494
			c-0.484-0.368-0.77-0.925-0.782-1.53c-0.012-0.595,0.245-1.154,0.705-1.532c0.386-0.318,0.444-0.882,0.134-1.312
			c-0.348-0.484-0.647-1.005-0.891-1.549c-0.213-0.475-0.741-0.714-1.2-0.539c-0.57,0.212-1.179,0.156-1.688-0.15
			c-0.517-0.312-0.858-0.835-0.937-1.438C0.033,13.008,0,12.501,0,12s0.033-1.008,0.097-1.504c0.078-0.603,0.419-1.127,0.937-1.438
			c0.51-0.306,1.121-0.361,1.677-0.154c0.471,0.178,1-0.06,1.211-0.535c0.244-0.544,0.543-1.065,0.892-1.549
			c0.309-0.43,0.251-0.994-0.134-1.311C4.219,5.128,3.962,4.57,3.974,3.974C3.986,3.37,4.271,2.813,4.755,2.445
			C5.553,1.84,6.423,1.338,7.343,0.951c0.563-0.238,1.191-0.206,1.722,0.087c0.521,0.287,0.875,0.788,0.973,1.375
			c0.083,0.495,0.546,0.827,1.075,0.771c0.583-0.062,1.191-0.062,1.775,0c0.534,0.055,0.992-0.276,1.075-0.771
			c0.098-0.587,0.452-1.087,0.973-1.375c0.53-0.292,1.157-0.325,1.722-0.087c0.919,0.387,1.789,0.889,2.587,1.494
			c0.484,0.368,0.77,0.925,0.782,1.53c0.012,0.595-0.245,1.154-0.705,1.532c-0.386,0.318-0.444,0.882-0.134,1.312
			c0.348,0.484,0.647,1.005,0.891,1.549c0.212,0.474,0.74,0.713,1.2,0.539c0.569-0.212,1.179-0.157,1.688,0.15
			c0.517,0.312,0.858,0.835,0.937,1.438C23.967,10.997,24,11.502,24,12s-0.033,1.003-0.097,1.504
			c-0.078,0.604-0.419,1.127-0.937,1.439c-0.509,0.306-1.119,0.363-1.677,0.154l-0.011-0.004c-0.458-0.173-0.988,0.064-1.2,0.539
			c-0.244,0.544-0.543,1.065-0.892,1.549c-0.309,0.43-0.251,0.994,0.134,1.311c0.46,0.379,0.717,0.938,0.705,1.534
			c-0.012,0.604-0.297,1.162-0.782,1.529c-0.798,0.604-1.668,1.107-2.587,1.494C16.407,23.154,16.145,23.207,15.883,23.207z
			 M13.009,19.811c0.967,0,1.781,0.662,1.94,1.613c0.047,0.283,0.218,0.524,0.469,0.663c0.26,0.143,0.572,0.159,0.851,0.041
			c0.843-0.354,1.641-0.815,2.371-1.369c0.24-0.182,0.38-0.456,0.386-0.753c0.006-0.288-0.118-0.558-0.34-0.741
			c-0.798-0.656-0.932-1.803-0.311-2.667c0.309-0.429,0.575-0.892,0.791-1.374c0.427-0.954,1.504-1.423,2.458-1.07l0.006,0.002
			c0.28,0.105,0.576,0.079,0.821-0.07c0.254-0.153,0.422-0.412,0.46-0.709C22.97,12.918,23,12.455,23,12s-0.03-0.918-0.089-1.377
			c-0.038-0.297-0.206-0.556-0.46-0.709c-0.246-0.148-0.541-0.175-0.809-0.074c-0.966,0.361-2.048-0.107-2.476-1.063
			c-0.216-0.482-0.481-0.944-0.79-1.374c-0.621-0.864-0.488-2.011,0.31-2.667c0.223-0.183,0.347-0.453,0.341-0.741
			c-0.006-0.296-0.146-0.571-0.386-0.752c-0.73-0.554-1.528-1.014-2.371-1.369c-0.281-0.119-0.591-0.104-0.852,0.041
			c-0.251,0.138-0.422,0.38-0.469,0.663c-0.171,1.024-1.108,1.714-2.166,1.601c-0.516-0.054-1.052-0.054-1.566,0
			c-1.064,0.114-1.995-0.577-2.166-1.601C9.004,2.294,8.833,2.052,8.582,1.914C8.321,1.77,8.01,1.755,7.731,1.873
			C6.888,2.228,6.09,2.688,5.36,3.242c-0.24,0.182-0.38,0.456-0.386,0.753c-0.006,0.288,0.118,0.558,0.34,0.741
			c0.798,0.656,0.932,1.803,0.311,2.667C5.316,7.832,5.05,8.294,4.834,8.776C4.406,9.733,3.325,10.2,2.37,9.844
			c-0.279-0.105-0.576-0.078-0.82,0.07c-0.254,0.153-0.422,0.412-0.46,0.709C1.03,11.078,1,11.542,1,12s0.03,0.922,0.089,1.376
			c0.038,0.298,0.206,0.557,0.46,0.709c0.245,0.149,0.542,0.174,0.809,0.074c0.967-0.36,2.048,0.107,2.476,1.063
			c0.216,0.482,0.481,0.944,0.79,1.374c0.621,0.864,0.488,2.011-0.31,2.667c-0.223,0.183-0.347,0.453-0.341,0.741
			c0.006,0.296,0.146,0.571,0.386,0.752c0.73,0.554,1.528,1.014,2.371,1.369c0.28,0.118,0.591,0.103,0.852-0.041
			c0.251-0.138,0.422-0.38,0.469-0.663c0.171-1.024,1.102-1.722,2.166-1.601c0.515,0.054,1.051,0.054,1.566,0
			C12.859,19.814,12.935,19.811,13.009,19.811z"/>
	</g>
	<g>
		<path d="M12,17c-2.757,0-5-2.243-5-5s2.243-5,5-5s5,2.243,5,5S14.757,17,12,17z M12,8c-2.206,0-4,1.794-4,4s1.794,4,4,4
			s4-1.794,4-4S14.206,8,12,8z"/>
	</g>
</g>
      );
    case (iconTypes.HOOK):
      return (
<path d="M18,0c-0.276,0-0.5,0.224-0.5,0.5v6.116c-0.19-0.162-0.403-0.314-0.639-0.457c-0.003-0.004-0.005-0.009-0.008-0.012
	c-0.061-0.061-0.135-0.096-0.213-0.119c-0.045-0.025-0.094-0.047-0.14-0.071V0.5C16.5,0.224,16.276,0,16,0s-0.5,0.224-0.5,0.5v5.039
	c-0.508-0.172-1.063-0.307-1.658-0.399c-0.159-0.151-0.388-0.172-0.573-0.074C12.859,5.023,12.436,5,12,5s-0.86,0.023-1.27,0.065
	c-0.185-0.098-0.415-0.076-0.574,0.074C9.562,5.231,9.007,5.367,8.5,5.539V0.5C8.5,0.224,8.276,0,8,0S7.5,0.224,7.5,0.5v5.457
	c-0.382,0.196-0.717,0.417-1,0.659V0.5C6.5,0.224,6.276,0,6,0S5.5,0.224,5.5,0.5v8c0,1.511,2.052,5.964,5,7.181v0.551l-1.035,0.69
	c-1.23,0.82-1.965,2.193-1.965,3.672c0,1.918,2.15,3.406,4,3.406s4-1.488,3.998-3.407v-1.094c0-0.189-0.107-0.361-0.275-0.446
	c-0.168-0.086-0.369-0.068-0.522,0.044l-2.12,1.575c-0.375,0.375-0.967,0.433-1.406,0.14c-0.305-0.203-0.485-0.53-0.496-0.896
	c-0.011-0.366,0.152-0.703,0.445-0.923l1.004-0.753c0.807-0.605,1.29-1.548,1.348-2.548C16.437,14.489,18.5,10.016,18.5,8.5v-8
	C18.5,0.224,18.276,0,18,0z M17.131,7.662l-6.806,6.806c-0.215-0.132-0.424-0.287-0.628-0.458l6.857-6.857
	C16.789,7.315,16.981,7.485,17.131,7.662z M12.279,6.014l-4.94,4.94C7.14,10.573,6.972,10.205,6.84,9.863
	c0.004-0.004,0.009-0.005,0.013-0.009l3.761-3.761C11.054,6.038,11.511,6,12,6C12.097,6,12.184,6.012,12.279,6.014z M8.799,6.494
	L6.525,8.768C6.511,8.672,6.5,8.578,6.5,8.5C6.5,7.767,7.37,6.985,8.799,6.494z M7.856,11.851l5.727-5.727
	c0.78,0.113,1.467,0.301,2.042,0.544L8.97,13.323C8.559,12.872,8.184,12.366,7.856,11.851z M11.529,17.44l-1.004,0.753
	c-0.548,0.411-0.864,1.065-0.845,1.75c0.019,0.695,0.362,1.315,0.941,1.701c0.836,0.557,1.958,0.446,2.612-0.216l1.267-0.937v0.103
	c0,1.296-1.649,2.406-3,2.406s-3-1.111-3-2.406c0-1.144,0.568-2.206,1.52-2.84l1.257-0.838c0.14-0.093,0.223-0.249,0.223-0.416
	v-0.547C11.665,15.976,11.831,16,12,16c0.151,0,0.297-0.024,0.445-0.042C12.33,16.543,12.011,17.078,11.529,17.44z M12,15
	c-0.233,0-0.463-0.042-0.69-0.102l6.169-6.169C17.262,10.444,14.771,15,12,15z"/>
      );
    case (iconTypes.HIDE_WINDOW):
      return (
        <g>
        <g>
          <path d="M11.5,24C5.159,24,0,18.841,0,12.5S5.159,1,11.5,1S23,6.159,23,12.5S17.841,24,11.5,24z M11.5,2C5.71,2,1,6.71,1,12.5
            S5.71,23,11.5,23S22,18.29,22,12.5S17.29,2,11.5,2z"/>
        </g>
        <g>
          <path d="M17.5,13h-12C5.224,13,5,12.776,5,12.5S5.224,12,5.5,12h12c0.276,0,0.5,0.224,0.5,0.5S17.776,13,17.5,13z"/>
        </g>
      </g>
      );
    case (iconTypes.CLOSE_WINDOW):
      return (
        <g>
        <g>
          <path d="M11.5,24C5.159,24,0,18.841,0,12.5S5.159,1,11.5,1S23,6.159,23,12.5S17.841,24,11.5,24z M11.5,2C5.71,2,1,6.71,1,12.5
            S5.71,23,11.5,23S22,18.29,22,12.5S17.29,2,11.5,2z"/>
        </g>
        <g>
          <path d="M16,17.5c-0.128,0-0.256-0.049-0.354-0.146l-9-9c-0.195-0.195-0.195-0.512,0-0.707s0.512-0.195,0.707,0l9,9
            c0.195,0.195,0.195,0.512,0,0.707C16.256,17.451,16.128,17.5,16,17.5z"/>
        </g>
        <g>
          <path d="M7,17.5c-0.128,0-0.256-0.049-0.354-0.146c-0.195-0.195-0.195-0.512,0-0.707l9-9c0.195-0.195,0.512-0.195,0.707,0
            s0.195,0.512,0,0.707l-9,9C7.256,17.451,7.128,17.5,7,17.5z"/>
        </g>
      </g>
      );
    case (iconTypes.RELOAD):
      return (
        <g>
        <g>
          <path d="M12,24C5.383,24,0,18.617,0,12S5.383,0,12,0c4.775,0,8.715,2.85,11.394,8.239c0.123,0.247,0.022,0.548-0.225,0.671
            c-0.249,0.121-0.547,0.021-0.67-0.226C20.756,5.179,17.521,1,12,1C5.935,1,1,5.935,1,12s4.935,11,11,11s11-4.935,11-11
            c0-0.276,0.224-0.5,0.5-0.5S24,11.724,24,12C24,18.617,18.617,24,12,24z"/>
        </g>
        <g>
          <path d="M23.5,9h-4C19.224,9,19,8.776,19,8.5S19.224,8,19.5,8H23V4.542c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5V8.5
            C24,8.776,23.776,9,23.5,9z"/>
        </g>
      </g>
      );
    case (iconTypes.BULB):
      return (
<g>
	<g id="XMLID_144_">
		<path d="M14,18.013h-4c-1.871,0-2.46-3.18-2.712-4.538c-0.02-0.104-0.076-0.197-0.169-0.276C5.536,11.84,4.583,9.872,4.505,7.8
			C4.43,5.829,5.189,3.853,6.586,2.379c1.395-1.472,3.324-2.334,5.292-2.365c2.011-0.013,3.938,0.732,5.382,2.154
			C18.705,3.589,19.5,5.487,19.5,7.513c0,2.185-0.955,4.257-2.62,5.686c-0.092,0.079-0.148,0.171-0.167,0.273
			C16.482,14.714,15.872,18.013,14,18.013z M12.001,1.013c-0.036,0-0.071,0-0.106,0.001c-1.701,0.027-3.371,0.775-4.583,2.053
			C6.099,4.347,5.44,6.058,5.504,7.762c0.068,1.795,0.894,3.5,2.266,4.678c0.266,0.229,0.439,0.522,0.5,0.851
			c0.6,3.239,1.341,3.723,1.729,3.723h4c0.389,0,1.13-0.484,1.729-3.723c0.061-0.328,0.234-0.621,0.499-0.849
			c1.444-1.239,2.272-3.035,2.272-4.928c0-1.756-0.689-3.401-1.94-4.633C15.334,1.674,13.718,1.013,12.001,1.013z"/>
	</g>
	<g id="XMLID_142_">
		<path d="M14,20.013h-4c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h4c0.276,0,0.5,0.224,0.5,0.5S14.276,20.013,14,20.013z"/>
	</g>
	<g id="XMLID_143_">
		<path d="M14,22.013h-4c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h4c0.276,0,0.5,0.224,0.5,0.5S14.276,22.013,14,22.013z"/>
	</g>
	<g id="XMLID_145_">
		<path d="M13,24.013h-2c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h2c0.276,0,0.5,0.224,0.5,0.5S13.276,24.013,13,24.013z"/>
	</g>
</g>
      );
    case (iconTypes.DIAMOND):
      return (
        <g>
        <g>
          <path d="M12,23c-0.165,0-0.318-0.081-0.412-0.217l-11-16C0.441,6.57,0.481,6.281,0.68,6.116l6-5C6.77,1.041,6.883,1,7,1h10
            c0.117,0,0.23,0.041,0.32,0.116l6,5c0.198,0.165,0.238,0.454,0.092,0.667l-11,16C12.318,22.919,12.165,23,12,23z M1.67,6.593
            L12,21.617L22.33,6.593L16.819,2H7.181L1.67,6.593z"/>
        </g>
        <g>
          <path d="M23,7H1C0.724,7,0.5,6.776,0.5,6.5S0.724,6,1,6h22c0.276,0,0.5,0.224,0.5,0.5S23.276,7,23,7z"/>
        </g>
        <g>
          <path d="M12,23c-0.219,0-0.419-0.145-0.481-0.364l-4.5-16C6.981,6.503,7,6.36,7.071,6.243l3-5
            c0.143-0.237,0.452-0.314,0.686-0.172c0.237,0.142,0.314,0.449,0.172,0.686L8.04,6.572l4.441,15.792
            c0.074,0.267-0.08,0.542-0.346,0.617C12.09,22.994,12.045,23,12,23z"/>
        </g>
        <g>
          <path d="M12,23c-0.045,0-0.09-0.006-0.136-0.019c-0.266-0.075-0.42-0.351-0.346-0.617L15.96,6.572l-2.889-4.815
            c-0.143-0.236-0.065-0.544,0.172-0.686c0.235-0.143,0.543-0.065,0.686,0.172l3,5c0.071,0.117,0.09,0.26,0.053,0.393l-4.5,16
            C12.419,22.855,12.219,23,12,23z"/>
        </g>
      </g>
      );
    case (iconTypes.SWITCH_OFF):
      return (
        <g>
        <g>
          <g>
            <path d="M6.5,16.5C4.019,16.5,2,14.481,2,12s2.019-4.5,4.5-4.5S11,9.519,11,12S8.981,16.5,6.5,16.5z M6.5,8.5
              C4.57,8.5,3,10.07,3,12s1.57,3.5,3.5,3.5S10,13.93,10,12S8.43,8.5,6.5,8.5z"/>
          </g>
          <g>
            <path d="M17.5,18.5h-11C2.916,18.5,0,15.584,0,12s2.916-6.5,6.5-6.5h11c3.584,0,6.5,2.916,6.5,6.5S21.084,18.5,17.5,18.5z
               M6.5,6.5C3.468,6.5,1,8.967,1,12s2.468,5.5,5.5,5.5h11c3.032,0,5.5-2.467,5.5-5.5s-2.468-5.5-5.5-5.5H6.5z"/>
          </g>
        </g>
        <g>
          <path d="M13.5,14.5c-0.827,0-1.5-0.673-1.5-1.5v-2c0-0.827,0.673-1.5,1.5-1.5S15,10.173,15,11v2C15,13.827,14.327,14.5,13.5,14.5z
             M13.5,10.5c-0.275,0-0.5,0.224-0.5,0.5v2c0,0.276,0.225,0.5,0.5,0.5S14,13.276,14,13v-2C14,10.724,13.775,10.5,13.5,10.5z"/>
        </g>
        <g>
          <path d="M16.5,14.5c-0.276,0-0.5-0.224-0.5-0.5v-4c0-0.276,0.224-0.5,0.5-0.5H18c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5h-1
            V14C17,14.276,16.776,14.5,16.5,14.5z"/>
        </g>
        <g>
          <path d="M17.5,12.5h-1c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h1c0.276,0,0.5,0.224,0.5,0.5S17.776,12.5,17.5,12.5z"/>
        </g>
        <g>
          <path d="M19.5,14.5c-0.276,0-0.5-0.224-0.5-0.5v-4c0-0.276,0.224-0.5,0.5-0.5H21c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5h-1
            V14C20,14.276,19.776,14.5,19.5,14.5z"/>
        </g>
        <g>
          <path d="M20.5,12.5h-1c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h1c0.276,0,0.5,0.224,0.5,0.5S20.776,12.5,20.5,12.5z"/>
        </g>
      </g>
      );
    case (iconTypes.SWITCH_ON):
      return (
        <g>
        <g>
          <g>
            <path d="M17.5,16.5c-2.481,0-4.5-2.019-4.5-4.5s2.019-4.5,4.5-4.5S22,9.519,22,12S19.981,16.5,17.5,16.5z M17.5,8.5
              c-1.93,0-3.5,1.57-3.5,3.5s1.57,3.5,3.5,3.5S21,13.93,21,12S19.43,8.5,17.5,8.5z"/>
          </g>
          <g>
            <path d="M17.5,18.5h-11C2.916,18.5,0,15.584,0,12s2.916-6.5,6.5-6.5h11c3.584,0,6.5,2.916,6.5,6.5S21.084,18.5,17.5,18.5z
               M6.5,6.5C3.468,6.5,1,8.967,1,12s2.468,5.5,5.5,5.5h11c3.032,0,5.5-2.467,5.5-5.5s-2.468-5.5-5.5-5.5H6.5z"/>
          </g>
        </g>
        <g>
          <path d="M5.5,14.5C4.673,14.5,4,13.827,4,13v-2c0-0.827,0.673-1.5,1.5-1.5S7,10.173,7,11v2C7,13.827,6.327,14.5,5.5,14.5z
             M5.5,10.5C5.225,10.5,5,10.724,5,11v2c0,0.276,0.225,0.5,0.5,0.5S6,13.276,6,13v-2C6,10.724,5.775,10.5,5.5,10.5z"/>
        </g>
        <g>
          <path d="M10.5,14.5c-0.187,0-0.361-0.104-0.447-0.276L9,12.118V14c0,0.276-0.224,0.5-0.5,0.5S8,14.276,8,14v-4
            c0-0.232,0.159-0.434,0.385-0.487c0.23-0.054,0.459,0.056,0.563,0.263L10,11.882V10c0-0.276,0.224-0.5,0.5-0.5S11,9.724,11,10v4
            c0,0.232-0.159,0.434-0.385,0.487C10.576,14.496,10.538,14.5,10.5,14.5z"/>
        </g>
      </g>
      );
    case (iconTypes.NO_ADDS):
      return (
        <g>
        <g>
          <path d="M12,0C5.383,0,0,5.383,0,12s5.383,12,12,12s12-5.383,12-12S18.617,0,12,0z M12,23C5.935,23,1,18.065,1,12
            c0-2.853,1.101-5.447,2.888-7.404L7.292,8H6.5C4.57,8,3,9.57,3,11.5c0,1.405,0.837,2.61,2.033,3.167
            c0.024,0.068,0.06,0.132,0.114,0.186l0.854,0.854v2.413c0,0.614,0.305,1.185,0.758,1.48l1.406,1.271C8.26,20.957,8.38,21,8.5,21
            c0.128,0,0.256-0.049,0.354-0.146l0.5-0.5C9.666,20.041,10,19.521,10,19v-0.23c0-0.489-0.191-0.95-0.51-1.261
            c-0.023-0.051-0.035-0.237,0-0.412L9.909,15h0.464l4.242,2.314C15.027,17.757,15.495,18,16,18c0.333,0,0.66-0.116,0.967-0.325
            l2.437,2.437C17.447,21.899,14.853,23,12,23z M6.854,15.146L6.708,15H8.89l-0.38,1.901c-0.041,0.202-0.143,0.891,0.246,1.281
            C8.913,18.34,9,18.549,9,18.771V19c0,0.167-0.152,0.445-0.354,0.646l-0.165,0.165l-1.11-0.998C7.139,18.659,7,18.399,7,18.12V15.5
            C7,15.367,6.947,15.24,6.854,15.146z M6.5,14C5.122,14,4,12.878,4,11.5S5.122,9,6.5,9h1.792l4.92,4.92
            c0.114,0.647,0.269,1.229,0.458,1.739l-2.931-1.599C10.666,14.021,10.583,14,10.5,14H6.5z M14.598,15.306l1.643,1.643
            c-0.047,0.02-0.096,0.027-0.144,0.035l-0.872-0.476C15.003,16.226,14.789,15.828,14.598,15.306z M20.111,19.405l-2.753-2.753
            c-0.002-0.002-0.002-0.004-0.004-0.006l-3.328-3.328c-0.001-0.001-0.003-0.001-0.004-0.002L8.856,8.149
            C8.854,8.148,8.852,8.146,8.851,8.144L4.595,3.889C6.552,2.101,9.147,1,12,1c6.065,0,11,4.935,11,11
            C23,14.853,21.899,17.448,20.111,19.405z M16.237,5.024c-0.146-0.08-0.324-0.093-0.479-0.007L15.681,5.06
            c-0.333,0.071-0.646,0.244-0.934,0.517l-3.955,2.19c-0.241,0.134-0.329,0.438-0.195,0.68c0.091,0.165,0.262,0.258,0.438,0.258
            c0.082,0,0.165-0.02,0.242-0.063l2.402-1.33c-0.346,0.917-0.58,2.057-0.652,3.357c-0.016,0.276,0.195,0.512,0.471,0.527
            c0.266,0.019,0.512-0.196,0.527-0.471c0.115-2.064,0.676-3.623,1.291-4.319l0.716-0.396C16.967,6.06,18,8.386,18,11.5
            c0,1.277-0.172,2.487-0.496,3.5c-0.084,0.262,0.061,0.544,0.324,0.628c0.05,0.016,0.102,0.024,0.152,0.024
            c0.212,0,0.409-0.135,0.477-0.347C18.812,14.195,19,12.879,19,11.5C19,7.968,17.828,5.271,16.237,5.024z M15.5,10
            c-0.276,0-0.5,0.224-0.5,0.5s0.224,0.5,0.5,0.5s0.5,0.224,0.5,0.5S15.776,12,15.5,12S15,12.224,15,12.5s0.224,0.5,0.5,0.5
            c0.827,0,1.5-0.673,1.5-1.5S16.327,10,15.5,10z"/>
        </g>
      </g>
      );
    case (iconTypes.MEGAPHONE):
      return (
        <g>
        <g>
          <path d="M23.865,20.34L12.57,0.697c-0.053-0.142-0.124-0.272-0.23-0.377c-1.399-1.397-5.699,2.164-7.778,4.243
            s-5.64,6.38-4.243,7.778c0.115,0.115,0.252,0.193,0.404,0.245l11.167,6.422l-0.809,1.213c-0.076,0.114-0.101,0.253-0.072,0.387
            c0.03,0.133,0.114,0.249,0.231,0.319l2.5,1.5c0.079,0.048,0.168,0.071,0.257,0.071c0.129,0,0.257-0.05,0.354-0.147l1.224-1.224
            l4.761,2.738c0.12,0.092,0.258,0.133,0.407,0.133c0.639,0,1.486-0.734,2.003-1.25c0.294-0.294,1.251-1.305,1.251-1.994
            C23.997,20.566,23.934,20.431,23.865,20.34z M2.232,11.347c-0.167,0.08-0.31,0.136-0.446,0.186
            c-0.136,0.047-0.27,0.091-0.377,0.109c-0.004,0.001-0.008,0.002-0.011,0.003c-0.139,0.023-0.253,0.022-0.336-0.005
            c-0.001,0-0.001,0-0.002,0c-0.007-0.005-0.027-0.001-0.032-0.007c-0.308-0.307,0.798-2.919,4.242-6.364
            c3.082-3.083,5.497-4.292,6.192-4.292c0.081,0,0.14,0.017,0.172,0.049c0.001,0.001,0.003,0.007,0.004,0.009
            c0.002,0.009,0,0.018,0.003,0.026c0.024,0.078,0.023,0.188,0.003,0.316c-0.003,0.021-0.009,0.044-0.013,0.066
            c-0.022,0.115-0.061,0.248-0.115,0.395c-0.019,0.053-0.046,0.115-0.07,0.174c-0.053,0.129-0.116,0.267-0.191,0.415
            c-0.025,0.051-0.044,0.094-0.073,0.148c-0.096,0.182-0.215,0.383-0.341,0.588c-0.146,0.239-0.313,0.488-0.488,0.74
            c-0.053,0.077-0.097,0.147-0.154,0.227c-0.24,0.335-0.502,0.678-0.785,1.027C9.411,5.16,9.41,5.162,9.409,5.164
            C8.814,5.895,8.131,6.649,7.39,7.39C6.972,7.808,6.568,8.188,6.178,8.539C6.1,8.608,6.029,8.665,5.953,8.732
            C5.628,9.017,5.309,9.291,5.008,9.53C4.978,9.554,4.95,9.574,4.921,9.597c-1.064,0.839-1.969,1.401-2.639,1.726
            C2.265,11.332,2.249,11.339,2.232,11.347z M13.92,21.367l-1.716-1.03l0.554-0.831l1.919,1.104L13.92,21.367z M22.039,22.04
            c-0.561,0.56-1.036,0.862-1.236,0.939l-5.084-2.923c-0.014-0.007-0.026-0.018-0.041-0.024l-13.31-7.654
            c0.038-0.015,0.08-0.039,0.119-0.055c0.126-0.054,0.253-0.112,0.383-0.177c0.111-0.055,0.223-0.114,0.337-0.176
            c0.034-0.019,0.068-0.038,0.102-0.058c1.328-0.744,2.86-1.967,4.255-3.298C7.75,8.438,7.928,8.266,8.09,8.104
            C8.092,8.102,8.094,8.1,8.096,8.098C8.421,7.773,8.74,7.435,9.054,7.093C9.14,6.999,9.226,6.905,9.311,6.811
            c0.284-0.318,0.561-0.638,0.825-0.96c0.066-0.081,0.126-0.159,0.19-0.24c0.215-0.269,0.419-0.537,0.613-0.802
            c0.058-0.08,0.119-0.161,0.174-0.24c0.224-0.317,0.431-0.628,0.613-0.931c0.01-0.016,0.023-0.034,0.033-0.05
            c0.267-0.445,0.468-0.847,0.616-1.215c0-0.001,0.001-0.002,0.002-0.003l10.601,18.435C22.901,21.004,22.6,21.479,22.039,22.04z"/>
        </g>
      </g>);
    case (iconTypes.STOP):
      return (
<g>
		<g>
			<path d="M15.935,24H8.065c-0.668,0-1.296-0.26-1.769-0.732l-5.564-5.564C0.26,17.23,0,16.603,0,15.935V8.065
				C0,7.397,0.26,6.77,0.732,6.297l5.564-5.564C6.77,0.26,7.397,0,8.065,0h7.869c0.668,0,1.296,0.26,1.769,0.732l5.564,5.564
				C23.74,6.77,24,7.397,24,8.065v7.869c0,0.668-0.26,1.296-0.732,1.769l-5.564,5.564C17.23,23.74,16.603,24,15.935,24z M8.065,1
				C7.664,1,7.287,1.156,7.004,1.439L1.439,7.004C1.156,7.287,1,7.664,1,8.065v7.869c0,0.401,0.156,0.778,0.439,1.062l5.564,5.564
				C7.287,22.844,7.664,23,8.065,23h7.869c0.401,0,0.778-0.156,1.062-0.439l5.564-5.564C22.844,16.713,23,16.336,23,15.935V8.065
				c0-0.401-0.156-0.778-0.439-1.062l-5.564-5.564C16.713,1.156,16.336,1,15.935,1H8.065z"/>
		</g>
		<g>
			<path d="M4.999,16c-1.056,0-1.934-0.928-1.998-2.111c-0.016-0.275,0.196-0.512,0.472-0.526c0.306-0.017,0.512,0.196,0.526,0.472
				C4.035,14.488,4.474,15,4.999,15C5.551,15,6,14.439,6,13.75c0-0.828-0.511-1.089-1.063-1.247l-0.011-0.524l-0.137,0.48
				C3.311,12.036,3,11.024,3,10.25C3,9.01,3.897,8,5.001,8c1.056,0,1.934,0.927,1.998,2.11c0.016,0.275-0.196,0.512-0.472,0.526
				c-0.297,0.045-0.511-0.196-0.526-0.472C5.965,9.512,5.526,9,5.001,9C4.449,9,4,9.561,4,10.25c0,0.829,0.512,1.09,1.064,1.247
				l0.149,0.045C6.399,11.883,7,12.625,7,13.75C7,14.99,6.103,16,4.999,16z"/>
		</g>
		<g>
			<path d="M11.5,9h-4C7.224,9,7,8.776,7,8.5S7.224,8,7.5,8h4C11.776,8,12,8.224,12,8.5S11.776,9,11.5,9z"/>
		</g>
		<g>
			<path d="M9.5,16C9.224,16,9,15.776,9,15.5v-7C9,8.224,9.224,8,9.5,8S10,8.224,10,8.5v7C10,15.776,9.776,16,9.5,16z"/>
		</g>
		<g>
			<path d="M14,16c-1.103,0-2-0.897-2-2v-4c0-1.103,0.897-2,2-2s2,0.897,2,2v4C16,15.103,15.103,16,14,16z M14,9
				c-0.552,0-1,0.448-1,1v4c0,0.552,0.448,1,1,1s1-0.448,1-1v-4C15,9.448,14.552,9,14,9z"/>
		</g>
		<g>
			<path d="M17.5,16c-0.276,0-0.5-0.224-0.5-0.5v-7C17,8.224,17.224,8,17.5,8h2C20.327,8,21,8.673,21,9.5v2
				c0,0.827-0.673,1.5-1.5,1.5H18v2.5C18,15.776,17.776,16,17.5,16z M18,12h1.5c0.275,0,0.5-0.225,0.5-0.5v-2
				C20,9.225,19.775,9,19.5,9H18V12z"/>
		</g>
	</g>
      );
    case (iconTypes.DIAMOND_TWO):
      return (
<g>
	<g>
		<path d="M12,22.207c-0.143,0-0.28-0.062-0.375-0.169l-11.5-13C-0.01,8.886-0.039,8.666,0.053,8.484l3-6
			C3.138,2.314,3.311,2.207,3.5,2.207h17c0.189,0,0.362,0.107,0.447,0.276l3,6c0.091,0.183,0.063,0.402-0.073,0.555l-11.5,13
			C12.28,22.146,12.143,22.207,12,22.207z M1.098,8.629L12,20.953L22.902,8.629l-2.711-5.421H3.809L1.098,8.629z"/>
	</g>
	<g>
		<path d="M23.5,9.207h-23c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h23c0.276,0,0.5,0.224,0.5,0.5S23.776,9.207,23.5,9.207z"/>
	</g>
	<g>
		<path d="M16.5,9.207c-0.157,0-0.306-0.074-0.4-0.2L12,3.541L7.9,9.007c-0.097,0.13-0.254,0.197-0.414,0.2
			C7.324,9.203,7.174,9.12,7.084,8.985l-4-6c-0.153-0.23-0.091-0.54,0.139-0.693s0.54-0.091,0.693,0.139l3.608,5.412L11.6,2.408
			c0.188-0.252,0.611-0.252,0.8,0l4.076,5.435l3.608-5.412c0.153-0.229,0.462-0.292,0.693-0.139c0.23,0.153,0.292,0.463,0.139,0.693
			l-4,6c-0.09,0.135-0.24,0.218-0.402,0.223C16.509,9.207,16.504,9.207,16.5,9.207z"/>
	</g>
	<g>
		<path d="M12,22.207c-0.213,0-0.403-0.135-0.473-0.336l-4.5-13C6.937,8.61,7.076,8.325,7.336,8.235
			c0.26-0.091,0.545,0.048,0.636,0.309L12,20.179l4.027-11.635c0.091-0.261,0.375-0.4,0.636-0.309
			c0.261,0.09,0.399,0.375,0.309,0.636l-4.5,13C12.403,22.072,12.213,22.207,12,22.207z"/>
	</g>
</g>
      );
    case (iconTypes.PICTURE_TWO):
      return (
<g>
	<g>
		<g>
			<g>
				<path d="M22.5,24h-21C1.224,24,1,23.776,1,23.5v-23C1,0.224,1.224,0,1.5,0h21C22.776,0,23,0.224,23,0.5v23
					C23,23.776,22.776,24,22.5,24z M2,23h20V1H2V23z"/>
			</g>
			<g>
				<path d="M20.5,18h-17C3.224,18,3,17.776,3,17.5v-15C3,2.224,3.224,2,3.5,2h17C20.776,2,21,2.224,21,2.5v15
					C21,17.776,20.776,18,20.5,18z M4,17h16V3H4V17z"/>
			</g>
		</g>
		<g>
			<g>
				<path d="M8.534,16H6.466c-0.263,0-0.48-0.203-0.499-0.465l-0.229-3.241c-0.184-0.053-0.352-0.158-0.481-0.304
					c-0.195-0.22-0.286-0.514-0.25-0.807l0.335-1.855C5.434,8.583,6.089,8,6.862,8h1.276c0.773,0,1.428,0.583,1.522,1.356
					l0.328,1.799c0.04,0.321-0.051,0.615-0.246,0.835c-0.129,0.146-0.297,0.251-0.481,0.304l-0.229,3.241
					C9.015,15.797,8.797,16,8.534,16z M6.932,15h1.137l0.226-3.202c0.018-0.262,0.236-0.465,0.499-0.465h0.19L8.672,9.507
					C8.635,9.206,8.407,9,8.138,9H6.862c-0.269,0-0.497,0.206-0.53,0.479l-0.335,1.855l0.211,0c0.263,0,0.48,0.203,0.499,0.465
					L6.932,15z"/>
			</g>
			<g>
				<path d="M7.5,8C6.673,8,6,7.327,6,6.5S6.673,5,7.5,5S9,5.673,9,6.5S8.327,8,7.5,8z M7.5,6C7.224,6,7,6.224,7,6.5S7.224,7,7.5,7
					S8,6.776,8,6.5S7.776,6,7.5,6z"/>
			</g>
		</g>
	</g>
	<g>
		<path d="M15.5,11C13.57,11,12,9.43,12,7.5S13.57,4,15.5,4S19,5.57,19,7.5S17.43,11,15.5,11z M15.5,5C14.122,5,13,6.122,13,7.5
			s1.122,2.5,2.5,2.5S18,8.878,18,7.5S16.878,5,15.5,5z"/>
	</g>
	<g>
		<path d="M15.5,16c-0.276,0-0.5-0.224-0.5-0.5v-5c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v5C16,15.776,15.776,16,15.5,16z"/>
	</g>
</g>
      );
    case (iconTypes.HANDSHAKE):
      return (
<g>
		<g>
			<path d="M3,15.5c-0.087,0-0.176-0.022-0.257-0.071l-2.5-1.5c-0.237-0.142-0.313-0.449-0.171-0.686s0.449-0.316,0.686-0.172
				l2.5,1.5c0.237,0.142,0.313,0.449,0.171,0.686C3.335,15.413,3.169,15.5,3,15.5z"/>
		</g>
		<g>
			<path d="M11.316,5.502c-0.048,0-0.097-0.007-0.146-0.021C9.392,4.937,7.277,4.854,4.885,5.236
				C3.878,5.394,2.858,5.115,2.083,4.47L0.18,2.884C-0.032,2.707-0.061,2.392,0.116,2.18C0.293,1.969,0.607,1.938,0.82,2.116
				l1.903,1.586C3.276,4.163,4.01,4.365,4.728,4.248c2.543-0.403,4.81-0.312,6.734,0.275c0.264,0.081,0.413,0.36,0.332,0.624
				C11.729,5.363,11.531,5.502,11.316,5.502z"/>
		</g>
		<g>
			<path d="M15.251,21c-0.597,0-1.184-0.206-1.65-0.579l-2.425-2.063c-0.21-0.179-0.236-0.494-0.057-0.705
				c0.179-0.21,0.496-0.235,0.705-0.057l2.413,2.053C14.516,19.872,14.88,20,15.251,20C15.664,20,16,19.664,16,19.251
				c0-0.217-0.093-0.422-0.256-0.563l-4.564-3.804c-0.212-0.177-0.241-0.492-0.064-0.704c0.177-0.21,0.491-0.241,0.705-0.063
				l4.551,3.792c0.006,0.005,0.013,0.011,0.02,0.017l1.051,0.876C17.596,18.93,17.792,19,17.992,19h0.148
				c0.474,0,0.86-0.386,0.86-0.86c0-0.25-0.105-0.484-0.291-0.647l-5.529-4.608c-0.212-0.177-0.241-0.492-0.064-0.704
				c0.178-0.211,0.492-0.241,0.705-0.063l5.51,4.592c0.01,0.008,0.019,0.016,0.028,0.023l0.081,0.067
				C19.595,16.929,19.793,17,19.996,17h0.134c0.48,0,0.87-0.391,0.87-0.87c0-0.257-0.109-0.497-0.3-0.662L13.17,8.876
				c-0.208-0.182-0.229-0.497-0.046-0.705c0.181-0.209,0.497-0.229,0.706-0.047l7.527,6.59C21.765,15.068,22,15.583,22,16.126
				C22,17.161,21.161,18,20.13,18h-0.134c0,0,0,0-0.001,0C19.999,18.045,20,18.091,20,18.137C20,19.165,19.166,20,18.14,20h-0.148
				c-0.374,0-0.738-0.113-1.045-0.321C16.756,20.437,16.068,21,15.251,21z"/>
		</g>
		<g>
			<path d="M12.5,22c-0.4,0-0.777-0.156-1.061-0.439l-1.281-1.195c-0.202-0.188-0.212-0.505-0.024-0.706
				c0.189-0.203,0.504-0.213,0.707-0.024l1.293,1.207c0.214,0.213,0.53,0.201,0.719,0.012c0.256-0.256,0.353-0.751,0.223-0.881
				c-0.195-0.195-0.195-0.512,0-0.707s0.512-0.195,0.707,0c0.593,0.593,0.371,1.7-0.223,2.295C13.277,21.844,12.9,22,12.5,22z"/>
		</g>
		<g>
			<path d="M21,15.5c-0.17,0-0.335-0.087-0.429-0.243c-0.142-0.236-0.065-0.544,0.171-0.686l2.5-1.5
				c0.237-0.145,0.544-0.064,0.686,0.172c0.142,0.236,0.065,0.544-0.171,0.686l-2.5,1.5C21.177,15.478,21.088,15.5,21,15.5z"/>
		</g>
		<g>
			<path d="M8.933,12h-0.4c-0.827,0-1.571-0.46-1.941-1.199C6.173,9.964,6.336,8.957,6.998,8.295l2.649-2.648
				c0.029-0.029,0.061-0.055,0.096-0.075c0.182-0.109,0.367-0.23,0.556-0.354C11.167,4.646,12.15,4,13.5,4
				c0.783,0,1.504,0.237,2.267,0.489c1.087,0.358,2.314,0.764,4.018,0.536l3.39-2.905c0.21-0.178,0.525-0.154,0.705,0.055
				c0.18,0.209,0.156,0.525-0.054,0.705l-3.5,3c-0.071,0.061-0.158,0.101-0.25,0.114c-2.01,0.309-3.458-0.171-4.621-0.556
				C14.74,5.203,14.123,5,13.5,5c-1.051,0-1.865,0.535-2.652,1.052c-0.183,0.12-0.361,0.237-0.537,0.345L7.705,9.002
				c-0.356,0.356-0.444,0.9-0.219,1.352C7.685,10.752,8.086,11,8.532,11h0.4c0.272,0,0.527-0.09,0.737-0.261
				c0.189-0.154,0.408-0.337,0.643-0.533c1.282-1.072,2.318-1.914,3.012-2.174c0.258-0.1,0.547,0.034,0.644,0.292
				c0.097,0.259-0.034,0.547-0.292,0.644c-0.589,0.222-1.873,1.295-2.722,2.006c-0.238,0.199-0.46,0.385-0.654,0.542
				C9.916,11.828,9.43,12,8.933,12z"/>
		</g>
		<g>
			<path d="M3.977,17.5c-0.814,0-1.477-0.663-1.477-1.478v-0.227c0-0.293,0.086-0.576,0.248-0.819l0.546-0.818
				C3.568,13.746,4.027,13.5,4.523,13.5C5.337,13.5,6,14.163,6,14.978v0.227c0,0.293-0.086,0.576-0.248,0.819l-0.546,0.818
				C4.932,17.254,4.473,17.5,3.977,17.5z M4.523,14.5c-0.16,0-0.308,0.079-0.396,0.212L3.58,15.531
				c-0.052,0.078-0.08,0.17-0.08,0.265v0.227c0,0.264,0.214,0.478,0.477,0.478c0.16,0,0.308-0.079,0.396-0.212l0.546-0.819
				C4.972,15.391,5,15.299,5,15.204v-0.227C5,14.714,4.786,14.5,4.523,14.5z"/>
		</g>
		<g>
			<path d="M5.511,19C4.663,19,4,18.337,4,17.522v-0.196c0-0.318,0.105-0.634,0.295-0.888l1.012-1.348
				c0.277-0.37,0.719-0.591,1.182-0.591C7.337,14.5,8,15.163,8,15.978v0.196c0,0.318-0.105,0.634-0.295,0.888l-1.012,1.348
				C6.416,18.779,5.974,19,5.511,19z M6.523,15.5c-0.184,0-0.326,0.071-0.416,0.19l-1.012,1.349C5.034,17.121,5,17.223,5,17.326
				v0.196C5,17.786,5.214,18,5.477,18c0.184,0,0.326-0.071,0.416-0.19l1.012-1.349C6.966,16.379,7,16.277,7,16.174v-0.196
				C7,15.714,6.786,15.5,6.523,15.5z"/>
		</g>
		<g>
			<path d="M7.511,20C6.663,20,6,19.337,6,18.522v-0.196c0-0.318,0.105-0.634,0.295-0.888l1.012-1.348
				c0.277-0.37,0.719-0.591,1.182-0.591C9.337,15.5,10,16.163,10,16.978v0.196c0,0.318-0.105,0.634-0.295,0.888l-1.012,1.348
				C8.416,19.779,7.974,20,7.511,20z M8.523,16.5c-0.184,0-0.326,0.071-0.416,0.19l-1.012,1.349C7.034,18.121,7,18.223,7,18.326
				v0.196C7,18.786,7.214,19,7.477,19c0.184,0,0.326-0.071,0.416-0.19l1.012-1.349C8.966,17.379,9,17.277,9,17.174v-0.196
				C9,16.714,8.786,16.5,8.523,16.5z"/>
		</g>
		<g>
			<path d="M9.511,21C8.663,21,8,20.337,8,19.522v-0.196c0-0.318,0.105-0.634,0.295-0.888l1.012-1.348
				c0.277-0.37,0.719-0.591,1.182-0.591c0.849,0,1.511,0.663,1.511,1.478v0.196c0,0.318-0.105,0.634-0.295,0.888l-1.012,1.348
				C10.416,20.779,9.974,21,9.511,21z M10.523,17.5c-0.184,0-0.326,0.071-0.416,0.19l-1.012,1.349C9.034,19.121,9,19.223,9,19.326
				v0.196C9,19.786,9.214,20,9.477,20c0.184,0,0.326-0.071,0.416-0.19l1.012-1.349C10.966,18.379,11,18.277,11,18.174v-0.196
				C11,17.714,10.786,17.5,10.523,17.5z"/>
		</g>
	</g>
      );
    case (iconTypes.PIN):
      return (
        <g>
        <g>
          <g>
            <path d="M11.5,24c-0.276,0-0.5-0.224-0.5-0.5v-13c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v13C12,23.776,11.776,24,11.5,24z
              "/>
          </g>
        </g>
        <g>
          <path d="M11.5,11C8.468,11,6,8.533,6,5.5S8.468,0,11.5,0S17,2.467,17,5.5S14.532,11,11.5,11z M11.5,1C9.019,1,7,3.019,7,5.5
            S9.019,10,11.5,10S16,7.981,16,5.5S13.981,1,11.5,1z"/>
        </g>
        <g>
          <path d="M8.5,6C8.224,6,8,5.776,8,5.5C8,3.57,9.57,2,11.5,2C11.776,2,12,2.224,12,2.5S11.776,3,11.5,3C10.121,3,9,4.122,9,5.5
            C9,5.776,8.776,6,8.5,6z"/>
        </g>
        <g>
          <path d="M11.5,11C8.468,11,6,8.533,6,5.5S8.468,0,11.5,0S17,2.467,17,5.5C17,5.776,16.776,6,16.5,6S16,5.776,16,5.5
            C16,3.019,13.981,1,11.5,1S7,3.019,7,5.5S9.019,10,11.5,10c0.276,0,0.5,0.224,0.5,0.5S11.776,11,11.5,11z"/>
        </g>
      </g>
      );
    case (iconTypes.GEAR_SOLID):
      return (
<path d="M23.903,10.496c-0.157-1.211-1.418-2.04-2.625-1.588c-0.452,0.171-0.996-0.083-1.2-0.539
	c-0.244-0.544-0.543-1.065-0.891-1.549c-0.31-0.431-0.251-0.995,0.134-1.313c0.46-0.378,0.717-0.937,0.705-1.532
	c-0.012-0.605-0.297-1.162-0.782-1.53c-0.798-0.604-1.668-1.107-2.587-1.494c-0.248-0.104-0.508-0.157-0.773-0.157
	c-0.958,0-1.766,0.681-1.922,1.619c-0.082,0.493-0.549,0.828-1.075,0.771c-0.584-0.062-1.192-0.062-1.775,0
	c-0.524,0.057-0.993-0.278-1.075-0.771C9.881,1.475,9.073,0.794,8.115,0.794c-0.265,0-0.525,0.053-0.772,0.157
	C6.423,1.338,5.553,1.84,4.755,2.445C4.271,2.813,3.986,3.37,3.974,3.974C3.962,4.57,4.219,5.128,4.679,5.508
	c0.386,0.317,0.443,0.881,0.134,1.311C4.465,7.303,4.165,7.824,3.921,8.368C3.717,8.824,3.213,9.092,2.71,8.903
	C2.49,8.821,2.261,8.779,2.029,8.779c-0.976,0-1.806,0.738-1.933,1.717C0.033,10.992,0,11.499,0,12s0.033,1.008,0.097,1.504
	c0.156,1.21,1.416,2.039,2.625,1.588c0.451-0.171,0.996,0.083,1.2,0.539c0.244,0.544,0.543,1.065,0.891,1.549
	c0.31,0.431,0.251,0.995-0.134,1.313c-0.46,0.378-0.717,0.937-0.705,1.532c0.012,0.605,0.297,1.162,0.782,1.53
	c0.798,0.604,1.668,1.107,2.587,1.494c0.248,0.104,0.508,0.157,0.773,0.157c0.958,0,1.766-0.681,1.922-1.619
	c0.082-0.493,0.55-0.823,1.075-0.771c0.583,0.062,1.191,0.062,1.775,0c0.526-0.053,0.993,0.278,1.075,0.771
	c0.156,0.938,0.964,1.618,1.922,1.619c0.265,0,0.525-0.053,0.772-0.157c0.919-0.387,1.79-0.889,2.587-1.494
	c0.484-0.368,0.77-0.925,0.782-1.529c0.012-0.596-0.245-1.154-0.705-1.534c-0.386-0.317-0.443-0.881-0.134-1.311
	c0.348-0.484,0.648-1.005,0.892-1.549c0.204-0.457,0.709-0.724,1.211-0.535c0.22,0.083,0.449,0.125,0.681,0.125
	c0.976,0,1.806-0.738,1.933-1.717C23.967,13.003,24,12.498,24,12S23.967,10.997,23.903,10.496z M12,16c-2.206,0-4-1.794-4-4
	s1.794-4,4-4s4,1.794,4,4S14.206,16,12,16z"/>
      );
    case (iconTypes.DIAMOND_SOLID):
      return (
        <path d="M22.715,6h-6.406l-2.5-5h2.918L22.715,6z M7.809,6l2.5-5h2.382l2.5,5H7.809z M15.34,7L11.5,20.653L7.66,7H15.34z M6.273,1
        h2.918l-2.5,5H0.285L6.273,1z M0.523,7h6.098l4.237,15.064L0.523,7z M11.5,23L11.5,23L11.5,23L11.5,23L11.5,23z M12.142,22.064
        L16.379,7h6.098L12.142,22.064z"/>
      );
    case (iconTypes.MORE):
      return (
        <g>
        <g>
          <path d="M3,15c-1.654,0-3-1.346-3-3s1.346-3,3-3s3,1.346,3,3S4.654,15,3,15z M3,10c-1.103,0-2,0.897-2,2s0.897,2,2,2s2-0.897,2-2
            S4.103,10,3,10z"/>
        </g>
        <g>
          <path d="M12,15c-1.654,0-3-1.346-3-3s1.346-3,3-3s3,1.346,3,3S13.654,15,12,15z M12,10c-1.103,0-2,0.897-2,2s0.897,2,2,2
            s2-0.897,2-2S13.103,10,12,10z"/>
        </g>
        <g>
          <path d="M21,15c-1.654,0-3-1.346-3-3s1.346-3,3-3s3,1.346,3,3S22.654,15,21,15z M21,10c-1.103,0-2,0.897-2,2s0.897,2,2,2
            s2-0.897,2-2S22.103,10,21,10z"/>
        </g>
      </g>
      );
    case (iconTypes.LOCK):
      return (
<g>
	<g>
		<path d="M18.5,12c-0.276,0-0.5-0.224-0.5-0.5V7c0-3.309-2.691-6-6-6S6,3.691,6,7v4.5C6,11.776,5.776,12,5.5,12S5,11.776,5,11.5V7
			c0-3.86,3.14-7,7-7s7,3.14,7,7v4.5C19,11.776,18.776,12,18.5,12z"/>
	</g>
	<g>
		<path d="M16.5,12c-0.276,0-0.5-0.224-0.5-0.5V7c0-2.206-1.794-4-4-4S8,4.794,8,7v4.5C8,11.776,7.776,12,7.5,12S7,11.776,7,11.5V7
			c0-2.757,2.243-5,5-5s5,2.243,5,5v4.5C17,11.776,16.776,12,16.5,12z"/>
	</g>
	<g>
		<path d="M17.5,24h-11C5.122,24,4,22.878,4,21.5v-9C4,11.673,4.673,11,5.5,11h13c0.827,0,1.5,0.673,1.5,1.5v9
			C20,22.878,18.878,24,17.5,24z M5.5,12C5.224,12,5,12.224,5,12.5v9C5,22.327,5.673,23,6.5,23h11c0.827,0,1.5-0.673,1.5-1.5v-9
			c0-0.276-0.224-0.5-0.5-0.5H5.5z"/>
	</g>
	<g>
		<path d="M13.5,20h-3c-0.161,0-0.312-0.077-0.406-0.208c-0.094-0.13-0.12-0.298-0.068-0.451l0.624-1.872
			C10.237,17.095,10,16.569,10,16c0-1.103,0.897-2,2-2s2,0.897,2,2c0,0.569-0.237,1.095-0.65,1.47l0.624,1.872
			c0.051,0.152,0.025,0.32-0.068,0.451C13.812,19.923,13.661,20,13.5,20z M11.194,19h1.612l-0.519-1.556
			c-0.075-0.223,0.017-0.468,0.219-0.588C12.815,16.672,13,16.352,13,16c0-0.551-0.449-1-1-1s-1,0.449-1,1
			c0,0.352,0.185,0.672,0.494,0.856c0.202,0.12,0.293,0.365,0.219,0.588L11.194,19z"/>
	</g>
</g>
      );
    case (iconTypes.LOCK_SOLID):
      return (
<g>
	<g>
		<path d="M18.5,12c-0.276,0-0.5-0.224-0.5-0.5V7c0-3.309-2.691-6-6-6S6,3.691,6,7v4.5C6,11.776,5.776,12,5.5,12S5,11.776,5,11.5V7
			c0-3.86,3.14-7,7-7s7,3.14,7,7v4.5C19,11.776,18.776,12,18.5,12z"/>
	</g>
	<g>
		<path d="M16.5,12c-0.276,0-0.5-0.224-0.5-0.5V7c0-2.206-1.794-4-4-4S8,4.794,8,7v4.5C8,11.776,7.776,12,7.5,12S7,11.776,7,11.5V7
			c0-2.757,2.243-5,5-5s5,2.243,5,5v4.5C17,11.776,16.776,12,16.5,12z"/>
	</g>
	<g>
		<path d="M17.5,24h-11C5.122,24,4,22.878,4,21.5v-9C4,11.673,4.673,11,5.5,11h13c0.827,0,1.5,0.673,1.5,1.5v9
			C20,22.878,18.878,24,17.5,24z M5.5,12C5.224,12,5,12.224,5,12.5v9C5,22.327,5.673,23,6.5,23h11c0.827,0,1.5-0.673,1.5-1.5v-9
			c0-0.276-0.224-0.5-0.5-0.5H5.5z"/>
	</g>
	<g>
		<path d="M13.5,20h-3c-0.161,0-0.312-0.077-0.406-0.208c-0.094-0.13-0.12-0.298-0.068-0.451l0.624-1.872
			C10.237,17.095,10,16.569,10,16c0-1.103,0.897-2,2-2s2,0.897,2,2c0,0.569-0.237,1.095-0.65,1.47l0.624,1.872
			c0.051,0.152,0.025,0.32-0.068,0.451C13.812,19.923,13.661,20,13.5,20z M11.194,19h1.612l-0.519-1.556
			c-0.075-0.223,0.017-0.468,0.219-0.588C12.815,16.672,13,16.352,13,16c0-0.551-0.449-1-1-1s-1,0.449-1,1
			c0,0.352,0.185,0.672,0.494,0.856c0.202,0.12,0.293,0.365,0.219,0.588L11.194,19z"/>
	</g>
</g>);
    case (iconTypes.AT):
      return (
        <g>
        <g>
          <path d="M15.5,13"/>
          <path d="M12,24C5.383,24,0,18.617,0,12S5.383,0,12,0s12,5.383,12,12c0,2.862-2.376,5-4.5,5c-2.24,0-4.5-1.237-4.5-4l1.008-5.589
            c0.049-0.272,0.309-0.452,0.581-0.402c0.272,0.049,0.452,0.31,0.402,0.581l-1,5.5C16,15.214,17.885,16,19.5,16
            c1.619,0,3.5-1.747,3.5-4c0-6.065-4.935-11-11-11S1,5.935,1,12s4.935,11,11,11c2.513,0,4.878-0.825,6.84-2.385
            c0.216-0.173,0.53-0.137,0.702,0.08c0.172,0.216,0.136,0.53-0.08,0.702C17.322,23.1,14.741,24,12,24z"/>
        </g>
        <g>
          <path d="M11.5,17c-1.305,0-2.511-0.624-3.308-1.712c-0.837-1.142-1.087-2.614-0.668-3.938c0.673-2.133,2.224-3.648,4.255-4.159
            c1.733-0.438,3.529-0.063,4.571,0.952c0.198,0.192,0.202,0.509,0.009,0.707c-0.192,0.199-0.508,0.201-0.707,0.009
            c-0.785-0.765-2.241-1.045-3.628-0.698c-0.991,0.25-2.77,1.034-3.546,3.49c-0.322,1.021-0.127,2.16,0.522,3.046
            C9.605,15.525,10.518,16,11.5,16c2.043,0,3.221-1.862,3.513-3.114c0.063-0.269,0.333-0.437,0.601-0.373
            c0.269,0.063,0.436,0.332,0.373,0.601C15.535,15.045,13.849,17,11.5,17z"/>
        </g>
      </g>
      );
    case (iconTypes.STAR_SOLID):
      return (
        <g>
          <path d="M22.977,8.309C22.91,8.102,22.718,7.961,22.5,7.961h-8.133l-2.39-7.649c-0.131-0.416-0.824-0.416-0.955,0l-2.39,7.649H0.5
            c-0.218,0-0.41,0.141-0.477,0.348c-0.065,0.207,0.009,0.433,0.186,0.56l6.688,4.776l-2.865,7.641
            c-0.077,0.206-0.011,0.438,0.163,0.572c0.175,0.135,0.414,0.137,0.596,0.011l6.709-4.793l6.709,4.793
            c0.088,0.062,0.189,0.093,0.291,0.093c0.107,0,0.215-0.034,0.305-0.104c0.174-0.134,0.24-0.366,0.163-0.572l-2.865-7.641
            l6.688-4.776C22.968,8.742,23.042,8.516,22.977,8.309z"/>
      </g>
      );
    case (iconTypes.STAR):
      return (
        <g>
        <g>
          <path d="M19,23c-0.102,0-0.204-0.031-0.291-0.093L12,18.114l-6.709,4.792c-0.179,0.128-0.421,0.123-0.595-0.01
            c-0.175-0.134-0.241-0.366-0.164-0.572l2.865-7.641L0.709,9.907c-0.177-0.126-0.252-0.353-0.186-0.56C0.59,9.141,0.783,9,1,9
            h8.132l2.391-7.649c0.13-0.418,0.824-0.418,0.954,0L14.868,9H23c0.217,0,0.41,0.141,0.476,0.347
            c0.066,0.207-0.009,0.434-0.186,0.56l-6.688,4.777l2.865,7.641c0.077,0.206,0.011,0.438-0.164,0.572
            C19.215,22.965,19.107,23,19,23z M12,17c0.102,0,0.204,0.031,0.291,0.093l5.666,4.046l-2.424-6.464
            c-0.08-0.212-0.006-0.451,0.178-0.583L21.44,10H14.5c-0.219,0-0.412-0.142-0.477-0.351L12,3.176L9.977,9.649
            C9.912,9.858,9.719,10,9.5,10H2.56l5.73,4.093c0.184,0.131,0.257,0.37,0.178,0.583L6.044,21.14l5.666-4.046
            C11.796,17.031,11.898,17,12,17z"/>
        </g>
      </g>
      );
    case (iconTypes.BULB_SOLID):
      return (
<g>
	<path d="M12,0l-0.121,0.001C9.91,0.032,7.981,0.895,6.586,2.366C5.189,3.84,4.43,5.816,4.505,7.787
		c0.078,2.072,1.031,4.04,2.614,5.398c0.093,0.08,0.149,0.172,0.169,0.276C7.539,14.82,8.129,18,10,18h4
		c1.872,0,2.482-3.299,2.712-4.541c0.019-0.102,0.075-0.194,0.167-0.273c1.665-1.429,2.62-3.501,2.62-5.686
		C19.5,3.365,16.136,0,12,0z"/>
	<path d="M14,19h-4c-0.276,0-0.5,0.224-0.5,0.5S9.723,20,10,20h4c0.276,0,0.5-0.224,0.5-0.5S14.276,19,14,19z"/>
	<path d="M14,21h-4c-0.276,0-0.5,0.224-0.5,0.5S9.723,22,10,22h4c0.276,0,0.5-0.224,0.5-0.5S14.276,21,14,21z"/>
	<path d="M13,23h-2c-0.276,0-0.5,0.224-0.5,0.5S10.723,24,11,24h2c0.276,0,0.5-0.224,0.5-0.5S13.276,23,13,23z"/>
</g>
      );
    case (iconTypes.STAIRS_UP):
      return (
<g>
	<g>
		<path d="M23.5,17.5H19V14c0-0.276-0.224-0.5-0.5-0.5H14V10c0-0.276-0.224-0.5-0.5-0.5H9V6c0-0.276-0.224-0.5-0.5-0.5H4V2
			c0-0.276-0.224-0.5-0.5-0.5h-3C0.224,1.5,0,1.724,0,2s0.224,0.5,0.5,0.5H3V6c0,0.276,0.224,0.5,0.5,0.5H8V10
			c0,0.276,0.224,0.5,0.5,0.5H13V14c0,0.276,0.224,0.5,0.5,0.5H18V18c0,0.276,0.224,0.5,0.5,0.5H23V22c0,0.276,0.224,0.5,0.5,0.5
			S24,22.276,24,22v-4C24,17.724,23.776,17.5,23.5,17.5z M14.5,5.5C14.776,5.5,15,5.276,15,5V3.208l5.146,5.146
			C20.244,8.451,20.372,8.5,20.5,8.5s0.256-0.049,0.353-0.146c0.195-0.195,0.195-0.512,0-0.707L15.706,2.5H17.5
			C17.776,2.5,18,2.276,18,2s-0.224-0.5-0.5-0.5h-3c-0.029,0-0.055,0.012-0.083,0.017c-0.036,0.006-0.073,0.008-0.108,0.022
			c-0.061,0.025-0.117,0.062-0.163,0.108c0,0,0,0,0,0c-0.004,0.004-0.005,0.01-0.01,0.014c-0.04,0.043-0.075,0.092-0.098,0.147
			C14.013,1.871,14,1.936,14,2.002V5C14,5.276,14.224,5.5,14.5,5.5z"/>
	</g>
</g>
      );
    case (iconTypes.DOWNLOAD_CLOUD):
      return (
        <g>
        <g>
          <path d="M18.5,17h-4c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h4c2.481,0,4.5-2.019,4.5-4.5S20.981,7,18.5,7
            c-0.249,0-0.538-0.175-0.573-0.422C17.474,3.398,14.711,1,11.5,1C7.916,1,5,3.916,5,7.5c0,0.281,0.024,0.579,0.076,0.937
            C5.097,8.58,5.054,8.726,4.959,8.835c-0.11,0.128-0.321,0.187-0.492,0.164C2.57,9,1,10.57,1,12.5S2.57,16,4.5,16h4
            C8.776,16,9,16.224,9,16.5S8.776,17,8.5,17h-4C2.019,17,0,14.981,0,12.5c0-2.32,1.765-4.235,4.022-4.475
            C4.007,7.843,4,7.67,4,7.5C4,3.364,7.364,0,11.5,0c3.564,0,6.65,2.561,7.346,6.022C21.754,6.228,24,8.606,24,11.5
            C24,14.532,21.532,17,18.5,17z"/>
        </g>
        <g>
          <path d="M11.5,24c-0.01,0-0.019,0-0.028-0.001l0,0h-0.001c-0.118-0.007-0.234-0.056-0.324-0.146l-4-4
            C7.049,19.756,7,19.628,7,19.5s0.049-0.256,0.146-0.354c0.195-0.195,0.512-0.195,0.707,0L11,22.293V8.5C11,8.224,11.224,8,11.5,8
            S12,8.224,12,8.5v13.793l3.146-3.146c0.195-0.195,0.512-0.195,0.707,0s0.195,0.512,0,0.707l-3.997,3.997
            c-0.01,0.011-0.021,0.021-0.032,0.03c0,0,0,0-0.001,0l0,0c-0.077,0.066-0.17,0.104-0.266,0.116c0,0,0,0-0.001,0l0,0
            C11.538,23.999,11.519,24,11.5,24z"/>
        </g>
      </g>
      );
    case (iconTypes.PDF):
      return (
        <g>
          <g>
            <g>
              <g>
                <path d="M20.5,24h-17C3.224,24,3,23.776,3,23.5v-17c0-0.133,0.053-0.26,0.146-0.354l6-6C9.24,0.053,9.367,0,9.5,0h11
                  C20.776,0,21,0.224,21,0.5v23C21,23.776,20.776,24,20.5,24z M4,23h16V1H9.707L4,6.707V23z"/>
              </g>
              <g>
                <path d="M9.5,7h-6C3.224,7,3,6.776,3,6.5S3.224,6,3.5,6H9V0.5C9,0.224,9.224,0,9.5,0S10,0.224,10,0.5v6C10,6.776,9.776,7,9.5,7z
                  "/>
              </g>
            </g>
          </g>
          <g>
            <path d="M6.453,22.066c-0.192,0-0.39-0.027-0.591-0.085c-0.704-0.203-0.835-0.627-0.851-0.861
              c-0.056-0.834,1.455-2.013,4.497-3.513c0.35-0.712,0.642-1.394,0.854-1.887l0.18-0.419c0.206-0.473,0.415-1.052,0.602-1.667
              c-1.153-1.728-1.379-3.578-0.926-4.671C10.47,8.351,10.938,8,11.5,8c0.003,0,0.006,0,0.009,0c0.413,0.008,0.764,0.205,0.99,0.556
              c0.615,0.958,0.242,3.118-0.261,4.889c0.182,0.237,0.386,0.472,0.615,0.701c0.612,0.611,1.23,1.122,1.818,1.537
              c1.512-0.267,2.965-0.243,3.725,0.252C18.786,16.189,19,16.567,19,17c0,0.465-0.231,0.828-0.635,0.995
              c-0.845,0.35-2.387-0.254-3.899-1.253c-0.749,0.156-1.532,0.393-2.265,0.716c-0.7,0.308-1.352,0.611-1.949,0.904
              C9.315,20.208,8.039,22.066,6.453,22.066z M6.049,20.99c0.024,0.009,0.053,0.02,0.089,0.03c0.869,0.252,1.74-0.644,2.486-1.817
              C7.173,19.999,6.263,20.646,6.049,20.99z M16.052,16.528c1.006,0.518,1.757,0.679,1.946,0.535c0.002-0.125-0.01-0.2-0.148-0.29
              C17.535,16.567,16.873,16.482,16.052,16.528z M11.884,14.579c-0.156,0.457-0.306,0.845-0.426,1.121l-0.178,0.414
              c-0.108,0.253-0.227,0.53-0.356,0.821c0.281-0.129,0.573-0.259,0.874-0.392c0.496-0.218,1.043-0.412,1.608-0.569
              c-0.446-0.354-0.873-0.732-1.26-1.12C12.056,14.763,11.969,14.671,11.884,14.579z M11.496,9c-0.142,0.002-0.261,0.118-0.355,0.345
              c-0.24,0.579-0.196,1.701,0.371,2.909c0.316-1.383,0.448-2.688,0.146-3.157C11.607,9.018,11.564,9.002,11.496,9z"/>
          </g>
        </g>
      );
    case (iconTypes.TRIANGLE_RIGHT):
      return (
        <g>
          <path d="M2.5,23.5c-0.086,0-0.173-0.022-0.25-0.067C2.096,23.344,2,23.178,2,23V1c0-0.179,0.096-0.344,0.25-0.433
            c0.156-0.089,0.347-0.088,0.501,0l19,11C21.905,11.656,22,11.822,22,12s-0.095,0.343-0.249,0.433l-19,11
            C2.673,23.477,2.587,23.5,2.5,23.5z M3,1.867v20.266L20.502,12L3,1.867z"/>
        </g>
      );
    case (iconTypes.MONOCLE):
      return (
        <g>
        <g>
          <path d="M22,6.5c-0.225,0-0.437,0.054-0.629,0.143C20.73,3.154,17.672,0.5,14,0.5C9.864,0.5,6.5,3.864,6.5,8s3.364,7.5,7.5,7.5
            c3.672,0,6.73-2.654,7.371-6.143C21.563,9.446,21.775,9.5,22,9.5c0.176,0,0.343-0.036,0.5-0.092V19c0,0.276,0.224,0.5,0.5,0.5
            s0.5-0.224,0.5-0.5V8C23.5,7.173,22.827,6.5,22,6.5z M14,14.5c-3.584,0-6.5-2.916-6.5-6.5s2.916-6.5,6.5-6.5
            c3.584,0,6.5,2.916,6.5,6.5S17.584,14.5,14,14.5z M22,8.5c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5
            S22.276,8.5,22,8.5z M14,2.5c-3.033,0-5.5,2.467-5.5,5.5c0,0.276,0.224,0.5,0.5,0.5S9.5,8.276,9.5,8c0-2.481,2.019-4.5,4.5-4.5
            c0.276,0,0.5-0.224,0.5-0.5S14.276,2.5,14,2.5z M19.084,22.507c-1.811-0.308-3.245-1.247-4.632-2.154
            C12.995,19.4,11.619,18.5,10,18.5c-1.619,0-2.995,0.9-4.452,1.853c-1.387,0.907-2.821,1.845-4.632,2.154
            c-0.256,0.044-0.436,0.276-0.414,0.535C0.523,23.301,0.74,23.5,1,23.5c2.329,0,4-0.617,5.474-1.16C7.698,21.89,8.754,21.5,10,21.5
            c1.202,0,2.154,0.37,3.256,0.798C14.707,22.861,16.351,23.5,19,23.5c0.26,0,0.477-0.199,0.498-0.458
            C19.52,22.783,19.34,22.55,19.084,22.507z M13.618,21.366C12.473,20.92,11.39,20.5,10,20.5c-1.424,0-2.613,0.438-3.872,0.902
            c-0.271,0.1-0.544,0.2-0.822,0.298c0.271-0.171,0.534-0.343,0.79-0.51C7.481,20.283,8.679,19.5,10,19.5
            c1.321,0,2.518,0.783,3.904,1.69c0.331,0.217,0.673,0.44,1.028,0.66C14.464,21.695,14.033,21.527,13.618,21.366z"/>
        </g>
      </g>
      );
    case (iconTypes.MELONIK):
      return (
<g>
	<g>
		<path d="M8,8H6C5.172,8,4.5,8.673,4.5,9.5v2C4.5,11.776,4.724,12,5,12s0.5-0.224,0.5-0.5v-2C5.5,9.224,5.724,9,6,9h2
			c0.276,0,0.5-0.224,0.5-0.5S8.276,8,8,8z M21.5,13.976V8.5C21.5,7.122,20.378,6,19,6H5C3.622,6,2.5,7.122,2.5,8.5v5.475
			c-1.356,0.401-2,0.888-2,1.525c0,2.472,11.03,2.5,11.5,2.5c0.47,0,11.5-0.028,11.501-2.499C23.5,14.864,22.856,14.376,21.5,13.976
			z M3.5,8.5C3.5,7.673,4.172,7,5,7h14c0.827,0,1.5,0.673,1.5,1.5v5.567C18.985,14.285,13.865,15,12,15
			c-1.864,0-6.986-0.716-8.5-0.933V8.5z M12,17c-6.491,0-10.235-0.987-10.507-1.459c0.002-0.004,0.157-0.273,1.268-0.603
			c0.051,0.028,0.106,0.048,0.166,0.057C3.203,15.036,9.739,16,12,16s8.796-0.964,9.073-1.005c0.059-0.009,0.115-0.029,0.165-0.056
			c1.003,0.3,1.231,0.55,1.263,0.533C22.182,16.026,18.445,17,12,17z"/>
	</g>
</g>
      );
    case (iconTypes.BALANCE):
      return (
<g>
	<path d="M12,3c-0.827,0-1.5-0.673-1.5-1.5S11.173,0,12,0s1.5,0.673,1.5,1.5S12.827,3,12,3z M12,1c-0.276,0-0.5,0.224-0.5,0.5
		S11.724,2,12,2s0.5-0.224,0.5-0.5S12.276,1,12,1z"/>
	<path d="M12,21c-0.276,0-0.5-0.224-0.5-0.5V4.121C10.6,4.664,9.226,4.89,7.953,5.101C6.401,5.356,4.65,5.646,4.509,6.4l2.938,5.876
		c0.078,0.155,0.069,0.339-0.022,0.486S7.173,13,7,13H1c-0.173,0-0.334-0.09-0.425-0.237s-0.1-0.332-0.022-0.486l2.952-5.903
		c0.107-1.57,2.23-1.92,4.286-2.26C9.614,3.813,11.5,3.501,11.5,2.5C11.5,2.224,11.724,2,12,2s0.5,0.224,0.5,0.5v18
		C12.5,20.776,12.276,21,12,21z M1.809,12h4.382L4,7.618L1.809,12z"/>
	<path d="M12,18c-1.93,0-3.5-1.57-3.5-3.5C8.5,14.224,8.724,14,9,14s0.5,0.224,0.5,0.5c0,1.378,1.122,2.5,2.5,2.5s2.5-1.122,2.5-2.5
		c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5C15.5,16.43,13.93,18,12,18z"/>
	<path d="M17,24H7c-0.276,0-0.5-0.224-0.5-0.5c0-1.962,2.416-3.5,5.5-3.5s5.5,1.538,5.5,3.5C17.5,23.776,17.276,24,17,24z M7.595,23
		h8.811c-0.43-1.125-2.273-2-4.405-2S8.025,21.875,7.595,23z"/>
	<path d="M4,16c-1.93,0-3.5-1.57-3.5-3.5C0.5,12.224,0.724,12,1,12s0.5,0.224,0.5,0.5C1.5,13.878,2.622,15,4,15s2.5-1.122,2.5-2.5
		C6.5,12.224,6.724,12,7,12s0.5,0.224,0.5,0.5C7.5,14.43,5.93,16,4,16z"/>
	<path d="M23,13h-6c-0.173,0-0.334-0.09-0.425-0.237s-0.1-0.332-0.022-0.486L19.491,6.4c-0.141-0.755-1.892-1.044-3.444-1.3
		C13.916,4.749,11.5,4.35,11.5,2.5C11.5,2.224,11.724,2,12,2s0.5,0.224,0.5,0.5c0,1.001,1.886,1.313,3.71,1.614
		c2.055,0.339,4.178,0.689,4.286,2.26l2.952,5.903c0.078,0.155,0.069,0.339-0.022,0.486S23.173,13,23,13z M17.809,12h4.382L20,7.618
		L17.809,12z"/>
	<path d="M20,16c-1.93,0-3.5-1.57-3.5-3.5c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5c0,1.378,1.122,2.5,2.5,2.5
		s2.5-1.122,2.5-2.5c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5C23.5,14.43,21.93,16,20,16z"/>
</g>
      );
    case (iconTypes.ANCHOR):
      return (
<g id="Anchor">
	<path d="M23.393,17.19c-0.13-0.166-0.35-0.229-0.551-0.165l-3,1c-0.262,0.088-0.403,0.371-0.316,0.633s0.368,0.405,0.632,0.316
		l1.55-0.517c-2.216,2.743-5.544,4.376-9.208,4.515v-9.993H16c0.276,0,0.5-0.224,0.5-0.5s-0.224-0.5-0.5-0.5h-3.5v-7.03
		c1.14-0.232,2-1.242,2-2.449C14.5,1.121,13.378,0,12,0S9.5,1.121,9.5,2.5c0,1.208,0.86,2.217,2,2.449v7.03H8
		c-0.276,0-0.5,0.224-0.5,0.5s0.224,0.5,0.5,0.5h3.5v9.992c-3.516-0.146-6.878-1.802-9.153-4.496l1.495,0.499
		c0.263,0.089,0.545-0.055,0.632-0.316s-0.054-0.545-0.316-0.633l-3-1c-0.202-0.063-0.423,0.001-0.554,0.169
		c-0.13,0.168-0.14,0.399-0.024,0.578C3.077,21.613,7.452,24,12,24c4.729,0,9-2.331,11.425-6.236
		C23.536,17.585,23.523,17.355,23.393,17.19z M10.5,2.5C10.5,1.673,11.173,1,12,1s1.5,0.673,1.5,1.5S12.827,4,12,4
		S10.5,3.327,10.5,2.5z"/>
</g>
      );
    case (iconTypes.SMILE):
      return (
<g>
	<g>
		<path d="M12,24C5.383,24,0,18.617,0,12S5.383,0,12,0s12,5.383,12,12S18.617,24,12,24z M12,1C5.935,1,1,5.935,1,12s4.935,11,11,11
			s11-4.935,11-11S18.065,1,12,1z"/>
	</g>
	<g>
		<path d="M7.5,10C6.673,10,6,9.327,6,8.5S6.673,7,7.5,7S9,7.673,9,8.5S8.327,10,7.5,10z M7.5,8C7.224,8,7,8.224,7,8.5
			S7.224,9,7.5,9S8,8.776,8,8.5S7.776,8,7.5,8z"/>
	</g>
	<g>
		<path d="M16.5,10C15.673,10,15,9.327,15,8.5S15.673,7,16.5,7S18,7.673,18,8.5S17.327,10,16.5,10z M16.5,8
			C16.224,8,16,8.224,16,8.5S16.224,9,16.5,9S17,8.776,17,8.5S16.776,8,16.5,8z"/>
	</g>
	<g>
		<path d="M12,19c-2.393,0-4.596-1.208-5.893-3.23c-0.149-0.232-0.082-0.542,0.151-0.691c0.231-0.15,0.542-0.082,0.691,0.151
			C8.062,16.964,9.95,18,12,18s3.938-1.036,5.051-2.77c0.149-0.232,0.458-0.301,0.691-0.151c0.232,0.149,0.3,0.458,0.151,0.691
			C16.596,17.792,14.393,19,12,19z"/>
	</g>
</g>
      );
    case (iconTypes.SAD):
      return (
<g>
	<g>
		<path d="M12,24C5.383,24,0,18.617,0,12S5.383,0,12,0s12,5.383,12,12S18.617,24,12,24z M12,1C5.935,1,1,5.935,1,12s4.935,11,11,11
			s11-4.935,11-11S18.065,1,12,1z"/>
	</g>
	<g>
		<path d="M7.5,10C6.673,10,6,9.327,6,8.5S6.673,7,7.5,7S9,7.673,9,8.5S8.327,10,7.5,10z M7.5,8C7.224,8,7,8.224,7,8.5
			S7.224,9,7.5,9S8,8.776,8,8.5S7.776,8,7.5,8z"/>
	</g>
	<g>
		<path d="M16.5,10C15.673,10,15,9.327,15,8.5S15.673,7,16.5,7S18,7.673,18,8.5S17.327,10,16.5,10z M16.5,8
			C16.224,8,16,8.224,16,8.5S16.224,9,16.5,9S17,8.776,17,8.5S16.776,8,16.5,8z"/>
	</g>
	<g>
		<path d="M17.472,19c-0.165,0-0.326-0.081-0.421-0.23C15.938,17.036,14.05,16,12,16c-2.051,0-3.938,1.036-5.05,2.77
			c-0.149,0.231-0.459,0.3-0.691,0.151c-0.232-0.149-0.3-0.458-0.151-0.691C7.404,16.208,9.607,15,12,15
			c2.393,0,4.596,1.208,5.893,3.23c0.149,0.232,0.082,0.542-0.151,0.691C17.658,18.975,17.564,19,17.472,19z"/>
	</g>
</g>
      );
    case (iconTypes.ROBOT):
      return (
<g id="Retro_Bot_3">
	<g id="Retro_bot_3">
		<g>
			<path d="M15.5,8h-8C7.224,8,7,7.776,7,7.5v-7C7,0.224,7.224,0,7.5,0h8C15.776,0,16,0.224,16,0.5v7C16,7.776,15.776,8,15.5,8z
				 M8,7h7V1H8V7z"/>
		</g>
		<g>
			<path d="M13.5,10h-4C9.224,10,9,9.776,9,9.5v-2C9,7.224,9.224,7,9.5,7h4C13.776,7,14,7.224,14,7.5v2C14,9.776,13.776,10,13.5,10z
				 M10,9h3V8h-3V9z"/>
		</g>
		<g>
			<path d="M16.5,19h-10C6.224,19,6,18.776,6,18.5v-9C6,9.224,6.224,9,6.5,9h10C16.776,9,17,9.224,17,9.5v9
				C17,18.776,16.776,19,16.5,19z M7,18h9v-8H7V18z"/>
		</g>
		<g>
			<path d="M10.5,24h-4C6.224,24,6,23.776,6,23.5C6,22.122,7.122,21,8.5,21s2.5,1.122,2.5,2.5C11,23.776,10.776,24,10.5,24z
				 M7.085,23h2.829c-0.207-0.582-0.763-1-1.415-1S7.292,22.418,7.085,23z"/>
		</g>
		<g>
			<path d="M16.5,24h-4c-0.276,0-0.5-0.224-0.5-0.5c0-1.378,1.122-2.5,2.5-2.5s2.5,1.122,2.5,2.5C17,23.776,16.776,24,16.5,24z
				 M13.085,23h2.829c-0.207-0.582-0.763-1-1.415-1S13.292,22.418,13.085,23z"/>
		</g>
		<g>
			<path d="M8.5,22C8.224,22,8,21.776,8,21.5v-3C8,18.224,8.224,18,8.5,18S9,18.224,9,18.5v3C9,21.776,8.776,22,8.5,22z"/>
		</g>
		<g>
			<path d="M14.5,22c-0.276,0-0.5-0.224-0.5-0.5v-3c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v3C15,21.776,14.776,22,14.5,22z"/>
		</g>
		<g>
			<path d="M4.5,17C4.224,17,4,16.776,4,16.5v-4C4,11.122,5.122,10,6.5,10C6.776,10,7,10.224,7,10.5S6.776,11,6.5,11
				C5.673,11,5,11.673,5,12.5v4C5,16.776,4.776,17,4.5,17z"/>
		</g>
		<g>
			<path d="M18.5,17c-0.276,0-0.5-0.224-0.5-0.5v-4c0-0.827-0.673-1.5-1.5-1.5c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5
				c1.378,0,2.5,1.122,2.5,2.5v4C19,16.776,18.776,17,18.5,17z"/>
		</g>
		<g>
			<path d="M12.5,15h-4C8.224,15,8,14.776,8,14.5v-3C8,11.224,8.224,11,8.5,11h4c0.276,0,0.5,0.224,0.5,0.5v3
				C13,14.776,12.776,15,12.5,15z M9,14h3v-2H9V14z"/>
		</g>
		<g>
			<circle cx="14.5" cy="11.5" r="0.5"/>
		</g>
		<g>
			<circle cx="14.5" cy="14.5" r="0.5"/>
		</g>
	</g>
	<g>
		<path d="M10.5,4c-0.551,0-1-0.449-1-1s0.449-1,1-1s1,0.449,1,1S11.051,4,10.5,4z M10.5,2.999C10.5,3,10.5,3,10.5,2.999L11,3
			L10.5,2.999z"/>
	</g>
	<g>
		<path d="M13.5,4c-0.551,0-1-0.449-1-1s0.449-1,1-1s1,0.449,1,1S14.051,4,13.5,4z M13.5,2.999C13.5,3,13.5,3,13.5,2.999L14,3
			L13.5,2.999z"/>
	</g>
	<g>
		<path d="M13,6h-2c-0.276,0-0.5-0.224-0.5-0.5S10.724,5,11,5h2c0.276,0,0.5,0.224,0.5,0.5S13.276,6,13,6z"/>
	</g>
	<g>
		<path d="M16,5.5c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5c0.276,0,0.5-0.224,0.5-0.5S16.276,3.5,16,3.5
			c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5c0.827,0,1.5,0.673,1.5,1.5S16.827,5.5,16,5.5z"/>
	</g>
	<g>
		<path d="M8,5.5C7.173,5.5,6.5,4.827,6.5,4S7.173,2.5,8,2.5c0.276,0,0.5,0.224,0.5,0.5S8.276,3.5,8,3.5C7.724,3.5,7.5,3.724,7.5,4
			S7.724,4.5,8,4.5c0.276,0,0.5,0.224,0.5,0.5S8.276,5.5,8,5.5z"/>
	</g>
</g>
      );
    case (iconTypes.SAVE):
      return (
        <g>
        <g>
          <path d="M22.5,24h-21C0.673,24,0,23.327,0,22.5v-21C0,0.673,0.673,0,1.5,0h18c0.133,0,0.26,0.053,0.354,0.146l4,4
            C23.947,4.24,24,4.367,24,4.5v18C24,23.327,23.327,24,22.5,24z M1.5,1C1.225,1,1,1.224,1,1.5v21C1,22.776,1.225,23,1.5,23h21
            c0.275,0,0.5-0.224,0.5-0.5V4.707L19.293,1H1.5z"/>
        </g>
        <g>
          <path d="M16.5,8h-9C6.673,8,6,7.327,6,6.5v-6C6,0.224,6.224,0,6.5,0h11C17.776,0,18,0.224,18,0.5v6C18,7.327,17.327,8,16.5,8z
             M7,1v5.5C7,6.776,7.225,7,7.5,7h9C16.775,7,17,6.776,17,6.5V1H7z"/>
        </g>
        <g>
          <path d="M20.5,24h-17C3.224,24,3,23.776,3,23.5v-12C3,10.122,4.121,9,5.5,9h13c1.379,0,2.5,1.122,2.5,2.5v12
            C21,23.776,20.776,24,20.5,24z M4,23h16V11.5c0-0.827-0.673-1.5-1.5-1.5h-13C4.673,10,4,10.673,4,11.5V23z"/>
        </g>
        <g>
          <path d="M15.5,6h-2C13.224,6,13,5.776,13,5.5v-3C13,2.224,13.224,2,13.5,2h2C15.776,2,16,2.224,16,2.5v3
            C16,5.776,15.776,6,15.5,6z M14,5h1V3h-1V5z"/>
        </g>
        <g>
          <path d="M17.5,14h-11C6.224,14,6,13.776,6,13.5S6.224,13,6.5,13h11c0.276,0,0.5,0.224,0.5,0.5S17.776,14,17.5,14z"/>
        </g>
        <g>
          <path d="M17.5,17h-11C6.224,17,6,16.776,6,16.5S6.224,16,6.5,16h11c0.276,0,0.5,0.224,0.5,0.5S17.776,17,17.5,17z"/>
        </g>
        <g>
          <path d="M17.5,20h-11C6.224,20,6,19.776,6,19.5S6.224,19,6.5,19h11c0.276,0,0.5,0.224,0.5,0.5S17.776,20,17.5,20z"/>
        </g>
      </g>
      );
    case (iconTypes.PLAY):
      return (
        <g>
        <path d="M1.5,24.001c-0.273,0-0.546-0.075-0.789-0.226C0.266,23.5,0,23.023,0,22.5v-21c0-0.523,0.266-1,0.711-1.275
          c0.446-0.275,0.992-0.3,1.459-0.066l21,10.5C23.682,10.914,24,11.428,24,12s-0.318,1.086-0.829,1.342l-21,10.5
          C1.958,23.948,1.728,24.001,1.5,24.001z M1.501,0.999c-0.123,0-0.218,0.048-0.265,0.076C1.166,1.119,1,1.249,1,1.5v21
          c0,0.251,0.166,0.381,0.237,0.425c0.072,0.043,0.262,0.136,0.487,0.022l21-10.5C22.896,12.361,23,12.193,23,12
          s-0.104-0.361-0.276-0.447l-21-10.5C1.644,1.013,1.569,0.999,1.501,0.999z"/>
      </g>
      );
    case (iconTypes.TIK_TOK):
      return (
      <svg height="512pt" viewBox="-32 0 512 512" width="512pt" xmlns="http://www.w3.org/2000/svg"><path d="m432.734375 112.464844c-53.742187 0-97.464844-43.722656-97.464844-97.464844 0-8.285156-6.71875-15-15-15h-80.335937c-8.285156 0-15 6.714844-15 15v329.367188c0 31.59375-25.703125 57.296874-57.300782 57.296874-31.59375 0-57.296874-25.703124-57.296874-57.296874 0-31.597657 25.703124-57.300782 57.296874-57.300782 8.285157 0 15-6.714844 15-15v-80.335937c0-8.28125-6.714843-15-15-15-92.433593 0-167.632812 75.203125-167.632812 167.636719 0 92.433593 75.199219 167.632812 167.632812 167.632812 92.433594 0 167.636719-75.199219 167.636719-167.632812v-145.792969c29.855469 15.917969 63.074219 24.226562 97.464844 24.226562 8.285156 0 15-6.714843 15-15v-80.335937c0-8.28125-6.714844-15-15-15zm-15 79.714844c-32.023437-2.664063-62.433594-13.851563-88.707031-32.75-4.566406-3.289063-10.589844-3.742188-15.601563-1.171876-5.007812 2.5625-8.15625 7.71875-8.15625 13.347657v172.761719c0 75.890624-61.746093 137.632812-137.636719 137.632812-75.890624 0-137.632812-61.742188-137.632812-137.632812 0-70.824219 53.773438-129.328126 122.632812-136.824219v50.8125c-41.015624 7.132812-72.296874 42.984375-72.296874 86.011719 0 48.136718 39.160156 87.300781 87.296874 87.300781 48.140626 0 87.300782-39.164063 87.300782-87.300781v-314.367188h51.210937c6.871094 58.320312 53.269531 104.71875 111.589844 111.589844zm0 0"/></svg>
      );
    case (iconTypes.YOU_TUBE):
      return (
        <g>
        <g>
          <path d="M12,20.851c-1.172,0-2.345-0.05-3.509-0.152l-5.294-0.46C1.375,20.081,0,18.582,0,16.752V7.253
            c0-1.83,1.375-3.329,3.197-3.487l5.294-0.46c2.328-0.203,4.689-0.203,7.018,0l5.294,0.46C22.625,3.925,24,5.424,24,7.253v9.499
            c0,1.83-1.375,3.329-3.197,3.487l-5.294,0.46C14.345,20.801,13.172,20.851,12,20.851z M12,4.154c-1.144,0-2.287,0.049-3.422,0.148
            l-5.294,0.46C1.982,4.876,1,5.947,1,7.253v9.499c0,1.307,0.982,2.377,2.284,2.491l5.294,0.46c2.271,0.197,4.573,0.197,6.844,0
            l5.294-0.46l0,0C22.018,19.13,23,18.059,23,16.752V7.253c0-1.307-0.982-2.377-2.284-2.491l-5.294-0.46
            C14.287,4.204,13.144,4.154,12,4.154z"/>
        </g>
        <g>
          <path d="M9.5,17.003c-0.083,0-0.165-0.021-0.24-0.061C9.1,16.854,9,16.686,9,16.503v-9C9,7.32,9.1,7.152,9.26,7.064
            c0.161-0.088,0.356-0.081,0.51,0.019l7,4.5c0.143,0.092,0.229,0.25,0.229,0.42s-0.086,0.329-0.229,0.42l-7,4.5
            C9.688,16.976,9.594,17.003,9.5,17.003z M10,8.419v7.168l5.575-3.584L10,8.419z"/>
        </g>
      </g>
      );
    case (iconTypes.LINKED_IN):
      return (
        <g>
          <g>
            <path d="M3,6.002c-1.654,0-3-1.346-3-3s1.346-3,3-3s3,1.346,3,3S4.654,6.002,3,6.002z M3,1.002c-1.103,0-2,0.897-2,2s0.897,2,2,2
              s2-0.897,2-2S4.103,1.002,3,1.002z"/>
          </g>
          <g>
            <path d="M5.5,24.002h-5c-0.276,0-0.5-0.224-0.5-0.5v-15c0-0.276,0.224-0.5,0.5-0.5h5c0.276,0,0.5,0.224,0.5,0.5v15
              C6,23.779,5.776,24.002,5.5,24.002z M1,23.002h4v-14H1V23.002z"/>
          </g>
          <g>
            <path d="M23.5,24.002h-5c-0.276,0-0.5-0.224-0.5-0.5v-9c0-1.135-0.473-1.987-1.299-2.336c-0.853-0.362-1.894-0.14-2.701,0.556
              v10.78c0,0.276-0.224,0.5-0.5,0.5h-5c-0.276,0-0.5-0.224-0.5-0.5v-15c0-0.276,0.224-0.5,0.5-0.5h5c0.233,0,0.429,0.16,0.484,0.375
              c1.604-1.154,4.276-1.796,6.413-1.064C22.01,7.866,23.943,9.386,24,13.496v10.007C24,23.779,23.776,24.002,23.5,24.002z
              M19,23.002h4v-9.5c-0.038-2.785-1.051-4.601-2.927-5.243c-2.33-0.798-5.266,0.407-6.183,1.555
              c-0.133,0.167-0.357,0.229-0.556,0.16C13.134,9.904,13,9.715,13,9.502v-0.5H9v14h4v-10.5c0-0.135,0.055-0.265,0.151-0.358
              c1.118-1.086,2.667-1.436,3.939-0.899c1.214,0.514,1.91,1.701,1.909,3.257V23.002z"/>
          </g>
        </g>
      );
    case (iconTypes.LINKED_IN_SOLID):
      return (
        <g>
          <circle cx="3" cy="3" r="3"/>
          <path d="M5.5,8h-5C0.224,8,0,8.224,0,8.5v15C0,23.776,0.224,24,0.5,24h5C5.776,24,6,23.776,6,23.5v-15C6,8.224,5.776,8,5.5,8z"/>
          <path d="M20.397,7.311c-2.137-0.732-4.81-0.089-6.413,1.064C13.929,8.16,13.733,8,13.5,8h-5C8.224,8,8,8.224,8,8.5v15
            C8,23.776,8.224,24,8.5,24h5c0.276,0,0.5-0.224,0.5-0.5V12.72c0.808-0.696,1.849-0.918,2.701-0.556
            C17.527,12.513,18,13.365,18,14.5v9c0,0.276,0.224,0.5,0.5,0.5h5c0.276,0,0.5-0.224,0.5-0.5V13.493
            C23.943,9.384,22.01,7.863,20.397,7.311z"/>
        </g>
      );
    case (iconTypes.PLAY_SOLID):
      return (
          <g id="Play">
            <path d="M23.171,10.658l-21-10.5c-0.47-0.234-1.014-0.21-1.459,0.066C0.266,0.5,0,0.977,0,1.5v21c0,0.523,0.266,1,0.712,1.276
              c0.242,0.15,0.514,0.226,0.787,0.226c0.229,0,0.458-0.053,0.672-0.16l21-10.5C23.683,13.086,24,12.572,24,12
              S23.683,10.914,23.171,10.658z"/>
          </g>
      );
    case (iconTypes.EXCEL):
      return (
      <g>
        <g>
          <path d="M13.5,23.5c-0.025,0-0.051-0.002-0.076-0.006l-13-2C0.18,21.456,0,21.246,0,21V4c0-0.233,0.161-0.435,0.388-0.487l13-3
            c0.148-0.033,0.305,0.001,0.424,0.096C13.931,0.703,14,0.847,14,1v22c0,0.146-0.063,0.285-0.174,0.379
            C13.734,23.458,13.619,23.5,13.5,23.5z M1,20.571l12,1.846V1.628L1,4.398V20.571z"/>
        </g>
        <g>
          <path d="M23.5,21.5h-10c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5H23v-17h-9.5C13.224,3.5,13,3.276,13,3s0.224-0.5,0.5-0.5h10
            C23.776,2.5,24,2.723,24,3v18C24,21.276,23.776,21.5,23.5,21.5z"/>
        </g>
        <g>
          <path d="M9.5,16.5c-0.167,0-0.33-0.083-0.424-0.235l-5-8C3.93,8.03,4.001,7.722,4.235,7.576C4.47,7.429,4.778,7.5,4.924,7.735l5,8
            c0.146,0.234,0.075,0.543-0.159,0.689C9.683,16.475,9.591,16.5,9.5,16.5z"/>
        </g>
        <g>
          <path d="M4.5,16.5c-0.09,0-0.182-0.024-0.265-0.076c-0.234-0.146-0.305-0.455-0.159-0.689l5-8C9.222,7.5,9.53,7.429,9.765,7.576
            C9.999,7.722,10.07,8.03,9.924,8.265l-5,8C4.829,16.417,4.666,16.5,4.5,16.5z"/>
        </g>
        <g>
          <path d="M17.5,21.5c-0.276,0-0.5-0.224-0.5-0.5V3c0-0.276,0.224-0.5,0.5-0.5S18,2.723,18,3v18C18,21.276,17.776,21.5,17.5,21.5z"
            />
        </g>
        <g>
          <path d="M23.5,18.5h-10c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h10c0.276,0,0.5,0.224,0.5,0.5S23.776,18.5,23.5,18.5z"/>
        </g>
        <g>
          <path d="M23.5,15.5h-10c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h10c0.276,0,0.5,0.224,0.5,0.5S23.776,15.5,23.5,15.5z"/>
        </g>
        <g>
          <path d="M23.5,12.5h-10c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h10c0.276,0,0.5,0.224,0.5,0.5S23.776,12.5,23.5,12.5z"/>
        </g>
        <g>
          <path d="M23.5,9.5h-10C13.224,9.5,13,9.276,13,9s0.224-0.5,0.5-0.5h10C23.776,8.5,24,8.723,24,9S23.776,9.5,23.5,9.5z"/>
        </g>
        <g>
          <path d="M23.5,6.5h-10C13.224,6.5,13,6.276,13,6s0.224-0.5,0.5-0.5h10C23.776,5.5,24,5.723,24,6S23.776,6.5,23.5,6.5z"/>
        </g>
      </g>
      );
    case (iconTypes.EXCEL_SOLID):
      return (
      <path d="M23.5,2.5H14V1c0-0.152-0.069-0.296-0.188-0.391c-0.119-0.095-0.275-0.129-0.424-0.096l-13,3C0.161,3.565,0,3.767,0,4v17
        c0,0.247,0.18,0.457,0.424,0.494l13,2c0.025,0.004,0.051,0.006,0.076,0.006c0.119,0,0.234-0.042,0.326-0.121
        C13.937,23.284,14,23.146,14,23v-1.5h9.5c0.276,0,0.5-0.224,0.5-0.5V3C24,2.723,23.776,2.5,23.5,2.5z M14,9.5h3v2h-3V9.5z
        M4.076,15.735l2.169-3.47c0.102-0.162,0.102-0.368,0-0.53l-2.168-3.47C3.93,8.031,4.001,7.722,4.235,7.576
        C4.47,7.43,4.778,7.501,4.924,7.735l1.652,2.643c0.183,0.292,0.665,0.292,0.848,0l1.652-2.643c0.071-0.114,0.183-0.193,0.313-0.223
        c0.13-0.028,0.264-0.007,0.376,0.063c0.234,0.146,0.306,0.456,0.16,0.689l-2.169,3.47c-0.102,0.162-0.102,0.368,0,0.53l2.168,3.47
        c0.146,0.234,0.075,0.543-0.159,0.689c-0.227,0.141-0.548,0.066-0.689-0.16l-1.652-2.643C7.333,13.475,7.172,13.386,7,13.386
        s-0.333,0.089-0.424,0.235l-1.652,2.643c-0.142,0.226-0.462,0.3-0.689,0.16C4.001,16.278,3.93,15.968,4.076,15.735z M14,12.5h3v2h-3
        V12.5z M18,12.5h5v2h-5V12.5z M18,11.5v-2h5v2H18z M18,8.5v-2h5v2H18z M17,8.5h-3v-2h3V8.5z M14,15.5h3v2h-3V15.5z M18,15.5h5v2h-5
        V15.5z M23,5.5h-5v-2h5V5.5z M17,3.5v2h-3v-2H17z M14,18.5h3v2h-3V18.5z M18,20.5v-2h5v2H18z"/>
      );
    case (iconTypes.EURO):
      return (
        <g>
          <path d="M12,23.5C5.659,23.5,0.5,18.341,0.5,12S5.659,0.5,12,0.5S23.5,5.659,23.5,12S18.341,23.5,12,23.5z M12,1.5
            C6.21,1.5,1.5,6.21,1.5,12S6.21,22.5,12,22.5S22.5,17.79,22.5,12S17.79,1.5,12,1.5z"/>
          <g>
            <path d="M12,17.5c-1.93,0-3.5-1.346-3.5-3v-5c0-1.654,1.57-3,3.5-3s3.5,1.346,3.5,3c0,0.276-0.224,0.5-0.5,0.5s-0.5-0.224-0.5-0.5
              c0-1.103-1.122-2-2.5-2s-2.5,0.897-2.5,2v5c0,1.103,1.122,2,2.5,2s2.5-0.897,2.5-2c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5
              C15.5,16.154,13.93,17.5,12,17.5z"/>
            <path d="M13,11.5H8c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h5c0.276,0,0.5,0.224,0.5,0.5S13.276,11.5,13,11.5z"/>
            <path d="M14,13.5H8c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h6c0.276,0,0.5,0.224,0.5,0.5S14.276,13.5,14,13.5z"/>
          </g>
        </g>
      );
    case (iconTypes.MONEY_PACK):
      return (
<g>
	<path d="M5.5,6C2.851,6,0,5.374,0,4s2.851-2,5.5-2S11,2.626,11,4S8.149,6,5.5,6z M5.5,3C2.574,3,1.075,3.73,0.999,4.007
		C1.075,4.27,2.574,5,5.5,5s4.425-0.73,4.501-1.007C9.925,3.73,8.426,3,5.5,3z"/>
	<path d="M5.5,8C2.851,8,0,7.374,0,6V4c0-0.276,0.224-0.5,0.5-0.5S1,3.724,1,4v2c0.075,0.27,1.574,1,4.5,1s4.425-0.73,4.501-1.007
		L10,4c0-0.276,0.224-0.5,0.5-0.5S11,3.724,11,4v2C11,7.374,8.149,8,5.5,8z"/>
	<path d="M5.5,10C2.851,10,0,9.374,0,8V6c0-0.276,0.224-0.5,0.5-0.5S1,5.724,1,6v2c0.075,0.27,1.574,1,4.5,1s4.425-0.73,4.501-1.007
		L10,6c0-0.276,0.224-0.5,0.5-0.5S11,5.724,11,6v2C11,9.374,8.149,10,5.5,10z"/>
	<path d="M18.5,16c-2.649,0-5.5-0.626-5.5-2s2.851-2,5.5-2s5.5,0.626,5.5,2S21.149,16,18.5,16z M18.5,13
		c-2.926,0-4.425,0.73-4.501,1.007C14.075,14.27,15.574,15,18.5,15s4.425-0.73,4.501-1.007C22.925,13.73,21.426,13,18.5,13z"/>
	<path d="M18.5,18c-2.649,0-5.5-0.626-5.5-2v-2c0-0.276,0.224-0.5,0.5-0.5S14,13.724,14,14v2c0.075,0.27,1.574,1,4.5,1
		s4.425-0.73,4.501-1.007L23,14c0-0.276,0.224-0.5,0.5-0.5S24,13.724,24,14v2C24,17.374,21.149,18,18.5,18z"/>
	<path d="M18.5,20c-2.649,0-5.5-0.626-5.5-2v-2c0-0.276,0.224-0.5,0.5-0.5S14,15.724,14,16v2c0.075,0.27,1.574,1,4.5,1
		s4.425-0.73,4.501-1.007L23,16c0-0.276,0.224-0.5,0.5-0.5S24,15.724,24,16v2C24,19.374,21.149,20,18.5,20z"/>
	<path d="M18.5,22c-2.649,0-5.5-0.626-5.5-2v-2c0-0.276,0.224-0.5,0.5-0.5S14,17.724,14,18v2c0.075,0.27,1.574,1,4.5,1
		s4.425-0.73,4.501-1.007L23,18c0-0.276,0.224-0.5,0.5-0.5S24,17.724,24,18v2C24,21.374,21.149,22,18.5,22z"/>
	<path d="M5.5,12C2.851,12,0,11.374,0,10V8c0-0.276,0.224-0.5,0.5-0.5S1,7.724,1,8v2c0.075,0.27,1.574,1,4.5,1
		s4.425-0.73,4.501-1.007L10,8c0-0.276,0.224-0.5,0.5-0.5S11,7.724,11,8v2C11,11.374,8.149,12,5.5,12z"/>
	<path d="M5.5,14C2.851,14,0,13.374,0,12v-2c0-0.276,0.224-0.5,0.5-0.5S1,9.724,1,10v2c0.075,0.27,1.574,1,4.5,1
		s4.425-0.73,4.501-1.007L10,10c0-0.276,0.224-0.5,0.5-0.5S11,9.724,11,10v2C11,13.374,8.149,14,5.5,14z"/>
	<path d="M5.5,16C2.851,16,0,15.374,0,14v-2c0-0.276,0.224-0.5,0.5-0.5S1,11.724,1,12v2c0.075,0.27,1.574,1,4.5,1
		s4.425-0.73,4.501-1.007L10,12c0-0.276,0.224-0.5,0.5-0.5S11,11.724,11,12v2C11,15.374,8.149,16,5.5,16z"/>
	<path d="M5.5,18C2.851,18,0,17.374,0,16v-2c0-0.276,0.224-0.5,0.5-0.5S1,13.724,1,14v2c0.075,0.27,1.574,1,4.5,1
		s4.425-0.73,4.501-1.007L10,14c0-0.276,0.224-0.5,0.5-0.5S11,13.724,11,14v2C11,17.374,8.149,18,5.5,18z"/>
	<path d="M5.5,20C2.851,20,0,19.374,0,18v-2c0-0.276,0.224-0.5,0.5-0.5S1,15.724,1,16v2c0.075,0.27,1.574,1,4.5,1
		s4.425-0.73,4.501-1.007L10,16c0-0.276,0.224-0.5,0.5-0.5S11,15.724,11,16v2C11,19.374,8.149,20,5.5,20z"/>
</g>
      );
    case (iconTypes.CONTRACT):
      return (
<g>
	<g>
		<g>
			<g>
				<g>
					<path d="M20.5,24h-17C3.224,24,3,23.776,3,23.5v-17c0-0.133,0.053-0.26,0.146-0.354l6-6C9.24,0.053,9.367,0,9.5,0h11
						C20.776,0,21,0.224,21,0.5v23C21,23.776,20.776,24,20.5,24z M4,23h16V1H9.707L4,6.707V23z"/>
					<path d="M9.5,7h-6C3.224,7,3,6.776,3,6.5S3.224,6,3.5,6H9V0.5C9,0.224,9.224,0,9.5,0S10,0.224,10,0.5v6C10,6.776,9.776,7,9.5,7
						z"/>
				</g>
			</g>
		</g>
		<path d="M10.5,11h-5C5.224,11,5,10.776,5,10.5S5.224,10,5.5,10h5c0.276,0,0.5,0.224,0.5,0.5S10.776,11,10.5,11z"/>
		<path d="M18.5,15h-13C5.224,15,5,14.776,5,14.5S5.224,14,5.5,14h13c0.276,0,0.5,0.224,0.5,0.5S18.776,15,18.5,15z"/>
		<path d="M18.5,19h-13C5.224,19,5,18.776,5,18.5S5.224,18,5.5,18h13c0.276,0,0.5,0.224,0.5,0.5S18.776,19,18.5,19z"/>
	</g>
	<path d="M15.498,10c-1.329,0-2.401-0.696-2.495-1.62c-0.028-0.275,0.172-0.521,0.446-0.548c0.271-0.026,0.521,0.171,0.548,0.447
		C14.032,8.62,14.657,9,15.498,9C16.344,9,17,8.597,17,8.25c0-0.234-0.256-0.553-1.476-0.74c-0.003,0-0.104-0.017-0.107-0.017
		C14.752,7.391,13,7.122,13,5.75C13,4.768,14.099,4,15.502,4c1.329,0,2.401,0.696,2.495,1.619c0.028,0.275-0.172,0.52-0.446,0.548
		c-0.27,0.022-0.521-0.172-0.548-0.447C16.968,5.379,16.343,5,15.502,5C14.656,5,14,5.403,14,5.75c0,0.234,0.257,0.554,1.479,0.741
		c0.003,0,0.104,0.017,0.107,0.017C16.251,6.609,18,6.878,18,8.25C18,9.231,16.901,10,15.498,10z"/>
	<path d="M15.5,11c-0.276,0-0.5-0.224-0.5-0.5v-7C15,3.224,15.224,3,15.5,3S16,3.224,16,3.5v7C16,10.776,15.776,11,15.5,11z"/>
</g>
      );
    case (iconTypes.ANNOUNCEMENT):
      return (
<g id="Announcement">
	<g>
		<g>
			<path d="M19.5,23.5c-0.13,0-0.258-0.051-0.354-0.146L14.293,18.5H3.5C1.57,18.5,0,16.93,0,15V4c0-1.93,1.57-3.5,3.5-3.5h16
				C21.43,0.5,23,2.07,23,4v11c0,1.76-1.306,3.221-3,3.465V23c0,0.202-0.122,0.385-0.309,0.462C19.629,23.487,19.564,23.5,19.5,23.5
				z M3.5,1.5C2.122,1.5,1,2.621,1,4v11c0,1.379,1.122,2.5,2.5,2.5h11c0.133,0,0.26,0.053,0.354,0.146L19,21.793V18
				c0-0.276,0.224-0.5,0.5-0.5c1.378,0,2.5-1.121,2.5-2.5V4c0-1.379-1.122-2.5-2.5-2.5H3.5z"/>
		</g>
		<g>
			<path d="M11.5,15.5c-0.827,0-1.5-0.673-1.5-1.5s0.673-1.5,1.5-1.5S13,13.173,13,14S12.327,15.5,11.5,15.5z M11.5,13.5
				c-0.276,0-0.5,0.225-0.5,0.5s0.224,0.5,0.5,0.5S12,14.275,12,14S11.776,13.5,11.5,13.5z"/>
		</g>
		<g>
			<path d="M11.5,11.5c-0.827,0-1.515-0.641-1.573-1.459L9.504,5.114C9.475,4.692,9.62,4.281,9.903,3.978
				C10.186,3.674,10.585,3.5,11,3.5h1c0.415,0,0.815,0.174,1.098,0.478s0.428,0.715,0.398,1.129l-0.431,4.942
				C13.008,10.859,12.32,11.5,11.5,11.5z M11,4.5c-0.14,0-0.27,0.057-0.366,0.159s-0.143,0.236-0.133,0.377l0.422,4.927
				c0.022,0.305,0.271,0.537,0.569,0.537c0.305,0,0.555-0.232,0.576-0.529L12.5,5.028c0.009-0.133-0.038-0.267-0.134-0.369
				S12.14,4.5,12,4.5H11z"/>
		</g>
	</g>
</g>
      );
    case (iconTypes.FULL_STAR): 
      return (
<g>
	<g>
		<path d="M23.474,9.328c-0.061-0.18-0.22-0.31-0.408-0.335l-7.242-0.96l-3.376-6.768c-0.168-0.339-0.727-0.339-0.895,0L8.177,8.033
			l-7.242,0.96C0.747,9.018,0.589,9.148,0.526,9.328c-0.061,0.18-0.015,0.379,0.12,0.514l5.311,5.311L4.51,22.39
			c-0.037,0.188,0.036,0.38,0.187,0.496c0.152,0.116,0.358,0.135,0.527,0.05L12,19.548l6.776,3.388
			c0.072,0.035,0.148,0.053,0.224,0.053c0.107,0,0.215-0.035,0.302-0.103c0.152-0.117,0.224-0.308,0.187-0.496l-1.447-7.237
			l5.311-5.311C23.489,9.707,23.534,9.508,23.474,9.328z M17.148,14.635c-0.118,0.119-0.17,0.288-0.137,0.452l1.3,6.498
			l-6.086-3.043c-0.071-0.035-0.148-0.053-0.224-0.053s-0.153,0.018-0.224,0.053L5.69,21.585l1.3-6.498
			c0.033-0.164-0.019-0.334-0.137-0.452L2.07,9.851l6.502-0.862C8.737,8.967,8.88,8.865,8.953,8.717L12,2.609l3.047,6.108
			c0.074,0.148,0.217,0.25,0.382,0.272l6.502,0.862L17.148,14.635z M12.446,5.746c-0.168-0.339-0.727-0.339-0.895,0L9.49,9.876
			l-4.278,0.567c-0.188,0.025-0.346,0.155-0.408,0.335c-0.061,0.18-0.015,0.379,0.12,0.514l3.202,3.203l-0.855,4.277
			c-0.037,0.188,0.036,0.38,0.187,0.496c0.151,0.117,0.356,0.135,0.527,0.05L12,17.312l4.014,2.006
			c0.072,0.035,0.148,0.053,0.224,0.053c0.108,0,0.215-0.034,0.302-0.103c0.152-0.117,0.224-0.308,0.187-0.496l-0.855-4.277
			l3.202-3.203c0.135-0.135,0.181-0.334,0.12-0.514c-0.061-0.18-0.22-0.31-0.408-0.335l-4.278-0.567L12.446,5.746z M14.977,13.978
			c-0.118,0.119-0.17,0.288-0.137,0.452l0.708,3.537l-3.323-1.661c-0.071-0.035-0.148-0.053-0.224-0.053s-0.153,0.018-0.222,0.053
			l-3.323,1.661l0.708-3.537c0.033-0.164-0.019-0.334-0.137-0.452l-2.676-2.676l3.538-0.469c0.165-0.022,0.308-0.124,0.382-0.272
			l1.731-3.471l1.731,3.471c0.074,0.148,0.217,0.25,0.382,0.272l3.538,0.469L14.977,13.978z"/>
	</g>
</g>);
    case (iconTypes.YOU_TUBE_SOLID):
      return (
<g>
	<path d="M20.803,3.764l-5.294-0.46c-2.328-0.203-4.689-0.203-7.018,0l-5.294,0.46C1.375,3.922,0,5.421,0,7.251v9.499
	c0,1.83,1.375,3.329,3.197,3.487l5.294,0.46c1.164,0.102,2.336,0.152,3.509,0.152s2.345-0.05,3.509-0.152l5.294-0.46
	C22.625,20.078,24,18.579,24,16.75V7.251C24,5.421,22.625,3.922,20.803,3.764z M9,7.5c0-0.183,0.1-0.352,0.261-0.439
	C9.333,7.021,9.417,7,9.5,7c0.096,0,0.189,0.027,0.27,0.079l7,4.5C16.914,11.672,17,11.829,17,12s-0.086,0.328-0.229,0.42l0,0
	l-7,4.5c-0.15,0.097-0.352,0.105-0.51,0.018C9.1,16.852,9,16.683,9,16.5V7.5z"/>
</g>
      );
    case (iconTypes.CLOCK):
      return (
<g>
	<g>
		<path d="M12,23.5C5.659,23.5,0.5,18.341,0.5,12S5.659,0.5,12,0.5S23.5,5.659,23.5,12S18.341,23.5,12,23.5z M12,1.5
			C6.21,1.5,1.5,6.21,1.5,12S6.21,22.5,12,22.5S22.5,17.79,22.5,12S17.79,1.5,12,1.5z"/>
	</g>
	<g>
		<path d="M18,12.5h-6c-0.276,0-0.5-0.224-0.5-0.5V4c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v7.5H18c0.276,0,0.5,0.224,0.5,0.5
			S18.276,12.5,18,12.5z"/>
	</g>
</g>)
    case (iconTypes.ABACUS):
      return (
<g>
	<g>
		<path d="M22,0c-1.103,0-2,0.897-2,2v2.006l-1.092-0.004C18.702,3.42,18.151,3,17.5,3c-0.656,0-1.21,0.427-1.412,1.015h-4.175
			C11.71,3.427,11.156,3,10.5,3S9.29,3.427,9.088,4.015H7.912C7.71,3.427,7.156,3,6.5,3S5.29,3.427,5.088,4.015H4V2
			c0-1.103-0.897-2-2-2S0,0.897,0,2v20c0,1.103,0.897,2,2,2s2-0.897,2-2v-1.998l1.094,0.004C5.303,20.584,5.852,21,6.5,21
			c0.651,0,1.201-0.419,1.408-1h4.184c0.207,0.581,0.757,1,1.408,1s1.201-0.419,1.408-1h1.184c0.207,0.581,0.757,1,1.408,1
			s1.201-0.419,1.408-1H20v2c0,1.103,0.897,2,2,2s2-0.897,2-2V2C24,0.897,23.103,0,22,0z M3,22c0,0.551-0.449,1-1,1s-1-0.449-1-1V2
			c0-0.551,0.449-1,1-1s1,0.449,1,1V22z M17.5,4C17.776,4,18,4.224,18,4.5S17.776,5,17.5,5S17,4.776,17,4.5S17.224,4,17.5,4z
			 M10.5,4C10.776,4,11,4.224,11,4.5S10.776,5,10.5,5S10,4.776,10,4.5S10.224,4,10.5,4z M6.5,4C6.776,4,7,4.224,7,4.5S6.776,5,6.5,5
			S6,4.776,6,4.5S6.224,4,6.5,4z M6.5,20C6.224,20,6,19.776,6,19.5S6.224,19,6.5,19S7,19.224,7,19.5S6.776,20,6.5,20z M13.5,20
			c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5S13.776,20,13.5,20z M17.5,20c-0.276,0-0.5-0.224-0.5-0.5
			s0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5S17.776,20,17.5,20z M20,19h-1.092c-0.207-0.581-0.757-1-1.408-1s-1.201,0.419-1.408,1
			h-1.184c-0.207-0.581-0.757-1-1.408-1s-1.201,0.419-1.408,1H7.908c-0.207-0.581-0.757-1-1.408-1c-0.653,0-1.204,0.422-1.41,1.006
			L4,19.002v-3.987h1.097C5.308,15.588,5.855,16,6.5,16s1.192-0.412,1.403-0.985h1.194C9.308,15.588,9.855,16,10.5,16
			s1.192-0.412,1.403-0.985H20V19z M6,14.5C6,14.224,6.224,14,6.5,14S7,14.224,7,14.5S6.776,15,6.5,15S6,14.776,6,14.5z M10,14.5
			c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5S10.776,15,10.5,15S10,14.776,10,14.5z M20,14.015h-8.088
			C11.71,13.427,11.156,13,10.5,13s-1.21,0.427-1.412,1.015H7.912C7.71,13.427,7.156,13,6.5,13s-1.21,0.427-1.412,1.015H4v-4h6.097
			C10.308,10.588,10.855,11,11.5,11s1.192-0.412,1.403-0.985h3.194C16.308,10.588,16.855,11,17.5,11c0.65,0,1.2-0.419,1.407-0.998
			L20,10.006V14.015z M11,9.5C11,9.224,11.224,9,11.5,9S12,9.224,12,9.5S11.776,10,11.5,10S11,9.776,11,9.5z M17,9.5
			C17,9.224,17.224,9,17.5,9S18,9.224,18,9.5S17.776,10,17.5,10S17,9.776,17,9.5z M20,9.006l-1.092-0.004
			C18.702,8.42,18.151,8,17.5,8c-0.656,0-1.21,0.427-1.412,1.015h-3.175C12.71,8.427,12.156,8,11.5,8s-1.21,0.427-1.412,1.015H4v-4
			h1.097C5.308,5.588,5.855,6,6.5,6s1.192-0.412,1.403-0.985h1.194C9.308,5.588,9.855,6,10.5,6s1.192-0.412,1.403-0.985h4.194
			C16.308,5.588,16.855,6,17.5,6c0.65,0,1.2-0.419,1.407-0.998L20,5.006V9.006z M23,22c0,0.551-0.449,1-1,1s-1-0.449-1-1V2
			c0-0.551,0.449-1,1-1s1,0.449,1,1V22z"/>
	</g>
</g>
      );    case (iconTypes.IMAGE_COLLECTION):
      return (
<g>
	<g>
		<path d="M17.5,17h-17C0.224,17,0,16.776,0,16.5v-15C0,1.224,0.224,1,0.5,1h17C17.776,1,18,1.224,18,1.5v15
			C18,16.776,17.776,17,17.5,17z M1,16h16V2H1V16z"/>
	</g>
	<g>
		<path d="M17.5,17c-0.184,0-0.36-0.101-0.448-0.276L11.971,6.559l-3.537,6.189c-0.077,0.135-0.213,0.227-0.368,0.248
			c-0.155,0.021-0.31-0.032-0.42-0.142l-2.562-2.562L0.92,16.771c-0.148,0.232-0.458,0.3-0.691,0.15
			c-0.232-0.149-0.299-0.458-0.15-0.691l4.5-7c0.082-0.127,0.217-0.21,0.367-0.227c0.149-0.017,0.3,0.037,0.407,0.144l2.537,2.537
			l3.675-6.432c0.092-0.16,0.259-0.26,0.448-0.252c0.185,0.005,0.351,0.111,0.434,0.276l5.5,11c0.124,0.247,0.023,0.547-0.224,0.671
			C17.652,16.983,17.576,17,17.5,17z"/>
	</g>
	<g>
		<path d="M5.5,8C4.122,8,3,6.878,3,5.5S4.122,3,5.5,3S8,4.122,8,5.5S6.878,8,5.5,8z M5.5,4C4.673,4,4,4.673,4,5.5S4.673,7,5.5,7
			S7,6.327,7,5.5S6.327,4,5.5,4z"/>
	</g>
	<g>
		<path d="M19.5,19h-17C2.224,19,2,18.776,2,18.5v-2C2,16.224,2.224,16,2.5,16S3,16.224,3,16.5V18h16V4h-1
			c-0.276,0-0.5-0.224-0.5-0.5S17.724,3,18,3h1.5C19.776,3,20,3.224,20,3.5v15C20,18.776,19.776,19,19.5,19z"/>
	</g>
	<g>
		<path d="M21.5,21h-17C4.224,21,4,20.776,4,20.5v-2C4,18.224,4.224,18,4.5,18S5,18.224,5,18.5V20h16V6h-1.5
			C19.224,6,19,5.776,19,5.5S19.224,5,19.5,5h2C21.776,5,22,5.224,22,5.5v15C22,20.776,21.776,21,21.5,21z"/>
	</g>
	<g>
		<path d="M23.5,23h-17C6.224,23,6,22.776,6,22.5v-2C6,20.224,6.224,20,6.5,20S7,20.224,7,20.5V22h16V9h-1.5
			C21.224,9,21,8.776,21,8.5S21.224,8,21.5,8h2C23.776,8,24,8.224,24,8.5v14C24,22.776,23.776,23,23.5,23z"/>
	</g>
</g>
      );
    default:
      return null;
  }
};
