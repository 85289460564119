import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Col, Input, Modal, notification, Row, Typography } from 'antd';
import { signInAnonymously } from 'firebase/auth';
import React, { useState } from 'react';
import { Img } from 'react-image';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import logo from '../assets/logo.svg';
import LsCodeInput from '../components/LsCode/LsCodeInput';
import AnimOpacity from '../components/UI/Anim/AnimOpacity';
import CheckVideoButton from '../components/UI/Button/CheckVideoButton';
import { auth } from '../services/firebase/auth';
import { LINK_FOLDER_CODES, YT_CODES, YT_CODES_EXPLAIN } from '../utils/helpers/links';
import { useTypedSelector } from '../store';
import { BLACK, ButtonFullWidth, EmptyButton, GRID_MD_PX, LinkFullWidth, RowExt, RowSm } from '../styles';

const LsCode = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [code, setCode] = useState('');
  const userExists = useTypedSelector(state =>
    state.user.isNonAnonymousUserLoggedIn);
  const history = useHistory();

  const onEdit = async () => {
    try {
      if (code.length === 12){
        if (userExists){
          history.push(`/resultLsCode/${code}`);
        } else {
          await signInAnonymously(auth);
          history.push(`/resultLsCode/${code}`);
        }
      } else {
        notification.info({ message: 'Kod jest za krótki' });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  return (
    <>
      <AnimOpacity>
        <Container>
          <div style={{ width: '100%' }}>
            <Link to="/">
              <Row justify="center">
                <Logo src={logo} />
              </Row>
            </Link>
            <Row justify="center">
              <Typography.Title level={3}>
                KOD WYLICZEŃ
              </Typography.Title>
            </Row>
            <RowExt justify="center" marginTop={12}>
              <Typography.Paragraph>
                Wpisz kod z wyliczeniami aby uzyskać do nich dostęp
              </Typography.Paragraph>
            </RowExt>
            <LsCodeInput
              setCode={setCode}
            />
            <RowExt
              marginTop={24}
              align="middle"
              marginBottom={72}
              gutter={[{ sm:8 }, { xs:24 }]}
              style={{ marginLeft: 2 }}
              justify="center"
            >
              <Col
                sm={{
                  span:8, order: 1
                }}
                xs={{
                  span:24, order: 3
                }}
              >
                <LinkFullWidth
                  to={{ pathname: userExists ? '/dashboard' : '/' }}
                >
                  <ButtonFullWidth
                    icon={<ArrowLeftOutlined />}
                  >
                    Wróć
                  </ButtonFullWidth>
                </LinkFullWidth>
              </Col>
              <Col
                sm={{
                  span:8, order: 2
                }}
                xs={{
                  span:24, order: 2
                }}
              >
                <EmptyButton
                  style={{ width: '100%' }}
                  onClick={
                  () => {
                    setCode('');
                    setIsModalVisible(true);
                  }
                }
                >
                  Wklej kod
                </EmptyButton>
              </Col>
              <Col
                sm={{
                  span:8, order: 3
                }}
                xs={{
                  span:24, order: 1
                }}
              >
                <ButtonFullWidth
                  type="primary"
                  onClick={onEdit}
                >
                  Dalej
                </ButtonFullWidth>
              </Col>
            </RowExt>
            <Row justify='space-between'>
              <CheckVideoButton
                url={YT_CODES}
                emptyButtonProps={{
                  color: BLACK, hoverBg: BLACK, hoverColor: 'white', dotted: false
                }}
                text="jak to działa ?"
              />
              <CheckVideoButton
                url={YT_CODES_EXPLAIN}
                emptyButtonProps={{
                  color: BLACK, hoverBg: BLACK, hoverColor: 'white', dotted: false
                }}
                text="teoria kodów wyliczeń"
              />
            </Row>
            <Row justify='center'>
              <Button type='link' href={LINK_FOLDER_CODES}>
                pobierz folder
              </Button>
            </Row>
          </div>
        </Container>
      </AnimOpacity>
      <Modal
        onCancel={() =>
          setIsModalVisible(false)}
        visible={isModalVisible}
        onOk={onEdit}
        destroyOnClose
        style={{ background: 'none !important' }}
      >
        <Typography.Text>Wklej lub wpisz kod wyliczeń</Typography.Text>
        <RowSm />
        <Input
          value={code}
          onChange={(event) =>
            setCode(event.target.value.replace(/ /g, ''))}
          placeholder="Kod wyliczeń"
        />
        <RowSm />
      </Modal>
    </>
  );
};

export default LsCode;



const Container = styled.div`
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    z-index: 11;
    width: 360px;
    @media only screen and (max-width: 376px) {
    width: 338px;
	}
`;

const Logo = styled(Img)`
    width: 124px;
    border-radius: 4px;
    /* margin-top: 48px; */
    margin-bottom: 48px;
  @media only screen and (max-width: ${GRID_MD_PX}) {
    margin-bottom: 48px;
	}
`;
