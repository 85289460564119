import React from 'react';
import { TitleAndDesc, ComponentChildren } from '../../@types';
import LawContainer from '../UI/Containers/LawContainer';
import LawBorders from './LawBorders';
import PrivacyTittle from './PrivacyTittle';

type Props = TitleAndDesc & ComponentChildren;

const PrivacyPar = (
  {
    children,
    title,
    desc
  }: Props
) => {
  return (
    <>
      <LawContainer>
        <PrivacyTittle
          title={title}
          desc={desc}
        />
        {children}
      </LawContainer>
      <LawBorders />
    </>
  );
};

export default PrivacyPar;
