/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Moment } from 'moment';

export const MATURITY_LATE_DAY = 'MATURITY_LATE_DAY';

export const MATURITY_DUE_DAY = 'MATURITY_DUE_DAY';

export type MaturityDateOpts =
typeof MATURITY_LATE_DAY |
typeof MATURITY_DUE_DAY;


interface InterestFormOptions {
  isSameEndDate: boolean,
  sameEndDate: Moment | null,
  maturityDateOpts: MaturityDateOpts,
  isStartingFromDue: boolean,
  isBasisVisible: boolean,
  areDateOptionsVisible: boolean,
  info: boolean,
  infoMsg: string,
}

const initialState:InterestFormOptions = {
  isSameEndDate: false,
  sameEndDate: null,
  maturityDateOpts: MATURITY_LATE_DAY,
  isStartingFromDue: true,
  isBasisVisible: false,
  areDateOptionsVisible: false,
  info: false,
  infoMsg: '',
};

export const interestFormOptionsSlice = createSlice({
  name: 'interestFormOptions',
  initialState,
  reducers: {
    isSameDateUpdated:(state:InterestFormOptions, action:PayloadAction<boolean>) => {
      state.isSameEndDate = action.payload;
    },
    sameEndDateUpdated:(state:InterestFormOptions, action:PayloadAction<Moment | null>) => {
      state.sameEndDate = action.payload;
    },
    startingFromDueUpdated:(state:InterestFormOptions, action: PayloadAction<boolean>) => {
      state.isStartingFromDue = action.payload;
    },
    maturityDateOptsUpdated:(state:InterestFormOptions, action: PayloadAction<MaturityDateOpts>) => {
      state.maturityDateOpts = action.payload;
    },
    isBasisVisibleUpdated:(state:InterestFormOptions, action: PayloadAction<boolean>) => {
      state.isBasisVisible = action.payload;
    },
    areDateOptionsVisibleUpdated:(state:InterestFormOptions, action: PayloadAction<boolean>) => {
      state.areDateOptionsVisible = action.payload;
    },
    infoUpdated:(state:InterestFormOptions, action: PayloadAction<{
      info: boolean,
      infoMsg: string,
    }>) => {
      state.info = action.payload.info;
      state.infoMsg = action.payload.infoMsg;
    },
    interestFormOptionsReset:() =>
      initialState
  },
});

export const {
  isSameDateUpdated,
  sameEndDateUpdated,
  startingFromDueUpdated,
  maturityDateOptsUpdated,
  isBasisVisibleUpdated,
  areDateOptionsVisibleUpdated,
  infoUpdated,
  interestFormOptionsReset
} = interestFormOptionsSlice.actions;

export default interestFormOptionsSlice.reducer;