/* eslint-disable no-loop-func */
/* eslint-disable @typescript-eslint/no-loop-func */
/* eslint-disable no-await-in-loop */
// import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import * as PDFJS from 'pdfjs-dist/legacy/build/pdf.js';

PDFJS.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${PDFJS.version}/pdf.worker.min.js`;



export function readFileAsync(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;

    reader.readAsDataURL(file);
  });
}

export const getImagesForOcr = async (files) => {
  let images = [];
  for (const file of files) {
    if (file.type === 'application/pdf'){
      const imagesFromPdf = await convertPdfToImages(file, images.length);
      images = [
        ...images,
        ...imagesFromPdf
      ];
    } else if (
      file.type === 'image/png'
    || file.type === 'image/jpeg'
    || file.type === 'image/jpg'
    ){
      const image = new Image();
      const imageData = await readFileAsync(file);
      image.src = imageData;
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.height = 3508;
      canvas.width = 2480;
      ctx.drawImage(image, 0, 0);
      images = [
        ...images,
        {
          imageData:canvas.toDataURL(),
          page: images.length + 1,
          imageUrl: window.URL.createObjectURL(file),
        }
      ];
      canvas.remove();
    }
  }
  return images;
};

const readFileData = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      resolve(e.target.result);
    };
    reader.onerror = (err) => {
      reject(err);
    };
    reader.readAsDataURL(file);
  });
};



// param: file -> the input file (e.g. event.target.files[0])
// return: images -> an array of images encoded in base64
export const convertPdfToImages = async (file, index) => {
  const images = [];
  const data = await readFileData(file);
  const pdf = await PDFJS.getDocument(data).promise;
  const canvas = document.createElement('canvas');
  for (let i = 0; i < pdf.numPages; i++) {
    const page = await pdf.getPage(i + 1);
    const viewport = page.getViewport({ scale: 3 });
    const context = canvas.getContext('2d');
    canvas.height = 3508;
    canvas.width = 2480;
    await page.render({
      canvasContext: context, viewport
    }).promise;
    const blob = await new Promise(resolve =>
      canvas.toBlob(resolve));
    images.push({
      imageData:canvas.toDataURL(),
      page: index + i + 1,
      imageUrl: window.URL.createObjectURL(blob)
    });
  }
  canvas.remove();
  return images;
};