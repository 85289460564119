import { ArrowLeftOutlined, ArrowRightOutlined, ControlOutlined } from '@ant-design/icons';
import { Button, Card, Row } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';

import { ocrStepUpdated } from '../../../store/impOcr';
import { RowExt } from '../../../styles';
import ImpCard from '../ImpCard';
import ImpCommAutoAssign from '../ImpCommonData/ImpCommAutoAssign';
import ImpCommFactualBasis from '../ImpCommonData/ImpCommFactualBasis';
import ImpCommLegalBasis from '../ImpCommonData/ImpCommLegalBasis';
import ImpCommMaturityOpts from '../ImpCommonData/ImpCommMaturityOpts';
import { IMP_OCR } from '../ImpCommonData/impCommonData';

const ImpOcrCommOpts = () => {
  const dispatch = useDispatch();

  const onNext = () => {
    dispatch(ocrStepUpdated(4));
  };

  const onBack = () => {
    dispatch(ocrStepUpdated(2));
  };

  return (
    <ImpCard
      title="Opcje importu"
      icon={<ControlOutlined />}
      desc="Wybierz wspólne dane importu. Zawsze możesz później skorygować swoją decyzję co do poszczególnych kwot."
    >
      <RowExt
        justify="space-between"
        align='middle'
        marginBottom={24}
      >
        <Button
          type="dashed"
          icon={<ArrowLeftOutlined />}
          onClick={onBack}
        />

        <Button
          type="primary"
          icon={<ArrowRightOutlined />}
          onClick={onNext}
        />
      </RowExt>
      <Row>
        <Card
          style={{ width: '100%' }}
        >
          <ImpCommMaturityOpts
            impType={IMP_OCR}
          />
          <ImpCommLegalBasis
            impType={IMP_OCR}
          />
          <ImpCommFactualBasis
            impType={IMP_OCR}
          />
          <ImpCommAutoAssign
            impType={IMP_OCR}
          />
        </Card>
      </Row>
    </ImpCard>

  );
};

export default ImpOcrCommOpts;