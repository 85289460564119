import { ArrowLeftOutlined, ArrowRightOutlined, CalendarOutlined } from '@ant-design/icons';
import { Button, Calendar } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';

import { useTypedSelector } from '../../../store';
import { ocrEndDateUpdated, ocrStepUpdated } from '../../../store/impOcr';
import { RowExt } from '../../../styles';
import { disabledDateImport } from '../../../utils/helpers/date';
import ImpCard from '../ImpCard';

export const ImpOcrEndDate = () => {

  const endDate = useTypedSelector(state =>
    state.impOcr.endDate);

  const dispatch = useDispatch();

  const onNext = () => {
    dispatch(ocrStepUpdated(3));
  };

  const onBack = () => {
    dispatch(ocrStepUpdated(1));
  };


  return (
    <ImpCard
      title="Wspólna data końcowa (opcjonalnie)"
      icon={<CalendarOutlined />}
      desc="Data, do której będą naliczane odsetki od wszyskich kwot. Domyślnie jest to dzień dzisiejszy. Możesz dla każdej z kwot modyfikować datę końcową już w czasie ustawiania parametrów wyliczeń."
    >
      <RowExt
        justify="space-between"
        align='middle'
        marginBottom={24}
      >
        <Button
          type="dashed"
          icon={<ArrowLeftOutlined />}
          onClick={onBack}
        />

        <Button
          type="primary"
          icon={<ArrowRightOutlined />}
          onClick={onNext}
        />
      </RowExt>
      <Calendar
        value={endDate ?? undefined}
        disabledDate={disabledDateImport}
        onChange={value =>
          dispatch(ocrEndDateUpdated(value))}
      />
    </ImpCard>
  );
};