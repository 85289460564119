import { InterestData, PaymentData, PaymentItem } from '../../../store/interestStorage';
import { AllocatedPayment, AUTO_ASSIGN_DEFAULT } from '../autoAssignCommon';
import { allocateFromDate } from './allocateFromDate';
import { allocateFromExact } from './allocateFromExact';
import { allocateFromSimilar } from './allocateFromSimilar';
import { allocateFromSum } from './allocateFromSum';


export const getAllocatedPayments = (interestData: InterestData, paymentData: PaymentData): AllocatedPayment[] =>
  paymentData.map((paymentItem) => {
    const results = chooseAllocationMechanism([paymentItem, interestData]);
    if (results){
      return {
        paymentItem,
        interestItemKeys: results.interestItemKeys as string[],
        allocationMechanism: results.allocationMechanism
      };
    }
    return {
      paymentItem,
      interestItemKeys: null,
      allocationMechanism: AUTO_ASSIGN_DEFAULT
    };
  });


export const chooseAllocationMechanism = (args: [paymentItem: PaymentItem, interestData: InterestData]) => {
  const exact = allocateFromExact(...args);
  if (exact.interestItemKeys) return exact;
  const fromDigits = allocateFromSimilar(...args);
  if (fromDigits.interestItemKeys) return fromDigits;
  const fromDate = allocateFromDate(...args);
  if (fromDate.interestItemKeys) return fromDate;
  const fromSum = allocateFromSum(...args);
  if (fromSum.interestItemKeys) return fromSum;
  return null;
};



