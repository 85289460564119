/* eslint-disable react/button-has-type */
import { FilePdfOutlined, PlusOutlined } from '@ant-design/icons';
import { Card, Space, Typography } from 'antd';
import Meta from 'antd/lib/card/Meta';
import _ from 'lodash';
import React, { useState } from 'react';
import nextId from 'react-id-generator';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { importPdf } from '../../../../services/ML/OCR/importPdf';
import { impOcrRested } from '../../../../store/impOcr';
import { BetaTag, RowSm } from '../../../../styles';
import AnimOpacity from '../../../UI/Anim/AnimOpacity';
import ImpCard from '../../ImpCard';
import FilePreview, { PlusButton } from './FilePreview';
import ImpOrcInboxActions from './ImpOrcInboxActions';

const { Paragraph } = Typography ;

const Desc = () =>
  (
    <Paragraph>
      Importuj dane z faktur i potwierdzeń wpłat bezpośrednio do formularza. Oszczędź jeszcze więcej czasu, dodatkowo
      {' '}
      <strong>zmniejsz ryzyko pomyłki</strong>
      . Stwórz
      {' '}
      <strong>listę dowodów</strong>
      {' '}
      na bazie danych.
    </Paragraph>
  );


const ImpOcrFiles = () => {
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const [files, setFiles] = useState<File[]>([]);

  const history = useHistory();

  const dispatch = useDispatch();

  const handleClick = () => {
    if (hiddenFileInput.current && hiddenFileInput) {
      hiddenFileInput.current.click();
    }
  };

  const onFileAdd = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files){
      setFiles([...files, event.target.files[0]]);
    }
  };

  const onAccept = () => {
    importPdf({
      files,
      dispatch
    });
    setFiles([]);
  };

  const onBack = () => {
    history.goBack();
    dispatch(impOcrRested());
  };

  return (
    <ImpCard
      title={(
        <Space>
          Zmień skany faktur/wpłat w tekst pozwu
          <BetaTag>BETA</BetaTag>
        </Space>
)}
      desc={<Desc />}
      icon={<FilePdfOutlined />}
    >
      <AnimOpacity>
        <ImpOrcInboxActions
          {...{
            onBack,
            onAccept,
            numberOfFiles: files.length
          }}
        />
        <RowSm>
          {_.isEmpty(files) ? null : files.map((file, index) =>
            (
              <FilePreview
                key={nextId()}
                file={file}
                removeFile={() =>
                  setFiles(files.filter((v, idx) =>
                    index !== idx))}
              />
            ))}
          <input
            type="file"
            ref={hiddenFileInput}
            onChange={(event) =>
              onFileAdd(event)}
            style={{ display: 'none' }}
            accept="image/png, image/jpeg, image/jpg, .pdf"
          />
          <PlusButton onClick={handleClick}>
            <div>
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Dodaj plik</div>
            </div>
          </PlusButton>
        </RowSm>
      </AnimOpacity>
      <Card
        title="Jak to działa?"
        style={{ marginTop: 24 }}
      >

        <Meta
          description={(
            <>
              Aplikacja rozpozna tekst w dokumentach
              {' '}
              i znajdzie istotne dane. Dane zostaną użyte do wyliczeń,
              a następnie zostanie stworzony na ich bazie teskt uzasadnianie pozwu/orzeczenia.
              Plik lub pliki powinnny zawierać skany
              {' '}
              <strong>faktur VAT</strong>
              . Można dołączyć też
              {' '}
              <strong>potwierdzenia zapłaty</strong>
              {' '}
              .
              {' '}
              <br />
            </>
        )}
        />
        <Meta
          style={{ marginTop: 12 }}
          title={(
            <>
              Uwaga! Ten moduł jest ciągle rozwijany
              {' '}
              <BetaTag>BETA</BetaTag>
            </>
)}
          description={(
            <>
              Aplikacja może czasami niewłaściwie rozpoznawać dokumenty
              lub przyporządkowywać dane. Cały czas
              {' '}
              <strong>
                {' '}
                trenujemy sztuczną inteligencję/ulepszamy algorytmy.
              </strong>
              <br />
              <br />
              <strong> Prosimy o wyrozumiałość</strong>
              ,
              {' '}
              z czasem wyniki będą coraz lepsze!
              <br />
            </>
        )}
        />
      </Card>

    </ImpCard>
  );
};

export default ImpOcrFiles;
