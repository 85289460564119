import { DownloadOutlined, HomeOutlined } from '@ant-design/icons';
import { Button, Row } from 'antd';
import React from 'react';
import { Img } from 'react-image';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import cover from '../assets/cover-codes-guide.png';
import AnimOpacity from '../components/UI/Anim/AnimOpacity';
import { LINK_FOLDER_CODES } from '../utils/helpers/links';
import { GREY_LIGHT, RowMd } from '../styles';

const CodesFolder = () => {
  return (
    <AnimOpacity>
      <Container>
        <div style={{ width:'100%' }}>
          <Row justify="center">
            <a
              href='https://firebasestorage.googleapis.com/v0/b/pozewapp.appspot.com/o/pozew-kody-wyliczen%CC%81.pdf?alt=media&token=9e30f03f-a090-41c8-82d2-29d75d86ae66'
              target="_blank"
              rel="noreferrer"
            >
              <Cover src={cover} />
            </a>
          </Row>
          <Row justify="center">
            <a
              href='https://firebasestorage.googleapis.com/v0/b/pozewapp.appspot.com/o/pozew-kody-wyliczen%CC%81.pdf?alt=media&token=9e30f03f-a090-41c8-82d2-29d75d86ae66'
              target="_blank"
              rel="noreferrer"
            >
              <Button
                type='primary'
                icon={<DownloadOutlined />}
                href={LINK_FOLDER_CODES}
              >
                Pobierz folder
              </Button>
            </a>
          </Row>
          <Link to="/">
            <RowMd justify="center">
              <Button
                type='dashed'
                icon={<HomeOutlined />}
                href='/'
                target="_blank"
              >
                Strona główna
              </Button>
            </RowMd>
          </Link>
        </div>
      </Container>
    </AnimOpacity>
  );
};

export default CodesFolder;

const Container = styled.div`
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    z-index: 11;
    width: 360px;
    @media only screen and (max-width: 376px) {
    width: 338px;
	}
`;


const Cover = styled(Img)`
    width: 400px;
    border-radius: 4px;
    margin-bottom: 24px;
    border: 1px ${GREY_LIGHT} dashed;
    @media only screen and (max-width: 500px) {
      width: 300px;
	  }
`;





