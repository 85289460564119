import React from 'react';
import { BLUE } from '../../../styles/colors';
import SubOptsHeader from './SubOptsHeader';

const PremiumHeadText = () =>
  (
    <>
      Jeśli potrzebujesz
      {' '}
      <strong>więcej niż 5 stanowisk</strong>
      {' '}
      i częstego szkolenia nowych
      pracowników
      <strong>*</strong>
    </>
  );

const SubPremiumHead = ({ inCard }: { inCard?: boolean }) => {
  return (
    <SubOptsHeader color={BLUE} inCard={inCard}>
      <PremiumHeadText />
    </SubOptsHeader>
  );
};

export default SubPremiumHead;
