import { Row, Space } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { GREY_LIGHT } from '../../../styles';
import { BorderColumn, ButtonFullWidth } from '../../../styles/ui';
import MenuItemWrapper from './MenuItemWrapper';

const MenuFooter = () => {
  return (
    <MenuItemWrapper>
      <Row justify="center">
        <SpaceWithPadding>
          <Link
            to="/privacy"
          >
            <ButtonFullWidth
              type="link"
            >
              {texts.button1}
            </ButtonFullWidth>
          </Link>

          <BorderColumn
            color={GREY_LIGHT}
          />
          <Link
            to="/terms"
          >
            <ButtonFullWidth
              type="link"
            >
              {texts.button2}
            </ButtonFullWidth>
          </Link>
        </SpaceWithPadding>
      </Row>
    </MenuItemWrapper>
  );
};

export default MenuFooter;

export const SpaceWithPadding = styled(Space)`
   padding: 24px 0;
`;

const texts = {
  button1: 'Polityka prywatności',
  button2: 'Regulamin'
};