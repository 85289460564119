import { Col, Divider } from 'antd';
import _ from 'lodash';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { RowExt } from '../../../styles';
import { GREY } from '../../../styles/colors';


export const AssignmentText = ({
  title,
  title2,
  marginTop,
  icon,
  icon2
}:{
  title: string | ReactNode,
  title2?: string | React.ReactNode,
  marginTop: number
  icon?: React.ReactNode,
  icon2?: React.ReactNode,
}) => {
  return (
    <RowStyled marginTop={marginTop} align="middle">
      <Col span={11}>
        <RowExt>
          {icon}
          {title}
        </RowExt>
      </Col>
      <Col span={2}>
        <Divider type="vertical" />
      </Col>
      <Col
        span={11}
        style={{ paddingLeft: 6 }}
      >
        <RowExt>
          {icon2}
          {title2}
        </RowExt>
      </Col>
    </RowStyled>
  );
};

export const AssignmentDesc = ({ text }:{
  text?: string | null
}) => {
  if (text){
    return (
      <>
        <RowDesc align="middle">
          {_.truncate(text, { 'length': 34 })}
        </RowDesc>
      </>
    );
  }
  return null;
};

const RowStyled = styled(RowExt)`
   font-weight: 700;
`;

const RowDesc = styled(RowExt)`
  margin-bottom: 12px;
  font-weight: 700;
  font-style: italic;
  color: ${GREY} !important;
`;
