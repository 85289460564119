/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ClaimItem } from '../services/calc/claim';
import { CompensationResult } from '../services/calc/compensationCalc';
import { PaymentData } from './interestStorage';
import { LawsuitOpts, lawSuitOptsInitialState } from './lawsuitOpts';


export type PartialResult = ClaimItem & {
  compensationResult?: CompensationResult
};

export type PartialResults = PartialResult[];
interface SumUp {
  partialResults: PartialResults;
  overallInterestsSum: number;
  compensationSum: number;
  finalSum: number;
  lawsuitOpts: LawsuitOpts,
  lsCode:  string | null,
  paymentData: PaymentData,
}

const initialState:SumUp = {
  partialResults: [],
  overallInterestsSum:0,
  compensationSum:0,
  finalSum: 0,
  lawsuitOpts: { ...lawSuitOptsInitialState },
  lsCode: null,
  paymentData: [],
};

export const sumUpSlice = createSlice({
  name: 'sumUp',
  initialState,
  reducers: {
    sumUpCreated: (state:SumUp, action: PayloadAction<{
      partialResults: PartialResults,
      overallInterestsSum: number;
      compensationSum: number;
      finalSum: number;
      lawsuitOpts: LawsuitOpts,
      paymentData: PaymentData,
    }>) => {
      const {
        partialResults,
        overallInterestsSum,
        compensationSum,
        finalSum,
        lawsuitOpts,
        paymentData,
      } = action.payload;

      return {
        ...state,
        partialResults,
        overallInterestsSum,
        compensationSum,
        finalSum,
        lawsuitOpts,
        paymentData,
      };
    },
    sumUpLsCodeChanged: (state:SumUp, action: PayloadAction<string | null>) => {
      state.lsCode = action.payload;
    },
    sumUpReset: () =>
      initialState
  },
});

export const {
  sumUpCreated,
  sumUpLsCodeChanged,
  sumUpReset,
} = sumUpSlice.actions;

export default sumUpSlice.reducer;
