/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const PRINCIPAL_USER  = 'PRINCIPAL_USER_OPTION';
export const PRINCIPAL_APP  = 'PRINCIPAL_APP';
export const PRINCIPAL_INTEREST  = 'PRINCIPAL_INTEREST';

export type PrincipalAmountOpt =
typeof PRINCIPAL_USER |
typeof PRINCIPAL_APP |
typeof PRINCIPAL_INTEREST;

export const INTEREST_LATE = 'INTEREST_LATE';
export const INTEREST_MED_PUBLIC = 'INTEREST_MED_PUBLIC';
export const INTEREST_TRANSACTION = 'INTEREST_TRANSACTION';
export const INTEREST_CAPITAL = 'INTEREST_CAPITAL';
export const INTEREST_AGREE = 'INTEREST_AGREE';

export type InterestTypes = typeof INTEREST_LATE
| typeof INTEREST_TRANSACTION
| typeof INTEREST_MED_PUBLIC
| typeof INTEREST_CAPITAL
| typeof INTEREST_AGREE;

export interface LawsuitOpts {
  typeOfInterest: InterestTypes,
  agreementInterestRate: number,
  principalAmountOpt: PrincipalAmountOpt,
  principalAmount: number,
  compensationOn: boolean,
}


export const lawSuitOptsInitialState:LawsuitOpts = {
  typeOfInterest: INTEREST_TRANSACTION,
  agreementInterestRate: 0,
  principalAmountOpt: PRINCIPAL_APP,
  principalAmount: 0,
  compensationOn: false,
};

const initialState = lawSuitOptsInitialState;


export const lawsuitOptsSlice = createSlice({
  name: 'lawsuitOpts',
  initialState,
  reducers: {
    interestTypeUpdated: (state:LawsuitOpts, action:PayloadAction<InterestTypes>) => {
      state.typeOfInterest = action.payload;
      state.compensationOn = false;
    },
    agreementInterestRateUpdated: (state:LawsuitOpts, action:PayloadAction<number>) => {
      state.agreementInterestRate = action.payload;
    },
    principalAmountOptUpdated: (state:LawsuitOpts, action:PayloadAction<PrincipalAmountOpt>) => {
      state.principalAmountOpt = action.payload;
    },
    principalAmountUpdated: (state:LawsuitOpts, action:PayloadAction<number>) => {
      state.principalAmount = action.payload;
    },
    compensationOnUpdated: (state:LawsuitOpts, action:PayloadAction<boolean>) => {
      state.compensationOn = action.payload;
    },
    lawsuitOptsAllUpdated: (state:LawsuitOpts, action:PayloadAction<LawsuitOpts>) => {
      return action.payload;
    },
    lawsuitOptsReset:() =>
      initialState
  },
});

export const {
  interestTypeUpdated,
  agreementInterestRateUpdated,
  principalAmountOptUpdated,
  principalAmountUpdated,
  compensationOnUpdated,
  lawsuitOptsAllUpdated,
  lawsuitOptsReset
} = lawsuitOptsSlice.actions;

export default lawsuitOptsSlice.reducer;

