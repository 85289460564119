import React from 'react';
import LawUst from './LawUst';

const LawUsts = (
  { texts }: {
    texts: { text:string }[]
  }
) => {
  return (
    <>
      {texts.map(value =>
        (
          <LawUst
            key={value.text}
            text={value.text}
          />
        ))}
    </>
  );
};

export default LawUsts;

