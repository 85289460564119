import { Divider, Typography } from 'antd';
import React from 'react';
import useWindowSize from '../../../utils/hooks/useWindowSize';
import { GRID_MD, RowExt } from '../../../styles';

export const WpsTitle = ({ title }: { title: string }) => {
  const { width } = useWindowSize();

  if (width >= GRID_MD) {
    return <Divider>{title}</Divider>;
  }

  return (
    <RowExt marginTop="md" marginBottom="md">
      <Typography.Title level={5}>{title}</Typography.Title>
    </RowExt>
  );
};
