import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Row, Col, DatePicker, Card } from 'antd';
import Meta from 'antd/lib/card/Meta';
import { Moment } from 'moment';
import React from 'react';
import { RowExt } from '../../../styles';
import { DateOptionsPickerActions } from './DateOptionsActions';
import DateOptionsCard from './DateOptionsCard';

export const MaturityDatePicker = ({
  setMaturityDateLocal,
  maturityDateLocal,
  setIsPickerVisible,
}:{
  setMaturityDateLocal: (maturityDateLocal: Moment | null)=> void,
  maturityDateLocal: Moment | null,
  setIsPickerVisible: (isPickerVisible: boolean) => void,
}) => {
  return (
    <DateOptionsCard>
      <Row>
        <Col span={16}>
          <DatePicker
            allowClear
            placeholder='Wybierz datę wymagalności'
            format="DD-MM-YYYY"
            style={{ width:'100%' }}
            onChange={(value) =>
              setMaturityDateLocal(value)}
            value={maturityDateLocal}
          />
        </Col>
      </Row>
      <RowExt marginTop="bg">
        <Col span={16}>
          <Card
            title={(
              <>
                <ExclamationCircleOutlined />
                {' '}
                Uwaga
              </>
)}
          >
            <Meta
              description={(
                <>
                  Jeśli po samodzielnym wyborze daty wymagalności
                  {' '}
                  <strong>
                    zmienisz termin spełnienia świadczenia
                    albo datę początkową
                  </strong>
                  {' '}
                  {' '}
                  dla tego roszczenia
                  data wymagalności zostanie zmieniona według automatycznych reguł.
                </>
        )}
            />
          </Card>
        </Col>
      </RowExt>
      <DateOptionsPickerActions
        {...{
          maturityDateLocal,
          onClose: () =>
            setIsPickerVisible(false)
        }}
      />
    </DateOptionsCard>
  );
};
