/* eslint-disable @typescript-eslint/no-explicit-any */
import { notification } from 'antd';
import ExcelJS from 'exceljs';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { AppDispatch } from '../store';
import { ImportedFormExcelRow, importExcelDataUpdated, importExcelReset } from '../store/importExcel';
import { interestAllDataChanged } from '../store/interestStorage';
import { checkDueDate } from '../utils/helpers/date';
import { errDescGeneral, errMsgGeneral } from '../utils/helpers/errorTexts';
import { isNumeric } from '../utils/helpers/isNumeric';
import { toAmount } from '../utils/helpers/money';
import { resetLawsuit } from '../utils/helpers/resetLawsuit';


const checkIfExcelFile = (
  event:React.ChangeEvent<HTMLInputElement>
) => {
  if (event.target &&
    event.target.files &&
    (event.target.files[0].type === 'application/vnd.ms-excel' ||
    event.target.files[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
  ){
    return event.target.files[0];
  }
  throw new Error('Nie mogę znaleźć pliku lub nieprawidłowy plik');
};

export const readExcel = async (
  event:React.ChangeEvent<HTMLInputElement>,
  dispatch: AppDispatch) => {
  const workbook = new ExcelJS.Workbook();
  try {
    const file = checkIfExcelFile(event);
    // @ts-ignore
    await workbook.xlsx.load(file);
    const arr:any = [];
    workbook.worksheets[0].eachRow(row => {
      const { values:rowValues }:any = row;
      const formattedRowValues = rowValues.splice(1);
      const firstCelIsNumber = _.isNumber(formattedRowValues[0]);
      if (firstCelIsNumber){
        const reversedRowValues = _.reverse(formattedRowValues);
        arr.push(reversedRowValues);
      } else {
        arr.push(formattedRowValues);
      }
    });
    dispatch(importExcelDataUpdated(arr));
    notification.info({
      message: 'Plik został odczytany',
      description: 'Wybierz pozostałe funkcje importu'
    });
  } catch (e) {
    if (e instanceof Error){
      notification.error({
        message:errMsgGeneral, description:  e.message
      });
    } else {
      notification.error({
        message:errMsgGeneral, description:  errDescGeneral
      });
    }
  }
};

export const convertNumeric = (importedSum: string) => {
  if (_.isString(importedSum) && isNumeric(importedSum)){
    const formattedStr = importedSum
      .replace(/,/g, '.')
      .replace(/\s/g, '')
      .replace('zł', '')
      .replace('PLN', '')
      .replace('ZŁ', '');
    return toAmount(_.toNumber(formattedStr));
  }
  return toAmount(0);
};

export const getPartialSum = (importedSum: string | number) =>
  _.isNumber(importedSum) ? toAmount(importedSum) :
    convertNumeric(importedSum);


export const importExToForm = ({
  data,
  dispatch,
  factualBasis,
  legalBasis,
  endDate,
  goToLawsuit,
}:{
  data: ImportedFormExcelRow[] | null,
  dispatch: AppDispatch,
  factualBasis: string | null,
  legalBasis: string | null,
  endDate: Moment,
  goToLawsuit?: ()=> any,
}) => {
  if (data !== null ){
    const importArray = data.filter(row =>
      moment(row[0]).isValid() && (_.isNumber(row[1]) || isNumeric(row[1])) && moment(row[0]).isSameOrAfter('2016-01-01')
    ).map(row => {
      const { date } = checkDueDate(moment(row[0]));
      const partialSum = getPartialSum(row[1]);
      return ({
        key:uuidv4(),
        startingDate: moment(date).add(1, 'day').startOf('day'),
        // only for tests  as for 2022-02-01
        endDate:moment(row[3]).endOf('day').isSameOrAfter(moment(date).add(1, 'day').startOf('day')) ? moment(row[3]).endOf('day') : endDate,
        partialSum,
        factualBasis,
        legalBasis,
        accountingDoc: (_.isString(row[2]) || _.isNumber(row[2])) ? _.toString(row[2]) : null,
        dueDate: moment(date),
        isClaimFromFulfilled: false,
        maturityDate: moment(date).add(1, 'day').startOf('day'),
      });
    });
    if (goToLawsuit){
      notification.info({
        duration: 6,
        message: 'Dane zaimportowane',
        description: 'Ustaw pozostałe parametry aby uzyskać tekst wezwania do zapłaty'
      });
      resetLawsuit(dispatch);
      goToLawsuit();
    }
    dispatch(interestAllDataChanged(importArray));
    dispatch(importExcelReset());
  }
};