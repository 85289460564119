import { Col, Row } from 'antd';
import React from 'react';
import { ComponentChildren } from '../../../@types';


const MenuItemWrapper = ({ children }: ComponentChildren) => {
  return (
    <Row>
      <Col span={2} />
      <Col span={20}>
        { children }
      </Col>
      <Col span={2} />
    </Row>
  );
};

export default MenuItemWrapper;
