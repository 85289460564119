import _ from 'lodash';
import React from 'react';
import { useAssignmentsDisplay } from '../../../../utils/hooks/useAssignmentsDisplay';

const ClaimGraph =  ({
  interestItemKey,
  paymentsColors,
  partialSum,
  horizontal
}:{
  interestItemKey: string,
  paymentsColors: Map<string, string>,
  partialSum: number,
  horizontal?: boolean
}) => {
  const { assignmentDisplayItems } = useAssignmentsDisplay({
    interestItemKey,
    paymentsColors,
    partialSum,
  });
  if (_.isNull(assignmentDisplayItems)){
    return null;
  }

  return (
    <>
      {assignmentDisplayItems.map(({ key, color, percentSum }) =>
        (
          <div
            key={key}
            style={{
              width: horizontal ? `${percentSum}%` : '100%',
              background:color,
              height:horizontal  ? '100%' : `${percentSum}%`
            }}
          />
        )
      )}
    </>
  );
};

export default ClaimGraph;