import { CheckOutlined, RobotFilled } from '@ant-design/icons';
import { Button } from 'antd';
import _ from 'lodash';
import React from 'react';
import nextId from 'react-id-generator';
import { useDispatch } from 'react-redux';

import { useTypedSelector } from '../../../../store';
import { autoAssignHistoryUpdated } from '../../../../store/lawsuitUI';
import { BetaTag, RowExt } from '../../../../styles';
import StepCard from '../../StepCard/StepCard';
import HistoryCard from './HistoryCard';


const AutoAssignmentHistory = () => {
  const autoAssignHistory = useTypedSelector(state =>
    state.lawsuitUI.autoAssignHistory);

  const dispatch = useDispatch();

  if (_.isNull(autoAssignHistory)) return null;

  return (
    <StepCard
      title={(
        <>
          <RobotFilled />
          {' '}
          Wyjaśnienia automatycznego zaliczenia wpłat
          <BetaTag>BETA</BetaTag>
        </>
        )}
      desc="Zobacz w jaki sposób został zastosowany art. 451 Kodeksu cywilnego."
    >
      {autoAssignHistory ? autoAssignHistory.map( (value, index) =>
        (
          <HistoryCard
            historyItem={value}
            key={nextId()}
            index={index}
          />
        )) : null}
      <RowExt marginTop={32} justify="center" align="middle" marginBottom="bg" gutter={[{ sm:16 }, { xs:24 }]}>
        <Button
          type='primary'
          icon={<CheckOutlined />}
          onClick={() =>
            dispatch(autoAssignHistoryUpdated(null))}
        >
          OK, rozumiem
        </Button>
      </RowExt>
    </StepCard>
  );
};

export default AutoAssignmentHistory;


