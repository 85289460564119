import { useTypedSelector } from '../../../store';


const useLsCodeText = () => {
  const lsCode = useTypedSelector(state =>
    state.sumUp.lsCode);
  const code =
        'Bezpośredni dostęp do kwot i obliczeń przedstawionych powyżej znajdziesz na stronie www.pozew.app po wpisaniu KODU WYLICZEŃ:';

  return lsCode ? [
    code,
    lsCode,
  ].join('\n\n') : '';
};

export default useLsCodeText;
