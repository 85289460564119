import { Col, Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { BLUE, RowExt } from '../../styles';

const TermsTitle = ({ title }: { title: string }) => {
  return (
    <>
      <RowExt>
        <BorderLeft
          lg={{
					  offset: 0,
					  span: 1,
          }}
          xs={{
					  offset: 1,
					  span: 1,
          }}
        />
        <Col lg={{ span: 21 }} xs={{ span: 20 }}>
          <Typography.Title level={4} style={{ marginTop: 12 }}>
            {title}
          </Typography.Title>
        </Col>
      </RowExt>
    </>
  );
};

export default TermsTitle;

const BorderLeft = styled(Col)`
	border-left: 1px solid ${BLUE};
`;
