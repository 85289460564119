import React from 'react';
import styled from 'styled-components';
import { GRID_SM_PX } from '../../../styles';




export const SloganDesc = () => {
  return (
    <SecondText>
      {sloganDesctexts.desc1}
      <br />
      {sloganDesctexts.desc4}
    </SecondText>
  );
};


export const SecondText = styled.h1`
   font-size: 18px;
   font-weight: 500;
   @media only screen and (max-width: ${GRID_SM_PX}) {
      font-size: 14px;
    }
`;

export const sloganDesctexts = {
  desc1: 'Przekształć dane liczbowe w tekst pozwu lub orzeczenia.',
  desc2: 'Komplet wyliczeń do pozwu lub orzeczenia w kilka minut.',
  desc3: 'Masowa kaptalizacja odsetek, rekompensaty, opłata sądowa oraz wezwanie do zapłaty w kilka miniut.',
  desc4: 'Użyj sztucznej inteligencji aby pobrać liczby bezpośrednio ze skanów faktur.',
};


