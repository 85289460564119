/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AssignHistory } from '../services/autoAssign/autoAssignCommon';

export const MANUAL_STANDARD = 'MANUAL_STANDARD';
export const MANUAL_PAYMENTS_ASSIGNMENTS = 'MANUAL_PAYMENTS_ASSIGNMENTS';
export const EXCEL_STANDARD = 'EXCEL STANDARD';
export const EXCEL_PAYMENTS_ASSIGNMENTS = 'EXCEL_PAYMENTS_ASSIGNMENTS';


export type InterestDataProvider =
  typeof MANUAL_STANDARD
  | typeof MANUAL_PAYMENTS_ASSIGNMENTS
  | typeof EXCEL_STANDARD;
interface LawsuitUI {
  step: number,
  isStepIncreaseDisabled: boolean;
  saveId: null | string;
  recordName: null | string;
  shouldCreateLsCode: boolean;
  lsCodeToBeUpdated: string | null,
  interestDataProvider: InterestDataProvider;
  isAddPaymentsVisible: boolean,
  claimDetailsKey: string | null,
  paymentDetailKey: string | null,
  isImportPaymentVisible: boolean,
  autoAssignHistory: AssignHistory | null,
  shouldAutoAssign: boolean,
}

const initialState:LawsuitUI = {
  step: 0,
  isStepIncreaseDisabled: false,
  saveId: null,
  recordName: null,
  shouldCreateLsCode: true,
  lsCodeToBeUpdated: null,
  interestDataProvider: MANUAL_STANDARD,
  isAddPaymentsVisible: false,
  claimDetailsKey: null,
  paymentDetailKey: null,
  isImportPaymentVisible: false,
  autoAssignHistory: null,
  shouldAutoAssign: false,
};

export const lawsuitUISlice = createSlice({
  name: 'lawsuitUI',
  initialState,
  reducers: {
    lsIncreaseStep: (state:LawsuitUI) => {state.step += 1; },
    lsDecreaseStep: (state:LawsuitUI) => {state.step > 0 ? state.step -= 1 : state.step = 0;},
    lsIncreaseStepDisabled: (state:LawsuitUI, action: PayloadAction<boolean>) => {
      state.isStepIncreaseDisabled = action.payload;
    },
    lsSetSave: (state:LawsuitUI, action: PayloadAction<{
      saveId: string | null;
      recordName: string | null;
    }>) => {
      state.saveId = action.payload.saveId;
      state.recordName = action.payload.recordName;
    },
    lsSaveReset: (state:LawsuitUI) => {
      state.saveId = null;
      state.recordName = null;
    },
    shouldCreateLsCodeUpdated: (state:LawsuitUI, action: PayloadAction<boolean>) => {
      state.shouldCreateLsCode = action.payload;
    },
    lsShouldUpdateLsCodeChanged: (state:LawsuitUI, action: PayloadAction<string | null>) => {
      state.lsCodeToBeUpdated = action.payload;
    },
    interestDataProviderUpdated: (state:LawsuitUI, action: PayloadAction<InterestDataProvider>) => {
      state.interestDataProvider = action.payload;
    },
    isAddPaymentsVisibleUpdate: (state:LawsuitUI, action: PayloadAction<boolean>) => {
      state.isAddPaymentsVisible = action.payload;
    },
    claimDetailsKeyUpdated: (state:LawsuitUI, action: PayloadAction<null | string>) => {
      state.claimDetailsKey = action.payload;
    },
    paymentDetailsKeyUpdated: (state:LawsuitUI, action: PayloadAction<null | string>) => {
      state.paymentDetailKey = action.payload;
    },
    isImportPaymentVisibleUpdated: (state:LawsuitUI, action: PayloadAction<boolean>) => {
      state.isImportPaymentVisible = action.payload;
    },
    autoAssignHistoryUpdated: (state:LawsuitUI, action: PayloadAction<AssignHistory | null>) => {
      state.autoAssignHistory = action.payload;
    },
    shouldAutoAssignUpdated: (state:LawsuitUI, action: PayloadAction<boolean>) => {
      state.shouldAutoAssign = action.payload;
    },
    lsReset: () =>
      initialState,
  }
});


export const {
  lsIncreaseStep,
  lsDecreaseStep,
  lsSetSave,
  lsSaveReset,
  lsIncreaseStepDisabled,
  shouldCreateLsCodeUpdated,
  lsShouldUpdateLsCodeChanged,
  interestDataProviderUpdated,
  isAddPaymentsVisibleUpdate,
  claimDetailsKeyUpdated,
  paymentDetailsKeyUpdated,
  isImportPaymentVisibleUpdated,
  autoAssignHistoryUpdated,
  shouldAutoAssignUpdated,
  lsReset,
} = lawsuitUISlice.actions;

export default lawsuitUISlice.reducer;