import { notification } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';

import { PaymentData } from '../store/interestStorage';
import { LawsuitOpts } from '../store/lawsuitOpts';
import { PartialResult } from '../store/sumUp';
import { convertToJSON } from '../utils/helpers/date';
import { formatPartialResultsToSave } from '../utils/helpers/formatInterestData';
import { formatPaymentDataToSave } from '../utils/helpers/formatPaymentData';
import { InterestDataSaved, PaymentDataSaved } from './lawsuitSave';

export interface LocalLawsuitSave{
  lawsuitOpts: LawsuitOpts,
  interestData: InterestDataSaved,
  paymentData: PaymentDataSaved,
  editDate: string,
  createDate: string,
  recordName: string,
  type: 'lawsuit',
  ver: string,
}

type State = {
  saveLocal: LocalLawsuitSave
};

export const loadJson = (
  event:React.ChangeEvent<HTMLInputElement>, history:{
    push: (path: string, state:State) => void,
  }) => {
  function readJson(file:File) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = ev =>
        ev.target && _.isString(ev.target.result) ?
          resolve(JSON.parse(ev.target.result)) :
          reject(new Error('Nie mogę odczytać danych'));
      fileReader.onerror = error =>
        reject(error);
      fileReader.readAsText(file);
    });
  }

  if (event.target && event.target.files && event.target.files[0].type === 'application/json') {
    readJson(event.target.files[0])
      .then(response => {
        if (response){
          const saveLocal = response as LocalLawsuitSave;
          history.push('/resultLsLocal', { saveLocal } );
        }
      }).catch(() => {
        notification.error({
          message: 'Ups! Wystąpił błąd',
          description: 'Nie mogę odczytać danych - spróbuj ponownie lub skontaktuj się z nami.'
        });
      });
  } else {
    notification.error({
      message: 'Ups! Wystąpił błąd',
      description: 'Nie mogę znaleźć pliku lub ma nieprawidłowy format'
    });
  }
};


export const saveJson = ({
  partialResults,
  lawsuitOpts,
  paymentData,
}: {
  partialResults: PartialResult[],
  lawsuitOpts: LawsuitOpts,
  paymentData: PaymentData,
}) => {
  const fileData = JSON.stringify({
    lawsuitOpts,
    interestData: formatPartialResultsToSave(partialResults),
    paymentData: formatPaymentDataToSave(paymentData),
    editDate: convertToJSON(moment.now()),
    createDate: convertToJSON(moment.now()),
    recordName: `Wyliczenia ${moment(Date.now()).format('YYYY-MM-DD hhhh:mm')}`,
    type: 'lawsuit',
    ver: process.env.REACT_APP_VERSION
  });
  const blob = new Blob([fileData], { type: 'text/plain' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.download = 'pozew-app.json';
  link.href = url;
  link.click();
};

