import { Tooltip } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { QuestionMark } from '../../styles/ui';


const InfoRadio = (
  {
    text,
    tooltip
  }: {
    text: string,
    tooltip: string
  }
) => {
  return (
    <>
      <TextContainer>{text}</TextContainer>
      <Tooltip placement="topLeft" title={tooltip} arrowPointAtCenter>
        <QuestionMark />
      </Tooltip>
    </>
  );
};



export default InfoRadio;


const TextContainer = styled.span`
  margin-right:4px
`;