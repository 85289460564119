/* eslint-disable @typescript-eslint/no-explicit-any */
import { AnalyticsCallOptions, CustomEventName, getAnalytics, logEvent } from 'firebase/analytics';
import app from '../../_firebase';


export const analytics = getAnalytics(app);


export const logToAnalytics = (eventName: CustomEventName<any>, eventParams?: {
  [key: string]: any;
}, options?: AnalyticsCallOptions) => {
  logEvent(analytics, eventName, eventParams, options);
};