/* eslint-disable max-len */
import { Button, Col, Divider, Space, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { LINK_FB, LINK_LI } from '../../../utils/helpers/links';
import { AboutContainer, BLUE_MEDIUM, DashedColLeft, GREY_WHITE, RowExt } from '../../../styles';
import { BLUE, RED } from '../../../styles/colors';
import { AT, FACEBOOK, FACEBOOK_SOLID, LINKED_IN, LINKED_IN_SOLID } from '../../../styles/iconTypes';
import IconButton from '../../UI/Button/IconButton';
import AbtTitle from '../AbtTitle';
import SponsorTexts from './SponsorTexts';
import SupportPayModal from './SupportPayModal';


const Sponsor = () => {
  return (
    <AboutContainer id="support">
      <RowExt>
        <Col
          lg={{
            span: 7,
            offset: 1
          }}
          md={{
            offset: 2,
            span: 9
          }}
          xs={{
            offset: 2,
            span: 20,
          }}
        >
          <AbtTitle
            title="Wesprzyj projekt"
            desc={(
              <>
                Podoba Ci się pomysł,
                <br />
                korzystasz z pozew.app na co dzień -
                <br />
                proszę pomyśl o jego wsparciu finansowym.
              </>
            )}
          />
        </Col>
        <TextCol
          lg={{
            offset: 0,
            span: 12,
          }}
          md={{
            offset: 0,
            span: 13,
          }}
          xs={{ span: 24 }}
        >
          <TextContainer>
            <TextMono>
              Utrzymanie tego pojektu wymaga zasobów, w tym pieniędzy. Jeśli lubisz pozew.app, uważasz, że oszczędza ona Twój czas/pomaga zarabiać pieniądze, rozważ regularne wsparcie.
              <strong> Bez tego trwanie pozew.app - w długiej perspektywie - będzie bardzo trudne.</strong>
            </TextMono>
          </TextContainer>
        </TextCol>
        <Col
          xl={{ span: 2 }}
          lg={{ span: 1 }}
          xs={0}
        />
        <DashedColLeft
          xl={{ span: 2 }}
          lg={{ span: 3 }}
          xs={0}
        />
      </RowExt>
      <SponsorTexts
        title="Wesprzyj finansowo"
        desc={(
          <>
            <Typography.Paragraph>Dziękujemy za zainteresowanie wsparciem aplikacji.</Typography.Paragraph>
            <Typography.Paragraph>
              Pozew.app ma przyspieszyć i usprawnić Twoją pracę,  to znaczy ułatwiać Ci zarabianie pieniędzy.  Aplikacja jest
              <strong> w swoim trzonie darmowa i chcemy, żeby taka pozostała</strong>
              . Nie chcemy na stronie umieszczać reklam ograniczających funkcjonalność aplikacji.
            </Typography.Paragraph>
            <Typography.Paragraph>
              Pragnelibyśmy zbudować wokół pozew.app krąg osób zaangażowanych w trwanie tego projektu.  Jeśli lubisz ten projekt i chcesz by był kontynuowany oraz rozwijany -
              <strong> zostań subskrybentem i dokonuj comiesięcznych wpłat</strong>
              .  Subskrybenci otrzymują dostęp do dodatkowych bardzo przydatnych funkcji.
            </Typography.Paragraph>
            <Typography.Paragraph>Kliknij link poniżej, dowiedz się więcej i zacznij wspierać:</Typography.Paragraph>
            <Space>
              <Link to="/subscribe">
                <Button type="link">
                  Subskrybuj
                </Button>
              </Link>
              <Divider type='vertical' />
              <SupportPayModal />
            </Space>
          </>
        )}
        color={RED}
      />
      <SponsorTexts
        title="Feedback"
        desc={(
          <>
            <Typography.Paragraph>
              Aplikacja ma jak najlepiej służyć swoim użytkownikom. Dlatego zapraszamy do przekazywania informacji zwrotnych dotyczących dowolnych aspektów, takich jak
              <strong> funkcjonalność, błędy, rozwój projektu, dokumenty prawne.</strong>
            </Typography.Paragraph>
            <Typography.Paragraph>
              Dziękujemy za każdą uwagę (w tym krytyczną), dołożymy starań by uczynić pozew.app najlepszą jak to możliwe.
              <strong> Feedback też jest wsparciem. </strong>
              Najlepiej zgłosić go wysyłając wiadomość przez Linked In albo Facebook:
            </Typography.Paragraph>
            <Space>
              <IconButton
                href={LINK_LI}
                iconType={LINKED_IN}
                iconHoover={LINKED_IN_SOLID}
                stroke
                style={{ marginTop:3 }}
              />
              <IconButton
                href={LINK_FB}
                iconType={FACEBOOK}
                iconHoover={FACEBOOK_SOLID}
                stroke
                style={{
                  marginTop:3, marginLeft:24
                }}
              />
            </Space>
          </>
        )}
        color={BLUE}
      />
      <SponsorTexts
        title="Reklamuj się"
        desc={(
          <>
            <Typography.Paragraph>
              Jeśli jesteś zainteresowany reklamowaniem swojej marki u odbiorców tej aplikacji, tzn. głównie
              <strong>  radców prawnych i adwokatów</strong>
              , skontaktuj się z nami - uzgodnijmy warunki:
            </Typography.Paragraph>
            <Space>
              <IconButton
                href="mailto:contact@pozew.app"
                iconType={AT}
                iconHoover={AT}
                stroke
                style={{ marginTop:3 }}
              />
              <IconButton
                href={LINK_LI}
                iconType={LINKED_IN}
                iconHoover={LINKED_IN_SOLID}
                stroke
                style={{ marginLeft:24 }}
              />
            </Space>
          </>
        )}
        color={BLUE_MEDIUM}
      />
    </AboutContainer>
  );
};

export default Sponsor;

const TextCol = styled(Col)`
  background-color: ${GREY_WHITE};
  @media only screen and (max-width: 767px) {
    margin-top: 48px;
	}
`;

const TextContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding:48px;
    width: 100%;
`;

const TextMono = styled.span`
   font-family: 'Fira Mono';
   font-size: 14px;
   font-weight: 500;
   letter-spacing: 2px;
`;
