import _ from 'lodash';
import React, { useState } from 'react';
import { ArcherElement } from 'react-archer';
import { shallowEqual } from 'react-redux';

import { useAppDispatch, useTypedSelector } from '../../../../store';
import { paymentDataChanged, PaymentItem } from '../../../../store/interestStorage';
import { BLACK, GRID_MD } from '../../../../styles';
import useWindowSize from '../../../../utils/hooks/useWindowSize';
import PaymentAssignmentMain from './PaymentAssignmentMain';
import RemoveAssignmentModal from './RemoveAssignmentModal';

const PaymentAssignment = ({
  paymentItem,
  isMarginTopReq,
  selectPayment,
  paymentKey,
  paymentsColors,
}:{
  paymentItem: PaymentItem
  isMarginTopReq?: boolean
  selectPayment: (paymentId: null | string) => void,
  paymentKey: string | null,
  paymentsColors: Map<string, string>
}) => {
  const { key, assignedTo } = paymentItem;
  const { width } = useWindowSize();

  const [isRemoveModalVisible, setRemoveModalVisible] = useState(false);
  const [paymentIdx, setPaymentIdx] = useState<number | null>(null);

  const dispatch = useAppDispatch();

  const { paymentData } = useTypedSelector(state =>
    state.interestStorage, shallowEqual);

  const removeAssignment = () => {
    selectPayment(null);
    const paymentIndex = paymentData.findIndex(( item ) =>
      item.key === key);
    if (paymentIndex > -1 && paymentData[paymentIndex].assignedTo.length > 0){
      const paymentDataCopy = _.cloneDeep(paymentData);
      paymentDataCopy[paymentIndex].assignedTo = [];
      if (paymentData[paymentIndex].assignedTo.length === 1){
        dispatch(paymentDataChanged(paymentDataCopy));
      } else {
        setRemoveModalVisible(true);
        setPaymentIdx(paymentIndex);
      }
    }
  };

  const removePayment = () => {
    const data = [...paymentData];
    data.splice(data.findIndex((i) => {
      return i.key === key;
    }), 1);
    dispatch(paymentDataChanged(data));
  };

  const togglePayment = () => {
    if (key === paymentKey){
      selectPayment(null);
    } else {
      selectPayment(key);
    }
  };

  return (
    <>
      <ArcherElement
        id={key}
        relations={width < GRID_MD ? undefined : assignedTo.map(({ key:targetId }) => {
          return  {
            targetId,
            targetAnchor: 'right',
            sourceAnchor: 'left',
            style: {
              strokeColor: paymentsColors.get(key),
              strokeWidth: 1,
              strokeDasharray: '4',
            }
          };
        })}
      >
        <div
          style={{ marginTop: isMarginTopReq ? 24 : 0 }}
        >
          <PaymentAssignmentMain
            {...{
              paymentItem,
              paymentKey,
              paymentsColors,
              togglePayment,
              removeAssignment,
              removePayment,
            }}
          />
        </div>
      </ArcherElement>
      <RemoveAssignmentModal
        {...{
          isRemoveModalVisible,
          setRemoveModalVisible,
          paymentIdx,
          setPaymentIdx,
          color:paymentsColors.get(key) ?? BLACK,
        }}
      />
    </>
  );
};

export default PaymentAssignment;
