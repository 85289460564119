import { CloseOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Tag } from 'antd';
import React from 'react';
import { receiverRemoveFromSubscription } from '../../../services/connectedUsers';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { GRID_MD } from '../../../styles';
import { BLUE } from '../../../styles/colors';
import useWindowSize from '../../../utils/hooks/useWindowSize';
import UserSubscriptionCard, { TagWrapper } from './UserSubscriptionCard';

const UserSubConnected = () => {
  const dispatch = useAppDispatch();
  const  { width } = useWindowSize();
  const subscriptionType = useTypedSelector(state =>
    state.user.subscriptionType);
  return (
    <UserSubscriptionCard
      subscriptionType={subscriptionType}
      actions={[
        <TagWrapper
          key="tag"
        >
          <Tag color={BLUE}>
            Użytkownik subskrypcji
          </Tag>
        </TagWrapper>,
        <Popconfirm
          title="Usunąć, jesteś pewna/pewny?"
          key="button"
          onConfirm={() =>
            receiverRemoveFromSubscription(dispatch)}
        >
          <Button
            type="link"
            key="button"
            icon={<CloseOutlined />}
          >
            {width >= GRID_MD ? 'Odłącz konto od subskrypcji' : ''}
          </Button>
        </Popconfirm>

      ]}
      metaTitle={`POZEW ${subscriptionType === 'premiumUser' ? 'PREMIUM' : 'STANDARD'} - konto połączone`}
      metaDesc="Konto połaczone w ramach subskrypcji (nie masz uprawnień administratora subskrypcji). Dziękujemy bardzo za wsparcie finansowe. Bez niego istnienie pozew.app jest niemożliwe."
    />
  );

};

export default UserSubConnected;
