import _ from 'lodash';
import moment, { Moment } from 'moment';
import { shallowEqual } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { ExternalInterestItem, ExternalPaymentItem } from '../../@types';
import { useTypedSelector } from '../../store';
import { MATURITY_LATE_DAY, MaturityDateOpts } from '../../store/interestFormOptions';
import { InterestItem, PaymentItem } from '../../store/interestStorage';
import { checkDueDate } from '../helpers/date';
import { toAmount } from '../helpers/money';


export type ImportWarning = {
  message: string,
  isFatal: boolean,
};

type Acc = {
  interestData: InterestItem[],
  warningData: {
    key: string,
    warnings: ImportWarning[],
    fatalErrorItem?: ExternalInterestItem,
  }[],
};
type AccPayments = {
  paymentData: PaymentItem[],
  warningData: {
    key: string,
    warnings: ImportWarning[]
    fatalErrorItem?: ExternalPaymentItem,
  }[],
};

const useImpExternalValidate = () => {
  const impExternalValidated = useTypedSelector(state => {
    const {
      factualBasis,
      legalBasis,
      maturityDateOpts,
      endDate,
      externalInterestData,
      externalPaymentData,
    } = state.impExternal;


    const interestDataValidated = externalInterestData.reduce((prev:Acc, curr) => {
      const key = uuidv4();
      const {
        warnings,
        dueDate
      } = checkInterestItem(curr);

      const { isFatalError, warningOnlyData } = checkFatalErrorInt(prev, {
        warnings,
        key,
      }, curr);


      if (isFatalError) return warningOnlyData;

      return {
        ...prev,
        interestData: [
          ...prev.interestData,
          convertToInterestItem({
            key,
            dueDate,
            endDate: endDate ?? moment().endOf('day'),
            factualBasis,
            externalInterestItem: curr,
            legalBasis,
            maturityDateOpts,
          }),
        ],
        warningData: _.isEmpty(warnings) ?
          prev.warningData : [
            ...prev.warningData,
            {
              key,
              warnings
            }
          ]
      };
    }, {
      interestData: [],
      warningData: [],
    });

    const paymentDataValidated = externalPaymentData.reduce((prev:AccPayments, curr) => {
      const key = uuidv4();
      const warnings = checkPaymentItem(curr);

      const { isFatalError, warningOnlyData } = checkFatalErrorPay(prev, {
        warnings,
        key,
      }, curr);


      if (isFatalError) return warningOnlyData;

      return {
        ...prev,
        paymentData: [
          ...prev.paymentData,
          convertToPaymentItem(curr, key),
        ],
        warningData: _.isEmpty(warnings) ?
          prev.warningData : [
            ...prev.warningData,
            {
              key,
              warnings,
            }
          ]
      };

    }, {
      paymentData: [],
      warningData: [],
    });

    return {
      interestData: interestDataValidated.interestData,
      interestDataWarnings: interestDataValidated.warningData,
      paymentData:  paymentDataValidated.paymentData,
      paymentDataWarnings: paymentDataValidated.warningData,
    };

  }, shallowEqual);

  const sumOfInterestData = impExternalValidated.interestData.reduce((prev, curr) =>
    prev + curr.partialSum, 0);
  const sumOfPaymentData = impExternalValidated.paymentData.reduce((prev, curr) =>
    prev + curr.paymentSum, 0);
  return {
    ...impExternalValidated,
    sumOfInterestData,
    sumOfPaymentData,
  };
};

export default useImpExternalValidate;


export const checkFatalErrorInt = (prev: Acc, check: {
  key: string,
  warnings: ImportWarning[]
}, externalInterestItem: ExternalInterestItem) => {
  const {
    key,
    warnings
  } = check;
  const isFatalError = warnings.some(value =>
    value.isFatal === true);
  const warningItem = isFatalError ? {
    key,
    warnings,
    fatalErrorItem: externalInterestItem
  } : {
    key,
    warnings,
  };

  return {
    isFatalError,
    warningOnlyData:{
      ...prev,
      warningData: [
        ...prev.warningData,
        warningItem
      ]
    }
  };
};

export const checkFatalErrorPay = (prev: AccPayments, check: {
  key: string,
  warnings: ImportWarning[]
}, externalPaymentItem: ExternalPaymentItem) => {
  const {
    key,
    warnings
  } = check;
  const isFatalError = warnings.some(value =>
    value.isFatal === true);
  const warningItem = isFatalError ? {
    key,
    warnings,
    fatalErrorItem: externalPaymentItem
  } : {
    key,
    warnings,
  };

  return {
    isFatalError,
    warningOnlyData:{
      ...prev,
      warningData: [
        ...prev.warningData,
        warningItem
      ]
    }
  };
};


const checkInterestItem = (externalItem: ExternalInterestItem) => {
  const {
    partialSum,
    dueDate,
    accountingDoc,
  } = externalItem;

  let modDueDate = dueDate;

  const warnings:ImportWarning[] = [];

  if (!_.isNumber(partialSum)) {
    warnings.push({
      message: 'Dla importu nie ma wybranej kwoty, która miałaby być dochodzona.',
      isFatal: true
    });
  } else if (_.isNumber(partialSum) && partialSum <= 0){
    warnings.push({
      message: 'Wybrana kwota jest niższa lub równa zero.',
      isFatal: true
    });
  }
  if (!moment(dueDate).isValid()){
    warnings.push({
      message: 'Termin spełnienia świadczenia nie jest datą.',
      isFatal: true
    });
  } else if (moment(dueDate).isBefore('01-01-2016')){
    warnings.push({
      message: 'Termin spełnienia jest ustawiony przed 1 stycznia 2016 roku. Aplikacja nie obsługuje roszczeń przed tą datą.',
      isFatal: true
    });
  } else if (moment(dueDate).isAfter(moment())){
    warnings.push({
      message: 'Termin spełnienia jest w przyszłości - odmowa importu.',
      isFatal: true
    });
  } else {
    const { date, alert } = checkDueDate(moment(dueDate));
    if (alert){
      warnings.push({
        message: `${alert  }. Na podstawie art. 115 KC zmieniam na najbliższy możliwy dzień.`,
        isFatal: false
      });
      modDueDate = date;
    }
  }
  if (!_.isString(accountingDoc)){
    warnings.push({
      message: 'Numer faktury nie został wskazany - pomijam.',
      isFatal: false
    });
  }

  return {
    warnings,
    dueDate: moment(modDueDate),
  };
};

const checkPaymentItem = (externalPayment: ExternalPaymentItem) => {
  const {
    paymentDate,
    paymentSum,
    transferDescription,
  } = externalPayment;

  const warnings:ImportWarning[] = [];

  if (!_.isNumber(paymentSum)) {
    warnings.push({
      message: 'Dla importu nie ma kwoty wpłaty.',
      isFatal: true
    });
  } else if (_.isNumber(paymentSum) && paymentSum <= 0){
    warnings.push({
      message: 'Wybrana kwota jest niższa lub równa zero.',
      isFatal: true
    });
  }
  if (!moment(paymentDate).isValid()){
    warnings.push({
      message: 'Termin płatności nie jest datą.',
      isFatal: true
    });
  } else if (moment(paymentDate).isBefore('01-01-2016')){
    warnings.push({
      message: 'Termin płatności jest ustawiony przed 1 stycznia 2016 roku. Aplikacja nie obsługuje roszczeń przed tą datą.',
      isFatal: true
    });
  } else if (moment(paymentDate).isAfter(moment())){
    warnings.push({
      message: 'Termin płatności jest w przyszłości - odmowa importu.',
      isFatal: true
    });
  }
  if (!_.isString(transferDescription)){
    warnings.push({
      message: 'Tytuł wpłaty nie został wskazany - pomijam.',
      isFatal: false
    });
  }

  return warnings;
};


const convertToInterestItem = ({
  dueDate,
  endDate,
  factualBasis,
  externalInterestItem,
  legalBasis,
  maturityDateOpts,
  key
}:{
  key: string,
  dueDate: Moment,
  endDate: Moment,
  factualBasis:string | null,
  externalInterestItem: ExternalInterestItem,
  legalBasis: null | string,
  maturityDateOpts: MaturityDateOpts,
}): InterestItem => {
  return ({
    key,
    startingDate: moment(externalInterestItem.dueDate).add(1, 'day').startOf('day'),
    endDate: endDate.endOf('day'),
    partialSum: _.isNumber(externalInterestItem.partialSum) ? toAmount(externalInterestItem.partialSum) : 0,
    legalBasis,
    factualBasis,
    accountingDoc: externalInterestItem.accountingDoc,
    dueDate:  moment(dueDate),
    maturityDate:  maturityDateOpts === MATURITY_LATE_DAY ? moment(dueDate).add(1, 'day') : moment(dueDate),
    isClaimFromFulfilled: false,
  });
};


const convertToPaymentItem = (externalPaymentItem: ExternalPaymentItem, key: string): PaymentItem => {
  return ({
    key,
    paymentDate: moment(externalPaymentItem.paymentDate),
    paymentSum: _.isNumber(externalPaymentItem.paymentSum) ? toAmount(externalPaymentItem.paymentSum) : 0,
    transferDescription: externalPaymentItem.transferDescription,
    assignedTo: [],
    statementDate: null,
  });
};




