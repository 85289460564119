import { AppDispatch } from '../../../store';
import { OcrItem, ocrItemUpdated } from '../../../store/impOcr';
import { InvcVat, isInvcVat, isPaymentConfirmation, PaymentConfirmation } from '../docTypes';

export type EvidInput = 'calendar' | 'number' | 'string';

export type InvVatFields = 'accountingDoc' | 'createDate' | 'dueDate' | 'partialSum';

export type PayConfFields = 'paymentSum' | 'createDate' | 'paymentTitle' | 'paymentDate';

export const  fieldInputMap = new Map<InvVatFields | PayConfFields, EvidInput>([
  ['createDate', 'calendar'],
  ['dueDate', 'calendar'],
  ['partialSum', 'number'],
  ['accountingDoc', 'string'],
  ['paymentSum', 'number'],
  ['paymentDate', 'calendar'],
  ['paymentTitle', 'string']
]);

export const isInvVatField = (key: InvVatFields | PayConfFields): key is InvVatFields =>
  true;

export const isPayConfField = (key: InvVatFields | PayConfFields): key is PayConfFields =>
  true;

export const isInvTypeMatch = (key: InvVatFields | PayConfFields, ocrItem: OcrItem) =>
  isInvcVat(ocrItem.correctedDocResults) && isInvVatField(key);

export const isPayConfMatch = (key: InvVatFields | PayConfFields, ocrItem: OcrItem) =>
  isPaymentConfirmation(ocrItem.correctedDocResults) && isPayConfField(key);


export const getDocField = (
  docResults: InvcVat | PaymentConfirmation,
  key: InvVatFields | PayConfFields
) => {
  if (isInvcVat(docResults) && isInvVatField(key)){
    return docResults[key];
  }
  if (isPaymentConfirmation(docResults) && isPayConfField(key)){
    return docResults[key];
  }
  return null;
};


export const updateDocResultsByKey = ({
  key,
  value,
  ocrItem,
  dispatch,
  index,
}:{
  key: InvVatFields | PayConfFields,
  value: number | string | null,
  ocrItem: OcrItem,
  dispatch: AppDispatch,
  index: number
}) => {
  if (isInvTypeMatch(key, ocrItem)){
    dispatch(ocrItemUpdated({
      ocrItem:{
        ...ocrItem,
        correctedDocResults:{
          ...ocrItem.correctedDocResults,
          [key]: value
        }
      },
      index,
    }));
  } else if (isPayConfMatch(key, ocrItem)){
    dispatch(ocrItemUpdated({
      ocrItem:{
        ...ocrItem,
        correctedDocResults:{
          ...ocrItem.correctedDocResults,
          [key]: value
        }
      },
      index,
    }));
  } else {
    // eslint-disable-next-line no-console
    console.log("can't update store, bad data match");
  }
};




