/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Moment } from 'moment';


interface Edit {
  key: null | string | number,
  startingDate: Moment | null,
  endDate: Moment | null,
  partialSum: number | null,
  legalBasis: string | null,
  factualBasis: string | null,
  accountingDoc: string | null,
  dueDate: null | Moment,
  maturityDate: null | Moment,
  info: boolean,
  infoMsg: string,
  isCurrVisible: boolean,
  isBasisVisible: boolean,
  isMaturityVisible: boolean,
}


const initialState:Edit = {
  key: null,
  startingDate: null,
  endDate: null,
  partialSum:null,
  legalBasis: null,
  factualBasis: null,
  accountingDoc: null,
  dueDate: null,
  maturityDate: null,
  info: false,
  infoMsg: '',
  isCurrVisible: false,
  isBasisVisible: false,
  isMaturityVisible: false,
};

export const editSlice = createSlice({
  name: 'edit',
  initialState,
  reducers: {
    editKeyUpdated: (state:Edit, action: PayloadAction<null | string | number>) => {
      state.key = action.payload;
    },
    editStartingDateUpdated: (state:Edit, action: PayloadAction<Moment | null>) => {
      state.startingDate = action.payload;
    },
    editEndDateUpdated: (state:Edit, action: PayloadAction<Moment | null>) => {
      state.endDate = action.payload;
    },
    editPartialSumUpdated:(state:Edit, action: PayloadAction<number | null>) => {
      state.partialSum = action.payload;
    },
    editLegalBasisUpdated:(state:Edit, action: PayloadAction<string | null>) => {
      state.legalBasis = action.payload;
    },
    editFactualBasisUpdated: (state:Edit, action: PayloadAction<string | null>) => {
      state.factualBasis = action.payload;
    },
    editAccountingDocUpdated:(state:Edit, action: PayloadAction<string | null>) => {
      state.accountingDoc = action.payload;
    },
    editDueDateUpdated:(state:Edit, action: PayloadAction<Moment | null>) => {
      state.dueDate = action.payload;
    },
    editMaturityDateUpdated:(state:Edit, action: PayloadAction<Moment | null>) => {
      state.maturityDate = action.payload;
    },
    editAllDataUpdated:(state:Edit, action: PayloadAction<{
      startingDate: Moment | null,
      endDate: Moment | null,
      partialSum: number | null,
      legalBasis: string | null,
      factualBasis: string | null,
      accountingDoc: string | null,
      maturityDate: null | Moment,
      dueDate: null | Moment,
    }>) => {
      const {
        startingDate,
        endDate,
        partialSum,
        legalBasis,
        factualBasis,
        accountingDoc,
        maturityDate,
        dueDate,
      } = action.payload;
      return {
        ...state,
        startingDate,
        endDate,
        partialSum,
        legalBasis,
        factualBasis,
        accountingDoc,
        maturityDate,
        dueDate,
      };
    },
    editBasisIsVisible:(state:Edit, action: PayloadAction<boolean>) => {
      state.isBasisVisible = action.payload;
    },
    editCurrencyIsVisible:(state:Edit, action: PayloadAction<boolean>) => {
      state.isCurrVisible = action.payload;
    },
    editMaturityIsVisible:(state:Edit, action: PayloadAction<boolean>) => {
      state.isMaturityVisible = action.payload;
    },
    editBasisReset:(state:Edit) => {
      state.legalBasis = null;
      state.factualBasis = null;
      state.accountingDoc = null;
    },
    editInfoUpdated:(state:Edit, action: PayloadAction<{
      info: boolean,
      infoMsg: string,
    }>) => {
      state.info = action.payload.info;
      state.infoMsg = action.payload.infoMsg;
    },
    editReset:() =>
      initialState
  },
});


export const {
  editKeyUpdated,
  editStartingDateUpdated,
  editEndDateUpdated,
  editPartialSumUpdated,
  editLegalBasisUpdated,
  editFactualBasisUpdated,
  editAccountingDocUpdated,
  editMaturityDateUpdated,
  editAllDataUpdated,
  editBasisIsVisible,
  editMaturityIsVisible,
  editCurrencyIsVisible,
  editDueDateUpdated,
  editInfoUpdated,
  editBasisReset,
  editReset,
} = editSlice.actions;

export default editSlice.reducer;
