import { Col } from 'antd';
import React from 'react';
import ImpExcelCommData from '../components/Import/ImpExcel/ImpExcelCommData';

import ImpExcelEndDate from '../components/Import/ImpExcel/ImpExcelEndDate';
import ImpExcelFile from '../components/Import/ImpExcel/ImpExcelFile';
import ImpExcelSteps from '../components/Import/ImpExcel/ImpExcelSteps';
import ImpExcelSumUp from '../components/Import/ImpExcel/ImpExcelSumUp';
import AnimOpacity from '../components/UI/Anim/AnimOpacity';
import PrivateContainer from '../components/UI/Containers/PrivateContainer';
import DotsEnding from '../components/UI/DotsEnding';
import NavPrivate from '../components/UI/Nav/NavPrivate';
import { useTypedSelector } from '../store';
import { RowExt } from '../styles';

const ImportExcel = () => {
  return (
    <PrivateContainer>
      <AnimOpacity>
        <NavPrivate />
        <ImpExcelSteps />
        <RowExt marginTop="md" fullWidth>
          <Col
            lg={{
              offset:0,
              span:24,
            }}
            xs={{
              offset:2,
              span: 20,
            }}
          >
            <ImportExcelActions />
          </Col>
        </RowExt>
      </AnimOpacity>
      <DotsEnding />
    </PrivateContainer>
  );
};
const ImportExcelActions = () => {
  const step = useTypedSelector(state =>
    state.importEx.step);
  if (step === 0) return <ImpExcelFile />;
  if (step === 1) return <ImpExcelEndDate />;
  if (step === 2) return <ImpExcelCommData />;
  return <ImpExcelSumUp />;
};


export default ImportExcel;
