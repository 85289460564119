import { CalendarOutlined,
  FieldNumberOutlined,
  NumberOutlined,
  StopOutlined,
  WarningOutlined } from '@ant-design/icons';
import { Card, List } from 'antd';
import React from 'react';
import nextId from 'react-id-generator';

import { ExternalInterestItem } from '../../../@types';
import { InterestItem } from '../../../store/interestStorage';
import { BLUE } from '../../../styles';
import { BLACK, RED } from '../../../styles/colors';
import Icon from '../../../styles/Icon';
import { formatDate } from '../../../utils/helpers/date';
import { formatPLN } from '../../../utils/helpers/formatNumber';
import { ImportWarning } from '../../../utils/hooks/useImpOrcValidate';

const ImpExternalField = ({
  interestItem,
  warnings
}:{
  interestItem: InterestItem | ExternalInterestItem
  warnings:  ImportWarning[]
}) => {
  return (
    <>
      <List.Item>
        <List.Item.Meta
          avatar={(
            <>
              <CalendarOutlined />
            </>
)}
          description={interestItem.dueDate ? formatDate(interestItem.dueDate) : ''}
          title="Termin spełnienia świadczenia"
        />
      </List.Item>
      <List.Item>
        <List.Item.Meta
          avatar={(
            <>
              <Icon
                height="16"
                width="16"
                fill={BLACK}
                stroke={BLACK}
                iconType="MONEY_PACK"
              />
            </>
)}
          description={formatPLN(interestItem.partialSum)}
          title="Kwota główna długu"
        />
      </List.Item>
      {interestItem.accountingDoc ? (
        <List.Item>
          <List.Item.Meta
            avatar={(
              <>
                <FieldNumberOutlined />
              </>
)}
            description={interestItem.accountingDoc}
            title="Numer dokumentu kwięgowego"
          />
        </List.Item>
      ) : null}
      {warnings.map(warning =>
        (
          <List.Item
            key={nextId()}
          >
            <List.Item.Meta
              key={nextId()}
              avatar={warning.isFatal ? (
                <StopOutlined style={{ color: RED }} />
              ) : <WarningOutlined style={{ color: BLUE }} />}
              title={warning.isFatal ? 'Odmowa importu' : 'Zmodyfikowano dane | informacja'}
              description={warning.message}
            />
          </List.Item>
        ))}
    </>
  );
};


export const ImpExternalFatalIntr = ({
  warnings,
  fatalErrorItem,
  index
}: {
  warnings: ImportWarning[],
  fatalErrorItem?: ExternalInterestItem,
  index: number
}) => {
  if (!fatalErrorItem) return null;

  const selectedWarnings = warnings.filter(value =>
    value.isFatal);
  return (
    <Card
      type="inner"
      style={{ marginBottom: 12 }}
      extra={(
        <>
          <StopOutlined style={{
            color: RED, margin: '0 2px'
          }}
          />
        </>
)}
      title={(
        <>
          Wierzytelność bez importu
          {' '}
          <NumberOutlined />
          {' '}
          {index + 1}
        </>
)}
    >
      <ImpExternalField
        {...{
          warnings: selectedWarnings,
          interestItem: fatalErrorItem
        }}
      />
    </Card>
  );
};


const ImpExternalItemIntr = ({
  interestItem,
  interestDataWarnings,
  index
}:{
  interestItem: InterestItem,
  interestDataWarnings: {
    key: string,
    warnings: ImportWarning[],
    fatalErrorItem?: ExternalInterestItem,
  }[]
  index: number,
}) => {
  const warningItem = interestDataWarnings.find(value =>
    value.key === interestItem.key);

  const warnings = warningItem ? warningItem.warnings : [];

  return (
    <Card
      type="inner"
      style={{ marginBottom: 12 }}
      extra={(
        <>
          {warningItem ? (
            <WarningOutlined style={{
              color: BLUE, margin: '0 2px'
            }}
            />
          ) : null}
        </>
  )}
      title={(
        <>
          Wierzytelność
          {' '}
          <NumberOutlined />
          {' '}
          {index + 1}
        </>
  )}
    >
      <ImpExternalField
        {...{
          interestItem,
          warnings,
        }}
      />
    </Card>
  );
};


export default ImpExternalItemIntr;