import React from 'react';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { editBasisIsVisible, editBasisReset } from '../../../store/edit';
import { interestFormBasisReset } from '../../../store/interestForm';
import { isBasisVisibleUpdated } from '../../../store/interestFormOptions';
import BasisActions from './BasisActions';
import BasicCard from './BasisCard';
import BasisInputs from './BasisInputs';
import BasisPreview from './BasisPreview';

const Basis = () => {
  const dispatch = useAppDispatch();

  const  editKey = useTypedSelector(state =>
    state.edit.key);

  const onBack = () => {
    if (editKey){
      dispatch(editBasisIsVisible(false));
    } else {
      dispatch(isBasisVisibleUpdated(false));
    }
  };

  const onClear = () => {
    editKey ?
      dispatch(editBasisReset()) :
      dispatch(interestFormBasisReset());
  };

  return (
    <BasicCard>
      <BasisPreview />
      <BasisInputs />
      <BasisActions
        {...{
          onBack,
          onClear
        }}
      />
    </BasicCard>
  );
};

export default Basis;

