import { UnorderedListOutlined } from '@ant-design/icons';
import { Button, Radio, Typography } from 'antd';
import moment from 'moment';
import React from 'react';
import { shallowEqual } from 'react-redux';
import styled from 'styled-components';
import { getInterestDesc } from '../../../../services/calc/interestDesc';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import { isJustListVisibleUpdated, justListOptsUpdated, JUST_LIST_ONLY_SUMS, JUST_LIST_WITH_SUMS } from '../../../../store/justOpts';
import { RowExt } from '../../../../styles';
import { GreyArea, JustRadioButton } from '../../../../styles/ui';
import { formatPLN } from '../../../../utils/helpers/formatNumber';

const JustListOpts = () => {
  const dispatch = useAppDispatch();

  const { justListOpts, isJustListVisible }
   = useTypedSelector(state =>
     state.justOpts, shallowEqual);

  const { partialResults } = useTypedSelector(state =>
    state.sumUp, shallowEqual);

  const { typeOfInterest } = useTypedSelector(state =>
    state.sumUp.lawsuitOpts, shallowEqual);

  return (
    <>
      <RowExt marginTop="md">
        <Typography.Title level={4}>
          Lista kwot składowych
        </Typography.Title>
      </RowExt>
      <ListParagraph type="secondary">Wybierz rodzaj</ListParagraph>
      <RowExt marginTop="md">
        <Radio.Group
          value={justListOpts}
          size="middle"
          onChange={
           (event) => {
             dispatch(justListOptsUpdated(event.target.value));
           }
         }
        >
          <JustRadioButton value={JUST_LIST_WITH_SUMS}>(...) do dnia zapłaty</JustRadioButton>
          <RadioButtonResp value={JUST_LIST_ONLY_SUMS}>same kwoty</RadioButtonResp>
        </Radio.Group>
      </RowExt>
      <RowExt marginTop="md">
        <Button
          type="primary"
          icon={<UnorderedListOutlined />}
          onClick={() => {
            dispatch(isJustListVisibleUpdated(true));
          }}
        >
          Generuj listę kwot składowych
        </Button>
      </RowExt>
      {isJustListVisible ? (
        <GreyArea>
          {justListOpts === JUST_LIST_WITH_SUMS ? (
            <>
              <ListParagraph>{`... wraz z ${getInterestDesc(typeOfInterest).declinationList}, liczonymi od:`}</ListParagraph>
              {partialResults.map(result =>
                (

                  <ListParagraph style={{ width:'100%' }} key={result.key}>
                    {`- kwoty ${formatPLN(result.partialSum)} od dnia ${moment(result.startingDate).format('DD-MM-YYYY')} do dnia zapłaty;` }
                  </ListParagraph>

                ))}
            </>
          ) : (
            <>
              {partialResults.map(result =>
                (
                  <ListParagraph key={result.key}>
                    {`- ${formatPLN(result.partialSum)}` }
                  </ListParagraph>
                ))}
            </>
          )}
        </GreyArea>
      ) : null}
    </>
  );
};

export default JustListOpts;

const ListParagraph = styled(Typography.Paragraph)`
  width: 100%;
`;


export const RadioButtonResp = styled(JustRadioButton)`
  @media only screen and (max-width: 360px) {
      margin-top: 24px;
	}
`;


