import { Col, Row, Space, Statistic, Typography } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import { shallowEqual } from 'react-redux';

import { useTypedSelector } from '../../../store';
import { BLUE } from '../../../styles';
import { toUnit } from '../../../utils/helpers/money';
import CopyButton from '../../UI/Button/CopyButton';


const { Title, Text } = Typography;

const SumUpHeadMain = () => {
  const valueStyle = {
    color: BLUE, fontSize: '32px'
  };
  const [addComp, setAddComp] = useState(false);

  const {
    overallInterestsSum,
    compensationSum,
    finalSum,
    lawsuitOpts,
  } = useTypedSelector(state =>
    state.sumUp, shallowEqual);

  const {
    compensationOn,
    principalAmountOpt
  } = lawsuitOpts;


  const setDesc = () => {
    if (principalAmountOpt === 'PRINCIPAL_INTEREST' && !(compensationOn && addComp)) return texts.desc3;
    if (principalAmountOpt === 'PRINCIPAL_INTEREST' && compensationOn && addComp) return texts.desc4;
    if (compensationOn && addComp) return texts.desc;
    return texts.desc2;
  };

  return (
    <>
      <Title level={3}>{texts.title}</Title>
      <Row>
        <Text type="secondary">{setDesc()}</Text>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          {addComp ? (
            <AnimatePresence>
              <motion.div
                transition={{
                  ease: 'easeInOut', duration: 0.6,
                }}
                initial={{ opacity: addComp ? 0 : 1 }}
                animate={{ opacity: addComp ? 1 : 0 }}
                exit={{ opacity: addComp ? 0 : 1 }}
              >
                <Statistic
                  value={toUnit((finalSum + overallInterestsSum +  compensationSum)).toFixed(2)}
                  precision={2}
                  decimalSeparator=","
                  groupSeparator=" "
                  valueStyle={valueStyle}
                  className="resultMain"
                />
              </motion.div>
            </AnimatePresence>
          ) : null}
          {!addComp ? (
            <AnimatePresence>
              <motion.div
                transition={{
                  ease: 'easeInOut', duration: 0.6,
                }}
                initial={{ opacity: !addComp ? 0 : 1 }}
                animate={{ opacity: !addComp ? 1 : 0 }}
                exit={{ opacity: !addComp ? 0 : 1 }}
              >
                <Statistic
                  value={toUnit((finalSum + overallInterestsSum + (addComp ? compensationSum : 0))).toFixed(2)}
                  precision={2}
                  decimalSeparator=","
                  groupSeparator=" "
                  valueStyle={valueStyle}
                  className="resultMain"
                />
              </motion.div>
            </AnimatePresence>
          ) : null}
          <Row align='middle'>
            <Space>
              <CopyButton
                value={toUnit((finalSum + overallInterestsSum + (addComp ? compensationSum : 0))).toFixed(2).replace(/\./g, ',')}
              />
              {compensationOn ? (
                <Checkbox
                  checked={addComp}
                  onChange={() =>
                    setAddComp(!addComp)}
                  style={{
                    marginTop:18,
                    marginLeft: 18,
                  }}
                >
                  Dodaj rekompensaty
                </Checkbox>
              ) : null}
            </Space>
          </Row>

        </Col>
      </Row>
    </>
  );
};



export default SumUpHeadMain;



const texts = {
  title: 'Łącznie',
  desc: 'Suma kwot składowych wraz ze skapitalizowanymi odsetkami i zsumowanymi rekompensatami',
  desc2: 'Suma kwot składowych wraz ze skapitalizowanymi odsetkami',
  desc3: 'Skapitalizowane odsetki',
  desc4: 'Skapitalizowane odsetki i zsumowane rekompensaty',
};