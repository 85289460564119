import { Col } from 'antd';
import React from 'react';
import CurrencyForm from '../components/Lawsuit/Currency/CurrencyForm';
import AnimOpacity from '../components/UI/Anim/AnimOpacity';
import PrivateContainer from '../components/UI/Containers/PrivateContainer';
import DotsEnding from '../components/UI/DotsEnding';
import NavPrivate from '../components/UI/Nav/NavPrivate';
import { RowExt } from '../styles';

const Currency = () => {
  return (
    <PrivateContainer>
      <AnimOpacity>
        <NavPrivate />
        <RowExt marginTop="md" fullWidth>
          <Col
            lg={{
              offset:0,
              span:24,
            }}
            xs={{
              span: 20,
              offset:2
            }}
          >
            <CurrencyForm />
          </Col>
        </RowExt>
      </AnimOpacity>
      <DotsEnding />
    </PrivateContainer>
  );
};

export default Currency;
