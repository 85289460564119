import { YoutubeOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';
import useWindowSize from '../../../utils/hooks/useWindowSize';
import { EmptyButton, GRID_LG, GRID_MD, GRID_SM } from '../../../styles';
import Icon from '../../../styles/Icon';
import { Player } from '../../Guides/GuideVideo';

const CheckVideoButton = (
  {
    url,
    emptyButtonProps,
    text,
    flat,
  }: {
    url: string,
    text?: string,
    emptyButtonProps?:{
      bgColor?: string, color?: string, hoverColor?: string, hoverBg?: string, dotted?: boolean, full?:boolean
    },
    flat?: boolean,
  }
) => {
  const { width } = useWindowSize();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const videoSize = () => {
    if (width >= GRID_LG) return {
      width: 960, height: 540
    };
    if (width >= GRID_MD) return {
      width: 720, height: 405
    };
    if (width >= GRID_SM) return {
      width: 480, height: 270
    };
    return {
      width: 320, height: '100%'
    };
  };
  const showVideo = () => {
    if (width >= GRID_SM){
      setIsModalVisible(true);
    } else {
      window.open(url, '_blank');
    }
  };

  return (
    <>
      <EmptyButtonSpace
        icon={<YoutubeOutlined />}
        {...emptyButtonProps}
        onClick={showVideo}
        dotted
        $flat={flat}
      >
        {text ?? 'VIDEO - zobacz OSZCZĘDNOŚĆ CZASU'}
      </EmptyButtonSpace>
      <Modal
        onCancel={() =>
          setIsModalVisible(false)}
        visible={isModalVisible}
        footer={false}
        title={(
          <Icon
            height="18"
            width="18"
            fill="white"
            iconType="PLAY"
          />
        )}
        destroyOnClose
        width={videoSize().width + 40}
        style={{ background: 'none !important' }}
      >
        <Player
          url={url}
          volume={0.5}
          playing={isModalVisible}
          height={videoSize().height}
          width={videoSize().width}
          controls
        />
      </Modal>
    </>
  );
};

export default CheckVideoButton;

const EmptyButtonSpace = styled(EmptyButton)<{ $flat?: boolean }>`
    margin: ${props =>
    props.$flat ? '0 0' : '12px 0 18px 0'};
`;
