import { Alert, Radio, RadioChangeEvent, Space, Typography } from 'antd';
import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { ENV_TYPE } from '../../env.json';
import { useAppDispatch } from '../../store';
import { subTypeUpdated } from '../../store/subscribe';
import { ButtonFullWidth, CardRadioButton, RowExt } from '../../styles';
import * as subCodes from '../../utils/helpers/subCodes';
import useWindowSize from '../../utils/hooks/useWindowSize';

const SubscribeActions = () => {
  const dispatch = useAppDispatch();
  const { width } = useWindowSize();
  const history = useHistory();
  const onPress = (event:RadioChangeEvent) => {
    dispatch(subTypeUpdated(event.target.value));
  };

  return (
    <>
      <RowExt marginTop={24} marginBottom={32} justify="center" align="middle">
        <Space>
          <Typography.Text>Poziom wsparcia:</Typography.Text>
          <Radio.Group
            onChange={onPress}
            defaultValue={ENV_TYPE === 'PRODUCTION' ? subCodes.SUB_99_LIVE : subCodes.SUB_99_TEST}
            size={width < 1044 ? 'middle' : 'large'}
            style={{
              display: 'flex', justifyContent:'center'
            }}
          >
            {/* <SelectButton
              value={ENV_TYPE === 'PRODUCTION' ? subCodes.SUB_2_5_LIVE : subCodes.SUB_2_5_LIVE}
            >
              2.5
            </SelectButton> */}
            <SelectButton
              value={ENV_TYPE === 'PRODUCTION' ? subCodes.SUB_49_LIVE : subCodes.SUB_49_TEST}
            >
              49
            </SelectButton>
            <SelectButton value={ENV_TYPE === 'PRODUCTION' ? subCodes.SUB_99_LIVE : subCodes.SUB_99_TEST}>99</SelectButton>
            <SelectButton value={ENV_TYPE === 'PRODUCTION' ? subCodes.SUB_199_LIVE : subCodes.SUB_199_TEST}>199</SelectButton>
          </Radio.Group>
        </Space>
        <RowExt marginTop={32} justify="center" align="middle">
          <Typography.Text>
            <strong> PLN/miesiąc</strong>
          </Typography.Text>
        </RowExt>
      </RowExt>
      <RowExt marginBottom={18} justify="space-between" align="middle">
        <ButtonFullWidth
          type="primary"
          onClick={() =>
            history.push('/payment')}
        >
          Subskrybuj
        </ButtonFullWidth>
      </RowExt>
      {ENV_TYPE === 'STAGING' ? (
        <RowExt marginBottom={18} justify="center" align="middle">
          <Alert message="TESTY: użyj karty o numerze 4242 4242 4242 4242 | reszta danych dowolna" type="info" />
        </RowExt>
      ) : null}
    </>
  );
};

export default SubscribeActions;


const SelectButton = styled(Radio.Button)`
    margin-right: 12px;
`;

export const Button3Lines = styled(CardRadioButton)`
  	@media only screen and (max-width: 580px) {
     padding-top: 34px;
     padding-bottom: 34px;
	}
  	@media only screen and (max-width: 355px) {
     padding-top: 52px;
     padding-bottom: 52px;
	}
`;

export const Button2Lines = styled(CardRadioButton)`
  	@media only screen and (max-width: 520px) {
      padding-top: 34px;
     padding-bottom: 34px;
	}
`;