import { ArrowDownOutlined, ArrowUpOutlined, FieldNumberOutlined } from '@ant-design/icons';
import { Col, Row, Statistic } from 'antd';
import React from 'react';

import { RowBg } from '../../../../styles';
import { formatPLN } from '../../../../utils/helpers/formatNumber';

const ImpStatsMoney = ({
  sumOfInterestData,
  sumOfPaymentData,
  numberOfImports,
}:{
  sumOfInterestData: number
  sumOfPaymentData: number
  numberOfImports: number,
}) => {
  return (
    <RowBg justify='space-around'>
      <Col span={8}>
        <Row justify="center">
          <Statistic
            title="Suma roszczeń"
            value={formatPLN(sumOfInterestData)}
            prefix={<ArrowUpOutlined />}
          />
        </Row>
      </Col>
      <Col span={8}>
        <Row justify="center">
          <Statistic
            title="Suma wpłat"
            value={formatPLN(sumOfPaymentData)}
            prefix={<ArrowDownOutlined />}
          />
        </Row>
      </Col>
      <Col span={8}>
        <Row justify="center">
          <Statistic
            title="Ilość dokumentów"
            value={numberOfImports}
            prefix={<FieldNumberOutlined />}
          />
        </Row>
      </Col>
    </RowBg>
  );
};

export default ImpStatsMoney;