import { Button, Col, Divider, Row, Typography } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { useTypedSelector } from '../../store';
import { ButtonFullWidth, GRID_MD, RowMd, RowSm } from '../../styles';
import { BLACK } from '../../styles/colors';
import useWindowSize from '../../utils/hooks/useWindowSize';
import GuideVideo from '../Guides/GuideVideo';
import { SubIcon } from '../Import/ImpExcel/ImpExcelButton';

const { Paragraph, Title } = Typography;

const SubInfo = (
  {
    thumbSrc,
    url
  }: {
    thumbSrc?: string,
    url: string
  }
) => {
  const  history = useHistory();
  const { width } = useWindowSize();
  const isSubscriber = useTypedSelector(state =>
    state.user.isSubscriber);
  const userExists = useTypedSelector(state =>
    state.user.isNonAnonymousUserLoggedIn);
  if (!isSubscriber){
    return (
      <>
        <RowMd justify="center">
          <Col
            lg={{
              offset: 0,
              span: 17,
            }}
            md={{
              offset: 0,
              span: 12,
            }}
            xs={{
              offset: 0,
              span: 24,
            }}
          >
            <GuideVideo
              {...{
                src: thumbSrc, url, color: BLACK
              }}
            />
          </Col>
        </RowMd>
        <Row justify="center" align="middle">
          {' '}
          <SubIcon />
          <SubInfoTitle level={5}>
            WYMAGA SUBSKRYPCJI
          </SubInfoTitle>
        </Row>
        <Row
          style={{ marginTop: 18 }}
          justify="center"
        >
          <Col
            lg={{
              offset: 0,
              span: 17,
            }}
            md={{
              offset: 0,
              span: 12,
            }}
            xs={{
              offset: 0,
              span: 24,
            }}
          >
            <ButtonFullWidth
              type="primary"
              onClick={() =>
                history.push('/subscribe')}
            >
              {width > GRID_MD ? 'ODBLOKUJ TERAZ - zacznij DARMOWY okres próbny' : 'ODBLOKUJ TERAZ'}
            </ButtonFullWidth>
          </Col>
        </Row>
        <RowSm justify="center">
          {width <= GRID_MD ? (
            <Paragraph>
              Zacznij DARMOWY okres próbny
            </Paragraph>
          ) : null}
          <Paragraph type='secondary'>
            Okres próbny trwa 14 dni, w czasie których bez żadnych kosztów możesz
            {' '}
            <strong>zrezygnować</strong>
            .
          </Paragraph>
          <Paragraph type="secondary">
            Sprawdź teraz, czy ta funkcja spełnia Twoje oczekiwania.
          </Paragraph>
        </RowSm>
        {!userExists ? (
          <>
            <Divider>LUB</Divider>
            <Row justify="center">
              <Button
                type="link"
                onClick={() =>
                  history.push('/login', { returnTo: 'goBack' })}
              >
                ZALOGUJ SIĘ JAKO SUBSKRYBENT
              </Button>
            </Row>
          </>
        ) : null}
      </>
    );
  }
  return null;
};

export default SubInfo;

const SubInfoTitle = styled(Title)`
  font-weight: 700;
  text-align: center;
  padding-top: 10px
`;