import './index.css';
import 'firebase/compat/analytics';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';

import { ConfigProvider } from 'antd';
import Dinero from 'dinero.js';
import moment from 'moment';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import plPL from '../node_modules/antd/lib/locale/pl_PL';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './store';

moment.locale('pl', { week: { dow: 1 } });

Dinero.globalLocale = 'pl-PL';


const app = (
  <Provider store={store}>
    <ConfigProvider locale={plPL}>
      <App />
    </ConfigProvider>
  </Provider>
);

ReactDOM.render(
  app,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
