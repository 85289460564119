import _ from 'lodash';
import moment, { Moment } from 'moment';
import { shallowEqual } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { INVC_VAT, InvcVat, PAYMENT_CONFIRMATION, PaymentConfirmation } from '../../services/ML/docTypes';
import { useTypedSelector } from '../../store';
import { MATURITY_LATE_DAY, MaturityDateOpts } from '../../store/interestFormOptions';
import { InterestData, InterestItem, PaymentData, PaymentItem } from '../../store/interestStorage';
import { checkDueDate } from '../helpers/date';
import { toAmount } from '../helpers/money';


export type ImportWarning = {
  message: string,
  isFatal: boolean,
};

type Acc = {
  interestData: InterestData,
  paymentData: PaymentData,
  warningData: {
    order: number,
    warnings: ImportWarning[]
  }[],
};

const useImpOrcValidate = () => {
  const impOrcValidated = useTypedSelector(state => {
    const {
      factualBasis,
      legalBasis,
      maturityDateOpts,
      endDate,
      ocrData,
    } = state.impOcr;

    return ocrData.reduce((prev:Acc, curr, index) => {
      if (curr.correctedDocType === INVC_VAT){
        const {
          warnings,
          order,
          dueDate
        } = checkInvcVat(curr.correctedDocResults as InvcVat, index);

        const { isFatalError, warningOnlyData } = checkFatalImpError(prev, {
          warnings,
          order,
        });

        if (isFatalError) return warningOnlyData;

        return {
          ...prev,
          interestData: [
            ...prev.interestData,
            invcVatToInterestItem({
              dueDate,
              endDate: endDate ?? moment().endOf('day'),
              factualBasis,
              invcVat: curr.correctedDocResults as InvcVat,
              legalBasis,
              maturityDateOpts,
            }),
          ],
          warningData: _.isEmpty(warnings) ?
            prev.warningData : [
              ...prev.warningData,
              {
                order,
                warnings
              }
            ]
        };
      }

      if (curr.correctedDocType === PAYMENT_CONFIRMATION){
        const check = checkPayCon(curr.correctedDocResults as PaymentConfirmation, index);

        const { isFatalError, warningOnlyData } = checkFatalImpError(prev, check);

        if (isFatalError) return warningOnlyData;

        return {
          ...prev,
          paymentData: [
            ...prev.paymentData,
            payConToPaymentItem(curr.correctedDocResults as PaymentConfirmation),
          ],
          warningData: _.isEmpty(check.warnings) ?
            prev.warningData : [
              ...prev.warningData,
              check
            ]
        };
      }
      return prev;
    }, {
      interestData: [],
      paymentData: [],
      warningData: [],
    });
  }, shallowEqual);

  const sumOfInterestData = impOrcValidated.interestData.reduce((prev, curr) =>
    prev + curr.partialSum, 0);
  const sumOfPaymentData = impOrcValidated.paymentData.reduce((prev, curr) =>
    prev + curr.paymentSum, 0);
  return {
    ...impOrcValidated,
    sumOfInterestData,
    sumOfPaymentData,
  };
};

export default useImpOrcValidate;


export const checkFatalImpError = (prev: Acc, check: {
  order: number,
  warnings: ImportWarning[]
}) => {
  const {
    order,
    warnings
  } = check;
  const isFatalError = warnings.some(value =>
    value.isFatal === true);
  return {
    isFatalError,
    warningOnlyData:{
      ...prev,
      warningData: [
        ...prev.warningData,
        {
          order,
          warnings
        }
      ]
    }
  };
};


const checkInvcVat = (extractedData: InvcVat, index: number) => {
  const {
    partialSum,
    dueDate,
    accountingDoc,
  } = extractedData;

  let modDueDate = dueDate;

  const warnings:ImportWarning[] = [];

  if (!_.isNumber(partialSum)) {
    warnings.push({
      message: 'Dla importu nie ma wybranej kwoty, która miałaby być dochodzona.',
      isFatal: true
    });
  } else if (_.isNumber(partialSum) && partialSum <= 0){
    warnings.push({
      message: 'Wybrana kwota jest niższa lub równa zero.',
      isFatal: true
    });
  }
  if (!moment(dueDate).isValid()){
    warnings.push({
      message: 'Termin spełnienia świadczenia nie jest datą.',
      isFatal: true
    });
  } else if (moment(dueDate).isBefore('01-01-2016')){
    warnings.push({
      message: 'Termin spełnienia jest ustawiony przed 1 stycznia 2016 roku. Aplikacja nie obsługuje roszczeń przed tą datą.',
      isFatal: true
    });
  } else if (moment(dueDate).isAfter(moment())){
    warnings.push({
      message: 'Termin spełnienia jest w przyszłości - odmowa importu.',
      isFatal: true
    });
  } else {
    const { date, alert } = checkDueDate(moment(dueDate));
    if (alert){
      warnings.push({
        message: `${alert  }. Na podstawie art. 115 KC zmieniam na najbliższy możliwy dzień.`,
        isFatal: false
      });
      modDueDate = date;
    }
  }
  if (!_.isString(accountingDoc)){
    warnings.push({
      message: 'Numer faktury nie został wskazany - pomijam.',
      isFatal: false
    });
  }

  return {
    warnings,
    dueDate: moment(modDueDate),
    order: index + 1,
  };
};

const checkPayCon = (extractedData: PaymentConfirmation, index: number) => {
  const {
    paymentDate,
    paymentSum,
    paymentTitle,
  } = extractedData;

  const warnings:ImportWarning[] = [];

  if (!_.isNumber(paymentSum)) {
    warnings.push({
      message: 'Dla importu nie ma kwoty wpłaty.',
      isFatal: true
    });
  } else if (_.isNumber(paymentSum) && paymentSum <= 0){
    warnings.push({
      message: 'Wybrana kwota jest niższa lub równa zero.',
      isFatal: true
    });
  }
  if (!moment(paymentDate).isValid()){
    warnings.push({
      message: 'Termin płatności nie jest datą.',
      isFatal: true
    });
  } else if (moment(paymentDate).isBefore('01-01-2016')){
    warnings.push({
      message: 'Termin płatności jest ustawiony przed 1 stycznia 2016 roku. Aplikacja nie obsługuje roszczeń przed tą datą.',
      isFatal: true
    });
  } else if (moment(paymentDate).isAfter(moment())){
    warnings.push({
      message: 'Termin płatności jest w przyszłości - odmowa importu.',
      isFatal: true
    });
  }
  if (!_.isString(paymentTitle)){
    warnings.push({
      message: 'Tytuł wpłaty nie został wskazany - pomijam.',
      isFatal: false
    });
  }

  return {
    warnings,
    order: index + 1,
  };
};


const invcVatToInterestItem = ({
  dueDate,
  endDate,
  factualBasis,
  invcVat,
  legalBasis,
  maturityDateOpts,
}:{
  dueDate: Moment,
  endDate: Moment,
  factualBasis:string | null,
  invcVat: InvcVat,
  legalBasis: null | string,
  maturityDateOpts: MaturityDateOpts,
}): InterestItem => {
  return ({
    key:uuidv4(),
    startingDate: moment(invcVat.dueDate).add(1, 'day').startOf('day'),
    endDate: endDate.endOf('day'),
    partialSum: _.isNumber(invcVat.partialSum) ? toAmount(invcVat.partialSum) : 0,
    legalBasis,
    factualBasis,
    accountingDoc: invcVat.accountingDoc,
    dueDate:  moment(dueDate),
    maturityDate:  maturityDateOpts === MATURITY_LATE_DAY ? moment(dueDate).add(1, 'day') : moment(dueDate),
    isClaimFromFulfilled: false,
  });
};


const payConToPaymentItem = (paymentConfirmation: PaymentConfirmation): PaymentItem => {
  return ({
    key:uuidv4(),
    paymentDate: moment(paymentConfirmation.paymentDate),
    paymentSum: _.isNumber(paymentConfirmation.paymentSum) ? toAmount(paymentConfirmation.paymentSum) : 0,
    transferDescription: paymentConfirmation.paymentTitle,
    assignedTo: [],
    statementDate: null,
  });
};




