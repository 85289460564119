import { notification } from 'antd';
import _ from 'lodash';

import { AppDispatch } from '../../../store';
import { appLoadingStarted, appLoadingStopped, appLoadingTextChanged } from '../../../store/appLoading';
import { ocrDataUpdated, ocrStepUpdated } from '../../../store/impOcr';
import { errDescGeneral, errMsgGeneral } from '../../../utils/helpers/errorTexts';
import { createMultiPage } from '../createMultiPage';
import { createOcrItems } from '../createOcrItems';
import { DocTypes } from '../docTypes';
import { ocrCategorize } from './ocrCategorize';
import { getImagesForOcr } from './pdfToImage';


export const importPdf = async ({
  files,
  dispatch,
}: {
  files: File[],
  dispatch: AppDispatch
}) => {
  try {
    dispatch(appLoadingStarted());

    document.body.classList.add('no-scroll');

    if (_.isEmpty(files)) throw new Error('Plik jest niewłaściwy, albo zawiera błędy.');

    checkFilesType(files);

    dispatch(appLoadingTextChanged('analizuję pliki'));

    const images = await getImagesForOcr(files);

    const docsWithCategory = await ocrCategorize({
      images,
      dispatch,
    });

    const withoutBlank = docsWithCategory.reduce((prev:{
      input: string;
      output: DocTypes;
      image: string;
    }[], curr) => {
      if (curr.input === '') return prev;
      return [
        ...prev,
        curr
      ];
    }, []);

    const refactoredDocs = createMultiPage(withoutBlank);

    const ocrItems = await createOcrItems(refactoredDocs);

    dispatch(ocrDataUpdated(ocrItems));

    dispatch(ocrStepUpdated(1));

  } catch (error) {
    if (error instanceof Error){
      notification.error({
        message: errMsgGeneral, description: error.message
      });
    } else {
      notification.error({
        message: errMsgGeneral, description: errDescGeneral
      });
    }
  } finally {
    document.body.classList.remove('no-scroll');
    dispatch(appLoadingStopped());
  }
};


const checkFilesType = (
  files: File[]
) => {
  files.forEach(({ type }) => {
    if (type !== 'application/pdf'
    && type !== 'image/png'
    && type !== 'image/jpg'
    && type !== 'image/jpeg'
    ) {
      throw new Error('Co najmniej jeden plik ma nieprawdiłowy format');
    }
  });
};
