import { RightOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Typography } from 'antd';
import _ from 'lodash';
import { remove } from 'ramda';
import React, { useEffect } from 'react';
import { ArcherContainer, ArcherElement } from 'react-archer';
import nextId from 'react-id-generator';
import { shallowEqual, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { useTypedSelector } from '../../../../store';
import { paymentDataChanged } from '../../../../store/interestStorage';
import { RowExt, RowMd, RowSm } from '../../../../styles';
import { toUnit } from '../../../../utils/helpers/money';

const RemoveAssignmentModal = ({
  isRemoveModalVisible,
  setRemoveModalVisible,
  paymentIdx,
  setPaymentIdx,
  color,
}:{
  isRemoveModalVisible:boolean,
  setRemoveModalVisible:(isRemoveModalVisible:boolean)=>void,
  paymentIdx: number | null,
  setPaymentIdx: (paymentIdx: number | null) => void,
  color: string,
}) => {

  const dispatch = useDispatch();

  const onFinish = () => {
    setRemoveModalVisible(false);
    setPaymentIdx(null);
  };

  const { paymentData:pd } = useTypedSelector(state =>
    state.interestStorage, shallowEqual);

  const assignmentsForDisplay = useTypedSelector(state => {
    const { interestData, paymentData } = state.interestStorage;
    if (_.isNumber(paymentIdx) && paymentIdx >= 0){
      const { assignedTo } = paymentData[paymentIdx];
      return assignedTo.map(({ key, sum }, index) => {
        const interestItem = interestData.find(value =>
          value.key === key);
        if (interestItem){
          const { partialSum } = interestItem;
          return ({
            partialSum, sum, key, index
          });
        }
        return ({
          partialSum: 0, sum, key, index
        });
      });
    }
    return [];
  });

  const onRemoveClick = (index: number) => {
    const paymentDataCopy = _.cloneDeep(pd);
    if (_.isNumber(paymentIdx)) {
      const dataWoEl = remove(index, 1, paymentDataCopy[paymentIdx].assignedTo);
      paymentDataCopy[paymentIdx].assignedTo = dataWoEl;
      dispatch(paymentDataChanged(paymentDataCopy));
    }
  };

  useEffect(() => {
    if (_.isNumber(paymentIdx) && pd[paymentIdx].assignedTo.length === 0){
      setRemoveModalVisible(false);
      setPaymentIdx(null);
    }
  }, [paymentIdx, pd, setPaymentIdx, setRemoveModalVisible]);


  return (
    <Modal
      onCancel={onFinish}
      visible={isRemoveModalVisible}
      onOk={onFinish}
      destroyOnClose
      style={{ background: 'none !important' }}
      footer={false}
    >
      <RowSm>
        <Typography.Title level={5}>Wybierz które przypisania usunąć?</Typography.Title>
      </RowSm>
      <RowMd
        align="middle"
        justify="space-around"
      >
        <Col span={6}>
          <RowStyled
            justify="end"
          >
            <Typography.Title level={5}> Roszczenie</Typography.Title>
          </RowStyled>
        </Col>
        <Col span={2} />
        <Col span={6}>
          <Typography.Title level={5}>
            {' '}
            <RowStyled
              justify="start"
            >
              Wpłata
            </RowStyled>
          </Typography.Title>
        </Col>
        <Col span={4} />
      </RowMd>
      <ArcherContainer>
        {assignmentsForDisplay.map(({
          sum,
          partialSum,
          key,
          index,
        }) =>
          (
            <RowSm
              key={key}
              align="middle"
            >
              <ArcherElement
                id={key}
              >
                <Col span={6}>
                  <RowStyled
                    justify="end"
                  >
                    {toUnit(partialSum)}
                    {' '}
                    zł
                  </RowStyled>
                </Col>
              </ArcherElement>
              <Col span={6} />
              <ArcherElement
                id={nextId()}
                relations={[
                  {
                    targetId: key,
                    targetAnchor: 'right',
                    sourceAnchor: 'left',
                    style: {
                      strokeColor: color,
                      strokeWidth: 1,
                      strokeDasharray: '4',
                    }
                  }]}
              >
                <Col span={6}>
                  <RowStyled
                    justify="start"
                  >
                    {toUnit(sum)}
                    {' '}
                    zł
                  </RowStyled>
                </Col>
              </ArcherElement>
              <Col span={4}>
                <Button
                  type="text"
                  onClick={() =>
                    onRemoveClick(index)}
                >
                  <RightOutlined />
                </Button>
              </Col>
            </RowSm>
          ))}
      </ArcherContainer>
    </Modal>
  );
};

export default RemoveAssignmentModal;

const RowStyled = styled(RowExt)`
    padding-left: 12px;
`;
