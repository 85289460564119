/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Moment } from 'moment';

interface InterestForm {
  startingDate: Moment | null,
  endDate: Moment | null,
  partialSum: number | null,
  legalBasis: string | null,
  factualBasis: string | null,
  accountingDoc: string | null,
  dueDate: null | Moment,
  maturityDate: null | Moment,
}

const initialState:InterestForm = {
  startingDate: null,
  endDate: null,
  partialSum:null,
  legalBasis: null,
  factualBasis: null,
  accountingDoc: null,
  dueDate: null,
  maturityDate: null,
};

export const interestFormSlice = createSlice({
  name: 'interestForm',
  initialState,
  reducers: {
    startingDateUpdated:(state:InterestForm, action:PayloadAction<Moment | null>) => {
      state.startingDate = action.payload;
    },
    endDateUpdated:(state:InterestForm, action:PayloadAction<Moment | null>) => {
      state.endDate = action.payload;
    },
    partialSumUpdated:(state:InterestForm, action:PayloadAction<number | null>) => {
      state.partialSum = action.payload;
    },
    legalBasisUpdated:(state:InterestForm, action:PayloadAction<string | null>) => {
      state.legalBasis = action.payload;
    },
    factualBasisUpdated:(state:InterestForm, action: PayloadAction<string | null>) => {
      state.factualBasis = action.payload;
    },
    accountingDocUpdated:(state:InterestForm, action: PayloadAction<string | null>) => {
      state.accountingDoc = action.payload;
    },
    dueDateUpdated:(state:InterestForm, action: PayloadAction<Moment | null>) => {
      state.dueDate = action.payload;
    },
    maturityDateUpdated:(state:InterestForm, action: PayloadAction<Moment | null>) => {
      state.maturityDate = action.payload;
    },
    interestFormAllDataUpdated:(state:InterestForm, action: PayloadAction<InterestForm>) => {
      return { ...action.payload };
    },
    interestFormBasisReset:(state:InterestForm) => {
      state.legalBasis = null;
      state.factualBasis = null;
      state.accountingDoc = null;
    },
    interestFormReset:() =>
      initialState
  },
});

export const {
  startingDateUpdated,
  endDateUpdated,
  partialSumUpdated,
  legalBasisUpdated,
  factualBasisUpdated,
  accountingDocUpdated,
  dueDateUpdated,
  maturityDateUpdated,
  interestFormAllDataUpdated,
  interestFormBasisReset,
  interestFormReset
} = interestFormSlice.actions;

export default interestFormSlice.reducer;

