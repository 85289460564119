import { Col, Typography } from 'antd';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import logo from '../../../assets/logo.svg';
import useWindowSize from '../../../utils/hooks/useWindowSize';
import { GRID_MD, RowExt, ScreenLayout } from '../../../styles';
import { ImageLogo } from '../../Home/Menu/MenuLogo';
import AnimOpacity from '../Anim/AnimOpacity';
import DotsEnding from '../DotsEnding';

const AuthContainer = (
  {
    children,
    title,
    desc
  }: {
    children?: ReactNode,
    title:string,
    desc: string
  }
) => {
  const { width } = useWindowSize();
  return (
    <AnimOpacity>
      <ScreenLayout>
        <FullRow justify="center">
          <Wrapper>
            <RowExt
              fullWidth
              paddingTop="md"
              paddingBottom={width >= GRID_MD ? 64 : 32}
            >
              <Col
                xs={{
                  offset: 2,
                  span:20,
                }}
              >
                <Link
                  to="/"
                >
                  <ImageLogo
                    src={logo}
                  />
                </Link>
              </Col>
            </RowExt>
            <RowExt
              fullWidth
              paddingTop="md"
              style={{ flex:1 }}
            >
              <Col
                xs={{
                  offset: 2,
                  span:20,
                }}
              >
                <Typography.Title level={2}>{title}</Typography.Title>
                <RowExt marginBottom="sm">
                  <Typography.Text type="secondary">{desc}</Typography.Text>
                </RowExt>
                {children}
              </Col>
            </RowExt>
            <DotsEnding />
          </Wrapper>
        </FullRow>
      </ScreenLayout>
    </AnimOpacity>
  );
};

export default AuthContainer;


const Wrapper = styled.div`
  width: 425px;
  text-align: start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const FullRow = styled(RowExt)`
  height: 100vh;
`;