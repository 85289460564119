import { Descriptions } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { formatDate } from '../../../utils/helpers/date';
import { formatPLN } from '../../../utils/helpers/formatNumber';
import { COMPENSATION_BASIS_SHORT } from '../../../utils/helpers/lawBasis';
import { toUnit } from '../../../utils/helpers/money';
import useWindowSize from '../../../utils/hooks/useWindowSize';
import { useTypedSelector } from '../../../store';
import { PartialResult } from '../../../store/sumUp';
import { GRID_SM } from '../../../styles';
import { DescFullWidth } from '../../../styles/ui';
import { WpsTitle } from '../WpsFees/WpsTitle';

const { Item: DescItem } = Descriptions;

const DetailsPartialComp = ({ partialResult }: { partialResult: PartialResult }) => {
  const { width } = useWindowSize();
  const compensationOn = useTypedSelector((state) =>
    state.sumUp.lawsuitOpts.compensationOn);
    // eslint-disable-next-line
  if (compensationOn) {
    return (
      <>
        <WpsTitle title={`Rekompensta na podstawie art. 10 ${COMPENSATION_BASIS_SHORT}`} />
        <DescFullWidth
          layout={width > GRID_SM ? 'horizontal' : 'vertical'}
          bordered
          key={partialResult.key}
        >
          <DescItem span={3} label="Wartość rekompensaty w Euro">
            {`${toUnit(partialResult.compensationResult?.valueEuro ?? 0)} euro`}
          </DescItem>
          <DescItem label="Dzień ustalenia kursu" span={3}>
            {formatDate(partialResult.compensationResult?.lastWorkingDay ?? '')}
          </DescItem>
          <DescItem label="Średni kurs euro" span={3}>
            <>{partialResult?.compensationResult?.exchangeRate}</>
          </DescItem>
          <DescItem span={3} label="Wartość rekompensaty w PLN">
            <ResultText>{`${formatPLN(partialResult.compensationResult?.valuePLN)}`}</ResultText>
          </DescItem>
        </DescFullWidth>
      </>
    );
  }
  return null;
};

export default DetailsPartialComp;

export const ResultText = styled.span`
	font-size: 15px;
	font-weight: 600;
`;
