import { Button, Col, Divider, Row, Space } from 'antd';
import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Img } from 'react-image';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import logo from '../../assets/logo.svg';
import useWindowSize from '../../utils/hooks/useWindowSize';
import { AboutContainer, DashedColBoth, DashedColLeft, GRID_MD, GRID_MD_PX, RowMd } from '../../styles';
import { ScreenBackButton } from '../UI/DescScreenFooter';

const AboutHeader = () => {
  const { width } = useWindowSize();
  const history = useHistory();
  return (
    <AboutContainer>
      <Row>
        <Col
          lg={{
            offset: 1,
            span: 8,
          }}
          md={{
            offset: 1,
            span: 7
          }}
          xs={{
            offset: 2,
            span: 10,
          }}
        >
          <Link
            to={{ pathname: '/' }}
          >
            <Logo
              src={logo}
            />
          </Link>
        </Col>
        <DashedColBoth
          xl={{ span: 11 }}
          lg={{ span: 11 }}
          md={16}
          xs={0}
        >
          {(width >= GRID_MD) ? (
            <RowMd align="middle" justify="center">
              <Space>
                <>
                  <AnchorLink href="#benefits">
                    <Button type="link">Korzyści</Button>
                  </AnchorLink>
                  <AnchorLink href="#codes">
                    <Button type="link">Kody</Button>
                  </AnchorLink>
                  <AnchorLink href="#support">
                    <Button type="link" href="#support">Wesprzyj</Button>
                  </AnchorLink>
                  <AnchorLink href="#faq">
                    <Button type="link">F.A.Q</Button>
                  </AnchorLink>
                  <AnchorLink href="#author">
                    <Button type="link" href="#author">Autor</Button>
                  </AnchorLink>
                  <Divider type='vertical' />
                  <ScreenBackButton
                    onClick={() =>
                      history.push('/')}
                  />
                </>
              </Space>
            </RowMd>
          ) : null}
        </DashedColBoth>
        <Col md={0} xs={10} sm={10}>
          <RowMd align="middle" justify="end">
            <ScreenBackButton
              onClick={() =>
                history.push('/')}
            />
          </RowMd>
        </Col>
        <Col
          xl={{ span: 2 }}
          lg={{ span: 1 }}
          xs={0}
        />
        <DashedColLeft
          xl={{ span: 2 }}
          lg={{ span: 3 }}
          xs={0}
        />
      </Row>
    </AboutContainer>
  );
};

export default AboutHeader;

const Logo = styled(Img)`
    width: 124px;
    border-radius: 4px;
    margin-top: 24px;
    margin-bottom: 72px;
  @media only screen and (max-width: ${GRID_MD_PX}) {
    margin-bottom: 48px;
	}
`;



