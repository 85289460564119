import { ReloadOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Tag } from 'antd';
import { Meta } from 'antd/lib/list/Item';
import { User } from 'firebase/auth';
import React from 'react';
import styled from 'styled-components';
import { auth } from '../../services/firebase/auth';
import { GRID_SM_PX } from '../../styles';
import { RED } from '../../styles/colors';


const emailIsNotVerified = (user: User | null) =>
  user ? (
    user.emailVerified === false &&
   user.providerData[0].providerId !== 'facebook.com' &&
   user.providerData[0].providerId !== 'google.com') : false;


const UserData = () => {
  const { currentUser } = auth;
  return (
    <Card
      title="Twoje dane"
      style={{
        width: '100%', marginTop: 16
      }}
      actions={emailIsNotVerified(currentUser) ? [
        <div
          key="tag"
          style={{ marginTop:6 }}
        >
          <Tag color={RED}>
            Niezweryfikowany email
          </Tag>
        </div>,
        <Button type="link" key="button">
          Wyślij ponownie weryfikację
        </Button>,
        <Button type="text" icon={<ReloadOutlined />} key="button" />
      ] : undefined}
    >
      <Meta
        avatar={(
          <Avatar icon={<UserOutlined />} src={auth.currentUser?.photoURL} />
        )}
        title={auth.currentUser?.email}
        description={<Desc>{`id: ${auth.currentUser?.uid ?? ''}`}</Desc>}
      />
    </Card>
  );
};

export default UserData;

export const Desc = styled.span`
  	@media only screen and (max-width: ${GRID_SM_PX}) {
    font-size: 12px;
	}
`;