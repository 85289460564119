import { ArrowDownOutlined,
  CheckSquareOutlined,
  CloseOutlined,
  CloseSquareOutlined,
  DownloadOutlined,
  PlusOutlined,
  RobotFilled,
  ToolOutlined } from '@ant-design/icons';
import { Button, Col, notification, Popconfirm, Typography } from 'antd';
import _ from 'lodash';
import { all } from 'ramda';
import React, { useMemo, useState } from 'react';
import { shallowEqual } from 'react-redux';

import { ENV_TYPE } from '../../../env.json';
import { assignToOldestNew } from '../../../services/assignToOldestNew';
import { autoAssign } from '../../../services/autoAssign/autoAssign';
import { updateAutoAssignTd } from '../../../services/ML/trainingData/autoAssignTd';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { interestAllDataChanged, paymentDataChanged } from '../../../store/interestStorage';
import { isAddPaymentsVisibleUpdate, isImportPaymentVisibleUpdated } from '../../../store/lawsuitUI';
import { BLACK, BLUE, DashedColRight, GREY_LIGHT, GRID_MD, RowExt } from '../../../styles';
import { ButtonFullWidth, EmptyButton } from '../../../styles/ui';
import useWindowSize from '../../../utils/hooks/useWindowSize';
import AssignToOldestModal from './Claim/AssignToOldestModal';
import AutoAssignModal from './Claim/AutoAssignModal';
import ClaimAllFulfilledIModal from './Claim/ClaimAllFulfilledIModal';

const { Title } = Typography;



const AssignPaymentsActions = () => {

  const { width } = useWindowSize();

  const dispatch = useAppDispatch();

  const [isModalVisible, setModalVisible] = useState(false);
  const [isAssignModalVisible, setAssignModalVisible] = useState(false);
  const [isAutoModalVisible, setAutoModalVisible] = useState(false);
  const userExists = useTypedSelector(state =>
    state.user.isNonAnonymousUserLoggedIn);

  const  { paymentData, interestData } = useTypedSelector(state =>
    state.interestStorage, shallowEqual);

  const lawsuitOpts = useTypedSelector(state =>
    state.lawsuitOpts);

  const removeAllAssigns = () => {
    const paymentDataWoAssigns = paymentData.map(paymentItem => {
      return {
        ...paymentItem,
        assignedTo:[],
      };
    });
    dispatch(paymentDataChanged(paymentDataWoAssigns));
  };

  const changeAllClaimFromFulfilled = (bool: boolean) => {
    const interestDataModified = interestData.map(interestItem => {
      return {
        ...interestItem,
        isClaimFromFulfilled: bool,
      };
    });
    dispatch(interestAllDataChanged(interestDataModified));
  };

  const showModal = (isAssign: boolean) => {
    closeModal();
    isAssign ? setAssignModalVisible(true) : setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
    setAssignModalVisible(false);
  };

  const onOn = () => {
    changeAllClaimFromFulfilled(true);
    closeModal();
  };

  const onOff = () => {
    changeAllClaimFromFulfilled(false);
    closeModal();
  };

  const onAssignToOldest = (isInterestFirst: boolean) => {
    assignToOldestNew({
      dispatch,
      paymentData,
      interestData,
      lawsuitOpts,
      isInterestFirst,
    });
    closeModal();
  };

  const assignToInterestFirst = () =>
    onAssignToOldest(true);

  const assignToMain = () =>
    onAssignToOldest(false);

  const areAllFulfilled = useMemo(() =>
    all(({ isClaimFromFulfilled }) =>
      isClaimFromFulfilled === true, interestData),
  [interestData]);

  const onShowHistory = (shouldShowHistory: boolean) => {
    setAutoModalVisible(false);
    if (_.isEmpty(paymentData)) {
      notification.info({ message:'Uzupełnij najpierw wpłaty' });
    } else {
      autoAssign({
        interestData,
        paymentData,
        dispatch,
        lawsuitOpts,
        shouldShowHistory,
      });
    }

  };

  const sendData = () => {
    updateAutoAssignTd({
      dispatch,
      paymentData,
      interestData,
      userExists
    });
  };

  const isDevEnv = ENV_TYPE === 'DEVELOPMENT';


  if (width < GRID_MD) {
    return (
      <>
        <RowExt marginTop="md">
          <Col span={24}>
            <ButtonFullWidth
              type="primary"
              onClick={() =>
                dispatch(isAddPaymentsVisibleUpdate(true))}
              icon={(
                <PlusOutlined />
)}
            >
              Dodaj
            </ButtonFullWidth>
          </Col>
        </RowExt>
        <RowExt marginTop="md">
          <Col span={24}>
            <Popconfirm
              title="Usunąć wszystkie zaliczenia wpłat"
              onConfirm={removeAllAssigns}
            >
              <ButtonFullWidth
                type="dashed"
                icon={(
                  <CloseOutlined />
)}
              >
                Usuń
              </ButtonFullWidth>
            </Popconfirm>
          </Col>
        </RowExt>
        <RowExt marginTop="md">
          <Col span={24}>
            <EmptyButton
              full
              type="primary"
              icon={<DownloadOutlined />}
              onClick={() =>
                dispatch(isImportPaymentVisibleUpdated(true))}
            >
              Importuj
            </EmptyButton>
          </Col>
        </RowExt>
        <RowExt marginTop="md">
          <Col span={24}>
            <EmptyButton
              dotted
              full
              {...{
                color: areAllFulfilled ? BLUE : BLACK,
                hoverBg: areAllFulfilled ? BLUE : BLACK,
                hoverColor: 'white'
              }}
              onClick={() =>
                setModalVisible(true)}
              icon={(
                  areAllFulfilled ?
                    <CheckSquareOutlined /> :
                    <CloseSquareOutlined />
)}
            >
              Odsetki od zapokojonych
            </EmptyButton>
          </Col>
        </RowExt>
      </>
    );
  }


  return (
    <>
      <RowExt align="middle">
        <DashedColRight
          xs={12}
          style={{
            borderColor: GREY_LIGHT, paddingBottom: 24
          }}
        >
          <RowExt justify="center">
            <Title level={3}>
              Roszczenia
            </Title>
            {isDevEnv ?  (
              <Button
                icon={<ToolOutlined />}
                type="link"
                onClick={sendData}
              />
            ) : null}
          </RowExt>
          <RowExt marginTop={12} align="middle">
            <Col offset={1} span={6}>
              <EmptyButton
                on
                icon={(
                  <ArrowDownOutlined />
)}
                onClick={() =>
                  showModal(true)}
              >
                Wszystkie
              </EmptyButton>
            </Col>
            <Col offset={2} span={11}>

              <EmptyButton
                dotted
                full
                {...{
                  color: areAllFulfilled ? BLUE : BLACK,
                  hoverBg: areAllFulfilled ? BLUE : BLACK,
                  hoverColor: 'white'
                }}
                onClick={() =>
                  showModal(false)}
                icon={(
                  areAllFulfilled ?
                    <CheckSquareOutlined /> :
                    <CloseSquareOutlined />
)}
              >
                % od zapokojonych
              </EmptyButton>
            </Col>
            <Col offset={1} span={2}>
              <Popconfirm
                title="Usunąć wszystkie zaliczenia wpłat"
                onConfirm={removeAllAssigns}
              >
                <ButtonFullWidth
                  type="dashed"
                  icon={(
                    <CloseOutlined />
)}
                />
              </Popconfirm>
            </Col>
          </RowExt>
        </DashedColRight>
        <Col
          xs={11}
          style={{ paddingBottom: 24 }}
        >
          <RowExt justify="center" align="middle">
            <Title level={3}>
              Wpłaty
            </Title>
          </RowExt>
          <RowExt marginTop={12} align="middle">
            <Col offset={1} span={8}>
              <ButtonFullWidth
                type="primary"
                onClick={() =>
                  dispatch(isAddPaymentsVisibleUpdate(true))}
                icon={(
                  <PlusOutlined />
)}
              >
                Dodaj
              </ButtonFullWidth>
            </Col>
            <Col offset={1} span={8}>
              <EmptyButton
                full
                type="primary"
                icon={<DownloadOutlined />}
                onClick={() =>
                  dispatch(isImportPaymentVisibleUpdated(true))}
              >
                Importuj
              </EmptyButton>
            </Col>

            <Col offset={1} span={5}>
              <ButtonFullWidth
                onClick={() =>
                  setAutoModalVisible(true)}
                type="primary"
                icon={(
                  <RobotFilled />
)}
              >
                <strong style={{ marginLeft: 8 }}>AI</strong>
              </ButtonFullWidth>
            </Col>
          </RowExt>
        </Col>
      </RowExt>
      <ClaimAllFulfilledIModal
        {...{
          closeModal,
          isModalVisible,
          onOn,
          onOff,
        }}
      />
      <AssignToOldestModal
        {...{
          isAssignModalVisible,
          closeModal,
          assignToInterestFirst,
          assignToMain
        }}
      />
      <AutoAssignModal
        {...{
          isAutoModalVisible,
          closeModal: () =>
            setAutoModalVisible(false),
          onShowHistory,

        }}
      />
    </>
  );

};

export default AssignPaymentsActions;
