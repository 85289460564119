import { HomeOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import logo from '../assets/logo.svg';
import { ImageLogo } from '../components/UI/Nav/NavPrivate';
import { RowExt } from '../styles';

const NotFound = () => {
  const history = useHistory();
  return (
    <Container>
      <RowExt justify="center" paddingBottom="md">
        <Link to="/">
          <ImageLogo
            src={logo}
          />
        </Link>
      </RowExt>
      <RowExt justify="center" paddingBottom="bg">
        strona nie istnieje
      </RowExt>
      <RowExt justify="center">
        <Button
          icon={<HomeOutlined />}
          onClick={() =>
            history.push('/')}
        >
          Wróć
        </Button>
      </RowExt>
    </Container>
  );
};

export default NotFound;


const Container = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    z-index: 2;
`;