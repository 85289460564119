export const privacyIntro = {
  p1: 'Niniejsza polityka prywatności reguluje prawo prywatności Użytkowników, w tym ochronę danych osobowych i informacje oraz wyjaśnienia związane z plikami cookies. Polityka prywatności stanowi również informację o przetwarzaniu danych osobowych zgodnie z Ogólnym Rozporządzeniem dotyczącym Ochrony Danych Osobowych (rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z 27.04.2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE – RODO. ',
  p2: 'Pomimo tego, że pozew.app stworzony jest z myślą o prawnikach, staramy się wyjaśnić wszystkie aspekty ochrony prywatności najbardziej przystępnym językiem. Jak każdy dokument prawny, również polityka prywatności może być ulepszona. Jeśli masz jakieś <strong> sugestie </strong> z chęcią je przyjmiemy.',
  p3: 'Sformułowana poniżej Polityka cookies stanowi integralną część Polityki prywatności <span class="dark-blue">pozew.app</span>.'
};

const pozewapp = '<span class="dark-blue">pozew.app</span>';

export const par1 = {
  title: 'Kim jesteśmy?',
  desc: '§1 Administrator danych osobowych',
  pars:[
    { text: 'Administratorem Twoich danych osobowych jest EoD Code Sp. z o.o. adres: Chodkiewicza 6/15, 31-523, Kraków, Polska, KRS: 0000800125. Założycielem EoD Code Sp. z o.o. i prezesem zarządu spółki jest autor <span class="dark-blue">pozew.app</span> Tadeusz Mięsowicz. Spółka dystrybuuje aplikację i zapewnia obsługę serwerową. ' },
    { text: 'Administrator Danych Osobowych jest osobą decydującą o celach i sposobach wykorzystania Twoich danych osobowych. ' }],
};

export const par2 = {
  title: 'W jaki sposób uzyskujemy dostęp do Twoich danych osobowych?',
  desc: '§2 Formy uzyskania dostępu do danych osobowych',
  pars1: [{ text: 'Otrzymujemy Twoje dane osobowe z następujących źródeł:' }],
  usts: [
    { text: `1.podczas tworzenia i używania konta aplikacji ${pozewapp};` },
    { text: '2.w czasie korzystania z pozew.app są zbierane pliki cookies mogące zawierać Twoje dane osobowe.' },
  ],
  pars2: [
    { text: '<span class="dark-blue">Pozew.app</span> nie przechowuje danych wpisanych do formularzy z wyłączeniem tych, które dobrowolnie zapisałaś/zapisałeś na swoim koncie. ' }
  ]
};

export const par3 = {
  title: 'Jakie rodzaje danych osobowych przetwarzamy?',
  desc: '§3 Rodzaje przetwarzanych danych osobowych',
  pars1: [
    { text: 'Możemy przetwarzać następujące rodzaje danych osobowych:' }],
  usts1: [
    { text:'1.adres email - w przypadku założenia konta przy użyciu adresu email;' },
    { text: '2.imię i nazwisko, adres email, nazwa użytkownika, zdjęcie profilowe - te dane otrzymujemy od dostawcy uwierzytelniania w przypadku założenia konta przez Facebook.Nie przetwarzamy i nie mamy dostępu do innych Twoich danych osobowych gromadzonych przy użyciu konta Facebook.' },
    { text:'3.imię i nazwisko, adres email, nazwa użytkownika, zdjęcie profilowe - te dane otrzymujemy od dostawcy uwierzytelniania w przypadku założenia konta przez Google. Nie przetwarzamy i nie mamy dostępu do innych Twoich danych osobowych gromadzonych przy użyciu konta Google.' },
    { text:'4.imię i nazwisko, adres email, dane adresowe konieczne do wystawienia  faktury VAT - te dane otrzymujemy od Ciebie w przypadku wykupienia subskrypcji. Nie przechowujemy Twojego numeru karty płatniczej, te dane są przetwarzane bezpośrednio przez system rozliczeń Stripe.' }
  ],
  pars2: [
    { text: 'Strona pozew.app wykorzystuje pliki cookies, które mogą zawierać Twoje dane osobowe. Informacje uzyskane w oparciu o cookies analizujemy za pomocą Google Analitics. Google Analitics jest serwisem służącym analizie zachowań w Internecie. Możemy używać plików cookies i Google Analitics do przetwarzania następujących Twoich danych osobowych:' }
  ],
  usts2:[
    { text: `1.Twojego zachowania podczas używania ${pozewapp} takiego jak czas korzystania, częstotliwość używania danych funkcji aplikacji (nie przetwarzamy danych wpisanych w formularze z wyłączeniem tych, które dobrowolnie zapisałeś na serwerze);` },
    { text: '2. Google Analitics może wymagać Twojego adresu IP.' },
  ],
  pars3: [
    { text: 'Politykę prywatności kont Google, Facebook, Stripe oraz więcej informacji o Google Analitics znajdziesz tutaj:' }
  ],
};

export const par4 = {
  title: 'W jakim celu przetwarzamy Twoje dane osobowe?',
  desc: '§4 Cele przetwarzania danych osobowych',
  pars:[{ text: 'Przetwarzamy Twoje dane w następujących celach:' }],
  usts:[
    { text: '1.Uwierzytelnienie i zapisywanie danych użytkownika' },
    { text: `Dobrowolnie przekazane dane w formie adresu email lub danych użytkownika Google lub Facebook służą do uzyskania dostępu do danych zapisanych przez Ciebie na serwerze podczas korzystania z ${pozewapp}.` },
    { text: '2.Komunikacja z użytkownikiem' },
    { text: 'Adres email służy także do komunikacji z użytkownikami, w tym odzyskiwania zapomnianego hasła, przesyłania wiadomości masowych związanych z aplikacją, takich jak informacja o wprowadzeniu nowej opcji, czy modułu <strong>(nie spamujemy)</strong>.' },
    { text: '3.Zapamiętywanie opcji użytkownika, ułatwień przy wypełnianiu formularzy' },
    { text: `4.Wspieranie bezpieczeństwa funkcjonowania ${pozewapp}` },
    { text: '5.Badanie wydajności i efektywności aplikacji' },
    { text: '6.Obsługa płatności związanych z subskrypcjami' },
  ]
};

export const par5 = {
  title: 'Jaka jest podstawa prawna przetwarzania Twoich danych osobowych?',
  desc: '§5 Podstawa prawna przetwarzania danych osobowych',
  pars:[
    { text: 'W momencie zaczęcia korzystania ze strony pozew.app, na mocy regulaminu, zawierasz z nami umowę o świadczenie usług. Udostępnienie przez nas konta pozew.app dzieje się również w oparciu o taką umowę. Dlatego podstawą korzystania przez nas z danych osobowy w postaci adresu email, czy w postaci danych przekazanych przez zewnętrznych dostawców uwierzytelniania (Google, Facebook) jest umowa.  Przetwarzamy wtedy Twoje dane w zakresie niezbędnym do wykonania umowy to znaczy, żeby zapewnić Ci możliwość dostępu do swoich danych zapisanych na serwerze i nasz kontakt z Tobą.' },
    { text: `Ze względu na nasz uzasadniony interes zbieramy Twoje dane osobowe dotyczące sposobu korzystania z aplikacji a odnoszące się do bezpieczeństwa, wydajności i efektywności aplikacji. Jest to niezbędne dla zapobiegania nadużyciom w używaniu ${pozewapp}, a także by eliminować ewentualne błędy, wady aplikacji. Na podstawie umowy o świadczenie usług masz również obowiązek wspierać bezpieczeństwo, wydajność i efektywność aplikacji.` },
  ]
};

export const par6 = {
  title: 'Jak i komu udostępniamy Twoje dane?',
  desc: '§6 Przekazanie danych osobowych osobom trzecim',
  pars:[
    { text: `W przypadku używania konta ${pozewapp} Twoje dane są przetwarzane w ramach usług Google Firebase (zwane dalej Firebase).` },
    { text: `Firebase jest profesjonalnym zestawem usług IT, w tym dostarczających chmurowe rozwiązania serwerowe (Firebase Firestore). Twoje dane za pomocą których się logujesz w ${pozewapp} i dane które zapisujesz są przetwarzane na serwerach będących własnością Google.` },
    { text: 'Serwer Firestore, na którym umieszczona jest baza danych znajduje się w Europejskim Obszarze Gospodarczym, natomiast siedziba dostawcy Firebase znajduje się w Irlandii. ' },
    { text: 'Część Twoich danych osobowych może być przetwarzana przy użyciu Google Analitics, Firebase Google Analitics, którego funkcjonowanie opiera się o serwery stanowiące własność Google. Siedziba tego dostawcy znajduje się w EEA, w Irlandii.' },
    { text: `Przekazujemy Twój podany podczas zakładania konta ${pozewapp}, email do serwisu Mailchimp, który umożliwia masowe wysyłanie maili do użytkowników ${pozewapp}. ` },
    { text: 'Mamy dostęp do części danych podanych przez Ciebie do Stripe (nie mamy dostępu do pełnego numeru karty płatniczej, tylko do 4 ostatnich liczb) podczas opłacania subskrypcji. Dane te używamy do rozliczania wpłaconych kwot i wykonywania obowiązków podatkowych.' },
    { text: 'W zakresie dozwolonym przez obowiązujące przepisy prawa możemy udostępniać Twoje dane zaufanym podmiotom trzecim w celach związanych ze świadczeniem naszych usług np. dostawcom usług IT, agencjom marketingowym, przedsiębiorcom telekomunikacyjnym, z którymi administrator zawarł umowy o współpracy. W każdym wypadku umowa jaką zawieramy z podwykonawcą zobowiązuje podwykonawcę do poufności oraz wykonywania tylko i wyłącznie niezbędnych operacji na danych osobowych użytkownika.' },
    { text: 'Więcej na temat Google Firebase, Google Analitics, Mailchimp - zakresu świadczonych usług, ich siedzib i polityk prywatności dowiesz się tutaj:' },
  ]
};

export const par7 = {
  title: 'Czy przekazujemy Twoje dane osobowe poza Europejski Obszar Gospodarczy (EEA)?',
  desc: '§7 Transfer danych osobowych poza Europejski Obszar Gospodarczy (EEA)',
  pars:[
    { text: `W momencie utworzenia konta ${pozewapp} przekazujemy Twój adres do Maichimp mający swoją siedzibę w Stanach Zjednoczonych Ameryki Północnej, aby móc prowadzić z Tobą korespondencję mailową. W przypadku utworzenia konta przez Google lub Facebook przekazujemy także Twoje imię i nazwisko.` },
    { text: 'Część serwerów obsługujących Google Analitics lub Google Firebase może mieć swoją siedzibę poza granicami EEA. ' },
    { text: 'Serwis opłat internetowych Stripe ma siedzibę poza Europejskim Obszarem Gospodarczym (w USA).' },
    { text: 'Czynimy co w naszej mocy, aby podmioty którym przekazujemy dane poza EEA były wiodącymi na rynku profesjonalistami dającymi gwarancje wysokiego stopnia ochrony danych osobowych. Gwarancje te wynikają w szczególności ze zobowiązania do stosowania standardowych klauzul umownych przyjętych przez Komisję (UE). Masz prawo żądać od nas przekazania kopii standardowych klauzul umownych kierując zapytanie do nas.' },
    { text: 'Więcej na temat realizacji przez Mailchimp wymogów RODO znajdziesz tutaj:' },
  ]
};

export const par8 = {
  title: 'Jak dbamy o prywatność dzieci?',
  desc: '§8 Tworzenie konta przez osoby poniżej 13 roku życia',
  pars:[
    { text: `Nie zezwalamy na tworzenie konta ${pozewapp} osobom poniżej 13 roku życia. W momencie wykrycia, że konto zostało utworzone przez osobę młodszą niż 13 lat usuniemy je.` }]
};

export const par9 = {
  title: 'Jak długo przechowujemy Twoje dane?',
  desc: '§9 Długość przechowywania danych osobowych',
  pars:[
    { text: 'Przechowujemy dane osobowe tak długo, jak jest to konieczne do realizacji celów, dla których zbieramy lub otrzymujemy dane osobowe, chyba że obowiązujące prawo wymaga inaczej. Zazwyczaj przechowujemy większość danych osobowych przez okres korzystania przez Ciebie ze strony internetowej aż do usunięcia konta, chyba że obowiązuje dłuższy ustawowy okres przechowywania. Będziemy przechowywać Twoje dane we wcześniej wykonanych kopiach zapasowych do 2 lat, aby zapewnić integrację danych. W przypadku, gdy podstawą przetwarzania danych jest uzasadniony nasz interes, przechowujemy dane do czasu zgłoszenia przez Ciebie skutecznego sprzeciwu.' }],
};

export const par10 = {
  title: 'Jakie przysługują Ci prawa?',
  desc: '§10 Prawa przysługujące użytkownikowi',
  pars:[
    { text: 'Przysługują Ci następujące prawa w odniesieniu do Twoich danych osobowych:' }],
  usts:[
    { text: '1.Prawo żądania sprostowania danych.' },
    { text: '2.Prawo do usunięcia danych (prawo do bycia zapomnianym).' },
    { text: '3.Prawo dostępu do Twoich danych, w tym uzyskania kopii danych.' },
    { text: '4.Prawo do ograniczenia przetwarzania danych.' },
    { text: '5.Prawo sprzeciwu – w przypadku uzasadnionych podstaw masz prawo sprzeciwić się przetwarzaniu Twoich danych.' },
    { text: '6.Prawo do cofnięcia zgody – masz prawo cofnąć zgodę na przetwarzanie danych w zakresie, w jakim przetwarzamy dane na podstawie Twojej zgody.' },
    { text: '7.Prawo wniesienia skargi do organu nadzorczego ochrony danych osobowych - w Polsce jest nim Prezes Urzędu Ochrony Danych Osobowych.' },

  ]
};

export const par11 = {
  title: 'Jak się z nami skontaktować?',
  desc: '§11 Kontakt z administratorem danych osobowych',
  pars:[
    { text: 'Jeśli masz jakiekolwiek uwagi lub pytania dotyczące informacji zawartych w niniejszym Oświadczeniu o ochronie prywatności, jeśli chcesz, abyśmy zaktualizowali Twoje dane osobowe lub chcesz skorzystać z przysługujących Ci praw, skontaktuj się z nami pod adresem e-mail contact@pozew.app, względnie wyślij pismo na adres: EoD Code Sp. z o.o. adres: Chodkiewicza 6/15, 31-523, Kraków.' }]
};