import { shallowEqual } from 'react-redux';

import { useTypedSelector } from '../../../store';
import { JUST_WITH_CAP } from '../../../store/justOpts';
import { formatPLN } from '../../helpers/formatNumber';


const useSumInterests = () => {
  const {
    partialResults,
    overallInterestsSum,
  } = useTypedSelector(state =>
    state.sumUp, shallowEqual);
  const { justIntCap } = useTypedSelector(state =>
    state.justOpts, shallowEqual);


  if (partialResults.length > 1 && justIntCap === JUST_WITH_CAP){
    const intro = `Łączna kwota dochodzonych skapitalizowanych odsetek (${formatPLN(overallInterestsSum) ?? '0 zł'}) wynika z zsumowania kwot: `;
    const sum = partialResults.reduce((prev, curr, index) => {
      if (index !== 0){
        return `${prev} + ${formatPLN(curr.interestsSumUncovered + curr.interestsSumFulfilledUncovered)}`;
      }
      return `${prev}${formatPLN(curr.interestsSumUncovered + curr.interestsSumFulfilledUncovered)}`;
    }, intro);

    return `${sum}.`;
  }
  return '';
};

export default useSumInterests;
