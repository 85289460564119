import React from 'react';
import nextId from 'react-id-generator';
import { PartialResult } from '../../../store/sumUp';
import { interestPartialDesc, periodText, interestFulfilledSumUp } from '../../../utils/hooks/justification/justInterestPartials';
import { paymentInterestCalcBasis } from '../../../utils/hooks/justification/justPayments';
import JustParagraph from './JustParagraph';

const JustInterestsFulfilled = ({
  result,
  index,
  shouldShowFulfilled,
}:{
  result: PartialResult,
  index: number,
  shouldShowFulfilled: boolean,
}) => {
  if (shouldShowFulfilled){
    return (
    // B. fulfilled desc
      <>
        <JustParagraph
          text={`${interestPartialDesc(result, true)}`}
        />
        {result.interestResultsFulfilled.map(({ interestSum, periodResults }, idx) => {
          return (
            <>
              {/* C. fulfilled periods */}
              {periodResults.map(periodResult =>
                (
                  <JustParagraph
                    key={nextId()}
                    text={periodText(
                      periodResult,
                      'odsetki',
                      periodResults.length - 1 === 0
                    )}
                  />
                ))}
              {/* D. interest calculation sum basis */}
              <JustParagraph
                text={paymentInterestCalcBasis(result, interestSum, idx)}
              />
            </>
          );
        })}
        {/* E. fulfilled sum up */}
        <JustParagraph
          text={interestFulfilledSumUp(result, index)}
        />
      </>
    );
  }
  return null;

};

export default JustInterestsFulfilled;

