import { Col, Row } from 'antd';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { BLUE, GRID_MD } from '../../../styles';

const SubOptsHeader = (
  {
    color,
    children,
    inCard
  }: {
    children?: ReactNode,
    color?: string,
    inCard?: boolean
  }
) => {
  return (
    <ColStyled
      xl={{
        span: inCard ? 0 : 6, offset: inCard ? 0 : 1
      }}
      lg={{
        span: inCard ? 0 : 8, offset:0
      }}
      md={{
        span: inCard ? 20 : 0, offset: inCard ? 2 : 0,
      }}
      xs={{
        span:inCard ? 20 : 0, offset: inCard ? 2 : 0,
      }}
    >
      <Row align="middle">
        <ValueInfo
          color={color}
        >
          <Col span={20} offset={2}>
            {children}
          </Col>
        </ValueInfo>
      </Row>
    </ColStyled>
  );
};

export default SubOptsHeader;


const ValueInfo = styled.div<{ color?: string }>`
   width: 100%;
   padding: 24px 12px;
   background: ${({ color }) =>
    color ?? BLUE};
   color: white;
   font-size: 800;
   min-height: 116px;
   @media only screen and (max-width: ${GRID_MD}) {
    padding: 12px 12px;
    height:100%;
    min-height: 0;
	}
   font-style: italic;
   display: flex;
   align-content: center;
`;

const ColStyled = styled(Col)`
  padding: 0 2px 0 
`;