import nextId from 'react-id-generator';
import React from 'react';
import { PartialResult } from '../../../store/sumUp';
import JustParagraph from './JustParagraph';
import { justAssignedPayments } from '../../../utils/hooks/justification/justPayments';


const JustAssignedPayments = ({ result }:{ result: PartialResult }) =>
  (
    <>
      {justAssignedPayments(result).map(text =>
        (
          <JustParagraph
            key={nextId()}
            text={text}
          />
        )
      )}
    </>
  );

export default JustAssignedPayments;
