import { ArrowLeftOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button, Col } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { GRID_SM_PX, RowExt } from '../../../styles';

const BasisActions = (
  {
    onBack,
    onClear
  }: {
    onBack: () => void,
    onClear: () => void
  }
) => {
  return (
    <RowExt
      marginTop="bg"
      marginBottom="md"
      justify="space-between"
    >
      <Col
        sm={{
          span:7, order: 1
        }}
        xs={{
          span: 24, order: 3
        }}
      >
        <ButtonResp
          type='primary'
          onClick={onBack}
          icon={<ArrowLeftOutlined />}
        >
          OK
        </ButtonResp>
      </Col>
      <Col
        sm={{
          span:7, order:4
        }}
        xs={{
          span: 24, order: 2
        }}
      >
        <ButtonResp
          icon={<ReloadOutlined />}
          onClick={onClear}
        >
          Wyczyść wszystko
        </ButtonResp>
      </Col>
    </RowExt>
  );
};

export default BasisActions;

const ButtonResp = styled(Button)`
    width: 100%;
    @media only screen and (max-width: ${GRID_SM_PX}) {
    margin-top: 24px;
	}
`;