import React from 'react';
import { shallowEqual } from 'react-redux';
import { toUnit } from '../../../utils/helpers/money';

import { useTypedSelector } from '../../../store';
import { RowMd } from '../../../styles';
import ResultCard from './ResultCard';

const SumUpHeadResults = () => {
  const {
    overallInterestsSum,
    compensationSum,
    finalSum,
    lawsuitOpts,
  } = useTypedSelector(state =>
    state.sumUp, shallowEqual);
  const {
    compensationOn,
    principalAmountOpt,
  } = lawsuitOpts;

  return (
    <>
      <RowMd gutter={16}>
        {principalAmountOpt !== 'PRINCIPAL_INTEREST' ? (
          <ResultCard
            title={texts.main}
            value={toUnit(finalSum)}
            compensationOn={compensationOn}
          />
        ) : null}
        <ResultCard
          title={texts.interestOnly}
          value={toUnit(overallInterestsSum)}
          compensationOn={compensationOn}
        />
        {compensationOn ? (
          <ResultCard
            title={texts.compensation}
            value={toUnit(compensationSum)}
            compensationOn={compensationOn}
          />
        ) : null}
      </RowMd>
    </>
  );
};

export default SumUpHeadResults;


const texts = {
  main: 'Suma kwot składowych',
  interestOnly: 'Skapitalizowane odsetki',
  compensation: 'Suma rekompensat',
};