import { Card, Col, Row, Statistic } from 'antd';
import React from 'react';

import { BLUE, RowMd } from '../../../../styles';
import { RED } from '../../../../styles/colors';
import { hasRowFatalError, RowWithWarnings } from '../../../../utils/hooks/useImpExcelValidate';

const numberOfFatalErrors = (rows:RowWithWarnings[]) =>
  rows.filter(hasRowFatalError).length;


const numberOfRowsWithWarnings = (rows:RowWithWarnings[]) =>
  rows.filter(row =>
    Object
      .values(row).some(value =>
        value.warnings.length > 0)).length;

const ImpExcelStats = (
  { rows }:{
    rows: RowWithWarnings[]
  }) => {

  const numberOfFatal = numberOfFatalErrors(rows);

  const numberOfOnlyModded = numberOfRowsWithWarnings(rows) - numberOfFatal;

  return (

    <Card
      title="Zaimportowane wiersze - podsumowanie"
      bordered={false}
    >
      <RowMd justify='space-around'>
        <Col span={8}>
          <Row justify="center">
            <Statistic
              title="Bez zmian"
              value={rows.length - (numberOfOnlyModded)}
            />
          </Row>
        </Col>
        <Col span={8}>
          <Row justify="center">
            <Statistic
              title="Modyfikacja"
              value={numberOfOnlyModded}
              valueStyle={{ color: BLUE }}
            />
          </Row>
        </Col>
        <Col span={8}>
          <Row justify="center">
            <Statistic
              title="Odmowa"
              value={numberOfFatal}
              valueStyle={{ color: RED }}
            />
          </Row>
        </Col>
      </RowMd>
    </Card>
  );
};

export default ImpExcelStats;