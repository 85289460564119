export const dueDatePhrases = [
  'termin zapłaty',
  'płatne dnia',
  'data zapłaty',
  'data płataności',
  'termin płatności',
  'płatne do dnia',
];

export const accDocPhrases = [
  'faktura vat numer',
  'faktura vat nr',
  'numer faktury vat',
  'nr faktury vat',
  'numer faktury',
  'nr faktury',
  'numer',
  'nr',
  'fv nr',
  'faktura vat',
  'faktura',
];

export const paymentDatesPhrases = ['data księgowania', 'data i czas operacji' ];

export const paymentSumNames = ['kwota w walucie rachunku', 'kwota'];

export const transferDescriptionNames = [
  'tytuł płatności',
  'opis operacji',
  'tytuł operacji',
  'tytuł',
];

export const partialSumNames = [
  'łączna kwota do zapłaty',
  'cena ogółem (z vat)',
  'razem faktura',
  'cena ogółem',
  'suma brutto',
  'do zapłaty',
  'wartość brutto',
  'suma całkowita',
  'razem',
  'ogółem',
  'suma',
];

export const createDatePhrases = [
  'data wystawienia faktury',
  'data wystawienia',
  'data faktury',
  'dnia',
  'z dnia',
  'data dokumentu',
  'wystawiono dnia',
];

export const paymentCreateDatePhrases = [
  'data transakcji',
  'data operacji',
  'data realizacji',
  'data i czas operacji',
];



