import { Col } from 'antd';
import React from 'react';
import { AboutContainer, AbtSpaceRow, DashedColBoth, DashedColLeft } from '../../../styles';

const AbtVideoBot = () => {
  return (
    <AboutContainer>
      <AbtSpaceRow>
        <Col
          lg={{
            offset: 1,
            span: 8,
          }}
          md={{
            offset: 1,
            span: 7
          }}
          xs={{ span: 24 }}
        />
        <DashedColBoth
          lg={{ span: 11 }}
          md={16}
          xs={0}
        />
        <Col
          xl={{ span: 2 }}
          lg={{ span: 1 }}
          xs={0}
        />
        <DashedColLeft
          xl={{ span: 2 }}
          lg={{ span: 3 }}
          xs={0}
        />
      </AbtSpaceRow>
    </AboutContainer>
  );
};

export default AbtVideoBot;
