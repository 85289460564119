/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button, Input, Space } from 'antd';
import React, { useState } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { setLawsuitSave, updateLawsuitSave } from '../../services/lawsuitSave';
import { useTypedSelector } from '../../store';
import { saveModalClosed } from '../../store/saveLawsuitModal';
import { RowExt } from '../../styles';
import SaveButton from '../UI/Button/SaveButton';
import NoUserSave from './NoUserSave';
import OverSave from './OverSave';
import SaveTable, { OverSaveData } from './SaveTable';


const Save = () => {
  const dispatch = useDispatch();

  const {
    recordName,
    saveId
  } = useTypedSelector(state =>
    state.lawsuitUI, shallowEqual);

  const { isSaveFromResult } = useTypedSelector(state =>
    state.saveLawsuitModal, shallowEqual);

  const [overSaveData, setOverSaveData] = useState<OverSaveData>({
    recordName: '',
    id: ''
  });

  const [isOverSave, setIsOverSave] = useState(false);
  const onEditOverSave = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOverSaveData({
      recordName: event.target.value,
      id: overSaveData.id,
    });
  };

  const [recordNameLocal, setRecordNameLocal] = useState( recordName || 'Nowe wyliczenia');
  const [loading, setLoading] = useState(false);


  const userExists = useTypedSelector(state =>
    state.user.isNonAnonymousUserLoggedIn);

  const onOverSaveClose = () => {
    setIsOverSave(false);
  };

  const onFinish = () => {
    setLoading(false);
    dispatch(saveModalClosed());
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSavePress();
    }
  };

  const onSavePress = () => {
    if (isOverSave){
      dispatch(updateLawsuitSave({
        saveId: overSaveData.id,
        recordName: overSaveData.recordName,
        onLoadingStart: () =>
          setLoading(true),
        onFinish,
        isSaveFromResult,
      }));
    } else if (!isOverSave && (recordName === recordNameLocal) && (recordNameLocal !== 'Nowe wyliczenia')){
      dispatch(updateLawsuitSave({
        saveId,
        recordName: recordNameLocal,
        onLoadingStart: () =>
          setLoading(true),
        onFinish,
        isSaveFromResult,
      }));
    } else {
      dispatch(setLawsuitSave({
        recordName: recordNameLocal,
        onLoadingStart: () =>
          setLoading(true),
        onFinish,
        isSaveFromResult,
      }));
    }
  };

  return (
    <>
      <NoUserSave />
      <OverSave
        {...{
          overSaveText: overSaveData.recordName,
          onEditOverSave,
          isOverSave,
          onClose: onOverSaveClose,
          onKeyDown: handleKeyDown,
        }}
      />
      <SaveTable
        {...{
          isOverSave,
          setIsOverSave,
          setOverSaveData,
        }}
      />
      {userExists && !isOverSave ? (
        <RowExt>
          <Input
            addonBefore="Zapisz jako"
            placeholder="Nazwa zapisu"
            value={recordNameLocal}
            onChange={(event) =>
              setRecordNameLocal(event.target.value)}
            autoFocus
            onKeyDown={handleKeyDown}
          />
        </RowExt>
      ) : null}
      {userExists ? (
        <>
          <RowExt marginTop="md" justify="end">
            <Space>
              <Button
                onClick={() =>
                  dispatch(saveModalClosed())}
              >
                Anuluj
              </Button>
              <SaveButton
                loading={loading}
                onClick={onSavePress}

              />
            </Space>
          </RowExt>
        </>
      ) : null}
    </>
  );
};

export default Save;
