/* eslint-disable @typescript-eslint/naming-convention */
import { FirebaseError } from '@firebase/util';
import { notification } from 'antd';
import firebase from 'firebase/compat/app';
import { ENV_TYPE } from '../env.json';
import { AppDispatch } from '../store';
import { appLoadingStarted, appLoadingStopped } from '../store/appLoading';
import * as links from '../utils/helpers/links';
import _firebase from '../_firebase';
import { auth } from './firebase/auth';
import { notifyFirebaseError } from './firebase/notifyFirebaseError';

export const goToStripe = async (dispatch:AppDispatch) => {
  try {
    dispatch(appLoadingStarted('łączenie z systemem płatności'));
    let returnUrl;
    switch (ENV_TYPE) {
      case 'PRODUCTION':
        returnUrl = 'https://pozew.app/user';
        break;
      case 'STAGING':
        returnUrl =  'https://stagingpozew.web.app//user';
        break;
      default:
        returnUrl = 'http://localhost:3000/user';
        break;
    }

    const functionRef = firebase.app()
      .functions('europe-central2')
      .httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink');
    const { data } = await functionRef({ returnUrl });
    window.location.assign(data.url);
  } catch (e){
    notification.error({
      message: 'Ups, coś poszło nie tak',
      description: 'Spróbuj jest raz.',
      duration: 8000,
    });
    dispatch(appLoadingStopped());
  }
};


type SubscriptionResponseData = {
  url: string,
  error: FirebaseError,
};

export const handleSubscribe =  async (dispatch:AppDispatch, subType: string, wasSubscriber: boolean) => {
  const { currentUser } = auth;
  if (currentUser && !currentUser.isAnonymous){
    dispatch(appLoadingStarted('łączenie z systemem płatności'));
    let success_url;
    let cancel_url;
    switch (ENV_TYPE) {
      case 'PRODUCTION':
        success_url =  links.SUCCESS_URL_PROD;
        cancel_url = links.CANCEL_URL_PROD;
        break;
      case 'STAGING':
        success_url =  links.SUCCESS_URL_STAGING;
        cancel_url = links.CANCEL_URL_STAGING;
        break;
      default:
        success_url =  links.SUCCESS_URL_DEV;
        cancel_url = links.CANCEL_URL_DEV;
        break;
    }

    const docRef = await _firebase.firestore()
      .collection('users')
      .doc(currentUser.uid)
      .collection('checkout_sessions')
      .add({
        price: subType,
        success_url,
        cancel_url,
        tax_id_collection:true,
        trial_from_plan: !wasSubscriber,
      });
      // Wait for the CheckoutSession to get attached by the extension
    docRef.onSnapshot((snap) => {
      const { error, url } = snap.data() as SubscriptionResponseData;
      if (error) {
        dispatch(appLoadingStopped());
        // Show an error to your customer and
        // inspect your Cloud Function logs in the Firebase console.
        notifyFirebaseError(error);
      }
      if (url) {
        // We have a Stripe Checkout URL, let's redirect.
        window.location.assign(url);
      }
    });
  }

};