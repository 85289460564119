/* eslint-disable @typescript-eslint/no-explicit-any */
import { Divider, Typography } from 'antd';
import React, { useRef } from 'react';
import nextId from 'react-id-generator';
import { shallowEqual } from 'react-redux';
import { useReactToPrint } from 'react-to-print';

import { useTypedSelector } from '../../../store';
import AnimOpacity from '../../UI/Anim/AnimOpacity';
import DetailsActions from './DetailsActions';
import DetailsClaimSum from './DetailsClaimSum';
import DetailsFulfilledInterestPeriods from './DetailsFulfilledInterestPeriods';
import DetailsInterestsIntro from './DetailsInterestsIntro';
import DetailsInterestsPeriods from './DetailsInterestsPeriods';
import DetailsPeriodInterestSum from './DetailsInterestsPeriodsSum';
import DetailsPartialComp from './DetailsPartialComp';
import DetailsPartialHead from './DetailsPartialHead';
import DetailsPayments from './DetailsPayments';

const { Title, Text } = Typography;

const Details = ({  detailsVisible, setDetailsVisible  }:{
  setDetailsVisible:(detailsVisible:boolean)=>void,
  detailsVisible: boolean,
}) => {

  const { partialResults } = useTypedSelector(state =>
    state.sumUp, shallowEqual);

  const componentRef = useRef<any>();
  const handlePrint = useReactToPrint({
    content: () =>
      componentRef.current
  });

  return (
    <div id="details">
      <Divider />
      <Title level={3}>{texts.title}</Title>
      <Text type="secondary">{texts.desc}</Text>
      <DetailsActions
        {...{
          setDetailsVisible,
          detailsVisible,
          handlePrint,
        }}
      />
      <div ref={componentRef}>
        {detailsVisible ? (
          <AnimOpacity>
            {partialResults.map((partialResult) => {
              return (
                <div
                  key={nextId()}
                  style={{ marginTop: 24 }}
                >
                  <DetailsPartialHead
                    {...{
                      partialResult,
                      isResult: true
                    }}
                  />
                  <DetailsPayments
                    {...{
                      ...partialResult,
                      claimKey: partialResult.key
                    }}
                  />
                  <DetailsClaimSum
                    {...{ ...partialResult }}
                  />
                  <DetailsInterestsIntro
                    {...{ ...partialResult }}
                  />
                  <DetailsFulfilledInterestPeriods
                    claim={partialResult}
                  />
                  <DetailsInterestsPeriods
                    {...{ partialResult }}
                  />
                  <DetailsPeriodInterestSum
                    {...{ partialResult }}
                  />
                  <DetailsPartialComp
                    {...{ partialResult }}
                  />
                  <Divider />
                </div>
              );
            })}
          </AnimOpacity>
        )
          : null}
      </div>
    </div>
  );
};


export default Details;

const texts = {
  title: 'Szczegóły wyliczeń',
  desc: 'W jaki sposób powstał wynik',
};