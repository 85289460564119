import { InputNumber, Typography } from 'antd';
import _ from 'lodash';
import React, { memo } from 'react';
import { shallowEqual } from 'react-redux';
import styled from 'styled-components';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import { agreementInterestRateUpdated, INTEREST_AGREE } from '../../../../store/lawsuitOpts';

const StepOneInput = () => {

  const dispatch = useAppDispatch();

  const { typeOfInterest, agreementInterestRate } = useTypedSelector(state =>
    state.lawsuitOpts, shallowEqual);

  const onInterestChange = (value: string | number | null | undefined) => {
    if (_.isNumber(value)){
      dispatch(agreementInterestRateUpdated(value));
    } else {
      dispatch(agreementInterestRateUpdated(0));
    }
  };

  return (
    <>
      {typeOfInterest === INTEREST_AGREE ? (
        <>
          <RadioInput
            min={0}
            max={2000}
            value={agreementInterestRate}
            onChange={(value) =>
              onInterestChange(value)}
            decimalSeparator=","
            placeholder={texts.placeholder}
            step="0.01"
          />
          <Typography.Text>
            % w skali roku
          </Typography.Text>
        </>
      ) : null}
    </>
  );
};



export default memo(StepOneInput);

const texts = { placeholder: 'Wysokość odsetek' };


export const RadioInput = styled(InputNumber)`
  margin-top: 12px;
  width: 120px;
  margin-left:20px;
  margin-right: 12px;
  @media only screen and (max-width: 992px) {
    padding: 6px 0;
    margin-left: 0;
    width:100%;
    justify-content: space-between;
    margin-bottom: 12px;
	}
`;

