/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';


export type SubscriptionTypes = 'premium' | 'standard' | 'basic' |  'premiumUser' | 'standardUser' | null;

interface UserState {
  isNonAnonymousUserLoggedIn: boolean;
  isSubscriber: boolean;
  subscriptionType: SubscriptionTypes;
  isIntrGuideClosed: boolean;
  isPaymentGuideClosed: boolean;
}

const initialState:UserState = {
  isNonAnonymousUserLoggedIn: false,
  isSubscriber: false,
  subscriptionType: null,
  isIntrGuideClosed: false,
  isPaymentGuideClosed: false,
};

export type StartLoadingText = undefined | string;


export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    userAnonymousStatusFetched: (state:UserState, action: PayloadAction<boolean>) => {
      state.isNonAnonymousUserLoggedIn = action.payload;
      state.isSubscriber = !_.isObject(action.payload) ? false : state.isSubscriber;
      state.subscriptionType = !_.isObject(action.payload) ? null : state.subscriptionType;
    },
    subscriberStatusFetched: (state:UserState, action: PayloadAction<{
      isSubscriber: boolean,
      subscriptionType: SubscriptionTypes,
    }>) => {
      state.isSubscriber = action.payload.isSubscriber;
      state.subscriptionType = action.payload.subscriptionType;
    },
    intrGuideClosed: (state:UserState) => {
      state.isIntrGuideClosed = true;
    },
    paymentGuideClosed: (state:UserState) => {
      state.isPaymentGuideClosed = true;
    },
    subscriberStatusReset: (state:UserState) => {
      state.isSubscriber = false;
      state.subscriptionType = null;
    }
  }
});

export const {
  userAnonymousStatusFetched,
  subscriberStatusFetched,
  subscriberStatusReset,
  intrGuideClosed,
  paymentGuideClosed,
} = userSlice.actions;

export default userSlice.reducer;
