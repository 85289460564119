import { ArrowLeftOutlined } from '@ant-design/icons';
import { Col, DatePicker, Statistic, Typography } from 'antd';
import _ from 'lodash';
import { Moment } from 'moment';
import React from 'react';
import { shallowEqual } from 'react-redux';
import styled from 'styled-components';

import { useAppDispatch, useTypedSelector } from '../../../store';
import { editMaturityDateUpdated, editMaturityIsVisible } from '../../../store/edit';
import { GRID_MD, GRID_SM, RowExt } from '../../../styles';
import { ButtonFullWidth, EmptyButton } from '../../../styles/ui';
import { formatDate } from '../../../utils/helpers/date';
import useWindowSize from '../../../utils/hooks/useWindowSize';
import EditMaturityCard from './EditMaturityCard';

const EditMaturity = () => {
  const dispatch = useAppDispatch();

  const {
    dueDate,
    startingDate,
    maturityDate
  } = useTypedSelector(state =>
    state.edit, shallowEqual);

  const { width } = useWindowSize();

  const onBackPress = () => {
    dispatch(editMaturityIsVisible(false));
  };

  const onDueDatePress = () => {
    if (dueDate){
      dispatch(editMaturityDateUpdated(dueDate));
    }
  };

  const onStartingPress = () => {
    if (startingDate){
      dispatch(editMaturityDateUpdated(startingDate));
    }
  };

  const onValueChange = (value: Moment | null) => {
    if (!_.isNull(value)){
      dispatch(editMaturityDateUpdated(value));
    }
  };



  return (
    <EditMaturityCard>
      <GreyRow justify="space-around">
        {dueDate && width >= GRID_MD ? (
          <Statistic
            title="Termin spełnienia świadczenia"
            value={formatDate(dueDate)}
          />
        ) : null}
        {startingDate && width >= GRID_MD ? (
          <Statistic
            title="Data początkowa(opóźnienia)"
            value={formatDate(startingDate)}
          />
        ) : null}
        {maturityDate ? (
          <Statistic
            title="Data wymagalności"
            value={formatDate(maturityDate)}
          />
        ) : null}
      </GreyRow>
      <RowExt
        marginTop={24}
        justify="center"
        align="middle"
        gutter={[{ sm:16 }, { xs:24 }]}
      >
        <Col sm={12} xs={24}>
          <EmptyButton
            full
            onClick={onDueDatePress}
          >
            Data jak termin spełnienia świadczenia
          </EmptyButton>
        </Col>
        <Col sm={12} xs={24}>
          <ButtonFullWidth
            type='primary'
            onClick={onStartingPress}
          >
            Data jak data początkową(opóźnienia)
          </ButtonFullWidth>
        </Col>
      </RowExt>
      <RowExt
        marginTop={24}
        justify="center"
        align="middle"
        marginBottom="bg"
        gutter={[{ sm:16 }, { xs:24 }]}
      >
        <Col
          sm={{
            span: 8, order: 1
          }}
          xs={{
            span: 24, order: 3
          }}
        >
          <ButtonFullWidth
            onClick={onBackPress}
            icon={<ArrowLeftOutlined />}
          >
            Wróć
          </ButtonFullWidth>
        </Col>
        <Col
          sm={{
            span: 8, order: 2
          }}
          xs={{
            span: 24, order: 1
          }}
        >
          <RowExt
            justify={width < GRID_SM ? 'start' : 'center'}
          >
            <Typography.Text strong>
              Wybierz samodzielnie:
            </Typography.Text>
          </RowExt>
        </Col>
        <Col
          sm={{
            span: 8, order: 2
          }}
          xs={{
            span: 24, order: 2
          }}
        >
          <DatePicker
            placeholder='ustaw samodzielnie'
            style={{ width: '100%' }}
            onChange={onValueChange}
            value={maturityDate}
          />
        </Col>
      </RowExt>
    </EditMaturityCard>
  );
};

export default EditMaturity;


export const GreyRow = styled(RowExt)`
  background-color: #f5f5f5;
  border-radius: 4;
  padding: 24px;
  margin-top: 24px;
`;