import React from 'react';
import nextId from 'react-id-generator';
import { shallowEqual } from 'react-redux';

import { useTypedSelector } from '../../../store';
import { PartialResult } from '../../../store/sumUp';
import { periodText } from '../../../utils/hooks/justification/justInterestPartials';
import { coveredInterestCalc, paymentInterestCalcBasis } from '../../../utils/hooks/justification/justPayments';
import JustParagraph from './JustParagraph';

const JustCoveredInterests = ({ result }:{
  result: PartialResult
}) => {
  const  lawsuitOpts  = useTypedSelector(state =>
    state.sumUp.lawsuitOpts, shallowEqual);
  const coveredResults = coveredInterestCalc(lawsuitOpts, result);
  if (result.interestsSumCovered > 0) {
    return (
      <>
        {coveredResults.periodResults.map(periodResult =>
          (
            <JustParagraph
              key={nextId()}
              text={periodText(
                periodResult,
                'odsetki',
                coveredResults.periodResults.length - 1 === 0
              )}
            />
          ))}
      </>
    );
  }
  if (result.interestsSumFulfilledCovered > 0){
    return (
      <>
        {result.interestResultsFulfilled.map(({ interestSum, periodResults }, index) => {
          return (
            <>
              {periodResults.map(periodResult =>
                (
                  <JustParagraph
                    key={nextId()}
                    text={periodText(
                      periodResult,
                      'odsetki',
                      periodResults.length - 1 === 0
                    )}
                  />
                ))}
              <JustParagraph
                text={paymentInterestCalcBasis(result, interestSum, index)}
              />
            </>
          );
        })}
      </>
    );
  }
  return null;
};

export default JustCoveredInterests;


