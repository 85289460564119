/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SaveLawsuitModal {
  isSaveModalVisible: boolean;
  isSaveFromResult: boolean;
}

const initialState:SaveLawsuitModal = {
  isSaveModalVisible:false,
  isSaveFromResult: false,
};

export const saveLawsuitModalSlice = createSlice({
  name: 'saveLawsuitModal',
  initialState,
  reducers: {
    saveModalOpened:(state:SaveLawsuitModal, action: PayloadAction<{
      isSaveModalVisible: boolean;
      isSaveFromResult: boolean;
    }>) => {
      state.isSaveModalVisible = action.payload.isSaveModalVisible;
      state.isSaveFromResult = action.payload.isSaveFromResult;
    },
    saveModalClosed:(state:SaveLawsuitModal) => {
      state.isSaveModalVisible = false;
      state.isSaveFromResult = false;
    },
  }
});

export const {
  saveModalOpened,
  saveModalClosed,
} = saveLawsuitModalSlice.actions;

export default saveLawsuitModalSlice.reducer;
