import { Button, Checkbox, Form } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { LINK_NO_STYLE } from '../../styles';

const SignUpCheckbox = (
  {
    text,
    buttonText,
    onChange,
    navPath,
    value,
    error
  }: {
    text: string,
    buttonText: string,
    onChange:(e: CheckboxChangeEvent) => void,
    navPath: string,
    value: boolean,
    error: boolean
  }
) => {
  return (
    <Form.Item
      validateStatus={error ? 'error' : ''}
      valuePropName="checked"
      style={{
        marginLeft:3, marginBottom:0
      }}
      rules={[{ required: error }]}
      help={error ? 'Zaakceptuj proszę aby kontunuować' : ''}
      preserve={false}
    >
      <Checkbox
        onChange={onChange}
        checked={value}
      >
        <SmallerText>
          {text}
        </SmallerText>

        <Button
          type="link"
          style={{ padding: 2 }}
        >
          <Link
            to={navPath}
            style={LINK_NO_STYLE}
          >
            <SmallerText>
              {buttonText}
            </SmallerText>
          </Link>
        </Button>
      </Checkbox>
    </Form.Item>
  );
};

export default SignUpCheckbox;

const SmallerText = styled.span`
   font-size: 12px;
`;