import { Anchor, Col, Modal, Row } from 'antd';
import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import video from '../../assets/intro.png';
import { LINK_TRAINING } from '../../utils/helpers/links';
import useWindowSize from '../../utils/hooks/useWindowSize';
import { AboutContainer, DashedColLeft, DashedColTop, GREY_WHITE, GRID_LG, GRID_MD, GRID_SM } from '../../styles';
import Icon from '../../styles/Icon';
import AbtTitle from '../About/AbtTitle';
import VideoPlaceholder from '../About/Video/VideoPlaceholder';

const { Link } = Anchor;

const IntroVideo = () => {
  const { width } = useWindowSize();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const videoSize = () => {
    if (width >= GRID_LG) return {
      width: 960, height: 540
    };
    if (width >= GRID_MD) return {
      width: 720, height: 405
    };
    if (width >= GRID_SM) return {
      width: 480, height: 270
    };
    return {
      width: 320, height: '100%'
    };
  };

  return (
    <>
      <AboutContainer>
        <Row id="intro">
          <DashedColTop
            lg={{
              offset: 1,
              span: 8,
            }}
            md={{
              offset: 1,
              span: 7
            }}
            xs={{
              offset: 2,
              span: 20,
            }}
          >
            <AbtTitle
              title='Darmowe wprowadzenie'
              desc={(
                <>
                  Umów się z nami na
                  {' '}
                  <strong>zdalne</strong>
                  {' '}
                  szkolenie dla Twojego
                  <br />
                  zespołu.
                  <br />
                  <br />
                  <strong>Czas trwania: </strong>
                  ok. 30 min + odpowiedzi na pytania
                </>
)}
            />
          </DashedColTop>
          <VideoCol
            xl={{ span: 11 }}
            lg={{ span: 11 }}
            md={16}
            xs={24}
          >
            <VideoPlaceholder
              showVideo={() =>
                setIsModalVisible(true)}
              src={video}
            />
          </VideoCol>
          <Col
            xl={{ span: 2 }}
            lg={{ span: 1 }}
            md={{ span:0 }}
            xs={0}
          />
          <DashedColLeft
            xl={{ span: 2 }}
            lg={{ span: 3 }}
            md={{ span:0 }}
            xs={0}
          >
            <Anchor
              offsetTop={160}
              style={{
                marginLeft: -5, fontFamily: 'Fira sans', background: 'none'
              }}
            >
              <Link href="#intro" title="Intro" />
              <Link href="#benefits" title="Co zyskasz ?" />
              <Link href="#contact" title="Umówmy się" />
            </Anchor>
          </DashedColLeft>
        </Row>
      </AboutContainer>
      <Modal
        onCancel={() =>
          setIsModalVisible(false)}
        visible={isModalVisible}
        footer={false}
        title={(
          <Icon
            height="18"
            width="18"
            fill="white"
            iconType="PLAY"
          />
        )}
        destroyOnClose
        width={videoSize().width + 40}
        style={{ background: 'none !important' }}
      >
        <Player
          url={LINK_TRAINING}
          volume={0.5}
          playing={isModalVisible}
          height={videoSize().height}
          width={videoSize().width}
          controls
        />
      </Modal>
    </>
  );
};

export default IntroVideo;

const VideoCol = styled(Col)`
  background-color: ${GREY_WHITE};
  padding: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px dashed rgba(66,71,112,0.09);
  @media only screen and (max-width: 767px) {
    margin-top: 48px;
    background-color: white;
    padding: 0;
    border-right: none;
	}
`;

export const Player = styled(ReactPlayer)`
  height: 540px;
  width: 960px;
`;
