import React from 'react';
import LawParText from './LawParText';

const LawPars = (
  { texts }: {
    texts: { text:string }[]
  }
) => {
  return (
    <>
      {texts.map(value =>
        (
          <LawParText
            key={value.text}
            text={value.text}
          />
        ))}
    </>
  );
};

export default LawPars;
