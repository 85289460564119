import { Button } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { BLACK, BLUE } from '../../../../styles';


const SloganMenuButton = ({
  pathname,
  text,
  borderColor
}:{
  pathname: string,
  text: string,
  borderColor?: string,
}) =>
  (
    <>
      <BorderColumn
        borderColor={borderColor}
      />
      <Link
        to={{ pathname }}
      >
        <MenuBottomButton type="link">
          {text}
        </MenuBottomButton>
      </Link>
    </>
  );

export default SloganMenuButton;


const BorderColumn = styled.span<{ borderColor?: string }>`
border-right: 1px solid ${props =>
    props.borderColor ?? 'black'};
height: 18px; 
width:1;

@media only screen and (max-width: 350px) {
    display: none;
	}
`;

const MenuBottomButton = styled(Button)<{ color?: string }>`
    color: ${BLACK};
   :hover{
      color: ${props =>
    props.color ?? BLUE};
   }
`;