import { ArrowLeftOutlined } from '@ant-design/icons';
import { Col } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import SignUpForm from '../components/SignUp/SignUpForm';
import AuthContainer from '../components/UI/Containers/AuthContainer';
import { useAppDispatch } from '../store';
import { signUpReset } from '../store/signUpReducer';
import { ButtonFullWidth, RowExt } from '../styles';


const SignUp = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  return (
    <AuthContainer
      title="Utwórz konto"
      desc="Wybierz metodę tworzenia konta"
    >
      <SignUpForm />
      <RowExt
        justify="space-around"
        align="middle"
        marginTop="md"
      >
        <Col span={12}>
          <ButtonFullWidth
            icon={<ArrowLeftOutlined />}
            onClick={() =>
              history.goBack()}
          >
            Wróć
          </ButtonFullWidth>
        </Col>
        <Col span={12}>
          <ButtonFullWidth
            type="link"
            onClick={() => {
              dispatch(signUpReset());
              history.push('/login');
            }}
          >
            Mam już konto
          </ButtonFullWidth>
        </Col>
      </RowExt>
    </AuthContainer>
  );
};

export default SignUp;