import { ArrowLeftOutlined, ArrowRightOutlined, ControlOutlined } from '@ant-design/icons';
import { Button, Card, Row } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';

import { importExcelStepUpdated } from '../../../store/importExcel';
import { RowExt } from '../../../styles';
import ImpCommFactualBasis from '../ImpCommonData/ImpCommFactualBasis';
import ImpCommLegalBasis from '../ImpCommonData/ImpCommLegalBasis';
import ImpCommMaturityOpts from '../ImpCommonData/ImpCommMaturityOpts';
import { IMP_EXCEL } from '../ImpCommonData/impCommonData';
import ImpCard from '../ImpCard';

const ImpExcelCommData = () => {
  const dispatch = useDispatch();

  const onNext = () => {
    dispatch(importExcelStepUpdated(3));
  };

  const onBack = () => {
    dispatch(importExcelStepUpdated(1));
  };

  return (
    <ImpCard
      icon={<ControlOutlined />}
      {...{
        title: 'Opcje importu',
        desc:' Wybierz wspólne dane importu. Zawsze możesz później skorygować swoją decyzję co do poszczególnych kwot.'
      }}
    >
      <RowExt
        justify="space-between"
        align='middle'
        marginBottom={24}
      >
        <Button
          type="dashed"
          icon={<ArrowLeftOutlined />}
          onClick={onBack}
        />

        <Button
          type="primary"
          icon={<ArrowRightOutlined />}
          onClick={onNext}
        />
      </RowExt>
      <Row>
        <Card
          style={{ width: '100%' }}
        >
          <ImpCommMaturityOpts
            impType={IMP_EXCEL}
          />
          <ImpCommLegalBasis
            impType={IMP_EXCEL}
          />
          <ImpCommFactualBasis
            impType={IMP_EXCEL}
          />
        </Card>
      </Row>
    </ImpCard>

  );
};

export default ImpExcelCommData;
