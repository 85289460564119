import { CalendarOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';

const { Title } = Typography;

type Props = {
  children: React.ReactNode;
};

const EditMaturityCard = (
  { children }: Props
) => {
  return (
    <AnimatePresence>
      <motion.div
        transition={{
          ease: 'easeInOut',
          duration: 1,
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Title
          level={2}
        >
          <CalendarOutlined
            style={{ marginRight:4 }}
          />
          Edytuj datę wymagalności
        </Title>
        <Title level={5}>
          Wybierz jedną z opcji lub ustaw datę wymagalności samodzielnie
        </Title>
        {children}
      </motion.div>
    </AnimatePresence>
  );
};

export default EditMaturityCard;
