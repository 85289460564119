import { Button, Col, Divider, Row, Space } from 'antd';
import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Img } from 'react-image';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import logo from '../../assets/logo.svg';
import { AboutContainer, DashedColBoth, DashedColLeft, GRID_MD_PX, RowMd } from '../../styles';
import { ScreenBackButton } from '../UI/DescScreenFooter';

const GoBackButton = ({ space }: { space?: boolean }) => {
  const history = useHistory();
  return (
    <RowStyled space={space} align="middle" justify="end">
      <ScreenBackButton onClick={() =>
        history.push('/')}
      />
    </RowStyled>
  );
};

const IntroHeader = () => {
  const history = useHistory();
  return (
    <AboutContainer>
      <Row>
        <Col
          lg={{
					  offset: 1,
					  span: 8,
          }}
          md={{
					  offset: 1,
					  span: 7,
          }}
          xs={{
					  offset: 2,
					  span: 12,
          }}
        >
          <Link to={{ pathname: '/' }}>
            <Logo src={logo} />
          </Link>
        </Col>
        <Col md={0} xs={8}>
          <GoBackButton />
        </Col>
        <DashedColBoth xl={{ span: 11 }} lg={{ span: 11 }} md={16} xs={0}>
          <RowMd align="middle" justify="center">
            <Space>
              <AnchorLink href="#benefits">
                <Button type="link" href="#benefits">
                  Co zyskasz ?
                </Button>
              </AnchorLink>
              <AnchorLink href="#contact">
                <Button type="link" href="#contact">
                  Umówmy się
                </Button>
              </AnchorLink>
              <Divider type="vertical" />
              <ScreenBackButton onClick={() =>
                history.push('/')}
              />
            </Space>
          </RowMd>
        </DashedColBoth>
        <Col xl={{ span: 2 }} lg={{ span: 1 }} xs={0} />
        <DashedColLeft xl={{ span: 2 }} lg={{ span: 3 }} xs={0} />
      </Row>
    </AboutContainer>
  );
};

export default IntroHeader;

const Logo = styled(Img)`
	width: 124px;
	border-radius: 4px;
	margin-top: 24px;
	margin-bottom: 72px;
	@media only screen and (max-width: ${GRID_MD_PX}) {
		margin-bottom: 48px;
	}
`;

const RowStyled = styled(RowMd)<{ space?: boolean }>`
	margin-right: ${({ space }) =>
    (space ? '24px' : 0)};
`;
