import { Col, Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { DashedColLeft, GRID_MD_PX, RowExt } from '../../../styles';

const { Paragraph } = Typography;

const SubBasicStar = ({ inCard }: { inCard?: boolean }) => {
  return (
    <RowExt>
      <Col
        lg={{
				  span: inCard ? 0 : 2,
				  offset: 0,
        }}
        xs={{
				  span: inCard ? 2 : 0,
				  offset: 0,
        }}
      />
      <Col
        lg={{
				  span: inCard ? 0 : 20,
				  offset: 0,
        }}
        xs={{
				  span: inCard ? 20 : 0,
				  offset: 0,
        }}
      >
        <Desc type="secondary">
          <strong>*</strong>
          {' '}
          przy założeniu, że rynkowa wartość godziny pracy radcy prawnego czy
          adwokata wynosi 300 zł netto
        </Desc>
      </Col>
      <Col xl={1} xs={0} />
      <DashedColLeft xl={1} xs={0} />
    </RowExt>
  );
};

export default SubBasicStar;

const Desc = styled(Paragraph)`
	padding-top: 12px;
	font-family: Fira mono;
	text-align: center;
	font-size: 12px;
	@media only screen and (max-width: ${GRID_MD_PX}) {
		padding-bottom: 24px;
	}
`;
