import { collection, doc, limit, orderBy, query, where } from 'firebase/firestore';
import { db } from '../../_firebase';

/* ---------------------------------- user ---------------------------------- */

export const users = collection(db, 'users');

export const userRef = (userId:string) =>
  doc(db, 'users', userId);

const calculationsRef = (userId: string) =>
  collection(db, `users/${userId}/calculations`);

export const counterForCalculationsRef = (userId: string) =>
  doc(db, 'users', userId, 'counters', 'calculations');

export const saveIdRef = (userId: string, saveId: string) =>
  doc(db, 'users', userId, 'calculations', saveId);

export const saveRef = (userId: string) =>
  collection(db, 'users', userId, 'calculations');

export const personalDataRef = (userId: string) =>
  doc(db, 'users', userId, 'personalData', 'personalData');


/* ----------------------------------- sub ---------------------------------- */

export const subscriptionsRef = (userId: string) =>
  collection(db, `users/${userId}/subscriptions`);

export const trailingOrActiveSubscriptionsQuery = (userId: string) => {
  const subscriptions = subscriptionsRef(userId);
  return query(subscriptions, where('status', 'in', ['trialing', 'active']));
};

export const subscriptionCodesRef = collection(db, 'subscriptionCodes');

export const calculationRef = (userId: string, id: string) =>
  doc(db, 'users', userId, 'calculations', id);


export const checkoutSessionsRef = (userId: string) =>
  collection(db, `users/${userId}/checkout_sessions`);

export const connectedAccountsRef = (userId: string) =>
  collection(db, `users/${userId}/connectedAccounts`);

export const connectedAccountsQuery = (userId: string) => {
  const connectedAccounts = connectedAccountsRef(userId);
  return query(connectedAccounts, orderBy('createdDate', 'desc'));
};

/* ---------------------------------- inbox --------------------------------- */

export const inboxQuery = (userId: string) => {
  const inbox = inboxRef(userId);
  return query(inbox, orderBy('createDate', 'desc'), limit(5));
};

export const inboxUsersRef = (userId: string) =>
  collection(db, `users/${userId}/inboxUsers`);

export const inboxUserRef = (receiverId: string, senderId: string) =>
  doc(db, `users/${receiverId}/inboxUsers/${senderId}`);

export const inboxUsersQuery = (userId: string) => {
  const inboxUsers = inboxUsersRef(userId);
  return query(inboxUsers, orderBy('createdDate', 'desc'));
};

export const inboxRef = (userId: string) =>
  collection(db, `users/${userId}/lawsuitInbox`);

export const inboxItemRef = (userId: string, inboxId: string) =>
  doc(db, `users/${userId}/lawsuitInbox/${inboxId}`);

export const calculationsQuery = (userId: string, direction: 'desc' | 'asc', limitLocal: number) => {
  const calculations = calculationsRef(userId);
  return query(calculations, orderBy('editDate', direction), limit(limitLocal));
};


/* ----------------------------------- ls ----------------------------------- */

export const lsDocRef = (id:string) =>
  doc(db, 'ls', id);

/* ----------------------------------- ml ----------------------------------- */

const trainingData = 'trainingData';

export const autoAssignTDref = doc(db, trainingData, 'autoAssign');

const nNet = 'NNetState';

export const trResCategorizeDocRef = doc(db, nNet, 'categorizeDoc');




