import { AuditOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Col, Form, Row } from 'antd';
import React, { memo } from 'react';
import styled from 'styled-components';

import { GRID_LG, GRID_SM } from '../../../styles';
import { ButtonFullWidth, EmptyButton } from '../../../styles/ui';
import useWindowSize from '../../../utils/hooks/useWindowSize';

const BasisButton = (
  {
    basisAvailable,
    buttonText,
    setEdit,
    tooltip
  }: {
    basisAvailable?: boolean | null | string,
    buttonText: string,
    setEdit: () =>void,
    tooltip: string
  }
) => {
  const { width } = useWindowSize();
  return (
    <Col
      sm={{ span:  10  }}
      xs={{ span:  24  }}
      style={{ marginTop: width <= GRID_SM ? 24 : 0 }}
    >
      <Form.Item
        label={(
          <>
            <IconAudit />
            Podstawa faktyczna i prawna
            {width >= GRID_LG || width <= GRID_SM ? ' roszczenia' : ''}
          </>
          )}
        tooltip={tooltip}
      >
        <Row>
          {basisAvailable ? (
            <EmptyButtonFull
              icon={<EditOutlined />}
              onClick={setEdit}
            >
              {buttonText}
            </EmptyButtonFull>
          ) : (
            <ButtonFullWidth
              type="dashed"
              icon={<PlusOutlined />}
              onClick={setEdit}
            >
              {buttonText}
            </ButtonFullWidth>
          )}
        </Row>
      </Form.Item>
    </Col>
  );
};


export default memo(BasisButton);

const EmptyButtonFull = styled(EmptyButton)`
  width: 100%;
`;

const IconAudit  = styled(AuditOutlined)`
  margin-right: 4px;
`;