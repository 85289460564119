import React from 'react';
import { PAYMENT_CONFIRMATION } from '../../../../services/ML/docTypes';
import { OcrItem } from '../../../../store/impOcr';
import InvcVatEvidCard from '../../../Evidence/EvidCard/Types/InvcVatEvidCard';
import PayConEvidCard from '../../../Evidence/EvidCard/Types/PayConEvidCard';


const ImpOcrEvidCard = ({
  ocrItem,
  index,
  setSelected,
  selectedItems,
  onMerge,
}:{
  ocrItem: OcrItem
  index: number,
  setSelected:(selectedItems: number[]) =>void
  selectedItems: number[],
  onMerge: (clickedIndex: number) => void
}) => {
  if (ocrItem.correctedDocType === PAYMENT_CONFIRMATION) return (
    <PayConEvidCard
      {...{
        ocrItem,
        index,
        setSelected,
        selectedItems,
        onMerge,
        isSelected:selectedItems.some(value =>
          value === index)
      }}
    />

  );

  return (
    <InvcVatEvidCard
      {...{
        ocrItem,
        index,
        setSelected,
        selectedItems,
        onMerge,
        isSelected:selectedItems.some(value =>
          value === index)
      }}
    />
  );
};

export default ImpOcrEvidCard;