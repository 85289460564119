import { Col } from 'antd';
import React from 'react';

import { GREY_LIGHT, GRID_MD, MiddleBorder, RowExt } from '../../../styles';
import useWindowSize from '../../../utils/hooks/useWindowSize';
import { IconStyled } from './Claim/Claim';

export const AssignPaymentsArrowEnd = () => {
  const { width } = useWindowSize();
  if (width < GRID_MD) return null;
  return (
    <>
      <RowExt>
        <Col
          xs={10}
        />
        <Col xs={4}>
          <RowExt
            justify="center"
            style={{ height: 24 }}
          >
            <Col xs={4}>
              <MiddleBorder />
            </Col>
          </RowExt>
        </Col>
        <Col
          xs={10}
        />
      </RowExt>
      <RowExt>
        <Col
          xs={10}
        />
        <Col xs={4}>
          <RowExt justify="center">
            <IconStyled
              height="16"
              width="16"
              fill={GREY_LIGHT}
              stroke={GREY_LIGHT}
              iconType="ARROW_DOWN_DECOR"
            />
          </RowExt>
        </Col>
        <Col
          xs={10}
        />
      </RowExt>
    </>
  );
};

export const AssignPaymentsArrowStart = () => {
  const { width } = useWindowSize();
  if (width < GRID_MD) return null;
  return (
    <RowExt>
      <Col
        xs={10}
      />
      <Col xs={4}>
        <RowExt
          justify="center"
          style={{ height: 24 }}
        >
          <Col xs={4}>
            <MiddleBorder />
          </Col>
        </RowExt>
      </Col>
      <Col
        xs={10}
      />
    </RowExt>
  );
};

