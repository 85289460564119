import { CalendarOutlined, FieldNumberOutlined } from '@ant-design/icons';
import React from 'react';

import { PaymentConfirmation } from '../../../../services/ML/docTypes';
import { InvVatFields, PayConfFields } from '../../../../services/ML/DataExtraction/fieldInputMap';
import { BLACK } from '../../../../styles';
import Icon from '../../../../styles/Icon';
import EvidEditPanel from '../EvidEditPanel';


const EditEvidPayCon = ({ results, onEdit, onReset, onChangeValue }:{
  results: PaymentConfirmation,
  onEdit: (field: PayConfFields) => void,
  onReset: (field: PayConfFields) => void,
  onChangeValue: ({
    key,
    value
  }:{
    key: InvVatFields | PayConfFields,
    value: number | string
  }) => void
}) => {
  const {
    allDocDates,
    allSums,
    paymentDate,
    paymentSum,
    paymentTitle,
  } = results;

  return (
    <>
      <EvidEditPanel
        icon={<FieldNumberOutlined />}
        {...{
          title: 'Tytuł wpłaty',
          similarValues: [],
          onEdit:() =>
            onEdit('paymentTitle'),
          onReset: () =>
            onReset('paymentTitle'),
          chosenValue: paymentTitle ?? '',
          selectValue: (v: string | number) =>
            onChangeValue({
              key: 'paymentTitle',
              value: v
            })
        }}
      />
      <EvidEditPanel
        icon={(
          <Icon
            height="16"
            width="16"
            fill={BLACK}
            stroke={BLACK}
            iconType="MONEY_PACK"
          />
)}
        {...{
          title: 'Wpłacona kwota',
          similarValues: allSums,
          areSums: true,
          onEdit:() =>
            onEdit('paymentSum'),
          onReset: () =>
            onReset('paymentSum'),
          chosenValue: paymentSum ?? 0,
          selectValue: (v: string | number) =>
            onChangeValue({
              key: 'paymentSum',
              value: v
            })
        }}
      />
      <EvidEditPanel
        icon={<CalendarOutlined />}
        {...{
          title: 'Data wpłaty',
          similarValues: allDocDates,
          onEdit:() =>
            onEdit('paymentDate'),
          onReset: () =>
            onReset('paymentDate'),
          chosenValue: paymentDate ?? '',
          selectValue: (v: string | number) =>
            onChangeValue({
              key: 'paymentDate',
              value: v
            })
        }}
      />
    </>
  );
};

export default EditEvidPayCon;