import moment from 'moment';
import React from 'react';
import { shallowEqual } from 'react-redux';
import { formatPLN } from '../../../utils/helpers/formatNumber';
import { toUnit } from '../../../utils/helpers/money';
import { useTypedSelector } from '../../../store';
import { RowSm } from '../../../styles';
import { GreyArea } from '../../../styles/ui';
import AnimOpacity from '../../UI/Anim/AnimOpacity';
import CopyButtonJust from '../../UI/Button/CopyButtonJust';


const CurrencyJust = () => {
  const {
    result,
    isResultVisible,
  } = useTypedSelector(state =>
    state.currency, shallowEqual);
  const justText = () => {
    const t1 = ` Kwota ${formatPLN(result.convertedSum)} stanowi równowartość ${toUnit(result.convertingSum) }`;
    const t2 = ` ${result.currencyType} obliczoną według średniego kursu NBP ustalonego w dniu ${moment(result.exchangeDate).format('DD-MM-YYYY')}`;
    const t3 = ` wynoszącego: ${result.exchangeRate}.`;
    return t1 + t2 + t3;
  };

  if (isResultVisible){
    return (
      <AnimOpacity>
        <GreyArea>
          {justText()}
        </GreyArea>
        <RowSm justify="end">
          <CopyButtonJust
            value={justText()}
          />
        </RowSm>
      </AnimOpacity>
    );
  }

  return null;

};

export default CurrencyJust;
