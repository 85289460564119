import { ContainerOutlined, LogoutOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import { signOut } from 'firebase/auth';
import React from 'react';
import { auth } from '../../../services/firebase/auth';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { subscriberStatusReset } from '../../../store/user';
import { lsSaveReset } from '../../../store/lawsuitUI';
import { BLACK, BLUE_DARK, RowSm } from '../../../styles';
import { ButtonFullWidth, EmptyButton, LinkFullWidth } from '../../../styles/ui';
import { withAppLoading } from '../../../utils/helpers/withAppLoading';
import { HomeDescWrapper, HomeMenuWrapper, HomeTitleWrapper } from '../HomeUI/HomeUI';


const MenuAuthOpts = (
  {
    setSignUpVisible,
    setLoginVisible
  }: {
    setSignUpVisible: (signUpVisible: boolean) => void,
    setLoginVisible: (isLoginVisible: boolean) => void
  }
) => {
  const userExists = useTypedSelector(state =>
    state.user.isNonAnonymousUserLoggedIn);
  const dispatch = useAppDispatch();
  const onLogout = async () => {
    withAppLoading({ dispatch }, async () => {
      try {
        await signOut(auth);
        dispatch(lsSaveReset());
        dispatch(subscriberStatusReset());
      } catch {
        notification.open({
          type: 'error',
          message: 'Ups!',
          description: 'Wystąpił błąd, spróbuj jeszcze raz.'
        });
      }
    });
  };
  return (
    <div>
      <HomeTitleWrapper
        borderColor={BLUE_DARK}
        title={userExists ? texts.title2 : texts.title1}
        level={5}
      />
      <HomeDescWrapper>{userExists  ? texts.desc2 : texts.desc1}</HomeDescWrapper>
      {!userExists  ? (
        <>
          <HomeMenuWrapper>
            <EmptyButton
              style={{ width: '100%' }}
              color={BLACK}
              hoverColor="white"
              hoverBg={BLACK}
              icon={<ContainerOutlined />}
              onClick={() =>
                setSignUpVisible(true)}
            >
              {texts.button}
            </EmptyButton>
          </HomeMenuWrapper>
          <RowSm>
            <ButtonFullWidth
              type="link"
              onClick={() =>
                setLoginVisible(true)}
            >
              {texts.already}
            </ButtonFullWidth>
          </RowSm>
        </>
      ) :       (
        <HomeMenuWrapper>
          <LinkFullWidth
            to={{ pathname: '/dashboard' }}
          >
            <EmptyButton
              style={{ width: '100%' }}
              color={BLACK}
              hoverColor="white"
              hoverBg={BLACK}
              icon={<ContainerOutlined />}
            >
              {texts.button2}
            </EmptyButton>
          </LinkFullWidth>
          <RowSm>
            <ButtonFullWidth
              type="link"
              onClick={onLogout}
              icon={<LogoutOutlined />}
            >
              {texts.logout}
            </ButtonFullWidth>
          </RowSm>
        </HomeMenuWrapper>
      )}
    </div>
  );
};

export default MenuAuthOpts;

const texts = {
  tab1: 'Nowy użytkownik',
  tab2: 'Mam konto',
  title1: 'Utwórz konto',
  desc1: 'Zapisz swoje wyliczenia - konto jest darmowe',
  title2: 'Twoje konto',
  desc2: 'Przejdź do zapisanych danych',
  button: 'Utwórz konto',
  already: 'Mam już konto - logowanie',
  button2: 'Panel użytkownika',
  logout: 'Wyloguj mnie'
};