import { Descriptions, Divider } from 'antd';
import _ from 'lodash';
import { Moment } from 'moment';
import React from 'react';

import { PaymentData } from '../../../store/interestStorage';
import { BLUE, DescFullWidth, RowExt } from '../../../styles';
import { formatDate } from '../../../utils/helpers/date';
import { formatPLN } from '../../../utils/helpers/formatNumber';

const { Item: DescItem } = Descriptions;

const DetailsClaimSum = ({
  claimSum,
  endDate,
  assignedPayments,
  claimStartingDate,
}:{
  claimSum:number,
  endDate: Moment | string,
  assignedPayments: PaymentData
  claimStartingDate: Moment | string,
}) => {
  if (_.isEmpty(assignedPayments)) return null;

  return (
    <>
      <Divider>Roszczenie - kwota główna</Divider>
      <RowExt marginTop="md" fullWidth>
        <DescFullWidth
          layout="horizontal"
          bordered
          key="d"
          style={{ borderColor: BLUE }}
        >
          <DescItem
            label="Kwota główna po zaliczeniu wszytkich wpłat"
            span={3}
            labelStyle={{ fontWeight: 700 }}
          >
            <div style={{ fontSize: 16 }}>
              <strong>{`${formatPLN(claimSum)}`}</strong>
            </div>
          </DescItem>
          <DescItem label="Data początkowa" span={2}>{formatDate(claimStartingDate)}</DescItem>
          <DescItem label="Data końcowa" span={2}>{formatDate(endDate)}</DescItem>
        </DescFullWidth>
      </RowExt>
    </>
  );
};

export default DetailsClaimSum;