import { FileExcelOutlined } from '@ant-design/icons';
import moment from 'moment';
import React from 'react';
import ReactExport from 'react-export-excel';
import _ from 'lodash';
import nextId from 'react-id-generator';
import { COMPENSATION_BASIS_SHORT } from '../../../utils/helpers/lawBasis';
import { toUnit } from '../../../utils/helpers/money';
import { useTypedSelector } from '../../../store';
import { ButtonFullWidth } from '../../../styles';

const { ExcelFile } = ReactExport;
const { ExcelSheet } = ReactExport.ExcelFile;
const { ExcelColumn } = ReactExport.ExcelFile;

type FormattedPeriod = {
  periodStart: string;
  periodEnd: string;
  days: number;
  periodSum: number;
  interestRate: number;
};

type FormattedInterestData = {
  key: string;
  interestData: FormattedPeriod[]
};

type FormattedCompensation = {
  lastWorkingDay: string
  exchangeRate: unknown;
  valueEuro: number,
  valuePLN: number,
  key: string,
};

type FormattedCompensationData = {
  key: number;
  compensationData: FormattedCompensation[]
};

const DetailsExcel = () => {
  const compensationOn = useTypedSelector(state =>
    state.sumUp.lawsuitOpts.compensationOn);
  const  interestsFormattedData =  useTypedSelector(state => {
    const partialResultsToFormat = state.sumUp.partialResults;
    const formattedData:FormattedInterestData[] = [];
    partialResultsToFormat.forEach((result) => {
      const formattedPeriods:FormattedPeriod[] = [];
      if (result.periodResults){
        result.periodResults.forEach(period => {
          const formattedResult = {
            ...period,
            periodStart: moment(period.periodStart).format('YYYY-MM-DD'),
            periodEnd: moment(period.periodEnd).format('YYYY-MM-DD'),
            periodSum: toUnit(period.periodSum)
          };
          formattedPeriods.push(formattedResult);
        });
        formattedData.push({
          interestData: formattedPeriods, key: nextId(),
        });
      }
    });
    return formattedData;
  });

  const paymentFormattedData =  useTypedSelector(state => {
    return state.sumUp.paymentData.map(value => {
      const {
        paymentDate,
        paymentSum,
        transferDescription,
        statementDate
      } = value;
      return {
        paymentDate:  moment(paymentDate).format('YYYY-MM-DD'),
        statementDate: statementDate ?  moment(statementDate).format('YYYY-MM-DD') : '',
        paymentSum: toUnit(paymentSum),
        transferDescription: transferDescription ?? '',
      };
    });
  });

  const  compensationFormattedData =  useTypedSelector(state => {
    const partialResultsToFormat = state.sumUp.partialResults;
    const formattedData:FormattedCompensationData[] = [];
    if (compensationOn){
      partialResultsToFormat.forEach((result, index) => {
        const formattedCompensationResults:FormattedCompensation[] = [];
        if (result.compensationResult){
          const formattedResult = {
            ...result.compensationResult,
            key: nextId(),
            lastWorkingDay: moment(result.compensationResult.lastWorkingDay).format('YYYY-MM-DD'),
            valueEuro:  toUnit(result.compensationResult.valueEuro),
            valuePLN: toUnit(result.compensationResult.valuePLN),
          };
          formattedCompensationResults.push(formattedResult);
        }
        formattedData.push({
          compensationData: formattedCompensationResults,
          key: index,
        });
      });
    }
    return formattedData;
  });

  const formattedSumUpData = useTypedSelector(state => {
    return state.sumUp.partialResults.map((result) => {
      const {
        accountingDoc,
        assignedPaymentsSum,
        claimSum,
        compensationResult,
        dueDate,
        endDate,
        factualBasis,
        interestsSumFulfilledUncovered,
        interestsSumUncovered,
        legalBasis,
        maturityDate,
        partialSum,
        startingDate,
        isClaimFromFulfilled
      } = result;
      const interestPartialSum = result.isClaimFromFulfilled ?
        toUnit(result.interestsSumUncovered + result.interestsSumFulfilledUncovered) :
        toUnit(result.interestsSumUncovered);
      return {
        accountingDoc: accountingDoc ?? '',
        assignedPaymentsSum: toUnit(assignedPaymentsSum),
        claimSum: toUnit(claimSum),
        compensationResult: compensationResult?.valuePLN ? toUnit(compensationResult?.valuePLN) : '',
        dueDate: moment(dueDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
        factualBasis: factualBasis ?? '',
        interestsSumFulfilledUncovered: toUnit(interestsSumFulfilledUncovered),
        interestsSumUncovered: toUnit(interestsSumUncovered),
        legalBasis: legalBasis ?? '',
        maturityDate: moment(maturityDate).format('YYYY-MM-DD'),
        interestPartialSum,
        partialSum: toUnit(partialSum),
        startingDate: moment(startingDate).format('YYYY-MM-DD'),
        isClaimFromFulfilled: isClaimFromFulfilled ? 'TAK' : 'NIE'
      };
    });
  });

  return (
    <ExcelFile
      filename="pozew-app-wyliczenia"
      element={(
        <ButtonFullWidth type='primary' icon={<FileExcelOutlined />}>
          Pobierz xlsx (Excel)
        </ButtonFullWidth>
        )}
    >
      <ExcelSheet
        data={formattedSumUpData}
        name="Podsumowanie"
      >
        <ExcelColumn
          label="Kwota główna przed zaliczeniem wpłat"
          value="partialSum"
        />
        <ExcelColumn
          label="Suma wpłat"
          value="assignedPaymentsSum"
        />
        <ExcelColumn
          label="Kwota główna (po zaliczeniu wpłat)"
          value="claimSum"
        />
        <ExcelColumn label="Skapitalizowane odsetki - łącznie" value="interestPartialSum" />
        {compensationOn ?  <ExcelColumn label={`Rekompensaty art 10 ${COMPENSATION_BASIS_SHORT}`} value="compensationResult" />  :  <ExcelColumn label={`Rekompensaty art 10 ${COMPENSATION_BASIS_SHORT}`} value="0" />}
        <ExcelColumn label="Termin spełnienia świadczenia" value="dueDate" />
        <ExcelColumn label="Data wymagalności" value="maturityDate" />
        <ExcelColumn label="Data początkowa" value="startingDate" />
        <ExcelColumn label="Data końcowa" value="endDate" />
        <ExcelColumn label="Nr dokumentu księgowego" value="accountingDoc" />
        <ExcelColumn label="Podstawa prawna" value="legalBasis" />
        <ExcelColumn label="Z tytułu" value="factualBasis" />
        <ExcelColumn label="Odsetki - niezaspokojona część kwoty głównej" value="interestsSumUncovered" />
        <ExcelColumn label="Odsetki - zaspokojona część kwoty głównej" value="interestsSumFulfilledUncovered" />
        <ExcelColumn label="Roszczenie o odsetki od zaspokojonej części kwoty głównej" value="isClaimFromFulfilled" />

      </ExcelSheet>
      {_.isEmpty(paymentFormattedData) ? null : (
        <ExcelSheet
          data={paymentFormattedData}
          name="Wpłaty"
        >
          <ExcelColumn label="Data wpłaty" value="paymentDate" />
          <ExcelColumn label="Kwota wpłaty" value="paymentSum" />
          <ExcelColumn label="Tytuł wpłaty" value="transferDescription" />
          <ExcelColumn label="Data oświadczenia" value="statementDate" />
        </ExcelSheet>
      )}
      {interestsFormattedData.map((result, index) => {
        return (
          <ExcelSheet
            key={result.key}
            data={result.interestData}
            name={`Odsetki - nr ${index + 1}`}
          >
            <ExcelColumn label="Data początkowa" value="periodStart" />
            <ExcelColumn label="Data końcowa" value="periodEnd" />
            <ExcelColumn label="Ilość dni" value="days" />
            <ExcelColumn label="Wysokość odsetek" value="interestRate" />
            <ExcelColumn label="Kwota" value="periodSum" />
          </ExcelSheet>
        );
      })}
      {compensationFormattedData.map((result, index) => {
        return (
          <ExcelSheet
            key={result.key}
            data={result.compensationData}
            name={`Rekompensata - nr ${index + 1}`}
          >
            <ExcelColumn label="Wartość rekompensaty w Euro" value="valueEuro" />
            <ExcelColumn label="Dzień ustalenia kursu" value="lastWorkingDay" />
            <ExcelColumn label="Średni kurs euro" value="exchangeRate" />
            <ExcelColumn label="Wartość rekompensaty w PLN" value="valuePLN" />
          </ExcelSheet>
        );
      })}
    </ExcelFile>
  );
};



export default DetailsExcel;
