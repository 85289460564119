import React from 'react';
import { AboutContainer } from '../../styles';
import { BLUE } from '../../styles/colors';
import SubCurrency from './SubBenefits/SubCurrency';
import SubExcel from './SubBenefits/SubExcel';
import SubRoadMap from './SubBenefits/SubRoadMap';
import SubTraining from './SubBenefits/SubTraining';
import SubSection from './SubSection';

const SubBenefits = () => {
  return (
    <AboutContainer>
      <SubSection
        title="Jakie są Twoje korzyści ?"
        id="benefits"
        desc={(
          <ul>
            <SubExcel />
            <SubCurrency />
            <SubTraining />
            <SubRoadMap />
          </ul>
          )}
        color={BLUE}
      />
    </AboutContainer>
  );
};

export default SubBenefits;
