import { Button, Table, Typography } from 'antd';
import { onSnapshot } from 'firebase/firestore';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import { auth } from '../../services/firebase/auth';
import { calculationsQuery } from '../../services/firebase/firestoreRefs';
import { useTypedSelector } from '../../store';
import { RowExt } from '../../styles';
import { formatSaveTableData, SaveTableRecord } from '../Dashboard/DashboardLsTable';
import useListenToCalculationsCount from '../../utils/hooks/useListenToCalculationsCount';


export type OverSaveData = {
  recordName: string;
  id: string;
};

const SaveTable = (
  {
    isOverSave,
    setIsOverSave,
    setOverSaveData
  }: {
    isOverSave: boolean,
    setIsOverSave: (isOverSave: boolean) => void,
    setOverSaveData: (overSaveData: OverSaveData) => void
  }
) => {
  const [data, setData] = useState<SaveTableRecord[] | null>(null);
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(10);

  const userExists = useTypedSelector(state =>
    state.user.isNonAnonymousUserLoggedIn);


  const calcCount = useListenToCalculationsCount();

  useEffect(() => {
    const { currentUser } = auth;
    if (currentUser && userExists){
      const unsubscribe = onSnapshot(calculationsQuery(currentUser.uid, 'desc', limit), (snapshot) => {
        if (!snapshot.empty){
          const saveTable = formatSaveTableData(snapshot);
          setLoading(false);
          setData(saveTable);
        } else {
          setLoading(false);
        }
      });
      return () => {
        unsubscribe();
      };
    }
    return undefined;
  }, [limit, userExists]);

  const onOverSavePress = (id:string, recordName:string) => {
    setOverSaveData({
      id, recordName
    });
    setIsOverSave(true);
  };

  const columns = [
    {
      title: 'Data ostatniej edycji',
      dataIndex: 'editDate',
      key: 'editDate',
      defaultSortOrder: 'descend',
      render:(value: firebase.firestore.Timestamp) => {
        return (
          <Typography.Text>
            {moment(value.toDate()).format('YYYY-MM-DD hh:mm')}
          </Typography.Text>
        );
      },
    },
    {
      title: 'Nazwa',
      dataIndex: 'recordName',
      key: 'recordName',
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      align: 'right',
      render: (record:SaveTableRecord) => {
        return (
          <Button
            type="link"
            onClick={() =>
              onOverSavePress(record.id, record.recordName)}
          >
            Nadpisz
          </Button>
        );
      }
    },
  ];


  if (loading && userExists){
    return (
      <RowExt paddingTop={48} paddingBottom={48} align="middle" justify="center">
        <div className="lds-ellipsis-big">
          <div />
          <div />
          <div />
          <div />
        </div>
      </RowExt>
    );
  }


  if (userExists && !isOverSave && data){
    return (
      <>
        <Typography.Title level={5}>Zapisy</Typography.Title>
        <Table
          size="middle"
          dataSource={data}
        // @ts-ignore
          columns={columns}
          showHeader={false}
          pagination={{
            total: calcCount,
            onChange: (page) => {
              setLimit(page * 10);
            }
          }}
        />
      </>
    );
  }
  return null;
};

export default SaveTable;


