import { COMPENSATION_BASIS } from '../utils/helpers/lawBasis';

export const O_OPOZNINIE =
	'art. 481 §2 Kodeksu cywilnego  "Jeżeli stopa odsetek za opóźnienie nie była oznaczona, należą się odsetki ustawowe za opóźnienie w wysokości równej sumie stopy referencyjnej Narodowego Banku Polskiego i 5,5 punktów procentowych."';
export const O_USTAWOWE =
	'art. 359 § 2 Kodeksu cywilnego "Jeżeli wysokość odsetek nie jest w inny sposób określona, należą się odsetki ustawowe w wysokości równej sumie stopy referencyjnej Narodowego Banku Polskiego i 3,5 punktów procentowych."';
export const O_HANDLOWE =
	'art. 11b Ustawy o przeciwdziałaniu nadmiernym opóźnieniom w transakcjach handlowych "Do ustalenia wysokości odsetek ustawowych za opóźnienie w transakcjach handlowych stosuje się stopę referencyjną Narodowego Banku Polskiego obowiązującą w dniu1) 1 stycznia - do odsetek należnych za okres od dnia 1 stycznia do dnia 30 czerwca; 2) 1 lipca - do odsetek należnych za okres od dnia 1 lipca do dnia 31 grudnia." ';
export const O_PUBLIC_MED =
	'art. 4 pkt 3. Ustawy o przeciwdziałaniu nadmiernym opóźnieniom w transakcjach handlowych odsetki ustawowe za opóźnienie w transakcjach handlowych: a) w przypadku transakcji handlowych, w których dłużnikiem jest podmiot publiczny będący podmiotem leczniczym – odsetki w wysokości równej sumie stopy referencyjnej Narodowego Banku Polskiego i ośmiu punktów procentowych," ';
export const O_MED = `4 ust 3 pkt b) ${COMPENSATION_BASIS}: "W przypadku transakcji handlowych, w których dłużnikiem nie jest podmiot publiczny będący podmiotem leczniczym – odsetki w wysokości równej sumie stopy referencyjnej Narodowego Banku Polskiego i dziesięciu punktów procentowych"`;
export const OKRES_ODSETEK =
	'Co do zasady: od dnia następującego po terminie spełnienia świadczenia do dnia zapłaty/wniesienia pozwu.';
export const KWOTA_SKŁDAOWA = 'Kwota roszczenia od którego naliczane będą odsetki.';
export const WYMAGALNOSC =
	'Por. art. 115 KC "Jeżeli koniec terminu do wykonania czynności przypada na dzień uznany ustawowo za wolny od pracy lub na sobotę, termin upływa następnego dnia, który nie jest dniem wolnym od pracy ani sobotą"';
export const TYTUL =
	'np: "z tytułu roszczenia o zaliczkę na zarządzanie nieruchomością wspólną za miesiąc marzec 2021"';
export const PODSTAWA =
	'Podstawa podstawa roszczenia - wymóg formalny pozwu.';
