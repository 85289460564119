import { Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { TitleAndDesc } from '../../@types';
import { TitleDivider } from '../../styles';

const AbtTitle = (
  {
    title,
    desc
  }: TitleAndDesc
) => {
  return (
    <>
      <TitleDivider />
      <Title
        level={2}
      >
        {title}
      </Title>
      <Typography.Text>
        {desc}
      </Typography.Text>
    </>
  );
};

export default AbtTitle;

const Title = styled(Typography.Title)`
   margin-top: 48px;
   margin-bottom: 24px;
`;
