import { Col, Row, Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { TitleAndDesc } from '../../@types';
import { DashedColLeft, RowBg } from '../../styles';

const SubSection = (
  {
    title,
    desc,
    color,
    id
  }: TitleAndDesc & {
    color: string,
    id?: string
  }
) => {
  return (
    <Row
      id={id}
    >
      <Col
        lg={{
          span: 6,
          offset: 2
        }}
        md={{
          offset: 2,
          span: 8
        }}
        xs={{
          offset: 2,
          span: 20,
        }}
      >
        <TitleWrapper
          color={color}
        >
          <Col span={18} offset={2}>
            <div>
              <Typography.Title
                level={3}
              >
                {title}
              </Typography.Title>
            </div>
          </Col>
        </TitleWrapper>
      </Col>
      <Col
        lg={{
          offset: 0,
          span: 14,
        }}
        md={{
          offset: 0,
          span: 12,
        }}
        xs={{
          offset: 2,
          span: 20,
        }}
      >
        <RowBg>
          <Col span={20} offset={2}>
            <Typography.Text>
              {desc}
            </Typography.Text>
          </Col>
        </RowBg>
      </Col>
      <Col
        xl={1}
        xs={0}
      />
      <DashedColLeft
        xl={1}
        xs={0}
      />
    </Row>
  );
};

export default SubSection;

const TitleWrapper = styled(Row)<{ color?: string }>`
    border-left: 1px solid ${({ color }) =>
    color};
    margin-top: 52px;
`;