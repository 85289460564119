import { Col, Tag, Typography } from 'antd';
import React, { ReactNode } from 'react';
import { TitleAndDesc } from '../../@types';
import { BLUE, DashedColLeft, RowExt } from '../../styles';
import LawBorders from '../Law/LawBorders';
import RoadMapTittle from '../Law/RoadMapTittle';
import LawContainer from '../UI/Containers/LawContainer';

const RoadMapPar = (
  {
    children,
    title,
    desc,
    done,
    par,
    sub,
    bColor
  }: TitleAndDesc &   {
    children?: ReactNode,
    done?: boolean,
    par: string,
    sub?:boolean,
    bColor?: string
  }
) => {
  return (
    <>
      <LawContainer>
        <RoadMapTittle
          title={title}
          desc={desc}
          done={done}
          bColor={bColor}
        />
        <RowExt>
          <DashedColLeft
            lg={{
              offset:0,
              span:1,
            }}
            xs={{
              offset: 1,
              span: 1,
            }}
          />
          <Col
            lg={{ span: 21 }}
            xs={{ span: 20 }}
          >
            <Typography.Paragraph>
              {par}
            </Typography.Paragraph>
            {sub ? (
              <Typography.Paragraph>
                <Tag style={{
                  color: BLUE, fontWeight: 800, borderColor: BLUE
                }}
                >
                  S
                </Tag>
                {' '}
                <strong>Wymaga subskrypcji</strong>
              </Typography.Paragraph>
            ) : null}
          </Col>
        </RowExt>
        {children}
      </LawContainer>
      <LawBorders />
    </>
  );
};

export default RoadMapPar;
