import { ArrowRightOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Radio, Space, Typography } from 'antd';
import React from 'react';
import { shallowEqual } from 'react-redux';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import { LocationState } from '../../../@types';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { currencyDateChanged } from '../../../store/currency';
import { RowExt } from '../../../styles';
import { disabledDateCurrency, disabledDateCurrencyOutsideLawsuit } from '../../../utils/helpers/date';


const { Title, Paragraph } = Typography;

const CurrencyDate = () => {
  const dispatch = useAppDispatch();

  const location = useLocation<LocationState>();
  const isScreen = location.pathname === '/currency';

  const isSubscriber = useTypedSelector(state =>
    state.user.isSubscriber);

  const convertDate = useTypedSelector(state =>
    state.currency.convertDate);

  const setCurrencyDate = (value:moment.Moment | null) => {
    dispatch(currencyDateChanged(value));
  };

  const { maturityDate, startingDate } = useTypedSelector(state =>
    state.interestForm, shallowEqual);

  const {
    maturityDate: editMaturityDate,
    startingDate: editStartingDate,
  } = useTypedSelector(state =>
    state.edit, shallowEqual);

  const  editKey = useTypedSelector(state =>
    state.edit.key);

  const copyDate = (md:boolean) => {
    if (md){
      dispatch(currencyDateChanged(editKey ? editMaturityDate :  maturityDate));
    } else {
      dispatch(currencyDateChanged(editKey ? editStartingDate : startingDate));
    }
  };

  return (
    <>
      <Title level={4}>Data przeliczenia</Title>
      <Paragraph>{isScreen ? 'Wpisz datę' : 'Wpisz datę lub skopiuj termin spełnienia świadczenia lub datę początkową (jeśli zostały uzupełnione).'}</Paragraph>
      <RowExt>
        <Col md={{ span:8 }} xs={{ span: 24 }}>
          <RowExt>
            <DatePicker
              allowClear
              disabled={!isSubscriber}
              format="DD-MM-YYYY"
              style={{ width:'100%' }}
              onChange={setCurrencyDate}
              value={convertDate}
              disabledDate={isScreen ? disabledDateCurrencyOutsideLawsuit : disabledDateCurrency}
            />
          </RowExt>
        </Col>
        <Col md={{ span: 2 }} xs={{ span:0 }} />
        <OptsCol md={{ span: 14 }} xs={{ span:24 }}>
          {isScreen ? null : (
            <Space>
              Użyj
              {' '}
              <ArrowRightOutlined />
              {' '}
              <Button
                type="dashed"
                disabled={editKey ? !editMaturityDate : !maturityDate}
                onClick={() =>
                  copyDate(true)}
              >
                Terminu spełnienia świadczenia
              </Button>
              <Button
                type="dashed"
                disabled={editKey ? !editStartingDate : !startingDate}
                onClick={() =>
                  copyDate(false)}
              >
                Daty początkowej
              </Button>
            </Space>
          )}
        </OptsCol>
      </RowExt>
    </>
  );
};

export default CurrencyDate;

export const CurrencyDateButton = styled(Radio.Button)`
  margin-right: 12px;
 `;

const OptsCol = styled(Col)`
  @media only isScreen and (max-width: 767px) {
    margin-top: 24px;
	}
  
`;