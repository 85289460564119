import { Moment } from 'moment';

import { getInterestDesc } from '../../../services/calc/interestDesc';
import { InterestTypes } from '../../../store/lawsuitOpts';
import { arrStrFormat } from '../../helpers/arrStrFormat';
import { formatDate } from '../../helpers/date';
import { formatPLN } from '../../helpers/formatNumber';
import { getResultNumber } from '../../helpers/getResultNumber';
import { textIf, textIfPad, textIfPadConvert } from '../../helpers/textIf';


export const setNoCapInt = ({
  partialSum,
  startingDate,
  endDate,
  justToPaymentDay,
  typeOfInterest,
  noCap,
}:{
  partialSum: number,
  startingDate: Moment;
  endDate: Moment;
  justToPaymentDay: boolean,
  typeOfInterest: InterestTypes,
  noCap: boolean
}
) => {
  const endText = justToPaymentDay ? ' do dnia zapłaty.' : ` do dnia ${formatDate(endDate)}.`;
  if (noCap) return ` W związku z opóźnieniem w spełnieniu świadczenia ${getInterestDesc(typeOfInterest).name} od kwoty ${formatPLN(partialSum)} przysługują za okres od ${formatDate(startingDate)}${endText} `;
  return '';
};

export const descAccDoc = (accountingDoc: string | null) =>
  accountingDoc ? ` (${accountingDoc})` : '';

export const descOnlyLegal = ({ partialSum, legalBasis }:{
  partialSum: number, legalBasis?: string | null
}) =>
  `Podstawę prawną roszczenia w wysokości ${formatPLN(partialSum)} stanowi ${legalBasis}.`;

export const descLegal = (legalBasis: string | null) =>
  `Podstawę stanowi roszczenia ${legalBasis}`;


export const setMaturityBasisText = ({
  partialSum,
  claimSum,
  maturityDate,
  legalBasis,
  factualBasis,
  accountingDoc,
  index,
  isSingle,
}:{
  partialSum: number,
  claimSum?: number,
  maturityDate: Moment | string | Date | null,
  legalBasis?: string | null,
  factualBasis: string | null,
  accountingDoc: string | null,
  index:number,
  isSingle:boolean,
}) => {

  const { resultNumber } = getResultNumber(index);

  const pluralResults = !isSingle;

  const onlyLegalBasis = legalBasis && !maturityDate && !factualBasis;

  const onlyAccountingDoc = accountingDoc && !factualBasis && !legalBasis;

  const parNumber = textIf(`${resultNumber}) `, pluralResults);

  const orderNumberText = textIf(`(zwane dalej roszczeniem nr ${resultNumber}) `, pluralResults);

  const claimText = `Roszczenie ${orderNumberText} w wysokości ${formatPLN(claimSum ?? partialSum)}`;

  const maturityText = textIfPadConvert({
    text: 'stało się wymagalne dnia',
    convertFunc: formatDate,
    arg: maturityDate,
    textEnd: textIf('', !factualBasis && !legalBasis)
  });

  const basisText = textIf(`, a jego podstawę prawną stanowi ${legalBasis}`, legalBasis);

  const factualCon = textIfPad('i', maturityDate && factualBasis);

  const factualText = textIfPad(`jest dochodzone z tytułu ${factualBasis}`, factualBasis);

  const dot = '.';

  const accText = textIfPad(`Zostało udokumentowane jako: ${accountingDoc}.`, accountingDoc);

  if (onlyLegalBasis){
    return parNumber + descOnlyLegal({
      partialSum: claimSum ?? partialSum,
      legalBasis,
    }) + dot + accText;
  }
  if (onlyAccountingDoc){
    return `${parNumber + claimText + maturityText + dot + accText}`;
  }
  return arrStrFormat([
    parNumber,
    claimText,
    maturityText,
    factualCon,
    factualText,
    basisText,
    dot,
    accText,
  ]);
};



