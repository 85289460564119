import { CheckSquareOutlined, CloseSquareOutlined } from '@ant-design/icons';
import { Col, Divider, Modal, Typography } from 'antd';
import React from 'react';

import { BLUE, ButtonFullWidth, RowExt } from '../../../../styles';

const ClaimAllFulfilledIModal = ({
  closeModal,
  isModalVisible,
  onOn,
  onOff,
}:{
  onOn: () => void,
  onOff: () => void,
  closeModal: () => void,
  isModalVisible:boolean,
}) => {
  return (
    <Modal
      onCancel={closeModal}
      visible={isModalVisible}
      destroyOnClose
      style={{ background: 'none !important' }}
      footer={false}
    >
      <RowExt marginTop="md">
        <Typography.Title level={5}>
          Naliczać dla wszystkich długów odsetki od zaspokojonych części kwot głównych?
        </Typography.Title>
      </RowExt>
      <RowExt align="middle" justify="center" marginTop="md">
        <Col span={11}>
          <ButtonFullWidth
            icon={(
              <CloseSquareOutlined />
)}
            type="text"
            onClick={onOff}
          />
        </Col>
        <Col span={2}>
          <Divider
            type='vertical'
          />
        </Col>
        <Col span={11}>
          <ButtonFullWidth
            icon={(
              <CheckSquareOutlined
                style={{ color: BLUE }}
              />
)}
            type="text"
            onClick={onOn}
          />
        </Col>
      </RowExt>
    </Modal>
  );
};

export default ClaimAllFulfilledIModal;