import { Col, Row } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { AboutContainer, BLUE, BLUE_MEDIUM, DashedColLeft } from '../../styles';
import { BLACK, RED } from '../../styles/colors';
import Icon from '../../styles/Icon';
import { BALANCE, CLOCK, MONEY_PACK, RELOAD } from '../../styles/iconTypes';
import AbtTitle from '../About/AbtTitle';
import FaqParagraph from '../About/FAQ/FaqParagraph';

const IntroWhy = () => {
  return (
    <AboutContainer>
      <Row id="benefits">
        <Col
          lg={{
            offset: 1,
            span: 5,
          }}
          md={{
            offset: 1,
            span: 7
          }}
          xs={{
            offset: 2,
            span: 20,
          }}
        >
          <AbtTitle
            title="Co zyskasz ?"
            desc={(
              <>
                <strong>wdrażając </strong>
                {' '}
                pozew.app w Twoim zespole
              </>
)}
          />
        </Col>
        <TextCol
          lg={{ span: 14 }}
          md={16}
          xs={{ span: 24 }}
        >
          <FaqParagraph
            title={(
              <>
                <Icon
                  iconType={CLOCK}
                  height="20"
                  width="20"
                  fill={BLACK}
                  style={{ marginRight:12 }}
                />
                MINUTY PRACY ZAMIAST GODZIN
              </>
              )}
            desc="Zamiast spędzać czas na wyliczeniach, na ich przekle janiu, sprawdzaniu poświęć czas na czynności zawodowe przynoszące Ci zysk."
            color={RED}
          />
          <FaqParagraph
            title={(
              <>
                <Icon
                  iconType={BALANCE}
                  height="20"
                  width="20"
                  fill={BLACK}
                  style={{ marginRight:12 }}
                />
                PRZYSPIESZ WYDAWANIE ORZECZEŃ - KODY WYLICZEŃ
              </>
              )}
            desc={(
              <>
                Wesprzyj przyspieszenie wydawania orzeczeń w Twojej sprawie.
                {' '}
                <Link to="about#codes">
                  <a href="about#codes">Dowiedz się więcej</a>
                </Link>
                .
              </>
)}
            color={BLUE_MEDIUM}
          />
          <FaqParagraph
            title={(
              <>
                <Icon
                  iconType={MONEY_PACK}
                  height="20"
                  width="20"
                  fill={BLACK}
                  style={{ marginRight:12 }}
                />
                KORZYŚĆ DLA CIEBIE I TWOJEGO KLIENTA
              </>
              )}
            desc="Większość pełnomocników nie kapitalizuje odsetek, gdyż jest to bardzo uciążliwe. Pozew.app pozwala na bezbolesne robienie tego w stosunku do każdego roszczenia. Klient na tym skorzysta, a Ty na jego lepszej opinii o Tobie."
            color={BLUE}
          />
          <FaqParagraph
            title={(
              <>
                <Icon
                  iconType={RELOAD}
                  height="20"
                  width="20"
                  fill={BLACK}
                  style={{ marginRight:12 }}
                />
                ZAWSZE WRÓĆ DO WYLICZEŃ
              </>
              )}
            desc="Przechowuj swoje dane wyliczeń na Twoim koncie i miej błyskawiczną możliwość ich zmiany. Nie dopuszczaj do sytuacji, gdy np. składając załączniki do pozwu zorientowałeś się, że źle przepisałeś kwoty i wszystkie wyliczenia musisz wykonać na nowo, a zapisy poprawić."
            color={RED}
          />
        </TextCol>
        <Col
          xl={{ span: 2 }}
          lg={{ span: 1 }}
          xs={0}
        />
        <DashedColLeft
          xl={{ span: 2 }}
          lg={{ span: 3 }}
          xs={0}
        />
      </Row>
    </AboutContainer>
  );
};

export default IntroWhy;

const TextCol = styled(Col)`
  padding-top: 54px;
  padding-bottom: 30px;
`;
