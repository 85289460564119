import { Radio, Space, Typography } from 'antd';
import React from 'react';
import { shallowEqual } from 'react-redux';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import { JUST_DEM_PAY, withCompUpdated, withCompWPSUpdated } from '../../../../store/justOpts';
import { RowExt } from '../../../../styles';
import { JustRadioButton } from '../../../../styles/ui';
import AnimOpacity from '../../../UI/Anim/AnimOpacity';
import { RadioButtonResp, RadioDescText } from './JustIntCap';


const JustComp = () => {
  const dispatch = useAppDispatch();

  const compensationOn = useTypedSelector(state =>
    state.sumUp.lawsuitOpts.compensationOn);

  const {
    withComp,
    withCompWPS,
    justType,
  } = useTypedSelector(state =>
    state.justOpts, shallowEqual);

  if (compensationOn){
    return (
      <>
        <RowExt marginTop="md">
          <Typography.Title level={4}>
            Uwzględnij rekompensaty
          </Typography.Title>
        </RowExt>
        <RowExt marginTop="sm" align="middle">
          <Space>
            <Radio.Group
              value={withComp}
              size="middle"
              onChange={
                 (event) => {
                   dispatch(withCompUpdated(event.target.value));
                 }
               }
            >
              <JustRadioButton value={true}>TAK</JustRadioButton>
              <JustRadioButton value={false}>NIE</JustRadioButton>
            </Radio.Group>
          </Space>
        </RowExt>
        {withComp && justType !== JUST_DEM_PAY  ? (
          <AnimOpacity>
            <RowExt align="middle" marginTop={24}>
              <RadioDescText>
                uwzględnij w kwocie głównej
              </RadioDescText>
              <Radio.Group
                value={withCompWPS}
                size="middle"
                onChange={
                 (event) => {
                   dispatch(withCompWPSUpdated(event.target.value));
                 }
               }
              >
                <RadioButtonResp value={true}>TAK</RadioButtonResp>
                <JustRadioButton value={false}>NIE</JustRadioButton>
              </Radio.Group>
            </RowExt>
          </AnimOpacity>
        ) :
          null}
      </>
    );
  }
  return null;

};

export default JustComp;
