import { Card, Typography } from 'antd';
import Meta from 'antd/lib/card/Meta';
import React from 'react';

import { readExcelPayment } from '../../../../services/importExPayments';
import { useAppDispatch } from '../../../../store';
import { ButtonFullWidth } from '../../../../styles';

const { Title } = Typography;


const ImportExPayments = () => {
  const dispatch = useAppDispatch();

  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (hiddenFileInput.current && hiddenFileInput) {
      hiddenFileInput.current.click();
    }
  };

  return (
    <>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={(event) =>
          readExcelPayment(event, dispatch)}
        style={{ display: 'none' }}
      />
      <ButtonFullWidth
        type="primary"
        onClick={handleClick}
      >
        Wczytaj plik excel (xlsx)
      </ButtonFullWidth>
      <Card
        title="O jaki plik excel poprosić księgowość ?"
        style={{ marginTop: 24 }}
      >
        <Meta
          description={(
            <>
              Plik w formacie
              {' '}
              <strong>xlsx (Excel)</strong>
              . Dla
              {' '}
              <strong>rozwiania wątpliwości</strong>
              {' '}
              sugerujemy przesłać księgowej/księgowemu przykładowy plik i poniższą instrukcję.
              {' '}
              <br />
              Kolumny w
              {' '}
              <strong>pierwszym arkuszu</strong>
              {' '}
              powinny zostać uzupełnione następująco:
              <ul>
                <li>
                  <strong>pierwsza</strong>
                  {' '}
                  kolumna powinna zawierać
                  {' '}
                  <strong>terminy wpłat </strong>
                </li>
                <li>
                  <strong>druga</strong>
                  {' '}
                  skorelowane z terminami
                  {' '}
                  <strong>kwoty wpłat </strong>
                </li>
                <li>kolumny nie powinny mieć tytułów</li>
              </ul>
              <Title level={5}>Opcjonalnie kolumny</Title>
              <ul>
                <li>
                  <strong>trzecia</strong>
                  {' '}
                  kolumna powinna zawierać
                  {' '}
                  <strong>tytuły wpłat</strong>
                </li>
              </ul>
            </>
            )}
        />
      </Card>
    </>
  );
};

export default ImportExPayments;