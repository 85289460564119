import { getFunctions, httpsCallable } from 'firebase/functions';
import app from '../../_firebase';

export const firebaseFunctions = getFunctions(app);

export const addSubCode = httpsCallable(firebaseFunctions, 'subscriptionCodeAdd');
export const receiverRemoveFromSub = httpsCallable(firebaseFunctions, 'receiverRemoveFromSubscription');
export const giverRemoveFromSub = httpsCallable(firebaseFunctions, 'giverRemoveFromSubscription');
export const setInboxUser = httpsCallable(firebaseFunctions, 'setInboxUser');
export const sendToInbox = httpsCallable(firebaseFunctions, 'sendToInbox');
export const sendCategorizeDoc = httpsCallable(firebaseFunctions, 'sendCategorizeDoc');
export const sendAutoAssignTd = httpsCallable(firebaseFunctions, 'sendAutoAssignTd');
