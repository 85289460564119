import { Col } from 'antd';
import React, { useEffect } from 'react';
import ResultActionsLsLocal from '../components/ResultActions/ResultActionsLsLocal';
import SumUpData from '../components/SumUp/SumUpData';
import AnimOpacity from '../components/UI/Anim/AnimOpacity';
import ResultContainer from '../components/UI/Containers/ResultContainer';
import DotsEnding from '../components/UI/DotsEnding';
import NavPrivate from '../components/UI/Nav/NavPrivate';
import useGetResultLsLocal from '../utils/hooks/useGetResultLsLocal';
import { logToAnalytics } from '../services/firebase/logToAnalytics';
import { RowExt } from '../styles';


const ResultLsLocal = () => {
  useGetResultLsLocal();
  useEffect(() => {
    logToAnalytics('ResultLsLocal_loaded');
  }, []);
  return (
    <ResultContainer>
      <NavPrivate />
      <AnimOpacity>
        <RowExt marginTop="bg">
          <Col
            lg={{
              offset:0,
              span:24,
            }}
            xs={{
              span: 20, offset:2
            }}
          >
            <SumUpData
              title="Zapis wyliczeń"
              desc="Sprawdź dane/edytuj"
            >
              <ResultActionsLsLocal />
            </SumUpData>
            <DotsEnding />
          </Col>
        </RowExt>
      </AnimOpacity>
    </ResultContainer>
  );
};

export default ResultLsLocal;