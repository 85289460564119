import { motion } from 'framer-motion';
import React from 'react';

import useAreStepsActionsHidden from '../../../utils/hooks/useAreStepsActionsHidden';
import ActionsBar from './StepActions/ActionsBar';
import SaveBar from './StepActions/SaveBar';



const StepActions = () => {

  const areStepsActionsHidden = useAreStepsActionsHidden();

  if (areStepsActionsHidden){
    return null;
  }

  return (
    <>
      <motion.div
        transition={{ duration: 1 }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        <SaveBar />
        <ActionsBar />
      </motion.div>
    </>
  );
};

export default React.memo(StepActions);
