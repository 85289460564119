import { Col, Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { TitleAndDesc } from '../../@types';
import { BLUE, DashedColLeft, RowExt } from '../../styles';

const PrivacyTittle = (
  {
    title,
    desc
  }: TitleAndDesc
) => {
  return (
    <>
      <RowExt>
        <BorderLeft
          lg={{
            offset:0,
            span:1,
          }}
          xs={{
            offset: 1,
            span: 1,
          }}
        />
        <Col
          lg={{ span:21 }}
          xs={{ span: 20 }}
        >
          <Typography.Title
            level={4}
            style={{ marginTop:12 }}
          >
            {title}
          </Typography.Title>
        </Col>
      </RowExt>
      <RowExt>
        <DashedColLeft
          lg={{
            offset:0,
            span:1,
          }}
          xs={{
            offset: 1,
            span: 1,
          }}
        />
        <Col
          lg={{ span:21 }}
          xs={{ span: 20 }}
        >
          <Typography.Title level={5} type='secondary'>{desc}</Typography.Title>
        </Col>
      </RowExt>
    </>
  );
};

export default PrivacyTittle;

const BorderLeft = styled(Col)`
  border-left: 1px solid ${BLUE};
`;
