import { OrderedListOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import React from 'react';
import nextId from 'react-id-generator';

import { RowMd } from '../../../../styles';
import { RowWithWarnings } from '../../../../utils/hooks/useImpExcelValidate';
import ImpExcelItem from './ImpExcelItem';


const ImpExcelList = (
  { rows }:{
    rows: RowWithWarnings[]
  }) => {

  return (
    <>
      <RowMd />
      <Card
        title="Lista importowanych danych"
        bordered={false}
        extra={(
          <OrderedListOutlined />
)}
      >
        {rows.map((row, index) =>
          (
            <ImpExcelItem
              key={nextId()}
              {...{
                row,
                index
              }}
            />
          ))}
      </Card>
    </>
  );
};

export default ImpExcelList;