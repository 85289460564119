import { Col } from 'antd';
import React from 'react';
import { useParams } from 'react-router';
import ResultLsCodeActions from '../components/ResultActions/ResultLsCodeActions';
import SumUpData from '../components/SumUp/SumUpData';
import AnimOpacity from '../components/UI/Anim/AnimOpacity';
import ResultContainer from '../components/UI/Containers/ResultContainer';
import DotsEnding from '../components/UI/DotsEnding';
import NavPrivate from '../components/UI/Nav/NavPrivate';
import useGetResultLsCode from '../utils/hooks/useGetResultLsCode';
import { RowExt } from '../styles';

const ResultLsCode = () => {
  const params = useParams<{
    id?: string,
  }>();
  const { createDate } = useGetResultLsCode(params?.id ?? '');

  return (
    <ResultContainer>
      <NavPrivate />
      <AnimOpacity>
        <RowExt marginTop="bg">
          <Col
            lg={{
              offset:0,
              span:24,
            }}
            xs={{
              span: 20, offset:2
            }}
          >
            <SumUpData
              title={`KOD WYLICZEŃ: ${  params?.id}`}
              desc={`Data utworzenia: ${createDate}`}
            >
              <ResultLsCodeActions />
            </SumUpData>
            <DotsEnding />
          </Col>
        </RowExt>
      </AnimOpacity>
    </ResultContainer>
  );
};

export default ResultLsCode;


