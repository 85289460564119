import { Col, Modal, Space } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';
import preview from '../../../assets/previewMobile.png';
import useWindowSize from '../../../utils/hooks/useWindowSize';
import { EmptyButton, GREY_WHITE, GRID_LG, GRID_LG_PX, GRID_MD, GRID_MD_PX, GRID_SM, GRID_SM_PX, LinkFullWidth, RowExt } from '../../../styles';
import Icon from '../../../styles/Icon';
import { Player } from '../../About/Video/AboutVideo';
import VideoPlaceholder from '../../About/Video/VideoPlaceholder';
import { SloganDesc } from './SloganDesc';
import { ColResp } from './SloganHeader';


const SloganHeaderMobile = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { width } = useWindowSize();
  const videoSize = () => {
    if (width >= GRID_LG) return {
      width: 960, height: 540
    };
    if (width >= GRID_MD) return {
      width: 720, height: 405
    };
    if (width >= GRID_SM) return {
      width: 480, height: 270
    };
    return {
      width: 320, height: '100%'
    };
  };
  return (
    <>
      <VideoCol>
        <VideoPlaceholder
          showVideo={() =>
            setIsModalVisible(true)}
          src={preview}
        />
      </VideoCol>
      <Container>
        <ColResp>
          <MainText>
            {texts.head1}
            <br />
            {texts.head2}
          </MainText>
        </ColResp>
        <ColResp>
          <SloganDesc />
        </ColResp>
        <RowExt
          fullWidth
          marginTop="md"
          align="middle"
        >
          <Col
            lg={{
              offset: 2, span: 14
            }}
            md={{
              offset: 2, span: 5
            }}
            sm={{
              offset: 2, span: 6
            }}
            xs={{
              offset: 2, span: 20
            }}
          >
            <Space>
              <LinkFullWidth
                to="/about"
              >
                <EmptyButton
                  bgColor={GREY_WHITE}
                >
                  {texts.button}
                </EmptyButton>
              </LinkFullWidth>
              {/* <Link
                to={{ pathname: '/trusted' }}
              >
                <Button type="link">
                  zaufali nam
                </Button>
              </Link> */}
            </Space>
          </Col>
        </RowExt>
      </Container>
      <Modal
        onCancel={() =>
          setIsModalVisible(false)}
        visible={isModalVisible}
        footer={false}
        title={(
          <Icon
            height="18"
            width="18"
            fill="white"
            iconType="PLAY"
          />
)}
        destroyOnClose
        width={videoSize().width + 40}
        style={{ background: 'none !important' }}
      >
        <Player
          url='https://firebasestorage.googleapis.com/v0/b/devpozew.appspot.com/o/PozewApp_10.mp4?alt=media&token=c4793420-15d3-4550-9a81-d149f5c42774'
          volume={0.5}
          playing={isModalVisible}
          height={videoSize().height}
          width={videoSize().width}
          controls
        />
      </Modal>
    </>
  );
};

export default SloganHeaderMobile;

const Container = styled.div`
    @media only screen and (min-width: ${GRID_LG_PX}) {
      height: 100%;
    }
    @media only screen and (max-width: ${GRID_MD_PX}) {
      padding: 24px 0;
    }
    background-color: ${GREY_WHITE};
    justify-content: center;
    width: 100%;
    display: flex;
    flex-direction: column;
`;


export const MainText = styled.h2`
   font-size: 28px;
   font-weight: 600;
   @media only screen and (max-width: ${GRID_SM_PX}) {
      font-size: 22px;
    }
`;

const VideoCol = styled(Col)`
  margin-top: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 0;
`;

const texts = {
  head1: 'Więcej czasu na argumentację,',
  head2: 'automatyzuj uciążliwości.',
  button: 'Dowiedz się więcej'
};