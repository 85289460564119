import _ from 'lodash';
import moment from 'moment';

import { AppDispatch, RootState } from '../store';
import { InterestData, InterestItem, PaymentData, paymentDataChanged, PaymentItem } from '../store/interestStorage';
import { LawsuitOpts } from '../store/lawsuitOpts';
import { assignmentDate, calcFulfilled, claimCalc, ClaimItem, getEndDateFromPaymentDate, isPaymentFullyAssigned } from './calc/claim';
import { calcOneInterest } from './calc/interestsCalc';

export const assignPaymentByKey = ({
  paymentItemKey,
  interestItemKey,
  isInterestFirst,
  assignmentSum,
}:{
  paymentItemKey: string,
  interestItemKey: string,
  isInterestFirst: boolean,
  assignmentSum?:number
}) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();

    const { paymentData, interestData } = state.interestStorage;

    const { lawsuitOpts } = state;

    const interestItemIndex = getIndexFromKey(interestData, interestItemKey);

    const paymentItemIndex = getIndexFromKey(paymentData, paymentItemKey);

    const assignedPayments = paymentData.filter(paymentItem =>
      paymentItem.assignedTo.some(({ key }) =>
        key === interestItemKey));

    // sort by date - assignments should be done in ascending order - art. 451 KC
    const sortedPayments = _.cloneDeep(assignedPayments).sort((itemA, itemB) =>
      moment(assignmentDate(itemA)).toDate().getTime() - moment(assignmentDate(itemB)).toDate().getTime()
    );

    const lastPayment = _.last(sortedPayments);

    checkPerquisites({
      interestData,
      interestItemIndex,
      lastPayment,
      paymentData,
      paymentItemIndex,
    });

    const claim = claimCalc({
      interestItem: interestData[interestItemIndex],
      paymentData,
      lawsuitOpts,
    });

    checkClaimFullyCovered(claim, isInterestFirst);

    const notCoveredSum = getNotCoveredSum({
      interestItem: interestData[interestItemIndex],
      paymentItem: paymentData[paymentItemIndex],
      paymentData,
      lawsuitOpts,
      isInterestFirst
    });

    const notAssignedSum = getNotAssignedSum(paymentData, paymentItemIndex);

    const fullAssignmentSum =  notCoveredSum <  notAssignedSum
      ? notCoveredSum : notAssignedSum;

    checkAssignmentSum(assignmentSum, notCoveredSum);

    const sum = _.isUndefined(assignmentSum) ? fullAssignmentSum : assignmentSum;

    const paymentDataCopy = _.cloneDeep(paymentData);

    paymentDataCopy[paymentItemIndex].assignedTo.push(
      {
        key:interestItemKey,
        sum,
        isInterestFirst,
      });
    dispatch(paymentDataChanged(paymentDataCopy));
  };

export const getNotCoveredSum = ({
  interestItem,
  paymentItem,
  lawsuitOpts,
  isInterestFirst,
  paymentData
}:{
  interestItem: InterestItem,
  paymentItem: PaymentItem,
  paymentData: PaymentData,
  lawsuitOpts: LawsuitOpts,
  isInterestFirst:boolean,
}) => {

  const {
    claimSum,
    interestsCovered,
    interestDataFulfilled
  } = calcFulfilled({
    interestItem,
    paymentData,
    lawsuitOpts,
  });

  // I. interest will not be covered fist

  if (!isInterestFirst) return claimSum;

  /*
    II. interest will be covered first

    1.Check the recoverable interest for the previous periods and the new one created for the payment data
    but for the amount of claimSum
    2.This function only works for chronological adding of consecutive periods
    3.the amount remaining to be covered is the current amount of the claim
    together with the test interest, reduced by the payment already made for interest
    */

  const lastClaimInterestItem = _.last(interestDataFulfilled);
  const endDateFromPaymentDate = getEndDateFromPaymentDate({
    endDate: interestItem.endDate,
    paymentDate:paymentItem.paymentDate,
    statementDate:paymentItem.statementDate,
  });

  if (lastClaimInterestItem) {

    /*
    same payment date as in last interestDataFulfilled period

    there is no need to start new interestDataFulfilled period
    */
    const isSameDatePayment =  moment(paymentItem.paymentDate).endOf('day').isSame((lastClaimInterestItem.endDate).endOf('day'));

    const testInterestData =  isSameDatePayment ? interestDataFulfilled : [
      ...interestDataFulfilled,
      {
        key: paymentItem.key,
        partialSum: claimSum,
        startingDate: moment(endDateFromPaymentDate).endOf('day').isSame((interestItem.endDate).endOf('day'))
          ? endDateFromPaymentDate : moment(lastClaimInterestItem.endDate).add(1, 'day').startOf('day'),
        endDate:endDateFromPaymentDate,
      },
    ];

    const interestsSum = testInterestData.map(sudoInterestItem =>
      calcOneInterest({
        lawsuitOpts,
        interestItem: sudoInterestItem,
      })).reduce((prev, { interestSum }) =>
      prev + interestSum, 0);

    return  (claimSum + interestsSum) - interestsCovered;
  }

  const testInterestResult = calcOneInterest({
    lawsuitOpts,
    interestItem: {
      ...interestItem,
      endDate: endDateFromPaymentDate
    },
  });

  return (interestItem.partialSum + testInterestResult.interestSum) - interestsCovered;

};

const checkPerquisites = ({
  interestData,
  interestItemIndex,
  lastPayment,
  paymentData,
  paymentItemIndex,
}:{
  interestData: InterestData,
  interestItemIndex: number,
  lastPayment: PaymentItem | undefined,
  paymentData: PaymentData,
  paymentItemIndex: number,
}) => {
  if (arrayElementNotExists(interestItemIndex) || arrayElementNotExists(paymentItemIndex)){
    throw new Error('Nie mogę przyporządkować wpłaty, brak jednego z elementów');
  }
  if (isPaymentFullyAssigned(paymentData, paymentItemIndex)){
    throw new Error('Wpłata już została w całości przypisana');
  }
  if (lastPayment && moment(assignmentDate(lastPayment)).endOf('day').isAfter(moment(assignmentDate(paymentData[paymentItemIndex])).endOf('day'))){
    throw new Error('Wpłaty na jedno roszczenie muszą być przypisywane chronologicznie. Od najwcześniejszej wpłaty do najstarszej.');
  }
  if (interestData[interestItemIndex].startingDate.isSameOrAfter(paymentData[paymentItemIndex].paymentDate)){
    throw new Error('Nie można zaliczyć wpłaty wcześniejszej niż dzień powstania roszczenia.');
  }
  if (interestData[interestItemIndex].endDate.isBefore(paymentData[paymentItemIndex].paymentDate)){
    throw new Error('Nie można zaliczyć wpłaty o późniejszej dacie niż określona (w poprzednim kroku) data końcowa naliczania odsetek od roszczenia.');
  }
};

export const checkClaimFullyCovered = (claim: ClaimItem, isInterestFirst: boolean) => {
  const {
    claimSum,
    assignedPaymentsSum,
    partialSum,
    interestsSumFulfilledOverall,
    interestsSum,
  } = claim;

  const isClaimFullyCovered = () =>
    isInterestFirst ?
      assignedPaymentsSum  ===  (partialSum + interestsSumFulfilledOverall + interestsSum) :
      claimSum  === 0;

  if (isClaimFullyCovered()){
    throw new Error('Roszczenie zostało już w pełni pokryte wpłatami');
  }
};

const checkAssignmentSum = (assignmentSum: number | undefined, notCoveredSum: number) => {
  if (_.isNumber(assignmentSum) && assignmentSum <= 0){
    throw new Error('Kwota zaliczenia musi być większa niż 0');
  }

  if (_.isNumber(assignmentSum) && assignmentSum > notCoveredSum){
    throw new Error('Kwota zaliczenia nie może być większa niż kwota roszczenia pozostała do zaspokojenia');
  }
};

const arrayElementNotExists = (index: number) =>
  index < 0;


export const getNotAssignedSum = (paymentData:PaymentData, paymentIndex: number) => {
  const sumOfAssignedTo = paymentData[paymentIndex].assignedTo.reduce((prev, { sum }) =>
    prev + sum, 0);
  return paymentData[paymentIndex].paymentSum - sumOfAssignedTo;
};


export const getIndexFromKey = (data: InterestData | PaymentData, searchingKey: string) =>
  data.findIndex(({ key }) =>
    searchingKey === key);
