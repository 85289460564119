import { Col } from 'antd';
import React from 'react';
import { useHistory } from 'react-router';

import LawBorders from '../components/Law/LawBorders';
import LawPars from '../components/Law/LawPars';
import LawUsts from '../components/Law/LawUsts';
import PrivacyPar from '../components/Law/PrivacyPar';
import BackButton from '../components/Lawsuit/StepCard/BackButton';
import AnimOpacity from '../components/UI/Anim/AnimOpacity';
import LawContainer from '../components/UI/Containers/LawContainer';
import DotsEnding from '../components/UI/DotsEnding';
import { RowBg } from '../styles';
import { act1,
  act10,
  act11,
  act12,
  act13,
  act2,
  act3,
  act4,
  act5,
  act6,
  act7,
  act8,
  act9 } from '../utils/law/execTrans';
import { DashedDivider } from './About';

const ExecActsTransaction = () => {
  const history = useHistory();
  return (
    <AnimOpacity>
      <LawContainer title="Akty wykonawcze" />
      <DashedDivider />
      <LawBorders />
      <PrivacyPar
        title={act1.title}
        desc={act1.desc}
      >
        <LawPars texts={act1.pars} />
      </PrivacyPar>
      <PrivacyPar
        title={act2.title}
        desc={act2.desc}
      >
        <LawPars texts={act2.pars} />
      </PrivacyPar>
      <PrivacyPar
        title={act3.title}
        desc={act3.desc}
      >
        <LawPars texts={act3.pars} />
      </PrivacyPar>
      <PrivacyPar
        title={act4.title}
        desc={act4.desc}
      >
        <LawPars texts={act4.pars} />
      </PrivacyPar>
      <PrivacyPar
        title={act5.title}
        desc={act5.desc}
      >
        <LawPars texts={act5.pars} />
      </PrivacyPar>
      <PrivacyPar
        title={act6.title}
        desc={act6.desc}
      >
        <LawPars texts={act6.pars} />
      </PrivacyPar>
      <PrivacyPar
        title={act7.title}
        desc={act7.desc}
      >
        <LawPars texts={act7.pars} />
      </PrivacyPar>
      <PrivacyPar
        title={act8.title}
        desc={act8.desc}
      >
        <LawPars texts={act8.pars} />
      </PrivacyPar>
      <PrivacyPar
        title={act9.title}
        desc={act9.desc}
      >
        <LawPars texts={act9.pars} />
        <LawUsts texts={act9.usts} />
      </PrivacyPar>
      <PrivacyPar
        title={act10.title}
        desc={act10.desc}
      >
        <LawPars texts={act10.pars} />
        <LawUsts texts={act10.usts} />
      </PrivacyPar>
      <PrivacyPar
        title={act11.title}
        desc={act11.desc}
      >
        <LawPars texts={act11.pars} />
        <LawUsts texts={act11.usts} />
      </PrivacyPar>
      <PrivacyPar
        title={act12.title}
        desc={act12.desc}
      >
        <LawPars texts={act12.pars} />
        <LawUsts texts={act12.usts} />
      </PrivacyPar>
      <PrivacyPar
        title={act13.title}
        desc={act13.desc}
      >
        <LawPars texts={act13.pars} />
        <LawUsts texts={act13.usts} />
      </PrivacyPar>
      <DashedDivider />
      <LawContainer>
        <RowBg>
          <Col
            lg={0}
            md={{
              offset: 1,
              span: 1,
            }}
          />
          <Col>
            <BackButton
              onBack={() =>
                history.goBack()}
              showHome={false}
            />
          </Col>
        </RowBg>
      </LawContainer>
      <LawContainer>
        <DotsEnding />
      </LawContainer>
    </AnimOpacity>
  );
};

export default ExecActsTransaction;
