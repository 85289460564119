import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';

import LoginForm from '../components/Login/LoginForm';
import PaymentFinish from '../components/Payment/PaymentFinish';
import SubExists from '../components/Payment/SubExists';
import SignUpForm from '../components/SignUp/SignUpForm';
import PaymentContainer from '../components/UI/Containers/PaymentContainer';
import { auth } from '../services/firebase/auth';
import { useTypedSelector } from '../store';
import { RowExt } from '../styles';

const Payment = () => {
  const { isSubscriber } = useTypedSelector(state =>
    state.user, shallowEqual);

  const [step, setStep] = useState(0);
  const [isLogin, setIsLogin] = useState(true);

  useEffect(() => {
    auth.onAuthStateChanged(user => {
      if (user && !user.isAnonymous) {
        setStep(1);
      } else  {
        setStep(0);
      }
    });
  }, []);

  if (isSubscriber){
    return (
      <SubExists />
    );
  }

  if (step === 1) {
    return (
      <PaymentContainer
        step={step}
      >
        <PaymentFinish />
      </PaymentContainer>
    );
  }

  return (
    <PaymentContainer
      title="Konto pozew.app"
      desc="Zaloguj się na swoje konto lub utwórz nowe"
      step={step}
    >
      {isLogin ? (
        <>
          <LoginForm
            goToSignUp={() =>
              setIsLogin(false)}
            payment
          />
          <RowExt
            justify="space-around"
            align="middle"
            marginTop={12}
          >
            <Button
              type="link"
              onClick={() =>
                setIsLogin(false)}
            >
              Utwórz nowe konto
            </Button>
          </RowExt>
        </>
      ) : (
        <>
          <SignUpForm
            payment
          />
          <RowExt
            justify="space-around"
            align="middle"
            marginTop={12}
          >
            <Button
              type="link"
              onClick={() =>
                setIsLogin(true)}
            >
              Zaloguj się
            </Button>
          </RowExt>
        </>
      )}
    </PaymentContainer>
  );
};

export default Payment;