import { CheckCircleFilled, CheckCircleOutlined } from '@ant-design/icons';
import { Col, Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { TitleAndDesc } from '../../@types';
import { BLUE, DashedColLeft, GREY_LIGHT, RowExt } from '../../styles';

const RoadMapTittle = (
  {
    title,
    desc,
    done,
    bColor
  }: TitleAndDesc & {
    done?: boolean,
    bColor?: string
  }
) => {
  return (
    <>
      <RowExt>
        <BorderLeft
          lg={{
            offset:0,
            span:1,
          }}
          xs={{
            offset: 1,
            span: 1,
          }}
          bColor={bColor}
        />
        <Col
          lg={{ span:21 }}
          xs={{ span: 20 }}
        >
          <Typography.Title
            level={4}
            style={{
              marginTop:12, textTransform: 'uppercase'
            }}
          >
            {title}
            {' '}
            {done ? (
              <CheckCircleFilled style={{
                color:BLUE, marginLeft: 12
              }}
              />
            ) : (
              <CheckCircleOutlined style={{
                color: GREY_LIGHT, marginLeft: 12
              }}
              />
            )}
          </Typography.Title>
        </Col>
      </RowExt>
      <RowExt>
        <DashedColLeft
          lg={{
            offset:0,
            span:1,
          }}
          xs={{
            offset: 1,
            span: 1,
          }}
        />
        <Col
          lg={{ span:21 }}
          xs={{ span: 20 }}
        >
          <Desc level={5} type='secondary' done={done}>
            {done ? 'Ukończono: ' : 'Planowane wprowadzenie: '}
            {desc}
          </Desc>
        </Col>
      </RowExt>
    </>
  );
};

export default RoadMapTittle;

const BorderLeft = styled(Col)<{ bColor?: string }>`
  border-left: 1px solid;
  border-left-color: ${props =>
    props.bColor || BLUE};
  display: flex;
  justify-content: center;
`;

const Desc = styled(Typography.Title)<{ done?: boolean }>`
   color: ${props =>
    props.done ? BLUE : GREY_LIGHT} !important`;


