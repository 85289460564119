/* eslint-disable no-unused-vars */
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Col, Descriptions, Radio, Row, Tag } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { BLACK, BLUE, GREY_LIGHT, GREY_WHITE } from './colors';
import { RowExt, RowMd } from './grid';


export const CardRadio = styled(Radio)`
	display: block;
	height: 32px;
	line-height: 32px;
`;

export const CardRadioButton = styled(Radio.Button)`
	display: flex;
    padding: 24px;
    align-items:center;
    margin-top: 12px;
`;

export const JustRadioButton = styled(Radio.Button)`
  margin-right: 24px;
`;

export const GreyArea = styled(RowMd)`
  background-color: #f5f5f5;
  border-radius: 4;
  padding: 24px 32px 32px 32px;
`;

export const CardPlaceholder = styled.div<{ size?: 2 | 3 | 4 | 5 }>`
	display: block;
	height: ${(props) =>
    (props.size ? `${props.size * 32}px` : '32px')};
`;

export const QuestionMark = styled(QuestionCircleOutlined)`
	color: #8c8c8c;
`;

export const BorderColumn = styled.span<{ color?: string }>`
	border-right: 1px solid ${props =>
    props.color ?? BLACK};
	height: 18px; 
	width:1;
`;


export const EmptyButton = styled(({
  bgColor,
  color,
  hoverColor,
  hoverBg,
  dotted,
  full,
  ...props
}) =>
  <Button {...props} />)
<{ bgColor?: string,
  color?: string,
  hoverColor?: string,
  hoverBG?: string,
  dotted?: boolean,
  full?:boolean,
}>
` width:${({ full }) =>
  full ? '100%' : 'inherit'};
  border-color: ${({ color }) =>
    color ?? BLUE} !important;
   border-width: 1px;
   font-weight: 400;
   background-color: ${({ bgColor }) =>
    bgColor || 'white'};
   color: ${({ color }) =>
    color ?? BLUE};
  border-style:${({ dotted }) =>
    dotted ? 'dashed' : 'solid'
};
  :hover{
    border-color: ${({ hoverColor }) =>
    hoverColor ?? BLUE} !important;
    background-color: ${({ hoverBg }) =>
    hoverBg || BLUE};
    color: ${({ hoverColor }) =>
    hoverColor ?? 'white'};
    fill: white;
    border-style: solid;
  }
  :active{
    border-color: ${({ hoverColor }) =>
    hoverColor ?? BLUE} !important;
    background-color: ${({ hoverBg }) =>
    hoverBg || BLUE};
    color: ${({ hoverColor }) =>
    hoverColor ?? 'white'};
    fill: white;
  }
  :focus{
    border-color: ${({ hoverColor }) =>
    hoverColor ?? BLUE} !important;
    background-color: ${({ hoverBg }) =>
    hoverBg || BLUE};
    color: ${({ hoverColor }) =>
    hoverColor ?? 'white'};
    fill: white;
  }
`;

export const ButtonFullWidth = styled(Button)`
  width: 100%;
`;

export const LinkFullWidth = styled(Link)`
  width: 100%;
`;
export const DescFullWidth = styled(Descriptions)`
  width: 100%;
`;

export const Dot = styled.div<{ color: string, align?: string }>`
   font-size: 52px;
   font-weight: 600;
   color:${props =>
    props.color};
   text-align:${props =>
    props.align ?? 'start'}
`;

export const LINK_NO_STYLE = {
  textDecoration: 'none', color: 'inherit'
};

export  const AboutContainer = styled.div`
    width: 1440px;
	@media only screen and (max-width: 1440px) {
    width: 100vw;
	}
`;
export  const AbtSpaceRow = styled(Row)`
   height: 48px;
`;


export const TitleDivider = styled.div<{ color?: string }>`
border-top: 2px  ${({ color }) =>
    color ?? BLUE} solid;
width: 42px;
margin-bottom: 24px;
margin-top: -1px;
`;

export const ABOUT_DASH = '1px dashed rgba(66,71,112,0.09)';

export const DashedColLeft = styled(Col)`
    border-left: ${ABOUT_DASH};
`;
export const DashedColRight = styled(Col)`
    border-right: ${ABOUT_DASH};
`;

export const DashedColBoth = styled(Col)`
    border-left: ${ABOUT_DASH};
    border-right: ${ABOUT_DASH};
`;

export const DashedColTop = styled(Col)`
    border-top: ${ABOUT_DASH};
`;

export const LabelForm = styled(RowExt)`
    font-size: 14px;
    font-weight: 500;
    color: ${BLACK};
`;

export const MiddleBorder = styled.div`
   border-right: 1px ${GREY_LIGHT} dashed;
   width: 50%;
   height: 100%;
`;

export const BetaTag = styled(Tag)`
  background: ${GREY_WHITE};
  color: ${BLUE};
  margin-left: 12px;
`;