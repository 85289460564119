import { Button, Typography } from 'antd';
import React from 'react';
import { Img } from 'react-image';
import styled from 'styled-components';
import stripe from '../../assets/Stripe.svg';
import { AboutContainer, RowExt } from '../../styles';
import { BLUE } from '../../styles/colors';
import SubSection from './SubSection';

const { Paragraph, Title } = Typography;

const SubFAQ = () => {
  return (
    <AboutContainer>
      <SubSection
        title="F. A. Q. - często zadawane pytania"
        id="faq"
        desc={(
          <ul>
            <Title level={5}>Jak realizowana jest płatność?</Title>
            <Paragraph>
              Płatność jest obsługiwana przez
              <a type="link" href="https://stripe.com" target="_blank" rel="noreferrer"> Stripe </a>
              światowego lidera bezpieczeństwa w tej dziedzinie, obsługującego takie podmioty jak Booking.com, czy Saleforce.
            </Paragraph>
            <RowExt
              marginBottom={24}
              style={{ paddingTop: 12 }}
            >
              <a type="link" href="https://stripe.com" target="_blank" rel="noreferrer">
                <StripeImg
                  src={stripe}
                />
              </a>
            </RowExt>
            <Paragraph>
              Po zakończeniu okresu próbnego Twoja karta zostanie obciążona wymaganą kwotą i otrzymasz fakturę VAT na wskazany podmiot.
              {' '}
              <strong>Jeśli zrezygnujesz z subskrypcji w czasie okresu próbnego płatność nie zostanie pobrana.</strong>
            </Paragraph>
            <Title level={5}>Wolałbym zrealizować płatność przelewem?</Title>
            <Paragraph>
              Oczywiście jest taka możliwość - wyślemy fakturę mailem. Prosimy o kontakt:
            </Paragraph>
            <RowExt>
              <ContactButton
                href="mailto:contact@pozew.app"
                type="link"
              >
                Skontaktuj się z nami bezpośrednio
              </ContactButton>
            </RowExt>
            <Title level={5}>Mam inne pytanie dotyczące subskrypcji</Title>
            <Paragraph>
              Z chęcią wyjaśnimy wszystkie wątpliwości. Zapraszamy do kontaktu:
            </Paragraph>
            <RowExt>
              <ContactButton
                href="mailto:contact@pozew.app"
                type="link"
                style={{ marginBottom: 12 }}
              >
                Skontaktuj się z nami bezpośrednio
              </ContactButton>
            </RowExt>
          </ul>
            )}
        color={BLUE}
      />
    </AboutContainer>
  );
};

export default SubFAQ;
export const StripeImg = styled(Img)`
    height: 26px;
    
`;

const ContactButton = styled(Button)`
   margin-top: 0px;
`;