import { UserAddOutlined, UserDeleteOutlined } from '@ant-design/icons';
import { Button, Card, Col, Empty, Input, notification, Popconfirm, Row } from 'antd';
import { deleteDoc, onSnapshot, Timestamp } from 'firebase/firestore';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { auth } from '../../services/firebase/auth';
import { setInboxUser } from '../../services/firebase/firebaseFunctions';
import { inboxUserRef, inboxUsersQuery } from '../../services/firebase/firestoreRefs';
import { AppDispatch } from '../../store';
import { appLoadingStarted, appLoadingStopped } from '../../store/appLoading';
import { GRID_SM_PX, RowExt } from '../../styles';
import { emailRegex } from '../../utils/helpers/regexChecks';


type InboxUser = {
  createdDate: Timestamp;
  email: string;
};

type InboxUsersLocal = {
  id: string;
  createdDate: string;
  email: string;
};


const removeUser = async (dispatch:AppDispatch, senderId: string) => {
  try {
    const { currentUser } = auth;
    dispatch(appLoadingStarted());
    if (currentUser){
      await deleteDoc(inboxUserRef(currentUser.uid, senderId));
    }
    dispatch(appLoadingStopped());
  } catch (e){
    dispatch(appLoadingStopped());
  }
};

const addUser = async (dispatch:AppDispatch, emailLocal: string | null, setEmail: (emailLocal:string | null) => void) => {
  try {
    const { currentUser } = auth;
    dispatch(appLoadingStarted('Dodaję użytkownika'));
    if (!emailLocal){
      notification.error({
        message: 'Brak adresu email',
        description: 'Wpisz właściwy email'
      });
    }
    if (emailLocal && !emailRegex.test(emailLocal.trim())){
      notification.error({
        message: 'Email jest wadliwie sformatowany',
        description: 'Wpisz właściwy email'
      });
    }

    if (currentUser && emailLocal && emailRegex.test(emailLocal.trim())){
      const res  = await setInboxUser({ senderEmail:emailLocal.trim() }) as unknown as { data:{
        title: string,
        message: string,
      } };
      notification.info({
        message: res.data.title,
        description: res.data.message
      });
    }
    dispatch(appLoadingStopped());
  } catch (e){
    dispatch(appLoadingStopped());
  } finally {
    setEmail(null);
  }
};



const InboxUsers = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState<InboxUsersLocal []>([]);
  const [loading, setLoading] = useState(true);
  const [emailLocal, setEmail] = useState<string | null>(null);

  useEffect(() => {
    const { currentUser } = auth;
    if (currentUser){
      const unsubscribe = onSnapshot(inboxUsersQuery(currentUser.uid), (res) => {
        if (!res.empty){
          const inboxUsers  = res.docs;
          const inboxUsersLocal = inboxUsers.map((value) => {
            const connectedAccount = value.data() as InboxUser;
            const { email, createdDate } = connectedAccount;
            return {
              id: value.id,
              email,
              createdDate:  moment(createdDate).format('YYYY-MM-DD HH:mm'),
            };
          });
          setData(inboxUsersLocal);
          setLoading(false);
        } else {
          setData([]);
          setLoading(false);
        }
      });
      return () => {
        if (currentUser){
          unsubscribe();
        }
      };
    }
    return undefined;
  }, []);

  if (loading && auth.currentUser){
    return (
      <RowExt paddingTop={72} align="middle" justify="center">
        <div className="lds-ellipsis-big">
          <div />
          <div />
          <div />
          <div />
        </div>
      </RowExt>
    );
  }
  return (
    <Card
      title="Uprawnieni do przesyłania danych na Twoją skrzynkę"
      style={{
        width: '100%', marginTop: 16
      }}
      actions={[
        <Row key="2" justify='center'>
          <Col xs={{ span: 16 }}>
            <Input.Search
              key="1"
              placeholder="Wpisz adres email użytkownika..."
              enterButton={(
                <>
                  <UserAddOutlined />
                  {' '}
                  Dodaj
                </>
)}
              allowClear
              onChange={(event) =>
                setEmail(event.target.value)}
              value={emailLocal ?? ''}
              onSearch={() =>
                addUser(dispatch, emailLocal, setEmail)}
            />
          </Col>
        </Row>
      ]}
    >
      {_.isEmpty(data) ? (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="Nie dodałaś/eś jeszcze nikogo..."
          style={{ marginBottom: 18 }}
        />
      ) :
        data.map(value =>
          (
            <Row
              key={value.id}
              align='middle'
              justify='space-between'
            >
              {value.email}
              <Popconfirm
                title="Usunąć użytkownika z listy?"
                onConfirm={() =>
                  removeUser(dispatch, value.id)}
              >
                <Button
                  type="link"
                  key="button"
                  icon={<UserDeleteOutlined />}
                >
                  Usuń użytkownika
                </Button>
              </Popconfirm>
            </Row>
          )) }
    </Card>
  );
};

export default InboxUsers;

export const Desc = styled.span`
  	@media only screen and (max-width: ${GRID_SM_PX}) {
    font-size: 12px;
	}
`;