import { shallowEqual } from 'react-redux';

import { useTypedSelector } from '../../../store';
import { formatPLN } from '../../helpers/formatNumber';

const useCompSum = () => {
  const {
    partialResults,
    compensationSum,
  } = useTypedSelector(state =>
    state.sumUp, shallowEqual);

  if (partialResults.length > 1){
    let sum = 'Suma kwot ';
    partialResults.forEach((result, index) => {
      if (index !== 0){
        sum += ` + ${formatPLN(result.compensationResult?.valuePLN)}`;
      } else  {
        sum += `${formatPLN(result.compensationResult?.valuePLN)}`;
      }
    });
    return `${sum } daje łączną kwotę dochodzonych rekompensat (${formatPLN(compensationSum)}).`;
  }

  return null;
};

export default useCompSum;
