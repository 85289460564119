import _ from 'lodash';
import { Moment } from 'moment';
import { PeriodResult, numberToPercent } from '../../../services/calc/interestsCalc';
import { PartialResult } from '../../../store/sumUp';
import { arrStrFormat } from '../../helpers/arrStrFormat';
import { formatDate } from '../../helpers/date';
import { formatPLN } from '../../helpers/formatNumber';
import { getResultNumber } from '../../helpers/getResultNumber';
import { textIf, textPad, textIfPad } from '../../helpers/textIf';


/* -------------------------------------------------------------------------- */
/*                          A. interest partial intro                         */
/* -------------------------------------------------------------------------- */

export const interestPartialIntro = ({
  claimStartingDate,
  claimSum,
  endDate,
  index,
  interestsSum,
  interestsSumFulfilledUncovered,
  interestsSumUncovered,
  isClaimFromFulfilled,
  singleResult,
}:{
  claimStartingDate: Moment | string,
  claimSum: number,
  endDate: Moment;
  index: number,
  interestsSum: number,
  interestsSumCovered: number,
  interestsSumFulfilledUncovered: number,
  interestsSumUncovered: number,
  isClaimFromFulfilled: boolean,
  singleResult:boolean,
  startingDate: Moment;
}) => {

  const shouldShowFulfilled = interestsSumFulfilledUncovered > 0 && isClaimFromFulfilled;

  // 1. No claim for interest fulfilled

  const textNoClaimFulfilled = arrStrFormat([
    `Dla roszczenia ${textIf(`nr ${index + 1}`, !singleResult)} w wysokości ${formatPLN(claimSum)}`,

    textPad(`skapitalizowane odsetki za okres od dnia ${formatDate(claimStartingDate)} 
      do dnia ${formatDate(endDate)} wynoszą ${formatPLN(interestsSum) ?? '0 zł'}.`),
  ]);

  // 2. Claim for fulfilled exists and user wants to claim it

  const textClaimFulfilled = arrStrFormat([
    `Dla roszczenia ${textIf(`nr ${index + 1}`, !singleResult)}`,

    textPad(`skapitalizowane odsetki liczone w związku z opóźnieniem w zapłacie zaspokojonej 
      już części kwoty głównej  wynoszą ${formatPLN(interestsSumUncovered) ?? '0 zł'}.`),

    textPad(`Natomiast skapitalizowane odsetki liczone w związku z opóźnieniem w zapłacie 
      niezaspokojonej części kwoty głównej wynoszą ${formatPLN(interestsSumFulfilledUncovered) ?? '0 zł'}.`),

    textPad(`Ich łączna, dochodzona wartość to: ${formatPLN(interestsSumUncovered + interestsSumFulfilledUncovered)}.`)
  ]);

  return [textIf('', singleResult, `${index + 1}) `),
    shouldShowFulfilled ? textClaimFulfilled : textNoClaimFulfilled
  ].join('');
};

export const interestCoveredText = (result:PartialResult) =>
  textIfPad(`Na poczet powyższej kwoty zaliczono ${formatPLN(result.interestsSumCovered)} i roszczenie odsetkowe uległo zmniejszeniu z ${formatPLN(result.interestsSum)} do ${formatPLN(result.interestsSumUncovered)}.`, result.interestsSumCovered).replace(/(\r\n|\n|\r)/gm, '').replace(/ +(?= )/g, '');

/* -------------------------------------------------------------------------- */
/*                       B. interest partial description                      */
/* -------------------------------------------------------------------------- */

// for both from fulfilled and unfulfilled

export const interestPartialDesc = (result:PartialResult, isFulfilled: boolean) => {
  const {
    interestsSumFulfilledUncovered,
    isClaimFromFulfilled
  } = result;
  return  arrStrFormat([
    'Kwota skapitalizowanych odsetek',
    textIfPad(`dochodzona od${textIf(' zaspokojonej części kwoty głównej',
      isFulfilled,
      ' niezaspokojonej części kwoty głównej')}`,
    interestsSumFulfilledUncovered > 0  && isClaimFromFulfilled
    ),
    ' została ustalona następująco:'
  ]);
};

/* -------------------------------------------------------------------------- */
/*                               C. period texts                              */
/* -------------------------------------------------------------------------- */

export const periodText = (
  periodResult: PeriodResult | undefined,
  interestType: string,
  isLastPeriod?: boolean
) => {
  if (periodResult === undefined) return '';
  return [
    `- ${interestType} w okresie od dnia ${formatDate(periodResult.periodStart)}`,
    ` do dnia ${formatDate(periodResult.periodEnd)} (${periodResult.days} dni)`,
    ` wynosiły ${numberToPercent(periodResult.interestRate)} w skali roku`,
    ` - kwota ${formatPLN(periodResult.periodSum)}`,
    textIf('.', isLastPeriod, ';'),
  ].join('');
};


/* -------------------------------------------------------------------------- */
/*                             E. fulfilled sum up                            */
/* -------------------------------------------------------------------------- */

export const interestFulfilledSumUp = (result:PartialResult, index: number) => {
  const {
    interestsSumFulfilledUncovered,
    interestsSumFulfilledCovered,
    interestsSumFulfilledOverall,
    assignedPayments
  } = result;

  const {
    pluralResults,
    resultNumber
  } = getResultNumber(index);

  return _.isEmpty(assignedPayments) ? '' : arrStrFormat([
    `Podsumowując: odsetki za opóźnienie w zapłacie zaspokojonej części roszczenia 
      ${textIf(`nr ${resultNumber}`, pluralResults)} 
      wynoszą ${formatPLN(interestsSumFulfilledOverall)}.`,

    textIfPad('Nie zaliczono na ich poczet żadnych wpłat.',
      interestsSumFulfilledCovered === 0,
      ` Zaliczono na ich poczet wpłaty w wysokości ${formatPLN(interestsSumFulfilledCovered)}. 
      To spowodowało zmniejszenie roszczenia odsetkowego w tym zakresie do kwoty
      ${formatPLN(interestsSumFulfilledUncovered)}.`)
  ]);
};


/* -------------------------------------------------------------------------- */
/*                          F. interest basic sum up                          */
/* -------------------------------------------------------------------------- */

export const interestBasicSumUp = (periodResults:PeriodResult[] | undefined, interestPartialSum: number | undefined, claimSum: number) => {
  if (periodResults){
    const sum = periodResults.reduce((prev, curr, index) => {
      if (index !== 0){
        return `${prev} + ${formatPLN(curr.periodSum)}`;
      } return `${prev}${formatPLN(curr.periodSum)}`;
    }, 'Suma kwot ');
    return `${sum} odpowiada kwocie skapitalizowanych odsetek (${formatPLN(interestPartialSum) ?? '0 zł'}) obliczonych od kwoty ${formatPLN(claimSum) ?? '0 zł'}.`;
  }
  return '';
};
