// Art. 19 [Zasada]
// § 1. W sprawach o roszczenia pieniężne, zgłoszone choćby w zamian innego przedmiotu, podana kwota pieniężna stanowi wartość przedmiotu sporu.
// § 2. W innych sprawach majątkowych powód obowiązany jest oznaczyć w pozwie kwotą pieniężną wartość przedmiotu sporu, uwzględniając postanowienia zawarte w artykułach poniższych.
// Art. 20 [Rozwinięcie] Do wartości przedmiotu sporu nie wlicza się odsetek, pożytków i kosztów, żądanych obok roszczenia głównego.


// Art. 126 1 [Wymóg formalny]
// § 1. W każdym piśmie należy podać wartość przedmiotu sporu lub wartość przedmiotu zaskarżenia, jeżeli od tej wartości zależy właściwość rzeczowa sądu, wysokość opłaty lub dopuszczalność środka odwoławczego, a przedmiotem sprawy nie jest oznaczona kwota pieniężna.
// § 2. Pisma dotyczące części przedmiotu sporu lub zaskarżenia podlegają opłacie tylko w stosunku do wartości tej części.
// § 3. Wartości przedmiotu sporu lub zaskarżenia podaje się w złotych, zaokrąglając w górę do pełnego złotego.

export const getWps = (finalSum: number) => {
  return  Math.ceil(finalSum);
};