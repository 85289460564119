import React from 'react';
import SubBenefits from '../components/Subscribe/SubBenefits';
import SubFAQ from '../components/Subscribe/SubFAQ';
import SubOpts from '../components/Subscribe/SubOpts';
import SubHeader from '../components/Subscribe/SubHeader';
import SubSpaceRow from '../components/Subscribe/SubSpaceRow';
import SubTitle from '../components/Subscribe/SubTitle';
import AnimOpacity from '../components/UI/Anim/AnimOpacity';
import DescScreenFooter from '../components/UI/DescScreenFooter';
import { ScreenLayout } from '../styles';
import { DashedDivider } from './About';

const Subscribe = () => {
  return (
    <AnimOpacity>
      <ScreenLayout>
        <SubHeader />
        <DashedDivider />
        <SubTitle />
        <SubSpaceRow />
        <SubOpts />
        <SubBenefits />
        <SubFAQ />
        <SubSpaceRow />
        <DashedDivider />
        <DescScreenFooter />
      </ScreenLayout>
    </AnimOpacity>
  );
};

export default Subscribe;
