import { Button, Col, Space } from 'antd';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import useWindowSize from '../../../utils/hooks/useWindowSize';
import { EmptyButton, GREY_WHITE, GRID_LG, GRID_LG_PX, GRID_SM_PX, LinkFullWidth, RowExt } from '../../../styles';
import { SloganDesc } from './SloganDesc';
import SloganDots from './SloganDots';


export const ColResp = (
  {
    children,
    md
  }: {
    children?: ReactNode,
    md?: boolean
  }
) =>
  (
    <RowExt
      fullWidth
      marginTop={md ? 'md' : 0}
    >
      <Col
        lg={{
          offset: 2, span: 14
        }}
        md={{
          offset: 2, span: 18
        }}
        sm={{
          offset: 2, span: 20
        }}
        xs={{
          offset: 2, span: 20
        }}
      >
        {children}
      </Col>
    </RowExt>
  );

const SloganHeader = () => {
  const { width } = useWindowSize();
  return (
    <>
      <Container>
        <ColResp>
          <MainText>
            {texts.head1}
            <br />
            {texts.head2}
          </MainText>
        </ColResp>
        <SloganDots />
        <ColResp>
          <SloganDesc />
        </ColResp>
        <RowExt
          fullWidth
          marginTop="md"
          align="middle"
        >
          <Col
            lg={{
              offset: 2, span: 20
            }}
            md={{
              offset: 2, span: 10
            }}
            sm={{
              offset: 2, span: 20
            }}
            xs={{
              offset: 2, span: 20
            }}
          >
            <Space>
              <LinkFullWidth
                to="/about"
              >
                <EmptyButton
                  bgColor={GREY_WHITE}
                >
                  {texts.button}
                </EmptyButton>
              </LinkFullWidth>
              <Link
                to={{ pathname: '/guides' }}
              >
                <Button type="link">
                  Instrukcje video
                </Button>
              </Link>

              {width < GRID_LG ? (
                <>
                  <Link
                    to="/privacy"
                  >
                    <Button
                      type="link"
                    >
                      Polityka prywatności
                    </Button>
                  </Link>
                  <Link
                    to="/terms"
                  >
                    <Button
                      type="link"
                    >
                      Reuglamin
                    </Button>
                  </Link>
                </>
              ) : null}
            </Space>
          </Col>
        </RowExt>
      </Container>
    </>
  );
};

export default SloganHeader;

const Container = styled.div`
    @media only screen and (min-width: ${GRID_LG_PX}) {
      height: 100%;
    }
    @media only screen and (max-width: ${GRID_LG_PX}) {
      padding: 48px 0;
      margin-top: 48px;
    }
    background-color: ${GREY_WHITE};
    justify-content: center;
    width: 100%;
    display: flex;
    flex-direction: column;
`;


export const MainText = styled.h2`
   font-size: 28px;
   font-weight: 600;
   @media only screen and (max-width: ${GRID_SM_PX}) {
      font-size: 22px;
    }
`;

const texts = {
  head1: 'Więcej czasu na argumentację,',
  head2: 'automatyzuj uciążliwości.',
  button: 'Dowiedz się więcej',
  button2: 'Zaufali nam',
  button3: 'Zaufali nam'
};