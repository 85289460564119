import { CalculatorOutlined, DownloadOutlined, HomeOutlined } from '@ant-design/icons';
import { Col } from 'antd';
import React, { useState } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { editResults } from '../../services/editResults';
import { saveJson } from '../../services/handleJson';
import { useTypedSelector } from '../../store';
import { RowExt } from '../../styles';
import { ButtonFullWidth, LinkFullWidth } from '../../styles/ui';
import NewLawsuit from '../NewLawsuit/NewLawsuit';


const ResultLsActions = (
  {
    recordName,
    saveId
  }: {
    recordName?: string,
    saveId?: string
  }
) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { lawsuitOpts, partialResults, paymentData } = useTypedSelector(state =>
    state.sumUp, shallowEqual);

  const userExists = useTypedSelector(state =>
    state.user.isNonAnonymousUserLoggedIn);

  const handleSaveToPC = () => {
    saveJson({
      paymentData,
      lawsuitOpts,
      partialResults
    });
  };

  const [isModalVisible, setModalVisible] = useState(false);

  const onEdit = () => {
    dispatch(editResults({
      history,
      recordName,
      saveId,
    }));
  };

  const interestData = useTypedSelector(state =>
    state.interestStorage.interestData);

  const handleEdit = () => {
    if (interestData.length > 0){
      setModalVisible(true);
    } else {
      onEdit();
    }
  };

  return (
    <>
      <RowExt marginTop={32} justify="space-between" align="middle" marginBottom="bg" gutter={[{ sm:16 }, { xs:24 }]}>
        <Col sm={8} xs={24}>
          <LinkFullWidth
            to={{ pathname: userExists ? '/dashboard' : '/' }}
          >
            <ButtonFullWidth
              icon={<HomeOutlined />}
            >
              Wróć
            </ButtonFullWidth>
          </LinkFullWidth>
        </Col>
        <Col sm={8} xs={24}>
          <ButtonFullWidth
            icon={<DownloadOutlined />}
            onClick={handleSaveToPC}
          >
            Zapisz lokalnie
          </ButtonFullWidth>
        </Col>
        <Col sm={8} xs={24}>
          <ButtonFullWidth
            type='primary'
            icon={<CalculatorOutlined />}
            onClick={handleEdit}
          >
            Edytuj
          </ButtonFullWidth>
        </Col>
      </RowExt>
      <NewLawsuit
        {...{
          isModalVisible,
          setModalVisible,
          isEdit: true,
          onEdit
        }}
      />
    </>
  );
};


export default ResultLsActions;