// Generated automatically by nearley, version 2.20.1
// http://github.com/Hardmath123/nearley
(function () {
	function id(x) {
		return x[0];
	}

	const moo = require('moo');

	const lexer = moo.compile({
		ws: {match: /\s+/, lineBreaks: true},
		keywords: [
			'tytuł płatności',
			'opis operacji',
			'tytuł operacji',
			'tytuł',
			'tytułem',
			'szczegóły transakcji',
		],
		sentenceEnd: [
			'rodzaj transakcji',
			'nr transakcji blik',
			'nr referencyjny operacji',
			'rodzaj operacji',
			'data księgowania',
			'kwota',
			'numer rachunku',
		],
		colon: /\:\s/,
		words: /\S+/,
		slash: /\/\s?/,
		specials: {match: /[^A-Za-z0-9]+/, lineBreaks: true},
	});
	var grammar = {
		Lexer: lexer,
		ParserRules: [
			{name: 'Main', symbols: ['ANY', 'Data', 'ANY']},
			{name: 'Main', symbols: [], postprocess: (d) => d[0]},
			{
				name: 'Data',
				symbols: [
					lexer.has('keywords') ? {type: 'keywords'} : keywords,
					'colon',
					'TransferDescription',
				],
				postprocess: (d) => d[2][0],
			},
			{
				name: 'TransferDescription',
				symbols: ['Result', lexer.has('sentenceEnd') ? {type: 'sentenceEnd'} : sentenceEnd],
				postprocess: (d) => d[0],
			},
			{
				name: 'Result$ebnf$1$subexpression$1',
				symbols: [lexer.has('words') ? {type: 'words'} : words],
			},
			{name: 'Result$ebnf$1$subexpression$1', symbols: [lexer.has('ws') ? {type: 'ws'} : ws]},
			{name: 'Result$ebnf$1', symbols: ['Result$ebnf$1$subexpression$1']},
			{
				name: 'Result$ebnf$1$subexpression$2',
				symbols: [lexer.has('words') ? {type: 'words'} : words],
			},
			{name: 'Result$ebnf$1$subexpression$2', symbols: [lexer.has('ws') ? {type: 'ws'} : ws]},
			{
				name: 'Result$ebnf$1',
				symbols: ['Result$ebnf$1', 'Result$ebnf$1$subexpression$2'],
				postprocess: function arrpush(d) {
					return d[0].concat([d[1]]);
				},
			},
			{name: 'Result', symbols: ['Result$ebnf$1']},
			{name: 'colon', symbols: [lexer.has('colon') ? {type: 'colon'} : colon]},
			{name: 'colon', symbols: [{literal: ' '}]},
			{name: '_$ebnf$1', symbols: [/[/\s+]/]},
			{
				name: '_$ebnf$1',
				symbols: ['_$ebnf$1', /[/\s+]/],
				postprocess: function arrpush(d) {
					return d[0].concat([d[1]]);
				},
			},
			{name: '_', symbols: ['_$ebnf$1']},
			{name: 'ANY$ebnf$1$subexpression$1$ebnf$1', symbols: [/./]},
			{
				name: 'ANY$ebnf$1$subexpression$1$ebnf$1',
				symbols: ['ANY$ebnf$1$subexpression$1$ebnf$1', /./],
				postprocess: function arrpush(d) {
					return d[0].concat([d[1]]);
				},
			},
			{name: 'ANY$ebnf$1$subexpression$1', symbols: ['ANY$ebnf$1$subexpression$1$ebnf$1']},
			{name: 'ANY$ebnf$1', symbols: ['ANY$ebnf$1$subexpression$1'], postprocess: id},
			{
				name: 'ANY$ebnf$1',
				symbols: [],
				postprocess: function (d) {
					return null;
				},
			},
			{name: 'ANY', symbols: ['ANY$ebnf$1'], postprocess: (d) => null},
		],
		ParserStart: 'Main',
	};
	if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
		module.exports = grammar;
	} else {
		window.grammar = grammar;
	}
})();
