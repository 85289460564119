import { AuditOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';

const { Title } = Typography;

type Props = {
  children: React.ReactNode;
};

const BasisCard = (
  { children }: Props
) => {
  return (
    <AnimatePresence>
      <motion.div
        transition={{
          ease: 'easeInOut',
          duration: 1,
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Title
          level={2}
        >
          <AuditOutlined
            style={{ marginRight:4 }}
          />
          Podstawa faktyczna i prawna roszczenia
        </Title>
        <Title level={5}>
          Podgląd tekstu uzasadnienia podstawy dla tej kwoty
        </Title>
        {children}
      </motion.div>
    </AnimatePresence>
  );
};

export default BasisCard;
