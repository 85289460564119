import { notification } from 'antd';
import { signInAnonymously } from 'firebase/auth';
import _ from 'lodash';
import { Moment } from 'moment';
import { AppDispatch } from '../../../store';
import { appLoadingStarted, appLoadingStopped } from '../../../store/appLoading';
import { PaymentData, InterestData } from '../../../store/interestStorage';
import { formatDate } from '../../../utils/helpers/date';
import { getInterestUntilPayment } from '../../assignToOldestNew';
import { auth } from '../../firebase/auth';
import { sendAutoAssignTd } from '../../firebase/firebaseFunctions';

export const updateAutoAssignTd = async ({
  dispatch,
  paymentData,
  interestData,
  userExists
}:{
  dispatch:  AppDispatch,
  paymentData: PaymentData,
  interestData: InterestData,
  userExists: boolean,
}) => {
  try  {
    dispatch(appLoadingStarted('przesyłam dane'));
    if (!userExists) await signInAnonymously(auth);
    const trainingData = createTrainingData({
      paymentData, interestData
    });
    await sendAutoAssignTd({ trainingData });
    dispatch(appLoadingStopped());
    notification.info({ message: 'Dane przesłane' });
  } catch (e){
    dispatch(appLoadingStopped());
  }
};



const createTrainingData = ({ paymentData, interestData }:{
  paymentData: PaymentData,
  interestData: InterestData
}) =>
  paymentData.reduce((prev: {
    input: string[],
    output: string[],
  }[], curr) => {
    if (_.isEmpty(curr.assignedTo)) return prev;
    const firstItem = [formatDate(curr.paymentDate), _.toString(curr.paymentSum), curr.transferDescription ?? ''].join();
    const outputFormatted = curr.assignedTo.reduce((p:string[], c) => {
      const index = interestData.findIndex(v =>
        v.key === c.key);
      if (index > -1 ) {
        return [...p,
          [
            formatDate(interestData[index].dueDate as Moment),
            _.toString(interestData[index].partialSum),
            interestData[index].accountingDoc ?? ''
          ].join()
        ];
      }
      return p;
    }, []);

    const relevantInterestData = getInterestUntilPayment(curr.paymentDate, interestData);

    const restOfInputData = relevantInterestData.map(value =>
      [
        formatDate(value.dueDate as Moment),
        _.toString(value.partialSum),
        value.accountingDoc ?? ''
      ].join());

    return _.isEmpty(outputFormatted) ? prev : [
      ...prev,
      {
        input:[firstItem, ... restOfInputData],
        output: outputFormatted,
      }
    ];
  }, []);
