import { Divider, Typography } from 'antd';
import { AnimatePresence, motion } from 'framer-motion';
import React, { ReactNode } from 'react';


const { Title, Paragraph } = Typography;

const ImpCard = ({
  children,
  title,
  icon,
  desc,
}: {
  children: ReactNode,
  title: string | ReactNode,
  icon: ReactNode,
  desc: string | ReactNode,
}) =>
  (
    <AnimatePresence>
      <motion.div
        transition={{
          ease: 'easeInOut',
          duration: 1,
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Title
          level={2}
          style={{ marginTop: 48 }}
        >
          {icon}
          {' '}
          {title}
        </Title>
        <Paragraph>
          {desc}
        </Paragraph>
        <Divider />
        {children}
      </motion.div>
    </AnimatePresence>
  );

export default ImpCard;
