import React from 'react';
import { useHistory } from 'react-router';
import { ENV_TYPE } from '../../../env.json';
import { useAppDispatch } from '../../../store';
import { subTypeUpdated } from '../../../store/subscribe';
import { subscriptionStyle } from '../../../styles/subscriptionStyles';
import * as subCodes from '../../../utils/helpers/subCodes';
import SubOptCard from './SubOptCard';
import SubOptListItem from './SubOptListItem';
import SubStandardHead from './SubStandardHead';

const SubStandard = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const onClick = () => {
    dispatch(subTypeUpdated(ENV_TYPE === 'PRODUCTION' ? subCodes.SUB_1199_LIVE : subCodes.SUB_1199_TEST));
    history.push('/payment');
  };
  return (
    <>
      <SubStandardHead
        inCard
      />
      <SubOptCard
        color={subscriptionStyle('standard').color}
        title="STANDARD"
        iconType={subscriptionStyle('standard').icon}
        price="później 1199 PLN + VAT / rok"
        bestFor=""
        bestForDesc="kilkuosobowej kancelarii"
        onClick={onClick}
      >
        <SubOptListItem
          text={(
            <>
              <strong> 5 kont</strong>
              {' '}
              z dodatkowymi funkcjami
            </>
          )}
        />
        <SubOptListItem
          text={(
            <>
              {' '}
              Zdalne
              <strong> szkolenia pracowników</strong>
              {' '}
              z działania aplikacji, nie częściej niż raz na kwartał.
            </>
          )}
        />
      </SubOptCard>
    </>
  );
};

export default SubStandard;
