import { CalculatorOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

import thumb from '../../../assets/intrFormGuide.png';
import { RED } from '../../../styles/colors';
import { YT_INTR_FORM_GUIDE } from '../../../utils/helpers/links';
import GuidePost from '../GuidePost';

const { Paragraph } = Typography;

const GuideIntrForm = () => {
  return (
    <GuidePost
      title="Jak dodawać dochodzone kwoty?"
      color={RED}
      desc={(
        <>
          <Paragraph>
            Wyjaśnimy Ci jak dodać pierwsze dane liczbowe do wyliczeń.
          </Paragraph>
          <Link to="/lawsuit">
            <Button
              type="link"
              icon={<CalculatorOutlined />}
            >
              Zacznij używać
            </Button>
          </Link>
        </>
      )}
      src={thumb}
      url={YT_INTR_FORM_GUIDE}
    />
  );
};

export default GuideIntrForm;
