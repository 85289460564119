
import React from 'react';
import styled from 'styled-components';
import Loading from '../../routes/Loading';
import { useTypedSelector } from '../../store';
import useAuthListener from '../hooks/useAuthListener';


const LoadingProvider = (
  { children }: {
    children: React.ReactNode
  }
) => {
  const loading = useTypedSelector(state =>
    state.appLoading.loading);
  useAuthListener();
  return (
    <>
      <DisableScrolling isLoading={loading}>{children}</DisableScrolling>
      <Loading />
    </>
  );
};

export default React.memo(LoadingProvider);


const DisableScrolling = styled.div<{ isLoading: boolean }>`
  overflow: ${({ isLoading }) =>
    isLoading ? 'hidden' : 'visible'};
`;
