/* eslint-disable react-hooks/exhaustive-deps */
import { Col } from 'antd';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

import ImportExternalCommData from '../components/Import/ImpExternal/ImpExternalCommData';
import ImportExternalEndDate from '../components/Import/ImpExternal/ImpExternalEndDate';
import ImpExternalSteps from '../components/Import/ImpExternal/ImpExternalSteps';
import ImpExternalSumUp from '../components/Import/ImpExternal/ImpExternalSumUp';
import AnimOpacity from '../components/UI/Anim/AnimOpacity';
import PrivateContainer from '../components/UI/Containers/PrivateContainer';
import DotsEnding from '../components/UI/DotsEnding';
import NavPrivate from '../components/UI/Nav/NavPrivate';
import { useTypedSelector } from '../store';
import { RowExt } from '../styles';

const ImpExternal = () => {
  const history = useHistory();

  const { externalInterestData, externalPaymentData } = useTypedSelector(state =>
    state.impExternal);

  useEffect(() => {
    if (_.isEmpty(externalInterestData) && _.isEmpty(externalPaymentData)){
      history.goBack();
    }
  }, []);

  return (
    <PrivateContainer>
      <AnimOpacity>
        <NavPrivate />
        <ImpExternalSteps />
        <RowExt marginTop="md" fullWidth>
          <Col
            lg={{
              offset:0,
              span:24,
            }}
            xs={{
              offset:2,
              span: 20,
            }}
          >
            <ImpExternalActions />
          </Col>
        </RowExt>
      </AnimOpacity>
      <DotsEnding />
    </PrivateContainer>
  );
};
const ImpExternalActions = () => {
  const step = useTypedSelector(state =>
    state.impExternal.step);
  if (step === 0) return <ImportExternalEndDate />;
  if (step === 1) return <ImportExternalCommData />;
  return <ImpExternalSumUp />;
};


export default ImpExternal;
