import { NumberOutlined, StopOutlined, WarningOutlined } from '@ant-design/icons';
import { Card, List } from 'antd';
import _ from 'lodash';
import React from 'react';
import nextId from 'react-id-generator';

import { BLUE, RowMd } from '../../../../styles';
import { RED } from '../../../../styles/colors';
import { ImportWarning } from '../../../../utils/hooks/useImpOrcValidate';

const ImpOrcWarningList = (
  { warningData }:{
    warningData: {
      order: number,
      warnings: ImportWarning[]
    }[]
  }) => {

  if (_.isEmpty(warningData)) return null;

  return (
    <>
      <RowMd />
      <Card
        title="Modyfikacje danych lub odmowa importu"
        bordered={false}
        extra={(
          <WarningOutlined />
)}
      >
        {warningData.map(item =>
          (
            <Card
              key={nextId()}
              type="inner"
              title={(
                <>
                  Dokument
                  {' '}
                  <NumberOutlined />
                  {' '}
                  {item.order}
                </>
)}
              style={{ marginTop:12 }}
            >
              {item.warnings.map(warning =>
                (
                  <List.Item
                    key={nextId()}
                  >
                    <List.Item.Meta
                      key={nextId()}
                      avatar={warning.isFatal ? (
                        <StopOutlined style={{ color: RED }} />
                      ) : <WarningOutlined style={{ color: BLUE }} />}
                      title={warning.isFatal ? 'Odmowa importu' : 'Zmodyfikowano dane | informacja'}
                      description={warning.message}
                    />
                  </List.Item>
                ))}
            </Card>
          ))}
      </Card>
    </>
  );
};

export default ImpOrcWarningList;