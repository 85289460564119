import { UserDeleteOutlined } from '@ant-design/icons';
import { Button, Col, Popconfirm, Tag, Typography } from 'antd';
import React from 'react';
import { giverRemoveFromSubscription } from '../../services/connectedUsers';
import { BLUE, ButtonFullWidth, GRID_MD, RowExt } from '../../styles';
import { useAppDispatch } from '../../store';
import useWindowSize from '../../utils/hooks/useWindowSize';


const DeleteButton = (
  {
    isAdmin,
    removeUser
  }: {
    isAdmin?: boolean,
    removeUser: () => void
  }
) => {
  const { width } = useWindowSize();
  if (isAdmin && width < GRID_MD) return (
    <Tag color={BLUE}>
      {width < GRID_MD ? 'Admin' : 'Administrator'}
    </Tag>
  );
  if (isAdmin && width >= GRID_MD) return (
    <ButtonFullWidth
      icon={<UserDeleteOutlined />}
      disabled
    >
      Usuń użytkownika
    </ButtonFullWidth>
  );
  if (width < GRID_MD) return (
    <Popconfirm
      title="Jesteś pewna/pewien?"
      onConfirm={removeUser}
    >
      <Button
        icon={<UserDeleteOutlined />}
      />
    </Popconfirm>
  );
  return (
    <Popconfirm
      title="Jesteś pewna/pewien?"
      onConfirm={removeUser}
    >
      <ButtonFullWidth
        icon={<UserDeleteOutlined />}
      >
        {width < GRID_MD ? null : 'Usuń użytkownika'}
      </ButtonFullWidth>
    </Popconfirm>
  );
};

const SubscriptionUser = (
  {
    email,
    isAdmin,
    receiverId
  }: {
    email: string,
    isAdmin?: boolean,
    receiverId: string
  }
) => {
  const dispatch = useAppDispatch();
  const { width } = useWindowSize();

  return (
    <RowExt marginTop={24} align="middle">
      <Col
        xs={{ span:14 }}
      >
        <RowExt justify="space-between">
          <Typography.Text>
            <strong>{email}</strong>
          </Typography.Text>
          {isAdmin && width >= GRID_MD ? (
            <Tag color={BLUE}>
              Administrator
            </Tag>
          ) : null}
        </RowExt>
      </Col>
      <Col
        xs={{
          span: 8, offset: 2
        }}
      >
        <RowExt justify="end">
          <DeleteButton
            isAdmin={isAdmin}
            removeUser={() =>
              giverRemoveFromSubscription(dispatch, receiverId)}
          />
        </RowExt>
      </Col>
    </RowExt>
  );
};

export default SubscriptionUser;
