import { RadioChangeEvent, Radio, Typography } from 'antd';
import React, { useState } from 'react';
import { BankNames, BANK_ALIOR, readCvs, BANK_M_BANK, BANK_PKO_BP, BANK_PKO_SA } from '../../../../services/importCvs';
import { useAppDispatch } from '../../../../store';
import { RowMd, ButtonFullWidth, RowExt, CardRadioButton, RowSm, GRID_SM } from '../../../../styles';
import useWindowSize from '../../../../utils/hooks/useWindowSize';

const ImportCsvPayments = () => {
  const dispatch = useAppDispatch();

  const { width } = useWindowSize();

  const [bankName, setBankName] = useState<BankNames>(BANK_ALIOR);

  const cvsInput = React.useRef<HTMLInputElement>(null);

  const handleCvsClick = () => {
    if (cvsInput.current && cvsInput) {
      cvsInput.current.click();
    }
  };

  const onBankSwitch = (event:RadioChangeEvent) => {
    setBankName(event.target.value);
  };

  return (
    <>
      <input
        type="file"
        ref={cvsInput}
        onChange={(event) =>
          readCvs(event,
            bankName,
            dispatch)}
        style={{ display: 'none' }}
        accept="csv"
      />
      <RowMd />
      <ButtonFullWidth
        type="primary"
        onClick={handleCvsClick}
      >
        Wczytaj plik csv
      </ButtonFullWidth>

      <Radio.Group
        onChange={onBankSwitch}
        value={bankName}
      >
        <RowExt justify='center' align="middle" marginTop="sm">
          {width > GRID_SM ? (
            <Typography.Title
              level={5}
              style={{
                margin: 0,
                lineHeight:0,
                marginRight: 24,
                marginTop: 16,
              }}
            >
              Wybierz bank
            </Typography.Title>
          ) : null}
          <CardRadioButton value={BANK_ALIOR}>
            Alior
          </CardRadioButton>
          <CardRadioButton value={BANK_M_BANK}>
            MBank
          </CardRadioButton>
          <CardRadioButton value={BANK_PKO_BP}>
            PKO BP
          </CardRadioButton>
          <CardRadioButton value={BANK_PKO_SA}>
            Pekao
          </CardRadioButton>
          fdaf
        </RowExt>

      </Radio.Group>
      <RowSm style={{ height: 28 }}>
        {bankName === BANK_PKO_SA ?
          <Typography.Paragraph type="secondary">Dla banku Pekao wygeneruj plik z 3 kolumnami - datą wpłaty, tytułem i kwotą. Wybierz fomatowanie "UTF-8"</Typography.Paragraph> : null}

      </RowSm>
    </>
  );
};

export default ImportCsvPayments;