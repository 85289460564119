import { Col, Row, Typography } from 'antd';
// import Paragraph from 'antd/lib/skeleton/Paragraph';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { GRID_MD_PX, RowExt } from '../../../styles';
import Icon from '../../../styles/Icon';
import { ABOUT_DASH, ButtonFullWidth } from '../../../styles/ui';
import SubOptListItem from './SubOptListItem';

const { Title } = Typography;

const SubOptCard = (
  {
    color,
    title,
    iconType,
    children,
    price,
    isPremium,
    bestFor,
    bestForDesc,
    onClick
  }: {
    color: string,
    title: string,
    iconType: string,
    children?: ReactNode,
    price?: number | string,
    isPremium?: boolean,
    bestFor: string,
    bestForDesc: string,
    onClick: () => void
  }
) => {
  return (
    <>
      <ColStyled
        xl={{
          span: 6, offset: 1
        }}
        lg={{
          span: 8, offset:0,
        }}
        md={{
          span: 20, offset:2
        }}
        xs={{
          span: 20, offset:2
        }}
      >
        <RowStyled>
          <BorderContainer>
            <RowExt justify="center">
              <IconStyled
                iconType={iconType}
                height="24"
                width="24"
                fill={color}
              />
            </RowExt>
            <RowExt justify="center">
              <TitleStyled
                level={4}
                style={{ letterSpacing: 1.2 }}
              >
                {title}
              </TitleStyled>
            </RowExt>
            <RowExt paddingTop={12} justify="center">
              <Desc>
                Najlepsze dla
                {' '}
                {bestFor}
              </Desc>
            </RowExt>
            <RowExt justify="center">
              <Desc>
                {bestForDesc}
              </Desc>
            </RowExt>
            <RowExt marginTop={24}>
              <Col span={20} offset={2}>
                <ButtonFullWidth
                  type="primary"
                  onClick={onClick}
                >
                  {isPremium ? 'SKONKTAKTUJ SIĘ Z NAMI' : 'ZACZNIJ OKRES PRÓBNY'}
                </ButtonFullWidth>
              </Col>
            </RowExt>
            <RowExt justify="center" marginTop={24}>
              <TitleStyled
                level={5}
                style={{ letterSpacing: 1.2 }}
              >
                {isPremium ? 'Cena, okres i szkolenia' :  '14-dniowy okres próbny,'}
              </TitleStyled>
            </RowExt>
            <RowExt justify="center" paddingBottom={12}>
              <TitleStyled
                level={5}
                style={{ letterSpacing: 1.2 }}
              >
                {isPremium ? 'ustalane indywidualnie' : price}
              </TitleStyled>
            </RowExt>
            <SubOptListItem
              text="Zmień plik excel w tekst pozwu"
            />
            <SubOptListItem
              text="Wyliczenia walutowe"
            />
            <SubOptListItem
              text="Aktywne wsparcie darmowej części projektu - w tym kodów wyliczeń"
            />
            {children}
          </BorderContainer>
        </RowStyled>
      </ColStyled>
    </>
  );
};

export default SubOptCard;

const ColStyled = styled(Col)`
  padding: 0 2px;
  @media only screen and (max-width: ${GRID_MD_PX}) {
    margin-bottom: 48px;
	}
`;

const BorderContainer = styled.div`
   border: ${ABOUT_DASH};
   padding: 18px;
   width: 100%;
   height: 100%;
   border-radius: 4px;
`;

const IconStyled = styled(Icon)`
  margin: 24px 0 12px 0;
`;

const TitleStyled = styled(Title)`
   letter-spacing: 1.2;
`;

const Desc = styled.div`
  font-family: Fira mono;
  text-align: center;

`;

const RowStyled = styled(Row)`
  height: 100%;
`;