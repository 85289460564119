/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENV_TYPE } from '../env.json';
import * as subCodes from '../utils/helpers/subCodes';

interface PaymentInfoState {
  subType: string,
}

const initialState: PaymentInfoState = { subType: ENV_TYPE === 'PRODUCTION' ? subCodes.SUB_99_LIVE : subCodes.SUB_99_TEST };

export const subscribeSlice = createSlice({
  name: 'subscribe',
  initialState,
  reducers: {
    subTypeUpdated: (state: PaymentInfoState, action:PayloadAction<string>) => {
      state.subType = action.payload;
    },
    subscribeReset:() =>
      initialState
  },
});

export const {
  subTypeUpdated,
  subscribeReset
} = subscribeSlice.actions;

export default subscribeSlice.reducer;




