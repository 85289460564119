import { Col, Modal, Typography } from 'antd';
import { updateDoc } from 'firebase/firestore';
import React, { useState } from 'react';
import { Img } from 'react-image';
import ReactPlayer from 'react-player';
import { shallowEqual } from 'react-redux';
import styled from 'styled-components';

import thumbPayment from '../../../assets/howToPayments.png';
import thumbIntr from '../../../assets/intrFormGuide.png';
import topBar from '../../../assets/topBar.png';
import { auth } from '../../../services/firebase/auth';
import { personalDataRef } from '../../../services/firebase/firestoreRefs';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { intrGuideClosed, paymentGuideClosed } from '../../../store/user';
import { GRID_LG, GRID_MD, GRID_SM, RowExt } from '../../../styles';
import { RED } from '../../../styles/colors';
import Icon from '../../../styles/Icon';
import { YT_INTR_FORM_GUIDE, YT_PAYMENTS_GUIDE } from '../../../utils/helpers/links';
import useIsPaymentAssignmentOn from '../../../utils/hooks/useIsPaymentAssignmentOn';
import useIsTransacOrAgree from '../../../utils/hooks/useIsTransacOrAgree';
import useWindowSize from '../../../utils/hooks/useWindowSize';
import VideoPlaceholder from '../../About/Video/VideoPlaceholder';

const LsGuideModal = () => {
  const dispatch = useAppDispatch();
  const { width } = useWindowSize();
  const [isVideoVisible, setVideoVisible] = useState(false);

  const step = useTypedSelector((state) =>
    state.lawsuitUI.step);

  const isTransacOrAgree = useIsTransacOrAgree();

  const isPaymentAssignmentOn = useIsPaymentAssignmentOn();

  const isInterestFormVisible = isTransacOrAgree && step === 3 || !isTransacOrAgree && step === 2;

  const isAssignPaymentsVisible = isPaymentAssignmentOn
  && (isTransacOrAgree ?  step === 4 : step === 3);

  const {
    isIntrGuideClosed,
    isPaymentGuideClosed,
    isNonAnonymousUserLoggedIn
  } = useTypedSelector((state) =>
    state.user, shallowEqual);

  const isIntrGuidVisible = isInterestFormVisible && !isIntrGuideClosed;

  const isPaymentGuideVisible = isAssignPaymentsVisible && !isPaymentGuideClosed;

  const onClose = () => {
    const { currentUser } = auth;
    if (isNonAnonymousUserLoggedIn && currentUser){
      const updateData = isIntrGuidVisible ?
        { isIntrGuideClosed:true } :
        { isPaymentGuideClosed: true };
      updateDoc(personalDataRef(currentUser.uid), updateData);
    }
    isIntrGuidVisible ?
      dispatch(intrGuideClosed()) :
      dispatch(paymentGuideClosed());
    setVideoVisible(false);
  };

  const videoSize = () => {
    if (width >= GRID_LG) return {
      width: 960, height: 540
    };
    if (width >= GRID_MD) return {
      width: 720, height: 405
    };
    if (width >= GRID_SM) return {
      width: 480, height: 270
    };
    return {
      width: 320, height: '100%'
    };
  };

  const showVideo = () => {
    if (width >= GRID_SM){
      setVideoVisible(true);
    } else {
      window.open(isIntrGuidVisible ? YT_INTR_FORM_GUIDE : YT_PAYMENTS_GUIDE, '_blank');
    }
  };

  return (
    <Modal
      visible={isIntrGuidVisible || isPaymentGuideVisible}
      onCancel={onClose}
      footer={false}
      destroyOnClose
      // eslint-disable-next-line no-nested-ternary
      width={isVideoVisible ? videoSize().width + 40 : width >= GRID_MD ? '38%' : '100%'}
      style={isVideoVisible ? { background: 'none !important' } : undefined}
      title={isVideoVisible ? (
        <Icon
          height="18"
          width="18"
          fill="white"
          iconType="PLAY"
        />
      ) : null}
    >

      {isVideoVisible ? (
        <VideoCol>
          <Player
            url={isIntrGuidVisible ? YT_INTR_FORM_GUIDE : YT_PAYMENTS_GUIDE}
            volume={0.5}
            playing={isVideoVisible}
            height={videoSize().height}
            width={videoSize().width}
            controls
          />
        </VideoCol>
      ) :  (
        <>
          <VideoCol>
            <VideoPlaceholder
              showVideo={showVideo}
              src={isIntrGuidVisible ? thumbIntr : thumbPayment}
              color={RED}
              noBorder
            />
          </VideoCol>

          <RowExt marginTop={isIntrGuidVisible ? 0 : 24}>
            <Typography.Paragraph>
              {`Zobacz wprowadzające video tłumaczące jak ${isIntrGuidVisible ? 'dodać dochodzone kwoty' : 'przypisać wpłaty do roszczeń'}`}
              . Zawsze możesz
              {' '}
              <strong>wrócić do instrukcji</strong>
              {' '}
              przyciskając przycisk "VIDEO instrukcja" na pasku aplikacji.
            </Typography.Paragraph>
          </RowExt>
          <RowExt justify="center">
            <StyledImg
              src={topBar}
            />
          </RowExt>
        </>
      )}
    </Modal>
  );
};

export default LsGuideModal;

const StyledImg = styled(Img)`
  width: 55%;
  /* height: 42px; */
`;

export const Player = styled(ReactPlayer)`
  width: 960px;
  border: none !important;
`;

const VideoCol = styled(Col)<{ background?: string }>`
  /* padding-top: 24px; */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: none !important;
  @media only screen and (max-width: 767px) {
    background-color: white;
    padding: 0;
    border-right: none;
	}
`;