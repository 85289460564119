/* eslint-disable react/react-in-jsx-scope */
import { initializeAnalytics } from 'firebase/analytics';
import _ from 'lodash';
import { useEffect } from 'react';
import { BrowserRouter as AppRouter, Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';


import { LocationParams } from './@types';
import Cookies from './components/UI/Cookies';
import About from './routes/About';
import Aplikanci from './routes/Aplikanci';
import CodesFolder from './routes/CodesFolder';
import CompensationBasis from './routes/CompensationBasis';
import ConnectedSubUsers from './routes/ConnectedSubUsers';
import Currency from './routes/Currency';
import Dashboard from './routes/Dashboard';
import Developer from './routes/Developer';
import ExecActsCapital from './routes/ExecActsCapital';
import ExecActsLate from './routes/ExecActsLate';
import ExecActsTransaction from './routes/ExecActsTransaction';
import Guides from './routes/Guides';
import Home from './routes/Home';
import ImpExternal from './routes/ImpExternal';
import ImportOcr from './routes/ImpOcr';
import ImportExcel from './routes/ImportExcel';
import Intro from './routes/Intro';
import Lawsuit from './routes/Lawsuit';
import Login from './routes/Login';
import LsCode from './routes/LsCode';
import NotFound from './routes/NotFound';
import Payment from './routes/Payment';
import PaymentSuccess from './routes/PaymentSuccess';
import Privacy from './routes/Privacy';
import ResultLs from './routes/ResultLs';
import ResultLsCode from './routes/ResultLsCode';
import ResultLsLocal from './routes/ResultLsLocal';
import RoadMap from './routes/RoadMap';
import SignUp from './routes/SignUp';
import Subscribe from './routes/Subscribe';
import Terms from './routes/Terms';
import User from './routes/User';
import { useTypedSelector } from './store';
import LoadingProvider from './utils/hoc/LoadingProvider';
import SaveProvider from './utils/hoc/SaveProvider';
import ScrollToTop from './utils/hoc/ScrollToTop';
import app from './_firebase';

type Routes = {
  path: string,
  component: () => JSX.Element,
}[];

const publicRoutes:Routes = [{
  path: '/',
  component: Home,
}, {
  path:'/lawsuit',
  component: Lawsuit,
}, {
  path: '/about',
  component: About,
}, {
  path: '/intro',
  component: Intro,
}, {
  path: '/guides',
  component: Guides,
}, {
  path: '/currency',
  component: Currency,
}, {
  path: '/importExcel',
  component: ImportExcel,
}, {
  path:'/privacy',
  component: Privacy,
}, {
  path:'/terms',
  component: Terms,
}, {
  path:'/codesFolder',
  component: CodesFolder,
}, {
  path:'/aplikanci',
  component: Aplikanci,
}, {
  path:'/roadMap',
  component: RoadMap,
}, {
  path: '/resultLsLocal',
  component: ResultLsLocal,
}, {
  path: '/execActsTransaction',
  component: ExecActsTransaction
}, {
  path: '/execActsLate',
  component: ExecActsLate,
}, {
  path: '/execActsCapital',
  component: ExecActsCapital,
}, {
  path: '/compensationBasis',
  component: CompensationBasis,
}, {
  path: '/resultLsCode/:id',
  component: ResultLsCode,
}, {
  path: '/subscribe',
  component: Subscribe,
}, {
  path: '/payment',
  component: Payment
}, {
  path: '/paymentSuccess',
  component: PaymentSuccess,
}, {
  path: '/lsCode',
  component: LsCode,
}, {
  path: '/developer',
  component: Developer,
}, {
  path: '/ocr',
  component: ImportOcr
}, {
  path:'/importExternal',
  component: ImpExternal,
}];

const privateRoutes:Routes = [{
  path: '/resultLs/:id',
  component: ResultLs,
}, {
  path: '/dashboard',
  component: Dashboard,
}, {
  path: '/user',
  component: User,
},  {
  path:'/connectedSubscriptionUsers',
  component: ConnectedSubUsers,
}];

const authRoutes:Routes = [{
  path: '/login',
  component: Login,
}, {
  path: '/signUp',
  component: SignUp,
}];

const shallReturnTo = (props: RouteComponentProps, userExists: boolean) => {
  const location = props.location as LocationParams;
  return _.isString(location?.state?.returnTo) && userExists;
};

const Router = () => {
  const userExists = useTypedSelector(state =>
    state.user.isNonAnonymousUserLoggedIn);

  useEffect(() => {
    initializeAnalytics(app);
    // axios.post('http://127.0.0.1:5000/predict').then(e =>
    //   console.log(e)).catch(e =>
    //   console.log(e));
  }, []);
  return (
    <>
      <Cookies />
      <LoadingProvider>
        <AppRouter>
          <ScrollToTop />
          <SaveProvider>
            <Switch>
              {publicRoutes.map(({ path, component }) =>
                (
                  <Route
                    key={path}
                    {...{
                      path,
                      component,
                      exact: true,
                    }}
                  />
                ))}
              {privateRoutes.map(({ path, component: Component }) =>
                (
                  <Route
                    key={path}
                    path={path}
                    exact
                    render={() =>
                      !userExists ? (
                        <Redirect
                          to={{ pathname: '/' }}
                        />
                      ) : (
                        <Component />
                      )}
                  />
                )
              )}
              {authRoutes.map(({ path, component: Component }) =>
                (
                  <Route
                    key={path}
                    path={path}
                    exact
                    render={(props) => {
                      return (
                        shallReturnTo(props, userExists) ? (
                          <Redirect
                            to={{ pathname: '/dashboard' }}
                          />
                        ) : (
                          <Component />
                        ));
                    }}
                  />
                )
              )}
              <Route path="*" component={NotFound} />
            </Switch>
          </SaveProvider>
        </AppRouter>
      </LoadingProvider>
    </>
  );
};

export default Router;

