import { PaymentData } from '../../store/interestStorage';
import { convertDateToString } from './date';


export const formatPaymentDataToSave = (paymentData: PaymentData) =>
  paymentData.map(paymentItem =>
    ({
      ...paymentItem,
      paymentDate: convertDateToString(paymentItem.paymentDate),
      statementDate: convertDateToString(paymentItem.statementDate),
    }));