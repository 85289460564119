import Dinero from 'dinero.js';
import _ from 'lodash';

export const toUnit = (num: number) => {
  if (_.isNumber(num)){
    return Dinero({ amount: Math.trunc(num) }).toUnit();
  }
  return  NaN;
};
// only for data input. Test on
export const toAmount = (num: number) => {
  if (_.isNumber(num)){
    const amount = num * 100;
    return Dinero({ amount: Math.trunc(amount) }).getAmount();
  }
  return  NaN;
};