import { Col, Row } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { ComponentChildren } from '../../../@types';

const MenuContainer = ({ children }: ComponentChildren) => {
  return (
    <ColAllPage
      span={10}
    >
      <RowAllPage
        justify="end"
      >
        <Container>
          <ContainerColumn>
            {children}
          </ContainerColumn>
        </Container>
      </RowAllPage>
    </ColAllPage>
  );
};

export default MenuContainer;


const ColAllPage = styled(Col)`
   height: 100%
`;

const RowAllPage = styled(Row)`
   height: 100%
`;

const Container = styled.div`
  width: 426px;
`;

const ContainerColumn = styled.div`
    height: 100%;
    justify-content: space-between;
    width: 100%;
    display: flex;
    flex-direction: column;
	@media only screen and (max-width: 768px) {
		width: 768px;
	}
`;