import React from 'react';
import nextId from 'react-id-generator';
import { shallowEqual } from 'react-redux';

import { useTypedSelector } from '../../../store';
import { JUST_WITH_CAP } from '../../../store/justOpts';
import { interestBasicSumUp, interestCoveredText, interestPartialDesc, interestPartialIntro, periodText } from '../../../utils/hooks/justification/justInterestPartials';

import JustInterestsFulfilled from './JustInterestFulfilled';
import JustParagraph from './JustParagraph';

const JustInterestPartials = () => {
  const { partialResults } = useTypedSelector(state =>
    state.sumUp, shallowEqual);
  const { justIntCap } = useTypedSelector(state =>
    state.justOpts, shallowEqual);

  if (justIntCap === JUST_WITH_CAP){
    return (
      <>
        {partialResults.map((result, index) => {
          const {
            interestsSumFulfilledUncovered,
            isClaimFromFulfilled
          } = result;
          const singleResult = partialResults.length === 1;
          const plural = result.periodResults?.length ? result.periodResults?.length > 1 : false;
          const shouldShowFulfilled = interestsSumFulfilledUncovered > 0 && isClaimFromFulfilled;
          return (
            <div
              key={result.key}
            >
              {/* A. interest partial intro */}
              <JustParagraph
                text={interestPartialIntro({
                  ...result,
                  singleResult,
                  index,
                })}
              />
              {/* -------------------------------- fulfilled ------------------------------- */}

              <>
                {/*
                  B. desc
                  C. periods
                  D. interest calculation sum basis
                  E. fulfilled sum up
                  */}
                <JustInterestsFulfilled
                  result={result}
                  index={index}
                  shouldShowFulfilled={shouldShowFulfilled}
                />
              </>
              {/* -------------------------------- basic ------------------------------- */}
              {/* B. desc */}
              <JustParagraph
                text={interestPartialDesc(result, false)}
              />
              {/* C. periods */}
              {result.periodResults?.map((periodResult) => {
                return (
                  <JustParagraph
                    key={nextId()}
                    text={periodText(
                      periodResult,
                      'odsetki',
                      result.periodResults.length - 1 === 0
                    )}
                  />
                );
              })}

              {plural ? (
                // F. basic interests sum up
                <JustParagraph
                  key={nextId()}
                  text={interestBasicSumUp(result.periodResults, result.interestsSum, result.claimSum)}
                />
              ) : null}
              {/* -------------------------- interest only - covered  --------------------------
                  G. covered for fulfilled are described in payments justification
              */}
              <JustParagraph
                text={interestCoveredText(result)}
              />
            </div>
          );
        }
        )}
      </>
    );
  }
  return null;
};


export default JustInterestPartials;
