import { Col } from 'antd';
import React from 'react';
import { YT_CURRENCY } from '../../../utils/helpers/links';
import { useTypedSelector } from '../../../store';
import { RowExt } from '../../../styles';
import SubInfo from '../../UI/SubInfo';
import CurrencyActions from './CurrencyActions';
import CurrencyCard from './CurrencyCard';
import CurrencyDate from './CurrencyDate';
import CurrencyJust from './CurrencyJust';
import CurrencyResult from './CurrencyResult';
import CurrencySum from './CurrencySum';
import CurrencyType from './CurrencyType';
import currThumb from '../../../assets/currThumb.png';

const CurrencyForm = () => {
  const isSubscriber = useTypedSelector(state =>
    state.user.isSubscriber);
  return (
    <CurrencyCard>
      <RowExt marginTop={24} />
      <SubInfo
        url={YT_CURRENCY}
        thumbSrc={currThumb}
      />
      {isSubscriber ? (
        <>
          <RowExt>
            <CurrencySum />
            <Col sm={{ span:2 }} xs={{ span: 0 }} />
            <CurrencyType />
          </RowExt>
          <CurrencyDate />
        </>
      ) : null}
      <CurrencyActions />
      <CurrencyJust />
      <CurrencyResult />
    </CurrencyCard>
  );
};

export default CurrencyForm;
