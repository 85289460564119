import { UpOutlined } from '@ant-design/icons';
import { Anchor, Button, Col, Row } from 'antd';
import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import styled from 'styled-components';
import { GRID_LG_PX } from '../../styles';


const { Link } = Anchor;

const SumUpNav = () => {
  return (
    <Container>
      <RowStyled
        id="intro"
      >
        <Col
          xl={{ span: 21 }}
          lg={{ span: 21 }}
          md={{ span:0 }}
          xs={0}
        />
        <Col
          xl={{ span: 3 }}
          lg={{ span: 3 }}
          md={{ span:0 }}
          xs={0}
        >
          <AnchorStyled
            offsetTop={180}
            style={{
              marginLeft: 12, fontFamily: 'Fira sans', background: 'none'
            }}
          >
            <Link href="#main" title="Wynik" />
            <Link href="#wps" title="WPS i Opłata" />
            <Link href="#justification" title="Uzasadnienie" />
            <Link href="#details" title="Tabele" />
            <Link href="#main" title="">
              <AnchorLink href="#main">
                <Button icon={<UpOutlined />} />
              </AnchorLink>
            </Link>
          </AnchorStyled>
        </Col>
      </RowStyled>
    </Container>
  );
};

export default SumUpNav;

const Container = styled.div`
  @media only screen and (min-width: 1441px) {
    margin-right: 10vw
  }  
`;

const RowStyled = styled(Row)`
  @media only screen and (min-width: ${GRID_LG_PX}) {
    margin-top: -172px;
  }  
`;

const AnchorStyled = styled(Anchor)`
    margin-top:12px;
    font-family: 'Fira sans';
    background: none;
  
`;
