import React from 'react';
import { shallowEqual } from 'react-redux';

import { useTypedSelector } from '../../../store';
import useCompSum from '../../../utils/hooks/justification/useCompSum';
import { compensationBasis, compensationText, compIntroText } from '../../../utils/hooks/justification/useCompText';
import JustParagraph from './JustParagraph';

const JustCompensation = () => {
  const { partialResults } = useTypedSelector(state =>
    state.sumUp, shallowEqual);

  const { compensationOn } = useTypedSelector(state =>
    state.sumUp.lawsuitOpts, shallowEqual);

  const justIntType = useTypedSelector(state =>
    state.justOpts.justIntCap);

  const { withComp } = useTypedSelector(state =>
    state.justOpts, shallowEqual);

  const compSum = useCompSum();

  if (compensationOn && withComp){
    return (
      <>
        <JustParagraph
          text={compIntroText(
            justIntType,
            partialResults.length === 1
          )}
        />
        {partialResults.map((result, index) => {
          return (
            <JustParagraph
              key={result.key}
              text={compensationText({
                ...result,
                index: partialResults.length > 1 ? index : null
              })}
            />
          );
        })}
        <JustParagraph
          text={compSum}
        />
        <JustParagraph
          text={compensationBasis(partialResults)}
        />
      </>
    );
  }
  return null;
};



export default JustCompensation;
