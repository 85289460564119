import { notification } from 'antd';
import { signOut } from 'firebase/auth';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { auth } from '../../../services/firebase/auth';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { subscriberStatusReset } from '../../../store/user';
import { lsSaveReset } from '../../../store/lawsuitUI';
import { BLUE, EmptyButton } from '../../../styles';
import { errMsgGeneral } from '../../../utils/helpers/errorTexts';
import ButtonLoading from './ButtonLoading';

const LogOutButton = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [isHovered, setIsHovered] = useState(false);
  const [loading, setLoading] = useState(false);
  const userExists = useTypedSelector(state =>
    state.user.isNonAnonymousUserLoggedIn);
  const goLogin = () =>
    history.push('/login');
  const onLogout = async () => {
    setLoading(true);
    try {
      await signOut(auth);
      dispatch(lsSaveReset());
      dispatch(subscriberStatusReset());
      setLoading(false);
    } catch (error){
      setLoading(false);
      notification.error({
        message: errMsgGeneral,
        description: 'Wystąpił błąd, spróbuj jeszcze raz.'
      });
    }
  };
  return (
    <EmptyButton
      onClick={userExists ? onLogout : goLogin}
      onMouseEnter={() =>
        setIsHovered(true)}
      onMouseLeave={() =>
        setIsHovered(false)}
    >
      <ButtonLoading loading={loading}>
        <EmptyButtonText
          isHovered={isHovered}
        >
          {userExists ? 'Wyloguj się' : 'Zaloguj się'}
        </EmptyButtonText>
      </ButtonLoading>
    </EmptyButton>
  );
};

export default LogOutButton;

const EmptyButtonText = styled.span<{ isHovered:boolean }>`
  color: ${({ isHovered }) =>
    isHovered ? 'white' : BLUE};
  padding: 0 10px;
`;