import { Col, Row } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { Dot } from '../../styles';
import { BLUE, BLUE_MEDIUM, RED } from '../../styles/colors';

const DotsEnding = ({ botpadding = 24 }: { botpadding?: number }) => {
  return (
    <DotsContainer justify="space-around" botpadding={botpadding}>
      <Col span={6} />
      <Col span={4}>
        <Dot color={RED} align="center">
          .
        </Dot>
      </Col>
      <Col span={4}>
        <Dot color={BLUE_MEDIUM} align="center">
          .
        </Dot>
      </Col>
      <Col span={4}>
        <Dot color={BLUE} align="center">
          .
        </Dot>
      </Col>
      <Col span={6} />
    </DotsContainer>
  );
};

export default DotsEnding;

const DotsContainer = styled(Row)<{ botpadding: number }>`
	margin-top: -6px;
	padding-bottom: ${({ botpadding }) =>
    `${botpadding}px`};
`;
