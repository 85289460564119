import React from 'react';
import styled from 'styled-components';

import AboutCodes from '../components/About/AboutCodes';
import AboutHeader from '../components/About/AboutHeader';
import AbtAuthorTop from '../components/About/AbtAuthorTop';
import AbtOneLineBot from '../components/About/AbtOneLineBot';
import AbtTwoLinesBot from '../components/About/AbtTwoLinesBot';
import Author from '../components/About/Author/Author';
import Benefits from '../components/About/Benefits';
import FAQ from '../components/About/FAQ/FAQ';
import Sponsor from '../components/About/Sponsor/Sponsor';
import AboutVideo from '../components/About/Video/AboutVideo';
import AbtVideoBot from '../components/About/Video/AbtVideoBot';
import AnimOpacity from '../components/UI/Anim/AnimOpacity';
import DescScreenFooter from '../components/UI/DescScreenFooter';
import { BLUE, ScreenLayout } from '../styles';
import { BLUE_MEDIUM, RED } from '../styles/colors';
import useWindowSize from '../utils/hooks/useWindowSize';


const About = () => {
  const { width } = useWindowSize();
  return (
    <AnimOpacity>
      <ScreenLayout>
        <AboutHeader />
        <AboutVideo />
        <AbtVideoBot />
        <DashedDivider color={BLUE} />
        <AbtVideoBot />
        <DashedDivider />
        <Benefits />
        <AbtOneLineBot />
        <DashedDivider color={RED} />
        <AbtOneLineBot />
        <DashedDivider />
        <AboutCodes />
        <AbtOneLineBot />
        <DashedDivider />
        <Sponsor />
        <AbtTwoLinesBot />
        <DashedDivider />
        <FAQ />
        <AbtAuthorTop />
        <DashedDivider color={BLUE_MEDIUM} />
        <AbtAuthorTop />
        <DashedDivider />
        <Author />
        {width < 992 ? (
          <DashedDivider style={{ marginTop:48 }} />
        ) : null}
        <DescScreenFooter />
      </ScreenLayout>
    </AnimOpacity>
  );
};

export default About;


export const DashedDivider = styled.div<{ color?: string }>`
    width: 100%;
    border-bottom: 1px dashed ${props =>
    props.color || 'rgba(66,71,112,0.09)'};
`;
