import { EuroOutlined, FileExcelOutlined, UploadOutlined } from '@ant-design/icons';
import React from 'react';

import { useHistory } from 'react-router';
import { loadJson } from '../../../services/handleJson';
import { EmptyButton, GRID_LG, GRID_MD, RowExt } from '../../../styles';
import { BLACK, RED } from '../../../styles/colors';
import { ButtonFullWidth, LinkFullWidth } from '../../../styles/ui';
import useWindowSize from '../../../utils/hooks/useWindowSize';
import { HomeMenuWrapper, HomeTitleWrapper } from '../HomeUI/HomeUI';

const MenuTools = () => {
  const { width } = useWindowSize();
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);
  const history = useHistory();
  const handleClick = () => {
    if (hiddenFileInput.current && hiddenFileInput) {
      hiddenFileInput.current.click();
    }
  };
  return (
    <RowExt marginTop={width < GRID_LG ? 24 : 0} marginBottom={width < GRID_LG ? 48 : 0}>
      <HomeTitleWrapper
        borderColor={RED}
        title="Dodatkowe narzędzia"
        level={width <= GRID_MD ? 4 : 5}
      />
      <HomeMenuWrapper>
        <LinkFullWidth
          to={{ pathname: '/importExcel' }}
        >
          <EmptyButton
            style={{ width: '100%' }}
            icon={<FileExcelOutlined />}
            dotted
          >
            Zmień excel w tekst pozwu
          </EmptyButton>
        </LinkFullWidth>
      </HomeMenuWrapper>
      <HomeMenuWrapper>
        <LinkFullWidth
          to={{ pathname: '/currency' }}
        >
          <EmptyButton
            style={{ width: '100%' }}
            icon={<EuroOutlined />}
            dotted
            {...{
              bgColor: 'white',
              color: BLACK,
              hoverColor: 'white',
              hoverBg: BLACK
            }}
          >
            Przelicz walutę do pozwu
          </EmptyButton>
        </LinkFullWidth>
      </HomeMenuWrapper>
      <HomeMenuWrapper>
        <input
          type="file"
          ref={hiddenFileInput}
          onChange={(event) =>
            loadJson(event, history)}
          style={{ display: 'none' }}
          accept="application/json"
        />
        <ButtonFullWidth
          type="dashed"
          icon={<UploadOutlined />}
          onClick={handleClick}
        >
          Załaduj plik z wyliczeniami
        </ButtonFullWidth>
      </HomeMenuWrapper>
    </RowExt>
  );
};

export default MenuTools;
