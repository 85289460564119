import { MailOutlined } from '@ant-design/icons';
import { Col, Form, Row } from 'antd';
import React from 'react';
import styled from 'styled-components';
import facebook from '../../../assets/facebook.png';
import googleIcon from '../../../assets/g-logo.png';
import { ButtonFullWidth } from '../../../styles/ui';

const ButtonIcon = ({ type }: { type: 'facebook' | 'email' | 'google' }) => {
  switch (type) {
    case 'facebook':
      return <GoogleIcon src={facebook} />;
    case 'google':
      return <GoogleIcon src={googleIcon} />;
    default:
      return <MailOutlined style={{ paddingTop: 4 }} />;
  }
};

const AuthButton = ({
  text,
  type,
  onClick,
  disabled = false,
  loading,
}: {
  text: string;
  type: 'facebook' | 'email' | 'google';
  onClick?: (event: React.MouseEvent | undefined) => void;
  disabled?: boolean;
  loading?: boolean;
}) => {
  return (
    <Form.Item name={type}>
      <ButtonFullWidth
        type={type === 'email' && !loading ? 'primary' : 'default'}
        onClick={onClick}
        disabled={disabled}
      >
        {loading ? (
          <div className="lds-ellipsis">
            <div />
            <div />
            <div />
            <div />
          </div>
        ) : (
          <Row align="middle">
            <>
              <Col span={1}>
                <ButtonIcon type={type} />
              </Col>
              <Col span={22}>{text}</Col>
              <Col span={2} />
            </>
          </Row>
        )}
      </ButtonFullWidth>
    </Form.Item>
  );
};

export default AuthButton;

const GoogleIcon = styled.img`
	width: 14px;
	height: 14px;
`;
