import { Col, Row } from 'antd';
import React from 'react';
import { Img } from 'react-image';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import logo from '../../assets/logo.svg';
import { AboutContainer, DashedColLeft, GRID_MD_PX, RowMd } from '../../styles';
import { ScreenBackButton } from '../UI/DescScreenFooter';

const GuidesHeader = () => {
  return (
    <AboutContainer>
      <Row>
        <Col
          xl={{
            offset: 2,
            span: 7,
          }}
          lg={{
            offset: 2,
            span: 7,
          }}
          md={{
            offset: 2,
            span: 6
          }}
          xs={{
            offset: 2,
            span: 12,
          }}
        >
          <Row>
            <Link
              to={{ pathname: '/' }}
            >
              <Logo
                src={logo}
              />
            </Link>
          </Row>
        </Col>
        <DashedColLeft
          xl={{ span: 10 }}
          lg={{ span: 10 }}
          md={15}
          xs={8}
        >
          <RowMd align="middle" justify="end">
            <ScreenBackButton />
          </RowMd>
        </DashedColLeft>
        <Col
          xl={{ span: 1 }}
          lg={{ span: 1 }}
          md={15}
          xs={2}
        />
        <DashedColLeft
          xl={{ span: 2 }}
          lg={{ span: 1 }}
          xs={0}
        />
        <DashedColLeft
          xl={{ span: 2 }}
          lg={{ span: 3 }}
          xs={0}
        />
      </Row>
    </AboutContainer>
  );
};

export default GuidesHeader;

const Logo = styled(Img)`
    width: 124px;
    border-radius: 4px;
    margin-top: 24px;
    margin-bottom: 72px;
  @media only screen and (max-width: ${GRID_MD_PX}) {
    margin-bottom: 48px;
	}
`;



