export const act1 = {
  title: 'OBWIESZCZENIE MINISTRA ROZWOJU W SPRAWIE WYSOKOŚCI ODSETEK USTAWOWYCH ZA OPÓŹNIENIE W TRANSAKCJACH HANDLOWYCH',
  desc: 'z dnia 7 stycznia 2016 r. (M.P. z 2016 r. poz. 43)',
  pars:[
    { text: 'Na podstawie art. 11c w związku z art. 11b pkt 1 ustawy z dnia 8 marca 2013 r. o terminach zapłaty w transakcjach handlowych (Dz.U. poz. 403 oraz z 2015 r. poz. 1830) ogłasza się, że wysokość odsetek ustawowych za opóźnienie w transakcjach handlowych, od dnia 1 stycznia 2016 r. do dnia 30 czerwca 2016 r., wynosi 9,50% w stosunku rocznym.' },
  ]
};
export const act2 = {
  title: 'OBWIESZCZENIE MINISTRA ROZWOJU W SPRAWIE WYSOKOŚCI ODSETEK USTAWOWYCH ZA OPÓŹNIENIE W TRANSAKCJACH HANDLOWYCH',
  desc: 'z dnia 29 czerwca 2016 r. (M.P. z 2016 r. poz. 601)',
  pars:[
    { text: 'Na podstawie art. 11c w związku z art. 11b pkt 2 ustawy z dnia 8 marca 2013 r. o terminach zapłaty w transakcjach handlowych (Dz.U. z 2016 r. poz. 684) ogłasza się, że wysokość odsetek ustawowych za opóźnienie w transakcjach handlowych, od dnia 1 lipca 2016 r. do dnia 31 grudnia 2016 r., wynosi 9,50 % w stosunku rocznym.' },
  ]
};
export const act3 = {
  title: 'OBWIESZCZENIE MINISTRA ROZWOJU I FINANSÓW W SPRAWIE WYSOKOŚCI ODSETEK USTAWOWYCH ZA OPÓŹNIENIE W TRANSAKCJACH HANDLOWYCH',
  desc: 'z dnia 29 grudnia 2016 r. (M.P. z 2017 r. poz. 10)',
  pars:[
    { text: 'Na podstawie art. 11c w związku z art. 11b pkt 1 ustawy z dnia 8 marca 2013 r. o terminach zapłaty w transakcjach handlowych (Dz.U. z 2016 r. poz. 684) ogłasza się, że wysokość odsetek ustawowych za opóźnienie w transakcjach handlowych, od dnia 1 stycznia 2017 r. do dnia 30 czerwca 2017 r., wynosi 9,50% w stosunku rocznym.' },
  ]
};

export const act4 = {
  title: 'OBWIESZCZENIE MINISTRA ROZWOJU I FINANSÓW W SPRAWIE WYSOKOŚCI ODSETEK USTAWOWYCH ZA OPÓŹNIENIE W TRANSAKCJACH HANDLOWYCH',
  desc: 'z dnia 10 lipca 2017 r. (M.P. z 2017 r. poz. 686)',
  pars:[
    { text: 'Na podstawie art. 11c w związku z art. 11b pkt 2 ustawy z dnia 8 marca 2013 r. o terminach zapłaty w transakcjach handlowych (Dz.U. z 2016 r. poz. 684) ogłasza się, że wysokość odsetek ustawowych za opóźnienie w transakcjach handlowych, od dnia 1 lipca 2017 r. do dnia 31 grudnia 2017 r., wynosi 9,50% w stosunku rocznym.' },
  ]
};

export const act5 = {
  title: 'OBWIESZCZENIE MINISTRA PRZEDSIĘBIORCZOŚCI I TECHNOLOGII W SPRAWIE WYSOKOŚCI ODSETEK USTAWOWYCH ZA OPÓŹNIENIE W TRANSAKCJACH HANDLOWYCH',
  desc: 'z dnia 24 stycznia 2018 r. (M.P. z 2018 r. poz. 139)',
  pars:[
    { text: 'Na podstawie art. 11c ustawy z dnia 8 marca 2013 r. o terminach zapłaty w transakcjach handlowych (Dz.U. z 2016 r. poz. 684) ogłasza się, że wysokość odsetek ustawowych za opóźnienie w transakcjach handlowych, od dnia 1 stycznia 2018 r. do dnia 30 czerwca 2018 r., wynosi 9,50% w stosunku rocznym.' },
  ]
};
export const act6 = {
  title: 'OBWIESZCZENIE MINISTRA PRZEDSIĘBIORCZOŚCI I TECHNOLOGII W SPRAWIE WYSOKOŚCI ODSETEK USTAWOWYCH ZA OPÓŹNIENIE W TRANSAKCJACH HANDLOWYCH',
  desc: 'z dnia 4 lipca 2018 r. (M.P. z 2018 r. poz. 671)',
  pars:[
    { text: 'Na podstawie art. 11c ustawy z dnia 8 marca 2013 r. o terminach zapłaty w transakcjach handlowych (Dz.U. z 2016 r. poz. 684 oraz z 2018 r. poz. 650) ogłasza się, że wysokość odsetek ustawowych za opóźnienie w transakcjach handlowych, od dnia 1 lipca 2018 r. do dnia 31 grudnia 2018 r., wynosi 9,50% w stosunku rocznym.' },
  ]
};
export const act7 = {
  title: 'OBWIESZCZENIE MINISTRA PRZEDSIĘBIORCZOŚCI I TECHNOLOGII W SPRAWIE WYSOKOŚCI ODSETEK USTAWOWYCH ZA OPÓŹNIENIE W TRANSAKCJACH HANDLOWYCH',
  desc: 'z dnia 14 stycznia 2019 r. (M.P. z 2019 r. poz. 42)',
  pars:[
    { text: 'Na podstawie art. 11c ustawy z dnia 8 marca 2013 r. o terminach zapłaty w transakcjach handlowych (Dz.U. z 2016 r. poz. 684 oraz z 2018 r. poz. 650) ogłasza się, że wysokość odsetek ustawowych za opóźnienie w transakcjach handlowych, od dnia 1 stycznia 2019 r. do dnia 30 czerwca 2019 r., wynosi 9,50% w stosunku rocznym.' },
  ]
};

export const act8 = {
  title: 'OBWIESZCZENIE MINISTRA PRZEDSIĘBIORCZOŚCI I TECHNOLOGII W SPRAWIE WYSOKOŚCI ODSETEK USTAWOWYCH ZA OPÓŹNIENIE W TRANSAKCJACH HANDLOWYCH',
  desc: 'z dnia 1 lipca 2019 r. (M.P. z 2019 r. poz. 671)',
  pars:[
    { text: 'Na podstawie art. 11c ustawy z dnia 8 marca 2013 r. o terminach zapłaty w transakcjach handlowych (Dz.U. z 2019 r. poz. 118) ogłasza się, że wysokość odsetek ustawowych za opóźnienie w transakcjach handlowych, od dnia 1 lipca 2019 r. do dnia 31 grudnia 2019 r., wynosi 9,50% w stosunku rocznym.' },
  ]
};

export const act9 = {
  title: 'OBWIESZCZENIE MINISTRA ROZWOJU W SPRAWIE WYSOKOŚCI ODSETEK USTAWOWYCH ZA OPÓŹNIENIE W TRANSAKCJACH HANDLOWYCH',
  desc: 'z dnia 23 grudnia 2019 r. (M.P. z 2020 r. poz. 2)',
  pars:[
    { text: 'Na podstawie art. 11c ustawy z dnia 8 marca 2013 r. o przeciwdziałaniu nadmiernym opóźnieniom w transakcjach handlowych (Dz.U. z 2019 r. poz. 118, 1649 i 2020) ogłasza się, że wysokość odsetek ustawowych za opóźnienie w transakcjach handlowych, od dnia 1 stycznia 2020 r. do dnia 30 czerwca 2020 r., wynosi:' },
  ],
  usts: [
    { text: '1) 9,5% w stosunku rocznym - w przypadku transakcji handlowych, w których dłużnikiem jest podmiot publiczny będący podmiotem leczniczym;' },
    { text: '2) 11,5% w stosunku rocznym - w przypadku transakcji handlowych, w których dłużnikiem nie jest podmiot publiczny będący podmiotem leczniczym.' },
  ],
};
export const act10 = {
  title: 'OBWIESZCZENIE MINISTRA ROZWOJU W SPRAWIE WYSOKOŚCI ODSETEK USTAWOWYCH ZA OPÓŹNIENIE W TRANSAKCJACH HANDLOWYCH',
  desc: 'z dnia 2 lipca 2020 r. (M.P. z 2020 r. poz. 610)',
  pars:[
    { text: 'Na podstawie art. 11c ustawy z dnia 8 marca 2013 r. o przeciwdziałaniu nadmiernym opóźnieniom w transakcjach handlowych (Dz.U. z 2020 r. poz. 935 i 1086) ogłasza się, że wysokość odsetek ustawowych za opóźnienie w transakcjach handlowych, od dnia 1 lipca 2020 r. do dnia 31 grudnia 2020 r., wynosi:' },
  ],
  usts: [
    { text: '1) 8,1% w stosunku rocznym - w przypadku transakcji handlowych, w których dłużnikiem jest podmiot publiczny będący podmiotem leczniczym;' },
    { text: '2) 10,1% w stosunku rocznym - w przypadku transakcji handlowych, w których dłużnikiem nie jest podmiot publiczny będący podmiotem leczniczym.' },
  ],
};
export const act11 = {
  title: 'OBWIESZCZENIE MINISTRA ROZWOJU, PRACY I TECHNOLOGII W SPRAWIE WYSOKOŚCI ODSETEK USTAWOWYCH ZA OPÓŹNIENIE W TRANSAKCJACH HANDLOWYCH',
  desc: 'z dnia 28 grudnia 2020 r. (M.P. z 2020 r. poz. 1212)',
  pars:[
    { text: 'Na podstawie art. 11c ustawy z dnia 8 marca 2013 r. o przeciwdziałaniu nadmiernym opóźnieniom w transakcjach handlowych (Dz.U. z 2020 r. poz. 935 i 1086) ogłasza się, że wysokość odsetek ustawowych za opóźnienie w transakcjach handlowych, od dnia 1 stycznia 2021 r. do dnia 30 czerwca 2021 r., wynosi:' },
  ],
  usts: [
    { text: '1) 8,1% w stosunku rocznym - w przypadku transakcji handlowych, w których dłużnikiem jest podmiot publiczny będący podmiotem leczniczym;' },
    { text: '2) 10,1% w stosunku rocznym - w przypadku transakcji handlowych, w których dłużnikiem nie jest podmiot publiczny będący podmiotem leczniczym.' },
  ],
};
export const act12 = {
  title: 'OBWIESZCZENIE MINISTRA ROZWOJU, PRACY I TECHNOLOGII W SPRAWIE WYSOKOŚCI ODSETEK USTAWOWYCH ZA OPÓŹNIENIE W TRANSAKCJACH HANDLOWYCH',
  desc: 'z dnia 25 czerwca 2021 r. (M.P. 2021 poz. 591)',
  pars:[
    { text: 'Na podstawie art. 11c ustawy z dnia 8 marca 2013 r. o przeciwdziałaniu nadmiernym opóźnieniom w transakcjach handlowych (Dz. U. z 2021 r. poz. 424) ogłasza się, że wysokość odsetek ustawowych za opóźnienie w transakcjach handlowych, od dnia 1 lipca 2021 r. do dnia 31 grudnia 2021 r., wynosi: ' },
  ],
  usts: [
    { text: '1) 8,1% w stosunku rocznym ‒ w przypadku transakcji handlowych, w których dłużnikiem jest podmiot publiczny będący podmiotem leczniczym;' },
    { text: '2) 10,1% w stosunku rocznym ‒ w przypadku transakcji handlowych, w których dłużnikiem nie jest podmiot publiczny będący podmiotem leczniczym. ' },
  ],
};
export const act13 = {
  title: 'OBWIESZCZENIE MINISTRA ROZWOJU, PRACY I TECHNOLOGII W SPRAWIE WYSOKOŚCI ODSETEK USTAWOWYCH ZA OPÓŹNIENIE W TRANSAKCJACH HANDLOWYCH',
  desc: 'z dnia 31 grudnia 2021 r. M.P. 2022 poz. 8)',
  pars:[
    { text: 'Na podstawie art. 11c ustawy z dnia 8 marca 2013 r. o przeciwdziałaniu nadmiernym opóźnieniom w transakcjach handlowych (Dz. U. z 2021 r. poz. 424 i 2317) ogłasza się, że wysokość odsetek ustawowych za opóźnienie w transakcjach handlowych, od dnia 1 stycznia 2022 r. do dnia 30 czerwca 2022 r., wynosi: ' },
  ],
  usts: [
    { text: '1) 9,75% w stosunku rocznym ‒ w przypadku transakcji handlowych, w których dłużnikiem jest podmiot publiczny będący podmiotem leczniczym;' },
    { text: '2) 11,75% w stosunku rocznym ‒ w przypadku transakcji handlowych, w których dłużnikiem nie jest podmiot publiczny będący podmiotem leczniczym. ' },
  ],
};