import { onAuthStateChanged, User } from 'firebase/auth';
import { getDoc } from 'firebase/firestore';
import _ from 'lodash';
import { useEffect } from 'react';

import { auth } from '../../services/firebase/auth';
import { personalDataRef } from '../../services/firebase/firestoreRefs';
import { AppDispatch, useAppDispatch } from '../../store';
import { appLoadingStopped } from '../../store/appLoading';
import { intrGuideClosed,
  paymentGuideClosed,
  subscriberStatusFetched,
  SubscriptionTypes,
  userAnonymousStatusFetched } from '../../store/user';

export const subTypes = ['premium', 'standard', 'basic', 'premiumUser', 'standardUser'];

const getUserData = async (dispatch: AppDispatch, user: User ) => {
  await user.getIdToken(true);
  const decodedToken = await user.getIdTokenResult();
  const subscriptionType = decodedToken.claims.stripeRole as SubscriptionTypes;
  const { isAnonymous } = user;
  if (!isAnonymous) {
    dispatch(userAnonymousStatusFetched(true));
    const isSubscriber = _.isString(subscriptionType) && subTypes.includes(subscriptionType);
    dispatch(subscriberStatusFetched({
      isSubscriber,
      subscriptionType: isSubscriber ? subscriptionType : null,
    }));
    const personalData = await getDoc(personalDataRef(user.uid));
    if (personalData.exists()){
      if (personalData.data().isIntrGuideClosed){
        dispatch(intrGuideClosed());
      }
      if (personalData.data().isPaymentGuideClosed){
        dispatch(paymentGuideClosed());
      }
    }
  } else {
    dispatch(userAnonymousStatusFetched(false));
  }
};

const useAuthListener = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async user => {
      try {

        user ?
          await getUserData(dispatch, user) :
          dispatch(userAnonymousStatusFetched(false));
      } catch (e){
        // eslint-disable-next-line no-console
        console.log(e);
      } finally {
        dispatch(appLoadingStopped());
      }
    });
    return () => {
      unsubscribe();
    };
  }, [dispatch]);
};

export default useAuthListener;