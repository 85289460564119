import { SubscriptionTypes } from '../store/user';
import { BLUE, BLUE_MEDIUM, RED } from './colors';
import { DIAMOND, FULL_STAR, STAR } from './iconTypes';

export const subscriptionStyle = (
  subscriptionType: SubscriptionTypes
) => {
  switch (subscriptionType) {
    case 'premium':
      return {
        color:BLUE,
        icon: DIAMOND,
        name: 'SUBSKRYPCJA PREMIUM'
      };
    case 'premiumUser':
      return {
        color:BLUE,
        icon: DIAMOND,
        name: 'SUBSKRYPCJA PREMIUM'
      };
    case 'standard':
      return {
        color:BLUE_MEDIUM,
        icon: FULL_STAR,
        name: 'SUBSKRYPCJA STANDARD'
      };
    case 'standardUser':
      return {
        color:BLUE_MEDIUM,
        icon: FULL_STAR,
        name: 'SUBSKRYPCJA STANDARD'
      };
    default:
      return {
        color:RED,
        icon: STAR,
        name: 'SUBSKRYPCJA PODSTAWOWA'
      };
  }
};