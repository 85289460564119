import { CheckOutlined, EditOutlined } from '@ant-design/icons';
import { Checkbox, Col, Divider, InputNumber, Modal, Typography } from 'antd';
import React from 'react';

import { ButtonFullWidth, EmptyButton, RowMd, RowSm } from '../../../../styles';
import { toUnit } from '../../../../utils/helpers/money';

const ClaimModal = ({
  assignmentSum,
  isAssignmentModalVisible,
  onAssign,
  onAssignAll,
  setAssignmentModalVisible,
  updateSum,
  isInterestFirst,
  setIsInterestFirst
}:{
  assignmentSum: number
  isAssignmentModalVisible: boolean,
  onAssign: () => void,
  onAssignAll: () => void,
  setAssignmentModalVisible: (isAssignmentModalVisible: boolean) => void,
  updateSum:(value:  number | undefined) => void,
  isInterestFirst:boolean,
  setIsInterestFirst: (isInterestFirst:boolean)=>void,
}) => {
  return (
    <Modal
      onCancel={() =>
        setAssignmentModalVisible(false)}
      visible={isAssignmentModalVisible}
      onOk={onAssignAll}
      destroyOnClose
      style={{ background: 'none !important' }}
      footer={false}
    >
      <Typography.Title level={5}>Jaką część wpłaty przypisać?</Typography.Title>
      <RowMd>
        <ButtonFullWidth
          key="submit"
          type="primary"
          onClick={onAssignAll}
          icon={<CheckOutlined />}
        >
          Przypisz maksimum
        </ButtonFullWidth>
      </RowMd>
      <Divider>lub</Divider>
      <RowSm>
        <Col span={11}>
          <InputNumber
            placeholder="0 zł"
            min={0}
            max={10000000000000}
            precision={2}
            onChange={updateSum}
            style={{ width: '100%' }}
            value={toUnit(assignmentSum)}
            decimalSeparator=","
          />
        </Col>
        <Col span={11} offset={2}>
          <EmptyButton
            key="back"
            onClick={onAssign}
            icon={<EditOutlined />}
            full
          >
            Przypisz część wpłaty
          </EmptyButton>
        </Col>
      </RowSm>
      <Divider />
      <RowMd justify='center'>
        <Checkbox
          checked={isInterestFirst}
          onChange={() =>
            setIsInterestFirst(!isInterestFirst)}
        >
          <Typography.Text strong>W pierwszej kolejności zalicz na odsetki</Typography.Text>
        </Checkbox>
      </RowMd>
    </Modal>
  );
};

export default ClaimModal;