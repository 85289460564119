import { AnimatePresence, motion } from 'framer-motion';
import React, { ReactNode } from 'react';

const AnimOpacity = (
  {
    children,
    delay = 0
  }: {
    delay?: number
    children: ReactNode,
  }
) => {
  return (
    <AnimatePresence>
      <motion.div
        transition={{
          ease: 'easeInOut', duration: 0.6, delay,
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
};

AnimOpacity.propTypes = {};

export default AnimOpacity;
