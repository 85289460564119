/* eslint-disable @typescript-eslint/no-explicit-any */
import { AppstoreOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  ToolOutlined,
  UnorderedListOutlined,
  WarningOutlined } from '@ant-design/icons';
import { Button, Divider, Modal, Row, Space } from 'antd';
import React, { useState } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';

import { ENV_TYPE } from '../../../../env.json';
import { updateOcrTrainingData } from '../../../../services/ML/trainingData/ocrTd';
import { useTypedSelector } from '../../../../store';
import { evidListToggled, ocrStepUpdated } from '../../../../store/impOcr';

const ImpOcrEvidBar = () => {

  const userExists = useTypedSelector(state =>
    state.user.isNonAnonymousUserLoggedIn);

  const [isModalVisible, setModalVisible] = useState(false);

  const { isListVisible, step, ocrData } = useTypedSelector(state =>
    state.impOcr, shallowEqual);

  const dispatch = useDispatch();

  const onShowList = () =>
    !isListVisible ? dispatch(evidListToggled()) : null;

  const onShowCards = () =>
    isListVisible ? dispatch(evidListToggled()) : null;

  const onFinish = () => {
    dispatch(ocrStepUpdated(2));

  };

  const onOk = () => {
    setModalVisible(false);
    dispatch(ocrStepUpdated(0));
  };

  const sendData = () =>
    updateOcrTrainingData({
      dispatch,
      userExists,
      ocrData,
    });

  if (step !== 1) return null;

  return (
    <>
      <div style={{ width: '100%' }}>
        <Row
          justify='space-between'
          align='middle'
        >
          <Button
            type="dashed"
            icon={<ArrowLeftOutlined />}
            onClick={() =>
              setModalVisible(true)}
          />

          <Space>
            {ENV_TYPE === 'DEVELOPMENT' ? (
              <>
                <Button
                  type="primary"
                  onClick={sendData}
                  icon={<ToolOutlined />}
                />
                <Divider type='vertical' />
              </>
            ) : null}
            <Divider type='vertical' />
            <Button
              key="1"
              icon={<AppstoreOutlined />}
              type="dashed"
              color={isListVisible ? undefined : 'blue'}
              onClick={onShowCards}
            />
            <Button
              key="2"
              icon={<UnorderedListOutlined />}
              type="dashed"
              color={isListVisible ? 'blue' : undefined}
              onClick={onShowList}
            />
            <Divider type='vertical' />
            <Button
              type="primary"
              icon={<ArrowRightOutlined />}
              onClick={onFinish}
            />
          </Space>
        </Row>
      </div>
      <Modal
        visible={isModalVisible}
        title={(
          <>
            <WarningOutlined />
            {' '}
            Uwaga
          </>
)}
        onOk={onOk}
        onCancel={() =>
          setModalVisible(false)}
      >
        Przejście cofnięcie spowoduje, że rozpoznawanie tekstu zostanie wykonane
        {' '}
        <strong>ponownie</strong>
        . Jeśli nie chcesz zaimportować dodatkowych skanów, sugerujemy tego nie robić.
      </Modal>
    </>
  );
};

export default ImpOcrEvidBar;