import React, { ReactNode } from 'react';
import { shallowEqual } from 'react-redux';

import { useTypedSelector } from '../../../store';
import useIsTransacOrAgree from '../../../utils/hooks/useIsTransacOrAgree';
import SumUpNav from '../../SumUp/SumUpNav';
import PrivateContainer from './PrivateContainer';

const LawsuitContainer = ({ children }: { children?:ReactNode }) => {
  const isTransacOrAgree = useIsTransacOrAgree();

  const isNavVisible = useTypedSelector(state => {
    const { interestDataProvider, step } = state.lawsuitUI;
    return [
      (isTransacOrAgree
        && interestDataProvider === 'MANUAL_PAYMENTS_ASSIGNMENTS'
        && step === 7),
      (isTransacOrAgree
          && interestDataProvider !== 'MANUAL_PAYMENTS_ASSIGNMENTS'
          && step === 6),
      (!isTransacOrAgree
            && interestDataProvider === 'MANUAL_PAYMENTS_ASSIGNMENTS'
            && step === 6),
      (!isTransacOrAgree
              && interestDataProvider !== 'MANUAL_PAYMENTS_ASSIGNMENTS'
              && step === 5),
    ].some(value =>
      value === true);
  }, shallowEqual);
  return (
    <>
      {isNavVisible ? <SumUpNav /> : null}
      <PrivateContainer>
        {children}
      </PrivateContainer>
    </>
  );
};

export default LawsuitContainer;

