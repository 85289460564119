/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const JUST_LS_MAIN = 'JUST_LS_MAIN';
export const JUST_DEM_PAY = 'JUST_DEM_PAY';
export const JUST_JUDGEMENT = 'JUST_JUDGEMENT';

export type JustType = typeof  JUST_LS_MAIN | typeof JUST_DEM_PAY | typeof JUST_JUDGEMENT;

export const JUST_WITH_CAP = 'JUST_WITH_CAP';
export const JUST_WO_CAP =  'JUST_WO_CAP';
export const JUST_WO_INT = 'JUST_WO_INT';


export type JustIntType = typeof JUST_WITH_CAP | typeof JUST_WO_CAP | typeof JUST_WO_INT;

export const JUST_LIST_ONLY_SUMS = 'JUST_LIST_ONLY_SUMS';
export const JUST_LIST_WITH_SUMS = 'JUST_LIST_WITH_SUMS';

export type JustListOptsType = typeof JUST_LIST_ONLY_SUMS | typeof JUST_LIST_WITH_SUMS;

export interface JustOpts {
  justType: JustType,
  justIntCap: JustIntType,
  justToPaymentDay: boolean,
  withComp: boolean,
  withCompWPS: boolean,
  isJustListVisible: boolean;
  isJustDocsVisible: boolean;
  justListOpts: JustListOptsType;
}

const initialState:JustOpts = {
  justType: JUST_LS_MAIN,
  justIntCap: JUST_WITH_CAP,
  justToPaymentDay: true,
  withComp: true,
  withCompWPS: false,
  isJustListVisible:false,
  isJustDocsVisible: false,
  justListOpts: JUST_LIST_WITH_SUMS,
};

export const justOptsSlice = createSlice({
  name: 'justOpts',
  initialState,
  reducers: {
    justTypeUpdated: (state:JustOpts, action:PayloadAction<JustType>) => {
      state.justType = action.payload;
    },
    justIntCapUpdated: (state:JustOpts, action:PayloadAction<JustIntType>) => {
      state.justIntCap = action.payload;
    },
    justToPaymentDayUpdated:(state:JustOpts, action:PayloadAction<boolean>) => {
      state.justToPaymentDay = action.payload;
    },
    withCompUpdated:(state:JustOpts, action:PayloadAction<boolean>) => {
      state.withComp = action.payload;
      state.withCompWPS = action.payload === false ? false : state.withCompWPS;
    },
    withCompWPSUpdated: (state:JustOpts, action:PayloadAction<boolean>) => {
      state.withCompWPS = action.payload;
    },
    isJustListVisibleUpdated: (state:JustOpts, action:PayloadAction<boolean>) => {
      state.isJustListVisible = action.payload;
    },
    isJustDocsVisibleUpdated: (state:JustOpts, action: PayloadAction<boolean>) => {
      state.isJustDocsVisible = action.payload;
    },
    justListOptsUpdated: (state:JustOpts, action: PayloadAction<JustListOptsType>) => {
      state.justListOpts = action.payload;
    },
    justOptsReset:() =>
      initialState
  },
});

export const {
  justTypeUpdated,
  justIntCapUpdated,
  justToPaymentDayUpdated,
  withCompUpdated,
  withCompWPSUpdated,
  isJustListVisibleUpdated,
  isJustDocsVisibleUpdated,
  justListOptsUpdated,
  justOptsReset
} = justOptsSlice.actions;

export default justOptsSlice.reducer;

