import _ from 'lodash';
import moment from 'moment';

import { assignmentDate } from '../../../services/calc/claim';
import { calcOneInterest } from '../../../services/calc/interestsCalc';
import { LawsuitOpts } from '../../../store/lawsuitOpts';
import { PartialResult } from '../../../store/sumUp';
import { arrStrFormat, ENT } from '../../helpers/arrStrFormat';
import { formatDate } from '../../helpers/date';
import { formatPLN } from '../../helpers/formatNumber';
import { getResultNumber } from '../../helpers/getResultNumber';
import { textIf, textIfPad } from '../../helpers/textIf';

/* -------------------------------------------------------------------------- */
/*                            1. PAYMENTS INTRO                               */
/* -------------------------------------------------------------------------- */

/*
  part of basis and payments intro
  only if payments present
  use in iteration over partial results
*/

export const paymentsIntro = (partialResult: PartialResult) => {
  const {
    claimSum,
    partialSum,
    assignedPayments,
  } = partialResult;
  const hasPartialSumDecreased = claimSum < partialSum;
  const isSinglePayment = assignedPayments.length === 1;

  if (_.isEmpty(assignedPayments)) return '';

  return arrStrFormat([
    textIfPad(`W dniu wymagalności kwota główna roszczenia miała wartość ${formatPLN(partialSum)}.`,
      hasPartialSumDecreased),
    textIfPad(`Na poczet kwoty głównej długu zaliczono 
    ${textIfPad('następującą wpłatę', isSinglePayment, 'następujące wpłaty')}:`,
    assignedPayments.length > 0),
  ]);
};

/* -------------------------------------------------------------------------- */
/*                   2. JUSTIFICATION FOR ASSIGNED PAYMENTS                   */
/* -------------------------------------------------------------------------- */


export const justAssignedPayments = (partialResult: PartialResult) => {
  const {
    assignedPayments,
    key,
  } = partialResult;
  if (_.isEmpty(assignedPayments)) return [];
  return assignedPayments.reduce((prev: string[], {
    paymentDate,
    paymentSum,
    transferDescription,
    statementDate,
    assignedTo,
  }, index) => {
    return [
      ...prev,
      [
        ENT,
        `- wpłatę z dnia ${formatDate(paymentDate)} na kwotę ${formatPLN(paymentSum)}.`,
        textIfPad(`Kwota została wpłacona pod tytułem "${transferDescription}".`, transferDescription),
        textIfPad(`Oświadczenie o zaliczeniu zotało złożone w dniu ${formatDate(statementDate ?? '')}.`, statementDate),
        assignedTo.reduce((prevAssignment, currAssignment) =>
          prevAssignment + arrStrFormat([
            textIfPad(`Na poczet długu 
            zaliczono jej cześć o wartości ${formatPLN(currAssignment.sum)}.`,
            (currAssignment.sum !== paymentSum && currAssignment.key === key)),

            textIfPad('Zaliczono ją w pierwszej kolejności na zaległe odsetki związane z tym długiem',
              (currAssignment.isInterestFirst && currAssignment.key === key),
              ' Nie zaliczono jej w pierwszej kolejności na zaległe odsetki związane z tym długiem'),

            textIf('.', index === assignedPayments.length - 1, ';')

          ]), '')
      ].join(''),
    ];
  }, []);
};

/* -------------------------------------------------------------------------- */
/*                  3. ASSIGNED PAYMENTS JUSTIFICATION SUM UP                 */
/* -------------------------------------------------------------------------- */

export const justPaymentsSumUp = (partialResult: PartialResult, lawsuitOpts: LawsuitOpts) => {
  const {
    key: interestItemKey,
    claimSum,
    partialSum,
    interestsSumFulfilledOverall,
    interestsSumFulfilledCovered,
    interestsSumFulfilledUncovered,
    assignedPayments,
    interestsSumCovered,
    assignedPaymentsSum,
  } = partialResult;

  const { interestSum:coveredInterestsSum } = coveredInterestCalc(lawsuitOpts, partialResult);

  const isSinglePayment = assignedPayments.length === 1;

  const isInterestFirstData = assignedPayments.reduce((prev: boolean[], paymentItem) => {
    const paymentAssignment = paymentItem.assignedTo.find(({ key }) =>
      key === interestItemKey)?.isInterestFirst;
    if (_.isUndefined(paymentAssignment)) return prev;
    return [
      ...prev,
      paymentAssignment
    ];
  }, []);

  const anyInterestFirst = isInterestFirstData.some(value =>
    value === true);

  const bothInterestFirst = anyInterestFirst && isInterestFirstData.some(value =>
    value === false);

  if (_.isEmpty(assignedPayments)) return '';

  return arrStrFormat([

    // more than one payment only
    textIf(`Łącznie wartość wpłat wynosi ${formatPLN(assignedPaymentsSum)}.`,
      !isSinglePayment),
    /*
        more than one payment and assigned for interest first
        both options - fulfilled interest and normal interest included
    */
    textIfPad(`${textIf('Część z nich w pierwszej kolejności została zaliczona', bothInterestFirst, 'W pierwszej kolejności zostały zaliczone')}
    na zaległe odsetki od kwoty głównej.`,
    (anyInterestFirst) && !isSinglePayment),

    // 1. interests sum is decreased by payments and partialSum (original claim sum) wasn't changed
    /*
        it only apply to only payments not changing partial sum
        which can be also checked as interestsSumCovered > 0;
    */
    textIfPad(`Kwota odsetek naliczonych do dnia 
    ${textIfPad('wpłaty', isSinglePayment, 'ostatniej wpłaty')} 
    wyniosła ${formatPLN(coveredInterestsSum)} i wynikała z następujących wyliczeń:`,
    interestsSumCovered > 0 && interestsSumFulfilledCovered === 0),

    // 2.

    // 2. interests sum fulfilled is decreased by payments and partialSum decreased

    textIfPad(
      [`W następstwie zmniejszeniu do ${formatPLN(interestsSumFulfilledUncovered)} 
      uległo roszczenie o odsetki naliczane od zaspokojonej części kwoty głównej`,
      ` - różnica pomiędzy wysokością roszczenia odsetkowego (${formatPLN(interestsSumFulfilledOverall)}), 
      a częścią ${textIfPad('wpłaty', isSinglePayment, 'wpłat')} zaliczoną na poczet odsetek 
      (${formatPLN(interestsSumFulfilledCovered)}) wynosi ${formatPLN(interestsSumFulfilledUncovered)}.`,
      textIfPad(`Zmniejszeniu uległy także odsteki od niezaspokojonej części kwoty głównej o ${formatPLN(interestsSumCovered)}.
      To nie spowodowało zmniejszenia kwoty głównej długu.`,
      interestsSumCovered > 0 && interestsSumFulfilledCovered > 0),
      ` Wartość roszczenia odsetkowego w kwocie ${formatPLN(interestsSumFulfilledOverall)} 
       wynika z następujących wyliczeń:`,
      ].join(''),
      interestsSumFulfilledCovered > 0),

    // 4. payments only for main sum
    /*
     This case ends here
    */
    textIfPad(`Po odliczeniu ${textIf('wpłaty', isSinglePayment, 'wpłat')}
      kwota główna roszczenia uległa zmniejszeniu do ${formatPLN(claimSum)}
      (${formatPLN(partialSum)} - ${formatPLN(assignedPaymentsSum)}).
    `,
    interestsSumFulfilledCovered === 0
    && interestsSumCovered === 0
    && claimSum < partialSum)
  ]);
};

/*
  only for interests sum fulfilled is decresed by payments and partialSum decreased
  used in covered interest and partial interest results
*/

export const paymentInterestCalcBasis = (result: PartialResult, interestSum: number, index: number) =>
  `Podstawę naliczania w tym okresie stanowiła suma 
${formatPLN(result.interestDataFulfilled[index]?.partialSum)}, 
a wyliczona kwota roszczenia odsetkowego to:
 ${formatPLN(interestSum)}.`.replace(/(\r\n|\n|\r)/gm, '').replace(/ +(?= )/g, '');


/* -------------------------------------------------------------------------- */
/*                               5.PAYMENTS OUTRO                             */
/* -------------------------------------------------------------------------- */

export const justPaymentOutro = (partialResult: PartialResult, lawsuitOpts:LawsuitOpts, index: number) => {
  const {
    claimSum,
    interestsSumFulfilledCovered,
    assignedPayments,
    interestsSumCovered,
    assignedPaymentsSum,
  } = partialResult;

  const {
    resultNumber,
    pluralResults
  } = getResultNumber(index);

  const { interestSum:coveredInterestsSum } = coveredInterestCalc(lawsuitOpts, partialResult);

  const isSinglePayment = assignedPayments.length === 1;

  const paymentForMain = assignedPaymentsSum - (interestsSumFulfilledCovered + interestsSumCovered);

  return ENT + arrStrFormat([

    // 1. interests sum is decresed by payments and partialSum (orginal claim sum) wasn't changed

    textIf(`Wartość ${textIfPad('wpłaty', isSinglePayment, 'całości wpłat')} (${formatPLN(assignedPaymentsSum)}) 
    jest mniejsza niż kwota naliczonych odsetek (${formatPLN(coveredInterestsSum)}), 
    tym samym kwota główna ${textIf(`roszczenia nr ${resultNumber}`, pluralResults, 'roszczenia')} nie uległa zmianie.
    `, interestsSumCovered > 0 && interestsSumFulfilledCovered === 0),


    // 2. interests sum fulfilled is decresed by payments and partialSum decreased

    textIf(`Pozostała część ${textIfPad('wpłaty', isSinglePayment, 'całości wpłat')} 
    na poczet ${textIf(`roszczenia nr ${resultNumber}`, pluralResults, 'roszczenia')}
     w kwocie ${formatPLN(paymentForMain)} (${formatPLN(assignedPaymentsSum)} - ${textIf(
  `(${formatPLN(interestsSumFulfilledCovered)} +  ${formatPLN(interestsSumCovered)})`,
  interestsSumCovered > 0 && interestsSumFulfilledCovered > 0,
  formatPLN(interestsSumFulfilledCovered)
)}),
     została zaliczona poczet kwoty głównej długu, powodując jego zmniejszenie do ${formatPLN(claimSum)}.`,
    //  (${formatPLN(partialSum)} - (${formatPLN(interestsSumFulfilledCovered)} + ${formatPLN(paymentForMain)})).`,
    interestsSumFulfilledCovered > 0)
  ]);
};

export const coveredInterestCalc = (lawsuitOpts: LawsuitOpts, result: PartialResult) =>  {
  const sortedPayments = _.cloneDeep(result.assignedPayments).sort((itemA, itemB) =>
    moment(assignmentDate(itemA)).toDate().getTime() - moment(assignmentDate(itemB)).toDate().getTime()
  );
  const lastPayment = _.last(sortedPayments);
  if (lastPayment){
    return calcOneInterest({
      lawsuitOpts,
      interestItem: {
        key: '1',
        partialSum: result.partialSum,
        startingDate: result.claimStartingDate,
        endDate: assignmentDate(lastPayment),
      }
    });
  }
  return {
    interestSum: 0,
    periodResults: [],
  };
};

