import { Radio, RadioChangeEvent } from 'antd';
import React, { memo } from 'react';
import { shallowEqual } from 'react-redux';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { compensationOnUpdated } from '../../../store/lawsuitOpts';
import { CardRadioButton, GRID_LG } from '../../../styles';
import { COMPENSATION_BASIS_ACT } from '../../../utils/helpers/lawBasis';
import useWindowSize from '../../../utils/hooks/useWindowSize';
import StepCard from '../StepCard/StepCard';


const CompensationSelect = () => {
  const { width } = useWindowSize();

  const  { compensationOn } =  useTypedSelector(state =>
    state.lawsuitOpts, shallowEqual);

  const dispatch = useAppDispatch();

  const onPress = (event:RadioChangeEvent) => {
    dispatch(compensationOnUpdated(event.target.value === 2));
  };
  const radioStyle = {
    display: 'block',
    height: '32px',
    lineHeight: '32px',
  };

  if (width < GRID_LG) {
    return (
      <StepCard title={texts.title} desc={texts.desc}>
        <Radio.Group
          onChange={onPress}
          value={compensationOn ? 2 : 1}
          style={{ width: '100%' }}
        >
          <CardRadioButton value={1}>
            {texts.radio1}
          </CardRadioButton>
          <CardRadioButton value={2}>
            {texts.radio2}
          </CardRadioButton>
        </Radio.Group>
      </StepCard>
    );
  }
  return (
    <StepCard title={texts.title} desc={texts.desc}>
      <Radio.Group
        onChange={onPress}
        value={compensationOn ? 2 : 1}
      >
        <Radio style={radioStyle} value={1}>
          {texts.radio1}
        </Radio>
        <Radio style={radioStyle} value={2}>
          {texts.radio2}
        </Radio>
      </Radio.Group>
    </StepCard>
  );
};

const texts = {
  title: 'Rekompensaty',
  desc: `Czy mam policzyć rekompensaty w rozumieniu art. 10 ${COMPENSATION_BASIS_ACT} w oparciu o podane później daty wymagalności?`,
  radio1: 'Nie',
  radio2: 'Tak'
};



export default memo(CompensationSelect);
