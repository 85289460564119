import { ArrowRightOutlined, CalculatorOutlined } from '@ant-design/icons';
import { Button, Row, Tooltip } from 'antd';
import React from 'react';


const NextButton = (
  {
    showCount,
    noPartialSum,
    handleStep,
    isStepIncreaseDisabled
  }: {
    showCount:boolean,
    noPartialSum:boolean,
    handleStep: ()=>void,
    isStepIncreaseDisabled:boolean,
  }
) => {
  if (showCount) {
    return (
      <Button
        type="primary"
        onClick={handleStep}
        icon={<CalculatorOutlined />}
      >
        {texts.count}
      </Button>
    );
  }
  return (
    <Tooltip title={noPartialSum ? 'Dodaj co najmniej jedną kwotę aby przejść dalej' : ''} color="blue">
      <Button type="primary" onClick={handleStep} disabled={isStepIncreaseDisabled || noPartialSum}>
        <Row align="middle">
          {texts.next}
          <ArrowRightOutlined style={{ marginLeft: 6 }} />
        </Row>
      </Button>
    </Tooltip>
  );
};

export default React.memo(NextButton);


const texts = {
  next: 'Dalej',
  count: 'Oblicz',
};
