import { getInterestDesc } from '../../../services/calc/interestDesc';
import { useTypedSelector } from '../../../store';
import { JUST_WITH_CAP, JUST_WO_INT } from '../../../store/justOpts';
import { textIf } from '../../helpers/textIf';

const useInterestBasis = () => {
  const typeOfInterest = useTypedSelector(state =>
    state.sumUp.lawsuitOpts.typeOfInterest);
  const justIntCap = useTypedSelector(state =>
    state.justOpts.justIntCap);
  return justIntCap !== JUST_WO_INT ?
    [
      `Podstawę prawną roszczenia o odsetki stanowi ${getInterestDesc(typeOfInterest).legalBasis}`,
      textIf(' oraz art. 482 § 2 Kodeksu cywilnego.', justIntCap === JUST_WITH_CAP, '.')
    ].join('')
    : '';
};

export default useInterestBasis;
