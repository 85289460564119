import _ from 'lodash';
import moment, { Moment } from 'moment';

import { SudoInterestItem } from '../../@types';
import { InterestItem, PaymentData, PaymentItem } from '../../store/interestStorage';
import { LawsuitOpts } from '../../store/lawsuitOpts';
import { calcOneInterest, PeriodResult } from './interestsCalc';

/*
1. Najpierw sprawdź umowę

2. Dłużnik mający kilka długów może przy zapłacie wskazać,
na który dług wpłata ma zostać zaliczona; wierzyciel musi się do tego zastosować

3. Wierzyciel może jednak (ale nie musi) zaliczyć wpłatę najpierw na odsetki,
ale tylko związane tym długiem, którego dotyczy wpłata

4. Wierzyciel nie musi informować dłużnika o zaliczeniu wpłaty na odsetki

5. Dłużnik nie może się sprzeciwić zaliczeniu wpłaty najpierw na odsetki

6. Jeśli dłużnik nie wskazał, na który z kilku jego długów wpłata ma zostać zaliczona,
  to decyzję może podjąć wierzyciel; musi to zrobić niezwłocznie po otrzymaniu wpłaty,
  wystawiając pokwitowanie dłużnikowi; dłużnik może nie przyjąć tego pokwitowania
  i może wskazać wierzycielowi inny sposób zaliczenia

7. Jeśli ani dłużnik, ani wierzyciel nie wskazali sposoby zaliczenia wpłaty –
  należy ją zaliczyć na ten dług, który jest najdłużej wymagalny.

https://poradnikprzedsiebiorcy.pl/-7-zasad-ktore-warto-znac-jak-jak-zaliczyc-wplate-otrzymana-od-kontrahenta
*/

/*
  Art.  451.  [Sposób zaliczenia zapłaty]
  §  1.
  Dłużnik mający względem tego samego wierzyciela kilka długów tego samego rodzaju
  może przy spełnieniu świadczenia wskazać, który dług chce zaspokoić.
  Jednakże to, co przypada na poczet danego długu, wierzyciel może
  przede wszystkim zaliczyć na związane z tym długiem zaległe należności
  uboczne oraz na zalegające świadczenia główne.
  §  2.
  Jeżeli dłużnik nie wskazał, który z kilku długów chce zaspokoić,
  a przyjął pokwitowanie, w którym wierzyciel zaliczył otrzymane
  świadczenie na poczet jednego z tych długów, dłużnik nie może
  już żądać zaliczenia na poczet innego długu.
  §  3.
  W braku oświadczenia dłużnika lub wierzyciela spełnione
  świadczenie zalicza się przede wszystkim na poczet długu wymagalnego,
  a jeżeli jest kilka długów wymagalnych - na poczet najdawniej wymagalnego.

 */

export type InterestResultFulfilled = {
  interestSum: number;
  periodResults: PeriodResult[];
};

export type ClaimItem = {
  claimSum: number,
  claimStartingDate: Moment | string,
  interestsSum: number,
  interestsSumCovered: number,
  interestsSumUncovered: number,
  periodResults: PeriodResult[],
  interestsSumFulfilledOverall: number, // interstSum
  interestsSumFulfilledCovered: number, // coveredInterestsSum
  interestsSumFulfilledUncovered: number, // coveredInterestsSum
  interestDataFulfilled: SudoInterestItem[],
  interestResultsFulfilled: InterestResultFulfilled[],
  assignedPayments: PaymentData,
  assignedPaymentsSum: number,
} & InterestItem;


export const claimCalc = ({
  interestItem,
  paymentData,
  lawsuitOpts,
}:{
  interestItem: InterestItem,
  paymentData: PaymentData,
  lawsuitOpts: LawsuitOpts,
}):ClaimItem => {
  const dataUntilLastPayment = calcFulfilled({
    interestItem,
    paymentData,
    lawsuitOpts,
  });

  const {
    claimSum,
    interestDataFulfilled,
    interestsCovered,
  } = dataUntilLastPayment;

  /*
  Array is not empty only if there have been at
  least one contribution reducing the partial sum.
  Only in this case is it a full basis for further calculations.
  If array was empty, we have 2 cases:
  1. Interest did not cover the entire claim for interest against paymentDate
  2. The interest covered then a new entry in the array was created
  */

  const lastInterestItem = _.last(interestDataFulfilled);

  // starting date after all payments/staments
  const claimStartingDate = setClaimStartingDate({
    lastInterestItem,
    endDate: interestItem.endDate,
    startingDate: interestItem.startingDate
  });

  /* -------------------------------- payments -------------------------------- */

  const assignedPayments = paymentData.filter(value =>
    value.assignedTo.find(v =>
      v.key === interestItem.key
    ));

  const assignedPaymentsSum = assignedPayments.reduce((prev, curr) => {
    const paymentAssignment = curr.assignedTo.find(_val =>
      _val.key === interestItem.key);
    return paymentAssignment ? paymentAssignment.sum + prev : prev;
  }, 0);

  /* ------------------------- interest after payments ------------------------ */

  const interestItemFromClaim = lastInterestItem ? {
    ...interestItem,
    partialSum: claimSum,
    startingDate: setClaimStartingDate({
      lastInterestItem,
      endDate: interestItem.endDate,
      startingDate: lastInterestItem?.endDate
    }),
  } : interestItem;


  const { interestSum: interestsSum, periodResults } = calcOneInterest({
    interestItem: interestItemFromClaim,
    lawsuitOpts,
  });

  const {
    interestsSumFulfilledOverall,
    interestResultsFulfilled
  }  = calcIntrSumFulfilledOverall(interestDataFulfilled, lawsuitOpts);


  const {
    interestsSumCovered,
    interestsSumFulfilledCovered,
  } = calcCovered({
    assignedPaymentsExist: !_.isEmpty(assignedPayments),
    isFulfilledDataEmpty: _.isEmpty(interestDataFulfilled),
    interestsSumFulfilledOverall,
    interestsCovered,
  });

  return {
    ...interestItem,
    accountingDoc: interestItem.accountingDoc ?? null,
    assignedPayments,
    assignedPaymentsSum,
    claimStartingDate,
    claimSum,
    factualBasis: interestItem.factualBasis ?? null,
    interestDataFulfilled,
    interestResultsFulfilled,
    interestsSum,
    interestsSumCovered,
    interestsSumFulfilledCovered,
    interestsSumFulfilledOverall,
    interestsSumFulfilledUncovered: interestsSumFulfilledOverall - interestsSumFulfilledCovered,
    interestsSumUncovered: interestsSum - interestsSumCovered,
    periodResults,
    isClaimFromFulfilled: interestItem.isClaimFromFulfilled ?? false,
  };
};


export const calcFulfilled = ({
  interestItem,
  paymentData,
  lawsuitOpts,
}:{
  interestItem: InterestItem,
  paymentData: PaymentData,
  lawsuitOpts: LawsuitOpts,
}) => {

  // 1. get payments assigned to interestItem
  const assignedPayments = paymentData.filter(paymentItem =>
    paymentItem.assignedTo.some(({ key }) =>
      key === interestItem.key));

  // 2. sort by date - assignments should be done in asscending order - art. 451 KC
  const sortedPayments = sortPaymentData(assignedPayments);

  // 3. find assingedTo and calc interests & new partialSum
  return sortedPayments.reduce((
    prev: Fulfilled, {
      assignedTo,
      paymentDate,
      statementDate,
      key: paymentItemKey
    }) => {
    // provide assignments only for our interstItem
    const paymentAssignment = assignedTo.find(({ key }) =>
      key === interestItem.key);

    if (paymentAssignment){

      const { isInterestFirst, sum } = paymentAssignment;

      const lastClaimInterestItem =  _.last(prev.interestDataFulfilled);

      const endDateFromPaymentDate = getEndDateFromPaymentDate({
        endDate: interestItem.endDate,
        paymentDate,
        statementDate
      });

      const createFirstClaimInterestItem = (endDate: Moment) => {
        return {
          key: paymentItemKey,
          partialSum: interestItem.partialSum,
          startingDate: interestItem.startingDate,
          endDate,
        };
      };

      // check dynamicly assign data ony last item
      // payments is assigned to interest first

      /* ------------------------ I. ASSIGN TO INTEREST FIRST ------------------------ */

      if (isInterestFirst){

        /*
        1. all interest periods are fulfilled

        there is no need to calc interstest
        */
        if (lastClaimInterestItem &&
          moment(endDateFromPaymentDate).endOf('day').isSame((interestItem.endDate).endOf('day'))){
          return {
            ...prev,
            claimSum: prev.claimSum > sum ? prev.claimSum - sum : 0,
          };
        }



        /*
        2. same payment date as in last interestDataFulfilled period

        there is no need to start new interestDataFulfilled period
        only increasing interestCovered and decreasing claimSum is required
        */



        if (lastClaimInterestItem &&
          moment(paymentDate).endOf('day').isSame((lastClaimInterestItem.endDate).endOf('day'))){

          const {
            recalcIntrestCovered,
            reCalcClaimSum,
          } = getSameDateClaim({
            prev,
            sum,
            lawsuitOpts,
          });

          return {
            ...prev,
            interestsCovered: recalcIntrestCovered,
            claimSum: reCalcClaimSum,
          };
        }


        /*
        3. calc claim interest required

        the last object in the array must always be
         with the payment as end date so that the interest
        for the periods is properly calculated
        */

        const interestDataUntilPayment = lastClaimInterestItem ? [
          ...prev.interestDataFulfilled,
          {
            key: paymentItemKey,
            partialSum: prev.claimSum,
            startingDate: moment(endDateFromPaymentDate).endOf('day').isSame((interestItem.endDate).endOf('day'))
              ? endDateFromPaymentDate : moment(lastClaimInterestItem.endDate).add(1, 'day').startOf('day'),
            endDate:endDateFromPaymentDate,
          }
        ] : [createFirstClaimInterestItem(endDateFromPaymentDate)];

        const {
          interestsCovered,
          partialSumDecrease,
        } = calcClaimInterests({
          interestDataUntilPayment,
          lawsuitOpts,
          interestsCovered: prev.interestsCovered,
          assignedSum: sum,
        });

        /* if the amount of the payment exceeded the amount of interest
        charged for the payment less the previous payments:

        create a new interest item storing data with last paymentSum - interests for that
        period are fully covered
        */

        if (partialSumDecrease > 0){
          const newPeriod = lastClaimInterestItem ?
            {
              key: paymentItemKey,
              partialSum: prev.claimSum,
              startingDate: moment(lastClaimInterestItem.endDate).add(1, 'day').startOf('day'),
              endDate:endDateFromPaymentDate,
            } : createFirstClaimInterestItem(endDateFromPaymentDate);


          return {
            claimSum: prev.claimSum - partialSumDecrease,
            interestsCovered,
            interestDataFulfilled:  [
              ...prev.interestDataFulfilled,
              newPeriod
            ],
          };
        }

        return {
          claimSum: prev.claimSum,
          interestsCovered,
          interestDataFulfilled: prev.interestDataFulfilled,
        };
      }

      /* -------------------------- II. NO INTERST ASSIGNMENT ------------------------- */

      /* If the payment is not assigned to interest first, we close the last period of
      interest on the payment date and create a new period with a changed amount.
      New period can be in the future may be covered by the payment with an indication of interest.
      Interest from the already fulfilled principal amount can also be calculated
      from it at the end.
      */

      const newPeriod = lastClaimInterestItem ?
        {
          key: paymentItemKey,
          partialSum: prev.claimSum,
          startingDate: moment(lastClaimInterestItem.endDate).add(1, 'day').startOf('day'),
          endDate:endDateFromPaymentDate,
        } : createFirstClaimInterestItem(endDateFromPaymentDate);

      /* same payment date as in last interestDataFulfilled period
        there is no need to start new interestDataFulfilled period
        else start another period
      */
      const interestDataFulfilled = (lastClaimInterestItem
        && moment(paymentDate).endOf('day')
          .isSame((lastClaimInterestItem.endDate).endOf('day'))) ? prev.interestDataFulfilled :
        [
          ...prev.interestDataFulfilled,
          newPeriod
        ];

      return {
        ...prev,
        claimSum: prev.claimSum > sum ? prev.claimSum - sum : 0,
        interestDataFulfilled,
      };
    }
    return prev;
  }, {
    claimSum: interestItem.partialSum,
    interestDataFulfilled: [],
    interestsCovered:0,
  });
};

const setClaimStartingDate = ({
  lastInterestItem,
  startingDate,
  endDate,
}:{
  lastInterestItem?: SudoInterestItem
  endDate: Moment | string,
  startingDate: Moment | string,
}
) => {
  // console.log(startingDate, lastInterestItem, 'HERE');
  if (lastInterestItem){
    if (moment(lastInterestItem.endDate).isSameOrAfter(endDate)){
      return endDate;
    }
    return moment(lastInterestItem.endDate).add(1, 'day').startOf('day');

  }
  return startingDate;
};


export type Acc = {
  interestDataFulfilled: SudoInterestItem[],
  interestResultsFulfilled: InterestResultFulfilled[],
  claimSum: number,
  coveredInterestsSum: number,
};

export type Fulfilled = {
  claimSum: number,
  interestsCovered: number,
  interestDataFulfilled: SudoInterestItem[],
};


const calcClaimInterests = ({
  interestDataUntilPayment,
  lawsuitOpts,
  interestsCovered,
  assignedSum,
}: {
  interestDataUntilPayment: SudoInterestItem[],
  lawsuitOpts: LawsuitOpts,
  interestsCovered: number,
  assignedSum: number
}) => {

  // 1. check what is the sum of interest from all available periods for till payment
  const interestResultsFulfilled = interestDataUntilPayment.map(sudoInterestItem =>
    calcOneInterest({
      lawsuitOpts,
      interestItem: sudoInterestItem,
    }));

  // 2. sum up interest
  const interestsSum = interestResultsFulfilled.reduce((prev, { interestSum }) =>
    prev + interestSum
  , 0);

  // 3. advance interest already covered from previous payments
  const interestToCover = interestsSum - interestsCovered;

  /* 4. return information on allowing the construction of the next
  calculation periods according to the payments
  and whether they are included in the interest */

  if (interestToCover > assignedSum){
    return {
      interestsCovered: interestsCovered + assignedSum,
      partialSumDecrease: 0,
    };
  }
  return {
    interestsCovered: interestsSum,
    partialSumDecrease: assignedSum - interestToCover,
  };
};


export const assignmentDate = (paymentItem: PaymentItem) =>
  paymentItem.statementDate ?? paymentItem.paymentDate;


export const getEndDateFromPaymentDate = ({
  endDate,
  paymentDate,
  statementDate
}:{
  endDate: Moment,
  paymentDate: Moment,
  statementDate: Moment | null,
}) =>
  moment(paymentDate ?? statementDate).endOf('day').isSameOrAfter(moment(endDate).endOf('day'))
    ? moment(endDate).endOf('day') : moment(paymentDate ?? statementDate).endOf('day');


export const isPaymentFullyAssigned = (paymentData:PaymentData, paymentItemKey: number) => {
  if (paymentItemKey === -1) return null;
  if (paymentData[paymentItemKey].assignedTo.length === 0) return false;
  const sumOfAssignedTo = paymentData[paymentItemKey].assignedTo.reduce((prev, { sum }) =>
    prev + sum, 0);
  return sumOfAssignedTo >=  paymentData[paymentItemKey].paymentSum;
};


export const getSumOfClaimAssignments = (paymentData:PaymentData, interestItemKey: string) =>
  paymentData.reduce((prev, { assignedTo }) => {
    const paymentAssignment = assignedTo.find(({ key }) =>
      key === interestItemKey);
    if (paymentAssignment){
      return paymentAssignment.sum + prev;
    }
    return prev;
  }, 0);


export const calcIntrSumFulfilledOverall = (interestDataFulfilled: SudoInterestItem[], lawsuitOpts: LawsuitOpts) => {
  const interestResultsFulfilled = interestDataFulfilled.map((sudoInterestItem) => {
    return calcOneInterest({
      lawsuitOpts,
      interestItem: sudoInterestItem,
    });
  });

  const interestsSumFulfilledOverall = interestResultsFulfilled.reduce((prev, { interestSum }) =>
    prev + interestSum
  , 0);

  return {
    interestResultsFulfilled,
    interestsSumFulfilledOverall
  };
};

export const getSameDateClaim = ({
  prev,
  lawsuitOpts,
  sum
}:{
  prev: Fulfilled,
  lawsuitOpts: LawsuitOpts,
  sum: number
}) => {
  /*
    to get uncovered fulfilled intersts we need to know overall sum of interests of previeus fulfilled periods
   */
  const { interestsSumFulfilledOverall }  = calcIntrSumFulfilledOverall(prev.interestDataFulfilled, lawsuitOpts);

  const isUncoveredInterest = interestsSumFulfilledOverall > prev.interestsCovered;

  /*
    there is some uncovered interest increased it by payment assignment sum
  */

  const intrCoveredIncrease =  sum > interestsSumFulfilledOverall ?
    interestsSumFulfilledOverall : prev.interestsCovered + sum;


  const  recalcIntrestCovered = isUncoveredInterest ?
    intrCoveredIncrease : prev.interestsCovered;

  /*
    after we uncovered is increased
    less is to be decreased from claim payment assignment sum

    if payment sum is lower than covered interst claim sum
    does not change - payment lowers only interst
  */

  const claimSumDecrease = sum > intrCoveredIncrease ?
    sum - intrCoveredIncrease : 0;


  /*
    claim is decreased only if all sum is not consumed by uncovered interest
    if there is uncovered interst othrewise just substract sum
    (if sum is lower than  previous claim sum, otherwise it's 0 -
    claim sum can't be negative)
  */

  const sumCond = isUncoveredInterest ? claimSumDecrease : sum;

  const reCalcClaimSum = prev.claimSum > sumCond ? prev.claimSum - sumCond : 0;

  return {
    recalcIntrestCovered,
    reCalcClaimSum,
  };
};

const calcCovered = ({
  assignedPaymentsExist,
  isFulfilledDataEmpty,
  interestsSumFulfilledOverall,
  interestsCovered,
}:{
  assignedPaymentsExist: boolean,
  isFulfilledDataEmpty: boolean,
  interestsSumFulfilledOverall: number
  interestsCovered: number
}) => {

  /*
    this function provides solution for
    payment beging lower than interest

    there are two cases

    1. all first payments are lower than interstest and
    no data fulfilled is created

    2. payments covers fulfilled and
    next payment is lower than interest
  */

  const fulfilledCovered = interestsSumFulfilledOverall < interestsCovered
    ? interestsSumFulfilledOverall : interestsCovered;

  const covered = interestsSumFulfilledOverall < interestsCovered ?
    interestsCovered - interestsSumFulfilledOverall  : 0;

  const interestsSumCovered = assignedPaymentsExist
  && isFulfilledDataEmpty ?
    interestsCovered : covered;

  const interestsSumFulfilledCovered = assignedPaymentsExist
  && isFulfilledDataEmpty
    ? 0 : fulfilledCovered;

  return {
    interestsSumCovered,
    interestsSumFulfilledCovered
  };
};


export const sortPaymentData = (data:PaymentData) =>
  _.cloneDeep(data).sort((itemA, itemB) =>
    moment(assignmentDate(itemA)).toDate().getTime() - moment(assignmentDate(itemB)).toDate().getTime());

export const sortInterestData = (data:InterestItem[]) =>
  _.cloneDeep(data).sort((itemA, itemB) =>
    moment(itemA.startingDate).toDate().getTime() - moment(itemB.startingDate).toDate().getTime());