/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type AuthProviders = 'email' | 'google' | 'facebook' | null;
interface LogInState {
  email: string;
  password: string;
  loading: boolean;
  error: boolean;
  errorMessage: string;
  phoneNumberValidated: boolean | null;
  confirmResult: null | string;
  provider: AuthProviders;
}

const initialState: LogInState = {
  email: '',
  password: '',
  loading: false,
  error: false,
  errorMessage: '',
  phoneNumberValidated: false,
  confirmResult: null,
  provider: null,
};

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    loginEmailUpdated: (state:LogInState, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    loginPasswordUpdated: (state:LogInState, action: PayloadAction<string>) => {
      state.password = action.payload;
    },
    loginLoadingStarted:(state:LogInState, action: PayloadAction<AuthProviders>) => {
      state.loading = true;
      state.provider = action.payload;
    },
    loginFailed:(state:LogInState, action: PayloadAction<string>) => {
      state.error = true;
      state.errorMessage = action.payload;
      state.loading = false;
      state.provider = null;
    },
    loginFailedOff:(state: LogInState) => {
      state.error = false;
      state.errorMessage = '';
    },
    loginReset: () =>
      initialState,
  }
});


export const {
  loginEmailUpdated,
  loginPasswordUpdated,
  loginLoadingStarted,
  loginFailed,
  loginFailedOff,
  loginReset
} = loginSlice.actions;

export default loginSlice.reducer;

