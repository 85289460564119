import { notification } from 'antd';
import { signInAnonymously } from 'firebase/auth';
import { AppDispatch } from '../../../store';
import { appLoadingStarted, appLoadingStopped } from '../../../store/appLoading';
import { OcrItem } from '../../../store/impOcr';
import { auth } from '../../firebase/auth';
import { sendCategorizeDoc } from '../../firebase/firebaseFunctions';


export const updateOcrTrainingData = async (
  {
    dispatch,
    userExists,
    ocrData
  }:{
    dispatch:AppDispatch,
    userExists: boolean,
    ocrData: OcrItem[]
  }) => {
  try  {
    dispatch(appLoadingStarted('przesyłam dane'));
    if (!userExists) await signInAnonymously(auth);
    await sendCategorizeDoc({
      categorizeDoc: ocrData.map(value => {
        return {
          input: value.doc,
          output: value.correctedDocType
        };
      }),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      partialSum: ocrData.reduce((prev:any, curr) => {
        // @ts-ignore
        if (curr.correctedDocResults.allSums && curr.correctedDocResults?.partialSum){
          return [
            ...prev,
            {
              input: curr.correctedDocResults.allSums,
              // @ts-ignore
              output: curr.correctedDocResults?.partialSum
            }];
        }
        return prev;
      }, [])
    });
    dispatch(appLoadingStopped());
    notification.info({ message: 'Dane przesłane' });
  } catch (e){
    dispatch(appLoadingStopped());
  }
};