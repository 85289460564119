import { Button } from 'antd';
import React from 'react';
import CookieLaw from '../components/Law/CookieLaw';
import LawBorders from '../components/Law/LawBorders';
import LawParLinks from '../components/Law/LawParLinks';
import LawPars from '../components/Law/LawPars';
import LawUsts from '../components/Law/LawUsts';
import PrivacyPar from '../components/Law/PrivacyPar';
import AnimOpacity from '../components/UI/Anim/AnimOpacity';
import LawContainer from '../components/UI/Containers/LawContainer';
import { ScreenBackButton } from '../components/UI/DescScreenFooter';
import DotsEnding from '../components/UI/DotsEnding';
import { par1, par10, par11, par2, par3, par4, par5, par6, par7, par8, par9 } from '../utils/law/privacy';
import { RowBg } from '../styles';
import { DashedDivider } from './About';
import PrivacyIntro from './PrivacyIntro';

const Privacy = () => {

  return (
    <AnimOpacity>
      <LawContainer title="Polityka prywatności" />
      <PrivacyIntro />
      <DashedDivider />
      <LawBorders />
      <PrivacyPar
        title={par1.title}
        desc={par1.desc}
      >
        <LawPars texts={par1.pars} />
      </PrivacyPar>
      <PrivacyPar
        title={par2.title}
        desc={par2.desc}
      >
        <LawPars texts={par2.pars1} />
        <LawUsts texts={par2.usts} />
        <LawPars texts={par2.pars2} />
      </PrivacyPar>
      <PrivacyPar
        title={par3.title}
        desc={par3.desc}
      >
        <LawPars texts={par3.pars1} />
        <LawUsts texts={par3.usts1} />
        <LawPars texts={par3.pars2} />
        <LawUsts texts={par3.usts2} />
        <LawPars texts={par3.pars3} />
        <LawParLinks>
          <Button type="link" href="https://policies.google.com/privacy">
            Google
          </Button>
          <Button type="link" href="https://www.facebook.com/about/privacy/">
            Facebook
          </Button>
          <Button type="link" href="https://stripe.com/en-gb-pl/privacy-center/legal">
            Stripe
          </Button>
          <Button type="link" href="https://marketingplatform.google.com/about/analytics/">
            Google Analitics
          </Button>
        </LawParLinks>
      </PrivacyPar>
      <PrivacyPar
        title={par4.title}
        desc={par4.desc}
      >
        <LawPars texts={par4.pars} />
        <LawUsts texts={par4.usts} />
      </PrivacyPar>
      <PrivacyPar
        title={par5.title}
        desc={par5.desc}
      >
        <LawPars texts={par5.pars} />
      </PrivacyPar>
      <PrivacyPar
        title={par6.title}
        desc={par6.desc}
      >
        <LawPars texts={par6.pars} />
        <LawParLinks>
          <Button type="link" href="https://firebase.google.com/">
            Firebase
          </Button>
          <Button type="link" href="https://marketingplatform.google.com/about/analytics/">
            Google Analitics
          </Button>
          <Button type="link" href="https://mailchimp.com/">
            Mailchimp
          </Button>
        </LawParLinks>
      </PrivacyPar>
      <PrivacyPar
        title={par7.title}
        desc={par7.desc}
      >
        <LawPars texts={par7.pars} />
        <LawParLinks>
          <Button type="link" href="https://mailchimp.com/gdpr/">
            Mailchimp a RODO
          </Button>
        </LawParLinks>
      </PrivacyPar>
      <PrivacyPar
        title={par8.title}
        desc={par8.desc}
      >
        <LawPars texts={par8.pars} />
      </PrivacyPar>
      <PrivacyPar
        title={par9.title}
        desc={par9.desc}
      >
        <LawPars texts={par9.pars} />
      </PrivacyPar>
      <PrivacyPar
        title={par10.title}
        desc={par10.desc}
      >
        <LawPars texts={par10.pars} />
        <LawUsts texts={par10.usts} />
      </PrivacyPar>
      <PrivacyPar
        title={par11.title}
        desc={par11.desc}
      >
        <LawPars texts={par11.pars} />
      </PrivacyPar>
      <CookieLaw />
      <DashedDivider />
      <LawContainer>
        <RowBg align="middle" justify="center">
          <ScreenBackButton />
        </RowBg>
        <DotsEnding />
      </LawContainer>

    </AnimOpacity>
  );
};

export default Privacy;
