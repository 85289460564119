import React from 'react';
import { useHistory } from 'react-router';
import { ENV_TYPE } from '../../../env.json';
import { useAppDispatch } from '../../../store';
import { subTypeUpdated } from '../../../store/subscribe';
import { subscriptionStyle } from '../../../styles/subscriptionStyles';
import * as subCodes from '../../../utils/helpers/subCodes';
import SubBasicHead from './SubBasicHead';
import SubBasicStar from './SubBasicStar';
import SubOptCard from './SubOptCard';
import SubOptListItem from './SubOptListItem';

const SubBasic = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const onClick = () => {
    dispatch(subTypeUpdated(ENV_TYPE === 'PRODUCTION' ? subCodes.SUB_599_LIVE : subCodes.SUB_599_TEST));
    history.push('/payment');
  };
  return (
    <>
      <SubBasicHead
        inCard
      />
      <SubOptCard
        color={subscriptionStyle('basic').color}
        title="PODSTAWOWA"
        iconType={subscriptionStyle('basic').icon}
        price="później 599 PLN + VAT / rok"
        bestFor="jednoosobowej"
        bestForDesc="kancelarii | in-house"
        onClick={onClick}
      >
        <SubOptListItem
          text={(
            <>
              <strong> 1 konto</strong>
              {' '}
              z dodatkowymi funkcjami
            </>
          )}
        />
        <SubOptListItem
          notAvailable
          text={(
            <>
              {' '}
              Brak
              <strong> szkoleń pracowników</strong>
            </>
          )}
        />
        <SubBasicStar inCard />
      </SubOptCard>

    </>
  );
};

export default SubBasic;
