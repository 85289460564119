import { Typography } from 'antd';
import React from 'react';
import { YT_CURRENCY } from '../../../utils/helpers/links';
import { BLACK, BLUE_MEDIUM } from '../../../styles/colors';
import Icon from '../../../styles/Icon';
import { EURO } from '../../../styles/iconTypes';
import CheckVideoButton from '../../UI/Button/CheckVideoButton';
import { TitleWrapper } from './SubExcel';


const { Paragraph } = Typography;

const par = 'Przelicz równowartość kwoty w innej walucie na złotówki w oparciu o średni kurs NBP we wskazanej dacie. Następnie dodaj jako kwotę składową pozwu.';

const SubCurrency = () => {
  return (
    <>
      <TitleWrapper
        color={BLUE_MEDIUM}
        align="middle"
        marginTop={12}
        marginBottom={12}
      >
        <Icon
          iconType={EURO}
          height="20"
          width="20"
          fill={BLACK}
          style={{ marginRight:12 }}
        />
        {' '}
        <strong>
          WYLICZENIA WALUTOWE
        </strong>
      </TitleWrapper>
      <Paragraph>
        {par}
      </Paragraph>
      <CheckVideoButton
        url={YT_CURRENCY}
        emptyButtonProps={{
          color: BLUE_MEDIUM, hoverBg: BLUE_MEDIUM, hoverColor: 'white'
        }}
      />
    </>
  );
};

export default SubCurrency;
