import { notification } from 'antd';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { pipe } from 'ramda';
import React from 'react';

import { InterestData, InterestItem } from '../../store/interestStorage';
import { PartialResults } from '../../store/sumUp';
import { checkDueDate, convertDateToString } from './date';
import { formatPLN } from './formatNumber';

type FromSave = {
  noDueDate: boolean,
  interestData: InterestItem[],
  alerts: string[],
};

const WarningTitle = () => {
  return (
    <>
      <strong>Termin spełnienia świadczenia</strong>
    </>
  );
};

export const partialResultsToInterestData = (partialResults:PartialResults) => {
  const {
    noDueDate,
    interestData,
    alerts
  } = partialResults.reduce((prev: FromSave, curr) => {
    const fromResult = _.pick(curr, [
      'key',
      'startingDate',
      'endDate',
      'partialSum',
      'legalBasis',
      'factualBasis',
      'accountingDoc',
      'maturityDate',
      'dueDate',
      'isClaimFromFulfilled'
    ]);

    const {
      startingDate,
      maturityDate,
      dueDate,
      alert
    } = handleNoDueDate(
      fromResult.dueDate,
      fromResult.startingDate,
      fromResult.maturityDate);

    const interestItem = {
      ...fromResult,
      startingDate,
      maturityDate,
      dueDate,
    };

    if (fromResult.dueDate){
      return {
        ...prev,
        interestData: [
          ...prev.interestData,
          interestItem
        ]
      };
    }
    const alertsLocal = _.isNull(alert) ?
      prev.alerts : [
        ...prev.alerts,
        `- dla kwoty ${formatPLN(interestItem.partialSum)}; `
      ];

    return {
      noDueDate: true,
      interestData: [
        ...prev.interestData,
        interestItem
      ],
      alerts: alertsLocal
    };

  }, {
    noDueDate: false,
    interestData: [],
    alerts: []
  });

  if (noDueDate && !_.isEmpty(alerts)){
    const generalDesc = [
      'Wyliczenia, które wcześniej odczytałaś/eś są zapisane według starego formatu danych.',
      ' Od końca marca 2022 roku, każda zapisywana na nowo lub przetwarzana w formularzu kwota główna długu musi mieć przypisany termin spełnienia świadczenia.',
      'Dodajemy go jako dzień poprzedzający początkową datę naliczania odsetek.',
    ].join(' ');
    const changedDates = [
      ' Wobec treści art. 115 KC daty początkowe naliczania i wymagalności zostały zmienione dla następujących kwot:',
      alerts.map(value =>
        value).join('\n'),
      ' Pozostałe dane nie uległy zmianie.'
    ].join('\n');
    notification.warning({
      message: <WarningTitle />
      ,
      description:generalDesc + changedDates,
      duration: 30000
    });
  }
  return interestData;
};

export const handleNoDueDate =  (dueDate: Moment | undefined | null, startingDate: Moment, maturityDate: Moment | null) => {
  if (_.isNull(dueDate) || _.isUndefined(dueDate)){
    const { date:checkedDate, alert } = checkDueDate(moment(startingDate).subtract(1, 'day'));
    return {
      startingDate: moment(checkedDate).add(1, 'day').startOf('day'),
      dueDate:  checkedDate.endOf('day'),
      maturityDate: _.isNull(maturityDate) ? moment(checkedDate).add(1, 'day').startOf('day') : maturityDate,
      alert,
    };
  }
  return {
    startingDate,
    dueDate,
    maturityDate,
    alert: null,
  };
};

export const formatInterestDataToSave = (interestData: InterestData) =>
  interestData.map(interestItem =>
    ({
      ...interestItem,
      startingDate: convertDateToString(interestItem.startingDate),
      endDate: convertDateToString(interestItem.endDate),
      maturityDate: convertDateToString(interestItem.maturityDate),
      dueDate: convertDateToString(interestItem.dueDate),
      isClaimFromFulfilled: interestItem.isClaimFromFulfilled ?? false,
    }));

export const formatPartialResultsToSave = pipe(partialResultsToInterestData, formatInterestDataToSave);