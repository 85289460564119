import _ from 'lodash';
import { toAmount, toUnit } from '../../../../utils/helpers/money';


export const findHighestBrutto = (allSums: number[]) => {
  if (_.isEmpty(allSums)) return null;
  const summedPairs = allSums
    .reduce((prev: number[], curr, index) => {
      const currSums = allSums.map((value, idx) => {
        if (idx === index) return 0;
        return toUnit(toAmount(value) + toAmount(curr));
      });
      return [
        ...prev,
        ... currSums
      ];
    }, [])
    .filter((value) =>
      value > 0);

  const relevantPairs = summedPairs
    .filter((value) =>
      allSums.some(v =>
        v === value));

  if (_.isEmpty(relevantPairs)) return null;

  return _.uniq(relevantPairs).sort((a, b) =>
    b - a)[0];
};

