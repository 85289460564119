/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Moment } from 'moment';

import { ExternalInboxData, ExternalInterestItem, ExternalPaymentItem } from '../@types';
import { MaturityDateOpts, MATURITY_LATE_DAY } from './interestFormOptions';


export type ImportedFormExcelRow = string | number[];

interface ImpExternal {
  externalInterestData: ExternalInterestItem[],
  externalPaymentData: ExternalPaymentItem[],
  endDate: null | Moment,
  factualBasis: string | null,
  legalBasis: string | null,
  maturityDateOpts: MaturityDateOpts,
  shouldAutoAssign: boolean,
  step: number,
}

const initialState: ImpExternal = {
  externalInterestData: [],
  externalPaymentData: [],
  endDate: null,
  factualBasis: null,
  legalBasis: null,
  maturityDateOpts: MATURITY_LATE_DAY,
  shouldAutoAssign: false,
  step: 0,
};

export const impExternalSlice = createSlice({
  name: 'impExternal',
  initialState,
  reducers: {
    impExternalChanged: (state:ImpExternal, action: PayloadAction<ExternalInboxData>) => {
      state.externalInterestData = action.payload.externalInterestData;
      state.externalPaymentData = action.payload.externalPaymentData;
    },
    impExternalEndDateUpdated: (state:ImpExternal, action: PayloadAction<Moment | null>) => {
      state.endDate = action.payload;
    },
    impExternalLegalBasisUpdated: (state:ImpExternal, action: PayloadAction<string | null>) => {
      state.legalBasis = action.payload;
    },
    impExternalFactualBasisUpdated: (state:ImpExternal, action: PayloadAction<string | null>) => {
      state.factualBasis = action.payload;
    },
    impExternalMaturityOptsUpdated: (state:ImpExternal, action: PayloadAction<MaturityDateOpts>) => {
      state.maturityDateOpts = action.payload;
    },
    impExternalShouldAutoAssignUpdated: (state:ImpExternal, action: PayloadAction<boolean>) => {
      state.shouldAutoAssign = action.payload;
    },
    impExternalStepUpdated: (state:ImpExternal, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
    impExternalReset: () =>
      initialState,
  },
});

export const {
  impExternalChanged,
  impExternalEndDateUpdated,
  impExternalLegalBasisUpdated,
  impExternalFactualBasisUpdated,
  impExternalMaturityOptsUpdated,
  impExternalShouldAutoAssignUpdated,
  impExternalStepUpdated,
  impExternalReset
} = impExternalSlice.actions;

export default impExternalSlice.reducer;