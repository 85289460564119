import { CheckOutlined, CopyOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { ReactNode, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

type Props = {
  womargin?: boolean;
  value?: undefined | string;
  fullWidth?:boolean;
  disabled?: boolean;
  children?: ReactNode;
};

const CopyButton = (
  {
    womargin,
    value,
    children,
    fullWidth,
    disabled
  }: Props
) => {

  const [linkWasCopied, setLinkWasCopied] = useState<boolean>(false);

  const onCopied = () => {
    setLinkWasCopied(true);
    setTimeout(() => {
      setLinkWasCopied(false);
    }, 1000);
  };

  return (
    <CopyToClipboard text={value ?? ''} onCopy={onCopied}>
      <Button
        style={{
          marginTop: womargin ? 0 : 18,
          width: fullWidth ? '100%' : 'none'
        }}
        type='dashed'
        icon={linkWasCopied ? <CheckOutlined /> : <CopyOutlined />}
        disabled={disabled}
      >
        {children || texts.copy}
      </Button>
    </CopyToClipboard>
  );
};


export default CopyButton;

const texts = { copy: 'kopiuj ' };