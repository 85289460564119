import { Button, Space } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { LINK_FB, LINK_LI, LINK_YT } from '../../../../utils/helpers/links';
import useWindowSize from '../../../../utils/hooks/useWindowSize';
import { GRID_SM, RowExt } from '../../../../styles';
import { BLACK } from '../../../../styles/colors';
import { FACEBOOK, FACEBOOK_SOLID, LINKED_IN, LINKED_IN_SOLID, YOU_TUBE, YOU_TUBE_SOLID } from '../../../../styles/iconTypes';
import IconButton from '../../../UI/Button/IconButton';
import DotsEnding from '../../../UI/DotsEnding';

const SloganFooterSM = () => {
  const { width } = useWindowSize();
  return (
    <>
      {width <= GRID_SM ? (
        <>
          <RowExt
            align="middle"
            fullWidth
            justify="center"
            marginTop="bg"
          >
            <Space>
              <IconButton
                href={LINK_YT}
                iconType={YOU_TUBE}
                iconHoover={YOU_TUBE_SOLID}
                stroke
                style={{
                  marginTop:8, marginRight: 12
                }}
              />
              <IconButton
                href={LINK_LI}
                iconType={LINKED_IN}
                iconHoover={LINKED_IN_SOLID}
                stroke
                style={{
                  marginTop:5, marginRight: 12
                }}
              />
              <IconButton
                href={LINK_FB}
                iconType={FACEBOOK}
                iconHoover={FACEBOOK_SOLID}
                stroke
                style={{
                  marginTop:6, marginRight: 6
                }}
              />
              <Link
                to='/terms'
              >
                <Button
                  type="link"
                  style={{ color:BLACK }}
                >
                  Regulamin
                </Button>
                <Link
                  to="/privacy"
                >
                  <Button
                    type="link"
                    style={{ color:BLACK }}
                  >
                    Prywatność
                  </Button>
                </Link>
              </Link>
            </Space>
          </RowExt>
          <DotsEnding />
        </>
      ) : null}

    </>
  );
};

export default SloganFooterSM;
