import { Col, Steps, Typography } from 'antd';
import React, { ReactNode } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import logo from '../../../assets/logo.svg';
import { RowExt, ScreenLayout } from '../../../styles';
import { ImageLogo } from '../../Home/Menu/MenuLogo';
import BackButton from '../../Lawsuit/StepCard/BackButton';
import AnimOpacity from '../Anim/AnimOpacity';
import DotsEnding from '../DotsEnding';

const { Step } = Steps;

const PaymentContainer = (
  {
    children,
    title,
    desc,
    step
  }: {
    children?:ReactNode
    title?:string,
    desc?: string,
    step: number
  }
) => {
  const history = useHistory();
  return (
    <AnimOpacity>
      <ScreenLayout>
        <FullRow justify="center">
          <Wrapper>
            <RowExt
              fullWidth
              paddingTop="md"
              paddingBottom={24}
            >
              <Col
                xs={{
                  offset: 2,
                  span:20,
                }}
              >
                <Link
                  to="/"
                >
                  <ImageLogo
                    src={logo}
                  />
                </Link>
              </Col>
              <Col
                xs={{
                  offset: 2,
                  span:20,
                }}
                style={{ marginTop: 32 }}
              >
                <Steps
                  size="small"
                  labelPlacement="vertical"
                  current={step}
                >
                  <Step title="Konto" />
                  <Step title="Okres próbny" />
                </Steps>
              </Col>
            </RowExt>
            <RowExt
              fullWidth
              style={{ flex:1 }}
            >

              <Col
                xs={{
                  offset: 2,
                  span:20,
                }}
              >
                {title ? (
                  <>
                    <Typography.Title level={2}>{title}</Typography.Title>
                    <RowExt marginBottom="sm">
                      <Typography.Text type="secondary">{desc}</Typography.Text>
                    </RowExt>
                  </>
                )
                  : null}
                {children}
              </Col>
            </RowExt>
            <RowExt
              justify="center"
              marginTop={48}
            >
              <BackButton
                onBack={() =>
                  history.goBack()}
                showHome={false}
              />
            </RowExt>
            <DotsEnding />
          </Wrapper>
        </FullRow>
      </ScreenLayout>
    </AnimOpacity>
  );
};

export default PaymentContainer;


const Wrapper = styled.div`
  width: 425px;
  text-align: start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const FullRow = styled(RowExt)`
  height: 100vh;
`;