import { shallowEqual } from 'react-redux';
import { formatPLN } from '../../helpers/formatNumber';
import { COMPENSATION_BASIS } from '../../helpers/lawBasis';
import { getInterestDesc } from '../../../services/calc/interestDesc';
import { useTypedSelector } from '../../../store';
import { JUST_DEM_PAY, JUST_JUDGEMENT, JUST_LS_MAIN, JUST_WITH_CAP } from '../../../store/justOpts';

const useJustHead = () => {

  const {
    partialResults,
    overallInterestsSum,
    compensationSum,
    finalSum,
  } = useTypedSelector(state =>
    state.sumUp, shallowEqual);

  const {
    withComp,
    justType,
    justIntCap,
    withCompWPS,
  } = useTypedSelector(state =>
    state.justOpts, shallowEqual);

  const {
    compensationOn,
    principalAmountOpt,
    typeOfInterest,
  } = useTypedSelector(state =>
    state.sumUp.lawsuitOpts, shallowEqual);

  const lsMainIntro = 'Kwota dochodzona zgodnie z żądaniem pozwu w wysokości';

  const mainSumMathJust = formatPLN(finalSum + overallInterestsSum + (withCompWPS ? compensationSum : 0));

  const includes = () => {
    switch (justType) {
      case JUST_JUDGEMENT:
        return 'zawierała w sobie';
      case JUST_LS_MAIN:
        return  'zawiera w sobie';
      default:
        return 'zawierającej w sobie';
    }
  };

  const capitalized =  principalAmountOpt === 'PRINCIPAL_INTEREST' ? 'skapitalizowane' : 'oraz skapitalizowane';
  const mainSumIncMath = `${partialResults.length > 1 ? 'kwotę roszczeń o łącznej' : 'kwotę roszczenia w'} wysokości ${formatPLN(finalSum)}`;

  const intMath = `${getInterestDesc(typeOfInterest).name} w ${partialResults.length > 1 ? 'łącznej ' : ''}wysokości ${formatPLN(overallInterestsSum)}`;

  const getCompText = () => {
    const compText = `, a także rekompensatę w rozumieniu ${COMPENSATION_BASIS} w ${partialResults.length > 1 ? 'łącznej' : ''} wysokości ${formatPLN(compensationSum)}.`;
    if (justType === JUST_DEM_PAY && compensationOn && withComp) return compText;
    if (justType !== JUST_DEM_PAY && compensationOn && withCompWPS) return compText;
    return '.';
  };

  const demIntro = 'Działając imieniem.../uzupełnij/ wzywam Panią/Pana/Państwa do zapłaty kwoty ';

  const intro = justType === JUST_DEM_PAY ? demIntro : lsMainIntro;


  if (justIntCap === JUST_WITH_CAP){
    if (principalAmountOpt === 'PRINCIPAL_INTEREST'){
      return `${intro} ${mainSumMathJust}, ${includes()} ${capitalized} ${intMath}${getCompText()}`;
    }
    return `${intro} ${mainSumMathJust}, ${includes()} ${mainSumIncMath} ${capitalized} ${intMath}${getCompText()}`;
  }

  if (!withCompWPS){
    if (justType === JUST_DEM_PAY){
      return `${demIntro} ${formatPLN(finalSum)}.`;
    }
    return `Kwota dochodzona zgodnie z żądaniem pozwu wynosi ${formatPLN(finalSum)}.`;
  }

  return `${intro} ${formatPLN(finalSum + (withCompWPS ? compensationSum : 0))}, ${includes()} ${mainSumIncMath}${getCompText()}`;
};

export default useJustHead;
