import { Col, Steps } from 'antd';
import React, { memo } from 'react';

import { useTypedSelector } from '../../store';
import { GRID_MD, RowExt } from '../../styles';
import useIsTransacOrAgree from '../../utils/hooks/useIsTransacOrAgree';
import useWindowSize from '../../utils/hooks/useWindowSize';


const { Step } = Steps;
const LawsuitSteps = () => {
  const  step = useTypedSelector(state =>
    state.lawsuitUI.step);
  const isTransacOrAgree = useIsTransacOrAgree();
  const { interestDataProvider } = useTypedSelector(state =>
    state.lawsuitUI);

  const { width } = useWindowSize();

  const renderSteps = () => {
    const amountOfSteps = isTransacOrAgree ? 6 : 5;
    const increaseNumber = interestDataProvider === 'MANUAL_PAYMENTS_ASSIGNMENTS' ? 1 : 0;
    const steps = [];
    for (let index = 0; index < amountOfSteps + increaseNumber; index++) {
      steps.push(<Step
        key={index}
      />);
    }
    return steps;
  };

  const editKey = useTypedSelector(state =>
    state.edit.key);

  const isImportExVisible = useTypedSelector(state =>
    state.importEx.isImportExVisible);

  if (isImportExVisible){
    return null;
  }

  if (editKey){
    return null;
  }

  if (width < GRID_MD){
    return null;
  }

  return (
    <RowExt
      justify="center"
      fullWidth
      marginTop="bg"
    >
      <Col
        lg={{
          offset:0,
          span:24,
        }}
        xs={{ span: 20 }}
      >
        <Steps
          current={step}
          size='small'
          direction="horizontal"

        >
          {renderSteps()}
        </Steps>
      </Col>
    </RowExt>
  );
};

Steps.propTypes = {};

export default memo(LawsuitSteps);
