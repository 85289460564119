import { Row } from 'antd';
import React, { useState } from 'react';
import { Img } from 'react-image';
import styled from 'styled-components';
import { BLUE, BLUE_HOVER } from '../../../styles';
import Icon from '../../../styles/Icon';

const VideoPlaceholder = (
  {
    showVideo,
    src,
    color,
    noBorder,
  }: {
    showVideo: ()=>void,
    src: string,
    color?: string,
    noBorder?:boolean,
  }
) => {
  const [isVideoHovered, setIsVideoHovered] = useState(false);
  return (
    <VideoPlaceholderWrapper
      onClick={showVideo}
      onMouseEnter={() =>
        setIsVideoHovered(true)}
      onMouseLeave={() =>
        setIsVideoHovered(false)}
      isVideoHovered={isVideoHovered}
      noBorder={noBorder}
    >
      <>
        <Row justify="center">
          <PlaceholderImg
            $noBorder={noBorder}
            color={color}
            src={src}
            loader={(
              <ImagePlaceholder />
              )}
            unloader={(
              <ImagePlaceholder />
              )}
          />
        </Row>
        <Container>
          <Icon
            height="42"
            width="42"
            fill={color ?? BLUE}
            iconType={isVideoHovered ? 'PLAY_SOLID' : 'PLAY'}
          />
        </Container>
      </>
    </VideoPlaceholderWrapper>
  );
};

export default VideoPlaceholder;

const VideoPlaceholderWrapper = styled.div<{ isVideoHovered?:boolean, noBorder?: boolean, }>`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px  8px 8px rgba(0, 0, 0, 0.11);
  border-radius: 6px;
  ${props =>
    props.noBorder ? 'border: none; box-shadow: none;' : '' }
  @media only screen and (max-width: 767px) {
    border: none;
    box-shadow: none;
	}
`;
const PlaceholderImg = styled(Img)<{
  isVideoHovered?:boolean,
  color?:string,
  $noBorder?: boolean
}>`
    width: 100%;
    height: 100%;
    @media only screen and (min-width: 768px) {
        border-radius: 6px;
      border:${({ isVideoHovered }) =>
    isVideoHovered ? '2px' : '1px'} solid ${({ isVideoHovered, color }) =>
  isVideoHovered ? (color ?? BLUE_HOVER) : (color ?? BLUE)};
    ${props =>
    props.$noBorder ? 'border: none;' : ''}
	}

`;

const Container = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    z-index: 2;
`;

const ImagePlaceholder = styled.div`
  position: relative;
  width: 100%;
  height: 326px;
`;
