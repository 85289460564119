//  @ts-ignore
export const wordIndex = {
  '': 0,
  '[UNK]': 1,
  'data': 2,
  'numer': 3,
  'vat': 4,
  'kwota': 5,
  'transakcji': 6,
  'nazwa': 7,
  'rachunek': 8,
  'nr': 9,
  'operacji': 10,
  'sa': 11,
  'rachunku': 12,
  'z': 13,
  'netto': 14,
  'i': 15,
  '\xa0zł': 16,
  'brutto': 17,
  'bank': 18,
  'bankowego': 19,
  'faktury': 20,
  'zł': 21,
  'usługi': 22,
  'zapłaty': 23,
  'do': 24,
  'ul': 25,
  'nie': 26,
  'odbiorca': 27,
  'potwierdzenie': 28,
  'wymaga': 29,
  'księgowania': 30,
  'dnia': 31,
  'nadawca': 32,
  'przelew': 33,
  'sp': 34,
  'towaru': 35,
  'wystawienia': 36,
  'wartość': 37,
  'sc': 38,
  'płatności': 39,
  'oo': 40,
  'plac': 41,
  'pl': 42,
  'spk': 43,
  'banku': 44,
  'nadawcy': 45,
  'al': 46,
  'ulica': 47,
  'aleja': 48,
  'lp': 49,
  'stawka': 50,
  'wykonania': 51,
  'razem': 52,
  'tytuł': 53,
  'przelewu': 54,
  'podpisu': 55,
  'w': 56,
  'produktu': 57,
  'odbiorcy': 58,
  'referencyjny': 59,
  'pln': 60,
  'liczba': 61,
  'dostawy': 62,
  'dokument': 63,
  'faktura': 64,
  'gotówka': 65,
  'dane': 66,
  'ani': 67,
  'nabywca': 68,
  'sprzedawca': 69,
  'stempla': 70,
  'płatne': 71,
  'suma': 72,
  'termin': 73,
  'ing': 74,
  'dokumentu': 75,
  'kraków': 76,
  'syn': 77,
  'klient': 78,
  'cena': 79,
  'typ': 80,
  'dokonania': 81,
  'się': 82,
  'dostawca': 83,
  'elektronicznie': 84,
  'szczegóły': 85,
  'pekao': 86,
  'usługobiorca': 87,
  'polski': 88,
  'spj': 89,
  'nip': 90,
  'usługodawca': 91,
  'sprzedaży': 92,
  'adres': 93,
  'o': 94,
  'lub': 95,
  'polska': 96,
  'grupa': 97,
  'na': 98,
  'spółdzielnia': 99,
  'stowarzyszenie': 100,
  'jest': 101,
  'fundacja': 102,
  'gabinety': 103,
  'forma': 104,
  'fpuh': 105,
  'ppuh': 106,
  'zleceniodawca': 107,
  'opis': 108,
  'właściciel': 109,
  'mbank': 110,
  'waluty': 111,
  'rodzaj': 112,
  'wygenerowane': 113,
  'informacje': 114,
  'winien': 115,
  'za': 116,
  'komputerowym': 117,
  'pieczątki': 118,
  'santander': 119,
  'wygenerowany': 120,
  'alior': 121,
  'kasa': 122,
  'powszechna': 123,
  'oszczędności': 124,
  'śląski': 125,
  'całkowita': 126,
  'sposób': 127,
  'ogólna': 128,
  'metoda': 129,
  'płatność': 130,
  'wystawiono': 131,
  'oraz': 132,
  'płataności': 133,
  'czas': 134,
  'ten': 135,
  'waluta': 136,
  'zamówienia': 137,
  'podpis': 138,
  'osoby': 139,
  'szt': 140,
  'ma': 141,
  'ilość': 142,
  'godzina': 143,
  'pomocą': 144,
  'dostawywykonania': 145,
  'a': 146,
  'zakończenia': 147,
  'słownie': 148,
  'tytułem': 149,
  'dzienny': 150,
  'realizacji': 151,
  'zaksięgowana': 152,
  'millnet': 153,
  'podpisów': 154,
  'tytułu': 155,
  'fv': 156,
  'wydrukiem': 157,
  'obiorcy': 158,
  'dodatkowych': 159,
  'paribas': 160,
  'bnp': 161,
  'został': 162,
  'wydruku': 163,
  'systemu': 164,
  'pko': 165,
  'złożenia': 166,
  'dyspozycji': 167,
  'internetowej': 168,
  'deutsche': 169,
  'bankowości': 170,
  'wydrukem': 171,
  'uwierzytelnienia': 172,
  'niniejszy': 173,
  'komputerowo': 174,
  'biznespignet': 175,
  'płatnika': 176,
  'kwoata': 177,
  'Śląskim': 178,
  'transakzji': 179,
  'strona': 180,
  'millenet': 181,
  'przy': 182,
  'mazowiecki': 183,
  'warszawa': 184,
  'jm': 185,
  'chodkiewicza': 186,
  'upoważnionej': 187,
  'odbioru': 188,
  ']': 189,
  'nowy': 190,
  'tel': 191,
  'r': 192,
  'mieszkaniowa': 193,
  'zapłacono': 194,
  'krakowie': 195,
  'budynku': 196,
  'mięsowicz': 197,
  'imię': 198,
  'wspólnota': 199,
  'miejsce': 200,
  'konto': 201,
  'opłata': 202,
  'wola': 203,
  'm': 204,
  'krakÓw': 205,
  'jana': 206,
  'pkwiu': 207,
  'nowa': 208,
  'arkana': 209,
  'wielkopolski': 210,
  'od': 211,
  'uprawnionej': 212,
  'nazwisko': 213,
  'góra': 214,
  'tym': 215,
  '—': 216,
  'b': 217,
  'tadeusz': 218,
  'przez': 219,
  'gdański': 220,
  'firma': 221,
  'ostrów': 222,
  'konta': 223,
  'ruda': 224,
  'pozostało': 225,
  'Śląski': 226,
  'dla': 227,
  'art': 228,
  'telefon': 229,
  'dziewięć': 230,
  'zgodnie': 231,
  'sól': 232,
  'sto': 233,
  'pięćdziesiąt': 234,
  'okres': 235,
  'Śląskie': 236,
  'trzy': 237,
  'prawo': 238,
  'osoba': 239,
  'koło': 240,
  'brzeg': 241,
  'złotych': 242,
  'poruszać': 243,
  'po': 244,
  'kod': 245,
  'jedn': 246,
  'górnicza': 247,
  'zero': 248,
  'składać': 249,
  'podatku': 250,
  'piłka': 251,
  'dni': 252,
  'Łódź': 253,
  'zielona': 254,
  'zamość': 255,
  'symbol': 256,
  'pierwiastek': 257,
  'mazowiecka': 258,
  'klienta': 259,
  'email': 260,
  'chemiczny': 261,
  'zw': 262,
  'otwock': 263,
  'jeden': 264,
  'dwadzieścia': 265,
  'czterdzieści': 266,
  'Żagań': 267,
  'Śląska': 268,
  'zakopane': 269,
  'x': 270,
  'sześćdziesiąt': 271,
  'powstańców': 272,
  'police': 273,
  'gr': 274,
  'bóg': 275,
  'słońce': 276,
  'sąd': 277,
  'stalowa': 278,
  'rumia': 279,
  'nysa': 280,
  'morze': 281,
  'groszy': 282,
  'gorzów': 283,
  'dwa': 284,
  'danych': 285,
  'zambrów': 286,
  'zajmować': 287,
  'uwagi': 288,
  'prawny': 289,
  'obiekt': 290,
  'kętrzyn': 291,
  'katowice': 292,
  'głogów': 293,
  'dąbrowa': 294,
  '[pln]': 295,
  'towaruusługi': 296,
  'tomaszów': 297,
  'sanok': 298,
  'płock': 299
};
