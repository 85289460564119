import _ from 'lodash';
import { RGX_PAGE_NUMBERS } from './regexExtraction';

export const extractPageNumber = (doc: string) => {
  const results = doc.match(RGX_PAGE_NUMBERS);
  if (_.isNull(results)) return {
    pageNumber: null,
    lastPageNumber: null
  };

  const pageNumbers = results[0].match(/\d+/gim);

  if (_.isNull(pageNumbers)) return {
    pageNumber: null,
    lastPageNumber: null
  };

  return {
    pageNumber: _.toNumber(pageNumbers[0]),
    lastPageNumber: _.isString(pageNumbers[1]) ? _.toNumber(pageNumbers[1]) : null
  };
};