import { AppDispatch } from '../../store';
import { currencyReset } from '../../store/currency';
import { editReset } from '../../store/edit';
import { importExcelReset } from '../../store/importExcel';
import { interestFormReset } from '../../store/interestForm';
import { interestFormOptionsReset } from '../../store/interestFormOptions';
import { interestStorageReset } from '../../store/interestStorage';
import { lawsuitOptsReset } from '../../store/lawsuitOpts';
import { lsReset } from '../../store/lawsuitUI';

export const resetLawsuit = (dispatch: AppDispatch) => {
  dispatch(lsReset());
  dispatch(editReset());
  dispatch(importExcelReset());
  dispatch(interestFormReset());
  dispatch(currencyReset());
  dispatch(interestFormOptionsReset());
  dispatch(interestStorageReset());
  dispatch(lawsuitOptsReset());
};