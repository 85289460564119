import React from 'react';
import './App.less';
import Router from './routes';



const App = () =>
  (
    <Router />

  );

export default App;
