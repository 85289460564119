import React from 'react';
import { useTypedSelector } from '../../../store';
import JustParagraph from './JustParagraph';

const JustLsCode = () => {
  const lsCode = useTypedSelector(state =>
    state.sumUp.lsCode);

  const code =
  'Bezpośredni dostęp do kwot i obliczeń przedstawionych powyżej znajdziesz na stronie www.pozew.app po wpisaniu KODU WYLICZEŃ:';

  if (lsCode){
    return (
      <>
        <JustParagraph
          text={code}
        />
        <JustParagraph
          text={lsCode}
        />
      </>
    );
  }
  return null;
};

export default JustLsCode;
