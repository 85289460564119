import _ from 'lodash';
import moment from 'moment';

import { InterestItem, PaymentData } from '../../../store/interestStorage';
import { LawsuitOpts } from '../../../store/lawsuitOpts';
import { getIndexFromKey, getNotAssignedSum, getNotCoveredSum } from '../../assignPaymentByKey';
import { claimCalc, isPaymentFullyAssigned } from '../../calc/claim';
import { AllocationMechanism,
  AllocationNote,
  AssignHistoryRecord,
  AUTO_ASSIGN_DEFAULT,
  isClaimFullyCovered } from '../autoAssignCommon';

export const autoAssignPaymentIndirect = ({
  isInterestFirst,
  lawsuitOpts,
  interestData,
  paymentData,
  paymentItemKey,
}:{
  paymentItemKey: string,
  paymentData: PaymentData,
  isInterestFirst: boolean,
  lawsuitOpts: LawsuitOpts,
  interestData: InterestItem[],
}) => {

  const paymentItemIndex = getIndexFromKey(paymentData, paymentItemKey);

  const { paymentDate } = paymentData[paymentItemIndex];

  const onPaymentDate = interestData.filter(value =>
    value.startingDate.isSameOrBefore(paymentDate));

  const sortedInterestData = _.cloneDeep(onPaymentDate).sort((itemA, itemB) =>
    moment(itemA.startingDate).toDate().getTime() - moment(itemB.startingDate).toDate().getTime());

  const assignedPaymentData = sortedInterestData.reduce((prev: {
    paymentData: PaymentData,
    historyRecords: AssignHistoryRecord[]
  }, interestItem) => {

    if (isPaymentFullyAssigned(prev.paymentData, paymentItemIndex)) return prev;

    const claim = claimCalc({
      interestItem,
      paymentData,
      lawsuitOpts,
    });

    if (isClaimFullyCovered(claim, isInterestFirst)) return prev;

    const notCoveredSum = getNotCoveredSum({
      interestItem,
      paymentItem: prev.paymentData[paymentItemIndex],
      paymentData: prev.paymentData,
      lawsuitOpts,
      isInterestFirst
    });

    const notAssignedSum = getNotAssignedSum(prev.paymentData, paymentItemIndex);

    const paymentDataCopy = _.cloneDeep(prev.paymentData);

    const fullAssignmentSum =  notCoveredSum <  notAssignedSum
      ? notCoveredSum : notAssignedSum;

    paymentDataCopy[paymentItemIndex].assignedTo.push(
      {
        key:interestItem.key,
        sum: fullAssignmentSum,
        isInterestFirst,
      });

    if ((notCoveredSum <  notAssignedSum)) {
      return {
        paymentData: paymentDataCopy,
        historyRecords: [
          ...prev.historyRecords,
          {
            interestItemKey: interestItem.key,
            allocationMechanism: AUTO_ASSIGN_DEFAULT as AllocationMechanism,
            note: 'partial' as AllocationNote
          }
        ]
      };
    }

    return {
      paymentData: paymentDataCopy,
      historyRecords: [
        ...prev.historyRecords,
        {
          interestItemKey: interestItem.key,
          allocationMechanism: AUTO_ASSIGN_DEFAULT as AllocationMechanism,
          note: null,
        }
      ]
    };

  }, {
    paymentData,
    historyRecords: []
  });

  return assignedPaymentData;
};