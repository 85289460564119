import React from 'react';
import { shallowEqual } from 'react-redux';
import { useTypedSelector } from '../../../store';
import FormAlert from '../../UI/Alerts/Alerts';


const EditAlert = () => {
  const { info, infoMsg } = useTypedSelector(state =>
    state.edit, shallowEqual);

  return (
    <FormAlert
      isOn={info}
      message={infoMsg}
    />
  );
};

export default EditAlert;
