import _ from 'lodash';
import moment, { Moment } from 'moment';
import nextId from 'react-id-generator';
import { assignmentDate } from '../../services/calc/claim';
import { useTypedSelector } from '../../store';
import { InterestItem, PaymentItem } from '../../store/interestStorage';


type AssignmentPeriod = {
  key: string,
  startOfPeriod: Moment,
  endOfPeriod: Moment,
  data:  (InterestItem | PaymentItem)[],
};

const useAssignmentPeriods = () => {
  const assignmentPeriods = useTypedSelector(state => {
    const { interestData, paymentData } = state.interestStorage;
    const joinedData = [...interestData, ...paymentData];
    return createAssignmentPeriods(joinedData);
  });
  return assignmentPeriods;
};

export default useAssignmentPeriods;

const createAssignmentPeriods = (joinedData:  (InterestItem | PaymentItem)[]) => {
  if (joinedData){
    const formattedJoinedData = joinedData.reduce((prev: [] | AssignmentPeriod[], current) => {
      const currentDateToCheck = getDateToCheck(current);
      const month = moment(currentDateToCheck).month();
      const year = moment(currentDateToCheck).year();
      const startOfPeriod = moment().year(year).month(month).startOf('month').startOf('day');
      const endOfPeriod =  moment().year(year).month(month).endOf('month').endOf('day');
      const createNewPeriod = () => {
        return [...prev, {
          key: nextId(),
          startOfPeriod,
          endOfPeriod,
          data: [{ ...current }]
        }];
      };
      if (_.isEmpty(prev)){
        return createNewPeriod();
      }
      const indexOfExistingPeriod = prev.findIndex(_prev =>
        moment(currentDateToCheck).isBetween(_prev.startOfPeriod, _prev.endOfPeriod)
      );

      if (indexOfExistingPeriod !== - 1){
        const newPrev = _.cloneDeep(prev);
        newPrev[indexOfExistingPeriod].data.push(current);
        return [...newPrev];
      }
      return createNewPeriod();
    }, []);

    if (_.isEmpty(formattedJoinedData)){
      return [];
    }

    const joinedDataWithSortedPeriods = formattedJoinedData.map((period) => {
      return ({
        ...period,
        data: period.data.sort((a, b) =>
          getDate(b) - getDate(a))
      });
    });

    return joinedDataWithSortedPeriods.sort((claimItemA, claimItemB) =>
      moment(claimItemB.startOfPeriod).toDate().getTime() - moment(claimItemA.startOfPeriod).toDate().getTime()
    );
  }
  return [];
};

const getDate = (item: PaymentItem | InterestItem) => {
  if ('paymentDate' in item){
    return moment(assignmentDate(item)).toDate().getTime();
  }
  return moment(item.startingDate).toDate().getTime();
};


const getDateToCheck = (current: InterestItem | PaymentItem) => {
  if ('startingDate' in current){
    return current.maturityDate ?? current.startingDate;
  }
  return current.paymentDate;
};

