import { Col, InputNumber, Typography } from 'antd';
import _ from 'lodash';
import React from 'react';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { currencySumChanged } from '../../../store/currency';
import { toAmount, toUnit } from '../../../utils/helpers/money';

const { Title, Paragraph } = Typography;

const CurrencySum = () => {
  const dispatch = useAppDispatch();
  const isSubscriber = useTypedSelector(state =>
    state.user.isSubscriber);

  const convertingSum = useTypedSelector(state =>
    state.currency.convertingSum);

  const onSumChange = (value: string | number | null | undefined) => {
    if (_.isNumber(value)){
      dispatch(currencySumChanged(toAmount(value)));
    }
  };

  return (
    <Col sm={{ span:8 }} xs={{ span: 24 }}>
      <Title level={4}>Kwota</Title>
      <Paragraph>W obcej walucie</Paragraph>
      <InputNumber
        placeholder="0"
        min={0}
        max={10000000000000}
        precision={2}
        value={toUnit(convertingSum)}
        onChange={onSumChange}
        style={{
          width: '100%', marginBottom: 24
        }}
        decimalSeparator=","
        disabled={!isSubscriber}
      />
    </Col>
  );
};

export default CurrencySum;
