import { FileExcelOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import excelThumb from '../../../assets/excelThumb.png';
import { YT_EXCEL } from '../../../utils/helpers/links';
import { BLUE_MEDIUM } from '../../../styles/colors';
import GuidePost from '../GuidePost';

const { Paragraph } = Typography;

const GuideImport = () => {
  return (
    <GuidePost
      title="Przekształć plik excel w tekst pozwu"
      color={BLUE_MEDIUM}
      desc={(
        <>
          <Paragraph>
            Importuj dane z księgowości bezpośrednio do formularza. Oszczędź jeszcze więcej czasu, dodatkowo
            {' '}
            <strong>zmniejsz ryzyko pomyłki</strong>
            . Stwórz
            {' '}
            <strong>listę dowodów</strong>
            {' '}
            na bazie danych księgowych.
          </Paragraph>
          <Link to="/importExcel">
            <Button
              type="link"
              icon={<FileExcelOutlined />}
            >
              Zacznij używać
            </Button>
          </Link>
        </>
      )}
      src={excelThumb}
      url={YT_EXCEL}
    />
  );
};

export default GuideImport;
