/* eslint-disable @typescript-eslint/no-explicit-any */
import { ArrowLeftOutlined, CheckOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Divider, Image, Input, InputNumber, Row, Typography } from 'antd';
import { AnimatePresence, motion } from 'framer-motion';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import nextId from 'react-id-generator';
import styled from 'styled-components';

import { EvidInput, InvVatFields, PayConfFields } from '../../services/ML/DataExtraction/fieldInputMap';
import { RGX_CURR_EURO_STYLE } from '../../services/ML/DataExtraction/regexExtraction';
import { useTypedSelector } from '../../store';
import { DocPage } from '../../store/impOcr';
import { BLACK, RowBg, RowSm } from '../../styles';
import { disabledUntilToday } from '../../utils/helpers/date';
import ScrollToTop from '../../utils/hoc/ScrollToTop';
import AnimOpacity from '../UI/Anim/AnimOpacity';
import LawContainer from '../UI/Containers/LawContainer';
import NavPrivate from '../UI/Nav/NavPrivate';
import { EvidTextTag } from './EvidTextTag';

const { Title, Paragraph } = Typography;

const Inputs = ({ inputType, onChange, value }:  { inputType: EvidInput, onChange: any, value: Moment | null | string | number  } ) => {
  switch (inputType) {
    case 'calendar':
      return (
        <DatePicker
          allowClear
          placeholder='Wybierz datę'
          format="DD-MM-YYYY"
          style={{ width:'100%' }}
          onChange={onChange}
          value={moment(value).isValid() ? moment(value) : null}
          disabledDate={disabledUntilToday}
        />
      );
    case 'number':
      return (
        <InputNumber
          placeholder="0 zł"
          min={0}
          max={10000000000000}
          precision={2}
          onChange={onChange}
          style={{ width: '100%' }}
          decimalSeparator=","
          value={_.isNumber(value) || _.isString(value) ? value : undefined}
        />
      );
    default:
      return (
        <Input
          placeholder="Wpisz dane"
          onChange={onChange}
          value={_.isString(value) ? value : undefined}
          allowClear
        />
      );
  }

};


const EvidEditField = ({
  onClose,
  pages,
  fieldValue,
  fieldInputMap,
  fieldType,
  onFinishEditing,
}:{
  pages: DocPage[],
  onClose: ()=> void,
  fieldValue: string | null | number,
  fieldType: InvVatFields | PayConfFields,
  fieldInputMap: Map<InvVatFields | PayConfFields, EvidInput>
  onFinishEditing: (value: string | null | number | Moment) => void,
}) => {
  const [field, setField] = useState<string | Moment | number | null>(fieldValue);

  const inputType = fieldInputMap.get(fieldType) ?? 'string';

  const words = useTypedSelector(state => {
    const { ocrData, editedIndex } = state.impOcr;
    if (_.isNumber(editedIndex)){
      return ocrData[editedIndex].doc
        .replace(/(\r\n|\n|\r)/gm, ' ')
        .replace(/ +(?= )/g, '')
        .split(' ');
    }
    return [];
  });

  const numbersInWord = /\S*\d+\S*/g;

  const onWordPress = (word:string) => {
    if (inputType === 'string'){
      setField(`${_.isString(field) ? `${field} ` : ''}${ `${  word}`}`);
    } else if (inputType === 'number' && RGX_CURR_EURO_STYLE.test(word)){
      setField(0);
      setField(word.replace(/,/g, '.')
        .replace(/\s/g, ''));
    } else if (inputType === 'number' && numbersInWord.test(word) && !RGX_CURR_EURO_STYLE.test(word)){
      setField(0);
      setField(_.toNumber((word.replace(/\D/g, ''))));
    }

  };

  useEffect(() => {
    window.scrollTo(0, 0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AnimOpacity>
      <LawContainer>
        <ScrollToTop />
        <NavPrivate />
        <div>

          <AnimatePresence>
            <motion.div
              transition={{
                ease: 'easeInOut',
                duration: 1,
              }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <Title
                level={2}
                style={{ marginTop: 48 }}
              >
                <EditOutlined />
                {' '}
                Edycja manualna
              </Title>
              <Paragraph>
                Przenalizuj rozpoznany tekst. Naciskając na słowo - dodasz je.
              </Paragraph>
              <Divider />
            </motion.div>
          </AnimatePresence>
          <RowSm
            justify='end'
          >
            <Col span={6}>
              <Row>
                <Button
                  icon={<ArrowLeftOutlined />}
                  onClick={onClose}
                  type="dashed"
                />
              </Row>
            </Col>
            <Col span={12} style={{ border: `${BLACK} solid 0.5px` }}>
              <Inputs
                value={field}
                inputType={inputType}
                onChange={setField}
              />
            </Col>
            <Col span={6}>
              <Row justify='end'>
                <Button
                  icon={<CheckOutlined />}
                  type="primary"
                  onClick={() =>
                    onFinishEditing(field)}
                />
              </Row>
            </Col>
          </RowSm>
        </div>
        <RowBg>
          {words.map(word =>
            (
              <EvidTextTag
                key={nextId()}
                text={word}
                onClick={() =>
                  onWordPress(word)}
              />
            ))}
        </RowBg>
        <RowBg>
          {pages.map(value =>
            (
              <ImageStyled
                key={nextId()}
                src={value.image ?? ''}
              />
            ))}
        </RowBg>
      </LawContainer>
    </AnimOpacity>
  );
};

export default EvidEditField;

const ImageStyled = styled(Image)`
  border-radius: 4px;
  border: 0.5px solid ${BLACK};
`;