import { CalculatorOutlined, DownloadOutlined, HomeOutlined, SaveOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import React, { useState } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { editResults } from '../../services/editResults';
import { saveJson } from '../../services/handleJson';
import { useTypedSelector } from '../../store';
import { saveModalOpened } from '../../store/saveLawsuitModal';
import { RowExt } from '../../styles';
import { ButtonFullWidth, LinkFullWidth } from '../../styles/ui';
import NewLawsuit from '../NewLawsuit/NewLawsuit';


const ResultLsCodeActions = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { lawsuitOpts, partialResults, paymentData } = useTypedSelector(state =>
    state.sumUp, shallowEqual);

  const userExists = useTypedSelector(state =>
    state.user.isNonAnonymousUserLoggedIn);

  const handleSaveToPC = () => {
    saveJson({
      paymentData,
      partialResults,
      lawsuitOpts
    });
  };

  const [isModalVisible, setModalVisible] = useState(false);

  const onEdit = () => {
    dispatch(editResults({ history }));
  };

  const interestData = useTypedSelector(state =>
    state.interestStorage.interestData);

  const handleEdit = () => {
    if (interestData.length > 0){
      setModalVisible(true);
    } else {
      onEdit();
    }
  };

  return (
    <>
      <RowExt marginTop={32} justify="space-between" align="middle" gutter={[{ sm:16 }, { xs:24 }]}>
        <Col sm={8} xs={24}>
          <ButtonFullWidth
            icon={<DownloadOutlined />}
            onClick={handleSaveToPC}
          >
            Zapisz lokalnie
          </ButtonFullWidth>
        </Col>
        <Col sm={8} xs={24}>
          <Row justify="center">
            <ButtonFullWidth
              icon={<SaveOutlined />}
              type='primary'
              onClick={() => {
                dispatch(saveModalOpened({
                  isSaveModalVisible: true,
                  isSaveFromResult: true,
                }));
              }}
            >
              Zapisz
            </ButtonFullWidth>
          </Row>
        </Col>
        <Col sm={8} xs={24}>
          <ButtonFullWidth
            type='primary'
            icon={<CalculatorOutlined />}
            onClick={handleEdit}
          >
            Edytuj
          </ButtonFullWidth>
        </Col>
      </RowExt>
      <RowExt marginTop={24} justify="space-between" align="middle" marginBottom="bg" gutter={[{ sm:16 }, { xs:24 }]}>
        <Col sm={8} xs={24}>
          <LinkFullWidth
            to={{ pathname: userExists ? '/dashboard' : '/' }}
          >
            <ButtonFullWidth
              icon={<HomeOutlined />}
            >
              Wróć
            </ButtonFullWidth>
          </LinkFullWidth>
        </Col>
      </RowExt>
      <NewLawsuit
        {...{
          isModalVisible,
          setModalVisible,
          isEdit: true,
          onEdit
        }}
      />
    </>
  );
};


export default ResultLsCodeActions;
