import { shallowEqual } from 'react-redux';

import { useTypedSelector } from '../../../store';
import { JUST_WITH_CAP } from '../../../store/justOpts';
import { PartialResult } from '../../../store/sumUp';
import { ENT } from '../../helpers/arrStrFormat';
import { interestBasicSumUp,
  interestCoveredText,
  interestFulfilledSumUp,
  interestPartialDesc,
  interestPartialIntro,
  periodText } from './justInterestPartials';
import { paymentInterestCalcBasis } from './justPayments';


const useInterestPartials = () => {
  const { partialResults } = useTypedSelector(state =>
    state.sumUp, shallowEqual);
  const { justIntCap } = useTypedSelector(state =>
    state.justOpts, shallowEqual);
  if (justIntCap === JUST_WITH_CAP) {
    return partialResults.reduce((prev: string, curr, index) => {

      const {
        interestsSumFulfilledUncovered,
        isClaimFromFulfilled
      } = curr;
      const singleResult = partialResults.length === 1;

      const intro = interestPartialIntro({
        ...curr,
        singleResult,
        index,
      });
      const plural = curr.periodResults?.length ? curr.periodResults?.length > 1 : false;

      const shouldShowFulfilled = interestsSumFulfilledUncovered > 0 && isClaimFromFulfilled;

      const basicInterestSumUp =
      plural ?
        [
          ENT,
          interestBasicSumUp(curr.periodResults, curr.interestsSum, curr.partialSum)
        ].join('') : 0;

      const partialResultText =
      [
        ENT,
        /* A. interest partial intro */
        intro,
        /* -------------------------------- fulfilled ------------------------------- */
        /*
          B. desc
          C. periods
          D. interest calculation sum basis
          E. fulfilled sum up
        */
        interestFulfilledText({
          result: curr,
          index,
          shouldShowFulfilled,
        }),
        /* -------------------------------- basic ------------------------------- */
        ENT,
        // B. desc
        interestPartialDesc(curr, false),
        // C. periods
        basicPeriodsText(curr, partialResults.length - 1 === 0),
        // F. basic interests sum up
        basicInterestSumUp,
        // G. covered basic interest
        interestCoveredText(curr)
      ].join('');

      return prev + partialResultText;
    }, '');
  }
  return '';
};

export default useInterestPartials;


const interestFulfilledText = ({
  result,
  index,
  shouldShowFulfilled,
}:{
  result: PartialResult,
  index: number,
  shouldShowFulfilled: boolean,
}) =>
  shouldShowFulfilled ?
    [
      ENT,
      // B. desc
      interestPartialDesc(result, true),
      // C. periods
      // D. interest calculation sum basis
      fulfilledPeriodsText(result),
      ENT,
      // E. fulfilled sum up
      interestFulfilledSumUp(result, index)
    ].join('') : '';

const fulfilledPeriodsText = (result: PartialResult) =>
  result.interestResultsFulfilled.map(({ interestSum, periodResults }, idx) => {
    return [
      ENT,
      periodResults.map(periodResult =>
        periodText(
          periodResult,
          'odsetki',
          periodResults.length - 1 === 0
        )),
      paymentInterestCalcBasis(result, interestSum, idx)
    ].join(ENT);
  });

const basicPeriodsText = (result: PartialResult, isLastPeriod: boolean) =>
  result.periodResults.reduce((prevPeriod, currResult) => {
    return (
      [
        prevPeriod,
        periodText(
          currResult,
          'odsetki',
          isLastPeriod),
      ].join(ENT + ENT)
    );
  }, '');