import { UserAddOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import React from 'react';
import { useHistory } from 'react-router';
import AddSubscriptionUser from '../components/ConnectedSubUsers/AddSubscriptionUser';
import SubscriptionUsersList from '../components/ConnectedSubUsers/SubscriptionUsersList';
import BackButton from '../components/Lawsuit/StepCard/BackButton';
import AnimOpacity from '../components/UI/Anim/AnimOpacity';
import CheckVideoButton from '../components/UI/Button/CheckVideoButton';
import PrivateContainer from '../components/UI/Containers/PrivateContainer';
import DotsEnding from '../components/UI/DotsEnding';
import NavPrivate from '../components/UI/Nav/NavPrivate';
import { YT_MENAGE_USERS } from '../utils/helpers/links';
import { BLACK } from '../styles';
import { RowRes } from './User';

const { Title, Text, Paragraph } = Typography;

const ConnectedSubUsers = () => {

  const history = useHistory();
  return (
    <PrivateContainer>
      <div>
        <NavPrivate />
        <AnimOpacity>
          <RowRes>
            <Title level={2}>
              <UserAddOutlined />
              {' '}
              Zarządzaj użytkownikami
            </Title>
            <Text>
              Dodaj
              {' '}
              <strong>istniejącego</strong>
              {' '}
              użytkownika do Twojej subskrypcji. Usuń nieakutalne konta.
            </Text>
            <CheckVideoButton
              url={YT_MENAGE_USERS}
              emptyButtonProps={{
                color: BLACK, hoverBg: BLACK, hoverColor: 'white', full:true, dotted: false
              }}
              text="VIDEO - jak zarządzać użytkownikami"
            />
            <Title
              level={4}

            >
              Użytkownicy subskrypcji
            </Title>
            <Paragraph
              type="secondary"
              style={{ marginBottom:48 }}
            >
              Osoby korzystające z Twojej subskrypcji.
            </Paragraph>
            <SubscriptionUsersList />
            <AddSubscriptionUser />
          </RowRes>
        </AnimOpacity>
      </div>
      <RowRes>
        <BackButton
          onBack={() =>
            history.goBack()}
          showHome={false}
        />
      </RowRes>
      <DotsEnding />
    </PrivateContainer>
  );
};

export default ConnectedSubUsers;
