/* eslint-disable react-hooks/exhaustive-deps */
import notification from 'antd/lib/notification';
import _ from 'lodash';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';

import { LocalLawsuitSave } from '../../services/handleJson';
import { setSumUp } from '../../services/setSumUp';
import { AppDispatch, useAppDispatch } from '../../store';
import { appLoadingStarted, appLoadingStopped } from '../../store/appLoading';
import { InterestItem } from '../../store/interestStorage';
import { justOptsReset } from '../../store/justOpts';
import { InterestTypes, PrincipalAmountOpt } from '../../store/lawsuitOpts';
import { checkSaveData } from '../helpers/checkSaveData';
import { errDescGeneral, errMsgGeneral } from '../helpers/errorTexts';
import { formatInterestDataFromSave } from '../helpers/formatInterestDataFromSave';
import { formatPaymentsFromSave } from '../helpers/formatPaymentsFromSave';

export interface LawsuitResult{
  typeOfInterest:InterestTypes,
  compensationOn: boolean,
  agreementInterestRate: number,
  principalAmountOpt: PrincipalAmountOpt,
  principalAmount: number,
  interestData: InterestItem[],
}

type State = {
  saveLocal: LocalLawsuitSave
};

const useGetResultLsLocal = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation<State>();
  useEffect(() => {
    const retrieveDataAndSetSumUp = async () => {
      const save = location.state.saveLocal;

      const { lawsuitOpts } = save;

      const interestData = formatInterestDataFromSave(save.interestData);

      const paymentData = formatPaymentsFromSave(save.paymentData);

      const isCalculationDataCorrect = checkSaveData({
        ...lawsuitOpts,
        interestData,
      }, true);
      try {
        isCalculationDataCorrect ?
          await setSumUp({
            paymentData,
            interestData,
            dispatch,
            lawsuitOpts,
          }) : handleDataRetrievingProblem(history, dispatch);
      } catch (error){
        history.goBack();
        // eslint-disable-next-line no-console
        console.log(error);
        notification.error({
          message: errMsgGeneral,
          description: error instanceof Error ? error.message : errDescGeneral,
        });
      } finally {
        dispatch(justOptsReset());
        dispatch(appLoadingStopped());
      }
    };
    dispatch(appLoadingStarted());
    fileHasData(location?.state?.saveLocal) ?
      retrieveDataAndSetSumUp() :
      handleDataRetrievingProblem(history, dispatch);

  }, [dispatch, history]);

};

export default useGetResultLsLocal;

const handleDataRetrievingProblem = (history: {
  goBack:() => void,
}, dispatch:AppDispatch) => {
  history.goBack();
  dispatch(appLoadingStopped());
};

const fileHasData = (saveLocal:LocalLawsuitSave) =>
  _.isObject(saveLocal);