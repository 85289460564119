import { Button } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import LawBorders from '../components/Law/LawBorders';
import LawParLinks from '../components/Law/LawParLinks';
import LawPars from '../components/Law/LawPars';
import LawUsts from '../components/Law/LawUsts';
import TermsPar from '../components/Law/TermsPar';
import AnimOpacity from '../components/UI/Anim/AnimOpacity';
import LawContainer from '../components/UI/Containers/LawContainer';
import { ScreenBackButton } from '../components/UI/DescScreenFooter';
import DotsEnding from '../components/UI/DotsEnding';
import { par1, par2, par3, par4, par5, par6, par7, par8 } from '../utils/law/terms';
import { RowBg } from '../styles';
import { DashedDivider } from './About';

const Terms = () => {
  return (
    <AnimOpacity>
      <LawContainer title="Regulamin pozew.app" />
      <LawBorders />
      <TermsPar
        title={par1.title}
      >
        <LawPars
          texts={par1.pars}
        />
      </TermsPar>
      <TermsPar
        title={par2.title}
      >
        <LawPars
          texts={par2.pars1}
        />
        <LawUsts
          texts={par2.usts}
        />
        <LawPars
          texts={par2.pars2}
        />
      </TermsPar>
      <TermsPar
        title={par3.title}
      >
        <LawPars
          texts={par3.pars1}
        />
        <LawUsts
          texts={par3.usts1}
        />
        <LawPars
          texts={par3.pars2}
        />
        <LawUsts
          texts={par3.usts2}
        />
        <LawPars
          texts={par3.pars3}
        />
        <LawPars
          texts={par3.pars4}
        />
        <LawPars
          texts={par3.pars5}
        />
        <LawPars
          texts={par3.pars6}
        />
        <LawPars
          texts={par3.pars7}
        />
        <LawParLinks>
          <Button type="link" href="https://policies.google.com/">
            Google
          </Button>
          <Button type="link" href="https://www.facebook.com/terms">
            Facebook
          </Button>
        </LawParLinks>
      </TermsPar>
      <TermsPar
        title={par4.title}
      >
        <LawPars
          texts={par4.pars}
        />
      </TermsPar>
      <TermsPar
        title={par5.title}
      >
        <LawPars
          texts={par5.pars}
        />
      </TermsPar>
      <TermsPar
        title={par6.title}
      >
        <LawPars
          texts={par6.pars}
        />
        <LawParLinks>
          <Link to="/privacy">
            <Button type="link" href="https://policies.google.com/">
              Polityka prywatności
            </Button>
          </Link>
        </LawParLinks>
      </TermsPar>
      <TermsPar
        title={par7.title}
      >
        <LawPars
          texts={par7.pars}
        />
      </TermsPar>
      <TermsPar
        title={par8.title}
      >
        <LawPars
          texts={par8.pars}
        />
      </TermsPar>
      <DashedDivider />
      <LawContainer>
        <RowBg align="middle" justify="center">
          <ScreenBackButton />
        </RowBg>
        <DotsEnding />
      </LawContainer>
    </AnimOpacity>
  );
};

export default Terms;
