import { PaymentConfirmation } from '../../docTypes';
import { extractAllDocDates, extractAllSums } from '../extractCommon';
import { extractCreateDate } from '../extractCreateDate';
import { getPaymentDate } from './payConPaymentDate';
import { getPaymentTitle } from './payConPaymentTitle';

export const payConExtraction = (doc: string):PaymentConfirmation => {

  const paymentTitle = getPaymentTitle(doc);

  const paymentDate = getPaymentDate(doc);

  const allDocDates = extractAllDocDates(doc);

  const createDate = extractCreateDate(doc, allDocDates, true);

  const allSums = extractAllSums(doc);

  const dateFallback = () =>
    paymentDate || allDocDates[0];

  return {
    paymentTitle,
    allDocDates,
    allSums,
    createDate,
    paymentDate: paymentDate ?? dateFallback(),
    paymentSum: allSums[0] ?? null,
  };
};
