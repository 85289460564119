import { Progress } from 'antd';
import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';

import { useTypedSelector } from '../store';
import { BLUE } from '../styles';
import { RED } from '../styles/colors';


export const LoadingSpinner = () =>
  (
    <ContainerWhite>
      <Container>
        <div className="lds-ellipsis-big">
          <div />
          <div />
          <div />
          <div />
        </div>
      </Container>
    </ContainerWhite>
  );

const Loading = () => {
  const loading = useTypedSelector(state =>
    state.appLoading.loading);
  const loadingText = useTypedSelector(state =>
    state.appLoading.loadingText);
  const progress = useTypedSelector(state =>
    state.appLoading.progress);
  if (loading){
    return  (
      <ContainerWhite>
        {_.isNull(progress) ? (
          <Container>
            <div className="lds-ellipsis-big">
              <div />
              <div />
              <div />
              <div />
            </div>
          </Container>
        ) : (
          <ContainerProgress>
            <Progress
              strokeLinecap="square"
              percent={progress}
              strokeColor={{
                '0%': RED,
                '100%': BLUE,
              }}
              showInfo={false}
              style={{ width:200 }}
            />
          </ContainerProgress>
        )}
        <ContainerBottom>
          <LoadingText>
            {loadingText}
          </LoadingText>
        </ContainerBottom>
      </ContainerWhite>
    );
  }

  return null;
};

export default Loading;


const Container = styled.div`
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    z-index: 11;
`;

const ContainerProgress = styled.div`
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    z-index: 11;
`;

const ContainerBottom = styled.div`
    background-color: white;
    position: absolute;
    margin-top: 80px;
    margin-left: 2px;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 11;
`;

const ContainerWhite = styled.div`
    background-color: white;
    position: absolute;    
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
`;
const LoadingText = styled.div`
    width: 100%;
    font-family: 'Fira Mono';
    font: 14px;
    font-weight: 500;
    text-align: center;
`;