/* eslint-disable max-len */
import { Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import useWindowSize from '../../../utils/hooks/useWindowSize';
import { GRID_SM } from '../../../styles';
import { BLACK, BLUE } from '../../../styles/colors';
import Icon from '../../../styles/Icon';
import { DIAMOND } from '../../../styles/iconTypes';
import { TitleWrapper } from './SubExcel';

const { Paragraph } = Typography;

const SubRoadMap = () => {
  const { width } = useWindowSize();
  return (
    <>
      <TitleWrapper
        color={BLUE}
        align="middle"
        marginTop={24}
        marginBottom={12}
      >
        <Icon
          iconType={DIAMOND}
          height="20"
          width="20"
          fill={BLACK}
          style={{ marginRight:12 }}
        />
        {' '}
        {width > GRID_SM ? (
          <>
            <strong>
              PRZYSZŁE FUNKCJE  I DALSZY ROZWÓJ
            </strong>
          </>
        ) : (
          <>
            <strong>
              PRZYSZŁE FUNKCJE
              <br />
              {' '}
              DLA SUBSKRYBENTÓW
            </strong>
          </>
        )}
      </TitleWrapper>
      <Paragraph>
        Opłacając subskrypcję wspierasz
        <strong> trwanie i rozwój </strong>
        {' '}
        unikalnego projektu. Pozew.app została wydana w czerwcu 2021 i mamy mnóstwo pomysłów na jej rozbudowę! Sugestie subskrybentów pozew.app
        będą miały szczególne znaczenie dla jej rozwoju.
      </Paragraph>
      <Paragraph>
        <Link to="/roadMap"><a href='/roadmap'> Zobacz MAPĘ DROGOWĄ - co zrobiliśmy, a co planujemy </a></Link>
      </Paragraph>
    </>
  );
};

export default SubRoadMap;
