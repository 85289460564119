import useInterestBasis from './useInterestBasis';
import useInterestIntro from './useInterestIntro';
import useInterestPartials from './useInterestPartials';
import useSumInterests from './useSumInterests';

const useJustInterests = () => {
  const interestIntro = useInterestIntro();
  const interestPartials = useInterestPartials();
  const interestSum = useSumInterests();
  const interestBasis = useInterestBasis();
  return [
    /* ---------------------------- 1. interest intro --------------------------- */
    interestIntro,
    /* --------------------------- 2. interest partials -------------------------- */
    interestPartials,
    /* ---------------------------- 3. interests sum ---------------------------- */
    interestSum,
    /* ----------------------------- interests basis ---------------------------- */
    interestBasis,
  ];
};

export default useJustInterests;