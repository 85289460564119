import { Descriptions, Divider } from 'antd';
import _ from 'lodash';
import React from 'react';
import nextId from 'react-id-generator';
import { PaymentData } from '../../../store/interestStorage';
import { DescFullWidth, RowExt } from '../../../styles';
import { formatDate } from '../../../utils/helpers/date';
import { formatPLN } from '../../../utils/helpers/formatNumber';
import { shortenString } from '../../Lawsuit/StepCards/InterestForm/InterestFormTable';


const { Item: DescItem } = Descriptions;

const DetailsPayments = ({
  assignedPayments,
  assignedPaymentsSum,
  claimKey,
  interestsSumFulfilledCovered,
  interestsSumCovered,
}:{
  assignedPayments: PaymentData,
  assignedPaymentsSum: number,
  interestsSumCovered: number,
  claimKey: string,
  interestsSumFulfilledCovered: number,
}) => {

  if (_.isEmpty(assignedPayments)) return null;

  return (
    <>
      <Divider>Zaliczone wpłaty - podsumowanie</Divider>
      <DescFullWidth layout="horizontal" bordered key="d">
        <DescItem
          label="Łącznie wpłaty"
          span={3}
          labelStyle={{ fontWeight: 700 }}
        >
          <strong>
            {`${formatPLN(assignedPaymentsSum)}`}
          </strong>
        </DescItem>
        <DescItem label="Łącznie wpłaty zaliczone na poczet roszczenia głównego" span={3}>
          <strong>
            {`${formatPLN(assignedPaymentsSum - (interestsSumFulfilledCovered + interestsSumCovered))}`}
          </strong>
        </DescItem>
        <DescItem label="Łącznie wpłaty zaliczone na poczet odsetek" span={3}>
          <strong>
            {`${formatPLN(interestsSumFulfilledCovered + interestsSumCovered)}`}
          </strong>
        </DescItem>
      </DescFullWidth>
      <Divider />
      {assignedPayments.map(value =>
        (
          <RowExt marginTop="md" fullWidth key={nextId()}>
            <DescFullWidth layout="horizontal" bordered key="d">
              <DescItem label="Dzień wpłaty" span={3}>{formatDate(value.paymentDate)}</DescItem>
              {_.isNull(value.statementDate) ? null :
              <DescItem label="Tytuł wpłaty" span={3}>{formatDate(value.statementDate)}</DescItem>}
              <DescItem label="Wysokość wpłaty" span={3}>
                {`${formatPLN(value.paymentSum)}`}
              </DescItem>
              <DescItem
                label={(
                  <>
                    Cześć wpłaty
                    {' '}
                    <strong>zaliczona to na roszczenie</strong>
                  </>
)}
                span={3}
              >
                <strong>
                  {formatPLN(value.assignedTo.find(_val =>
                    _val.key === claimKey)?.sum ?? 0)}
                </strong>
              </DescItem>
              <DescItem label="Wpłata zaliczana w pierwszej kolejności na odsetki" span={3}>
                {(value.assignedTo.find(_val =>
                  _val.key === claimKey)?.isInterestFirst) ?
                  'tak' : 'nie'}
              </DescItem>
              {_.isNull(value.transferDescription) ? null :
              <DescItem label="Tytuł wpłaty" span={3}>{shortenString(value.transferDescription)}</DescItem>}
            </DescFullWidth>
          </RowExt>
        )) }
    </>
  );
};

export default DetailsPayments;