/* eslint-disable no-unused-vars */
import { Layout, Row } from 'antd';
import React from 'react';
import styled, { css } from 'styled-components';

export const RowBg = styled(Row)`
	margin-top: 48px;
`;

export const RowMd = styled(Row)`
	margin-top: 24px;
`;

export const RowSm = styled(Row)`
	margin-top: 12px;
`;

// XS is lower than 576
export const GRID_SM = 576;
export const GRID_SM_PX = '576px';
export const GRID_MD = 768;
export const GRID_MD_PX = '768px';
export const GRID_LG = 992;
export const GRID_LG_PX = '992px';

type RowExtSizes = 'sm' | 'md' | 'bgr' | 'bg' | number;

const getSize = (size?:RowExtSizes) => {
  if (size) {
    switch (size) {
      case 'sm':
        return '12px';
      case 'md':
        return '24px';
      case 'bgr':
        return '32px';
      case 'bg':
        return '48px';
      default:
        return `${size}px`;
    }
  }
  return null;
};


export const RowExt = styled(({
  fullWidth,
  marginTop,
  marginBottom,
  paddingTop,
  paddingBottom,
  bgColor,
  ...props
}) =>
  <Row {...props} />)<{
  fullWidth?: boolean,
  marginTop?: RowExtSizes,
  marginBottom?: RowExtSizes,
  paddingTop?: RowExtSizes,
  paddingBottom?: RowExtSizes,
  bgColor?: string
}>`
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: ${getSize(marginTop)};
    `}
  ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: ${getSize(marginBottom)};
    `}
  ${({ paddingTop }) =>
    paddingTop &&
    css`
      padding-top: ${getSize(paddingTop)};
    `}
  ${({ paddingBottom }) =>
    paddingBottom &&
    css`
      padding-bottom: ${getSize(paddingBottom)};
    `}
  ${({ bgColor }) =>
    bgColor &&
    css`
      background-color: ${bgColor};
    `}
  width:100%;
`;

export const ScreenLayout = styled(Layout)`
 background: white;
 align-items: center;
`;
