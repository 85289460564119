import moment from 'moment';

// 40 amount
export const COMPENSATION_VALUE_1 = 4000;
// 70 amount
export const COMPENSATION_VALUE_2 = 7000;
// 100 amount
export const COMPENSATION_VALUE_3 = 10000;

// 5k Int
export const COMPENSATION_POINT_1 = 500000;
// 50k Int
export const COMPENSATION_POINT_2 = 5000000;


// before this date only COMPENSATION_VALUE_1 (40 euro)
export const COMPENSATION_BREAK_DATE = moment('2019-12-31');

// https://www.nbp.pl/home.aspx?f=/dzienne/stopy_archiwum.htm