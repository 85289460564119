import { ArrowLeftOutlined, ArrowRightOutlined, CalendarOutlined } from '@ant-design/icons';
import { Button, Calendar } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { useTypedSelector } from '../../../store';
import { impExternalEndDateUpdated, impExternalReset, impExternalStepUpdated } from '../../../store/impExternal';
import { RowExt } from '../../../styles';
import { disabledDateImport } from '../../../utils/helpers/date';
import ImpCard from '../ImpCard';

const ImportExternalEndDate = () => {
  const history = useHistory();

  const endDate = useTypedSelector(state =>
    state.impExternal.endDate);

  const dispatch = useDispatch();

  const onNext = () => {
    dispatch(impExternalStepUpdated(1));
  };

  const onBack = () => {
    history.goBack();
    dispatch(impExternalReset());
  };
  return (
    <ImpCard
      title="Wspólna data końcowa (opcjonalnie)"
      desc=" Data, do której będą naliczane odsetki od wszyskich kwot. Domyślnie jest to dzień dzisiejszy. Dla każdej z kwot, możesz modyfikować datę końcową w czasie ustawiania parametrów wyliczeń."
      icon={<CalendarOutlined />}
    >
      <RowExt
        justify="space-between"
        align='middle'
        marginBottom={24}
      >
        <Button
          type="dashed"
          icon={<ArrowLeftOutlined />}
          onClick={onBack}
        />

        <Button
          type="primary"
          icon={<ArrowRightOutlined />}
          onClick={onNext}
        />
      </RowExt>
      <Calendar
        value={endDate ?? undefined}
        disabledDate={disabledDateImport}
        onChange={value =>
          dispatch(impExternalEndDateUpdated(value))}
      />
    </ImpCard>
  );
};

export default ImportExternalEndDate;