import React, { useState } from 'react';
import LoginHome from '../../Login/LoginHome';
import SignUpHome from '../../SignUp/SignUpHome';
import MenuAuthOpts from './MenuAuthOpts';
import MenuContainer from './MenuContainer';
import MenuFooter from './MenuFooter';
import MenuLogo from './MenuLogo';
import MenuStart from './MenuStart';
import MenuTools from './MenuTools';


const MainMenu = () => {
  const [signUpVisible, setSignUpVisible] = useState(false);
  const [isLoginVisible, setLoginVisible] = useState(false);
  const goToLogin = () => {
    setSignUpVisible(false);
    setLoginVisible(true);
  };

  const goToSignUp = () => {
    setSignUpVisible(true);
    setLoginVisible(false);
  };

  const goBack = () => {
    setSignUpVisible(false);
    setLoginVisible(false);
  };

  const Menu = () => {
    if (isLoginVisible){
      return (
        <LoginHome
          goToSignUp={goToSignUp}
          onBack={goBack}
        />
      );
    }
    if (signUpVisible){
      return (
        <SignUpHome
          goToLogin={goToLogin}
          onBack={goBack}
        />
      );
    }
    return (
      <>
        <MenuLogo />
        <MenuStart />
        <MenuTools />
        <MenuAuthOpts
          {...{
            setSignUpVisible,
            setLoginVisible
          }}
        />
        <MenuFooter />
      </>
    );
  };


  return (
    <MenuContainer>
      <Menu />
    </MenuContainer>
  );
};

export default MainMenu;