import { ArrowLeftOutlined, FileOutlined, PlusOutlined } from '@ant-design/icons';
import { Col, Typography } from 'antd';
import React from 'react';
import { useHistory, useLocation } from 'react-router';

import { LocationState } from '../../../@types';
import excelThumb from '../../../assets/excelThumb.png';
import { readExcel } from '../../../services/importEx';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { importExcelReset } from '../../../store/importExcel';
import { ButtonFullWidth, RowExt } from '../../../styles';
import { YT_EXCEL } from '../../../utils/helpers/links';
import SubInfo from '../../UI/SubInfo';
import ImpCard from '../ImpCard';
import ImpExcelFileInfo from './ImpExcelFile/ImpExcelFileInfo';

const { Paragraph } = Typography;


const ImpExcelFile = () => {
  const history = useHistory();
  const isSubscriber = useTypedSelector(state =>
    state.user.isSubscriber);

  const dispatch = useAppDispatch();

  const location = useLocation<LocationState>();

  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (hiddenFileInput.current && hiddenFileInput) {
      hiddenFileInput.current.click();
    }
  };

  const onBack = () => {
    if (location.pathname === '/importExcel'){
      history.goBack();
    }
    dispatch(importExcelReset());
  };

  return (
    <ImpCard
      title="Zmień plik excel w tekst pozwu/wezwania"
      desc={<Desc />}
      icon={<FileOutlined />}
    >
      <SubInfo
        thumbSrc={excelThumb}
        url={YT_EXCEL}
      />
      {isSubscriber ? (
        <>
          <RowExt>
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={(event) =>
                readExcel(event, dispatch)}
              style={{ display: 'none' }}
              accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            />
            <ButtonFullWidth
              type="primary"
              onClick={handleClick}
              disabled={!isSubscriber}
              icon={<PlusOutlined />}
            >
              Wczytaj plik excel (xlsx)
            </ButtonFullWidth>
          </RowExt>
          <ImpExcelFileInfo />
        </>
      ) : null}
      <RowExt marginTop={32} justify="space-between" align="middle" marginBottom="bg" gutter={[{ sm:16 }, { xs:24 }]}>
        <Col sm={8} xs={24}>
          <ButtonFullWidth
            icon={<ArrowLeftOutlined />}
            onClick={onBack}
          >
            Wróć
          </ButtonFullWidth>
        </Col>
        <Col sm={16} xs={0} />
      </RowExt>
    </ImpCard>
  );
};

export default ImpExcelFile;

const Desc = () =>
  (
    <Paragraph>
      Importuj dane z księgowości bezpośrednio do formularza. Oszczędź jeszcze więcej czasu, dodatkowo
      {' '}
      <strong>zmniejsz ryzyko pomyłki</strong>
      . Stwórz
      {' '}
      <strong>listę dowodów</strong>
      {' '}
      na bazie danych księgowych.
    </Paragraph>
  );
