import { Col } from 'antd';
import React from 'react';
import styled from 'styled-components';

import { Dot, GRID_SM_PX, RowExt } from '../../../styles';
import { BLUE, BLUE_MEDIUM, RED } from '../../../styles/colors';

const SloganDots = () => {
  return (

    <DotsContainer
      justify="space-around"
      fullWidth
    >
      <Col span={2} />
      <Col span={4}>
        <Dot
          color={RED}
        >
          .
        </Dot>

      </Col>
      <Col span={4}>
        <Dot
          color={BLUE_MEDIUM}
        >
          .
        </Dot>

      </Col>
      <Col span={4}>
        <Dot
          color={BLUE}
        >
          .
        </Dot>
      </Col>
      <Col span={10} />
    </DotsContainer>
  );
};

export default SloganDots;

const DotsContainer = styled(RowExt)`
   margin-bottom: 24px;
   margin-top: -24px;
   @media only screen and (max-width: ${GRID_SM_PX}) {
    margin-bottom: 12px;
    margin-top: -32px;
  }
`;
