import { ArrowLeftOutlined, CheckOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { importExcelReset, importExcelStepUpdated } from '../../../store/importExcel';
import { interestAllDataChanged } from '../../../store/interestStorage';
import { RowExt } from '../../../styles';
import { resetLawsuit } from '../../../utils/helpers/resetLawsuit';
import useImpExcelValidate from '../../../utils/hooks/useImpExcelValidate';
import ImpCard from '../ImpCard';
import ImpExcelStats from '../ImportOcr/ImpOrcSumUp/ImpExcelStats';
import ImpExcelList from './ImpExcelSumUp/ImpExcelList';


const ImpExcelSumUp = () => {
  const {
    interestData,
    rows,
  } = useImpExcelValidate();

  const history = useHistory();

  const dispatch = useDispatch();

  const onFinish = () => {
    if (interestData.length > 0){
      resetLawsuit(dispatch);
      dispatch(interestAllDataChanged(interestData));
      notification.info({
        duration: 6,
        message: 'Dane zaimportowane',
        description: 'Ustaw pozostałe parametry aby uzyskać tekst'
      });
      dispatch(importExcelReset());
      history.push('/lawsuit');
    }
  };
  const onBack = () => {
    dispatch(importExcelStepUpdated(2));
  };

  return (
    <ImpCard
      title="Podsumowanie importu"
      icon={<InfoCircleOutlined />}
      desc="Zobacz podsumowanie danych i ich ewentualnych modyfikacji. Jeśli chcesz coś zmienić możesz się jeszcze cofnąć."
    >
      <RowExt
        justify="space-between"
        align='middle'
        marginBottom={24}
      >
        <Button
          type="dashed"
          icon={<ArrowLeftOutlined />}
          onClick={onBack}
        />
        <Button
          type="primary"
          icon={<CheckOutlined />}
          onClick={onFinish}
          disabled={(interestData.length) <= 0}
        >
          Zacznij obliczenia i tworzenie tesktu
        </Button>
      </RowExt>
      <ImpExcelStats
        rows={rows}
      />
      <ImpExcelList
        rows={rows}
      />
      {/* <ImpSumUpOpts

      /> */}
    </ImpCard>
  );
};

export default ImpExcelSumUp;
