import { Button, Col, Space, Typography } from 'antd';
import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import logo from '../../../assets/logo.svg';
import { useTypedSelector } from '../../../store';
import { EmptyButton, GRID_SM_PX, LinkFullWidth, RowExt } from '../../../styles';
import useWindowSize from '../../../utils/hooks/useWindowSize';
import LogOutButton from '../../UI/Button/LogOutButton';


const MenuLogo = () => {
  const { width } = useWindowSize();
  const history = useHistory();
  const userExists = useTypedSelector(state =>
    state.user.isNonAnonymousUserLoggedIn);

  return (
    <RowExt
      fullWidth
      paddingTop="md"
    >
      <Col
        lg={{
          offset: 2,
          span:20
        }}
        md={{
          offset: 2,
          span:9,
        }}
        sm={{
          offset: 2,
          span:9,
        }}
        xs={{
          offset: 2,
          span:10,
        }}
      >
        <LinkFullWidth
          to={{ pathname: '/' }}
        >
          <ImageLogo
            src={logo}
          />
        </LinkFullWidth>
      </Col>
      <Col
        lg={{ span:0 }}
        md={{ span:11 }}
        sm={{ span:11 }}
        xs={{ span: 10 }}
      >
        <RowExt
          justify="end"
          marginTop={2}
        >
          <Space>
            {userExists ?
              <LogOutButton /> : (
                <>
                  {width > 425 ? (
                    <Button
                      type="link"
                      onClick={() =>
                        history.push('/signUp')}
                    >
                      {texts.button1}
                    </Button>
                  ) : null}
                  <EmptyButton
                    type="link"
                    onClick={() =>
                      history.push(width < 425 ? '/signUp' : '/login')}
                  >
                    {width < 425 ? texts.button1 : texts.button2}
                  </EmptyButton>
                </>
              )}
          </Space>
        </RowExt>
      </Col>
    </RowExt>
  );
};



export default MenuLogo;


export const ImageLogo = styled.img<{ grey?: boolean }>`
    width: 124px;
    margin-right: 12px;
`;


export const VersionText = styled(Typography.Text)<{ grey?: boolean }>`
    font-family: Fira mono, monospace; 
    margin-left: 12px;
    @media only screen and (max-width: ${GRID_SM_PX}) {
      margin-left: 8px;
    }
`;

const texts = {
  button1: 'Utwórz konto',
  button2: 'Zaloguj się',
  button3: 'Wyloguj się'
};