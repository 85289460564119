import { ArrowLeftOutlined, MailOutlined } from '@ant-design/icons';
import { FirebaseError } from '@firebase/util';
import { Button, Col, Divider, Form, Input, Modal, notification, Row, Space, Typography } from 'antd';
import { sendPasswordResetEmail } from 'firebase/auth';
import React, { useState } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { LocationState } from '../../@types';
import { auth } from '../../services/firebase/auth';
import { handleLogInExternalProvider, loginEmail } from '../../services/user/login';
import { useTypedSelector } from '../../store';
import { loginEmailUpdated, loginPasswordUpdated, loginReset } from '../../store/logInReducer';
import { ButtonFullWidth, RowBg, RowExt, RowSm } from '../../styles';
import { errDescGeneral, errMsgGeneral, errMsgWithCode } from '../../utils/helpers/errorTexts';
import { emailRegex } from '../../utils/helpers/regexChecks';
import { handleReturnTo } from '../SignUp/SignUpForm';
import FormAlert from '../UI/Alerts/Alerts';
import AuthButton from '../UI/Button/AuthButton';


const LoginForm = (
  {
    goToSignUp,
    payment,
    onBack
  }: {
    goToSignUp: () => void,
    payment?: boolean,
    onBack?: () => void,
  }
) => {
  const dispatch = useDispatch();
  const [emailValidated, setEmailValidated] = useState<boolean | null>(null);
  const history = useHistory();
  const location = useLocation<LocationState>();
  const [form] = Form.useForm();

  const {
    email,
    password,
    error,
    loading,
    errorMessage,
    provider
  } = useTypedSelector(
    (state) =>
      state.login,
    shallowEqual
  );

  const validateEmail = (emailToValidate: string) => {
    if (emailToValidate.match(emailRegex)) {
      setEmailValidated(true);
    } else {
      setEmailValidated(false);
    }
  };

  const handleLogIn = () => {
    dispatch(loginEmail({
      history,
      returnTo: handleReturnTo(location.state, payment),
    }));
  };

  const onEmailChange = (text:string) => {
    setEmailValidated(null);
    dispatch(loginEmailUpdated(text));
  };


  const [isModalVisible, setIsModalVisible] = useState(false);
  const [emailRemember, setEmailRemember] = useState('');

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onRemember = async () => {
    try {
      await sendPasswordResetEmail(auth, emailRemember);
      notification.open({
        type:'info',
        message: `Email z instrukcją przypomnienia hasła został wysłany na adres:  ${emailRemember}`,
        duration:10,
      });
    } catch (e){
      if (e instanceof FirebaseError){
        if (e.code === 'auth/user-not-found'){
          notification.open({
            type:'error',
            message: `użytkownik z adresem ${emailRemember} nie istnieje`,
          });
        } else {
          notification.error({ message: errMsgWithCode(e.code) });
        }
      } else {
        notification.error({
          message: errMsgGeneral, description: errDescGeneral
        });
      }
    } finally {
      setEmailRemember('');
      handleCancel();
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleLogIn();
    }
  };

  return (
    <>
      <FormAlert
        message={errorMessage}
        isOn={error}
        type="error"
      />
      {error ?
        <RowBg /> : null}
      <Form
        layout="vertical"
        preserve={false}
        form={form}
      >
        <Form.Item
          label="Email"
          name="email"
          validateStatus={emailValidated === false ? 'error' : ''}
          help={emailValidated === false ? 'Wpisz prawidłowy adres, proszę' : ''}
          style={{ marginBottom:0 }}
          initialValue={email}
          preserve={false}
        >
          <Input
            value={email}
            onChange={(event) =>
              onEmailChange(event.target.value)}
            onBlur={(event) =>
              validateEmail(event.target.value.toLocaleLowerCase())}
          />
        </Form.Item>
        <Form.Item
          label="Hasło"
          name="password"
          preserve={false}
        >
          <Input.Password
            value={password}
            onChange={(event) =>
              dispatch(loginPasswordUpdated(event.target.value))}
            onKeyDown={handleKeyDown}
          />
        </Form.Item>
        <AuthButton
          type="email"
          text="Zaloguj się przez email"
          onClick={handleLogIn}
          loading={loading && provider === 'email'}
          disabled={loading && provider === 'email'}
        />
        <Divider>LUB</Divider>
        <AuthButton
          type="google"
          text={texts.button2}
          loading={loading && provider === 'google'}
          onClick={() =>
            dispatch(handleLogInExternalProvider({
              provider: 'google',
              history,
              returnTo: handleReturnTo(location?.state, payment),
            }))}
          disabled={loading && provider === 'google'}
        />
        <AuthButton
          type="facebook"
          text={texts.button3}
          loading={loading && provider === 'facebook'}
          onClick={() =>
            dispatch(handleLogInExternalProvider({
              provider: 'facebook',
              history,
              returnTo: handleReturnTo(location?.state, payment),
            }))}
          disabled={loading && provider === 'facebook'}
        />
      </Form>
      <RowSm
        justify="space-around"
        align="middle"
      >
        <Button
          type="link"
          onClick={() =>
            setIsModalVisible(true)}
        >
          Zapomniałam/em hasło
        </Button>
      </RowSm>
      {!payment ? (
        <RowExt
          justify="space-around"
          align="middle"
          marginTop="bg"
        >
          <Col span={12}>
            <ButtonFullWidth
              icon={<ArrowLeftOutlined />}
              onClick={onBack || (() =>
                history.goBack())}
            >
              Wróć
            </ButtonFullWidth>
          </Col>
          <Col span={12}>
            <ButtonFullWidth
              type="link"
              onClick={goToSignUp || (() => {
                dispatch(loginReset());
                history.push('/signUp');
              })}
            >
              {texts.newAccount}
            </ButtonFullWidth>
          </Col>
        </RowExt>
      ) : null}
      <Modal
        title="Odzyskiwania hasła"
        visible={isModalVisible}
        onOk={onRemember}
        onCancel={handleCancel}
        okText={(
          <Row align="middle">
            <Space>
              <MailOutlined />
              <Typography.Text style={{ color: 'white' }}>
                Wyślij email
              </Typography.Text>
            </Space>
          </Row>
        )}
      >
        <Typography.Text>Wpisz proszę adres przy pomocy którego się logowałaś/eś</Typography.Text>
        <RowSm />
        <Input
          value={emailRemember}
          onChange={(event) =>
            setEmailRemember(event.target.value)}
          placeholder="Adres email"
        />
        <RowSm />
        <Typography.Text type="secondary">Wyślemy na podany email instrukcje do odzyskiwania hasła.</Typography.Text>
      </Modal>
    </>
  );
};



export default LoginForm;


const texts = {
  newAccount: 'Utwórz nowe konto',
  button1: 'Zaloguj się przez email',
  button2: 'Zaloguj się przez Google',
  button3: 'Zaloguj się przez Facebook',
};