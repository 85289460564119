/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash';
import React, { memo } from 'react';
import { shallowEqual } from 'react-redux';

import { setStartingEnd } from '../../../services/setDates';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { editCurrencyIsVisible, editPartialSumUpdated } from '../../../store/edit';
import { toAmount, toUnit } from '../../../utils/helpers/money';
import StarEndPartialSum from '../../UI/Forms/StarEndPartialSum';


const EditPartialSum = () => {
  const {
    startingDate,
    endDate,
    partialSum,
    dueDate,
  } = useTypedSelector(state =>
    state.edit, shallowEqual);

  const dispatch = useAppDispatch();

  const {
    isSameEndDate,
    isStartingFromDue,
    maturityDateOpts,
  } = useTypedSelector(state =>
    state.interestFormOptions, shallowEqual);

  // ant date picker uses any
  const  onDateChange = (value: any) => {
    setStartingEnd({
      value,
      dispatch,
      isSameEndDate,
      isStartingFromDue,
      maturityDateOpts,
      isEdit: false,
    });
  };

  const onSumChange = (value: string | number | null | undefined) => {
    if (_.isNumber(value)){
      dispatch(editPartialSumUpdated(toAmount(value)));
    }
  };

  const onCurrencyClick = () => {
    dispatch(editCurrencyIsVisible(true));
  };

  return (
    <StarEndPartialSum
      {...{
        startingDate,
        endDate,
        partialSum: partialSum ?  toUnit(partialSum) : null,
        dueDate,
        onDateChange,
        onSumChange,
        onCurrencyClick
      }}
    />
  );
};

export default memo(EditPartialSum);