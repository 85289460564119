import { ArrowLeftOutlined, CheckOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import _ from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { useTypedSelector } from '../../../store';
import { impOcrRested, ocrStepUpdated } from '../../../store/impOcr';
import { interestDataChanged, paymentDataChanged } from '../../../store/interestStorage';
import { interestDataProviderUpdated, MANUAL_PAYMENTS_ASSIGNMENTS, shouldAutoAssignUpdated } from '../../../store/lawsuitUI';
import { RowExt } from '../../../styles';
import { resetLawsuit } from '../../../utils/helpers/resetLawsuit';
import useImpOrcValidate from '../../../utils/hooks/useImpOrcValidate';
import ImpCard from '../ImpCard';
import ImpStats from '../ImpCommonData/ImpSumUp/ImpStats';
import ImpStatsMoney from './ImpOrcSumUp/ImpStatsMoney';
import ImpSumUpOpts from '../ImpCommonData/ImpSumUp/ImpSumUpOpts';
import ImpOrcWarningList from './ImpOrcSumUp/ImpOrcWarningList';
import { IMP_OCR } from '../ImpCommonData/impCommonData';

const ImpOcrSumUp = () => {
  const {
    interestData,
    paymentData,
    warningData,
    sumOfInterestData,
    sumOfPaymentData,
  } = useImpOrcValidate();

  const history = useHistory();

  const ocrData  = useTypedSelector(state =>
    state.impOcr.ocrData);

  const shouldAutoAssign = useTypedSelector(state =>
    state.impOcr.shouldAutoAssign);

  const dispatch = useDispatch();

  const onFinish = () => {
    if ((interestData.length + paymentData.length) > 0){
      resetLawsuit(dispatch);
      dispatch(interestDataChanged(interestData));
      dispatch(paymentDataChanged(paymentData));
      if (!_.isEmpty(paymentData)) dispatch(interestDataProviderUpdated(MANUAL_PAYMENTS_ASSIGNMENTS));
      if (!_.isEmpty(paymentData) && shouldAutoAssign) dispatch(shouldAutoAssignUpdated(true));
      notification.info({
        duration: 6,
        message: 'Dane zaimportowane',
        description: 'Ustaw pozostałe parametry aby uzyskać tekst'
      });
      dispatch(impOcrRested());
      history.push('/lawsuit');
    }
  };
  const onBack = () => {
    dispatch(ocrStepUpdated(3));
  };

  return (
    <ImpCard
      title="Podsumowanie importu"
      icon={<InfoCircleOutlined />}
      desc=" Zobacz podsumowanie danych i ich ewentualnych modyfikacji. Jeśli chcesz coś zmienić możesz się jeszcze cofnąć."
    >
      <RowExt
        justify="space-between"
        align='middle'
        marginBottom={24}
      >
        <Button
          type="dashed"
          icon={<ArrowLeftOutlined />}
          onClick={onBack}
        />
        <Button
          type="primary"
          icon={<CheckOutlined />}
          onClick={onFinish}
          disabled={(interestData.length + paymentData.length) <= 0}
        >
          Zacznij obliczenia i tworzenie tesktu
        </Button>
      </RowExt>
      <ImpStats
        warningData={warningData}
        numberOfImports={ocrData.length}
      />
      <ImpOrcWarningList
        warningData={warningData}
      />
      <ImpStatsMoney
        {...{
          sumOfInterestData,
          sumOfPaymentData,
          numberOfImports: ocrData.length,
        }}
      />
      <ImpSumUpOpts
        impType={IMP_OCR}
      />
    </ImpCard>
  );
};

export default ImpOcrSumUp;
