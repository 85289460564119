import { ArrowLeftOutlined, HomeOutlined } from '@ant-design/icons';
import { Button, Row } from 'antd';
import React from 'react';
import { LinkFullWidth } from '../../../styles/ui';


const BackButton = ({
  onBack,
  showHome,
  fullWidth = false,
}:{
  onBack: () =>  void;
  showHome: boolean;
  fullWidth?: boolean | undefined;
}) => {

  if (showHome){
    return (
      <LinkFullWidth
        to={{ pathname:  '/' }}
      >
        <Button
          icon={<HomeOutlined />}
        >
          Wróć
        </Button>
      </LinkFullWidth>
    );
  }
  return (
    <Button
      type="default"
      onClick={onBack}
      style={fullWidth ? { width: '100%' } : {}}
    >
      <Row align="middle" justify="center">
        <ArrowLeftOutlined style={{ marginRight: 6 }} />
        {texts.back}
      </Row>
    </Button>
  );
};

export default BackButton;


const texts = { back: 'Cofnij' };

