import { Divider, Typography } from 'antd';
import React from 'react';
import { TitleAndDesc } from '../../@types';

const { Title, Text } = Typography;

const SumUpTitle = (
  {
    title,
    desc
  }: TitleAndDesc
) =>
  (
    <>
      <Title level={2}>{title}</Title>
      <Text>{desc}</Text>
      <Divider />
    </>
  );


SumUpTitle.propTypes = {};

export default SumUpTitle;
