/* eslint-disable max-len */
import { Col, Row, Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';
import LawBorders from '../Law/LawBorders';
import LawContainer from '../UI/Containers/LawContainer';
import { DashedColLeft, GREY_WHITE } from '../../styles';

const RoadMapIntro = () => {
  return (
    <LawContainer>
      <LawBorders />
      <Row>
        <DashedColLeft span={1} />
        <IntroContainer span={21}>
          <IntroParagraph>
            Pozew.app została wydana w czerwcu 2021. Mamy mnóstwo pomysłów na jej rozwój. Zobacz, co udało nam się wykonać, a co planujemy. Część nowych funkcji będzie przeznaczona tylko dla osób finansowo wspierających projekt (subskrybentów) - oznaczamy je symbolem S.
          </IntroParagraph>
        </IntroContainer>
      </Row>
    </LawContainer>
  );
};

export default RoadMapIntro;

const IntroContainer = styled(Col)`
   background: ${GREY_WHITE};
   padding: 24px;
`;
const IntroParagraph = styled(Typography.Paragraph)`
   font-family: Fira Mono, monospace;
`;