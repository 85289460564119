/* eslint-disable @typescript-eslint/no-explicit-any */
import { notification } from 'antd';
import { User } from 'firebase/auth';
import { AppDispatch } from '../../store';
import { StartLoadingText, appLoadingStarted, appLoadingStopped } from '../../store/appLoading';
import { errMsgGeneral } from './errorTexts';

export const withAppLoading = (
  { dispatch, startLoadingText }:{ dispatch: AppDispatch, startLoadingText?: StartLoadingText },
  func: () => Promise<any> ) => {

  dispatch(appLoadingStarted(startLoadingText));

  func().finally(() =>
    dispatch(appLoadingStopped()));
};

export const withAppLoadingAndUser = (
  {
    dispatch,
    startLoadingText,
    user,
    userCanBeAnonymous = false,
  }:{
    dispatch: AppDispatch,
    startLoadingText?: StartLoadingText,
    user: User | null
    userCanBeAnonymous?: boolean,
  },
  func: (user:User) => Promise<any> ) => {
  if (user && (!user.isAnonymous || userCanBeAnonymous)){
    dispatch(appLoadingStarted(startLoadingText));

    func(user).finally(() =>
      dispatch(appLoadingStopped()));

  } else {
    notification.error({ message: userCanBeAnonymous ? errMsgGeneral : 'Nie jesteś zalogowana/y - odmowa dostępu.' });
  }
};

export const withUser = (user: User | null, func: (user:User) => Promise<any> ) => {
  if (user && (!user.isAnonymous)){
    func(user);
  } else {
    notification.error({ message: 'Nie jesteś zalogowana/y - odmowa dostępu.' });
  }
};



