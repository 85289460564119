import { PaymentDataSaved, PaymentItemSaved } from '../../services/lawsuitSave';
import { PaymentData } from '../../store/interestStorage';
import { convertToMoment } from './date';

export const formatPaymentsFromSave = (paymentDataSaved: PaymentDataSaved | undefined) => {
  if (paymentDataSaved === undefined) return [];
  const paymentData:PaymentData = paymentDataSaved.map((
    paymentItemSaved:PaymentItemSaved
  ) => {
    const { statementDate, paymentDate } = paymentItemSaved;
    return {
      ...paymentItemSaved,
      paymentDate: convertToMoment(paymentDate, true),
      statementDate: statementDate ? convertToMoment(statementDate) : null,
    };
  });
  return paymentData;
};
