import React from 'react';

import AbtOneLineBot from '../components/About/AbtOneLineBot';
import DeveloperAuth from '../components/Developer/DeveloperAuth';
import DeveloperInbox from '../components/Developer/DeveloperInbox';
import DeveloperIntro from '../components/Developer/DeveloperIntro';
import GuidesHeader from '../components/Guides/GuidesHeader';
import AnimOpacity from '../components/UI/Anim/AnimOpacity';
import DescScreenFooter from '../components/UI/DescScreenFooter';
import { ScreenLayout } from '../styles';
import { DashedDivider } from './About';

const Developer = () => {
  return (
    <AnimOpacity>
      <ScreenLayout>
        <GuidesHeader />
        <DashedDivider />
        <AbtOneLineBot />
        <DeveloperIntro />
        <DeveloperAuth />
        <DeveloperInbox />
        <DescScreenFooter />
      </ScreenLayout>
    </AnimOpacity>
  );
};

export default Developer;