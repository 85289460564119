/* eslint-disable max-len */
import { Button, Col, Row, Space, Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { COMPENSATION_BASIS } from '../../../utils/helpers/lawBasis';
import { AboutContainer, BLUE, DashedColLeft, GREY_WHITE } from '../../../styles';
import { BLUE_MEDIUM, RED } from '../../../styles/colors';
import AbtTitle from '../AbtTitle';
import FaqParagraph from './FaqParagraph';


const FAQ = () => {
  return (
    <AboutContainer>
      <Row id="faq">
        <Col
          lg={{
            offset: 1,
            span: 5,
          }}
          md={{
            offset: 1,
            span: 7
          }}
          xs={{
            offset: 2,
            span: 20,
          }}
        >
          <AbtTitle
            title={(
              <>
                F
                <RedDot> . </RedDot>
                A
                <DarkBlueDot> . </DarkBlueDot>
                Q
                <BlueDot> . </BlueDot>
              </>
          )}
            desc={(
              <>
                Odpowiedzi
                <br />
                na najczęstsze pytania
                <br />
                (Frequently asked questions)
              </>
)}
          />

        </Col>
        <TextCol
          lg={{ span: 14 }}
          md={16}
          xs={{ span: 24 }}
        >
          <FaqParagraph
            title="Do kogo kierowany jest pozew.app?"
            desc="Aplikacja jest kierowana główne do radców prawnych, adwokatów i aplikantów. Pomimo tego, że większość procesów jest zautomatyzowana, właściwe rozumienie instytucji prawnych stojących za wyliczeniami jest konieczne (jak np. data wymagalności). Korzystanie z tego narzędzia wymaga - mimo wszystko - kompetencji."
            color={RED}
          />
          <FaqParagraph
            title="Wynik kapitalizacji odsetek różni się o jeden grosz od wyniku w innym kalkulatorze online?"
            desc={(
              <>
                <Typography.Paragraph>
                  Z naszych obserwacji wynika, że
                  {' '}
                  <strong>czasami</strong>
                  {' '}
                  kalkulatory kapitalizacji odsetek dostępne na rynku wyświetlają wadliwie dane:
                </Typography.Paragraph>
                <Typography.Paragraph>
                  Właściwie prezentują zaokrąglenie do jednego grosza przy wyliczeniu odsetek dla każdego okresu, w którym obowiązywała dana stawka. Następnie dodają poszczególne kwoty i wynik dodawania jest zaniżony o jeden grosz w stosunku od oczywistej do uzyskania sumy (wyliczonej w oparciu o dane podane przez same kalkulatory). Najłatwiej zobaczyć to na zdjęciach dostępnych tutaj:
                </Typography.Paragraph>
                <Space>
                  <Button
                    type="link"
                    href="https://firebasestorage.googleapis.com/v0/b/pozewapp.appspot.com/o/Screenshot%202021-06-07%20at%2018.33.35.png?alt=media&token=aa063e95-3876-4bad-88ce-ca29f5fcf20c"
                    style={{
                      paddingLeft:0, marginBottom: 14
                    }}
                    target="_blank"
                  >
                    Zjęcie 1
                  </Button>
                  <Button
                    type="link"
                    href="https://firebasestorage.googleapis.com/v0/b/pozewapp.appspot.com/o/Screenshot%202021-06-07%20at%2018.32.53.png?alt=media&token=69dda0da-1d4c-4824-bcef-aecb54d0fe00"
                    style={{
                      paddingLeft:0, marginBottom: 14
                    }}
                    target="_blank"
                  >
                    Zjęcie 2
                  </Button>
                </Space>
                <Typography.Paragraph>
                  Takie działanie kalkulatorów prawdopodobnie wynika z tego, że wbrew wyświetlanym informacjom zaokrąglenie następuje już po dodaniu wszystkich liczb uzyskanych w danych okresach. Czyli dodawane są liczby z więcej niż dwoma miejscami po przecinku.
                </Typography.Paragraph>
                <Typography.Paragraph>
                  W naszej ocenie to niewłaściwe założenie. Powinny być dodawane
                  <strong> wartości pieniężne, </strong>
                  to znaczy takie, które mają maksymalnie dwa miejsca po przecinku. Czyli w momencie obliczenia odsetek za dany okres powinna już powstać liczba z maksymalnie dwiema cyframi po przecinku. To już wtedy jest wartość pieniężna (nie dowolna liczba) i ta powinna ulec zaokrągleniu. To dzielenie powoduje konieczność zaokrąglenia a nie sumowanie. Tylko wtedy wynik dodawania w stosunku do prezentacji liczb sumowanych będzie prawidłowy.
                </Typography.Paragraph>
                <Typography.Paragraph>
                  Proszę zwrócić uwagę na to, że w każdym momencie obliczania odsetek liczymy wartości pieniężne a nie dowolne liczby matematyczne. Także w javascripcie (języku programowania, za pomocą którego zbudowany jest pozew.app) pieniądze nie powinny być traktowane w żadnym punkcie jako zwykła liczba. Więcej na ten temat tutaj:
                </Typography.Paragraph>
                <Button
                  type="link"
                  href="https://frontstuff.io/how-to-handle-monetary-values-in-javascript"
                  style={{ padding:0 }}
                  target="_blank"
                >
                  How to Handle Monetary Values in JavaScript
                </Button>
              </>
)}
            color={BLUE_MEDIUM}
          />
          <FaqParagraph
            title="Dlaczego można ustawiać datę początkową naliczania odsetek niezależnie od daty wymagalności?"
            desc="W standardowych przypadkach odsetki nalicza się od dnia następującego po dniu wymagalności - terminie zapłaty (na tym założeniu opiera się większość kalkulatorów). Jednak te daty nie są ze sobą nierozerwalnie związane. Zgłaszanie roszczenia o odsetki nie musi obejmować wszystkich dni od dnia wymagalności. Np. wierzyciel zrzeka się roszczenia o odsetki za pierwszy miesiąc (data wymagalności roszczenia będącego podstawą naliczania odsetek nie ulega wtedy zmianie)."
            color={BLUE}
          />
          <FaqParagraph
            title="Mam pewien specyficzny przypadek, którego pozew.app nie obejmuje. Co zrobić?"
            desc="Zgłoś proszę feedback do autora. Może uda nam się wprowadzić zmiany, które uzupełnią ten brak. Zaznaczamy jednak, że na ten moment aplikacja obejmuje typowe przypadki i nie przewidzieliśmy np. liczenia kilku typów odsetek łączenie. Nie chcemy wprowadzać każdego niszowego przypadku obliczeń do pozwu, gdyż naszym zadaniem osłabi to wtedy użyteczność narzędzia (nazbyt je skomplikuje). Dlatego wyliczamy wps od pozwu według zasad ogólnych. Zakładamy, że osoby korzystające z pozew.app są profesjonalistami i rozumieją jak ją stosować."
            color={BLUE_MEDIUM}
          />
          <FaqParagraph
            title="Dlaczego muszę ustawić datę wymaglności przy obliczaniu rekompensat ?"
            desc={`Bez tego nie jest możliwe wyliczenie wysokości rekompensat - por. ${COMPENSATION_BASIS}. Jeśli chcesz policzyć same odsetki bez wyznaczania dat wymagalności musisz zrezygnować z wyliczania rekompensat.`}
            color={BLUE}
          />
        </TextCol>
        <Col
          xl={{ span: 2 }}
          lg={{ span: 1 }}
          xs={0}
        />
        <DashedColLeft
          xl={{ span: 2 }}
          lg={{ span: 3 }}
          xs={0}
        />
      </Row>
    </AboutContainer>
  );
};

export default FAQ;

const TextCol = styled(Col)`
  background-color: ${GREY_WHITE};
  padding-top: 54px;
  padding-bottom: 30px;
  @media only screen and (max-width: 767px) {
    margin-top: 48px;
	}
`;

const BlueDot = styled.span`
   color:${BLUE};
`;
const RedDot = styled.span`
   color:${RED};
`;
const DarkBlueDot = styled.span`
   color:${BLUE_MEDIUM};
`;
