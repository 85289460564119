import { onSnapshot, Timestamp } from 'firebase/firestore';
import { AnimatePresence, motion } from 'framer-motion';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { auth } from '../../services/firebase/auth';
import { connectedAccountsQuery } from '../../services/firebase/firestoreRefs';
import { RowExt } from '../../styles';
import SubscriptionUser from './SubscriptionUser';


type ConnectedAccount = {
  createdDate: Timestamp;
  email: string;
};

type ConnectedAccountLocal = {
  id: string;
  createdDate: string;
  email: string;
};

const SubscriptionUsersList = () => {
  const [data, setData] = useState<ConnectedAccountLocal []>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const { currentUser } = auth;
    if (currentUser){
      const unsubscribe =  onSnapshot(connectedAccountsQuery(currentUser.uid), (res) => {
        if (!res.empty){
          const connectedAccounts  = res.docs;
          const connectedAccountsLocal = connectedAccounts.map((value) => {
            const connectedAccount = value.data() as ConnectedAccount;
            const { email, createdDate } = connectedAccount;
            return {
              id: value.id,
              email,
              createdDate:  moment(createdDate).format('YYYY-MM-DD HH:mm'),
            };
          });
          setData(connectedAccountsLocal);
          setLoading(false);
        } else {
          setData([]);
          setLoading(false);
        }
      });
      return () => {
        if (currentUser){
          unsubscribe();
        }
      };
    }
    return undefined;
  }, []);

  if (loading){
    return (
      <RowExt paddingTop={24} align="middle" justify="center">
        <div className="lds-ellipsis-big">
          <div />
          <div />
          <div />
          <div />
        </div>
      </RowExt>
    );
  }

  return (
    <>
      <SubscriptionUser
        email={auth.currentUser?.email ?? ''}
        isAdmin
        receiverId={auth.currentUser?.uid ?? ''}
      />
      {!_.isEmpty(data) ? (
        <AnimatePresence>
          <motion.div
            transition={{
              ease: 'easeInOut', duration: 1,
            }}
            initial={{ opacity: !_.isEmpty(data) ? 1 : 0 }}
            animate={{ opacity: !_.isEmpty(data) ? 1 : 0 }}
            exit={{ opacity: 0 }}
          >
            {data.map((value:{
              id: string,
              email: string,
            }) =>
              (
                <SubscriptionUser
                  key={value.id}
                  email={value.email}
                  receiverId={value.id}
                />
              )
            )}
          </motion.div>
        </AnimatePresence>
      ) : null}
    </>
  );
};

export default SubscriptionUsersList;
