import { DeleteOutlined, EditOutlined, EyeOutlined, ShareAltOutlined } from '@ant-design/icons';
import { Divider, Empty, Popconfirm, Table, Typography } from 'antd';
import firebase from 'firebase/compat/app';
import { DocumentData, onSnapshot, QuerySnapshot } from 'firebase/firestore';
import { AnimatePresence, motion } from 'framer-motion';
import _ from 'lodash';
import moment from 'moment';
import React, { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { auth } from '../../services/firebase/auth';
import { calculationsQuery } from '../../services/firebase/firestoreRefs';
import { LawsuitSave, saveDelete } from '../../services/lawsuitSave';
import { useAppDispatch, useTypedSelector } from '../../store';
import { InterestItem } from '../../store/interestStorage';
import { lsSaveReset } from '../../store/lawsuitUI';
import { RowExt } from '../../styles';
import { toUnit } from '../../utils/helpers/money';
import useListenToCalculationsCount from '../../utils/hooks/useListenToCalculationsCount';
import EditRecordName from '../UI/EditRecordName';
import ShareSaveModal from '../UI/ShareSaveModal';

export interface SaveTableRecord{
  recordName: string,
  editDate: firebase.firestore.Timestamp,
  sum: string,
  id: string,
}

export const formatSaveTableData = (snapshot: QuerySnapshot<DocumentData>): SaveTableRecord[] =>
  snapshot.docs.map(calculationSave => {
    const lawsuitSave = calculationSave.data() as LawsuitSave;
    const sum:number = calculationSave.data().interestData.reduce((acc:number, element:InterestItem) =>
      acc + element.partialSum, 0);
    return {
      key: calculationSave.id,
      recordName: lawsuitSave.recordName,
      editDate: lawsuitSave.editDate,
      id: calculationSave.id,
      sum: lawsuitSave.interestData.length === 0  ? 'Brak danych' : `${toUnit(sum).toFixed(2)} zł`
    };
  });

const ResultLsTable = () => {
  const [data, setData] = useState<SaveTableRecord[] | null>(null);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [saveId, setSaveId] = useState('');
  const [isShareVisible, setIsShareVisible] = useState(false);
  const [sharedId, setSharedId] = useState('');
  const [recordName, setRecordName] = useState('');
  const [limit, setLimit] = useState(10);
  const [direction, setDirection] = useState<'desc' | 'asc'>('desc');

  const userExists = useTypedSelector(state =>
    state.user.isNonAnonymousUserLoggedIn);

  const calcCount = useListenToCalculationsCount();

  useEffect(() => {
    const { currentUser } = auth;
    if (userExists && currentUser) {
      const resetComponent = () => {
        setData([]);
        setLoading(false);
        dispatch(lsSaveReset());
      };
      const unsubscribe = onSnapshot(calculationsQuery(currentUser.uid, direction, limit), (snapshot:QuerySnapshot<DocumentData>) => {
        if (!snapshot.empty){
          const saveTable = formatSaveTableData(snapshot);
          setData(saveTable);
          setLoading(false);
        } else {
          resetComponent();
        }
      }, err => {
        // eslint-disable-next-line no-console
        console.log(err);
        resetComponent();
      });
      return () => {
        unsubscribe();
        resetComponent();
      };
    }
    return undefined;
  }, [direction, dispatch, limit, userExists]);

  const history = useHistory();

  const onEdit = (id:string) => {
    history.push(`/resultLs/${id}`);
  };

  const onShare = (id:string) => {
    if (id){
      setSharedId(id);
      setIsShareVisible(true);
    }
  };

  const columns = [
    {
      title: 'Data ostatniej edycji',
      dataIndex: 'editDate',
      key: 'editDate',

      render:(value: firebase.firestore.Timestamp) => {
        return (
          <Typography.Text>
            {moment(value.toDate()).format('YYYY-MM-DD HH:mm')}
          </Typography.Text>
        );
      },
      sorter:(recordA: { editDate: firebase.firestore.Timestamp },
        recordB:{ editDate: firebase.firestore.Timestamp }) =>
        (
          recordA.editDate.toDate().getTime() - recordB.editDate.toDate().getTime()
        ),
      sortDirections: ['descend', 'ascend', 'descend'],
      defaultSortOrder: 'descend',
      onHeaderCell: () => {
        return {
          onClick: () => {
            setDirection(direction === 'desc' ? 'asc' : 'desc');
          }
        };
      }
    },
    {
      title: 'Nazwa',
      dataIndex: 'recordName',
      key: 'recordName',
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (record:SaveTableRecord) => {
        return (
          <EditOutlined
            onClick={() => {
              setIsModalVisible(true);
              setSaveId(record.id);
              setRecordName(record.recordName);
            }}
          />
        );
      },
    },
    {
      title: '',
      dataIndex: '',
      key: 'check',
      render: (record:SaveTableRecord) => {
        return (
          <EyeOutlined
            onClick={() =>
              onEdit(record.id)}
          />
        );
      },
    },
    {
      title: '',
      dataIndex: '',
      key: 'check',
      render: (record:SaveTableRecord) => {
        return (
          <ShareAltOutlined
            onClick={() =>
              onShare(record.id)}
          />
        );
      },
    },
    {
      title: '',
      dataIndex: '',
      key: 'usuń',
      render: (record:SaveTableRecord) => {
        return (
          <Popconfirm
            title="Usunąć, jesteś pewna/pewny?"
            onConfirm={() =>
              saveDelete(record.id)}
          >
            <DeleteOutlined />
          </Popconfirm>
        );
      },
    },
  ];


  if (loading && auth.currentUser){
    return (
      <RowExt paddingTop={72} align="middle" justify="center">
        <div className="lds-ellipsis-big">
          <div />
          <div />
          <div />
          <div />
        </div>
      </RowExt>
    );
  }

  const renderTable = () => {
    return (
      <Table
        dataSource={data instanceof Array ? data : []}
        // @ts-ignore
        columns={columns}
        className="components-table-demo-nested"
        pagination={{
          total: calcCount,
          onChange: (page) => {
            setLimit(page * 10);
          }
        }}
        style={{ marginTop:0 }}
      />
    );
  };


  return (
    <>
      {!_.isEmpty(data) ? (
        <AnimatePresence>
          <motion.div
            transition={{
              ease: 'easeInOut', duration: 1,
            }}
            initial={{ opacity: !_.isEmpty(data) ? 1 : 0 }}
            animate={{ opacity: !_.isEmpty(data) ? 1 : 0 }}
            exit={{ opacity: 0 }}
          >
            { renderTable()}
          </motion.div>

        </AnimatePresence>
      ) : (
        <>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Brak zapisanych wyliczeń"
            style={{ margin: '64px 0' }}
          />
        </>
      )}
      <Divider />
      <EditRecordName
        {...{
          isModalVisible,
          closeModal: () => {
            setIsModalVisible(false);
            setSaveId('');
            setRecordName('');
          },
          saveId,
          recordName,
        }}
      />
      <ShareSaveModal
        {...{
          isModalVisible: isShareVisible,
          closeModal: () => {
            setIsShareVisible(false);
            setSharedId('');
          },
          sharedId
        }}
      />
    </>
  );
};



export default memo(ResultLsTable);
