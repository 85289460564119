import { UserOutlined } from '@ant-design/icons';
import { Col, Divider, Typography } from 'antd';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import BackButton from '../components/Lawsuit/StepCard/BackButton';
import AnimOpacity from '../components/UI/Anim/AnimOpacity';
import PrivateContainer from '../components/UI/Containers/PrivateContainer';
import DotsEnding from '../components/UI/DotsEnding';
import NavPrivate from '../components/UI/Nav/NavPrivate';
import AppVersion from '../components/User/AppVersion';
import ContactDeleteAcc from '../components/User/ContactDeleteAcc';
import InboxUsers from '../components/User/InboxUsers';
import UserData from '../components/User/UserData';
import UserSubscription from '../components/User/UserSubscription';
import { RowExt } from '../styles';
import { LinkFullWidth } from '../styles/ui';

const { Title, Text } = Typography;

export const RowRes = ({ children }: { children: ReactNode }) =>
  (
    <RowExt marginTop={48} fullWidth justify="center">
      <Col
        lg={{
          offset:0,
          span:24,
        }}
        xs={{ span: 20 }}
      >
        {children}
      </Col>
    </RowExt>

  );

const User = () => {
  return (
    <PrivateContainer>
      <div>
        <NavPrivate />
        <AnimOpacity>
          <RowRes>
            <Title level={2}>
              <UserOutlined />
              {' '}
              Konto & kontakt
            </Title>
            <Text>Twoje dane ustawienia konta i kontakt</Text>
            <Divider />
            <UserData />
            <UserSubscription />
            <InboxUsers />
            <ContactDeleteAcc />
            <AppVersion />
          </RowRes>
        </AnimOpacity>
      </div>
      <RowRes>
        <LinkFullWidth
          to={{ pathname: '/dashboard' }}
        >
          <BackButton
            onBack={() =>
              null}
            showHome={false}
          />
        </LinkFullWidth>
      </RowRes>
      <DotsEnding />
    </PrivateContainer>
  );
};

export default User;

export const DashboardWrapper = styled.div`
	padding-top: 72px;
    width: 100%;
`;
