import _ from 'lodash';
import moment, { Moment } from 'moment';
import { shallowEqual } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { getPartialSum } from '../../services/importEx';
import { useTypedSelector } from '../../store';
import { ImportedFormExcelRow } from '../../store/importExcel';
import { MATURITY_LATE_DAY, MaturityDateOpts } from '../../store/interestFormOptions';
import { InterestData, InterestItem } from '../../store/interestStorage';
import { checkDueDate } from '../helpers/date';
import { isNumeric } from '../helpers/isNumeric';
import { ImportWarning } from './useImpOrcValidate';


type Acc = {
  interestData: InterestData,
  rows: RowWithWarnings[],
};

const useImpExcelValidate = () => {
  const impExcelValidated = useTypedSelector(state => {
    const {
      factualBasis,
      legalBasis,
      maturityDateOpts,
      endDate,
      data
    } = state.importEx;

    if (data){

      return data.reduce((prev:Acc, curr, indx) => {
        const {
          rowWithWarnings,
          index,
          dueDate
        } = validateExcelRows(curr, indx);

        const { isFatalError, rowOnlyData } = checkFatalError(prev, {
          rowWithWarnings,
          index,
        });

        if (isFatalError) return rowOnlyData;

        return {
          interestData: [
            ...prev.interestData,
            rowToInterestItem({
              dueDate,
              endDate: endDate ?? moment(),
              factualBasis,
              row: curr,
              legalBasis,
              maturityDateOpts,
            }),
          ],
          rows:[
            ...prev.rows,
            rowWithWarnings,
          ]
        };
      }, {
        interestData: [],
        rows: [],
      });

    } return {
      interestData: [],
      rows: [],
    };
  }, shallowEqual);

  const sumOfInterestData = impExcelValidated.interestData.reduce((prev, curr) =>
    prev + curr.partialSum, 0);
  return {
    ...impExcelValidated,
    sumOfInterestData,
  };
};

export default useImpExcelValidate;

export type ImportWarningRow = {
  message: string,
  isFatal: boolean,
};

export type RowWithWarnings = {
  dueDate: {
    value: string | number,
    warnings: ImportWarning[]
  },
  partialSum: {
    value: string | number,
    warnings: ImportWarning[]
  },
  accountingDoc: {
    value: string | number,
    warnings: ImportWarning[]
  },
};

const validateExcelRows = (row: ImportedFormExcelRow, index: number) => {

  let modDueDate: Moment | Date = moment(row[0]);

  const rowWithWarnings: RowWithWarnings = {
    dueDate: {
      value: row[0],
      warnings: [],
    },
    partialSum: {
      value: row[1],
      warnings: [],
    },
    accountingDoc: {
      value: row[2],
      warnings: []
    }
  };

  if (!moment(row[0]).isValid()){
    rowWithWarnings.dueDate.warnings.push({
      message: 'Termin spełnienia świadczenia nie jest datą (pierwsza kolumna).',
      isFatal: true
    });
  } else if (moment(row[0]).isBefore('01-01-2016')){
    rowWithWarnings.dueDate.warnings.push({
      message: 'Termin spełnienia jest ustawiony przed 1 stycznia 2016 roku. Aplikacja nie obsługuje roszczeń przed tą datą.',
      isFatal: true
    });
  } else if (moment(row[0]).isAfter(moment())){
    rowWithWarnings.dueDate.warnings.push({
      message: 'Termin spełnienia jest w przyszłości - odmowa importu.',
      isFatal: true
    });
  } else {
    const { date, alert } = checkDueDate(moment(row[0]));
    if (alert){
      rowWithWarnings.dueDate.warnings.push({
        message: `${alert  }. Na podstawie art. 115 KC zmieniam na najbliższy możliwy dzień.`,
        isFatal: false
      });
      modDueDate = moment(date).toDate();
    }
  }
  if (!(_.isNumber(row[1]) || isNumeric(row[1]))){
    rowWithWarnings.partialSum.warnings.push({
      message: `Dane nie posiadają właściwie sformatowanej kwoty(druga kolumna ${row[1]}).`,
      isFatal: true
    });
  }
  if (row[3] && !_.isString(row[2])){
    rowWithWarnings.accountingDoc.warnings.push({
      message: 'Numer faktury nie został właściwie sformatowany - pomijam (trzecia kolumna).',
      isFatal: false
    });
  }
  return {
    rowWithWarnings,
    dueDate: moment(modDueDate),
    index,
  };
};


const rowToInterestItem = ({
  dueDate,
  endDate,
  factualBasis,
  row,
  legalBasis,
  maturityDateOpts,
}:{
  dueDate: Moment,
  endDate: Moment,
  factualBasis:string | null,
  row: ImportedFormExcelRow,
  legalBasis: null | string,
  maturityDateOpts: MaturityDateOpts,
}): InterestItem => {
  return ({
    key:uuidv4(),
    startingDate: moment(_.cloneDeep(dueDate)).add(1, 'day').startOf('day'),
    endDate:moment(row[3]).isValid() ? moment(row[3]).endOf('day') : endDate,
    partialSum: getPartialSum(row[1]),
    legalBasis,
    factualBasis,
    accountingDoc: (_.isString(row[2]) || _.isNumber(row[2])) ? _.toString(row[2]) : null,
    dueDate,
    maturityDate:  maturityDateOpts === MATURITY_LATE_DAY ? moment(_.cloneDeep(dueDate)).add(1, 'day').startOf('day') : moment(_.cloneDeep(dueDate)).startOf('day'),
    isClaimFromFulfilled: false,
  });
};


export const hasRowFatalError = (row:RowWithWarnings) =>
  Object
    .values(row).some(value =>
      value.warnings.some(({ isFatal }) =>
        isFatal === true));

const checkFatalError = (prev: Acc, check: {
  index: number,
  rowWithWarnings: RowWithWarnings
}) => {
  const { rowWithWarnings } = check;

  const isFatalError = hasRowFatalError(rowWithWarnings);

  return {
    isFatalError,
    rowOnlyData:{
      ...prev,
      rows: [
        ...prev.rows,
        rowWithWarnings
      ]
    }
  };
};

