import { repeat } from 'ramda';
import { useTypedSelector } from '../../store';
import { BLUE } from '../../styles';
import { BLACK, BLUE_MEDIUM, RED } from '../../styles/colors';



const usePaymentsColors = () => {
  const paymentsColors = useTypedSelector(state => {
    const { paymentData } = state.interestStorage;
    const paymentMap = new Map<string, string>();
    const colors = [BLUE, RED, BLACK,  BLUE_MEDIUM, '#00E8DA', '#E60082' ];

    const multipliedColor = repeat(colors, 100).flat();
    paymentData.forEach(({ key }, index) => {
      paymentMap.set(key, multipliedColor[index]);
    });
    return paymentMap;
  });
  return paymentsColors;
};

export default usePaymentsColors;