import { Button, Divider, Row } from 'antd';
import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import useWindowSize from '../../utils/hooks/useWindowSize';
import { GRID_SM, RowExt } from '../../styles';


const SumUpNavBar = ({  showDetails, showWps, showJustification }:{
  showDetails:()=>void,
  showWps:()=>void,
  showJustification:()=>void,
}) => {
  const { width } = useWindowSize();
  if (width < GRID_SM) {
    return (
      <>
        <RowExt marginTop="md" justify="center" align="middle" fullWidth>
          <AnchorLink href="#wps">
            <Button type='link' onClick={showWps}>
              {texts.wps}
            </Button>
          </AnchorLink>
        </RowExt>
        <RowExt marginTop="md" justify="center" align="middle" fullWidth>
          <AnchorLink href="#justification">
            <Button type='link' onClick={showJustification}>
              {texts.justification}
            </Button>
          </AnchorLink>
        </RowExt>
        <RowExt marginTop="md" justify="center" align="middle" fullWidth>
          <AnchorLink href="#details">
            <Button type='link' onClick={showDetails}>
              {texts.details}
            </Button>
          </AnchorLink>
        </RowExt>
      </>
    );
  }
  return (
    <>
      <RowExt marginTop="md" justify="space-around" align="middle">
        <Row justify="center">
          <AnchorLink href="#wps">
            <Button type='link' onClick={showWps}>
              {texts.wps}
            </Button>
          </AnchorLink>
        </Row>
        <Divider type="vertical" />
        <Row justify="center">
          <AnchorLink href="#justification">
            <Button type='link' onClick={showJustification}>
              {texts.justification}
            </Button>
          </AnchorLink>
        </Row>
        <Divider type="vertical" />
        <Row justify="center">
          <AnchorLink href="#details">
            <Button type='link' onClick={showDetails}>
              {texts.details}
            </Button>
          </AnchorLink>
        </Row>
      </RowExt>
    </>
  );
};


export default SumUpNavBar;

const texts = {
  goBack: 'wróc',
  reset:'resetuj',
  wps:'wps i opłata sądowa',
  payment: 'wynagrodzenie',
  justification: 'wygeneruj tekst',
  details: 'szczegóły wyliczeń',
  save: 'zapisz'
};