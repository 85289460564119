import { Card, Col, Input, Row, Typography } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { useTypedSelector } from '../../../store';
import { impExternalFactualBasisUpdated } from '../../../store/impExternal';
import { ocrFactualBasisUpdated } from '../../../store/impOcr';
import { importExcelFactualBasisUpdated } from '../../../store/importExcel';
import { BLACK } from '../../../styles';
import Icon from '../../../styles/Icon';
import { ANNOUNCEMENT } from '../../../styles/iconTypes';
import { IMP_OCR, ImpTypes, IMP_EXCEL } from './impCommonData';


const ImpCommFactualBasis = ({ impType }:{ impType: ImpTypes })  => {
  const dispatch = useDispatch();

  const factualBasis = useTypedSelector(state => {
    switch (impType) {
      case IMP_OCR:
        return state.impOcr.factualBasis;
      case IMP_EXCEL:
        return state.importEx.factualBasis;
      default:
        return state.impExternal.factualBasis;
    }
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    switch (impType) {
      case IMP_OCR:
        dispatch(ocrFactualBasisUpdated(e.target.value));
        break;
      case IMP_EXCEL:
        dispatch(importExcelFactualBasisUpdated(e.target.value));
        break;
      default:
        dispatch(impExternalFactualBasisUpdated(e.target.value));
    }
  };

  return (
    <Card
      type="inner"
      title="Wspólna podstawa faktyczna (opcjonalne)"
      style={{ marginTop:12 }}
      extra={(
        <Icon
          iconType={ANNOUNCEMENT}
          height="18"
          width="18"
          fill={BLACK}
        />
)}
    >
      <Row align='middle'>
        <Col span={8}>
          <TextIntro>
            Z tytułu...
          </TextIntro>
        </Col>
        <Col span={16}>
          <Input
            placeholder="umowy najmu z dnia 2021-04-27 "
            allowClear
            value={factualBasis ?? ''}
            onChange={onChange}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default ImpCommFactualBasis;


export const TextIntro = styled(Typography.Text)`
    font-style: italic;
    justify-content: center;
    display: flex;
    padding: 0 24px;
`;