import { Card } from 'antd';
import Meta from 'antd/lib/card/Meta';
import React from 'react';
import { Desc } from './UserData';

const AppVersion = () => {
  return (
    <Card>
      <Meta
        title="Wersja pozew.app"
        description={<Desc>{`v-${process.env.REACT_APP_VERSION}`}</Desc>}
      />
    </Card>
  );
};

export default AppVersion;
