import { Typography } from 'antd';
import React from 'react';
import useWindowSize from '../../utils/hooks/useWindowSize';
import { GRID_LG, RowExt } from '../../styles';
import MenuItemWrapper from '../Home/Menu/MenuItemWrapper';
import MenuLogo from '../Home/Menu/MenuLogo';
import SignUpMainCont from '../SignUp/SignUpMainCont';
import AnimOpacity from '../UI/Anim/AnimOpacity';
import DotsEnding from '../UI/DotsEnding';
import LoginForm from './LoginForm';

const { Title } = Typography;

const LoginHome = (
  {
    goToSignUp,
    onBack
  }: {
    goToSignUp: () => void,
    onBack?: () => void,
  }
) => {

  const { height, width } = useWindowSize();

  return (
    <AnimOpacity>
      <SignUpMainCont>
        <MenuLogo />
        <MenuItemWrapper>
          <RowExt
            marginTop={width >= GRID_LG && height <= 800 ? 0 : 'md'}
          >
            <Title level={width >= GRID_LG && height <= 800  ? 2 : 2}>{texts.title1}</Title>
          </RowExt>
          <LoginForm
            goToSignUp={goToSignUp}
            onBack={onBack}
          />
        </MenuItemWrapper>
        {width >= GRID_LG && height <= 800 ? (
          <RowExt />
        ) : (
          <MenuItemWrapper>
            <DotsEnding botpadding={10} />
          </MenuItemWrapper>
        )}
      </SignUpMainCont>
    </AnimOpacity>

  );
};

export default LoginHome;

const texts = { title1: 'Zaloguj się' };
