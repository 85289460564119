import { ContainerOutlined, DownOutlined, FieldTimeOutlined, PrinterOutlined, UpOutlined } from '@ant-design/icons';
import { Button, Col, Space } from 'antd';
import React from 'react';
import { useHistory } from 'react-router';
import { useTypedSelector } from '../../../store';
import { ButtonFullWidth, RowExt } from '../../../styles';
import DetailsExcel from './DetailsExcel';


const DetailsActions = ({  detailsVisible, setDetailsVisible, handlePrint  }:{
  setDetailsVisible:(detailsVisible:boolean)=>void,
  detailsVisible: boolean,
  handlePrint: (() => void) | undefined,
}) => {
  const typeOfInterest  = useTypedSelector(state =>
    state.sumUp.lawsuitOpts.typeOfInterest);
  const compensationOn  = useTypedSelector(state =>
    state.sumUp.lawsuitOpts.compensationOn);
  const history = useHistory();
  const getNavTarget = () => {
    switch (typeOfInterest) {
      case 'INTEREST_CAPITAL':
        return '/execActsCapital';
      case 'INTEREST_LATE':
        return '/execActsLate';
      case 'INTEREST_TRANSACTION':
        return '/execActsTransaction';
      case 'INTEREST_MED_PUBLIC':
        return '/execActsTransaction';
      default:
        return '/execActsTransaction';
    }
  };

  return (
    <>
      <RowExt marginTop="md">
        <Space>
          <Button
            type='primary'
            icon={detailsVisible ? <DownOutlined /> : <UpOutlined />}
            onClick={() =>
              setDetailsVisible(!detailsVisible)}
          >
            {texts.check}
          </Button>
        </Space>
      </RowExt>
      {detailsVisible ? (
        <RowExt
          marginTop={32}
          justify="space-between"
          align="middle"
          gutter={[{ sm:16 }, {
            md:24, sm: 24, xs: 24
          }]}
        >
          <Col lg={6} md={12} xs={24}>
            <ButtonFullWidth onClick={handlePrint} type='primary' icon={<PrinterOutlined />}>
              Drukuj
            </ButtonFullWidth>
          </Col>
          <Col lg={6} md={12} xs={24}>
            <DetailsExcel />
          </Col>
          <Col lg={6} md={12} xs={24}>
            {typeOfInterest !== 'INTEREST_AGREE' ? (
              <ButtonFullWidth
                onClick={() =>
                  history.push(getNavTarget())}
                type='primary'
                icon={<ContainerOutlined />}
              >
                Akty wykonawcze
              </ButtonFullWidth>
            ) : null}
          </Col>
          <Col lg={6} md={12} xs={24}>
            {  compensationOn ? (
              <ButtonFullWidth
                onClick={() =>
                  history.push('/compensationBasis')}
                type='primary'
                icon={<FieldTimeOutlined />}
              >
                Rekompensaty
              </ButtonFullWidth>
            ) : null}
          </Col>
        </RowExt>
      ) : null}
    </>
  );
};

export default DetailsActions;

const texts = {
  check: 'Zobacz',
  exel: 'pobierz xls (exel)',
  pdf: 'pobierz pdf',
};