import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import _ from 'lodash';
import React from 'react';
import { DocTypes, GeneralDoc, InvcVat, INVC_VAT, PaymentConfirmation, PAYMENT_CONFIRMATION } from '../../../../services/ML/docTypes';
import { useAppDispatch } from '../../../../store';
import { OcrItem, ocrItemRemoved, ocrItemSelected } from '../../../../store/impOcr';
import { EvidListItem } from '../../../Evidence/EvidListItem';


import { createFVTitle } from '../../../Evidence/EvidCard/Types/InvcVatEvidCard';
import { createPayConTitle } from '../../../Evidence/EvidCard/Types/PayConEvidCard';

const createTitle = (correctedDocResults: InvcVat | PaymentConfirmation | GeneralDoc, docType: DocTypes) => {
  if (docType === PAYMENT_CONFIRMATION) return createPayConTitle();
  if (docType === INVC_VAT) return createFVTitle(correctedDocResults as InvcVat);
  if (correctedDocResults.createDate) return `Dokument z dnia ${correctedDocResults.createDate}`;
  return 'Dokument';
};

const ImpOcrListItem = ({
  ocr,
  index,
  onMerge,
  isSelected,
  setSelected,
  selectedItems
}:{
  ocr: OcrItem
  index: number
  isSelected:boolean
  setSelected:(selectedItems: number[]) =>void
  selectedItems: number[],
  onMerge: (clickedIndex: number) => void
}) => {
  const dispatch = useAppDispatch();

  const { correctedDocResults:{ createDate }, correctedDocType } = ocr;

  const onDelete = () => {
    setSelected([]);
    dispatch(ocrItemRemoved(index));
  };

  const onEdit = () => {
    dispatch(ocrItemSelected(index));
  };

  const onMergeClick = () => {
    onMerge(index);
  };

  const onSelect = (e: CheckboxChangeEvent) => {
    if ( e.target.checked && !isSelected) {
      setSelected([
        ...selectedItems,
        index
      ]);
    }
    if (!e.target.checked && isSelected) {
      const cloned = _.cloneDeep(selectedItems).filter(v =>
        v !== index);
      setSelected(cloned);
    }
  };


  return (
    <EvidListItem
      {...{
        checked:isSelected,
        onSelect,
        onDelete,
        onMerge:onMergeClick,
        onEdit,
        pages: ocr.pages.length,
        description:createDate ? `z dnia ${createDate}` : 'z dnia | brak daty',
        title: createTitle(ocr.correctedDocResults, correctedDocType),
        mergeDisabled: selectedItems.length === 0 || isSelected
      }}
    />
  );
};

export default ImpOcrListItem;
