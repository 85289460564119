import React from 'react';
import { ComponentChildren } from '../../@types';
import LawContainer from '../UI/Containers/LawContainer';
import LawBorders from './LawBorders';
import TermsTitle from './TermsTitle';

const TermsPar = (
  {
    children,
    title
  }: ComponentChildren & {
    title: string
  }
) => {
  return (
    <>
      <LawContainer>
        <TermsTitle
          title={title}
        />
        {children}
      </LawContainer>
      <LawBorders />
    </>
  );
};

export default TermsPar;
