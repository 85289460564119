import { Button, Row, Tag } from 'antd';
import React from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import { LocationState } from '../../../@types';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { importExcelIsVisible } from '../../../store/importExcel';
import { BLUE, GREY_WHITE } from '../../../styles';
import useIsTransacOrAgree from '../../../utils/hooks/useIsTransacOrAgree';

export const SubIcon = () => {
  return (
    <Tag style={{
      color: BLUE, fontWeight: 800, borderColor: BLUE
    }}
    >
      S
    </Tag>
  );
};

const ImpExcelButton = () => {
  const isTransacOrAgree = useIsTransacOrAgree();
  const dispatch = useAppDispatch();

  const step = useTypedSelector(state =>
    state.lawsuitUI.step);

  const isCurrencyVisible  = useTypedSelector(state =>
    state.currency.isCurrencyVisible);

  const location = useLocation<LocationState>();

  if (step === 2 && isTransacOrAgree && location.pathname === '/lawsuit' && !isCurrencyVisible){
    return (
      <RowWithBorder align="middle">
        <Tag style={{
          color: BLUE, fontWeight: 800, borderColor: BLUE
        }}
        >
          S
        </Tag>
        <Button
          type="link"
          onClick={() => {
            dispatch(importExcelIsVisible(true));
          }}
        >
          Przekształć  plik excel w tekst
        </Button>
      </RowWithBorder>
    );
  }
  if (step === 1 && !isTransacOrAgree && location.pathname === '/lawsuit' && !isCurrencyVisible){
    return (
      <Button
        type="link"
        onClick={() => {
          dispatch(importExcelIsVisible(true));

        }}
      >
        Przekształć plik excel w tekst
      </Button>
    );
  }
  return null;
};

export default ImpExcelButton;


const RowWithBorder = styled(Row)`
   border-right: 0.5px solid ${GREY_WHITE};
   margin-right: 12px;
`;
