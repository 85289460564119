/* eslint-disable react/jsx-key */
import { UnorderedListOutlined } from '@ant-design/icons';
import { Alert, Button, Typography } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';
import styled from 'styled-components';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import { isJustDocsVisibleUpdated } from '../../../../store/justOpts';
import { RowExt } from '../../../../styles';
import { GreyArea } from '../../../../styles/ui';

const JustDocList = () => {
  const dispatch = useAppDispatch();
  const { partialResults } = useTypedSelector(state =>
    state.sumUp, shallowEqual);
  const [docs, setDocs] = useState<[] | (string | null)[]>([]);

  const { isJustDocsVisible }
  = useTypedSelector(state =>
    state.justOpts, shallowEqual);

  useEffect(() => {
    const accountingDocsArr: string[] = [];
    partialResults.forEach(value => {
      if (value.accountingDoc !== null && value.accountingDoc !== ''){
        accountingDocsArr.push(value.accountingDoc);
      }
    });
    if (!_.isEmpty(accountingDocsArr)){
      setDocs(accountingDocsArr);
    }
  }, [partialResults]);

  return (
    <>
      <RowExt marginTop="md">
        <Typography.Title level={4}>
          Lista dowodów/dokumentów księgowych
        </Typography.Title>
      </RowExt>
      <ListParagraph type="secondary">Wygenerowana na bazie podanych dokumentów księgowych</ListParagraph>
      <RowExt marginTop="md">
        <Button
          type="primary"
          icon={<UnorderedListOutlined />}
          onClick={() => {
            dispatch(isJustDocsVisibleUpdated(true));
          }}
        >
          Generuj listę dokumentów księgowych
        </Button>
      </RowExt>
      {isJustDocsVisible ? (
        <>
          {docs.length === 0 ? (
            <AlertStyled
              message='Brak danych. Uzupełnij nazwy dokumentów księgowych w podstwie podstawie prawnej i faktycznej dla poszczególnych kwot składowych.'
            />
          ) : (
            <GreyArea>
              <ListParagraph>Niniejszym wnoszę o przeprowadzenie dowodu z dokumentów w postaci:</ListParagraph>
              {docs.map(result =>
                (
                  <ListParagraph>
                    {`- ${result};` }
                  </ListParagraph>
                ))}
              <ListParagraph>celem wykazania...</ListParagraph>
            </GreyArea>
          )}
        </>
      ) : null}
    </>
  );
};

export default JustDocList;

const ListParagraph = styled(Typography.Paragraph)`
  width: 100%;
`;

const AlertStyled = styled(Alert)`
  margin-top: 24px;
`;