// import { History } from 'react-router';
import _ from 'lodash';
import { AppDispatch, RootState } from '../store';
import { appLoadingStarted, appLoadingStopped } from '../store/appLoading';
import { interestAllDataChanged, paymentDataChanged } from '../store/interestStorage';
import { lawsuitOptsAllUpdated } from '../store/lawsuitOpts';
import { interestDataProviderUpdated, lsSetSave, MANUAL_PAYMENTS_ASSIGNMENTS } from '../store/lawsuitUI';
import { partialResultsToInterestData } from '../utils/helpers/formatInterestData';
import { resetLawsuit } from '../utils/helpers/resetLawsuit';

export const editResults = ({
  history,
  saveId,
  recordName
}:{
  history: {
    push: (location: string) => void;
  },
  saveId?: string,
  recordName?: string
}) =>
  (dispatch:AppDispatch, getState: () => RootState) => {
    dispatch(appLoadingStarted());

    const state = getState();
    const { partialResults, lawsuitOpts, paymentData } = state.sumUp;

    resetLawsuit(dispatch);

    const interestData = partialResultsToInterestData(partialResults);

    dispatch(interestAllDataChanged(interestData));
    dispatch(paymentDataChanged(paymentData));
    if (!_.isEmpty(paymentData)){
      dispatch(interestDataProviderUpdated(MANUAL_PAYMENTS_ASSIGNMENTS));
    }
    dispatch(lawsuitOptsAllUpdated(lawsuitOpts));
    if (saveId) {
      dispatch(lsSetSave({
        saveId,
        recordName: recordName ?? null,
      }));
    }

    history.push('/lawsuit');

    dispatch(appLoadingStopped());
  };

