import { ArrowLeftOutlined, CheckOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Divider, Typography } from 'antd';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';

import { RowSm } from '../../../styles';

const { Title, Paragraph } = Typography;

const EvidEditActionBar = ({
  title,
  onConfirm,
  onClose
}:{
  title: string,
  onConfirm: () => void
  onClose: () => void
}) => {
  return (
    <div>

      <AnimatePresence>
        <motion.div
          transition={{
            ease: 'easeInOut',
            duration: 1,
          }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Title
            level={2}
            style={{ marginTop: 48 }}
          >
            <EditOutlined />
            {' '}
            {title}
          </Title>
          <Paragraph>
            Edytuj dane. Użyj proponowanych lub zmień je manualnie.
          </Paragraph>
          <Divider />
        </motion.div>
      </AnimatePresence>
      <RowSm justify='space-between'>
        <Button
          icon={<ArrowLeftOutlined />}
          onClick={onClose}
          type="dashed"
        />
        <Button
          icon={<CheckOutlined />}
          type="primary"
          onClick={onConfirm}
        />
      </RowSm>
    </div>
  );
};

export default EvidEditActionBar;