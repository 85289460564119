import { Col } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { DashedDivider } from '../../../routes/About';
import { TitleDivider } from '../../../styles';

const MenuDividerMobile = (
  { color }: {
    color?: string
  }
) => {
  return (
    <>
      <DashedDividerStyled />
      <Col
        xs={{
          offset: 2,
          span: 20,
        }}
      >
        <TitleDividerStyled
          color={color}
        />
      </Col>
    </>
  );
};

export default MenuDividerMobile;

const TitleDividerStyled = styled(TitleDivider)`
  margin-bottom:0;
`;

const DashedDividerStyled = styled(DashedDivider)`
   margin-top: 48px;
`;
