import { claimCalc } from '../../services/calc/claim';
import { useTypedSelector } from '../../store';
import { InterestData, PaymentData } from '../../store/interestStorage';
import { LawsuitOpts } from '../../store/lawsuitOpts';


export const getClaimsCovered = (interestData: InterestData, paymentData: PaymentData, lawsuitOpts: LawsuitOpts) =>
  interestData.filter((interestItem)  => {
    const { claimSum, interestsSumFulfilledUncovered, isClaimFromFulfilled } = claimCalc({
      interestItem,
      paymentData,
      lawsuitOpts,
    });
    return (claimSum === 0 && !isClaimFromFulfilled) ||
    (claimSum === 0 &&
    interestsSumFulfilledUncovered === 0 &&
    isClaimFromFulfilled);
  });

export const getClaimsNotCovered = (interestData: InterestData, paymentData: PaymentData, lawsuitOpts: LawsuitOpts) =>
  interestData.filter((interestItem)  => {
    const { claimSum, interestsSumFulfilledUncovered, isClaimFromFulfilled } = claimCalc({
      interestItem,
      paymentData,
      lawsuitOpts,
    });
    return (claimSum > 0 && !isClaimFromFulfilled) ||
    (claimSum >= 0 &&
    interestsSumFulfilledUncovered > 0 &&
    isClaimFromFulfilled);
  });


const useListOfFullCoveredClaims = () => {
  const { claimsCovered, claimsNotCovered } = useTypedSelector(state => {
    const { paymentData, interestData } = state.interestStorage;
    const { lawsuitOpts } = state;
    return {
      claimsCovered:getClaimsCovered(interestData, paymentData, lawsuitOpts),
      claimsNotCovered:getClaimsNotCovered(interestData, paymentData, lawsuitOpts)
    };
  });
  return {
    claimsCovered,
    claimsNotCovered,
    areSomeClaimsCovered: claimsCovered.length > 0,
  };
};

export default useListOfFullCoveredClaims;