import { CheckOutlined, CopyOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

type Props = {
  womargin?: boolean;
  value?: undefined | string;
};

const CopyButtonJust = (
  {
    womargin,
    value
  }: Props
) => {

  const [linkWasCopied, setLinkWasCopied] = useState<boolean>(false);

  const onCopied = () => {
    setLinkWasCopied(true);
    setTimeout(() => {
      setLinkWasCopied(false);
    }, 1000);
  };

  return (
    <CopyToClipboard text={value ?? ''} onCopy={onCopied}>
      <Button
        style={{ marginTop: womargin ? 0 : 18 }}
        type='dashed'
        icon={linkWasCopied ? <CheckOutlined /> : <CopyOutlined />}
      >
        {texts.copy}
      </Button>
    </CopyToClipboard>
  );
};


export default CopyButtonJust;

const texts = { copy: 'kopiuj ' };