import { Col } from 'antd';
import React from 'react';
import { AboutContainer, AbtSpaceRow, DashedColBoth, DashedColLeft } from '../../styles';


const IntroContactTop = () => {
  return (
    <AboutContainer>
      <AbtSpaceRow>
        <Col
          span={1}
        />
        <DashedColBoth
          xl={{ span: 7 }}
          lg={{ span: 7 }}
          md={{ span:0 }}
          xs={0}
        />
        <Col
          xl={{ span: 14 }}
          lg={{ span: 3 }}
          md={{ span:0 }}
          xs={0}
        />
        <DashedColLeft
          xl={{ span: 2 }}
          lg={{ span: 3 }}
          xs={0}
        />
      </AbtSpaceRow>
    </AboutContainer>
  );
};

export default IntroContactTop;
