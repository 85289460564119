/* eslint-disable no-useless-escape */
/* eslint-disable max-len */

export const composeRegex = (regexes: RegExp[], flags:string = '') =>
  new RegExp(regexes.map(regex =>
    regex.source).join('|'), flags);

export const flattenString = (str: string) =>
  str
    .replace(RGX_LINE_BRAKES, ' ')
    .replace(RGX_SPACES_MORE, '');


/* ------------------- FOR NEARLEY DO NOT USE GLOBAL FLAG ------------------- */
// ############################################################################

/* -------------------------------------------------------------------------- */
/*                                   GENERAL                                  */
/* -------------------------------------------------------------------------- */

// line brakes
export const RGX_LINE_BRAKES = /(\r\n|\n|\r)/gm;

// 2 or more spaces

export const RGX_SPACES_MORE = / +(?= )/g;

// http/s link
export const RGX_HTTPS_LINK = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%.\_+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;

export const RGX_NR_EURO_STYLE = /((\d{1,3}[ ](\d{3}[ ])*\d{3}|\d+)([,]\d{1,2}))\ ([£€$]|pln|zł)/gi;

// for fv number
export const RGX_2_SLASH = /\w+?\/\w+\/\S+/gi;

// 4 digits
export const RGX_4_DIGITS = /^\d{4}/;

export const RGX_FV_HAS_NUMBER = /\S*\d{1,}\S*\s(?:\S*\d{1,}\S*)?/g;

export const RGX_HAS_DIGITS = /\S*\d{1,}\S*/;

export const RGX_HAS_DIGIT_PREFIX = /(?:\D{0,1}\d{1,3}\s)?(?:\S*\d{1,}\S*)/gi;

export const RGX_FV_PREFIX = /f(v|a)(?:\/|\s)(?:[a-zA-Z]|[^a-zA-Z])[^\s]+/gi;

export const RGX_ACCOUNTING_DOC_KEYS = /(?:zaliczk|cz\u0119\u015Bci)owa|ko\u0144cowa|faktur[ay]|n(?:ume)?r|vat/gmi;

export const RGX_WORD_WITH_NUMBERS = /\w*\d{1,}\w*/gmi;

export const RGX_CURR_EURO_STYLE = /(?:(?:\d{1,3}[ ](?:\d{3}[ ])*\d{3}|\d+)(?:[,]\d{1,2}))\ (?:[£€$]|pln|zł|PLN)/;

export const RGX_CURR_US_STYLE = /((\d{1,3}[ ](\d{3}[ ])*\d{3}|\d+)([,]\d{1,2}))\ ?([£€$]|pln|zł)?/gi;

export const RGX_DUE_DATE_DAYS = /(?:\d{1,3})\sdni/gmi;


/* -------------------------------------------------------------------------- */
/*                                    DATES                                   */
/* -------------------------------------------------------------------------- */

export const RGX_DD_MM_YYYY = /(?:(?:31(?:\/|-|\.)(?:0?[13578]|1[02]))(?:\/|-|\.)|(?:(?:29|30)(?:\/|-|\.)(?:0?[13-9]|1[0-2])(?:\/|-|\.)))(?:(?:1[6-9]|[2-9]\d)?\d{2})|(?:29(?:\/|-|\.)0?2(?:\/|-|\.)(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))|(?:0?[1-9]|1\d|2[0-8])(?:\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))(?:\/|-|\.)(?:(?:1[6-9]|[2-9]\d)?\d{2})/g;

// something off here
export const RGX_YYYY_MM_DD = /(?:(?:1[6-9]|[2-9]\d)?\d{2})(?:(?:(?:\/|-|\.)(?:0?[13578]|1[02])(?:31))|(?:(?:\/|-|\.)(?:0?[13-9]|1[0-2])(?:\/|-|\.)(?:29|30)))|(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00)))(?:\/|-|\.)0?2(?:\/|-|\.)(?:29)|(?:(?:1[6-9]|[2-9]\d)?\d{2})(?:\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))(?:\/|-|\.)(?:0?[1-9]|1\d|2[0-8])/g;

export const RGX_MMMM_PL = /(?:(?:[1-9])|(?:[0][1-9])|(?:[1-2][0-9])|(?:[3][0-1]))\s+(?:(?:pa\u017Adziernik|maj)a|pa\u017Adziernik|l(?:i(?:stopada|piec)|ut(?:ego|y))|listopad|czerwiec|(?:wrze\u015B|kwiet|grud)nia|(?:grudz|wrzes|kwiec)ie\u0144|s(?:ierp(?:nia|ie\u0144)|tycz(?:nia|e\u0144))|(?:czerw|mar|lip)ca|marzec|maj)\s\d{4}/g;
export const RGX_DATES = composeRegex([
  RGX_DD_MM_YYYY,
  RGX_YYYY_MM_DD,
  RGX_MMMM_PL]
);
export const RGX_DATES_NUM = composeRegex([
  RGX_DD_MM_YYYY,
  RGX_YYYY_MM_DD], 'g'
);

export const RGX_DOTS_SLASHES_EMPTY = /(\.)|( )|(\/)/gim;

export const RGX_JAN = /(styczeń|stycznia)/i;
export const RGX_FEB = /lut(?:ego|y)/i;
export const RGX_MAR = /(marzec|marca)/i;
export const RGX_APR = /(kwiecień|kwietnia)/i;
export const RGX_MAY = /(maja|maj)/i;
export const RGX_JUN = /(czerwca|czerwiec)/i;
export const RGX_JUL = /(lipiec| lipca)/i;
export const RGX_AUG = /(sierpień | sierpnia)/i;
export const RGX_SEB = /(wrzesień | września)/i;
export const RGX_OTC = /(październik | października)/i;
export const RGX_NOV = /(listopad | listopada)/i;
export const RGX_DEC = /(grudzień | grudnia)/i;

export const RGX_MOUTHS = [
  RGX_JAN,
  RGX_FEB,
  RGX_MAR,
  RGX_APR,
  RGX_MAY,
  RGX_JUN,
  RGX_JUL,
  RGX_AUG,
  RGX_SEB,
  RGX_OTC,
  RGX_NOV,
  RGX_DEC,
];

export const RGX_ALL_MONTHS = /(?:pa\u017Adziernik|maj)a|pa\u017Adziernik|l(?:i(?:st(?:opada|\.)|p(?:iec|\.))|ut(?:ego|[\.y]))|l(?:i(?:stopad|p)|ut)|(?:kwiet|grud)nia|(?:grudz|wrzes|kwiec)ie\u0144|s(?:ierp(?:nia|ie\u0144)|ty(?:cz(?:nia|e\u0144))?)|marzec|(?:mar|lip)ca|(?:gru|pa\u017A|wrz|kwi|mar)\.|gru|pa\u017A|wrz|kwi|maj/gim;

export const RGX_PAGE_NAMES = /n(?:umer strony|r str(?:ony|\.))|str(?:ona(?: n(?:ume)?r)?|(?:\. nume| n(?:ume)?)r)|page number|page(?: num)?|page nr/gim;

export const RGX_PAGE_NUM_FORMAT = /\s(?:(?:\d+(?:|\s(?:of|na|z)\s|\s?\/\s?|)\d+)|\d+)/gm;

export const RGX_PAGE_NUMBERS = /(?:(?:n(?:umer strony|r str(?:ony|\.))|str(?:ona(?: n(?:ume)?r)?|(?:\. nume| n(?:ume)?)r)|page number|page(?: num)?|page nr)\s?(?:(?:\d+(?:|\s(?:of|na|z)\s|\s?\/\s?|)\d+)|\d+))/gim;

// https://stackoverflow.com/questions/44374088/regex-to-match-comma-separated-numbers-or-stop-full-match-only
export const NumWithCom = /^\d+(?:,\d+)*$/;

