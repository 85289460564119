import { Button as Bt, Button, Card, Radio, RadioChangeEvent, Space } from 'antd';
import Meta from 'antd/lib/card/Meta';
import React from 'react';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { shouldCreateLsCodeUpdated, lsShouldUpdateLsCodeChanged } from '../../../store/lawsuitUI';
import { BLACK, GRID_LG, RowExt, RowMd } from '../../../styles';
import { LINK_FOLDER_CODES, YT_CODES, YT_CODES_EXPLAIN } from '../../../utils/helpers/links';
import useWindowSize from '../../../utils/hooks/useWindowSize';
import CheckVideoButton from '../../UI/Button/CheckVideoButton';
import StepCard from '../StepCard/StepCard';
import { Button2Lines } from './TypeOfInterest';

const LsCodesSelect = () => {
  const { width } = useWindowSize();

  const dispatch = useAppDispatch();

  const shouldCreateLsCode  = useTypedSelector(state =>
    state.lawsuitUI.shouldCreateLsCode);
  const lsCodeToBeUpdated  = useTypedSelector(state =>
    state.lawsuitUI.lsCodeToBeUpdated);

  const radioStyle = {
    display: 'block',
    height: '32px',
    lineHeight: '32px',
  };

  const onPress = (event:RadioChangeEvent) => {
    dispatch(shouldCreateLsCodeUpdated(event.target.value === 1));
  };

  const onResetCode = () => {
    dispatch(lsShouldUpdateLsCodeChanged(null));
  };

  if (width < GRID_LG) {
    return (
      <StepCard
        title="Wygeneruj kod do wyliczeń"
        desc="Udostępnij wyliczenia współpracownikom albo sądowi. Wesprzyj szybkość wydawania orzeczeń."
      >
        <Radio.Group
          onChange={onPress}
          value={shouldCreateLsCode ? 1 : 2}
          style={{ width: '100%' }}
        >
          <Button2Lines value={1}>
            {lsCodeToBeUpdated ? (
              <>
                Zaktualizuj kod wyliczeń:
                <strong>
                  {' '}
                  {lsCodeToBeUpdated}
                  {' '}
                </strong>
                (uwzględnij zmiany danych)
              </>
            ) : 'Wygeneruj nowy kod wyliczeń'}
          </Button2Lines>
          <Button2Lines value={2}>
            {lsCodeToBeUpdated ? 'Nie aktualizuj i nie pokazuj kodu wyliczeń' : 'Nie twórz kodu wyliczeń'}
          </Button2Lines>
        </Radio.Group>
        {lsCodeToBeUpdated ? (
          <RowExt marginTop={8}>
            <Bt type="link" onClick={onResetCode}>
              Wygeneruj nowy kod
            </Bt>
          </RowExt>
        ) : null}
        <RowMd />
        <CheckVideoButton
          key="video"
          url={YT_CODES}
          emptyButtonProps={{
            color: BLACK, hoverBg: BLACK, hoverColor: 'white', full: true, dotted: false
          }}
          text="VIDEO - jak to działa ?"
        />
        <Card
          title="Kody wyliczeń - przyspiesz wydawanie orzeczeń"
        >
          <Meta
            description={(
              <>
                Wygenerowany kod zawierający dane wyliczeń jest importowany
                <strong> do tekstu uzasadniającego wyliczenia.</strong>
                {' '}
                Z wyliczeń może skorzystać
                {' '}
                <strong>sędzia lub referendarz</strong>
                {' '}
                w prowadzonej przez Ciebie sprawie, aby ją szybciej rozstrzygnąć.
                {' '}
                <strong>Ułatwiaj pracę pracownikom wymiaru sprawiedliwości. </strong>
                Możesz też przesłać kod
                <strong>  przełożonemu/współpracownikowi</strong>
                , żeby mógł przeanalizować/zmienić Twoje wyliczenia.
                <CheckVideoButton
                  url={YT_CODES_EXPLAIN}
                  emptyButtonProps={{
                    color: BLACK, hoverBg: BLACK, hoverColor: 'white', dotted: false
                  }}
                  text="VIDEO - kody wyliczeń - teoria"
                />
                <Button type='link' href={LINK_FOLDER_CODES}>
                  pobierz folder
                </Button>
              </>
            )}
          />
        </Card>
      </StepCard>
    );
  }
  return (
    <StepCard
      title="Wygeneruj kod wyliczeń"
      desc="Udostępnij wyliczenia współpracownikom albo sądowi przesyłając unikalny kod. Wesprzyj szybkość wydawania orzeczeń. Za każdym razem, gdy zmienisz dane będące podstawą wyliczeń sugerujemy zaktualizować kod."
    >
      <Radio.Group
        onChange={onPress}
        value={shouldCreateLsCode ? 1 : 2}
      >
        <Radio style={radioStyle} value={1}>
          {lsCodeToBeUpdated ? (
            <Space>
              Zaktualizuj kod wyliczeń:
              <strong style={{
                marginRight: 10, marginLeft:14
              }}
              >
                {' '}
                {lsCodeToBeUpdated}
              </strong>
              (uwzględnij zmiany w danych)
            </Space>
          ) : 'Wygeneruj nowy kod wyliczeń'}
        </Radio>
        <Radio style={radioStyle} value={2}>
          {lsCodeToBeUpdated ? 'Nie aktualizuj i nie pokazuj kodu wyliczeń' : 'Nie twórz kodu wyliczeń'}
        </Radio>
      </Radio.Group>
      {lsCodeToBeUpdated ? (
        <RowExt marginTop={8}>
          <Bt type="link" onClick={onResetCode}>
            Wygeneruj nowy kod wyliczeń
          </Bt>
        </RowExt>
      ) : null}
      <CheckVideoButton
        key="video"
        url={YT_CODES}
        emptyButtonProps={{
          color: BLACK, hoverBg: BLACK, hoverColor: 'white', full:true, dotted: false
        }}
        text="VIDEO - jak to działa ?"
      />
      <Card
        title="Kody wyliczeń - przyspiesz wydawanie orzeczeń"
        // style={{ marginTop: }}
      >
        <Meta
          description={(
            <>
              Wygenerowany kod zawierający dane wyliczeń jest importowany
              <strong> do tekstu uzasadniającego wyliczenia.</strong>
              {' '}
              Z wyliczeń może skorzystać
              {' '}
              <strong>sędzia lub referendarz</strong>
              {' '}
              w prowadzonej przez Ciebie sprawie, aby ją szybciej rozstrzygnąć.
              {' '}
              <strong>Ułatwiaj pracę pracownikom wymiaru sprawiedliwości. </strong>
              Możesz też przesłać kod
              <strong>  przełożonemu/współpracownikowi</strong>
              , żeby mógł przeanalizować/zmienić Twoje wyliczenia.
              <CheckVideoButton
                url={YT_CODES_EXPLAIN}
                emptyButtonProps={{
                  color: BLACK, hoverBg: BLACK, hoverColor: 'white', dotted: false
                }}
                text="VIDEO - kody wyliczeń a informatyczny system sądów"
              />
              <Button type='link' href={LINK_FOLDER_CODES}>
                pobierz folder
              </Button>
            </>
            )}
        />
      </Card>

    </StepCard>
  );
};

export default LsCodesSelect;

