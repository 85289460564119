import React from 'react';
import nextId from 'react-id-generator';
import { shallowEqual } from 'react-redux';

import { useTypedSelector } from '../../../../store';
import { RowMd } from '../../../../styles';
import AnimOpacity from '../../../UI/Anim/AnimOpacity';
import ImpOcrListItem from './ImpOcrListItem';

const ImpOcrList = ({
  selectedItems,
  setSelected,
  onMerge
}:{
  setSelected:(selectedItems: number[]) =>void
  selectedItems: number[],
  onMerge: (clickedIndex: number) => void
}) => {
  const { ocrData, isListVisible  } = useTypedSelector(state =>
    state.impOcr, shallowEqual);

  if (!isListVisible) return null;

  return (
    <>
      <RowMd />
      <div>
        <AnimOpacity>
          {ocrData.map((ocr, index) =>
            (
              <ImpOcrListItem
                key={nextId()}
                index={index}
                ocr={ocr}
                isSelected={selectedItems.some(value =>
                  value === index)}
                setSelected={setSelected}
                selectedItems={selectedItems}
                onMerge={onMerge}
              />
            )
          )}
        </AnimOpacity>
      </div>
    </>
  );
};

export default ImpOcrList;