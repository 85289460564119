/* eslint-disable no-useless-escape */
import _ from 'lodash';
import { DocParser, findAccDocWord } from '../DocParser';

import { accDocPhrases } from '../keywords';
import { flattenString, RGX_2_SLASH, RGX_FV_PREFIX, RGX_HAS_DIGITS } from '../regexExtraction';

export const getFvNrFromPrefix = (str:string) => {
  const matches = flattenString(str).match(RGX_FV_PREFIX);
  return _.isNull(matches) ? null : matches[0];
};

export const getFvFromSlashes = (str:string) => {
  const results = str.match(RGX_2_SLASH);

  if (!results) return null;
  return results.filter(value =>
    RGX_HAS_DIGITS.test(value) && value.length > 3)[0];
};

export const getFvNrFromTitle = (doc: string) => {

  const parser = new DocParser(doc);

  return parser.extractRelevantWord(findAccDocWord, accDocPhrases, 1);
};
