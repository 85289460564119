import { Col } from 'antd';
import React from 'react';
import { useHistory } from 'react-router';

import LawBorders from '../components/Law/LawBorders';
import LawPars from '../components/Law/LawPars';
import LawUsts from '../components/Law/LawUsts';
import PrivacyPar from '../components/Law/PrivacyPar';
import BackButton from '../components/Lawsuit/StepCard/BackButton';
import AnimOpacity from '../components/UI/Anim/AnimOpacity';
import LawContainer from '../components/UI/Containers/LawContainer';
import DotsEnding from '../components/UI/DotsEnding';
import { RowBg } from '../styles';
import { act1, act2, act3, act4, act5, act6, act7, act8 } from '../utils/law/execLate';
import { DashedDivider } from './About';

const ExecActsLate = () => {
  const history = useHistory();
  return (
    <AnimOpacity>
      <LawContainer title="Akty wykonawcze" />
      <DashedDivider />
      <LawBorders />
      <PrivacyPar
        title={act1.title}
        desc={act1.desc}
      >
        <LawPars texts={act1.pars} />
      </PrivacyPar>
      <PrivacyPar
        title={act2.title}
        desc={act2.desc}
      >
        <LawPars texts={act2.pars1} />
        <LawUsts texts={act2.usts} />
        <LawPars texts={act2.pars2} />
      </PrivacyPar>
      <PrivacyPar
        title={act3.title}
        desc={act3.desc}
      >
        <LawPars texts={act3.pars} />
      </PrivacyPar>
      <PrivacyPar
        title={act4.title}
        desc={act4.desc}
      >
        <LawPars texts={act4.pars} />
      </PrivacyPar>
      <PrivacyPar
        title={act5.title}
        desc={act5.desc}
      >
        <LawPars texts={act5.pars} />
      </PrivacyPar>
      <PrivacyPar
        title={act6.title}
        desc={act6.desc}
      >
        <LawPars texts={act6.pars} />
      </PrivacyPar>
      {' '}
      <PrivacyPar
        title={act7.title}
        desc={act7.desc}
      >
        <LawPars texts={act7.pars} />
      </PrivacyPar>
      {' '}
      <PrivacyPar
        title={act8.title}
        desc={act8.desc}
      >
        <LawPars texts={act8.pars} />
      </PrivacyPar>
      <DashedDivider />
      <LawContainer>
        <RowBg>
          <Col
            lg={0}
            md={{
              offset: 1,
              span: 1,
            }}
          />
          <Col>
            <BackButton
              onBack={() =>
                history.goBack()}
              showHome={false}
            />
          </Col>
        </RowBg>
      </LawContainer>
      <LawContainer>
        <DotsEnding />
      </LawContainer>
    </AnimOpacity>
  );
};

export default ExecActsLate;
