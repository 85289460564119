import { ArrowLeftOutlined, ReloadOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Col, notification } from 'antd';
import _ from 'lodash';
import { Moment } from 'moment';
import React from 'react';
import styled from 'styled-components';

import { useAppDispatch, useTypedSelector } from '../../../store';
import { editMaturityDateUpdated } from '../../../store/edit';
import { maturityDateUpdated } from '../../../store/interestForm';
import { areDateOptionsVisibleUpdated, maturityDateOptsUpdated, MATURITY_LATE_DAY, startingFromDueUpdated } from '../../../store/interestFormOptions';
import { GRID_SM_PX, RowExt } from '../../../styles';

const DateOptionsActions = () => {
  const dispatch = useAppDispatch();

  const onBack = () => {
    dispatch(areDateOptionsVisibleUpdated(false));
  };

  const onReset = () => {
    dispatch(startingFromDueUpdated(true));
    dispatch(maturityDateOptsUpdated(MATURITY_LATE_DAY));
    notification.info({ message: 'Przywrócono domyślne ustawienia. UWAGA: Przywrócenie ustawień nie modyfikuje dodanych według innych ustawień danych.' });
  };

  return (
    <RowExt
      marginTop="bg"
      marginBottom="md"
    >
      <Col
        sm={{
          span:7, order: 2
        }}
        xs={{
          span: 24, order: 2
        }}
      >
        <ButtonResp
          onClick={onBack}
          icon={<ArrowLeftOutlined />}
        >
          Wróc
        </ButtonResp>
      </Col>
      <Col
        sm={{
          offset: 2,
          span:7,
          order: 2
        }}
        xs={{
          span: 24, order: 1
        }}
      >
        <ButtonResp
          onClick={onReset}
          type="dashed"
          icon={<ReloadOutlined />}
        >
          Przywróć domyślne
        </ButtonResp>
      </Col>
    </RowExt>
  );
};


export const DateOptionsPickerActions = ({
  onClose,
  maturityDateLocal
}:{
  onClose: () => void,
  maturityDateLocal: Moment | null,
}) => {

  const isEdit = useTypedSelector(state =>
    !_.isNull(state.edit.key));
  const dispatch = useAppDispatch();

  const onApprove = () => {
    dispatch(isEdit ? editMaturityDateUpdated(maturityDateLocal) : maturityDateUpdated(maturityDateLocal));
    onClose();
  };

  return (
    <RowExt
      marginTop="bg"
      marginBottom="md"
    >
      <Col
        sm={{
          span:7, order: 2
        }}
        xs={{
          span: 24, order: 2
        }}
      >
        <ButtonResp
          onClick={onClose}
          icon={<ArrowLeftOutlined />}
        >
          Anuluj
        </ButtonResp>
      </Col>
      <Col
        sm={{
          offset: 2,
          span:7,
          order: 2
        }}
        xs={{
          span: 24, order: 1
        }}
      >
        <ButtonResp
          onClick={onApprove}
          type="primary"
          icon={<SaveOutlined />}
        >
          Zapisz
        </ButtonResp>
      </Col>
    </RowExt>
  );
};

export default DateOptionsActions;

const ButtonResp = styled(Button)`
    width: 100%;
    @media only screen and (max-width: ${GRID_SM_PX}) {
    margin-top: 24px;
	}
`;