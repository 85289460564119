import _ from 'lodash';
import moment, { Moment } from 'moment';
import React, { useState } from 'react';
import { shallowEqual } from 'react-redux';

import { convertNumeric } from '../../services/ML/DataExtraction/extractCommon';
import { fieldInputMap,
  getDocField,
  InvVatFields,
  isInvVatField,
  isPayConfField,
  PayConfFields,
  updateDocResultsByKey } from '../../services/ML/DataExtraction/fieldInputMap';
import { createOcrItems } from '../../services/ML/createOcrItems';
import { INVC_VAT, InvcVat, isInvcVat, isPaymentConfirmation, PaymentConfirmation } from '../../services/ML/docTypes';
import { useAppDispatch, useTypedSelector } from '../../store';
import { DocPage, ocrDataUpdated, ocrItemSelected, ocrItemUpdated } from '../../store/impOcr';
import { createFVTitle } from './EvidCard/Types/InvcVatEvidCard';
import { createPayConTitle } from './EvidCard/Types/PayConEvidCard';
import { EvidEditForm } from './EvidEdit/EvidEditForm';
import TgEditInvcVat from './EvidEdit/Types/EvidEditInvcVat';
import EditPayCon from './EvidEdit/Types/EvidEditPayCon';
import EvidEditField from './EvidEditField';


const EvidEdit = () => {
  const dispatch = useAppDispatch();

  const [isEditFieldVisible, setEditFieldVisible] = useState(false);

  const [fieldType, setFieldType] = useState<InvVatFields | PayConfFields>('createDate');
  const [ correctedDocResults, pages, correctedDocType, docResults ] = useTypedSelector(state => {
    const { ocrData, editedIndex } = state.impOcr;
    if (_.isNumber(editedIndex)){
      return [ ocrData[editedIndex].correctedDocResults,
        ocrData[editedIndex].pages,
        ocrData[editedIndex].correctedDocType,
        ocrData[editedIndex].docResults
      ];
    }
    return [null, []];
  }, shallowEqual);

  const { editedIndex, ocrData } = useTypedSelector(state =>
    state.impOcr, shallowEqual);

  if (_.isNull(correctedDocResults) && _.isNull(docResults)) return null;

  const resetAllData = () => {
    if (_.isNumber(editedIndex)){
      const ocrItem = ocrData[editedIndex];
      dispatch(ocrItemUpdated({
        ocrItem:{
          ...ocrItem,
          correctedDocResults:ocrItem.extractedData
        },
        index: editedIndex
      }));
    }
  };

  const onClose = () => {
    dispatch(ocrItemSelected(null));
    resetAllData();
  };

  const onConfirm = () => {
    dispatch(ocrItemSelected(null));
  };

  const onSplit = async (pageNumber: number) => {
    if (_.isNumber(editedIndex) && !_.isEmpty(pages)){
      const removedPage = pages.find(value =>
        value.pageNumber === pageNumber);
      if (removedPage){
        const p = pages as DocPage[];

        const updatedPages = p.reduce((prev: DocPage[], curr, index) => {
          if (curr.pageNumber === pageNumber) return prev;
          return [...prev, {
            ...curr,
            pageNumber: index + 1,
          }];
        }, []);

        const updatedDoc =  p.filter(value =>
          value.pageNumber !== pageNumber).reduce((prev, curr) =>
          prev + curr.input
        , '');

        const [updatedOcrItem, newOcrItem] = await createOcrItems([{
          doc: updatedDoc,
          pages: updatedPages
        }, {
          doc: removedPage.input,
          pages: [removedPage]
        }]);

        const clonedData = _.cloneDeep(ocrData);

        clonedData[editedIndex] = updatedOcrItem;

        clonedData.splice(editedIndex + 1, 0,  newOcrItem);

        dispatch(ocrDataUpdated(clonedData));
      }
    }
  };


  const onEdit = (field: InvVatFields | PayConfFields) => {
    setEditFieldVisible(true);
    setFieldType(field);
  };

  const onFinishEditing = (value: string | null | number | Moment) => {
    if (fieldInputMap.get(fieldType) === 'number'){
      onChangeValue({
        key: fieldType,
        value:_.isString(value) ? convertNumeric(value) : value as number | string,
      });
    } else if (fieldInputMap.get(fieldType) === 'calendar' && !_.isNull(value)){
      onChangeValue({
        key:fieldType,
        value:(moment(value).format('YYYY-MM-DD'))
      });
    } else if (_.isString(value)){
      onChangeValue({
        key: fieldType,
        value,
      });
    }
    setEditFieldVisible(false);
  };

  const onChangeValue = ({
    key,
    value
  }:{
    key: InvVatFields | PayConfFields,
    value: number | string
  }) => {
    if (_.isNumber(editedIndex)){
      const ocrItem = ocrData[editedIndex];
      updateDocResultsByKey({
        key,
        value,
        index: editedIndex,
        ocrItem,
        dispatch,
      });
    }
  };

  const onReset = (key: InvVatFields | PayConfFields) => {
    if (_.isNumber(editedIndex)){
      const ocrItem = ocrData[editedIndex];
      if (isInvcVat(ocrItem.extractedData) &&  isInvVatField(key)){
        dispatch(ocrItemUpdated({
          ocrItem:{
            ...ocrItem,
            correctedDocResults:{
              ...ocrItem.correctedDocResults,
              [key]: ocrItem.extractedData[key]
            }
          },
          index: editedIndex
        }));
      }
      if (isPaymentConfirmation(ocrItem.extractedData) &&  isPayConfField(key)){
        dispatch(ocrItemUpdated({
          ocrItem:{
            ...ocrItem,
            correctedDocResults:{
              ...ocrItem.correctedDocResults,
              [key]: ocrItem.extractedData[key]
            }
          },
          index: editedIndex
        }));
      }
    }
  };

  if (isEditFieldVisible && correctedDocResults){
    const closeEditField = () => {
      setEditFieldVisible(false);
    };

    return (
      <EvidEditField
        onClose={closeEditField}
        pages={pages}
        fieldValue={getDocField(correctedDocResults, fieldType)}
        fieldType={fieldType}
        fieldInputMap={fieldInputMap}
        onFinishEditing={onFinishEditing}
      />
    );
  }

  return (
    <EvidEditForm
      title={correctedDocType === INVC_VAT ?
        createFVTitle(correctedDocResults as InvcVat) :
        createPayConTitle()}
      onClose={onClose}
      onConfirm={onConfirm}
      pages={pages}
      onSplit={onSplit}
    >
      { correctedDocType === INVC_VAT ?      (
        <TgEditInvcVat
          {...{
            results: correctedDocResults as InvcVat,
            onEdit,
            onReset,
            onChangeValue,
            docResults: docResults as InvcVat,
          }}
        />
      ) : (
        <EditPayCon
          {...{
            results: correctedDocResults as PaymentConfirmation,
            onEdit,
            onReset,
            onChangeValue
          }}
        />
      )}
    </EvidEditForm>
  );
};

export default React.memo(EvidEdit);