import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { ENV_TYPE } from './env.json';
import appLoading from './store/appLoading';
import currency from './store/currency';
import edit from './store/edit';
import impExternal from './store/impExternal';
import impOcr from './store/impOcr';
import importEx from './store/importExcel';
import interestForm from './store/interestForm';
import interestFormOptions from './store/interestFormOptions';
import interestStorage from './store/interestStorage';
import justOpts from './store/justOpts';
import lawsuitOpts from './store/lawsuitOpts';
import lawsuitUI from './store/lawsuitUI';
import login from './store/logInReducer';
import saveLawsuitModal from './store/saveLawsuitModal';
import signUp from './store/signUpReducer';
import subscribe from './store/subscribe';
import sumUp from './store/sumUp';
import user from './store/user';

const devEnv = ENV_TYPE === 'DEVELOPMENT';

export const store = configureStore({
  devTools: devEnv,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }),
  reducer: {
    appLoading,
    currency,
    edit,
    importEx,
    interestForm,
    interestFormOptions,
    interestStorage,
    justOpts,
    lawsuitOpts,
    lawsuitUI,
    login,
    saveLawsuitModal,
    signUp,
    subscribe,
    sumUp,
    impExternal,
    impOcr,
    user,
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () =>
  useDispatch<AppDispatch>();

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;