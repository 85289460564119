import { Button, Col, Row, Space } from 'antd';
import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Img } from 'react-image';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import logo from '../../assets/logo.svg';
import useWindowSize from '../../utils/hooks/useWindowSize';
import { AboutContainer, DashedColLeft, GRID_MD, GRID_MD_PX, RowMd } from '../../styles';
import { ScreenBackButton } from '../UI/DescScreenFooter';

const SubHeader = () => {
  const { width } = useWindowSize();
  return (
    <AboutContainer id="subscribe">
      <Row>
        <Col
          md={{
            offset: 1,
            span: 10
          }}
          xs={{
            offset: 2,
            span: 10,
          }}
        >
          <Link
            to={{ pathname: '/' }}
          >
            <Logo
              src={logo}
            />
          </Link>
        </Col>
        <Col
          md={{ span: 10 }}
          xs={0}
        >
          <RowMd align="middle" justify="end">
            <Space>
              <AnchorLink href="#subscribe">
                <Button type="link" href="#subscribe">Subskrypcja</Button>
              </AnchorLink>
              <AnchorLink href="#benefits">
                <Button type="link" href="#benefits">Twoje korzyści</Button>
              </AnchorLink>
              <AnchorLink href="#faq">
                <Button type="link" href="#faq">F.A.Q.</Button>
              </AnchorLink>
            </Space>
          </RowMd>
        </Col>
        <Col
          xl={{ span: 2 }}
          md={{ span: 2 }}
          xs={10}
        >
          <RowMd
            align="middle"
            justify={width >= GRID_MD ? 'center' : 'end'}
          >
            <ScreenBackButton />
          </RowMd>
        </Col>
        <DashedColLeft
          xl={{ span: 1 }}
          xs={0}
        />
      </Row>
    </AboutContainer>
  );
};

export default SubHeader;

const Logo = styled(Img)`
    width: 124px;
    border-radius: 4px;
    margin-top: 24px;
    margin-bottom: 24px;
  @media only screen and (max-width: ${GRID_MD_PX}) {
    margin-bottom: 48px;
	}
`;



