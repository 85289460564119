import { onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { auth } from '../../services/firebase/auth';
import { counterForCalculationsRef } from '../../services/firebase/firestoreRefs';
import { useTypedSelector } from '../../store';

export type CalculationCounter = {
  count: number;
};

const useListenToCalculationsCount = () => {
  const [calcCount, setCalcCount] = useState(0);
  const userExists = useTypedSelector(state =>
    state.user.isNonAnonymousUserLoggedIn);
  useEffect(() => {
    const { currentUser } = auth;
    if (userExists && currentUser) {
      const unsubscribe = onSnapshot(counterForCalculationsRef(currentUser.uid), (snapshot) => {
        const value = snapshot.data() as CalculationCounter;
        setCalcCount(value?.count ?? 0);
      });
      return () => {
        unsubscribe();
      };
    }
    return undefined;
  }, [userExists]);
  return calcCount;
};

export default useListenToCalculationsCount;