import { Alert } from 'antd';
import { motion } from 'framer-motion';
import React from 'react';

const FormAlert = (
  {
    message,
    isOn,
    type
  }: {
    message:string,
    isOn: boolean,
    type?: 'info' | 'success' | 'warning' | 'error'
  }
) => {
  return (
    <motion.div
      transition={{ duration: 0.3 }}
      initial={{
        opacity: !isOn ? 0 : 1, height: !isOn ? 0 : 58,
      }}
      animate={{
        opacity: !isOn ? 0 : 1, height: !isOn ? 0 : 58,
      }}
    >
      <Alert
        message={message}
        type={type ?? 'info'}
      />
    </motion.div>
  );
};

export default FormAlert;
