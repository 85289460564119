import { Divider, Typography } from 'antd';
import React from 'react';

import { RowSm } from '../../../styles';
import { GreyArea } from '../../../styles/ui';
import useJustFinalSum from '../../../utils/hooks/justification/useJustFinalSum';
import useJustHead from '../../../utils/hooks/justification/useJustHead';
import useJustification from '../../../utils/hooks/justification/useJustification';
import AnimOpacity from '../../UI/Anim/AnimOpacity';
import CopyButtonJust from '../../UI/Button/CopyButtonJust';
import JustActions from './JustActions';
import JustDocList from './JustActions/JustDocList';
import JustListOpts from './JustActions/JustListOpts';
import JustBasisPayments from './JustBasisPayments';
import JustCompensation from './JustCompensation';
import JustInterests from './JustInterests';
import JustLsCode from './JustLsCode';
import JustParagraph from './JustParagraph';

const { Title, Text } = Typography;

const Justification = ({  justificationVisible, setJustificationVisible  }:{
  setJustificationVisible:(justificationVisible:boolean)=>void,
  justificationVisible: boolean,
}) => {
  const head = useJustHead();
  const finalSum = useJustFinalSum();
  const justification = useJustification();

  return (
    <div id="justification">
      <Divider />
      <Title
        level={3}
      >
        {texts.title}
      </Title>
      <Text type="secondary">{texts.desc}</Text>
      <JustActions
        {...{
          justificationVisible,
          setJustificationVisible,
        }}
      />
      {justificationVisible ? (
        <AnimOpacity>
          <GreyArea>
            <AnimOpacity>
              <JustParagraph
                text={head}
              />
              <JustParagraph
                text={finalSum}
              />
              <JustBasisPayments />
              <JustInterests />
              <JustCompensation />
              <JustLsCode />
            </AnimOpacity>
          </GreyArea>
          <RowSm justify="end">
            <CopyButtonJust
              value={justification}
            />
          </RowSm>
          <JustListOpts />
          <JustDocList />
        </AnimOpacity>
      ) : null}
    </div>
  );
};


export default Justification;

const texts = {
  title: 'Wygeneruj tekst na bazie wyliczeń',
  desc: 'Wyliczenia przekształcone w tekst.',
};
