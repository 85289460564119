import { CheckOutlined, ShareAltOutlined } from '@ant-design/icons';
import { Input, Modal, notification, Row, Space, Typography } from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { auth } from '../../services/firebase/auth';
import { sendToInbox } from '../../services/firebase/firebaseFunctions';
import { AppDispatch } from '../../store';
import { appLoadingStarted, appLoadingStopped } from '../../store/appLoading';
import { RowSm } from '../../styles';
import { emailRegex } from '../../utils/helpers/regexChecks';


const share = async (
  {
    saveId,
    dispatch,
    email,
    onFinish
  }:
  { saveId: string
    dispatch:AppDispatch,
    email: string,
    onFinish: () => void }) => {
  try {
    const { currentUser } = auth;
    onFinish();
    if (!email){
      notification.error({
        message: 'Brak adresu email',
        description: 'Wpisz właściwy email'
      });
    }
    if (email && !emailRegex.test(email.trim())){
      notification.error({
        message: 'Email jest wadliwie sformatowany',
        description: 'Wpisz właściwy email'
      });
    }
    dispatch(appLoadingStarted('przesyłam dane'));
    if (currentUser && email && emailRegex.test(email.trim())){
      const res  = await sendToInbox({
        receiverEmail:email.trim(),
        saveId
      }) as unknown as { data:{
        title: string,
        message: string,
      } };
      notification.info({
        message: res.data.title,
        description: res.data.message
      });
    }
    dispatch(appLoadingStopped());
  } catch (e){
    dispatch(appLoadingStopped());
  }
};

const ShareSaveModal = (
  {
    closeModal,
    isModalVisible,
    sharedId
  }: {
    closeModal: ()=>void,
    isModalVisible:boolean,
    sharedId: string
  }
) => {
  const [email, setEmail] = useState('');
  const dispatch = useDispatch();

  const onFinish = () => {
    closeModal();
    setEmail('');
  };

  return (
    <Modal
      destroyOnClose={true}
      title="Prześlij innemu użytkownikowi pozew.app"
      visible={isModalVisible}
      onCancel={closeModal}
      onOk={() =>
        share({
          email,
          onFinish,
          saveId: sharedId,
          dispatch,
        })}
      okText={(
        <Row align="middle">
          <Space>
            <CheckOutlined />
            <Typography.Text style={{ color: 'white' }}>
              Prześlij
            </Typography.Text>
          </Space>
        </Row>
      )}
    >
      <Input
        addonBefore={<ShareAltOutlined />}
        placeholder="jan.kowalski@gmail.com"
        value={email}
        onChange={(event) =>
          setEmail(event.target.value)}
        autoFocus
      />
      <RowSm>
        <Typography.Text type="secondary">
          Wpisz adres email, którym użytkownik posługuje się aby
          logować się w pozew.app. Aby to zrobić musisz być wcześniej
          {' '}
          <strong>upoważniony przez odbiorcę</strong>
          {' '}
          do przesyłania danych na jego skrzynkęodbiorczą w pozew.app.
          Możne to zrobić w zakładce
          {' '}
          <strong>Konto & kontakt</strong>
          .
        </Typography.Text>
      </RowSm>
    </Modal>
  );
};


export default ShareSaveModal;
