import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Input, InputNumber, notification } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import _ from 'lodash';
import { Moment } from 'moment';
import React, { useState } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import { useAppDispatch, useTypedSelector } from '../../../store';
import { paymentDataChanged } from '../../../store/interestStorage';
import { isAddPaymentsVisibleUpdate } from '../../../store/lawsuitUI';
import { BLACK, ButtonFullWidth, GRID_SM_PX, RowExt } from '../../../styles';
import Icon from '../../../styles/Icon';
import { disabledStatementDate } from '../../../utils/helpers/date';
import { toAmount, toUnit } from '../../../utils/helpers/money';
import { CalendarFormIcon } from '../../UI/Forms/DueDate';
import StepCard from '../StepCard/StepCard';

const AddPayments = () => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [paymentDate, setPaymentDate] = useState<null | Moment>(null);
  const [statementDate, setStatementDate] = useState<null | Moment>(null);
  const [paymentSum, setPaymentSum] = useState(0);
  const [transferDescription, setTransferDescription] = useState<null | string>(null);

  const paymentData = useTypedSelector(state =>
    state.interestStorage.paymentData);

  const resetState = () => {
    setPaymentDate(null);
    setTransferDescription(null);
    setPaymentSum(0);
  };

  const onClose = () => {
    resetState();
    dispatch(isAddPaymentsVisibleUpdate(false));
  };

  const updateSum = (value:  number | undefined) => {
    if (_.isNumber(value)){
      setPaymentSum(toAmount(value));
    }
  };

  const addPayment = () => {
    const isStatementDateOk = () => {
      if (_.isNull(statementDate)) return true;
      return statementDate.isSameOrAfter(paymentDate);
    };

    if (paymentDate && paymentSum > 0 && isStatementDateOk()){
      const paymentDataCopy  = _.cloneDeep(paymentData);
      const key = uuidv4();
      paymentDataCopy.push({
        key,
        paymentDate,
        paymentSum,
        transferDescription,
        assignedTo: [],
        statementDate,
      });
      resetState();
      dispatch(paymentDataChanged(paymentDataCopy));
      dispatch(isAddPaymentsVisibleUpdate(false));
    } else {
      notification.open({ message: 'Dodaj kwotę i datę wpłaty, żeby móc ją dodać.' });
    }
  };

  return (
    <StepCard
      title="Dodaj wpłatę"
      desc="Po dodaniu przyporządkujesz wpłatę do odpowiedniego roszczenia"
    >
      <Form
        form={form}
        layout="vertical"
      >
        <RowExt align="middle">
          <Col sm={{ span: 11 }} xs={{ span:24 }}>
            <FormItem
              label={(
                <>
                  <CalendarFormIcon />
                  {' '}
                  Data wpłaty
                </>
              )}
            >
              <DatePicker
                allowClear
                format="DD-MM-YYYY"
                style={{ width:'100%' }}
                onChange={(value) =>
                  setPaymentDate(value)}
                value={paymentDate}
                disabledDate={(current) =>
                  disabledStatementDate(current, statementDate)}
              />
            </FormItem>
          </Col>
          <Col sm={{ span: 2 }} xs={{ span:24 }} />
          <Col sm={{ span: 11 }} xs={{ span: 24  }}>
            <FormItem
              label={(
                <>
                  <IconFormIcon
                    height="18"
                    width="18"
                    fill={BLACK}
                    stroke={BLACK}
                    iconType="MONEY_PACK"
                  />
                  Wpłata
                </>
              )}
            >
              <InputNumber
                placeholder="0 zł"
                min={0}
                max={10000000000000}
                precision={2}
                onChange={updateSum}
                style={{ width: '100%' }}
                value={toUnit(paymentSum)}
                decimalSeparator=","
              />
            </FormItem>
          </Col>
          <Col span={1} />
        </RowExt>
        <RowExt align="middle">
          <ColStyled
            sm={{ span: 11 }}
            xs={{ span: 24 }}
          >
            <FormItem
              label={(
                <>
                  <CalendarFormIcon />
                  {' '}
                  Zaliczenie zapłaty (opcjonalnie)
                </>
              )}
            >
              <DatePicker
                allowClear
                format="DD-MM-YYYY"
                style={{ width:'100%' }}
                onChange={(value) =>
                  setStatementDate(value)}
                value={statementDate}
                disabledDate={(current) =>
                  disabledStatementDate(current, paymentDate)}
                disabled
              />
            </FormItem>
          </ColStyled>
          <Col span={2} />
          <Col sm={{ span:  11 }} xs={{ span: 24  }}>
            <FormItem
              label={(
                <>
                  <IconFormIcon
                    height="18"
                    width="18"
                    fill={BLACK}
                    stroke={BLACK}
                    iconType="CONTRACT"
                  />
                  Tytuł wpłaty (opcjonalnie)
                </>
              )}
            >
              <Input
                placeholder="z tytuł..."
                onChange={(event) =>
                  setTransferDescription(event.target.value)}
                style={{ width: '100%' }}
                value={transferDescription ?? ''}
              />
            </FormItem>
          </Col>
        </RowExt>
      </Form>
      <RowExt
        justify="end"
        marginBottom="md"
        marginTop={18}
      >
        <Col
          sm={{
            span:11, order: 1
          }}
          xs={{
            span: 24, order: 3
          }}
        >
          <ButtonResp
            onClick={onClose}
            icon={<CloseOutlined />}
          >
            Anuluj
          </ButtonResp>
        </Col>
        <Col
          sm={{
            span:2, order:2
          }}
          xs={0}
        />
        <Col
          sm={{
            span:11, order:5
          }}
          xs={{
            span: 24, order: 1
          }}
        >
          <ButtonFullWidth
            type="primary"
            size="middle"
            icon={<PlusOutlined />}
            onClick={addPayment}
          >
            Dodaj
          </ButtonFullWidth>
        </Col>
      </RowExt>
    </StepCard>
  );

};

export default AddPayments;

const ColStyled = styled(Col)`
   padding-top: 10px;
   @media only screen and (max-width: ${GRID_SM_PX}) {
    padding-top: 34px;
	}
`;

const ButtonResp = styled(Button)`
    width: 100%;
    @media only screen and (max-width: ${GRID_SM_PX}) {
    margin-top: 24px;
	}
`;

export const IconFormIcon = styled(Icon)`
    margin-right: 4px;
`;
