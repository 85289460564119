import _ from 'lodash';

import { InvcVat } from '../../docTypes';
import { extractAllDocDates, extractAllSums } from '../extractCommon';
import { extractCreateDate } from '../extractCreateDate';
import { getFvFromSlashes, getFvNrFromPrefix, getFvNrFromTitle } from './invcVatAccDoc';
import { getDueDateFV } from './invcVatDueDate';
import { findHighestBrutto } from './invcVatPartialSum';


export const invcVatExtract = (doc: string):InvcVat => {
  const fvNrFromPrefix = getFvNrFromPrefix(doc);
  const fvNrFromTitle = getFvNrFromTitle(doc);
  const fvNroFormSlashes = getFvFromSlashes(doc);
  const allDocDates = extractAllDocDates(doc);
  const createDate = extractCreateDate(doc, allDocDates);
  const dueDate = getDueDateFV(doc, createDate);
  const allSums = extractAllSums(doc);
  const partialSum = findHighestBrutto(allSums);

  const accOpts = [
    fvNrFromTitle,
    fvNrFromPrefix,
    fvNroFormSlashes
  ];

  const accountingDoc = accOpts.find(value =>
    !_.isNull(value)) ?? null;

  const accountingDocOpts  = _.uniq(accOpts.filter(value =>
    value !== null)) as string[];

  const dateFallback = () =>
    dueDate || allDocDates[0];

  return {
    accountingDoc,
    accountingDocOpts,
    allDocDates,
    allSums,
    createDate,
    dueDate: dateFallback() ?? null,
    factualBasis: null,
    partialSum: partialSum ?? allSums[0],
  };
};
