import { ArrowLeftOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Col, Divider, Modal, Typography } from 'antd';
import React from 'react';

import { ButtonFullWidth, EmptyButton, RowExt } from '../../../../styles';

const AssignToOldestModal = ({
  isAssignModalVisible,
  closeModal,
  assignToInterestFirst,
  assignToMain,
}:{
  assignToInterestFirst: () => void,
  assignToMain: () => void,
  closeModal: () => void,
  isAssignModalVisible:boolean,
}) => {
  return (
    <Modal
      onCancel={() =>
        closeModal()}
      visible={isAssignModalVisible}
      destroyOnClose
      style={{ background: 'none !important' }}
      footer={false}
    >
      <RowExt marginTop="md">
        <Typography.Title level={4}>
          Zalicz wszystkie wpłaty na najstarsze długi
        </Typography.Title>
        <Typography.Text>
          Czy w pierwszej kolejności mam zaliczyć na odsetki?
        </Typography.Text>
      </RowExt>
      <RowExt align="middle" justify="center" marginTop="md">
        <Col span={6}>
          <ButtonFullWidth
            type='dashed'
            icon={(
              <ArrowLeftOutlined />
)}
            onClick={closeModal}
          >
            Anuluj
          </ButtonFullWidth>
        </Col>
        <Col span={2}>
          <Divider
            type='vertical'
          />
        </Col>
        <Col span={6}>
          <EmptyButton
            full
            icon={(
              <CloseOutlined />
)}
            onClick={assignToMain}
          >
            Nie
          </EmptyButton>
        </Col>
        <Col span={2}>
          <Divider
            type='vertical'
          />
        </Col>
        <Col span={6}>
          <ButtonFullWidth
            type="primary"
            onClick={assignToInterestFirst}
            icon={
              <CheckOutlined />
            }
          >
            Tak
          </ButtonFullWidth>
        </Col>
      </RowExt>
    </Modal>
  );
};

export default AssignToOldestModal;