import { Typography } from 'antd';
import React from 'react';
import { useAppDispatch } from '../../store';
import { signUpReset } from '../../store/signUpReducer';
import { GRID_LG, RowExt } from '../../styles';
import useWindowSize from '../../utils/hooks/useWindowSize';
import MenuItemWrapper from '../Home/Menu/MenuItemWrapper';
import MenuLogo from '../Home/Menu/MenuLogo';
import AnimOpacity from '../UI/Anim/AnimOpacity';
import DotsEnding from '../UI/DotsEnding';
import SignUpForm from './SignUpForm';
import SignUpMainCont from './SignUpMainCont';
import SignUpNav from './SignUpNav';

const { Title } = Typography;

const SignUpHome = (
  {
    onBack,
    goToLogin
  }: {
    onBack: () => void,
    goToLogin: () => void
  }
) => {
  const dispatch = useAppDispatch();

  const handleOnBack = () => {
    dispatch(signUpReset());
    onBack();
  };

  const handleGoLogin = () => {
    dispatch(signUpReset());
    goToLogin();
  };

  const { height, width } = useWindowSize();

  return (
    <AnimOpacity>
      <SignUpMainCont>
        <MenuLogo />
        <MenuItemWrapper>
          <RowExt
            marginTop={width >= GRID_LG && height <= 800 ? 0 : 'md'}
          >
            <Title level={width >= GRID_LG && height <= 800  ? 2 : 2}>{texts.title1}</Title>
          </RowExt>
          <SignUpForm />
        </MenuItemWrapper>
        <SignUpNav
          onBack={handleOnBack}
          goToLogin={handleGoLogin}
        />
        {width >= GRID_LG && height <= 800 ? (
          <RowExt />
        ) : (
          <MenuItemWrapper>
            <DotsEnding botpadding={10} />
          </MenuItemWrapper>
        )}
      </SignUpMainCont>
    </AnimOpacity>

  );
};

export default SignUpHome;

const texts = { title1: 'Utwórz konto' };