/* eslint-disable no-underscore-dangle */
import axios, { AxiosResponse } from 'axios';
import Holidays from 'date-holidays';
import Dinero from 'dinero.js';
import moment, { Moment } from 'moment';
import * as R from 'ramda';
import { InterestItem } from '../../store/interestStorage';
import { COMPENSATION_BREAK_DATE,
  COMPENSATION_POINT_1,
  COMPENSATION_POINT_2,
  COMPENSATION_VALUE_1,
  COMPENSATION_VALUE_2,
  COMPENSATION_VALUE_3 } from './compensationData';

const hd = new Holidays('PL');

export type CompensationResult = {
  lastWorkingDay: Moment;
  exchangeRate: number;
  valueEuro: number;
  valuePLN: number;
};

type NbpCurrencyRates = {
  mid: number,
};

export type NbpData = {
  rates: NbpCurrencyRates[]
};

type NbpResponse = {
  data: NbpData,
};

const getMaturityDates = (interestUserData: InterestItem[]) =>
  interestUserData.map((value) =>
    moment(value.maturityDate));

const isSunday = (day: Moment) =>
  day.day() === 0;

const isSaturday = (day: Moment) =>
  day.day() === 6;

const isHoliday = (day: Moment) =>
  !!hd.isHoliday(moment(day).toDate());

const lastDayOfMonth = (day: Moment) =>
  moment(day).subtract(1, 'months').endOf('months');

const correctDate = (day: Moment) => {
  const workingDay = day;
  while (
    isSunday(day)
  || isSaturday(day)
  || isHoliday(day)
  ){
    workingDay.subtract(1, 'day');
  }
  return workingDay;

};

const isBefore2015 = (day: Moment) =>
  moment(day).isBefore('2015-12-31');

const getLastWorkingDayOfLastMonth = R.ifElse(
  isBefore2015,
  () => {
    throw new Error('Nie można policzyć rekompensat przed 2015 rokiem');
  },
  R.pipe(lastDayOfMonth,
    correctDate)
);

const getLastWorkingDaysOfLastMonth = (maturityDates: Moment[]) =>
  maturityDates.map(getLastWorkingDayOfLastMonth);

const getExchangeRate = (day: Moment) =>
  axios({
    method: 'get',
    url: `https://api.nbp.pl/api/exchangerates/rates/a/eur/${day.format('YYYY-MM-DD')}/?format=json`,
    responseType: 'stream',
  });

const getExchangeRates = R.map(getExchangeRate);

const isExchangeRateAvailable = R.has('data');

const resolveGetExchangeRates =  async (calls: Promise<AxiosResponse<NbpData>>[]) => {
  const resolvedCalls  = await Promise.all(calls);
  return resolvedCalls.map(
    R.ifElse(
      isExchangeRateAvailable,
      (response) => {
        const nbpResponse = response as NbpResponse;
        return nbpResponse.data.rates[0].mid;
      },
      () => {
        throw new Error("Can't get exchange rate of one of sum");
      }
    )
  );
};

const isAfterYear2019 = (day: Moment) =>
  day.isSameOrAfter(moment(COMPENSATION_BREAK_DATE).startOf('day'));

const isCompensation2Range = R.allPass([
  R.gt(R.__, COMPENSATION_POINT_1),
  R.lt(R.__, COMPENSATION_POINT_2),
]);

const isCompensationValue2 = (day: Moment, partialSum: number) =>
  R.and(isAfterYear2019(day), isCompensation2Range(partialSum));

const isCompensationValue3 = (day: Moment, partialSum: number) =>
  R.and(isAfterYear2019(day), R.gte(partialSum, COMPENSATION_POINT_2));

const getCompensationValueEuro = R.cond([
  [isCompensationValue2, R.always(COMPENSATION_VALUE_2)],
  [isCompensationValue3, R.always(COMPENSATION_VALUE_3)],
  [R.T, R.always(COMPENSATION_VALUE_1)],
]);

const getCompensationResults = ({
  interestUserData,
  lastWorkingDaysOfLastMonth,
  exchangeRates,
}: {
  interestUserData: InterestItem[];
  lastWorkingDaysOfLastMonth: Moment[];
  exchangeRates: number[];
}) =>
  interestUserData.map(({ partialSum, key, maturityDate }, index) => {
    const valueEuro = getCompensationValueEuro(moment(maturityDate), partialSum);
    const exchangeRate = exchangeRates[index];
    return {
      lastWorkingDay: moment(lastWorkingDaysOfLastMonth[index]).startOf('day'),
      valueEuro,
      exchangeRate,
      valuePLN: Dinero({ amount: valueEuro }).multiply(exchangeRate).getAmount(),
      key,
    };
  });

const sumUpCompensationsSum = (compensationResults: CompensationResult[]) => {
  return compensationResults.reduce((acc: number, currentValue: CompensationResult) => {
    return acc + currentValue.valuePLN;
  }, 0);
};

export const calcCompensation = async (interestUserData: InterestItem[]) => {
  const lastWorkingDaysOfLastMonth = R.pipe(
    getMaturityDates,
    getLastWorkingDaysOfLastMonth
  )(interestUserData);

  const exchangeRatesPromises = getExchangeRates(lastWorkingDaysOfLastMonth);

  const exchangeRates: number[] = await resolveGetExchangeRates(exchangeRatesPromises);

  const compensationResults = getCompensationResults({
    interestUserData,
    lastWorkingDaysOfLastMonth,
    exchangeRates,
  });

  return {
    compensationResults,
    compensationSum: sumUpCompensationsSum(compensationResults),
  };
};
