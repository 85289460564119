import { FirebaseError } from '@firebase/util';
import { notification } from 'antd';
import { createUserWithEmailAndPassword, sendEmailVerification, signInWithPopup, User } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import { getDoc, setDoc } from 'firebase/firestore';
import { AppDispatch, RootState } from '../../store';
import { appLoadingStarted, appLoadingStopped } from '../../store/appLoading';
import { saveModalOpened } from '../../store/saveLawsuitModal';
import { signUpErrorReset, signUpFailed, signUpLoadingStarted, signUpReset, signUpSucceeded } from '../../store/signUpReducer';
import { errMsgWithCode } from '../../utils/helpers/errorTexts';
import { auth } from '../firebase/auth';
import { personalDataRef } from '../firebase/firestoreRefs';


/* -------------------------------------------------------------------------- */
/*                                   HELPERS                                  */
/* -------------------------------------------------------------------------- */

export const userExistCheck = async (userId: string) => {
  try {
    const userData = await getDoc(personalDataRef(userId));
    return userData.exists();
  } catch (e) {
    return e;
  }
};

export const createPersonalData = async (userId: string) =>
  setDoc(personalDataRef(userId), { verified: true });



const handleSuccess = ({
  dispatch,
  history,
  returnTo
}:{
  dispatch: AppDispatch;
  history: {
    push: (location: string) => void;
    goBack: () => void;
  },
  returnTo?: string | '/resultLsLocal' | '/lawsuit'
}) => {
  if (returnTo ===  'stay'){
    dispatch(signUpSucceeded());
    dispatch(appLoadingStopped());
  } else {
    dispatch(signUpSucceeded());
    if (returnTo === '/resultLsLocal') history.goBack();
    if (returnTo === '/lawsuit') history.push('/lawsuit');
    else history.push('/dashboard');
    dispatch(appLoadingStopped());
    if (returnTo){
      dispatch(saveModalOpened({
        isSaveModalVisible:true,
        isSaveFromResult: returnTo === '/resultLsLocal' || false,
      }));
    }
  }
};

const handleError = (dispatch: AppDispatch, errorMsg: string) => {
  dispatch(appLoadingStopped());
  dispatch(signUpFailed(errorMsg));
  setTimeout(() => {
    dispatch(signUpErrorReset());
  }, 4000);
};

const handleDbFailure = ({
  dispatch,
  errorCode,
  user,
}:{
  dispatch: AppDispatch;
  errorCode: string;
  user: User;
}) => {
  dispatch(appLoadingStopped());
  handleError(dispatch, errMsgWithCode(errorCode));
  user.delete();
};

/* -------------------------------------------------------------------------- */
/*                                    EMAIL                                   */
/* -------------------------------------------------------------------------- */

export const handleSignUpEmail = ({
  history,
  returnTo,
}: {
  history: {
    push: (location: string) => void;
    goBack: () => void;
  },
  returnTo?: string | '/resultLsLocal' | '/lawsuit'
}) =>
  async (
    dispatch: AppDispatch,
    getState: () => RootState
  ) => {
    const state = getState();

    const { email, password } = state.signUp;

    dispatch(signUpLoadingStarted('email'));
    try {
      const { user } = await createUserWithEmailAndPassword(auth, email, password);
      if (user) {
        dispatch(appLoadingStarted('tworzenie konta'));
        try {
          await sendEmailVerification(user);
          handleSuccess({
            dispatch,
            history,
            returnTo,
          });
          notification.info({
            message: 'Potwierdzenie adresu email',
            description:`Na adres ${ auth.currentUser?.email ?? '' } została wysłana prośba o potwierdzenie maila. Proszę potwierdź email, żeby mieć pełen dostęp do funkcji aplikacji.`,
            duration: 8000,
          });
        } catch (e) {
          handleDbFailure({
            dispatch,
            errorCode:e instanceof FirebaseError ? e.code : 'nieznany',
            user
          });
        }
      } else {
        dispatch(signUpReset());
      }
    } catch (error) {
      if (error instanceof FirebaseError){
        if (error.code === 'auth/email-already-in-use'){
          handleError(dispatch, 'Email jest już używany przez inne konto. Zaloguj się proszę');
        } else {
          handleError(dispatch, errMsgWithCode(error.code));
        }
      }
    }
  };


/* -------------------------------------------------------------------------- */
/*                              EXTERNAL PROVIDER                             */
/* -------------------------------------------------------------------------- */

const google = new firebase.auth.GoogleAuthProvider;
const facebook = new firebase.auth.FacebookAuthProvider;
export const handleSignUpExternalProvider =  ({
  provider,
  history,
  returnTo,
}: {
  provider: 'google' | 'facebook';
  history: {
    push: (location: string) => void;
    goBack: () => void;
  },
  returnTo?: string | '/resultLsLocal' | '/lawsuit'
}) =>
  async (
    dispatch: AppDispatch
  ) => {
    const signUp = () =>
      signInWithPopup(auth, provider === 'facebook' ? facebook : google);
    dispatch(signUpLoadingStarted(provider));
    try {
      const { user } = await signUp();
      if (user){
        dispatch(appLoadingStarted());
        const userExists = await userExistCheck(user.uid);
        if (userExists){
          handleSuccess({
            dispatch,
            history,
            returnTo,
          });
        } else {
          try {
            await createPersonalData(user.uid);
            handleSuccess({
              dispatch,
              history,
              returnTo
            });
          } catch (e){
            handleDbFailure({
              dispatch,
              errorCode: e instanceof FirebaseError ? e.code : 'nieznany',
              user,
            });
          }
        }
      } else {
        dispatch(signUpReset());
      }
    } catch (error){
      if (error instanceof FirebaseError){
        if (error.code === 'auth/popup-closed-by-user'){
          dispatch(signUpReset());
        } else if (error.code === 'auth/cancelled-popup-request'){
          dispatch(signUpReset());
        } else {
          handleError(dispatch, errMsgWithCode(error.code));
        }
      }
    }
  };