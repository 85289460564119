export const BLUE = '#00A8E8';
export const BLUE_HOVER = '#17B0EA';
export const BLUE_MEDIUM = '#007EA7';
export const BLUE_DARK = '#003459';
export const RED = '#E6000B';
export const BLACK = '#141414';
export const BLUE_WHITE = '#FBFEFF';
export const GREY_WHITE = '#F5F5F5';
export const GREY_LIGHT = '#D9D9D9';
export const GREY = '#333333';
export const GREY_BORDER = '#f0f0f0';
export const WARNING_COLOR = '#7F587B';

// https://coolors.co/e6000b-ffffff-00171f-003459-007ea7-00a8e8