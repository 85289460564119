/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash';
import { revertTextFormatting } from '../extractCommon';

import transferDescriptionGr from '../grammars/transferDescriptionGr';
import { feedParser } from '../parser';

export const getPaymentTitle = (doc: string) => {
  const result = feedParser(doc, transferDescriptionGr) as any[] | null;

  if (_.isNull(result)) return null;

  const composedResult =  _.flattenDeep(result[1]).reduce((prev: string, curr:any) => {
    if (curr.type === 'words'){
      return `${prev} ${curr.value}`;
    }
    return prev;
  }, '');

  return revertTextFormatting(composedResult, doc);
};
