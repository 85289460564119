import { CalendarOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  InboxOutlined,
  PlusOutlined,
  SaveOutlined,
  UserOutlined } from '@ant-design/icons';
import { Badge, Button, Card, Col, Divider, Empty, notification, Popconfirm, Row, Space, Typography } from 'antd';
import firebase from 'firebase/compat/app';
import { addDoc, deleteDoc, DocumentData, getDoc, onSnapshot, QuerySnapshot } from 'firebase/firestore';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { InboxItemSaveCopy, InboxItemExternal, InboxItemLocal } from '../../@types';

import { auth } from '../../services/firebase/auth';
import { inboxItemRef, inboxQuery, saveRef } from '../../services/firebase/firestoreRefs';
import { AppDispatch, useTypedSelector } from '../../store';
import { appLoadingStarted, appLoadingStopped } from '../../store/appLoading';
import { impExternalChanged } from '../../store/impExternal';
import { interestAllDataChanged, paymentDataChanged } from '../../store/interestStorage';
import { lawsuitOptsAllUpdated } from '../../store/lawsuitOpts';
import { interestDataProviderUpdated, MANUAL_PAYMENTS_ASSIGNMENTS } from '../../store/lawsuitUI';
import { RowMd } from '../../styles';
import { GREY_WHITE, RED } from '../../styles/colors';
import { formatDate } from '../../utils/helpers/date';
import { errDescGeneral, errMsgGeneral } from '../../utils/helpers/errorTexts';
import { resetLawsuit } from '../../utils/helpers/resetLawsuit';
import { formatFetchedData } from '../../utils/hooks/useGetResultLs';


export const copyInboxItemToSave = async ({
  dispatch,
  inboxId,
}:{
  dispatch: AppDispatch
  inboxId: string,
}) => {
  try {
    dispatch(appLoadingStarted('zapisuję dane'));
    const { currentUser } = auth;
    if (currentUser){
      const inboxDoc = await getDoc(inboxItemRef(currentUser.uid, inboxId));
      if (inboxDoc.exists()){
        const inboxDocData = inboxDoc.data() as InboxItemSaveCopy;
        await addDoc(saveRef(currentUser.uid), {
          ...inboxDocData.data,
          createDate: firebase.firestore.Timestamp.now(),
          editDate: firebase.firestore.Timestamp.now(),
        });
        await deleteDoc(inboxItemRef(currentUser.uid, inboxId));
      }
    }
    dispatch(appLoadingStopped());
    notification.info({ message: 'Dane zostały zapisane.' });
  } catch (e){
    dispatch(appLoadingStopped());
    notification.error({
      message: errMsgGeneral,
      description: errDescGeneral
    });
  }
};

export const deleteInboxItem = async ({
  dispatch,
  inboxId,
}:{
  dispatch: AppDispatch
  inboxId: string,
}) => {
  try {
    dispatch(appLoadingStarted('usuwam dane'));
    const { currentUser } = auth;
    if (currentUser){
      await deleteDoc(inboxItemRef(currentUser.uid, inboxId));
    }
    dispatch(appLoadingStopped());
    notification.info({ message: 'Dane zostały zapisane.' });
  } catch (e){
    dispatch(appLoadingStopped());
    notification.error({
      message: errMsgGeneral,
      description: errDescGeneral
    });
  }
};

export const editInboxItem = async ({
  dispatch,
  inboxId,
  history,
}:{
  dispatch: AppDispatch
  inboxId: string,
  history: {
    push: (location: string) => void;
  },
}) => {
  try {
    dispatch(appLoadingStarted('importuję dane do edycji'));
    const { currentUser } = auth;
    if (currentUser){
      const inboxDoc = await getDoc(inboxItemRef(currentUser.uid, inboxId));
      if (inboxDoc.exists()){
        const inboxDocData = inboxDoc.data() as InboxItemSaveCopy;

        resetLawsuit(dispatch);

        const {
          interestData,
          lawsuitOpts,
          paymentData,
        } = formatFetchedData(inboxDocData.data);

        dispatch(interestAllDataChanged(interestData));
        dispatch(paymentDataChanged(paymentData));

        if (!_.isEmpty(paymentData)){
          dispatch(interestDataProviderUpdated(MANUAL_PAYMENTS_ASSIGNMENTS));
        }

        dispatch(lawsuitOptsAllUpdated(lawsuitOpts));

        history.push('/lawsuit');
      }
    }
    dispatch(appLoadingStopped());
    notification.info({ message: 'Dane zostały zaimportowane.' });
  } catch (e){
    dispatch(appLoadingStopped());
    notification.error({
      message: errMsgGeneral,
      description: errDescGeneral
    });
  }
};

export const importExternalApi = async ({
  dispatch,
  inboxId,
  history,
}:{
  dispatch: AppDispatch
  inboxId: string,
  history: {
    push: (location: string) => void;
  },
}) => {
  try {
    dispatch(appLoadingStarted('importuję dane do edycji'));
    const { currentUser } = auth;
    if (currentUser){
      const inboxDoc = await getDoc(inboxItemRef(currentUser.uid, inboxId));
      if (inboxDoc.exists()){
        const inboxDocData = inboxDoc.data() as InboxItemExternal;

        dispatch(impExternalChanged({
          externalInterestData: inboxDocData.data.externalInterestData,
          externalPaymentData: inboxDocData.data.externalPaymentData,
        }));

        history.push('/importExternal');
      }
    }
    dispatch(appLoadingStopped());
    notification.info({ message: 'Dane zostały zaimportowane.' });
  } catch (e){
    dispatch(appLoadingStopped());
    notification.error({
      message: errMsgGeneral,
      description: errDescGeneral
    });
  }
};

export const createInbox = (snapshot: QuerySnapshot<DocumentData>):InboxItemLocal[] =>
  snapshot.docs.map( value => {
    const inboxItem = value.data() as InboxItemSaveCopy | InboxItemExternal;
    const { createDate, data, type, senderEmail } = inboxItem;

    return {
      key: value.id,
      // @ts-ignore
      recordName: type === 'INBOX_SAVE_COPY' ? data?.recordName : inboxItem.recordName,
      senderEmail,
      createDate: moment(createDate.toDate()),
      type
    };
  });

const InboxItem = ({ inboxItem }:{ inboxItem: InboxItemLocal }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const ActionsSave = [
    <Button
      type="link"
      key="button"
      icon={<PlusOutlined />}
      onClick={() =>
        copyInboxItemToSave({
          dispatch,
          inboxId: inboxItem.key
        })}
    />,
    <Button
      type="link"
      key="button"
      icon={<EditOutlined />}
      onClick={() =>
        editInboxItem({
          dispatch,
          inboxId: inboxItem.key,
          history
        })}
    />,
    <Popconfirm
      key="delete"
      onConfirm={
  () =>
    deleteInboxItem({
      dispatch,
      inboxId: inboxItem.key
    })
}
      title="Usunąć przesłane dane?"
    >
      <Button
        type="link"
        key="button"
        icon={<DeleteOutlined />}
      />
    </Popconfirm>];

  const ActionsExternal = [
    <Button
      type="link"
      key="button"
      icon={<DownloadOutlined />}
      onClick={() =>
        importExternalApi({
          dispatch,
          inboxId: inboxItem.key,
          history
        })}
    />,
    <Popconfirm
      key="delete"
      onConfirm={
  () =>
    deleteInboxItem({
      dispatch,
      inboxId: inboxItem.key
    })
}
      title="Usunąć przesłane dane?"
    >
      <Button
        type="link"
        key="button"
        icon={<DeleteOutlined />}
      />
    </Popconfirm>
  ];

  return (
    <Card
      actions={inboxItem.type === 'INBOX_SAVE_COPY' ? ActionsSave : ActionsExternal}
      style={{
        width: '100%',
        marginBottom: 24
      }}
    >
      <Row>
        <Col
          span={8}
        >
          <Typography.Text strong italic>
            {inboxItem.recordName}
          </Typography.Text>
        </Col>
        <Col
          span={14}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Divider type='vertical' />
          <Space>
            <CalendarOutlined />
            {formatDate(inboxItem.createDate)}
          </Space>
          <Divider type='vertical' />
          <Space>
            <UserOutlined />
            {inboxItem.senderEmail}
          </Space>
        </Col>
      </Row>
    </Card>
  );
};


const DashboardInbox = () => {
  const [data, setData] = useState<InboxItemLocal[] | null>(null);
  const userExists = useTypedSelector(state =>
    state.user.isNonAnonymousUserLoggedIn);

  useEffect(() => {
    const { currentUser } = auth;
    if (userExists && currentUser) {
      const resetComponent = () => {
        setData([]);
      };
      const unsubscribe = onSnapshot(inboxQuery(currentUser.uid), (snapshot:QuerySnapshot<DocumentData>) => {
        if (snapshot.docs){
          setData(createInbox(snapshot));
        } else {
          resetComponent();
        }
      }, err => {
        // eslint-disable-next-line no-console
        console.log(err);
        resetComponent();
      });
      return () => {
        unsubscribe();
        resetComponent();
      };
    }
    return undefined;
  }, [userExists]);
  if (_.isNull(data)) return null;

  return (
    <RowMd>
      <Card
        style={{ width: '100%' }}
        bordered={false}
        extra={<Badge count={data ? data.length : 0} style={{ backgroundColor: data ? RED : GREY_WHITE }} />}
        title={(
          <>
            <InboxOutlined />
            {' '}
            Skrzynka odbiorcza
          </>
)}
      />
      {data ? data.map(item =>
        (
          <InboxItem
            inboxItem={item}
            key={item.key}
          />
        )) : <Empty />}
      <Card
        style={{
          width: '100%', marginBottom: 0
        }}
        bordered={false}
        title={(
          <>
            <SaveOutlined />
            {' '}
            Zapisy wyliczeń
          </>
)}
      />

    </RowMd>
  );
};

export default DashboardInbox;
