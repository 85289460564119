import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Button, Col } from 'antd';
import React from 'react';
import { RowMd } from '../../../styles';
import JustComp from './JustActions/JustComp';
import JustCopy from './JustActions/JustCopy';
import JustIntCap from './JustActions/JustIntCap';
import JustType from './JustActions/JustType';


const JustActions = ({
  justificationVisible,
  setJustificationVisible,
}:{
  setJustificationVisible:(justificationVisible:boolean)=>void,
  justificationVisible: boolean,
}) => {

  return (
    <>
      <RowMd align="middle">
        <Col span={12}>
          <Button
            type='primary'
            icon={justificationVisible ? <DownOutlined /> : <UpOutlined />}
            onClick={() =>
              setJustificationVisible(!justificationVisible)}
          >
            {texts.check}
          </Button>
        </Col>
      </RowMd>
      {justificationVisible ? (
        <>
          <JustType />
          <JustIntCap />
          <JustComp />
          <JustCopy />
        </>
      ) : null}
    </>
  );
};

export default JustActions;

const texts = {
  check: 'Zobacz',
  compensation: 'Uwzględnij w wps rekompensatę',
  compensationOnly: 'Tylko rekompensaty',
};
