import { editDueDateUpdated, editEndDateUpdated, editMaturityDateUpdated, editStartingDateUpdated } from '../../store/edit';
import { dueDateUpdated, endDateUpdated, maturityDateUpdated, startingDateUpdated } from '../../store/interestForm';
import { displayEditInfo, displayFormInfo } from '../../utils/helpers/displayFormInfo';


export const editActions = {
  dueDateUpdated : editDueDateUpdated,
  maturityDateUpdated: editMaturityDateUpdated,
  startingDateUpdated: editStartingDateUpdated,
  endDateUpdated: editEndDateUpdated,
  displayFormInfo: displayEditInfo,
};

export const interestFormActions = {
  dueDateUpdated,
  maturityDateUpdated,
  startingDateUpdated,
  endDateUpdated,
  displayFormInfo,
};
