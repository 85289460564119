import React from 'react';
import nextId from 'react-id-generator';
import { shallowEqual } from 'react-redux';

import { useTypedSelector } from '../../../../store';
import { RowBg } from '../../../../styles';
import AnimOpacity from '../../../UI/Anim/AnimOpacity';
import ImpOcrEvidCard from './ImpOcrEvidCard';


const ImpOcrCardList = ({
  selectedItems,
  setSelected,
  onMerge
}:{
  setSelected:(selectedItems: number[]) =>void
  selectedItems: number[],
  onMerge: (clickedIndex: number) => void
}) => {
  const { ocrData, isListVisible  } = useTypedSelector(state =>
    state.impOcr, shallowEqual);

  if (isListVisible) return null;

  return (
    <RowBg>
      <AnimOpacity>
        {ocrData.map((ocrItem, index) =>
          (
            <ImpOcrEvidCard
              key={nextId()}
              {...{
                index,
                ocrItem,
                selectedItems,
                setSelected,
                onMerge
              }}
            />
          )
        )}
      </AnimOpacity>
    </RowBg>
  );
};

export default ImpOcrCardList;