/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Modal } from 'antd';
import React from 'react';
import { useAppDispatch, useTypedSelector } from '../../store';
import { saveModalClosed } from '../../store/saveLawsuitModal';
import SaveLawsuit from './SaveLawsuit';


const SaveLawsuitModal = () => {
  const dispatch = useAppDispatch();

  const  isSaveModalVisible  = useTypedSelector(state =>
    state.saveLawsuitModal.isSaveModalVisible);

  const userExists = useTypedSelector(state =>
    state.user.isNonAnonymousUserLoggedIn);

  return (
    <Modal
      destroyOnClose={true}
      title={userExists ? 'Zapisz wyilczeń' : 'Zaloguj się najpierw'}
      visible={isSaveModalVisible}
      width={!userExists ? 320 : undefined}
      footer={false}
      onOk={() =>
        null}
      okText={userExists ? 'zapisz' : undefined}
      onCancel={() =>
        dispatch(saveModalClosed())}
    >
      <SaveLawsuit />
    </Modal>
  );
};

export default SaveLawsuitModal;
