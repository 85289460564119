import _ from 'lodash';
import { InterestData, PaymentItem } from '../../../store/interestStorage';
import { AllocatedItems, isAutoAssignDateValid, AUTO_ASSIGN_SUM } from '../autoAssignCommon';



export const allocateFromSum = (paymentItem: PaymentItem,
  interestData: InterestData): AllocatedItems => {
  const ids =   interestData
    .filter(value =>
      value.partialSum === paymentItem.paymentSum
        && isAutoAssignDateValid(paymentItem.paymentDate, value))
    .map(({ key }) =>
      key);

  return {
    interestItemKeys: _.isEmpty(ids) ? null : ids,
    allocationMechanism: AUTO_ASSIGN_SUM
  };
};
