import { ContainerOutlined } from '@ant-design/icons';
import { Col, Divider, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import DashboardActions from '../components/Dashboard/DashboardActions';
import DashboardLsTable from '../components/Dashboard/DashboardLsTable';
import AnimOpacity from '../components/UI/Anim/AnimOpacity';
import PrivateContainer from '../components/UI/Containers/PrivateContainer';
import DotsEnding from '../components/UI/DotsEnding';
import NavPrivate from '../components/UI/Nav/NavPrivate';
import { auth } from '../services/firebase/auth';
import { isEmailVerified } from '../utils/helpers/emailVerified';
import { RowExt } from '../styles';
import VerifyEmail from './VerifyEmail';
import DashboardInbox from '../components/Dashboard/DashboardInbox';



const { Title, Paragraph } = Typography;
const Dashboard = () => {
  const [emailVerified, setEmailVerified] = useState(false);
  useEffect(() => {
    auth.onAuthStateChanged(user => {
      if (user && !user.isAnonymous){
        setEmailVerified(user.emailVerified);
      }
    });
  }, []);
  return (
    <PrivateContainer>
      <div style={{ width:'100%' }}>
        <NavPrivate />
        <AnimOpacity>
          <RowExt marginTop="bg" fullWidth>
            <Col
              lg={{
                offset:0,
                span:24,
              }}
              xs={{
                span: 20, offset:2
              }}
            >
              <div>
                <Title level={2}>
                  <ContainerOutlined />
                  {' '}
                  Panel Użytkownika
                </Title>
                <Paragraph>
                  Dzień dobry ! Zaczynamy pracę ?
                </Paragraph>
              </div>
              <Divider />
              {isEmailVerified() === false   && !emailVerified ? (
                <VerifyEmail
                  {... { setEmailVerified }}
                />
              ) : (
                <div>
                  <DashboardActions />
                  <VerifyEmail
                    {... { setEmailVerified }}
                  />
                  <DashboardInbox />
                  <TableWrapper>
                    <DashboardLsTable />
                  </TableWrapper>
                </div>
              )}
            </Col>
          </RowExt>
        </AnimOpacity>
      </div>
      <DotsEnding />
    </PrivateContainer>
  );
};

export default Dashboard;

const TableWrapper = styled.div`
      /* margin-top: 48px; */
  `;

export const DashboardWrapper = styled.div`
	padding-top: 72px;
  width: 100%;
`;
