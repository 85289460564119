/* eslint-disable no-console */
import { notification } from 'antd';
import { HttpsCallableResult } from 'firebase/functions';
import _ from 'lodash';
import { AppDispatch } from '../store';
import { subscriberStatusFetched, subscriberStatusReset, SubscriptionTypes } from '../store/user';
import { withAppLoadingAndUser } from '../utils/helpers/withAppLoading';
import { subTypes } from '../utils/hooks/useAuthListener';
import { auth } from './firebase/auth';
import { addSubCode, giverRemoveFromSub, receiverRemoveFromSub } from './firebase/firebaseFunctions';


type CFunctionResponseData = {
  title: string,
  message: string,
};

const subscriptionCodeExists = (subscriptionCode: string) =>
  _.isString(subscriptionCode) && subscriptionCode !== '';

export const subscriptionCodeAdd = ({
  dispatch,
  subscriptionCode,
  onFinish
}:{
  dispatch: AppDispatch
  subscriptionCode: string,
  onFinish: () => void,
}) =>
  withAppLoadingAndUser({
    dispatch,
    startLoadingText: 'sprawdzam kod subskrypcji',
    user: auth.currentUser
  }, async (usr) => {
    try {
      if (!subscriptionCodeExists(subscriptionCode)) throw new Error('kod nie został wpisany');
      const response = await addSubCode({ subscriptionCode:subscriptionCode.trim() }) as HttpsCallableResult<CFunctionResponseData>;
      await usr.getIdToken(true);
      const decodedToken = await usr.getIdTokenResult();
      const subscriptionType = decodedToken.claims.stripeRole as SubscriptionTypes;
      const isSubscriber = _.isString(subscriptionType) && subTypes.includes(subscriptionType);
      dispatch(subscriberStatusFetched({
        isSubscriber,
        subscriptionType: isSubscriber ? subscriptionType : null,
      }));
      onFinish();
      notification.info({
        message: response.data.title,
        description:  response.data.message,
      });
    } catch (error) {
      console.log(error);
    }
  });



export const receiverRemoveFromSubscription = (dispatch: AppDispatch) =>
  withAppLoadingAndUser({
    dispatch,
    startLoadingText: 'usuwam powiązanie konta z subskrypcją',
    user: auth.currentUser
  }, async () => {
    try {
      const response = await receiverRemoveFromSub() as HttpsCallableResult<CFunctionResponseData>;
      dispatch(subscriberStatusReset());
      notification.info({
        message: response.data.title,
        description:  response.data.message,
      });
    } catch (error) {
      console.log(error);
    }
  });

export const giverRemoveFromSubscription = async (dispatch: AppDispatch, receiverId: string) =>
  withAppLoadingAndUser({
    dispatch,
    startLoadingText: 'usuwam powiązanie konta z subskrypcją',
    user: auth.currentUser
  }, async () => {
    try {
      const response = await giverRemoveFromSub({ receiverId }) as HttpsCallableResult<CFunctionResponseData>;
      dispatch(subscriberStatusReset());
      notification.info({
        message: response.data.title ?? '',
        description:  response.data.message ?? ''
      });
    } catch (error) {
      console.log(error);
    }
  });
