import { notification } from 'antd';
import _ from 'lodash';
import React, { useState } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { assignPaymentByKey } from '../../../../services/assignPaymentByKey';
import { getSumOfClaimAssignments, isPaymentFullyAssigned } from '../../../../services/calc/claim';
import { useTypedSelector } from '../../../../store';
import { interestDataChanged, InterestItem } from '../../../../store/interestStorage';
import Icon from '../../../../styles/Icon';
import { toAmount } from '../../../../utils/helpers/money';
import { ClaimMain } from './ClaimMain';
import ClaimModal from './ClaimModal';
import FulfilledInterestModal from './FulfilledInterestModal';

export const Claim = ({
  interestItem,
  isMarginTopReq,
  paymentItemKey,
  selectPayment,
  paymentsColors,
}:{
  interestItem: InterestItem
  isMarginTopReq?: boolean,
  paymentItemKey: string | null,
  selectPayment: (paymentItemKey: string | null) => void,
  paymentsColors: Map<string, string>,
}) => {
  const {
    partialSum,
    key: interestItemKey,
    isClaimFromFulfilled,
  } = interestItem;

  const dispatch = useDispatch();

  const [isAssignmentModalVisible, setAssignmentModalVisible] = useState(false);

  const [isFulfilledModalVisible, setFulfilledModalVisible] = useState(false);

  const [areAllSumsWarningVisible, setAreAllSumsWarningVisible] = useState(false);

  const [isInterestFirst, setIsInterestFirst] = useState(true);

  const [assignmentSum, setAssignmentSum] = useState(0);

  const { paymentData, interestData } = useTypedSelector(state =>
    state.interestStorage, shallowEqual);

  const notCoveredSum = partialSum - getSumOfClaimAssignments(paymentData, interestItemKey);

  const onClaimClick = () => {
    if (paymentItemKey){
      const paymentIndex = paymentData.findIndex(({ key }) =>
        paymentItemKey === key);
      const areAllSumsAssigned = isPaymentFullyAssigned(paymentData, paymentIndex);
      if (!_.isNull(areAllSumsAssigned) && areAllSumsAssigned){
        setAreAllSumsWarningVisible(true);
        setAssignmentModalVisible(true);
      } else {
        setAssignmentModalVisible(true);
      }
    }
  };

  const onAssignAll = () => {
    if (paymentItemKey){
      try {
        dispatch(assignPaymentByKey({
          interestItemKey,
          paymentItemKey,
          isInterestFirst,
        }));
        selectPayment(null);
      } catch (error) {
        if (error instanceof Error){
          notification.error({ message:error.message });
        }
      } finally {
        setAssignmentSum(0);
        setAssignmentModalVisible(false);
      }
    }
    setAssignmentModalVisible(false);
  };

  const onAssign = () => {
    if (paymentItemKey && assignmentSum > 0 && assignmentSum <= notCoveredSum){
      try {
        dispatch(assignPaymentByKey({
          interestItemKey,
          paymentItemKey,
          assignmentSum,
          isInterestFirst,
        }));
        selectPayment(null);
      } catch (error) {
        if (error instanceof Error){
          notification.error({ message:error.message });
        }
      } finally {
        setAssignmentSum(0);
        setAssignmentModalVisible(false);
      }
    }
  };

  const updateSum = (value:  number | undefined) => {
    if (_.isNumber(value)){
      setAssignmentSum(toAmount(value));
    }
  };

  const toggleInterestFromFulfilled = () => {
    const it = interestData.findIndex(({ key }) =>
      key === interestItemKey);
    if (it > -1){
      const data = _.cloneDeep(interestData);
      data[it] = {
        ...data[it],
        isClaimFromFulfilled: !isClaimFromFulfilled
      };
      dispatch(interestDataChanged(data));
    }
    setFulfilledModalVisible(false);
  };

  return (
    <>
      <ClaimMain
        {...{
          ...{
            interestItem,
            isMarginTopReq,
            paymentsColors,
            onClaimClick,
            setFulfilledModalVisible,
          }
        }}
      />
      <ClaimModal
        {...{
          areAllSumsWarningVisible,
          assignmentSum,
          isAssignmentModalVisible,
          onAssign,
          onAssignAll,
          setAssignmentModalVisible,
          updateSum,
          isInterestFirst,
          setIsInterestFirst
        }}
      />
      <FulfilledInterestModal
        {...{
          setFulfilledModalVisible,
          toggleInterestFromFulfilled,
          isFulfilledModalVisible
        }}
      />
    </>

  );
};

export const IconStyled = styled(Icon)`
    margin-right: 2px;
    margin-bottom: -2px;
`;
