/* eslint-disable @typescript-eslint/no-explicit-any */
import { notification } from 'antd';
import ExcelJS from 'exceljs';
import _ from 'lodash';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { AppDispatch } from '../store';
import { paymentDataChanged } from '../store/interestStorage';
import { isImportPaymentVisibleUpdated } from '../store/lawsuitUI';
import { errDescGeneral, errMsgGeneral } from '../utils/helpers/errorTexts';
import { getPartialSum } from './importEx';


const checkIfExcelFile = (
  event:React.ChangeEvent<HTMLInputElement>
) => {
  if (event.target &&
    event.target.files &&
    (event.target.files[0].type === 'application/vnd.ms-excel' ||
    event.target.files[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
  ){
    return event.target.files[0];
  }
  throw new Error('Nie mogę znaleźć pliku lub nieprawidłowy plik');
};

export const readExcelPayment = async (
  event:React.ChangeEvent<HTMLInputElement>,
  dispatch: AppDispatch) => {
  const workbook = new ExcelJS.Workbook();
  try {
    const file = checkIfExcelFile(event);
    // @ts-ignore
    await workbook.xlsx.load(file);
    const arr:any = [];
    workbook.worksheets[0].eachRow(row => {
      const { values:rowValues }:any = row;
      const formattedRowValues = rowValues.splice(1);
      const firstCelIsNumber = _.isNumber(formattedRowValues[0]);
      if (firstCelIsNumber){
        const reversedRowValues = _.reverse(formattedRowValues);
        arr.push(reversedRowValues);
      } else {
        arr.push(formattedRowValues);
      }
    });

    const importArray = arr.map((row:any) => {
      return ({
        key:uuidv4(),
        paymentDate: moment(row[0]).endOf('day'),
        paymentSum: getPartialSum(row[1]),
        transferDescription: row[3] ?? null,
        assignedTo: [],
        statementDate: null,
      });
    });
    dispatch(paymentDataChanged(importArray));
    dispatch(isImportPaymentVisibleUpdated(false));
  } catch (e) {
    if (e instanceof Error){
      notification.error({
        message:errMsgGeneral, description:  e.message
      });
    } else {
      notification.error({
        message:errMsgGeneral, description:  errDescGeneral
      });
    }
  }
};
