import { CalculatorOutlined, ContainerOutlined, FilePdfOutlined, NumberOutlined } from '@ant-design/icons';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTypedSelector } from '../../../store';
import { GRID_LG, GRID_MD, RowExt } from '../../../styles';
import { BLACK, BLUE } from '../../../styles/colors';
import { ButtonFullWidth, EmptyButton, LinkFullWidth } from '../../../styles/ui';
import useWindowSize from '../../../utils/hooks/useWindowSize';
import { HomeDescWrapper, HomeMenuWrapper, HomeTitleWrapper } from '../HomeUI/HomeUI';

const MenuStart = () => {
  const userExists = useTypedSelector(state =>
    state.user.isNonAnonymousUserLoggedIn);
  const history = useHistory();

  const { width } = useWindowSize();
  const Buttons = () => {
    if (width < GRID_LG && userExists){
      return (
        <>
          <HomeMenuWrapper>
            <LinkFullWidth
              to={{ pathname: '/lawsuit' }}
            >
              <ButtonFullWidth
                type="primary"
                icon={<CalculatorOutlined />}
              >
                Oblicz & wygeneruj tekst
              </ButtonFullWidth>
            </LinkFullWidth>
          </HomeMenuWrapper>
          <HomeMenuWrapper>
            <LinkFullWidth
              to={{ pathname: '/dashboard' }}
            >
              <EmptyButton
                style={{ width: '100%' }}
                color={BLACK}
                hoverColor="white"
                hoverBg={BLACK}
                icon={<ContainerOutlined />}
              >
                Panel użytkownika
              </EmptyButton>
            </LinkFullWidth>
          </HomeMenuWrapper>
        </>
      );
    }
    return (
      <>
        <HomeMenuWrapper>
          <LinkFullWidth
            to={{ pathname: '/lawsuit' }}
          >
            <ButtonFullWidth
              type="primary"
              icon={<CalculatorOutlined />}
            >
              Oblicz & wygeneruj tekst
            </ButtonFullWidth>
          </LinkFullWidth>
        </HomeMenuWrapper>
        <HomeMenuWrapper>
          <LinkFullWidth
            to={{ pathname: '/ocr' }}
          >
            <EmptyButton
              style={{ width: '100%' }}
              icon={<FilePdfOutlined />}
              dotted
              {...{
                bgColor: 'white',
                color: BLACK,
                hoverColor: 'white',
                hoverBg: BLACK
              }}
            >
              Zmień pdf w tekst pozwu |
              <span style={{
                color: BLUE,
                fontWeight: 700,
                paddingLeft: 6,
              }}
              >
                AI
              </span>
            </EmptyButton>
          </LinkFullWidth>
        </HomeMenuWrapper>
        <HomeMenuWrapper>
          <ButtonFullWidth
            type="dashed"
            icon={<NumberOutlined />}
            onClick={() =>
              history.push('/lsCode')}
          >
            Wpisz KOD WYLICZEŃ
          </ButtonFullWidth>
        </HomeMenuWrapper>
      </>
    );
  };

  return (
    <RowExt marginTop={width < GRID_LG ? 24 : 0}>
      <HomeTitleWrapper
        borderColor={BLUE}
        title={texts.start}
        level={width <= GRID_MD ? 4 : 5}
      />
      <HomeDescWrapper>
        Rozpocznij za darmo -
        {' '}
        <Link
          to={{ pathname: '/guides' }}
        >

          jak to działa ?

        </Link>
      </HomeDescWrapper>
      <Buttons />
    </RowExt>
  );
};

export default MenuStart;

const texts = {
  start: 'Zacznij pracę',
  desc: 'Wybierz opcję',
  button: 'Wyliczenia w tekst',
  button2: 'Załaduj plik z wyliczeniami'
};