import { Radio, Typography } from 'antd';
import React from 'react';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import { justTypeUpdated, JUST_DEM_PAY, JUST_JUDGEMENT, JUST_LS_MAIN } from '../../../../store/justOpts';
import { RowExt } from '../../../../styles';
import { RadioButtonResp } from './JustIntCap';


const JustType = () => {
  const dispatch = useAppDispatch();

  const justType
   = useTypedSelector(state =>
     state.justOpts.justType);

  return (
    <>
      <RowExt marginTop="md">
        <Typography.Title level={4}>
          Wybierz rodzaj dokumentu
        </Typography.Title>
      </RowExt>
      <RowExt marginTop="sm">
        <Radio.Group
          value={justType}
          size="middle"
          onChange={
           (event) => {
             dispatch(justTypeUpdated(event.target.value));
           }
         }
        >
          <RadioButtonResp value={JUST_LS_MAIN}>Uzasadnienie pozwu</RadioButtonResp>
          <RadioButtonResp value={JUST_JUDGEMENT}>Uzasadnienie orzeczenia sądu</RadioButtonResp>
          <RadioButtonResp value={JUST_DEM_PAY}>Wezwanie do zapłaty</RadioButtonResp>
        </Radio.Group>
      </RowExt>
    </>
  );
};

export default JustType;
