import { Radio, RadioChangeEvent } from 'antd';
import _ from 'lodash';
import React, { memo } from 'react';
import { shallowEqual } from 'react-redux';
import styled from 'styled-components';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { principalAmountOptUpdated, principalAmountUpdated, PRINCIPAL_APP, PRINCIPAL_INTEREST, PRINCIPAL_USER } from '../../../store/lawsuitOpts';
import { CardPlaceholder, CardRadio, CardRadioButton, GRID_LG } from '../../../styles';
import { toAmount, toUnit } from '../../../utils/helpers/money';
import useWindowSize from '../../../utils/hooks/useWindowSize';
import StepCard from '../StepCard/StepCard';
import { RadioInput } from './StepOne/StepOneInput';



const MainSumSelect = () => {
  const { width } = useWindowSize();

  const { principalAmountOpt, principalAmount }  = useTypedSelector(state =>
    state.lawsuitOpts, shallowEqual);

  const dispatch = useAppDispatch();

  const onPress = (event:RadioChangeEvent) => {
    switch (event.target.value) {
      case PRINCIPAL_USER:
        dispatch(principalAmountOptUpdated(event.target.value));
        break;
      case PRINCIPAL_INTEREST:
        dispatch(principalAmountOptUpdated(event.target.value));
        break;
      case PRINCIPAL_APP:
        dispatch(principalAmountOptUpdated(event.target.value));
        break;
      default:
        break;

    }
  };
  const onSumChange = (value: string | number | null | undefined) => {
    if (_.isNumber(value)){
      dispatch(principalAmountUpdated(toAmount(value)));
    }
  };

  if (width < GRID_LG) {
    return (
      <StepCard title={texts.title} desc={texts.desc}>
        <Radio.Group
          onChange={onPress}
          value={principalAmountOpt}
          style={{ width: '100%' }}
        >
          <CardRadioButton value={PRINCIPAL_APP}>
            {texts.radio1}
          </CardRadioButton>
          <Button2Lines value={PRINCIPAL_USER}>
            {texts.radio2}
          </Button2Lines>
        </Radio.Group>
        {principalAmountOpt === PRINCIPAL_USER ? (
          <RadioInput
            min={0}
            max={10000000000000}
            precision={2}
            defaultValue={principalAmount}
            onChange={onSumChange}
            decimalSeparator=","
            placeholder={texts.placeholder}
          />
        ) : null}
      </StepCard>
    );
  }

  return (
    <StepCard title={texts.title} desc={texts.desc}>
      <Radio.Group
        onChange={onPress}
        value={principalAmountOpt}
      >
        <CardRadio value={PRINCIPAL_APP}>
          {texts.radio1}
        </CardRadio>
        <CardRadio value={PRINCIPAL_INTEREST}>
          {texts.radio3}
        </CardRadio>
      </Radio.Group>
      {principalAmountOpt === PRINCIPAL_USER ? (
        <RadioInput
          min={0}
          max={10000000000000}
          precision={2}
          defaultValue={toUnit(principalAmount)}
          onChange={onSumChange}
          decimalSeparator=","
          placeholder={texts.placeholder}
        />
      ) : null}
      <CardPlaceholder />
    </StepCard>
  );
};


export default memo(MainSumSelect);

const texts = {
  title: 'Roszczenie główne pozwu',
  desc: 'Wybierz opcję',
  radio1: 'Policz sumę kwot składowych',
  radio2: 'Sama/sam wyliczyłem sumę kwot składowych, policz jedynie odsetki',
  radio3: 'Dochodzę jedynie skapitalizowanych odsetek jako roszczenia głównego',
  placeholder: 'Wysokość odsetek',
};


const Button2Lines = styled(CardRadioButton)`
  	@media only screen and (max-width: 6000px) {
      padding-top: 34px;
     padding-bottom: 34px;
	}
`;