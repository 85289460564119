import { FileDoneOutlined } from '@ant-design/icons';
import _ from 'lodash';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { createOcrItems } from '../../../services/ML/createOcrItems';
import { useTypedSelector } from '../../../store';
import { DocPage, ocrDataUpdated, OcrItem } from '../../../store/impOcr';
import ImpCard from '../ImpCard';
import ImpOcrCardList from './ImpOrcEvidences/ImpOcrCardList';
import ImpOcrEvidBar from './ImpOrcEvidences/ImpOcrEvidBar';
import ImpOcrList from './ImpOrcEvidences/ImpOcrList';

const ImpOcrEvidences = () => {
  const dispatch = useDispatch();

  const [selectedItems, setSelected] = useState<number[]>([]);

  const ocrData  = useTypedSelector(state =>
    state.impOcr.ocrData);

  const onMerge = async (clickedIndex: number) => {
    const updatedItem = ocrData[clickedIndex];
    const mergedItems = ocrData.filter((v, i) =>
      selectedItems.some(value =>
        value === i));

    const pagesList = mergedItems.reduce((prev:DocPage[], curr) => {
      return [...prev, ...curr.pages];
    }, []);

    const updatedPages = [...updatedItem.pages, ...pagesList ].map((v, i) => {
      return ({
        ...v,
        pageNumber: i + 1
      });
    });

    const updatedDoc =  updatedPages.reduce((prev, curr) =>
      prev + curr.input
    , '');

    const [updatedOcrItem] = await createOcrItems([{
      doc: updatedDoc,
      pages: updatedPages
    }]);

    const clonedData = _.cloneDeep(ocrData);

    clonedData[clickedIndex] = updatedOcrItem;

    const newOcrData = clonedData.reduce((prev: OcrItem[], curr, index) => {
      if (selectedItems.some(v =>
        index === v)){
        return prev;
      }
      return [
        ...prev,
        curr,
      ];
    }, []);


    dispatch(ocrDataUpdated(newOcrData));
    setSelected([]);
  };

  return (
    <ImpCard
      title="Skontroluj listę dowodów i dane"
      icon={<FileDoneOutlined />}
      desc={`Sprawdź czy dany dokument został zakwalifikowany prawidłowo oraz czy nastąpiła właściwa ekstrakcja danych.
      Zmieniaj zaimportowane dane według potrzeb.`}
    >
      <ImpOcrEvidBar />
      <ImpOcrCardList
        setSelected={setSelected}
        selectedItems={selectedItems}
        onMerge={onMerge}
      />
      <ImpOcrList
        setSelected={setSelected}
        selectedItems={selectedItems}
        onMerge={onMerge}
      />
    </ImpCard>
  );
};

export default ImpOcrEvidences;
