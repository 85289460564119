import { toUnit } from '../../utils/helpers/money';

// § 2 2)Stawki minimalne wynoszą przy wartości przedmiotu sprawy:
// 1) do 500 zł - 90 zł;
// 2) powyżej 500 zł do 1500 zł - 270 zł;
// 3) powyżej 1500 zł do 5000 zł - 900 zł;
// 4) powyżej 5000 zł do 10 000 zł - 1800 zł;
// 5) powyżej 10 000 zł do 50 000 zł - 3600 zł;
// 6) powyżej 50 000 zł do 200 000 zł - 5400 zł;
// 7) powyżej 200 000 zł do 2 000 000 zł - 10 800 zł;
// 8) powyżej 2 000 000 zł do 5 000 000 zł - 15 000 zł;
// 9) powyżej 5 000 000 zł - 25 000 zł.



export const getPaymentsG = (WpS:number) => {
  const wps = toUnit(WpS);
  if (wps <= 500) return 90;
  if (wps > 500 && wps <= 1500) return 270;
  if (wps > 1500 && wps <= 5000) return 900;
  if (wps > 5000 && wps <= 10000) return 1800;
  if (wps > 10000 && wps <= 50000) return 3600;
  if (wps > 50000 && wps <= 200000) return 5400;
  if (wps > 200000 && wps <= 2000000) return 10800;
  if (wps > 2000000 && wps <= 5000000) return 15000;
  return 25000;
};

// 1. W sprawach rozpoznawanych w postępowaniu upominawczym, elektronicznym postępowaniu upominawczym, postępowaniu nakazowym oraz europejskim postępowaniu nakazowym stawki minimalne wynoszą przy wartości przedmiotu sprawy:
// 1) do 500 zł - 60 zł;
// 2) powyżej 500 zł do 1500 zł - 180 zł;
// 3) powyżej 1500 zł do 5000 zł - 600 zł;
// 4) powyżej 5000 zł do 10 000 zł - 1200 zł;
// 5) powyżej 10 000 zł do 50 000 zł - 2400 zł;
// 6) powyżej 50 000 zł do 200 000 zł - 3600 zł;
// 7) powyżej 200 000 zł - 7200 zł.

export const getPaymentU = (WpS:number) => {
  const wps = toUnit(WpS);
  if (wps <= 500) return 60;
  if (wps > 500 && wps <= 1500) return 180;
  if (wps > 1500 && wps <= 5000) return 600;
  if (wps > 5000 && wps <= 10000) return 1200;
  if (wps > 10000 && wps <= 50000) return 2400;
  if (wps > 50000 && wps <= 200000) return 3600;
  return 7200;
};