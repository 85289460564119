import 'firebase/compat/analytics';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import { getFirestore } from 'firebase/firestore';
import { API_KEY, APP_ID, AUTH_DOMAIN, MEASUREMENT_ID, MESSEGING_SENDER_ID,
  // DB_URL,
  PROJECT_ID,
  STORAGE_BUCKET } from './env.json';

export const firebaseConfig = {
  apiKey: API_KEY,
  authDomain: AUTH_DOMAIN,
  // databaseURL: DB_URL,
  projectId: PROJECT_ID,
  storageBucket: STORAGE_BUCKET,
  messagingSenderId: MESSEGING_SENDER_ID,
  appId: APP_ID,
  measurementId: MEASUREMENT_ID,
};

const app = firebase.initializeApp(firebaseConfig);

export default app;

export const db = getFirestore();


