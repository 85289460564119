import { DownloadOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import folderCover from '../../assets/folder-cover.png';
import { LINK_FOLDER_GENERAL } from '../../utils/helpers/links';
import useWindowSize from '../../utils/hooks/useWindowSize';
import { AboutContainer, BLUE, DashedColLeft, EmptyButton, RowExt, RowMd } from '../../styles';
import { BLACK, BLUE_MEDIUM, RED } from '../../styles/colors';
import Icon from '../../styles/Icon';
import { BALANCE, CLOCK, MONEY_PACK, RELOAD } from '../../styles/iconTypes';
import { CoverWrapper, FolderCover } from './AboutCodes';
import AbtTitle from './AbtTitle';
import FaqParagraph from './FAQ/FaqParagraph';

const Benefits = () => {
  const { width } = useWindowSize();
  return (
    <AboutContainer>
      <Row id="benefits">
        <Col
          lg={{
            offset: 1,
            span: 8,
          }}
          md={{
            offset: 4,
            span: 16
          }}
          xs={{
            offset: 2,
            span: 20
          }}
        >
          <CoverWrapper>
            <Row justify="center">
              <a href={LINK_FOLDER_GENERAL} target="_blank" rel="noreferrer">
                <StyledFolderCover
                  src={folderCover}
                />
              </a>
            </Row>
          </CoverWrapper>
          {width <= 1240 ? (
            <>
              <RowMd justify="center">
                <a href={LINK_FOLDER_GENERAL} target="_blank" rel="noreferrer">
                  <Button icon={<DownloadOutlined />} type='primary'>
                    Pobierz folder
                  </Button>
                </a>
              </RowMd>
              <RowExt justify="center" marginTop={24} marginBottom={48}>
                <Link to="intro">
                  <EmptyButton
                    color={BLUE}
                    bgColor="white"
                    hoverBg={BLUE}
                    hoverColor="white"
                  >
                    Umów się na DARMOWE WPROWADZENIE
                  </EmptyButton>
                </Link>
              </RowExt>
            </>
          ) : null}
        </Col>
        <Col
          lg={{
            span: 10, offset: 1
          }}
          xs={{
            offset: 2,
            span: 20
          }}
        >
          <AbtTitle
            title={(
              <>
                Co zyskasz korzystając z pozew.app?
              </>
            )}
            desc=""
          />
          <RowExt marginTop={48} />
          <FaqParagraph
            title={(
              <>
                <Icon
                  iconType={CLOCK}
                  height="20"
                  width="20"
                  fill={BLACK}
                  style={{ marginRight:12 }}
                />
                MINUTY PRACY ZAMIAST GODZIN
              </>
              )}
            desc="Zamiast spędzać czas na wyliczeniach, na ich przeklejaniu, sprawdzaniu poświęć czas na czynności zawodowe przynoszące Ci zysk."
            color={RED}
          />
          <FaqParagraph
            title={(
              <>
                <Icon
                  iconType={BALANCE}
                  height="20"
                  width="20"
                  fill={BLACK}
                  style={{ marginRight:12 }}
                />
                PRZYSPIESZ WYDAWANIE ORZECZEŃ - KODY WYLICZEŃ
              </>
              )}
            desc={(
              <>
                Wesprzyj przyspieszenie wydawania orzeczeń w Twojej sprawie.
                {' '}
                <AnchorLink href="#codes">
                  <a href="#codes">Dowiedz się więcej</a>
                </AnchorLink>
                .
              </>
)}
            color={BLUE_MEDIUM}
          />
          <FaqParagraph
            title={(
              <>
                <Icon
                  iconType={MONEY_PACK}
                  height="20"
                  width="20"
                  fill={BLACK}
                  style={{ marginRight:12 }}
                />
                KORZYŚĆ DLA CIEBIE I TWOJEGO KLIENTA
              </>
              )}
            desc="Większość pełnomocników nie kapitalizuje odsetek, gdyż jest to bardzo uciążliwe. Pozew.app pozwala na bezbolesne robienie tego w stosunku do każdego roszczenia. Klient na tym skorzysta, a Ty na jego lepszej opinii o Tobie."
            color={BLUE}
          />
          <FaqParagraph
            title={(
              <>
                <Icon
                  iconType={RELOAD}
                  height="20"
                  width="20"
                  fill={BLACK}
                  style={{ marginRight:12 }}
                />
                ZAWSZE WRÓĆ DO WYLICZEŃ
              </>
              )}
            desc="Przechowuj swoje dane wyliczeń na Twoim koncie i miej błyskawiczną możliwość ich zmiany. Nie dopuszczaj do sytuacji, gdy np. składając załączniki do pozwu zorientowałeś się, że źle przepisałeś kwoty i wszystkie wyliczenia musisz wykonać na nowo, a zapisy poprawić."
            color={RED}
          >
            {width > 1241 ? (
              <RowMd justify="space-around">
                <a href={LINK_FOLDER_GENERAL} target="_blank" rel="noreferrer">
                  <Button icon={<DownloadOutlined />} type='primary'>
                    Pobierz folder
                  </Button>
                </a>
                <Link to="intro">
                  <EmptyButton
                    color={BLUE}
                    bgColor="white"
                    hoverBg={BLUE}
                    hoverColor="white"
                  >
                    Umów się na DARMOWE WPROWADZENIE
                  </EmptyButton>
                </Link>
              </RowMd>
            ) : null}
          </FaqParagraph>
        </Col>
        <Col
          xl={{ span: 2 }}
          lg={{ span: 1 }}
          xs={0}
        />
        <DashedColLeft
          xl={{ span: 2 }}
          lg={{ span: 3 }}
          xs={0}
        />
      </Row>
    </AboutContainer>
  );
};

export default Benefits;


const StyledFolderCover = styled(FolderCover)`
    margin-top: 52px;
`;
