/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash';
import React, { memo } from 'react';
import { shallowEqual } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { setStartingEnd } from '../../../../services/setDates';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import { currencyIsVisibleChanged } from '../../../../store/currency';
import { interestFormReset, partialSumUpdated } from '../../../../store/interestForm';
import { infoUpdated } from '../../../../store/interestFormOptions';
import { interestItemAdded } from '../../../../store/interestStorage';
import { toAmount, toUnit } from '../../../../utils/helpers/money';
import StarEndPartialSum from '../../../UI/Forms/StarEndPartialSum';

const InterestFormPartialSum = () => {

  const {
    startingDate,
    endDate,
    partialSum,
    maturityDate,
    legalBasis,
    dueDate,
  } = useTypedSelector(state =>
    state.interestForm, shallowEqual);

  const {
    sameEndDate,
    isSameEndDate,
    isStartingFromDue,
    maturityDateOpts,
  } = useTypedSelector(state =>
    state.interestFormOptions, shallowEqual);
  const  compensationOn
    = useTypedSelector(state =>
      state.lawsuitOpts.compensationOn);

  const dispatch = useAppDispatch();

  const  onDateChange = (value: any) => {
    setStartingEnd({
      value,
      dispatch,
      isSameEndDate,
      isStartingFromDue,
      maturityDateOpts,
      isEdit: false,
    });
  };

  const onSumChange = (value: string | number | null | undefined) => {
    if (_.isNumber(value)){
      dispatch(partialSumUpdated(toAmount(value)));
    }
  };

  const onNextButtonPress = () => {
    if (startingDate
      && (endDate || sameEndDate)
      && partialSum
      && dueDate
      && (compensationOn ? maturityDate : true)
    ){
      dispatch(interestItemAdded({
        key: uuidv4(),
        startingDate,
        isClaimFromFulfilled: false,
        dueDate,
        // @ts-ignore
        endDate: isSameEndDate ? sameEndDate : endDate,
        partialSum,
        legalBasis,
        maturityDate,
      }));
      dispatch(interestFormReset());
    } else {
      dispatch(infoUpdated({
        info: true,
        infoMsg: 'Aby dodać, wpisz co najmniej: termin spełnienia świadczenia, okres naliczania odsetek i kwotę.'
      }));
      setTimeout(() => {
        dispatch(infoUpdated({
          info: false,
          infoMsg: ''
        }));
      }, 5000);
    }
  };


  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onNextButtonPress();
    }
  };

  const handleCurrencyClick = () => {
    dispatch(currencyIsVisibleChanged(true));
  };

  const ed = isSameEndDate ? sameEndDate : endDate;

  return (
    <StarEndPartialSum
      {...{
        startingDate,
        endDate: ed,
        partialSum: partialSum ? toUnit(partialSum) : null,
        dueDate,
        onDateChange,
        onSumChange,
        disableSecond:isSameEndDate,
        onKeyDown: handleKeyDown,
        onCurrencyClick: handleCurrencyClick
      }}
    />
  );
};



export default memo(InterestFormPartialSum);
