/* eslint-disable max-len */
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Col, Input } from 'antd';
import { Meta } from 'antd/lib/list/Item';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { subscriptionCodeAdd } from '../../services/connectedUsers';
import { useAppDispatch, useTypedSelector } from '../../store';
import { GRID_SM, GRID_SM_PX, RowExt } from '../../styles';
import useWindowSize from '../../utils/hooks/useWindowSize';
import AnimOpacity from '../UI/Anim/AnimOpacity';
import UserSubBasic from './UserSubscriptionCard/UserSubBasic';
import UserSubConnected from './UserSubscriptionCard/UserSubConnected';
import UserSubPremium from './UserSubscriptionCard/UserSubPremium';
import { UserSubCardStyle } from './UserSubscriptionCard/UserSubscriptionCard';
import UserSubStandard from './UserSubscriptionCard/UserSubStandard';


const UserSubscription = () => {
  const { width } = useWindowSize();
  const subscriptionType = useTypedSelector(state =>
    state.user.subscriptionType);
  const dispatch = useAppDispatch();

  const [subscriptionCode, setCode] = useState('');

  const history = useHistory();

  const [isCodeInputVisible, setCodeInputVisible] = useState(false);

  const onCloseInput = () => {
    setCode('');
    setCodeInputVisible(false);
  };

  const onCodeAdd = () => {
    subscriptionCodeAdd({
      dispatch,
      subscriptionCode,
      onFinish: onCloseInput
    });
  };

  if (subscriptionType === 'basic'){
    return <UserSubBasic />;
  }

  if (subscriptionType === 'premiumUser' || subscriptionType === 'standardUser'){
    return <UserSubConnected />;
  }

  if (subscriptionType === 'standard'){
    return <UserSubStandard />;
  }

  if (subscriptionType === 'premium'){
    return <UserSubPremium />;
  }

  return (
    <UserSubCardStyle
      title="Subskrypcja"
      actions={isCodeInputVisible ? [
        <AnimOpacity key="1">
          <RowExt>
            <Col xs={{
              span: 16, offset:2
            }}
            >
              <Input.Search
                key="1"
                placeholder="Wpisz kod subskrypcji ..."
                enterButton={(
                  <>
                    <CheckOutlined />
                    {' '}
                    Dodaj
                  </>
)}
                allowClear
                onChange={(event) =>
                  setCode(event.target.value)}
                value={subscriptionCode}
                onSearch={onCodeAdd}
              />
            </Col>
            <Col xs={{
              span:2, offset:2
            }}
            >
              <Button
                type='dashed'
                icon={<CloseOutlined />}
                onClick={onCloseInput}
              />
            </Col>
          </RowExt>
        </AnimOpacity>
      ] : [
        <Button
          type="link"
          key="button"
          onClick={() =>
            history.push('/subscribe')}
        >
          {width <= GRID_SM ? 'Subskrybuj' : 'Subskrybuj - zobacz korzyści'}
        </Button>,
        <Button
          type="link"
          key="button"
          onClick={() =>
            setCodeInputVisible(true)}
        >
          {width <= GRID_SM ? 'Wpisz kod' : 'Wpisz kod subskrypcji'}
        </Button>,
      ]}
    >
      <Meta
        title="Nie jesteś jeszcze subskrybentem pozew.app"
        description={<Desc>Utrzymanie pozew.app wymaga zasobów, w tym pieniędzy. Jeśli lubisz pozew.app, oszczędza ona Twój czas/pomaga zarabiać pieniądze, rozważ regularne wsparcie. Bez tego trwanie pozew.app - w długiej perspektywie - będzie bardzo trudne.</Desc>}
      />
    </UserSubCardStyle>

  );
};

export default UserSubscription;

export const Desc = styled.span`
  	@media only screen and (max-width: ${GRID_SM_PX}) {
    font-size: 12px;
	}
`;