import { SaveOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import ButtonSpinner from '../Spinner/ButtonSpinner';

const SaveButton = (
  {
    onClick,
    loading,
    saveAs
  }: {
    onClick: ()=> void,
    loading: boolean,
    saveAs?: boolean
  }
) => {

  if (loading && !saveAs){
    return (
      <div style={{
        width: 88,
        height: 32,
        paddingRight: 20,
        paddingTop: 6,
      }}
      >
        <ButtonSpinner />
      </div>
    );
  }

  if (loading && saveAs){
    return (
      <div style={{
        width: 134,
        height: 32,
        paddingLeft: 28,
        paddingTop: 6,
      }}
      >
        <ButtonSpinner />
      </div>
    );
  }

  return (
    <Button
      onClick={onClick}
      type="primary"
      disabled={loading}
      icon={<SaveOutlined />}
    >
      {saveAs ?  'Zapisz jako...' : 'Zapisz'}
    </Button>
  );
};

export default SaveButton;