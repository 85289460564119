import React from 'react';
import nextId from 'react-id-generator';
import { shallowEqual } from 'react-redux';

import { useTypedSelector } from '../../../store';
import { JUST_WO_CAP } from '../../../store/justOpts';
import { PartialResult } from '../../../store/sumUp';
import { justPaymentOutro, justPaymentsSumUp } from '../../../utils/hooks/justification/justPayments';
import { basisPaymentsIntro } from '../../../utils/hooks/justification/useJustBasisPayments';
import JustAssignedPayments from './JustAssignedPayments';
import JustParagraph from './JustParagraph';
import JustCoveredInterests from './JustCoveredInterests';

const JustBasisPayments = () => {
  const { justIntCap, justToPaymentDay } = useTypedSelector(state =>
    state.justOpts, shallowEqual);
  const  typeOfInterest  = useTypedSelector(state =>
    state.sumUp.lawsuitOpts.typeOfInterest);
  const  principalAmountOpt  = useTypedSelector(state =>
    state.sumUp.lawsuitOpts.principalAmountOpt);
  const  lawsuitOpts  = useTypedSelector(state =>
    state.sumUp.lawsuitOpts);
  const partialResults = useTypedSelector(state =>
    state.sumUp.partialResults);
  if (principalAmountOpt === 'PRINCIPAL_INTEREST' || principalAmountOpt === 'PRINCIPAL_USER_OPTION'){
    return null;
  }
  return (
    <>
      {partialResults.map((result:PartialResult, index) => {
        if (result.maturityDate){
          return (
            <>
              {/* -------------------------------- 1. intro -------------------------------- */}
              <JustParagraph
                key={nextId()}
                text={basisPaymentsIntro({
                  result,
                  justToPaymentDay,
                  typeOfInterest,
                  noCap: justIntCap === JUST_WO_CAP,
                  index,
                  isSingle: partialResults.length === 1,
                })}
              />
              {
              // next points only if there are assigned payments

              /* ------------------------ 2. assigned payments list ----------------------- */
              }
              <JustAssignedPayments
                key={nextId()}
                result={result}
              />
              {/* --------------------------- 3. payments sum up --------------------------- */}
              <JustParagraph
                key={nextId()}
                text={justPaymentsSumUp(result, lawsuitOpts)}
              />
              {/* --------------------------- 4. covered interest --------------------------- */}
              <JustCoveredInterests
                result={result}
              />
              {/* ---------------------------- 5. payments outro --------------------------- */}
              <JustParagraph
                text={justPaymentOutro( result, lawsuitOpts, index)}
              />
            </>
          );
        }
        return null;
      })}
    </>
  );
};

export default JustBasisPayments;

