/* eslint-disable no-lonely-if */
import { Col, Form, Row } from 'antd';
import moment from 'moment';
import React, { memo } from 'react';
import { shallowEqual } from 'react-redux';

import { setDueDate } from '../../../../services/setDates';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import { isBasisVisibleUpdated } from '../../../../store/interestFormOptions';
import BasisButton from '../../../UI/Forms/BasisButton';
import DueDate from '../../../UI/Forms/DueDate';

const InterestFormDueBasis = () => {
  const [form] = Form.useForm();

  const dispatch = useAppDispatch();

  const {
    isStartingFromDue,
    maturityDateOpts,
  } = useTypedSelector(state =>
    state.interestFormOptions, shallowEqual);

  const {
    legalBasis,
    factualBasis,
    accountingDoc,
    startingDate,
    dueDate,
    endDate,
  } = useTypedSelector(state =>
    state.interestForm, shallowEqual);

  const handleDueDate = (value:moment.Moment | null) => {
    setDueDate({
      value,
      dispatch,
      maturityDateOpts,
      isStartingFromDue,
      endDate,
      isEdit: false,
    });
  };

  const onBasisPress = () => {
    dispatch(isBasisVisibleUpdated(true));
  };

  return (
    <Form
      form={form}
      layout="vertical"
    >
      <Row>
        <DueDate
          error={moment(startingDate).toDate() < moment(dueDate).toDate()}
          dueDate={dueDate}
          handleDueDate={handleDueDate}
        />
        <Col sm={{ span:2 }} xs={{ span:0 }} />
        <BasisButton
          basisAvailable={legalBasis || factualBasis || accountingDoc}
          buttonText={legalBasis || factualBasis || accountingDoc ? 'Edytuj' : 'Dodaj (opcjonalne)'}
          setEdit={onBasisPress}
          tooltip="Opcjonalne"
        />
      </Row>
    </Form>
  );
};



export default memo(InterestFormDueBasis);


