/* eslint-disable no-console */
import _ from 'lodash';
import nearley from 'nearley';
import { RGX_LINE_BRAKES, RGX_SPACES_MORE } from './regexExtraction';

export const feedParser = (doc: string, rules: nearley.CompiledRules, log?: string) => {
  const parser = new nearley.Parser(nearley.Grammar.fromCompiled(rules));
  try {
    const formattedDoc = doc
      .toLowerCase()
      .replace(RGX_LINE_BRAKES, ' ')
      .replace(RGX_SPACES_MORE, '');

    parser.feed(formattedDoc);
    const { results } = parser;
    if (log){
      console.log(log.toUpperCase(), results);
    }
    return _.isEmpty(results) ? null : results[0];
  } catch (error) {
    console.log(error);
    return null;
  }
};

