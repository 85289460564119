import React from 'react';
import SubOptsHeader from './SubOptsHeader';

const BasicHeadText = () =>
  (
    <>
      Jeśli aplikacja oszczędzi Ci
      {' '}
      <strong>
        co najmniej
        2 godziny pracy ROCZNIE
      </strong>
      {' '}
      - ten zakup się opłaca
      <strong>*</strong>
    </>
  );

const SubBasicHead = (
  { inCard }: {
    inCard?: boolean
  }
) => {
  return (
    <SubOptsHeader
      inCard={inCard}
      color="#1f1f1f"
    >
      <BasicHeadText />
    </SubOptsHeader>
  );
};

export default SubBasicHead;
