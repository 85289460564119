import _ from 'lodash';
import moment from 'moment';
import { DocParser, findDate } from './DocParser';

import { convertStrToDay, isDate } from './extractCommon';
import { createDatePhrases } from './keywords';
import { getPaymentCreateDateFromName } from './paymentConfirmation/payCreateDate';
import { RGX_LINE_BRAKES, RGX_MOUTHS } from './regexExtraction';


export const extractFromNames = (doc:string) => {
  const parser = new DocParser(doc);
  return parser.extractRelevantWord(findDate, createDatePhrases, 1);
};


export const extractFromNewest = (allDates: string[]) => {
  const sortedDates = _.cloneDeep(allDates).sort((itemA, itemB) =>
    moment(itemA).toDate().getTime() - moment(itemB).toDate().getTime());
  return sortedDates[0] ?? null;
};

export const findFirstDate = (doc:string) => {
  const splittedDoc = doc.replace(RGX_LINE_BRAKES, ' ')
    .split(' ');

  const firstDateIndex = splittedDoc.findIndex((value, index) => {
    const previousWord =  splittedDoc[index - 1] ?? '';
    const nextWord = splittedDoc[index + 1] ?? '';
    if (isDate(value)){
      return true;
    } if (RGX_MOUTHS.some(regex =>
      regex.test(value))
        && previousWord.trim().match(/^[0-9]/g)
        && nextWord.trim().match(/^[0-9]/g)){
      return true;
    }
    return false;
  });

  const dateWithWord =  `${splittedDoc[firstDateIndex - 1]} ${splittedDoc[firstDateIndex]} ${splittedDoc[firstDateIndex + 1]}`;
  const dateWoWord = splittedDoc[firstDateIndex];

  return firstDateIndex < 0  ? null :
    convertStrToDay(RGX_MOUTHS.some(regex =>
      splittedDoc[firstDateIndex].match(regex)) ?
      dateWithWord :
      dateWoWord);
};

export const extractCreateDate = (doc: string, allDates:string[], isPayCon:boolean = false) => {
  const dateFromNames = isPayCon ?  getPaymentCreateDateFromName(doc) : extractFromNames(doc);
  const firstDate = findFirstDate(doc);
  const newestDate = extractFromNewest(allDates);
  if (dateFromNames) return dateFromNames;
  if (firstDate) return firstDate;
  return newestDate;
};
