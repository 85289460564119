import { notification } from 'antd';
import _ from 'lodash';
import { AppDispatch } from '../store';
import * as interestTypes from '../store/lawsuitOpts';
import { InterestTypes, PrincipalAmountOpt, PRINCIPAL_USER } from '../store/lawsuitOpts';
import { lsIncreaseStepDisabled, lsIncreaseStep } from '../store/lawsuitUI';
import { checkIsTransacOrAgree } from '../utils/hooks/useIsTransacOrAgree';
import { logToAnalytics } from './firebase/logToAnalytics';


export const handleAddStep = ({
  dispatch,
  step,
  typeOfInterest,
  agreementInterestRate,
  principalAmountOpt,
  principalAmount,
}:{
  dispatch:AppDispatch,
  step:number,
  typeOfInterest: InterestTypes,
  agreementInterestRate: number,
  principalAmountOpt: PrincipalAmountOpt,
  principalAmount: number,
}) => {
  const addStep = () => {
    dispatch(lsIncreaseStep());
    dispatch(lsIncreaseStepDisabled(false));
  };

  if (agreeInterestBadlyFormatted(typeOfInterest, agreementInterestRate, step)){
    setDisableNextStepNotification({
      dispatch,
      message: texts.error1message,
      description: texts.error1description,
    });
  } else if (principalIsBadlyFormatted({
    principalAmountOpt,
    principalAmount,
    step,
    typeOfInterest,
  })) {
    setDisableNextStepNotification({
      dispatch,
      message: texts.error2message,
      description: texts.error2description,
    });
  } else {
    addStep();
    if (checkIsTransacOrAgree(typeOfInterest) ? step === 4 : step === 3) logToAnalytics('Form_data_added');
  }
};

const agreeInterestBadlyFormatted = (
  typeOfInterest: InterestTypes,
  agreementInterestRate: number,
  step: number
) =>
  typeOfInterest === interestTypes.INTEREST_AGREE
&& (!_.isNumber(agreementInterestRate) || agreementInterestRate <= 0)
&& step === 0;

const principalIsBadlyFormatted = ({
  principalAmountOpt,
  principalAmount,
  typeOfInterest,
  step,
}:{
  principalAmountOpt: PrincipalAmountOpt,
  principalAmount: number,
  typeOfInterest: InterestTypes,
  step: number
}) =>
  principalAmountOpt === PRINCIPAL_USER
&& (!_.isNumber(principalAmount) || principalAmount < 0)
&& (checkIsTransacOrAgree(typeOfInterest) ? step === 3 : step === 2);


const setDisableNextStepNotification = ({
  dispatch,
  message,
  description,
}:{
  dispatch: AppDispatch,
  message: string
  description: string,
}) => {
  dispatch(lsIncreaseStepDisabled(true));
  setTimeout(() => {
    dispatch(lsIncreaseStepDisabled(false));
  }, 4000);
  notification.error({
    message,
    description,
    onClose: () => {
      dispatch(lsIncreaseStepDisabled(false));
    }
  });
};

const texts = {
  error1message: 'Odsetki umowne nie są określone.',
  error1description: 'Określ wysokość odsetek umownych zanim przejdziesz dalej',
  error2message: 'Podaj kwotę sumę kwot składowych',
  error2description: 'Określ sumę kwot składowych prawidłowo zanim przejdziesz dalej',
};
