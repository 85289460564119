import { Form, Input, Typography } from 'antd';
import React from 'react';
import { shallowEqual } from 'react-redux';
import styled from 'styled-components';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { editAccountingDocUpdated, editFactualBasisUpdated, editLegalBasisUpdated } from '../../../store/edit';
import { accountingDocUpdated, factualBasisUpdated, legalBasisUpdated } from '../../../store/interestForm';
import { BLACK } from '../../../styles';
import Icon from '../../../styles/Icon';

const { Paragraph } = Typography;
const { Item } = Form;

const BasisInputs = () => {
  const dispatch = useAppDispatch();

  const  editKey = useTypedSelector(state =>
    state.edit.key);

  const {
    legalBasis,
    factualBasis,
    accountingDoc,
  } = useTypedSelector(state =>
    state.interestForm, shallowEqual);

  const {
    legalBasis: editLegalBasis,
    factualBasis: editFactualBasis,
    accountingDoc: editAccountingDoc,
  } = useTypedSelector(state =>
    state.edit, shallowEqual);



  const  onFactualBasisChange
        = (basis: string) => {
          editKey ?
            dispatch(editFactualBasisUpdated(basis)) :
            dispatch(factualBasisUpdated(basis));
        };
  const  onAccountantDocChange
        = (basis:string) => {
          editKey ?
            dispatch(editAccountingDocUpdated(basis)) :
            dispatch(accountingDocUpdated(basis));
        };
  const  onLegalBasisChange
        = (basis:string) => {
          editKey ?
            dispatch(editLegalBasisUpdated(basis)) :
            dispatch(legalBasisUpdated(basis));
        };

  return (
    <>
      <Form
        layout="vertical"
      >
        <Item label="Roszczenie jest dochodzone z tytułu... (opcjonalne)">
          <Input
            placeholder="umowy najmu z dnia 20.01.2021 roku"
            onChange={(event) =>
              onFactualBasisChange(event.target.value)}
            value={editKey ? editFactualBasis ?? '' : factualBasis ?? ''}
            prefix={(
              <IconInputIcon
                height="18"
                width="18"
                fill={BLACK}
                stroke={BLACK}
                iconType="ANNOUNCEMENT"
              />
            )}
            allowClear
          />
        </Item>
        <Item label="Nazwa dokumentu księgowego (opcjonalne)">
          <Input
            placeholder="faktura Vat nr FV/123/2021"
            onChange={(event) =>
              onAccountantDocChange(event.target.value)}
            value={editKey ? editAccountingDoc ?? '' : accountingDoc ?? ''}
            prefix={(
              <IconInputIcon
                height="16"
                width="16"
                fill={BLACK}
                iconType="CONTRACT"
              />
            )}
            allowClear
          />
        </Item>
        <Item label="Podstawę prawną stanowi... (opcjonalne)">
          <Input
            placeholder="art. 535 Kodeksu Cywinlego"
            onChange={(event) =>
              onLegalBasisChange(event.target.value)}
            value={editKey ? editLegalBasis ?? '' : legalBasis ?? ''}
            prefix={(
              <IconInputIcon
                height="16"
                width="16"
                fill={BLACK}
                iconType="BALANCE"
              />
            )}
            allowClear
          />
        </Item>
      </Form>
      <Paragraph>
        Powyższe dane zostaną umieszczone automatycznie w treści wygenerowanego
        {' '}
        <strong>tekstu uzasadanienia</strong>
        {' '}
        pozwu / orzeczenia / wezwania do zapłaty.
      </Paragraph>
    </>
  );
};

export default BasisInputs;

export const IconInputIcon = styled(Icon)`
    margin-right: 4px;
`;