import { Radio, Space, Typography } from 'antd';
import React from 'react';
import { shallowEqual } from 'react-redux';
import styled from 'styled-components';
import _ from 'lodash';
import { justIntCapUpdated, justToPaymentDayUpdated, JUST_WITH_CAP, JUST_WO_CAP, JUST_WO_INT } from '../../../../store/justOpts';
import { PRINCIPAL_INTEREST } from '../../../../store/lawsuitOpts';
import { GRID_SM_PX, RowExt } from '../../../../styles';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import { JustRadioButton } from '../../../../styles/ui';
import AnimOpacity from '../../../UI/Anim/AnimOpacity';


const JustIntCap = () => {
  const dispatch = useAppDispatch();
  const { justIntCap, justToPaymentDay } = useTypedSelector(state =>
    state.justOpts, shallowEqual);
  const principalAmountOpt = useTypedSelector(state =>
    state.sumUp.lawsuitOpts.principalAmountOpt);

  const paymentsExist = useTypedSelector(state =>
    !_.isEmpty(state.sumUp.paymentData));

  if (principalAmountOpt !== PRINCIPAL_INTEREST){
    return (
      <>
        <RowExt marginTop="md">
          <Typography.Title level={4}>
            Uwzględnij skapitalizowane odsetki
          </Typography.Title>
        </RowExt>
        <RowExt marginTop="sm" align="middle">
          <Space>
            <Radio.Group
              value={justIntCap}
              size="middle"
              onChange={
           (event) => {
             dispatch(justIntCapUpdated(event.target.value));
           }
         }
            >
              <JustRadioButton value={JUST_WITH_CAP}>TAK</JustRadioButton>
              <JustRadioButton value={JUST_WO_CAP} disabled={paymentsExist}>NIE</JustRadioButton>
              <JustRadioButton value={JUST_WO_INT}>BEZ ODSETEK</JustRadioButton>
            </Radio.Group>
          </Space>
        </RowExt>
        {justIntCap === JUST_WO_CAP ? (
          <AnimOpacity>
            <RowExt align="middle" marginTop={24}>
              <RadioDescText>
                okres dochodzenia
              </RadioDescText>
              <Radio.Group
                value={justToPaymentDay}
                size="middle"
                onChange={
           (event) => {
             dispatch(justToPaymentDayUpdated(event.target.value));
           }
         }
              >
                <RadioButtonResp value={true}>DO DNIA ZAPŁATY</RadioButtonResp>
                <RadioButtonResp value={false}>DO DAT KOŃCOWYCH</RadioButtonResp>
              </Radio.Group>
            </RowExt>
          </AnimOpacity>
        )
          : null}
      </>
    );
  }
  return null;
};

export default JustIntCap;


export const RadioDescText = styled(Typography.Text)`
    margin: 0 12px;
    font-weight: 700;
    text-align: center;
`;

export const RadioButtonResp = styled(JustRadioButton)`
  @media only screen and (max-width: ${GRID_SM_PX}) {
      margin-top: 24px;
	}
`;