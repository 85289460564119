import { CalculatorOutlined, NumberOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import codesThumb from '../../../assets/codesThumb.png';
import { YT_CODES } from '../../../utils/helpers/links';
import { BLUE } from '../../../styles/colors';
import GuidePost from '../GuidePost';

const { Paragraph } = Typography;

const GuideCode = () => {
  return (
    <GuidePost
      title="Kody wyliczeń - przyspiesz wydawanie orzeczeń"
      color={BLUE}
      desc={(
        <>
          <Paragraph>
            Udostępnij wyliczenia współpracownikom albo sądowi przesyłając unikalny kod. Wesprzyj szybkość wydawania orzeczeń.
          </Paragraph>
          <Paragraph>
            Wygenerowany kod zawierający dane wyliczeń jest importowany
            <strong> do tekstu uzasadniającego wyliczenia.</strong>
            {' '}
            Z wyliczeń może skorzystać
            {' '}
            <strong>sędzia lub referendarz</strong>
            {' '}
            w prowadzonej przez Ciebie sprawie, aby ją szybciej rozstrzygnąć.
            {' '}
            <strong>Ułatwiaj pracę pracownikom wymiaru sprawiedliwości. </strong>
            Możesz też przesłać kod
            <strong>  przełożonemu/współpracownikowi</strong>
            , żeby mógł przeanalizować/zmienić Twoje wyliczenia.
          </Paragraph>
          <Link to="/lawsuit">
            <Button
              type="link"
              icon={<CalculatorOutlined />}
            >
              Zacznij obliczenia
            </Button>
          </Link>
          <Link to="/lsCode">
            <Button
              type="link"
              icon={<NumberOutlined />}
            >
              Wpisz kod
            </Button>
          </Link>
        </>
      )}
      src={codesThumb}
      url={YT_CODES}
    />
  );
};

export default GuideCode;
