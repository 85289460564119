import { CheckSquareOutlined, CloseSquareOutlined } from '@ant-design/icons';
import { Col, Divider, Modal, Typography } from 'antd';
import React from 'react';

import { BLUE, ButtonFullWidth, RowExt } from '../../../../styles';

const FulfilledInterestModal = ({
  setFulfilledModalVisible,
  toggleInterestFromFulfilled,
  isFulfilledModalVisible
}:{
  toggleInterestFromFulfilled: () => void,
  setFulfilledModalVisible: (isFulfilledModalVisible: boolean) => void,
  isFulfilledModalVisible:boolean,
}) => {
  return (
    <Modal
      onCancel={() =>
        setFulfilledModalVisible(false)}
      visible={isFulfilledModalVisible}
      destroyOnClose
      style={{ background: 'none !important' }}
      footer={false}
    >
      <Typography.Title level={5}>Naliczać odsetki od zaspokojonej części kwoty głównej?</Typography.Title>
      <RowExt align="middle" justify="center" marginTop="md">
        <Col span={11}>
          <ButtonFullWidth
            icon={<CloseSquareOutlined />}
            type="text"
            onClick={toggleInterestFromFulfilled}
          />
        </Col>
        <Col span={2}>
          <Divider
            type='vertical'
          />
        </Col>
        <Col span={11}>
          <ButtonFullWidth
            icon={(
              <CheckSquareOutlined
                style={{ color: BLUE }}
              />
)}
            type="text"
            onClick={toggleInterestFromFulfilled}
          />
        </Col>
      </RowExt>
    </Modal>
  );
};

export default FulfilledInterestModal;