import { shallowEqual } from 'react-redux';
import { formatPLN } from '../../helpers/formatNumber';
import { toUnit } from '../../helpers/money';
import { useTypedSelector } from '../../../store';
import { JUST_JUDGEMENT } from '../../../store/justOpts';

const useJustFinalSum = () => {
  const {
    partialResults,
    finalSum,
  } = useTypedSelector(state =>
    state.sumUp, shallowEqual);
  const { justType } = useTypedSelector(state =>
    state.justOpts, shallowEqual);

  const { principalAmountOpt } = useTypedSelector(state =>
    state.sumUp.lawsuitOpts, shallowEqual);

  const statesAs = justType === JUST_JUDGEMENT ? 'stanowiło' : 'stanowi';

  if (partialResults.length > 1 && principalAmountOpt !== 'PRINCIPAL_INTEREST' && principalAmountOpt !== 'PRINCIPAL_USER_OPTION'){
    let sum = `1. Dochodzone roszczenie (${toUnit(finalSum)?.toFixed(2) ?? 0} zł) ${statesAs} sumę kwot głównych poszczególnych roszczeń wyliczoną w sposób następujący: `;
    partialResults.forEach((result, index) => {
      if (index !== 0){
        sum += ` + ${formatPLN(result.claimSum)}`;
      } else  {
        sum += `${formatPLN(result.claimSum)}`;
      }
    });
    return `\n${sum}.`;
  }
  return '';
};

export default useJustFinalSum;
