import { Col } from 'antd';
import React, { memo, ReactNode } from 'react';

import { useTypedSelector } from '../../store';
import { RowExt } from '../../styles';
import useIsPaymentAssignmentOn from '../../utils/hooks/useIsPaymentAssignmentOn';
import useIsTransacOrAgree from '../../utils/hooks/useIsTransacOrAgree';
import AssignPayments from './AssignPayments/AssignPayments';
import CompensationSelect from './StepCards/CompensationSelect';
import InterestDataProviderSelect from './StepCards/InterestDataProviderSelect';
import InterestForm from './StepCards/InterestForm';
import LsCodesSelect from './StepCards/LsCodesSelect';
import MainSumSelect from './StepCards/MainSumSelect';
import SumUpLs from './StepCards/SumUpLs';
import TypeOfInterest from './StepCards/TypeOfInterest';

const ConditionalStepCard = ({
  noTransacNoAssign,
  transacNoAssign,
  noTransacAssign,
  transacAssign,
}: {
  noTransacNoAssign:ReactNode,
  noTransacAssign: ReactNode,
  transacNoAssign: ReactNode,
  transacAssign: ReactNode,
}) => {
  const isTransacOrAgree = useIsTransacOrAgree();
  const isPaymentAssignmentOn = useIsPaymentAssignmentOn();
  if (!isTransacOrAgree && !isPaymentAssignmentOn){
    return <>{noTransacNoAssign}</>;
  }
  if (isTransacOrAgree && !isPaymentAssignmentOn){
    return <>{transacNoAssign}</>;
  }
  if (!isTransacOrAgree && isPaymentAssignmentOn){
    return <>{noTransacAssign}</>;
  }
  return (
    <>
      {transacAssign}
    </>
  );
};

const StepCards = () => {
  const   step  = useTypedSelector(state =>
    state.lawsuitUI.step);
  const isTransacOrAgree = useIsTransacOrAgree();
  switch (step) {
    case 1:
      return isTransacOrAgree ? <CompensationSelect /> : <InterestDataProviderSelect />;
    case 2:
      return isTransacOrAgree ? <InterestDataProviderSelect /> : <InterestForm />;
    case 3:
      return (
        <ConditionalStepCard
          noTransacAssign={<AssignPayments />}
          noTransacNoAssign={<MainSumSelect />}
          transacAssign={<InterestForm />}
          transacNoAssign={<InterestForm />}
        />
      );
    case 4:
      return (
        <ConditionalStepCard
          noTransacAssign={<MainSumSelect />}
          noTransacNoAssign={<LsCodesSelect />}
          transacAssign={<AssignPayments />}
          transacNoAssign={<MainSumSelect />}
        />
      );
    case 5:
      return (
        <ConditionalStepCard
          noTransacAssign={<LsCodesSelect />}
          noTransacNoAssign={<SumUpLs />}
          transacAssign={<MainSumSelect />}
          transacNoAssign={<LsCodesSelect />}
        />
      );
    case 6:
      return (
        <ConditionalStepCard
          noTransacAssign={<SumUpLs />}
          noTransacNoAssign={<SumUpLs />}
          transacAssign={<LsCodesSelect />}
          transacNoAssign={<SumUpLs />}
        />
      );
    case 7:
      return (
        <SumUpLs />
      );
    default:
      return (
        <TypeOfInterest />
      );
  }
};

const LawsuitDisplay = () => {
  return (
    <RowExt marginTop="bg" fullWidth>
      <Col
        lg={{
          offset:0,
          span:24,
        }}
        xs={{
          offset:2,
          span: 20,
        }}
      >
        <StepCards />
      </Col>
    </RowExt>
  );
};

export default memo(LawsuitDisplay);
