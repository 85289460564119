import { ArrowRightOutlined, CalendarOutlined } from '@ant-design/icons';
import { Button, Checkbox, notification, Space, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import _ from 'lodash';
import { Moment } from 'moment';
import React, { useState } from 'react';
import { shallowEqual } from 'react-redux';
import styled from 'styled-components';

import { useAppDispatch, useTypedSelector } from '../../../store';
import { startingFromDueUpdated } from '../../../store/interestFormOptions';
import { GRID_LG, RowExt, RowMd } from '../../../styles';
import useWindowSize from '../../../utils/hooks/useWindowSize';
import ImpCommMaturityOpts from '../../Import/ImpCommonData/ImpCommMaturityOpts';
import { IMP_OCR } from '../../Import/ImpCommonData/impCommonData';
import DateOptionsActions from './DateOptionsActions';
import DateOptionsCard from './DateOptionsCard';
import { MaturityDatePicker } from './MaturityDatePicker';


const { Text } = Typography;

const DateOptions = () => {
  const { width } = useWindowSize();

  const [isPickerVisible, setIsPickerVisible] = useState(false);
  const [maturityDateLocal, setMaturityDateLocal] = useState<null | Moment>(null);

  const dispatch = useAppDispatch();

  const { isStartingFromDue } = useTypedSelector(state =>
    state.interestFormOptions, shallowEqual);

  const areAllDatesAdded = useTypedSelector(state => {
    const {
      startingDate,
      endDate,
      dueDate,
    } = state.interestForm;
    return !_.isNull(startingDate) && !_.isNull(endDate) && !_.isNull(dueDate);
  }, shallowEqual);

  const setStaringFromDue = (event: CheckboxChangeEvent) => {
    if (event.target.checked){
      dispatch(startingFromDueUpdated(event.target.checked));
    } else {
      dispatch(startingFromDueUpdated(event.target.checked));
    }
  };

  const onSetDatePress = () => {
    if (areAllDatesAdded){
      setIsPickerVisible(true);
    } else {
      notification.open({
        type: 'info',
        message: 'Zanim ustawisz samodzielnie datę wymagalności ustaw dla tej kwoty, datę początkową, końcową i termin spełnienia świadczenia'
      });
    }
  };

  if (isPickerVisible){
    return (
      <MaturityDatePicker
        {...{
          setMaturityDateLocal,
          maturityDateLocal,
          setIsPickerVisible,
        }}
      />
    );
  }


  return (
    <DateOptionsCard>
      <StyledTitle level={5}>
        1. Data wymagalności
      </StyledTitle>
      <ImpCommMaturityOpts
        impType={IMP_OCR}
      />
      <RowExt marginTop={32}>
        <Button
          type="primary"
          icon={<CalendarOutlined />}
          onClick={onSetDatePress}
        >
          Ustaw datę wymagalności samodzielnie
        </Button>
      </RowExt>
      <RowExt marginTop={42}>
        <StyledTitle level={5}>
          2. Data początkowa naliczania odstek (pierwszy dzień opóźnienia)
        </StyledTitle>
      </RowExt>
      <RowMd>
        <Checkbox
          checked={isStartingFromDue}
          onChange={setStaringFromDue}
        >
          <Space>
            {width < GRID_LG ? (
              <Text>
                Wymagalność
                <ArrowRightOutlined size={6} style={{ margin: '0 6px' }} />
                początek
              </Text>
            ) : <Text>Dzień po terminie spełnienia świadczenia</Text>}
          </Space>
        </Checkbox>
      </RowMd>
      <DateOptionsActions />
    </DateOptionsCard>
  );
};

export default DateOptions;

const StyledTitle = styled(Typography.Title)`
  margin-bottom: 0px !important;
`;