import { ArrowLeftOutlined, ArrowRightOutlined, CalendarOutlined } from '@ant-design/icons';
import { Button, Calendar } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';

import { useTypedSelector } from '../../../store';
import { importExcelEndDateUpdated, importExcelStepUpdated } from '../../../store/importExcel';
import { RowExt } from '../../../styles';
import { disabledDateImport } from '../../../utils/helpers/date';
import ImpCard from '../ImpCard';

const ImpExcelEndDate = () => {
  const endDate = useTypedSelector(state =>
    state.importEx.endDate);

  const dispatch = useDispatch();

  const onNext = () => {
    dispatch(importExcelStepUpdated(2));
  };

  const onBack = () => {
    dispatch(importExcelStepUpdated(0));
  };
  return (
    <ImpCard
      title="Wspólna data końcowa (opcjonalnie)"
      desc=" Data, do której będą naliczane odsetki od wszyskich kwot. Domyślnie jest to dzień dzisiejszy. Dla każdej z kwot, możesz modyfikować datę końcową w czasie ustawiania parametrów wyliczeń."
      icon={<CalendarOutlined />}
    >
      <RowExt
        justify="space-between"
        align='middle'
        marginBottom={24}
      >
        <Button
          type="dashed"
          icon={<ArrowLeftOutlined />}
          onClick={onBack}
        />

        <Button
          type="primary"
          icon={<ArrowRightOutlined />}
          onClick={onNext}
        />
      </RowExt>
      <Calendar
        value={endDate ?? undefined}
        disabledDate={disabledDateImport}
        onChange={value =>
          dispatch(importExcelEndDateUpdated(value))}
      />
    </ImpCard>
  );
};

export default ImpExcelEndDate;