import { Row } from 'antd';
import React, { ReactNode } from 'react';
import ButtonSpinner from '../Spinner/ButtonSpinner';

const ButtonLoading = (
  {
    children,
    loading
  }: {
    children?: ReactNode,
    loading:boolean
  }
) => {
  if (loading){
    return (
      <ButtonSpinner />
    );
  }
  return (
    <Row align="middle" justify="space-around">
      {children}
    </Row>
  );
};

export default ButtonLoading;
