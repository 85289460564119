import _ from 'lodash';
import { useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';

import { useTypedSelector } from '../../store';
import useGetNumberOfSteps from './useGetNumberOfSteps';

const useAreStepsActionsHidden = () => {

  const { step, interestDataProvider } = useTypedSelector(
    (state) =>
      state.lawsuitUI,
    shallowEqual
  );

  const editKey = useTypedSelector((state) =>
    state.edit.key);

  const isImportExVisible = useTypedSelector((state) =>
    state.importEx.isImportExVisible);

  const isCurrencyVisible = useTypedSelector((state) =>
    state.currency.isCurrencyVisible);

  const { isBasisVisible, areDateOptionsVisible } = useTypedSelector(
    (state) =>
      state.interestFormOptions,
    shallowEqual
  );

  const  {
    isAddPaymentsVisible,
    claimDetailsKey,
    paymentDetailKey,
    isImportPaymentVisible,
    autoAssignHistory,
  } = useTypedSelector(state =>
    state.lawsuitUI, shallowEqual);

  const [areStepsActionsHidden, setAreStepsActionsHidden] = useState(false);

  const numberOfSteps = useGetNumberOfSteps();

  useEffect(() => {
    const hideConditions = [
      isCurrencyVisible,
      isImportExVisible,
      !!editKey,
      numberOfSteps === step,
      isBasisVisible,
      areDateOptionsVisible,
      isAddPaymentsVisible,
      !!claimDetailsKey,
      !!paymentDetailKey,
      !!isImportPaymentVisible,
      !_.isEmpty(autoAssignHistory) && !_.isNull(autoAssignHistory),
    ];

    const hideActions = hideConditions.some((condition) =>
      condition === true);

    setAreStepsActionsHidden(hideActions);
  }, [
    areDateOptionsVisible,
    editKey,
    isBasisVisible,
    isCurrencyVisible,
    isImportExVisible,
    step,
    interestDataProvider,
    numberOfSteps,
    isAddPaymentsVisible,
    claimDetailsKey,
    paymentDetailKey,
    isImportPaymentVisible,
    autoAssignHistory
  ]);

  return areStepsActionsHidden;
};

export default useAreStepsActionsHidden;
