
import { Col, Modal } from 'antd';
import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import useWindowSize from '../../utils/hooks/useWindowSize';
import { GREY_WHITE, GRID_LG, GRID_MD, GRID_SM } from '../../styles';
import Icon from '../../styles/Icon';
import VideoPlaceholder from '../About/Video/VideoPlaceholder';

const GuideVideo = (
  {
    src,
    color,
    url,
    background,
  }: {
    url: string,
    src?:string,
    color?: string,
    background?: string,
  }
) => {
  const { width } = useWindowSize();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const videoSize = () => {
    if (width >= GRID_LG) return {
      width: 960, height: 540
    };
    if (width >= GRID_MD) return {
      width: 720, height: 405
    };
    if (width >= GRID_SM) return {
      width: 480, height: 270
    };
    return {
      width: 320, height: '100%'
    };
  };

  const showVideo = () => {
    if (width >= GRID_SM){
      setIsModalVisible(true);
    } else {
      window.open(url, '_blank');
    }
  };

  return (
    <VideoCol background={background}>
      <VideoPlaceholder
        showVideo={showVideo}
        src={src ?? ''}
        color={color}
      />
      <Modal
        onCancel={() =>
          setIsModalVisible(false)}
        visible={isModalVisible}
        footer={false}
        title={(
          <Icon
            height="18"
            width="18"
            fill="white"
            iconType="PLAY"
          />
)}
        destroyOnClose
        width={videoSize().width + 40}
        style={{ background: 'none !important' }}
      >
        <Player
          url={url}
          volume={0.5}
          playing={isModalVisible}
          height={videoSize().height}
          width={videoSize().width}
          controls
        />
      </Modal>
    </VideoCol>
  );
};

export default GuideVideo;
export const Player = styled(ReactPlayer)`
  width: 960px;
`;

const VideoCol = styled(Col)<{ background?: string }>`
  background-color: ${({ background }) =>
    background ?? GREY_WHITE};
  padding: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 767px) {
    margin-top: 48px;
    background-color: white;
    padding: 0;
    border-right: none;
	}
  margin-bottom: 24px;
`;
