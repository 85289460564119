/* eslint-disable max-len */
import { CheckOutlined, MailOutlined } from '@ant-design/icons';
import { Button, Card, notification, Tag } from 'antd';
import Meta from 'antd/lib/card/Meta';
import React, { useState } from 'react';
import styled from 'styled-components';
import ButtonSpinner from '../components/UI/Spinner/ButtonSpinner';
import { auth } from '../services/firebase/auth';
import { isEmailVerified } from '../utils/helpers/emailVerified';
import { errDescGeneral, errMsgGeneral } from '../utils/helpers/errorTexts';
import { sendConfirmationEmail } from '../services/user/sendConfirmEmail';
import { createPersonalData } from '../services/user/signUp';
import { useTypedSelector } from '../store';
import { GRID_SM_PX } from '../styles';
import { RED } from '../styles/colors';


const VerifyEmail = ({ setEmailVerified }:{
  setEmailVerified: (emailVerified:boolean) => void;
}) => {
  const [loading, setLoading] = useState(false);
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const userExists = useTypedSelector(state =>
    state.user.isNonAnonymousUserLoggedIn);

  const reloadUser = async () => {
    try {
      setLoadingConfirm(true);
      const { currentUser } = auth;
      await currentUser?.reload();
      setLoadingConfirm(false);
      if (currentUser && currentUser?.emailVerified && userExists){
        setEmailVerified(auth.currentUser?.emailVerified ?? false);
        notification.info({ message: 'Dziękujemy email zwerfyfikowany' });
        createPersonalData(currentUser.uid);
      } else {
        notification.info({ message: 'Nie otrzymaliśmy potwierzenia' });
      }
    } catch (e){
      setLoadingConfirm(false);
      notification.error({
        message: errMsgGeneral,
        description: errDescGeneral,
      });
    }
  };

  if (!isEmailVerified()){
    return (
      <Card
        style={{
          width: '100%', marginTop: 48
        }}
        actions={[
          <div
            key="tag"
            style={{ marginTop:6 }}
          >
            <Tag color={RED}>
              Niezweryfikowany email
            </Tag>
          </div>,
          <Button
            type="link"
            key="button"
            onClick={() =>
              sendConfirmationEmail(setLoading)}
          >
            {loading ? <ButtonSpinner /> : (
              <>
                <MailOutlined style={{ marginRight: 6 }} />
                Wyślij ponownie weryfikację
              </>
            )}
          </Button>,
          <Button
            type="link"
            key="button"
            onClick={reloadUser}
          >
            {loadingConfirm ? <ButtonSpinner /> : (
              <>
                <CheckOutlined style={{ marginRight: 6 }} />
                Potwierdziłam/em
              </>
            )}
          </Button>,
        ]}
      >
        <Meta
          title={auth.currentUser?.email}
          description={<Desc>Na powyższy adres email został wystłany email z prośbą o weryfikację. Potwierdź proszę a nastęnie przyciśnij przycisk potwierdziłam/em</Desc>}
        />
      </Card>
    );
  }
  return null;
};

export default VerifyEmail;

const Desc = styled.span`
  	@media only screen and (max-width: ${GRID_SM_PX}) {
    font-size: 12px;
	}
`;
