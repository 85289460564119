/* eslint-disable jsx-a11y/click-events-have-key-events */
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Modal, notification, Space, Typography } from 'antd';
import _ from 'lodash';
import React from 'react';

import { ComponentChildren } from '../../@types';
import { useAppDispatch, useTypedSelector } from '../../store';
import { InterestData, interestDataChanged, PaymentAssignment, PaymentData, paymentDataChanged, PaymentItem } from '../../store/interestStorage';
import { lsDecreaseStep } from '../../store/lawsuitUI';
import { RowSm } from '../../styles';
import useListOfAssignedPayments from '../../utils/hooks/useListOfAssignedPayments';
import useListOfFullCoveredClaims from '../../utils/hooks/useListOffFullCoveredClaims';
import AnimOpacity from '../UI/Anim/AnimOpacity';

const { Paragraph, Title } = Typography;

const StepsWarningModal = ({ isModalVisible, setModalVisible, addStep, paymentWaringOn }:{
  isModalVisible: boolean,
  setModalVisible: (isModalVisible: boolean) => void,
  addStep: () => void,
  paymentWaringOn: boolean,
} & ComponentChildren) => {
  const dispatch = useAppDispatch();

  const {
    areSomeClaimsCovered,
    claimsNotCovered
  } = useListOfFullCoveredClaims();

  const paymentsNotFullyCovering: PaymentData = useTypedSelector(state => {
    const { paymentData } = state.interestStorage;
    return  _.cloneDeep(paymentData).filter(paymentItem =>
      isAssignedToAny(claimsNotCovered, paymentItem)).map(paymentItem => {
      const assignedTo = paymentItem.assignedTo.filter(value =>
        claimsNotCovered.some(v =>
          v.key === value.key));
      return {
        ...paymentItem,
        assignedTo,
      };
    });
  });

  const paymentDataWithAssignments = useTypedSelector(state =>
    state.interestStorage.paymentData.filter(({ assignedTo }) =>
      !_.isEmpty(assignedTo)));


  const removePaymentsWoAssignments = () => {
    dispatch(paymentDataChanged(paymentDataWithAssignments));
    if (!areSomeClaimsCovered){
      setModalVisible(false);
      addStep();
    }
  };

  const removeFullyCoveredAndCovering = () => {
    dispatch(paymentDataChanged(paymentsNotFullyCovering));
    dispatch(interestDataChanged(claimsNotCovered));
    setModalVisible(false);
    if (claimsNotCovered.length === 0){
      dispatch(lsDecreaseStep());
      notification.open({ message: 'Po usunięciu przekształceniu nie zostały już żadne roszczenia. Dodaj nowe.' });
    } else {
      addStep();
    }
  };

  const onCancel = () => {
    setModalVisible(false);
  };
  const onLeave = () => {
    setModalVisible(false);
    addStep();
  };

  const removeAllPayments = () => {
    dispatch(paymentDataChanged([]));
    setModalVisible(false);
    addStep();
  };

  return (
    <Modal
      destroyOnClose={true}
      title="Uwaga - proszę podjąć decyzję"
      visible={isModalVisible}
      footer={false}
      onCancel={() =>
        setModalVisible(false)}
    >
      {paymentWaringOn ? (
        <PaymentsWarning
          {...{
            onCancel,
            removeAllPayments
          }}
        />
      ) : (
        <AssignmentsWarning
          {...{
            removePaymentsWoAssignments,
            removeFullyCoveredAndCovering,
            onCancel,
            onLeave
          }}
        />
      )}
    </Modal>
  );
};

export default StepsWarningModal;

const AssignmentsWarning = ({
  removePaymentsWoAssignments,
  onCancel,
  removeFullyCoveredAndCovering
}:{
  onCancel: () => void,
  removePaymentsWoAssignments: () => void,
  removeFullyCoveredAndCovering: () => void,
}) => {
  const { areSomePaymentsWoAssignments } = useListOfAssignedPayments();
  if (areSomePaymentsWoAssignments){
    return (
      <AnimOpacity>
        <Title level={5}> </Title>
        <Paragraph>
          Część wpłat nie została przypisana do roszczenia.
          Jeśli przyciśniesz OK, zostaną one usunięte. Możesz też wrócić i przypisać wpłaty do roszczeń.
        </Paragraph>
        <RowSm justify='end'>
          <Space>
            <Button
              icon={
                <ArrowLeftOutlined />
            }
              onClick={onCancel}
            >
              Wróć
            </Button>
            <Button
              type="primary"
              onClick={removePaymentsWoAssignments}
            >
              OK
            </Button>
          </Space>
        </RowSm>
      </AnimOpacity>
    );
  }
  return (
    <AnimOpacity>
      <Title level={5}>Usunąć całkowicie zaspokojone roszczenia? </Title>
      <Paragraph>
        Jeśli przyciśniesz OK zostaną one usunięte wraz z właściwymi wpłatami jako zbędne.
      </Paragraph>
      <RowSm justify='end'>
        <Space>
          <Button
            icon={
              <ArrowLeftOutlined />
          }
            onClick={onCancel}
          >
            Wróć
          </Button>
          <Button
            type="primary"
            onClick={removeFullyCoveredAndCovering}
          >
            OK
          </Button>
        </Space>
      </RowSm>
    </AnimOpacity>
  );
};

const PaymentsWarning = ({
  onCancel,
  removeAllPayments
}:{
  onCancel: () => void,
  removeAllPayments: () => void,
}) =>
  (
    <AnimOpacity>
      <Title level={5}>Usunąć dane wpłat? </Title>
      <Paragraph>
        Zmieniłeś tryb wpisywania kwot głównych. Jeśli przejdziesz dalej, dotychczas wpisane dane wpłat zostaną usunięte jako zbędne.
      </Paragraph>
      <RowSm justify='end'>
        <Space>
          <Button
            icon={
              <ArrowLeftOutlined />
      }
            onClick={onCancel}
          >
            Wróć
          </Button>
          <Button
            type="primary"
            onClick={removeAllPayments}
          >
            PRZEJDŹ DALEJ
          </Button>
        </Space>
      </RowSm>
    </AnimOpacity>
  );




const isAssignedTo = (
  assignedTo: PaymentAssignment[],
  interestItemKey:string
) =>
  assignedTo.some(( { key }) =>
    key === interestItemKey);

const isAssignedToAny = (interestData: InterestData, paymentItem: PaymentItem) =>
  interestData.some(({ key }) =>
    isAssignedTo(paymentItem.assignedTo, key));





