import { CloseOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row } from 'antd';
import React from 'react';

const OverSave = (
  {
    onEditOverSave,
    overSaveText,
    onClose,
    isOverSave,
    onKeyDown
  }: {
    onEditOverSave: (event: React.ChangeEvent<HTMLInputElement>)=>void,
    overSaveText: string,
    onClose: () => void,
    isOverSave: boolean,
    onKeyDown:  (event: React.KeyboardEvent<HTMLInputElement>) => void
  }
) => {
  if (isOverSave){
    return (
      <Row>
        <Col span={20}>
          <Input
            placeholder="Nazwa zapisu"
            onChange={onEditOverSave}
            value={overSaveText}
            autoFocus
            onKeyDown={onKeyDown}
          />
        </Col>
        <Col span={4}>
          <Row
            justify="end"
          >
            <Button
              icon={<CloseOutlined />}
              onClick={onClose}
            />
          </Row>
        </Col>
      </Row>
    );
  }
  return null;
};

export default OverSave;
