import { Card, Col, Input, Row, Typography } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { useTypedSelector } from '../../../store';
import { impExternalLegalBasisUpdated } from '../../../store/impExternal';
import { ocrLegalBasisUpdated } from '../../../store/impOcr';
import { importExcelLegalBasisUpdated } from '../../../store/importExcel';
import { BLACK } from '../../../styles';
import Icon from '../../../styles/Icon';
import { BALANCE } from '../../../styles/iconTypes';
import { IMP_OCR, ImpTypes, IMP_EXCEL } from './impCommonData';


const ImpCommLegalBasis = ({ impType }:{ impType: ImpTypes })  => {
  const dispatch = useDispatch();

  const legalBasis = useTypedSelector(state => {
    switch (impType) {
      case IMP_OCR:
        return state.impOcr.legalBasis;
      case IMP_EXCEL:
        return state.importEx.legalBasis;
      default:
        return state.impExternal.legalBasis;
    }
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    switch (impType) {
      case IMP_OCR:
        dispatch(ocrLegalBasisUpdated(e.target.value));
        break;
      case IMP_EXCEL:
        dispatch(importExcelLegalBasisUpdated(e.target.value));
        break;
      default:
        dispatch(impExternalLegalBasisUpdated(e.target.value));
    }
  };

  return (
    <Card
      type="inner"
      title="Wspólna podstawa prawna (opcjonalne)"
      style={{ marginTop:12 }}
      extra={(
        <Icon
          iconType={BALANCE}
          height="18"
          width="18"
          fill={BLACK}
        />
)}
    >
      <Row align='middle'>
        <Col span={8}>
          <TextIntro>
            Podstawę prawną stanowi...
          </TextIntro>
        </Col>
        <Col span={16}>
          <Input
            placeholder="art. 535 Kodeksu Cywinlego"
            allowClear
            value={legalBasis ?? ''}
            onChange={onChange}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default ImpCommLegalBasis;


export const TextIntro = styled(Typography.Text)`
    font-style: italic;
    justify-content: center;
    display: flex;
    padding: 0 24px;
`;