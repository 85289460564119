import { ArrowLeftOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Modal, Typography } from 'antd';
import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { ComponentChildren } from '../../@types';
import { useAppDispatch } from '../../store';
import { saveModalOpened } from '../../store/saveLawsuitModal';
import { RowExt } from '../../styles';
import { resetLawsuit } from '../../utils/helpers/resetLawsuit';

const ButtonWrapper = ({ children }: ComponentChildren) =>
  (
    <Col span={14}>
      {children}
    </Col>
  );

const NewLawsuit = (
  {
    isModalVisible,
    setModalVisible,
    isEdit,
    onEdit
  }: {
    isModalVisible:boolean,
    setModalVisible: (isModalVisible: boolean) => void,
    isEdit?: boolean,
    onEdit?: ()=>void
  }
) => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const closeModal = () =>
    setModalVisible(false);

  const onReset = () => {
    resetLawsuit(dispatch);
    history.push('/lawsuit');
    closeModal();
  };

  const handleEdit = () => {
    closeModal();
    if (onEdit){
      onEdit();
    }
  };

  return (
    <Modal
      destroyOnClose={true}
      title="Dotychczasowe obliczenia"
      visible={isModalVisible}
      footer={false}
      onCancel={closeModal}
    >
      <Typography.Text>
        W formularzu obliczeń znajdują się dane.
        {isEdit ?
          ' Zaczęcie edycji spowoduje spowoduje usunięcie danych w forumlarzu. '
          : ' Zaczęcie nowych obliczeń spowoduje usunięcie danych w forumlarzu. '}
      </Typography.Text>
      <Divider />
      <RowExt gutter={[16, 16]}>
        <ButtonWrapper>
          <ButtonModal
            icon={<ArrowLeftOutlined />}
            onClick={() =>
              history.push('/lawsuit')}
          >
            Dotychczasowe
          </ButtonModal>
        </ButtonWrapper>
        <Col span={10}>
          <ButtonModal
            type="primary"
            onClick={isEdit ? handleEdit : onReset}
          >
            {isEdit ? 'Edytuj' : 'Zacznij nowe'}
          </ButtonModal>
        </Col>
        <Col span={10}>
          <ButtonModal
            onClick={closeModal}
          >
            Anuluj
          </ButtonModal>
        </Col>
        <ButtonWrapper>
          <ButtonModal
            icon={<SaveOutlined />}
            type="primary"
            onClick={() =>
              dispatch(saveModalOpened({
                isSaveModalVisible: true,
                isSaveFromResult: false,
              }))}
          >
            Zapisz dotychczasowe
          </ButtonModal>
        </ButtonWrapper>
      </RowExt>
    </Modal>
  );
};

export default NewLawsuit;


const ButtonModal = styled(Button)`
   width: 100%;
`;