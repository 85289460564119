import { Col } from 'antd';
import React from 'react';
import { AbtSpaceRow, DashedColLeft } from '../../styles';
import LawContainer from '../UI/Containers/LawContainer';

const LawBorders = () => {
  return (
    <LawContainer>
      <AbtSpaceRow>
        <DashedColLeft
          lg={{
            offset:0,
            span:1,
          }}
          xs={{
            offset: 1,
            span: 1,
          }}
        />
        <DashedColLeft span={1} />
        <DashedColLeft span={1} />
        <DashedColLeft span={1} />
        <Col
          lg={{ span: 20 }}
          xs={{ span: 19 }}
        />
      </AbtSpaceRow>
    </LawContainer>
  );
};

export default LawBorders;
