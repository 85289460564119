import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

function ScrollToTop({ history }:{
  history: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    listen: any,
  }
}) {
  useEffect(() => {
    const unListen = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unListen();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (null);
}

export default withRouter(ScrollToTop);