import { Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';

import { RowExt } from '../../../styles';

const JustParagraph = ({ text }:{
  text: string | null;
}) => {
  if (text) {
    return (
      <RowExt marginTop="sm">
        <JustificationText>
          {text}
        </JustificationText>
      </RowExt>
    );
  }
  return null;
};

export default JustParagraph;

const JustificationText = styled(Typography.Text)`
    font-family: 'Fira Mono';
`;
