import { ArrowLeftOutlined, CheckOutlined, CloseOutlined, RobotFilled } from '@ant-design/icons';
import { Col, Divider, Modal, Typography } from 'antd';
import React from 'react';

import { ButtonFullWidth, EmptyButton, RowExt } from '../../../../styles';

const AutoAssignModal = ({
  isAutoModalVisible,
  closeModal,
  onShowHistory
}:{
  closeModal: () => void,
  isAutoModalVisible:boolean,
  onShowHistory: (shouldShowHistory: boolean) => void
}) => {
  return (
    <Modal
      onCancel={() =>
        closeModal()}
      visible={isAutoModalVisible}
      destroyOnClose
      style={{ background: 'none !important' }}
      footer={false}
    >
      <RowExt marginTop="md">

        <Typography.Title level={4}>
          <RobotFilled />
          {' '}
          Czy mam pokazać wyjaśnienia?
        </Typography.Title>
        <Typography.Text>
          Autonomiczne zastosowanie art. 451 Kodeksu cywilnego, wspierane przez nauczanie maszynowe.
        </Typography.Text>
      </RowExt>
      <RowExt align="middle" justify="center" marginTop="md">
        <Col span={6}>
          <ButtonFullWidth
            type='dashed'
            icon={(
              <ArrowLeftOutlined />
)}
            onClick={closeModal}
          >
            Anuluj
          </ButtonFullWidth>
        </Col>
        <Col span={2}>
          <Divider
            type='vertical'
          />
        </Col>
        <Col span={6}>
          <EmptyButton
            full
            icon={(
              <CloseOutlined />
)}
            onClick={() =>
              onShowHistory(false)}
          >
            Nie
          </EmptyButton>
        </Col>
        <Col span={2}>
          <Divider
            type='vertical'
          />
        </Col>
        <Col span={6}>
          <ButtonFullWidth
            type="primary"
            onClick={() =>
              onShowHistory(true)}
            icon={
              <CheckOutlined />
            }
          >
            Tak
          </ButtonFullWidth>
        </Col>
      </RowExt>
    </Modal>
  );
};

export default AutoAssignModal;