import { Col, Row } from 'antd';
import React from 'react';
import { AboutContainer, DashedColLeft } from '../../styles';
import SubBasic from './SubOpts/SubBasic';
import SubBasicHead from './SubOpts/SubBasicHead';
import SubBasicStar from './SubOpts/SubBasicStar';
import SubPremium from './SubOpts/SubPremium';
import SubPremiumHead from './SubOpts/SubPremiumHead';
import SubStandard from './SubOpts/SubStandard';
import SubStandardHead from './SubOpts/SubStandardHead';


const SubOpts = () => {
  return (
    <AboutContainer>
      <Row>
        <Col
          xl={1}
          xs={0}
        />
        <SubBasicHead />
        <SubStandardHead />
        <SubPremiumHead />
        <DashedColLeft
          xl={1}
          xs={0}
        />
      </Row>
      <Row
        id="subscribe"
      >
        <Col
          xl={1}
          xs={0}
        />
        <SubBasic />
        <SubStandard />
        <SubPremium />
        <Col
          xl={1}
          xs={0}
        />
        <DashedColLeft
          xl={1}
          xs={0}
        />
      </Row>
      <SubBasicStar />
    </AboutContainer>
  );
};

export default SubOpts;