import axios from 'axios';
import Holidays from 'date-holidays';
import Dinero from 'dinero.js';
import moment, { Moment } from 'moment';

import { AppDispatch } from '../store';
import { appLoadingStarted, appLoadingStopped, appLoadingTextChanged } from '../store/appLoading';
import { CurrencyType } from '../store/currency';

const hd = new Holidays('PL');

export const getCurrExchangeRate = async (day:Moment, currencyType:CurrencyType) => {
  const formattedDay = day.format('YYYY-MM-DD');
  const response = await axios({
    method: 'get',
    url: `https://api.nbp.pl/api/exchangerates/rates/a/${currencyType}/${formattedDay}/?format=json`,
    responseType: 'stream',
  });
  return response.data?.rates[0].mid;
};

export const exchangeCurrency = async ({ dispatch, convertDate, convertingSum, currencyType }:{
  dispatch: AppDispatch,
  convertDate: Moment,
  convertingSum: number,
  currencyType: CurrencyType
}) => {
  dispatch(appLoadingStarted());
  let isWeekendOrHoliday = false;
  let exchangeDate = convertDate.clone();
  while (
    (exchangeDate.day() === 0)
    || (exchangeDate.day() === 6)
    || hd.isHoliday(moment(exchangeDate).toDate())
  ){
    isWeekendOrHoliday = true;
    exchangeDate = convertDate.subtract(1, 'day');
  }
  try {
    dispatch(appLoadingTextChanged('pobieranie danych z NBP'));
    const exchangeRate = await getCurrExchangeRate(exchangeDate, currencyType) as number;
    return {
      convertedSum: Dinero({ amount:convertingSum * exchangeRate }).getAmount(),
      exchangeRate,
      isWeekendOrHoliday,
      exchangeDate,
    };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    return null;
  } finally {
    dispatch(appLoadingStopped());
  }
};