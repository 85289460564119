import { DownloadOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import React from 'react';
import { Img } from 'react-image';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import codesGuidesThumb from '../../assets/codesGuidesThumb.png';
import codesThumb from '../../assets/codesThumb.png';
import folderCover from '../../assets/cover-codes-guide.png';
import { LINK_FOLDER_CODES, YT_CODES, YT_CODES_EXPLAIN } from '../../utils/helpers/links';
import useWindowSize from '../../utils/hooks/useWindowSize';
import { AboutContainer, DashedColLeft, RowMd } from '../../styles';
import { BLUE_MEDIUM, GREY_LIGHT, RED } from '../../styles/colors';
import AboutCodesVideo from './AboutCodesVideo';
import AbtTitle from './AbtTitle';


const AboutCodes = () => {
  const { width } = useWindowSize();
  return (
    <AboutContainer>
      <Row id="codes">
        <Col
          lg={{
            offset: 1,
            span: 8,
          }}
          md={{
            offset: 2,
            span: 12
          }}
          xs={{
            offset: 2,
            span: 20,
          }}
        >
          <AbtTitle
            title={(
              <>
                Kody wyliczeń
              </>
            )}
            desc={(
              <>
                Innowacja pozew.app. Zobacz video instruktażowe oraz wprowadzające do zagadnień teoretycznych.
                {' '}
                <br />
                <a href={LINK_FOLDER_CODES} target="_blank" rel="noreferrer">Pobierz folder</a>
                {' '}
                |
                {' '}
                <Link to="lawsuit">Generuj kod</Link>
              </>
  )}
          />
        </Col>
        <Col
          xl={13}
          lg={12}
        />
        <DashedColLeft
          xl={{ span: 2 }}
          lg={{ span: 3 }}
          xs={0}
        />
      </Row>
      <Row>
        <Col
          lg={{
            offset: 1,
            span: 8,
          }}
          md={{
            offset: 6,
            span: 12
          }}
          xs={{
            offset: 2,
            span: 20,
          }}
        >
          <AboutCodesVideo
            {...{
              src:codesThumb,
              url:YT_CODES,
              color: RED,
              background: 'white',
            }}
          />
          <AboutCodesVideo
            {...{
              src:codesGuidesThumb,
              url:YT_CODES_EXPLAIN,
              color: BLUE_MEDIUM,
              background: 'white',
            }}
          />
        </Col>
        <Col
          lg={{
            span: 7, offset: 3
          }}
          md={{
            offset: 6,
            span: 12
          }}
          xs={{
            offset: 2,
            span: 20,
          }}
        >
          <CoverWrapper href={LINK_FOLDER_CODES} target="_blank" rel="noreferrer">
            <Row justify="center">
              <FolderCover
                src={folderCover}
              />
            </Row>
          </CoverWrapper>
          {width <= 768 ? (
            <a href={LINK_FOLDER_CODES} target="_blank" rel="noreferrer">
              <RowMd justify="center">
                <Button icon={<DownloadOutlined />} type='primary'>
                  Pobierz folder
                </Button>
              </RowMd>
            </a>
          ) : null}
        </Col>
        <Col
          xl={{ span: 3 }}
          lg={{ span: 3 }}
          xs={0}
        />
        <DashedColLeft
          xl={{ span: 2 }}
          lg={{ span: 3 }}
          xs={0}
        />
      </Row>
    </AboutContainer>
  );
};

export default AboutCodes;

export const CoverWrapper = styled.a`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const FolderCover = styled(Img)`
    margin-top: 48px;
    width: 100%;
    border-radius: 2px;
    border: 1px solid${GREY_LIGHT}
`;
