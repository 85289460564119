/* eslint-disable max-len */
import { MailOutlined } from '@ant-design/icons';
import { Button, Card, Col, Input, Modal, Popconfirm, Typography } from 'antd';
import { Meta } from 'antd/lib/list/Item';
import { getDocs } from 'firebase/firestore';
import React, { useState } from 'react';
import { useHistory } from 'react-router';

import { auth } from '../../services/firebase/auth';
import { trailingOrActiveSubscriptionsQuery } from '../../services/firebase/firestoreRefs';
import { notifyFirebaseError } from '../../services/firebase/notifyFirebaseError';
import { deleteUser } from '../../services/user/deleteUser';
import { useAppDispatch } from '../../store';
import { BLUE, RowExt, RowSm } from '../../styles';
import { BLUE_MEDIUM, RED } from '../../styles/colors';
import { FACEBOOK, FACEBOOK_SOLID, LINKED_IN, LINKED_IN_SOLID } from '../../styles/iconTypes';
import { LINK_FB, LINK_LI } from '../../utils/helpers/links';
import { withAppLoadingAndUser } from '../../utils/helpers/withAppLoading';
import IconButton from '../UI/Button/IconButton';

const ContactDeleteAcc = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [password, setPassword] = useState('');
  const [subWarning, setSubWarning] = useState(false);

  const providerId = auth.currentUser?.providerData[0]?.providerId;

  const onDelete = () => {
    setSubWarning(false);
    withAppLoadingAndUser({
      dispatch,
      startLoadingText: 'sprawdzam konto',
      user: auth.currentUser
    }, async (user) => {
      try {
        const trailingOrActiveSubscriptions = await getDocs(trailingOrActiveSubscriptionsQuery(user.uid));
        if (!trailingOrActiveSubscriptions.empty) setSubWarning(true);
        setIsModalVisible(true);
      } catch (error:unknown){
        notifyFirebaseError(error);
      }
    });
  };

  const isExtProvider =
  providerId === 'facebook.com' ||
  providerId  === 'google.com';
  return (
    <>
      <RowExt gutter={16} fullWidth marginTop="md">
        <Col sm={12} xs={24}>
          <Card
            title="Kontakt, sugestie i zgłaszanie błędów"
            actions={[
              <a
                href="mailto:contact@pozew.app"
                target="_blank"
                rel="noreferrer"
                key="at"
              >
                <MailOutlined
                  key="ellipsis"
                  style={{ color:RED }}
                />
              </a>,
              <IconButton
                href={LINK_LI}
                iconType={LINKED_IN}
                iconHoover={LINKED_IN_SOLID}
                fill={BLUE_MEDIUM}
                stroke
                key="LI"
                style={{ marginLeft:12 }}
              />,
              <IconButton
                href={LINK_FB}
                iconType={FACEBOOK}
                iconHoover={FACEBOOK_SOLID}
                stroke
                fill={BLUE}
                key="fb"
                style={{
                  marginTop:3, marginLeft:12
                }}
              />
            ]}
          >
            <Meta
              title="Naciśnij jedną z opcji poniżej"
              description="Zgłaszanie sugestii: bardzo proszę o kontakt przez Linked In albo Facebook. Jeśli zaistniał problem z Twoimi danymi podaj proszę id konta(dostępne powyżej)."
            />
          </Card>
        </Col>
        <Col sm={12} xs={24}>
          <Card
            title="Usuwanie konta"
            bordered={false}
            actions={[
              <Popconfirm
                title="Jesteś pewna/pewien"
                key="button"
                onConfirm={onDelete}
              >
                <Button
                  type="link"
                >
                  Usuń konto
                </Button>
              </Popconfirm>
            ]}
          >
            <Meta
              description="Uwaga: usunięcie konta spowoduje usunięcie Twoich danych i wyliczeń. Jeśli logowałeś się przez Google lub Facebook - usunięcie konta pozew.app NIE spowoduje usunięcie Twojego konta u któregoś ze wspomnianych dostawców."
            />
            <RowSm />
          </Card>
        </Col>
      </RowExt>
      <Modal
        destroyOnClose={true}
        title={isExtProvider ? 'Zaloguj się ponownie, żeby potwierdzić tożsamość' : 'Podaj hasło żeby potwierdzić tosamość'}
        visible={isModalVisible}
        onOk={() =>
          deleteUser({
            history,
            closeModal: () =>
              setIsModalVisible(false),
            password,
            providerId: providerId ?? 'email',
            dispatch,
          })}
        okText={isExtProvider ? 'Dalej' : 'Usuń'}
        onCancel={() => {
          setPassword('');
          setIsModalVisible(false);
        }}
      >
        {subWarning ? (
          <Typography.Paragraph type="danger">
            <strong> Uwaga, konto ma włączoną subskrypcję. Zanim usniesz konto, upewnij się, że anulowałeś subskrypcję, żeby w przyszłości nie zostały pobrane środki. </strong>
          </Typography.Paragraph>
        ) : null}
        {!isExtProvider ? (
          <Input.Password
            value={password}
            onChange={(event) =>
              setPassword(event.target.value)}
          />
        ) : <Typography.Text>Sprawdzamy Twoją tożsamość zanim usuniemy dane.</Typography.Text>}
      </Modal>
    </>
  );
};

export default ContactDeleteAcc;
