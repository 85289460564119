import { Input } from 'antd';
import React from 'react';

const EditRecordNameInput = (
  {
    recordNameLocal,
    setRecordNameLocal
  }: {
    recordNameLocal: string,
    setRecordNameLocal:(recordNameLocal:string) => void
  }
) => {
  return (
    <Input
      addonBefore="Zapisz jako"
      placeholder="Nazwa zapisu"
      value={recordNameLocal}
      onChange={(event) =>
        setRecordNameLocal(event.target.value)}
      autoFocus
    />
  );
};

export default EditRecordNameInput;
