import _ from 'lodash';
import moment from 'moment';
import { formatDate } from '../../../../utils/helpers/date';
import { DocParser, findDate } from '../DocParser';

import { convertStrToDay } from '../extractCommon';
import { dueDatePhrases } from '../keywords';
import { RGX_DUE_DATE_DAYS } from '../regexExtraction';

export const getDueDateFV = (doc: string, createDate: string) => {
  const parser = new DocParser(doc);
  const date = parser.extractRelevantWord(findDate, dueDatePhrases, 1);

  if (_.isNull(date)) {
    const days = doc.match(RGX_DUE_DATE_DAYS);

    if (createDate && !_.isEmpty(days) && days !== null){
      const numberOfDays = Number(days[0].split(' ')[0]);

      const fd = moment(createDate).add(numberOfDays, 'days');

      return formatDate(fd);
    }
    return null;
  }

  return convertStrToDay(date);
};
