import { DeleteOutlined, EditOutlined, MergeCellsOutlined } from '@ant-design/icons';
import { Badge, Button, Card, Checkbox, Col, Image, PageHeader, Row, Select, Space, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React from 'react';
import styled from 'styled-components';

import { ENV_TYPE } from '../../../env.json';
import { docNamesMap, DocTypes } from '../../../services/ML/docTypes';
import { RowMd } from '../../../styles';
import { BLACK, BLUE, GREY_BORDER } from '../../../styles/colors';
import Icon from '../../../styles/Icon';

const { Option } = Select;

const EvidCard = ({
  checked,
  children,
  docType,
  image,
  onDelete,
  onDocTypeChange,
  onEdit,
  onMerge,
  onSelect,
  pages,
  subtitle,
  title,
  mergeDisabled,
  correctedDocType
}:{
  checked: boolean,
  mergeDisabled: boolean,
  children: React.ReactNode,
  docType: DocTypes,
  image: string,
  onDelete: () => void,
  onDocTypeChange: (docType:DocTypes) => void,
  onEdit: () => void,
  onMerge: () => void,
  onSelect: (e: CheckboxChangeEvent) => void,
  pages?: number
  subtitle: React.ReactNode | string
  title: React.ReactNode | string,
  correctedDocType?: DocTypes,
}) => {

  return (
    <CardStyled>
      <Row align='middle'>
        <Col xs={0} sm={5}>
          {pages && pages > 1 ? (
            <>
              <MultiPageIcon
                height="24"
                width="24"
                fill={BLACK}
                stroke={BLACK}
                iconType="IMAGE_COLLECTION"
              />
              <ImageEviPrevOnly
                src={image}
              />
            </>
          )
            :     (
              <ImageEvi
                src={image}
              />
            )}
        </Col>
        <ColStyled xs={22} sm={18} offset={1}>
          <PageHeader
            backIcon={false}
            title={title}
            subTitle={subtitle}
            extra={[
              <Checkbox
                checked={checked}
                onChange={onSelect}
                key="0"
              />,
              <Button
                key="2"
                icon={<MergeCellsOutlined />}
                type="text"
                onClick={onMerge}
                disabled={mergeDisabled}
              />,
              <Button
                key="1"
                icon={<EditOutlined />}
                type="primary"
                onClick={onEdit}
              />,
              <Button
                key="3"
                icon={<DeleteOutlined />}
                type="text"
                onClick={onDelete}
              />,
            ]}
          >
            {children}
            <RowMd justify='space-between' align='middle'>
              <PagesText>
                <Typography.Text type="secondary">
                  Ilość stron
                </Typography.Text>
                <NumberStyled>
                  {pages}
                </NumberStyled>
              </PagesText>
              <Space>
                <PagesText>
                  <Typography.Text type="secondary">
                    Typ
                  </Typography.Text>
                </PagesText>
                <Select
                  style={{ width: 240 }}
                  onSelect={onDocTypeChange}
                  value={docType}
                >
                  {[...docNamesMap.keys()].map(dt =>
                    (
                      <Option value={dt} key={dt}>
                        <Space>
                          {docNamesMap.get(dt)}
                          {correctedDocType === dt && ENV_TYPE === 'DEVELOPMENT' ? (
                            <>
                              {' '}
                              <Badge
                                style={{
                                  backgroundColor: GREY_BORDER, color: BLUE
                                }}
                                count="AI"
                              />
                            </>
                          )
                            : null}
                        </Space>
                      </Option>
                    ))}
                </Select>
              </Space>
            </RowMd>
          </PageHeader>
        </ColStyled>
      </Row>
    </CardStyled>
  );
};


export default EvidCard;


const ColStyled = styled(Col)`
  /* padding-top: 12px; */
`;

const PagesText = styled(Typography.Text)`
   text-align: center;
   /* padding-left: 24px; */
`;

const NumberStyled = styled.span`
  font-size: 14px;
  padding-left: 12px;
  font-weight: 500;
`;

const CardStyled = styled(Card)`
   margin-bottom: 24px;
`;

export const ImageEvi = styled(Image)`
  border: 1px solid ${BLACK};
  border-radius: 2px;
  /* f0f0f0 */
`;

export const ImageEviPrevOnly = styled.img`
  border: 1px solid ${BLACK};
  border-radius: 2px;
  width: 100%;
  /* f0f0f0 */
`;

const MultiPageIcon = styled(Icon)`
    display: block;
    color: ${BLACK};
    position: absolute;
    top: 50%;
    z-index: 10;
    font-size: 24px;
    right: 10px;
    top: 10px;
`;