import { CalendarOutlined,
  FieldNumberOutlined,
  NumberOutlined,
  StopOutlined,
  WarningOutlined } from '@ant-design/icons';
import { Card, List } from 'antd';
import React from 'react';
import nextId from 'react-id-generator';

import { ExternalPaymentItem } from '../../../@types';
import { PaymentItem } from '../../../store/interestStorage';
import { BLUE } from '../../../styles';
import { BLACK, RED } from '../../../styles/colors';
import Icon from '../../../styles/Icon';
import { formatDate } from '../../../utils/helpers/date';
import { formatPLN } from '../../../utils/helpers/formatNumber';
import { ImportWarning } from '../../../utils/hooks/useImpOrcValidate';


export const ImpExternalFatalPay = ({
  warnings,
  fatalErrorItem,
  index
}: {
  warnings: ImportWarning[],
  fatalErrorItem?: ExternalPaymentItem,
  index: number
}) => {
  if (!fatalErrorItem) return null;
  const selectedWarnings = warnings.filter(value =>
    value.isFatal);
  return (
    <Card
      type="inner"
      style={{ marginBottom: 12 }}
      extra={(
        <>
          <StopOutlined style={{
            color: RED, margin: '0 2px'
          }}
          />
        </>
)}
      title={(
        <>
          Wpłata bez importu
          {' '}
          <NumberOutlined />
          {' '}
          {index + 1}
        </>
)}
    >
      <ImpExternalField
        {...{
          warnings: selectedWarnings,
          paymentItem: fatalErrorItem
        }}
      />
    </Card>
  );
};


const ImpExternalItemPay = ({
  paymentItem,
  paymentDataWarnings,
  index
}:{
  paymentItem: PaymentItem,
  paymentDataWarnings: {
    key: string,
    warnings: ImportWarning[],
    fatalErrorItem?: ExternalPaymentItem,
  }[]
  index: number,
}) => {
  const warningItem = paymentDataWarnings.find(value =>
    value.key === paymentItem.key);

  const warnings = warningItem ? warningItem.warnings : [];

  return (
    <Card
      type="inner"
      style={{ marginBottom: 12 }}
      extra={(
        <>
          {warningItem ? (
            <WarningOutlined style={{
              color: BLUE, margin: '0 2px'
            }}
            />
          ) : null}
        </>
  )}
      title={(
        <>
          Wpłata
          {' '}
          <NumberOutlined />
          {' '}
          {index + 1}
        </>
  )}
    >
      <ImpExternalField
        {...{
          paymentItem,
          warnings,
        }}
      />
    </Card>
  );
};


export default ImpExternalItemPay;



const ImpExternalField = ({
  paymentItem,
  warnings
}:{
  paymentItem: PaymentItem | ExternalPaymentItem
  warnings:  ImportWarning[]
}) => {
  return (
    <>
      <List.Item>
        <List.Item.Meta
          avatar={(
            <>
              <CalendarOutlined />
            </>
)}
          description={formatDate(paymentItem.paymentDate)}
          title="Data wpłaty"
        />
      </List.Item>
      <List.Item>
        <List.Item.Meta
          avatar={(
            <>
              <Icon
                height="16"
                width="16"
                fill={BLACK}
                stroke={BLACK}
                iconType="MONEY_PACK"
              />
            </>
)}
          description={formatPLN(paymentItem.paymentSum)}
          title="Kwota wpłaty"
        />
      </List.Item>
      {paymentItem.transferDescription ? (
        <List.Item>
          <List.Item.Meta
            avatar={(
              <>
                <FieldNumberOutlined />
              </>
)}
            description={paymentItem.transferDescription}
            title="Tytuł wpłaty"
          />
        </List.Item>
      ) : null}
      {warnings.map(warning =>
        (
          <List.Item
            key={nextId()}
          >
            <List.Item.Meta
              key={nextId()}
              avatar={warning.isFatal ? (
                <StopOutlined style={{ color: RED }} />
              ) : <WarningOutlined style={{ color: BLUE }} />}
              title={warning.isFatal ? 'Odmowa importu' : 'Zmodyfikowano dane | informacja'}
              description={warning.message}
            />
          </List.Item>
        ))}
    </>
  );
};
