import { Col, Space } from 'antd';
import React from 'react';

import { GREY_WHITE, GRID_MD, GRID_SM, RowExt } from '../../../styles';
import { BLUE, BLUE_MEDIUM, RED } from '../../../styles/colors';
import useWindowSize from '../../../utils/hooks/useWindowSize';
import SloganFooterSM from './SloganFooter/SloganFooterSM';
import SloganMenuButton from './SloganFooter/SloganMenuButton';
import SloganSocialMedia from './SloganFooter/SloganSocialMedia';

const SloganFooter = () => {
  const { width } = useWindowSize();
  return (
    <>
      <RowExt
        align="bottom"
        bgColor={GREY_WHITE}
        paddingBottom="md"
        fullWidth
        paddingTop={width <= GRID_SM ? 24 : 24}
      >
        <Col
          sm={{
            offset: 2, span: 20
          }}
          xs={{
            offset: 2, span: 20
          }}
        >
          <Space>
            <SloganSocialMedia />
            <SloganMenuButton
              pathname="/subscribe"
              text={texts.button3}
              borderColor={RED}
            />
            {width < GRID_MD ? (
              <SloganMenuButton
                pathname="/guides"
                text="Video"
                borderColor={BLUE}
              />
            ) : null}
            <SloganMenuButton
              pathname="/developer"
              text={texts.button2}
              borderColor={BLUE_MEDIUM}
            />
            <SloganMenuButton
              pathname="/roadMap"
              text={width > 375 ? texts.button : texts.button1}
              borderColor={BLUE}
            />

          </Space>
        </Col>
      </RowExt>
      <SloganFooterSM />
    </>
  );
};

export default SloganFooter;

const texts = {
  desc1: ' Zgłaszanie sugestii:',
  button: 'Mapa drogowa',
  button1: 'Projekt',
  button2: 'API',
  button3: 'Subskrypcja'
};
