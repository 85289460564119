import notification from 'antd/lib/notification';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { writeLsCode } from '../../services/lsCode';
import { setSumUp } from '../../services/setSumUp';
import { useTypedSelector } from '../../store';
import { appLoadingStopped } from '../../store/appLoading';
import { sumUpReset } from '../../store/sumUp';
import { errMsgGeneral } from '../helpers/errorTexts';


const useGetSumUpData = () => {
  const dispatch = useDispatch();

  const lawsuitOpts = useTypedSelector((state) =>
    state.lawsuitOpts);

  const interestData = useTypedSelector((state) =>
    state.interestStorage.interestData);

  const paymentData = useTypedSelector((state) =>
    state.interestStorage.paymentData);

  useEffect(() => {
    const setSumUpAndWriteLsCode = async () => {
      dispatch(sumUpReset());
      try {
        await setSumUp({
          interestData,
          dispatch,
          lawsuitOpts,
          paymentData,
        });
        dispatch(writeLsCode());
      } catch (error) {
        dispatch(appLoadingStopped());
        notification.error({
          message: errMsgGeneral,
          description: error instanceof Error ? error.message : errMsgGeneral,
        });
      }
    };
    setSumUpAndWriteLsCode();
    return () => {
      dispatch(sumUpReset());
    };

  }, [dispatch, interestData, lawsuitOpts, paymentData]);
};

export default useGetSumUpData;
