/* eslint-disable max-len */
import { ReloadOutlined } from '@ant-design/icons';
import { Col, Divider, notification, Typography } from 'antd';
import firebase from 'firebase/compat/app';
import { addDoc, getDocs } from 'firebase/firestore';
import React, { useState } from 'react';
import styled from 'styled-components';

import { auth } from '../../services/firebase/auth';
import { subscriptionCodesRef, trailingOrActiveSubscriptionsQuery } from '../../services/firebase/firestoreRefs';
import { notifyFirebaseError } from '../../services/firebase/notifyFirebaseError';
import { useAppDispatch } from '../../store';
import { BLUE, EmptyButton, RowExt } from '../../styles';
import { YT_MENAGE_USERS } from '../../utils/helpers/links';
import { withAppLoadingAndUser } from '../../utils/helpers/withAppLoading';
import CopyButton from '../UI/Button/CopyButton';

const { Title, Text, Paragraph } = Typography;

const AddSubscriptionUser = () => {
  const [code, setCode] = useState('');
  const  dispatch = useAppDispatch();
  const createSubscriptionCode = () =>
    withAppLoadingAndUser({
      dispatch,
      startLoadingText: 'generuję kod',
      user: auth.currentUser
    }, async (user) => {
      try {
        const trailingOrActiveSubscriptions = await getDocs(trailingOrActiveSubscriptionsQuery(user.uid));
        if (!trailingOrActiveSubscriptions.empty){
          const res = await addDoc(subscriptionCodesRef, {
            giverId: user.uid,
            createDate: firebase.firestore.FieldValue.serverTimestamp(),
            subscriptionId: trailingOrActiveSubscriptions.docs[0].id,
          });
          notification.info({
            message: 'Nowy kod wygenerowany',
            description: 'Skopiuj i prześlij współpracownikowi'
          });
          setCode(res.id);
        }
      } catch (error: unknown) {
        notifyFirebaseError(error);
      }
    });

  return (
    <>
      <Divider
        style={{ marginTop: 48 }}
      />
      <Title level={5}>Dodaj użytkownika - wygeneruj dla niego kod</Title>
      <Paragraph type="secondary">
        Wygeneruj kod,
        {' '}
        <strong>skopiuj kod, link do instrukcji video i prześlij </strong>
        {' '}
        innemu użykownikowi. Będzie mógł wpisać kod w opcjach swojego konta. W ten sposób uruchomi na swoim koncie dodatkowe opcje na czas trwania Twojej subskrypcji. Po dodaniu kodu jego email pojawi się w liście "Użytkowników subskrypcji". W każdej chwili możesz usunąć użytkowników z wyłączeniem administratora.
        {' '}
        <strong>Kod może zostać użyty tylko raz, więc dla każdego dodawanego użytkownika wygeneruj osobny. </strong>
      </Paragraph>
      <RowExt marginTop={24} align="middle" justify="space-between">
        <RespCol
          md={{ span: 8 }}
          xs={{ span: 24 }}
        >
          <Code>
            <Text type="secondary">
              {code}
            </Text>
          </Code>
        </RespCol>
        <RespCol md={{ span: 5 }} xs={{ span: 24 }}>
          <EmptyButton
            icon={<ReloadOutlined />}
            full
            onClick={createSubscriptionCode}
          >
            Generuj kod
          </EmptyButton>
        </RespCol>
        <RespCol md={{ span: 3 }} xs={{ span: 24 }}>
          <CopyButton
            womargin
            disabled={code === ''}
            value={code}
            fullWidth
          />
        </RespCol>
        <RespCol md={{ span: 5 }} xs={{ span: 24 }}>
          <CopyButton
            womargin
            fullWidth
            value={YT_MENAGE_USERS}
          >
            link do instrukcji
          </CopyButton>
        </RespCol>
      </RowExt>
    </>
  );
};

export default AddSubscriptionUser;

const Code = styled.div`
   padding: 4px 12px;
   border-radius: 4;
   background-color: #f5f5f5;
   border: solid ${BLUE} 1px;
   font-family: Fira mono;
   display: flex;
   justify-content: center;
   width: 100%;
   height: 30px;
`;

const RespCol = styled(Col)`
  @media only screen and (max-width: 767px){
      margin-top:24px;
  }
`;
