/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Moment } from 'moment';

export interface InterestItem{
  key:string,
  startingDate: Moment,
  endDate: Moment,
  partialSum: number,
  legalBasis: string | null,
  factualBasis: string | null,
  accountingDoc: string | null,
  dueDate:  Moment | null,
  maturityDate: Moment | null,
  isClaimFromFulfilled: boolean,
}

export type InterestData = InterestItem[];

export type PaymentAssignment = {
  key: string,
  sum: number,
  isInterestFirst: boolean,
};

export interface PaymentItem{
  key:string,
  paymentDate: Moment,
  paymentSum: number,
  transferDescription: null | string,
  assignedTo: PaymentAssignment[],
  statementDate: Moment | null,
}

export type PaymentData = PaymentItem[];
interface InterestStorage {
  interestData: InterestData
  paymentData: PaymentData;
}

const initialState:InterestStorage = {
  interestData: [], paymentData: []
};

export const interestStorageSlice = createSlice({
  name: 'interestStorage',
  initialState,
  reducers: {
    interestItemAdded: (state:InterestStorage, action:PayloadAction<InterestItem>) => {
      state.interestData.push(action.payload);
    },
    interestDataChanged:(state:InterestStorage, action:PayloadAction<InterestData>) => {
      state.interestData = action.payload;
    },
    paymentItemAdded: (state:InterestStorage, action: PayloadAction<PaymentItem>) => {
      state.paymentData.push(action.payload);
    },
    paymentDataChanged:(state:InterestStorage, action:PayloadAction<PaymentData>) => {
      state.paymentData = action.payload;
    },
    interestAllDataChanged:(state:InterestStorage, action:PayloadAction<InterestData>) => {
      state.interestData = [...action.payload];
    },
    interestStorageReset:() =>
      initialState
  },
});

export const {
  interestItemAdded,
  interestDataChanged,
  paymentItemAdded,
  paymentDataChanged,
  interestAllDataChanged,
  interestStorageReset
} = interestStorageSlice.actions;

export default interestStorageSlice.reducer;

