/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AppLoading {
  loading:boolean;
  loadingText: string | null;
  progress: null | number;
}

const initialState:AppLoading = {
  loading: true,
  loadingText: null,
  progress: null,
};

export type StartLoadingText = undefined | string;


export const appLoadingSlice = createSlice({
  name: 'appLoading',
  initialState,
  reducers: {
    appLoadingStarted: (state: AppLoading, action: PayloadAction<StartLoadingText>) => {
      state.loading = true;
      state.loadingText = action.payload ?? null;
    },
    appLoadingStopped: (state: AppLoading) => {
      state.loading = false;
      state.progress = null;
      state.loadingText = null;
    },
    appLoadingTextChanged:(state: AppLoading, action: PayloadAction<string | null>) => {state.loadingText = action.payload;},
    appProgressUpdated: (state: AppLoading, action: PayloadAction<{ progress: number | null, loadingText: string | null }>) => {
      state.loading = true;
      state.progress = action.payload.progress;
      state.loadingText = action.payload.loadingText;
    },
  }
});

export const {
  appLoadingStarted,
  appLoadingStopped,
  appLoadingTextChanged,
  appProgressUpdated,
} = appLoadingSlice.actions;

export default appLoadingSlice.reducer;
