import { Col, Typography } from 'antd';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import logo from '../../../assets/logo.svg';
import { DashedColLeft, RowBg, RowExt, ScreenLayout } from '../../../styles';
import { ScreenBackButton } from '../DescScreenFooter';
import { ImageLogo } from '../Nav/NavPrivate';

const LawContainer = (
  {
    children,
    title
  }: {
    title?: string
    children?: ReactNode,
  }
) => {
  return (
    <ScreenLayout>
      <MDContainer>
        {title ? (
          <>
            <div style={{ width:'100%' }}>
              <RowExt
                fullWidth
                paddingTop="md"
              >
                <Col
                  lg={{
                    offset: 0,
                    span: 12
                  }}
                  xs={{
                    offset: 1,
                    span: 11,
                  }}
                >
                  <Link
                    to="/"
                  >
                    <ImageLogo
                      src={logo}
                    />
                  </Link>
                </Col>
                <ColStyled
                  lg={{
                    offset: 0,
                    span: 10
                  }}
                  xs={{
                    offset: 0,
                    span: 10,
                  }}
                >
                  <ScreenBackButton />
                </ColStyled>
              </RowExt>
            </div>
            <RowBg>
              <DashedColLeft span={1} />
              <Col span={21}>
                <Typography.Title level={1}>{title}</Typography.Title>
              </Col>
            </RowBg>
          </>
        ) : null}
        {children}
      </MDContainer>
    </ScreenLayout>
  );
};

export default LawContainer;

export const MDContainer = styled.div`
  width: 992px;
  flex-direction: column;
  display: flex;
  @media only screen and (max-width: 992px) {
  width: 100%;
  } 
`;

const ColStyled = styled(Col)`
   display: flex;
   justify-content: flex-end;
`;