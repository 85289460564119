/* eslint-disable no-prototype-builtins */
import { notification } from 'antd';
import _ from 'lodash';
import { InterestData } from '../../store/interestStorage';
import * as opts from '../../store/lawsuitOpts';

type SaveToBeChecked = { interestData: InterestData } & opts.LawsuitOpts;

export const checkSaveData = (save:SaveToBeChecked, json?: boolean) => {
  const notifyError = () =>
    notification.error({
      message: 'Nastąpił błąd',
      description: `Zapis nie posiada wszystkich wymaganych danych. ${json ? 'Możliwe, że plik uległ uszkodzeniu.' : '' } `
    });

  if ( _.has(save, 'typeOfInterest')
    && _.has(save, 'compensationOn')
    && _.has(save, 'agreementInterestRate')
    && _.has(save, 'principalAmountOpt')
    && _.has(save, 'principalAmount')
    && _.has(save, 'interestData')) {
    if (!_.includes([
      opts.INTEREST_LATE,
      opts.INTEREST_TRANSACTION,
      opts.INTEREST_MED_PUBLIC,
      opts.INTEREST_CAPITAL,
      opts.INTEREST_AGREE,
    ], save.typeOfInterest)){
      notifyError();
      return false;
    }
    if (!_.isBoolean(save.compensationOn)){
      notifyError();
      return false;
    }
    if (!_.isNumber(save.agreementInterestRate)){
      notifyError();
      return false;
    }
    if (!_.isNumber(save.principalAmount)){
      notifyError();
      return false;
    }
    if (!_.includes([
      opts.PRINCIPAL_USER,
      opts.PRINCIPAL_APP,
      opts.PRINCIPAL_INTEREST,
    ], save.principalAmountOpt)){
      notifyError();
      return false;
    }
    if (!_.isArray(save.interestData)){
      notifyError();
      return false;
    }
    if (_.isEmpty(save.interestData)){
      notification.info({
        message: 'Uwaga',
        description: 'Zapis nie posiada żadnych kwot składowych'
      });
      return true;
    }
    return true;
  }
  notifyError();
  return false;

};
