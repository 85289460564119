import { ArrowLeftOutlined, BankOutlined, DollarOutlined, EditOutlined, EuroOutlined, PoundOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button, Col } from 'antd';
import { shallowEqual } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import React from 'react';
import styled from 'styled-components';
import { LocationState } from '../../../@types';
import { exchangeCurrency } from '../../../services/currencyExchange';
import { AppDispatch, useAppDispatch, useTypedSelector } from '../../../store';
import { currencyErrorOccurred, currencyReset, currencyResultChanged } from '../../../store/currency';
import { editCurrencyIsVisible, editPartialSumUpdated } from '../../../store/edit';
import { partialSumUpdated } from '../../../store/interestForm';
import { GRID_SM_PX, RowExt } from '../../../styles';


const ButtonIcon = () => {
  const currencyType = useTypedSelector(state =>
    state.currency.currencyType);
  switch (currencyType) {
    case 'eur':
      return <EuroOutlined />;
    case 'gbp':
      return <PoundOutlined />;
    case 'usd':
      return <DollarOutlined />;
    default:
      return <BankOutlined />;
  }
};

const handleError = (dispatch: AppDispatch, err: boolean, message?:string) => {
  if (err){
    dispatch(currencyErrorOccurred({
      error: true,
      errorMessage: message ?? 'Ups coś poszło nie tak, spróbuj ponownie.'
    }));
    setTimeout(() => {
      dispatch(currencyErrorOccurred({
        error: false,
        errorMessage: '',
      }));
    }, 3000);
  }  else {
    dispatch(currencyErrorOccurred({
      error: false,
      errorMessage: '',
    }));
  }
};

const CurrencyActions = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const location = useLocation<LocationState>();
  const isScreen = location.pathname === '/currency';

  const isSubscriber = useTypedSelector(state =>
    state.user.isSubscriber);

  const { currencyType, convertingSum, convertDate, result } = useTypedSelector(state =>
    state.currency, shallowEqual);

  const  editKey = useTypedSelector(state =>
    state.edit.key);

  const onBack = () => {
    if (isScreen){
      history.goBack();
    } else {
      if (editKey){
        dispatch(editCurrencyIsVisible(false));
        dispatch(currencyReset());
      }
      dispatch(currencyReset());
    }
  };

  const convert = async () => {
    if (convertDate && convertingSum !== 0){
      try {
        const currencyData = await exchangeCurrency({
          dispatch, convertDate: convertDate.clone(), convertingSum, currencyType
        });
        if (currencyData){
          const { convertedSum, exchangeRate,  isWeekendOrHoliday, exchangeDate } = currencyData;
          dispatch(currencyResultChanged({
            currencyType,
            convertingSum,
            convertDate,
            convertedSum,
            exchangeRate,
            isWeekendOrHoliday,
            exchangeDate
          }));
        } else {
          handleError(dispatch, true);
        }
      } catch (err) {
        handleError(dispatch, true);
      }
    } else {
      handleError(dispatch, true, 'Podaj kwotę oraz datę przeliczenia.');
    }
  };


  const add = () => {
    if (result.convertedSum !== 0){
      if (editKey){
        dispatch(editPartialSumUpdated(result.convertedSum));
        dispatch(editCurrencyIsVisible(false));
      } else {
        dispatch(partialSumUpdated(result.convertedSum));
      }
      dispatch(currencyReset());
    }  else {
      handleError(dispatch, true, 'Podaj kwotę oraz datę przeliczenia.');
    }
  };

  const reset = () => {
    dispatch(currencyReset());
  };

  return (
    <RowExt
      marginTop="bg"
      marginBottom="md"
      justify="space-between"
    >
      <ColResp
        sm={{
          span: 7, order: 1
        }}
        xs={{
          span: 24, order: 3
        }}
      >
        <ButtonResp
          onClick={onBack}
          icon={<ArrowLeftOutlined />}
        >
          {texts.goBack}
        </ButtonResp>
      </ColResp>
      {isSubscriber ? (
        <>
          <Col
            sm={{
              span:7, order: isScreen ? 3 : 2
            }}
            xs={{
              span: 24, order: 1
            }}
          >
            <ButtonResp
              type="primary"
              icon={<ButtonIcon />}
              disabled={!isSubscriber}
              onClick={convert}
            >
              Przelicz
            </ButtonResp>
          </Col>
          {isScreen ? (
            <ColResp
              sm={{
                span:7, order:2
              }}
              xs={{
                span: 24, order: 2
              }}
            >
              <ButtonResp
                icon={<ReloadOutlined />}
                disabled={(!convertingSum && !convertDate) ||  !isSubscriber}
                onClick={reset}
              >
                Wyczyść
              </ButtonResp>
            </ColResp>
          )
            : (
              <ColResp
                sm={{
                  span:7, order:2
                }}
                xs={{
                  span: 24, order: 2
                }}
              >
                <ButtonResp
                  type='primary'
                  icon={<EditOutlined />}
                  disabled={!result.convertedSum || !isSubscriber}
                  onClick={add}
                >
                  Dodaj
                </ButtonResp>
              </ColResp>
            )}
        </>
      ) : null}
    </RowExt>
  );
};


export default CurrencyActions;

const texts = {
  goBack: 'Wróc',
  reset:'Cofnij zmiany',
  justification: 'uzasadnienie',
  details: 'szczegóły wyliczeń',
  save: 'Zmień',
};

const ButtonResp = styled(Button)`
    width: 100%;
`;

const ColResp = styled(Col)`
  @media only screen and (max-width: ${GRID_SM_PX}) {
    margin-top: 24px;
	}
`;