import { StopOutlined, WarningOutlined } from '@ant-design/icons';
import { Divider, List } from 'antd';
import moment from 'moment';
import React from 'react';
import nextId from 'react-id-generator';

import { convertNumeric } from '../../../../services/importEx';
import { BLUE } from '../../../../styles';
import { RED } from '../../../../styles/colors';
import { formatDate } from '../../../../utils/helpers/date';
import { formatPLN } from '../../../../utils/helpers/formatNumber';
import { isNumeric } from '../../../../utils/helpers/isNumeric';
import { RowWithWarnings } from '../../../../utils/hooks/useImpExcelValidate';

const ImpExcelField = ({
  row,
  fieldKey,
  label
}:{
  row: RowWithWarnings,
  fieldKey: 'dueDate' | 'partialSum' | 'accountingDoc',
  label: string
}) => {
  const colNumber = () => {
    switch (fieldKey) {
      case 'dueDate':
        return 1;
      case 'partialSum':
        return 2;
      default:
        return 3;
    }
  };

  const formatValue = () => {
    const bkValue = row[fieldKey].value.toString() ?? 'BRAK';
    switch (fieldKey) {
      case 'dueDate':
        if (moment(row.dueDate.value).isValid()){
          return formatDate(row.dueDate.value as string);
        } return  bkValue;
      case 'partialSum':
        if (isNumeric(row.partialSum.value.toString())){
          return `${formatPLN(convertNumeric(row.partialSum.value.toString()))}`;
        } return  bkValue;
      default:
        return bkValue;
    }
  };

  return (
    <>
      <List.Item
        key={nextId()}
      >
        <List.Item.Meta
          key={nextId()}
          avatar={(
            <>
              {colNumber()}
            </>
  )}
          description={formatValue()}
          title={label}
        />
      </List.Item>
      {row[fieldKey].warnings.map(warning =>
        (
          <List.Item
            key={nextId()}
          >
            <List.Item.Meta
              key={nextId()}
              avatar={warning.isFatal ? (
                <StopOutlined style={{ color: RED }} />
              ) : <WarningOutlined style={{ color: BLUE }} />}
              title={warning.isFatal ? 'Odmowa importu' : 'Zmodyfikowano dane | informacja'}
              description={warning.message}
            />
          </List.Item>
        ))}
      {fieldKey !== 'accountingDoc' ?     (
        <Divider
          style={{
            padding: '0 0', margin: '12px 0'
          }}
        />
      ) : null}
    </>
  );
};

export default ImpExcelField;