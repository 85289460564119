import { Space } from 'antd';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';

import { autoAssign } from '../../../services/autoAssign/autoAssign';
import { useTypedSelector } from '../../../store';
import { shouldAutoAssignUpdated } from '../../../store/lawsuitUI';
import { BetaTag } from '../../../styles';
import StepCard from '../StepCard/StepCard';
import AddPayments from './AddPayments';
import AssignPaymentsActions from './AssignPaymentsActions';
import { AssignPaymentsArrowEnd } from './AssignPaymentsArrow';
import AssignPaymentsList from './AssignPaymentsList';
import AssignHistoryItem from './AutoAssignment/AutoAssignmentHistory';
import DetailsClaim from './Claim/DetailsClaim';
import DetailsPayment from './Claim/DetailsPayment';
import ImportPayments from './ImportPayments';


const AssignPayments = () => {

  const dispatch = useDispatch();

  const {
    isAddPaymentsVisible,
    claimDetailsKey,
    paymentDetailKey,
    isImportPaymentVisible,
    autoAssignHistory,
    shouldAutoAssign
  } = useTypedSelector(state =>
    state.lawsuitUI, shallowEqual);

  const { interestData, paymentData } = useTypedSelector(state =>
    state.interestStorage, shallowEqual);

  const lawsuitOpts = useTypedSelector(state =>
    state.lawsuitOpts);

  useEffect(() => {
    if (shouldAutoAssign){
      autoAssign({
        interestData,
        lawsuitOpts,
        paymentData,
        dispatch,
        shouldShowHistory: true
      });
      dispatch(shouldAutoAssignUpdated(false));
    }

    return () => {
      dispatch(shouldAutoAssignUpdated(false));
    };
  }, [dispatch, interestData, lawsuitOpts, paymentData, shouldAutoAssign]);


  if (!_.isEmpty(autoAssignHistory) && !_.isNull(autoAssignHistory)) {
    return (
      <AssignHistoryItem />
    );
  }

  if (isImportPaymentVisible){
    return (
      <ImportPayments />
    );
  }

  if (claimDetailsKey){
    return (
      <DetailsClaim />
    );
  }

  if (paymentDetailKey){
    return (
      <DetailsPayment />
    );
  }

  if (isAddPaymentsVisible){
    return (<AddPayments />);
  }

  return (
    <StepCard
      title={(
        <Space>
          {texts.title}
          <BetaTag>BETA</BetaTag>
        </Space>
      )}
      desc={texts.desc}
    >
      <AssignPaymentsActions />
      <AssignPaymentsList />
      <AssignPaymentsArrowEnd />
    </StepCard>
  );
};

export default AssignPayments;

const texts = {
  title: 'Uzupełnij i przyporządkuj wpłaty',
  desc: 'Dodaj kwoty wpłat a następnie wybierz, jak mają zostać zaliczone, przyporządkowując do danego roszczenia.',
};
