import { Col, Space } from 'antd';
import React from 'react';
import { ComponentChildren } from '../../@types';
import { DashedColLeft, RowExt } from '../../styles';

const LawParLinks = ({ children }: ComponentChildren) => {
  return (
    <RowExt>
      <DashedColLeft
        lg={{
          offset:0,
          span:1,
        }}
        xs={{
          offset: 1,
          span: 1,
        }}
      />
      <Col
        lg={{ span: 21 }}
        xs={{ span: 20 }}
      >
        <Space>
          {children}
        </Space>
      </Col>
    </RowExt>
  );
};

export default LawParLinks;
