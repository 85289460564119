import { Row, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';

import { extractDoc } from '../../../../services/ML/DataExtraction/extractDoc';
import { DocTypes, PaymentConfirmation } from '../../../../services/ML/docTypes';
import { useAppDispatch } from '../../../../store';
import { OcrItem, ocrItemRemoved, ocrItemSelected, ocrItemUpdated } from '../../../../store/impOcr';
import { RowMd } from '../../../../styles';
import { formatDate } from '../../../../utils/helpers/date';
import EvidCard from '../EvidCard';
import { EvidCardItem } from '../EvidCardItem';

const { Text } = Typography;

const PayConEvidCard = ({
  ocrItem,
  index,
  setSelected,
  selectedItems,
  onMerge,
  isSelected
}:{
  ocrItem: OcrItem
  index: number,
  setSelected:(selectedItems: number[]) =>void
  selectedItems: number[],
  onMerge: (clickedIndex: number) => void
  isSelected: boolean,
}) => {
  const dispatch = useAppDispatch();
  const {
    correctedDocResults,
    pages,
    correctedDocType,
    doc
  } = ocrItem;

  const results = correctedDocResults as PaymentConfirmation;

  const {
    paymentDate,
    paymentSum,
    createDate,
    paymentTitle,
  } = results;

  const onDelete = () => {
    dispatch(ocrItemRemoved(index));
  };

  const onEdit = () => {
    dispatch(ocrItemSelected(index));
  };

  const onDocTypeChange = (dt: DocTypes) => {
    const extractedData = extractDoc(doc, dt);
    dispatch(ocrItemUpdated({
      ocrItem:{
        ...ocrItem,
        correctedDocType: dt,
        extractedData,
        docResults: extractedData,
        correctedDocResults: extractedData,
      },
      index
    }));
  };

  const onMergeClick = () => {
    onMerge(index);
  };

  const onSelect = (e: CheckboxChangeEvent) => {
    if ( e.target.checked && !isSelected) {
      setSelected([
        ...selectedItems,
        index
      ]);
    }
    if (!e.target.checked && isSelected) {
      const cloned = _.cloneDeep(selectedItems).filter(v =>
        v !== index);
      setSelected(cloned);
    }
  };

  return (
    <EvidCard
      {...{
        image: pages[0].image ?? '',
        onDelete,
        onMerge: onMergeClick,
        onEdit,
        pages:pages.length,
        subtitle:createDate ? `z dnia ${createDate}` : '',
        title: createPayConTitle(),
        docType: correctedDocType,
        onDocTypeChange,
        onSelect,
        checked:isSelected,
        mergeDisabled: selectedItems.length === 0 || isSelected
      }}
    >
      <>
        <Row>
          <EvidCardItem
            title='Wysokość wpłaty'
            value={paymentSum ? `${paymentSum.toFixed(2)} zł` : '0 zł'}
          />
          <EvidCardItem
            title='Data wpłaty (księgowania)'
            value={paymentDate ? formatDate(paymentDate) : 'BRAK'}
          />
        </Row>
        <RowMd>
          <Text type='secondary'>Tytuł wpłaty</Text>
          <TextStyled strong>{paymentTitle ?? 'BRAK'}</TextStyled>
        </RowMd>
      </>
    </EvidCard>
  );
};

export default PayConEvidCard;

const TextStyled  = styled(Text)`
  padding-left: 12px;
  font-size: 500;
`;


export const createPayConTitle = () => {
  return 'Potwierdzenie wpłaty';
};