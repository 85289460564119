import { notification } from 'antd';
import { getDoc } from 'firebase/firestore';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { auth } from '../../services/firebase/auth';
import { calculationRef } from '../../services/firebase/firestoreRefs';
import { LawsuitSave } from '../../services/lawsuitSave';
import { setSumUp } from '../../services/setSumUp';
import { useAppDispatch } from '../../store';
import { InterestItem } from '../../store/interestStorage';
import { justOptsReset } from '../../store/justOpts';
import { InterestTypes, PrincipalAmountOpt } from '../../store/lawsuitOpts';
import { sumUpReset } from '../../store/sumUp';
import { checkSaveData } from '../helpers/checkSaveData';
import { convertToMoment } from '../helpers/date';
import { errDescGeneral, errMsgGeneral } from '../helpers/errorTexts';
import { formatInterestDataFromSave } from '../helpers/formatInterestDataFromSave';
import { formatPaymentsFromSave } from '../helpers/formatPaymentsFromSave';
import { withAppLoadingAndUser } from '../helpers/withAppLoading';

export interface LawsuitResult{
  typeOfInterest:InterestTypes,
  compensationOn: boolean,
  agreementInterestRate: number,
  principalAmountOpt: PrincipalAmountOpt,
  principalAmount: number,
  interestData: InterestItem[],
}

const useGetResultLs = (id:string) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [recordName, setRecord] = useState('');
  const [editDate, setEditDate] = useState('');

  useEffect(() => {
    const fetchDataAndSetSumUp = () =>
      withAppLoadingAndUser({
        dispatch,
        startLoadingText: 'pobieranie danych z serwera',
        user: auth.currentUser
      }, async (user) => {
        try {
          const calculation = await getDoc(calculationRef(user.uid, id));

          if (!calculation.exists()) {
            throw new Error('Nie ma takiego zapisu na sewerze');
          }

          dispatch(sumUpReset());

          const lawsuitSave = calculation.data() as LawsuitSave;

          const {
            interestData,
            lawsuitOpts,
            paymentData,
            recordName: recordNameLocal,
            editDate: editNameLocal,
          } = formatFetchedData(lawsuitSave);

          setRecord(recordNameLocal);
          setEditDate(editNameLocal);

          const isCalculationDataCorrect = checkSaveData({
            ...lawsuitOpts,
            interestData,
          }, false);

          isCalculationDataCorrect ?
            await setSumUp({
              dispatch,
              interestData,
              paymentData,
              lawsuitOpts,
            }) :  history.goBack();

        } catch (error){
          // eslint-disable-next-line no-console
          console.log(error);
          history.goBack();
          notification.error({
            message: errMsgGeneral,
            description: error instanceof Error ? error.message : errDescGeneral,
          });
        } finally {
          dispatch(justOptsReset());
        }
      });

    _.isString(id) ?
      fetchDataAndSetSumUp() :
      history.push('/');

  }, [dispatch, history, id]);


  return {
    recordName, editDate
  };
};


export const formatFetchedData = (calculationData: LawsuitSave | undefined) => {
  if (calculationData){
    const { lawsuitOpts, editDate, recordName, interestData, paymentData } = calculationData;
    return {
      editDate: convertToMoment(editDate)?.format('YYYY-MM-DD hh:mm') ?? '',
      recordName: _.isString(recordName) ? calculationData.recordName : '',
      interestData: formatInterestDataFromSave(interestData),
      paymentData: formatPaymentsFromSave(paymentData),
      lawsuitOpts,
    };
  }
  throw new Error('Nie ma takiego zapisu na sewerze');
};

export default useGetResultLs;
