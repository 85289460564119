import moment, { Moment } from 'moment';

export type InterestLawData = {
  start: Moment;
  end: Moment;
  interestRate: number;
};

// OBWIESZCZENIE MINISTRA ROZWOJU W SPRAWIE WYSOKOŚCI ODSETEK USTAWOWYCH ZA OPÓŹNIENIE W TRANSAKCJACH HANDLOWYCH
// z dnia 7 stycznia 2016 r. (M.P. z 2016 r. poz. 43)

// Na podstawie art. 11c w związku z art. 11b pkt 1 ustawy z dnia 8 marca 2013 r. o terminach zapłaty w transakcjach handlowych (Dz.U. poz. 403 oraz z 2015 r. poz. 1830) ogłasza się, że
// wysokość odsetek ustawowych za opóźnienie w transakcjach handlowych, od dnia 1 stycznia 2016 r. do dnia 30 czerwca 2016 r., wynosi 9,50% w stosunku rocznym.

// OBWIESZCZENIE MINISTRA ROZWOJU W SPRAWIE WYSOKOŚCI ODSETEK USTAWOWYCH ZA OPÓŹNIENIE W TRANSAKCJACH HANDLOWYCH

// z dnia 29 czerwca 2016 r. (M.P. z 2016 r. poz. 601)

// Na podstawie art. 11c w związku z art. 11b pkt 2 ustawy z dnia 8 marca 2013 r. o terminach zapłaty w transakcjach handlowych (Dz.U. z 2016 r. poz. 684) ogłasza się,
// że wysokość odsetek ustawowych za opóźnienie w transakcjach handlowych, od dnia 1 lipca 2016 r. do dnia 31 grudnia 2016 r., wynosi 9,50 % w stosunku rocznym.

// OBWIESZCZENIE MINISTRA ROZWOJU I FINANSÓW W SPRAWIE WYSOKOŚCI ODSETEK USTAWOWYCH ZA OPÓŹNIENIE W TRANSAKCJACH HANDLOWYCH
// z dnia 29 grudnia 2016 r. (M.P. z 2017 r. poz. 10)

// Na podstawie art. 11c w związku z art. 11b pkt 1 ustawy z dnia 8 marca 2013 r. o terminach zapłaty w transakcjach handlowych (Dz.U. z 2016 r. poz. 684) ogłasza się, że
// wysokość odsetek ustawowych za opóźnienie w transakcjach handlowych, od dnia 1 stycznia 2017 r. do dnia 30 czerwca 2017 r., wynosi 9,50% w stosunku rocznym.

// OBWIESZCZENIE MINISTRA ROZWOJU I FINANSÓW W SPRAWIE WYSOKOŚCI ODSETEK USTAWOWYCH ZA OPÓŹNIENIE W TRANSAKCJACH HANDLOWYCH

// z dnia 10 lipca 2017 r. (M.P. z 2017 r. poz. 686)

// Na podstawie art. 11c w związku z art. 11b pkt 2 ustawy z dnia 8 marca 2013 r. o terminach zapłaty w transakcjach handlowych (Dz.U. z 2016 r. poz. 684) ogłasza się, że
// wysokość odsetek ustawowych za opóźnienie w transakcjach handlowych, od dnia 1 lipca 2017 r. do dnia 31 grudnia 2017 r., wynosi 9,50% w stosunku rocznym.

// OBWIESZCZENIE MINISTRA PRZEDSIĘBIORCZOŚCI I TECHNOLOGII W SPRAWIE WYSOKOŚCI ODSETEK USTAWOWYCH ZA OPÓŹNIENIE W TRANSAKCJACH HANDLOWYCH

// z dnia 24 stycznia 2018 r. (M.P. z 2018 r. poz. 139)

// Na podstawie art. 11c ustawy z dnia 8 marca 2013 r. o terminach zapłaty w transakcjach handlowych (Dz.U. z 2016 r. poz. 684) ogłasza się, że
// wysokość odsetek ustawowych za opóźnienie w transakcjach handlowych, od dnia 1 stycznia 2018 r. do dnia 30 czerwca 2018 r., wynosi 9,50% w stosunku rocznym.

// OBWIESZCZENIE MINISTRA PRZEDSIĘBIORCZOŚCI I TECHNOLOGII W SPRAWIE WYSOKOŚCI ODSETEK USTAWOWYCH ZA OPÓŹNIENIE W TRANSAKCJACH HANDLOWYCH

// z dnia 4 lipca 2018 r. (M.P. z 2018 r. poz. 671)

// Na podstawie art. 11c ustawy z dnia 8 marca 2013 r. o terminach zapłaty w transakcjach handlowych (Dz.U. z 2016 r. poz. 684 oraz z 2018 r. poz. 650) ogłasza się, że
// wysokość odsetek ustawowych za opóźnienie w transakcjach handlowych, od dnia 1 lipca 2018 r. do dnia 31 grudnia 2018 r., wynosi 9,50% w stosunku rocznym.

// OBWIESZCZENIE MINISTRA PRZEDSIĘBIORCZOŚCI I TECHNOLOGII W SPRAWIE WYSOKOŚCI ODSETEK USTAWOWYCH ZA OPÓŹNIENIE W TRANSAKCJACH HANDLOWYCH

// z dnia 14 stycznia 2019 r. (M.P. z 2019 r. poz. 42)

// Na podstawie art. 11c ustawy z dnia 8 marca 2013 r. o terminach zapłaty w transakcjach handlowych (Dz.U. z 2016 r. poz. 684 oraz z 2018 r. poz. 650) ogłasza się, że
// wysokość odsetek ustawowych za opóźnienie w transakcjach handlowych, od dnia 1 stycznia 2019 r. do dnia 30 czerwca 2019 r., wynosi 9,50% w stosunku rocznym.

// OBWIESZCZENIE MINISTRA PRZEDSIĘBIORCZOŚCI I TECHNOLOGII W SPRAWIE WYSOKOŚCI ODSETEK USTAWOWYCH ZA OPÓŹNIENIE W TRANSAKCJACH HANDLOWYCH

// z dnia 1 lipca 2019 r. (M.P. z 2019 r. poz. 671)

// Na podstawie art. 11c ustawy z dnia 8 marca 2013 r. o terminach zapłaty w transakcjach handlowych (Dz.U. z 2019 r. poz. 118) ogłasza się, że
// wysokość odsetek ustawowych za opóźnienie w transakcjach handlowych, od dnia 1 lipca 2019 r. do dnia 31 grudnia 2019 r., wynosi 9,50% w stosunku rocznym.

// OBWIESZCZENIE MINISTRA ROZWOJU W SPRAWIE WYSOKOŚCI ODSETEK USTAWOWYCH ZA OPÓŹNIENIE W TRANSAKCJACH HANDLOWYCH

// z dnia 23 grudnia 2019 r. (M.P. z 2020 r. poz. 2)

// Na podstawie art. 11c ustawy z dnia 8 marca 2013 r. o przeciwdziałaniu nadmiernym opóźnieniom w transakcjach handlowych (Dz.U. z 2019 r. poz. 118, 1649 i 2020) ogłasza się, że wysokość odsetek ustawowych za opóźnienie w transakcjach handlowych, od dnia 1 stycznia 2020 r. do dnia 30 czerwca 2020 r., wynosi:
// 1) 9,5% w stosunku rocznym - w przypadku transakcji handlowych, w których dłużnikiem jest podmiot publiczny będący podmiotem leczniczym;
// 2) 11,5% w stosunku rocznym - w przypadku transakcji handlowych, w których dłużnikiem nie jest podmiot publiczny będący podmiotem leczniczym.

// OBWIESZCZENIE MINISTRA ROZWOJU W SPRAWIE WYSOKOŚCI ODSETEK USTAWOWYCH ZA OPÓŹNIENIE W TRANSAKCJACH HANDLOWYCH

// z dnia 2 lipca 2020 r. (M.P. z 2020 r. poz. 610)

// Na podstawie art. 11c ustawy z dnia 8 marca 2013 r. o przeciwdziałaniu nadmiernym opóźnieniom w transakcjach handlowych (Dz.U. z 2020 r. poz. 935 i 1086) ogłasza się, że wysokość odsetek ustawowych za opóźnienie w transakcjach handlowych, od dnia 1 lipca 2020 r. do dnia 31 grudnia 2020 r., wynosi:
// 1) 8,1% w stosunku rocznym - w przypadku transakcji handlowych, w których dłużnikiem jest podmiot publiczny będący podmiotem leczniczym;
// 2) 10,1% w stosunku rocznym - w przypadku transakcji handlowych, w których dłużnikiem nie jest podmiot publiczny będący podmiotem leczniczym.

// OBWIESZCZENIE MINISTRA ROZWOJU, PRACY I TECHNOLOGII W SPRAWIE WYSOKOŚCI ODSETEK USTAWOWYCH ZA OPÓŹNIENIE W TRANSAKCJACH HANDLOWYCH

// z dnia 28 grudnia 2020 r. (M.P. z 2020 r. poz. 1212)

// Na podstawie art. 11c ustawy z dnia 8 marca 2013 r. o przeciwdziałaniu nadmiernym opóźnieniom w transakcjach handlowych (Dz.U. z 2020 r. poz. 935 i 1086) ogłasza się, że wysokość odsetek ustawowych za opóźnienie w transakcjach handlowych, od dnia 1 stycznia 2021 r. do dnia 30 czerwca 2021 r., wynosi:
// 1) 8,1% w stosunku rocznym - w przypadku transakcji handlowych, w których dłużnikiem jest podmiot publiczny będący podmiotem leczniczym;
// 2) 10,1% w stosunku rocznym - w przypadku transakcji handlowych, w których dłużnikiem nie jest podmiot publiczny będący podmiotem leczniczym.

export const INTEREST_TRANSACTION_PERIODS: InterestLawData[] = [
  {
    start: moment('2016-01-01').startOf('day'),
    end: moment('2019-12-31').endOf('day'),
    interestRate: 0.095,
  },
  {
    start: moment('2020-01-01').startOf('day'),
    end: moment('2020-06-30').endOf('day'),
    interestRate: 0.115,
  },
  {
    start: moment('2020-07-01').startOf('day'),
    end: moment('2021-12-31').endOf('day'),
    interestRate: 0.101,
  },
  {
    start: moment('2022-01-01').startOf('day'),
    end: moment('2022-06-30').endOf('day'),
    interestRate: 0.1175,
  },
  {
    start: moment('2022-07-01').startOf('day'),
    end: moment('2022-12-31').endOf('day'),
    interestRate: 0.16,
  },
  {
    start: moment('2023-01-01').startOf('day'),
    end: moment().endOf('day').add(60, 'days'),
    interestRate: 0.1675,
  },
];

// OBWIESZCZENIE MINISTRA SPRAWIEDLIWOŚCI W SPRAWIE WYSOKOŚCI ODSETEK USTAWOWYCH ZA OPÓŹNIENIE
// z dnia 7 stycznia 2016 r. (M.P. z 2016 r. poz. 47)

// Na podstawie art. 481 § 24 ustawy z dnia 23 kwietnia 1964 r. - Kodeks cywilny (Dz.U. z 2014 r. poz. 121, z późn. zm.1)) ogłasza się,
// że poczynając od dnia 1 stycznia 2016 r. wysokość odsetek ustawowych za opóźnienie wynosi 7% w stosunku rocznym.

// OBWIESZCZENIE MINISTRA SPRAWIEDLIWOŚCI W SPRAWIE WYSOKOŚCI ODSETEK USTAWOWYCH ZA OPÓŹNIENIE
// z dnia 14 lipca 2020 r. (M.P. z 2020 r. poz. 627)

// Na podstawie art. 481 § 24 ustawy z dnia 23 kwietnia 1964 r. - Kodeks cywilny (Dz.U. z 2019 r. poz. 1145 i 1495 oraz z 2020 r. poz. 875) ogłasza się, co następuje:
// 1) poczynając od:
// a) dnia 9 kwietnia 2020 r. do dnia 28 maja 2020 r. wysokość odsetek ustawowych za opóźnienie wynosiła 6% w stosunku rocznym,
// b) dnia 18 marca 2020 r. do dnia 8 kwietnia 2020 r. wysokość odsetek ustawowych za opóźnienie wynosiła 6,5% w stosunku rocznym;
// 2) poczynając od dnia 29 maja 2020 r. wysokość odsetek ustawowych za opóźnienie wynosi 5,6% w stosunku rocznym.

export const INTEREST_LATE_PERIODS: InterestLawData[] = [
  {
    start: moment('2016-01-01').startOf('day'),
    end: moment('2020-03-17').endOf('day'),
    interestRate: 0.07,
  },
  {
    start: moment('2020-03-18').startOf('day'),
    end: moment('2020-04-08').endOf('day'),
    interestRate: 0.065,
  },
  {
    start: moment('2020-04-09').startOf('day'),
    end: moment('2020-05-28').endOf('day'),
    interestRate: 0.06,
  },
  {
    start: moment('2020-05-29').startOf('day'),
    end: moment('2021-10-06').endOf('day'),
    interestRate: 0.056,
  },
  {
    start: moment('2021-10-07').startOf('day'),
    end: moment('2021-11-03').endOf('day'),
    interestRate: 0.06,
  },
  {
    start: moment('2021-11-04').startOf('day'),
    end: moment('2021-12-08').endOf('day'),
    interestRate: 0.0675,
  },
  {
    start: moment('2021-12-09').startOf('day'),
    end: moment('2022-01-04').endOf('day'),
    interestRate: 0.0725,
  },
  {
    start: moment('2022-01-05').startOf('day'),
    end: moment('2022-02-08').endOf('day'),
    interestRate: 0.0775,
  },
  {
    start: moment('2022-02-09').startOf('day'),
    end: moment('2022-03-08').endOf('day'),
    interestRate: 0.0825,
  },
  {
    start: moment('2022-03-09').startOf('day'),
    end: moment('2022-04-06').endOf('day'),
    interestRate: 0.09,
  },
  {
    start: moment('2022-04-07').startOf('day'),
    end: moment('2022-05-05').endOf('day'),
    interestRate: 0.1,
  },
  {
    start: moment('2022-05-06').startOf('day'),
    end: moment('2022-06-08').endOf('day'),
    interestRate: 0.1075,
  },
  {
    start: moment('2022-06-09').startOf('day'),
    end: moment('2022-07-07').endOf('day'),
    interestRate: 0.115,
  },
  {
    start: moment('2022-07-08').startOf('day'),
    end: moment('2022-09-07').endOf('day'),
    interestRate: 0.12,
  },
  {
    start: moment('2022-09-08').startOf('day'),
    end: moment('2023-09-06').endOf('day'),
    interestRate: 0.1225,
  },
  {
    start: moment('2023-09-07').startOf('day'),
    end: moment().endOf('day').add(60, 'days'),
    interestRate: 0.115,
  },
];

// OBWIESZCZENIE MINISTRA SPRAWIEDLIWOŚCI W SPRAWIE WYSOKOŚCI ODSETEK USTAWOWYCH
// z dnia 7 stycznia 2016 r. (M.P. z 2016 r. poz. 46)

// Na podstawie art. 359 § 4 ustawy z dnia 23 kwietnia 1964 r. - Kodeks cywilny (Dz.U. z 2014 r. poz. 121, z późn. zm.1)) ogłasza się,
// że poczynając od dnia 1 stycznia 2016 r. wysokość odsetek ustawowych wynosi 5% w stosunku rocznym.

// OBWIESZCZENIE MINISTRA SPRAWIEDLIWOŚCI W SPRAWIE WYSOKOŚCI ODSETEK USTAWOWYCH
// z dnia 14 lipca 2020 r. (M.P. z 2020 r. poz. 626)

// Na podstawie art. 359 § 4 ustawy z dnia 23 kwietnia 1964 r. - Kodeks cywilny (Dz.U. z 2019 r. poz. 1145 i 1495 oraz z 2020 r. poz. 875) ogłasza się, co następuje:
// 1) poczynając od:
// a) dnia 9 kwietnia 2020 r. do dnia 28 maja 2020 r. wysokość odsetek ustawowych wynosiła 4% w stosunku rocznym,
// b) dnia 18 marca 2020 r. do dnia 8 kwietnia 2020 r. wysokość odsetek ustawowych wynosiła 4,5% w stosunku rocznym;
// 2) poczynając od dnia 29 maja 2020 r. wysokość odsetek ustawowych wynosi 3,6% w stosunku rocznym.

// OBWIESZCZENIE
// MINISTRA SPRAWIEDLIWOŚCI
// z dnia 29 listopada 2021 r.
// w sprawie wysokości odsetek ustawowych
// Na podstawie art. 359 § 4 ustawy z dnia 23 kwietnia 1964 r. – Kodeks cywilny (Dz. U. z 2020 r. poz. 1740 i 2320
// oraz z 2021 r. poz. 1509) ogłasza się, że poczynając od dnia 4 listopada 2021 r. wysokość odsetek ustawowych wynosi
// 4,75% w stosunku rocznym.

export const INTEREST_CAPITAL_PERIODS: InterestLawData[] = [
  {
    start: moment('2016-01-01').startOf('day'),
    end: moment('2020-03-17').endOf('day'),
    interestRate: 0.05,
  },
  {
    start: moment('2020-03-18').startOf('day'),
    end: moment('2020-04-08').endOf('day'),
    interestRate: 0.045,
  },
  {
    start: moment('2020-04-09').startOf('day'),
    end: moment('2020-05-28').endOf('day'),
    interestRate: 0.04,
  },
  {
    start: moment('2020-05-29').startOf('day'),
    end: moment('2021-10-06').endOf('day'),
    interestRate: 0.036,
  },
  {
    start: moment('2021-10-07').startOf('day'),
    end: moment('2021-11-03').endOf('day'),
    interestRate: 0.04,
  },
  {
    start: moment('2021-11-04').startOf('day'),
    end: moment('2021-12-08').endOf('day'),
    interestRate: 0.0475,
  },
  {
    start: moment('2021-12-09').startOf('day'),
    end: moment('2022-01-04').endOf('day'),
    interestRate: 0.0525,
  },
  {
    start: moment('2022-01-05').startOf('day'),
    end: moment('2022-02-08').endOf('day'),
    interestRate: 0.0575,
  },
  {
    start: moment('2022-02-09').startOf('day'),
    end: moment('2022-03-08').endOf('day'),
    interestRate: 0.0625,
  },
  {
    start: moment('2022-03-09').startOf('day'),
    end: moment('2022-04-06').endOf('day'),
    interestRate: 0.07,
  },
  {
    start: moment('2022-04-07').startOf('day'),
    end: moment('2022-05-05').endOf('day'),
    interestRate: 0.08,
  },
  {
    start: moment('2022-05-06').startOf('day'),
    end: moment('2022-06-08').endOf('day'),
    interestRate: 0.0875,
  },
  {
    start: moment('2022-06-09').startOf('day'),
    end: moment('2022-07-07').endOf('day'),
    interestRate: 0.095,
  },
  {
    start: moment('2022-07-08').startOf('day'),
    end: moment('2022-09-07').endOf('day'),
    interestRate: 0.1,
  },
  {
    start: moment('2022-09-08').startOf('day'),
    end: moment('2023-09-06').endOf('day'),
    interestRate: 0.1025,
  },
  {
    start: moment('2023-09-07').startOf('day'),
    end: moment().endOf('day').add(60, 'days'),
    interestRate: 0.095,
  },
];

export const INTEREST_MED_PUBLIC_PERIODS: InterestLawData[] = [
  {
    start: moment('2016-01-01').startOf('day'),
    end: moment('2020-06-30').endOf('day'),
    interestRate: 0.095,
  },
  {
    start: moment('2020-07-01').startOf('day'),
    end: moment('2021-12-31').endOf('day'),
    interestRate: 0.081,
  },
  {
    start: moment('2022-01-01').startOf('day'),
    end: moment('2022-06-30').endOf('day'),
    interestRate: 0.0975,
  },
  {
    start: moment('2022-07-01').startOf('day'),
    end: moment('2022-12-31').endOf('day'),
    interestRate: 0.14,
  },
  {
    start: moment('2023-01-01').startOf('day'),
    end: moment().endOf('day').add(60, 'days'),
    interestRate: 0.1475,
  },
];
