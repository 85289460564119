import { Col, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ComponentChildren } from '../../../@types';
import logo from '../../../assets/logo.svg';
import useWindowSize from '../../../utils/hooks/useWindowSize';
import { useTypedSelector } from '../../../store';
import { GRID_MD, RowExt, ScreenLayout } from '../../../styles';
import Icon from '../../../styles/Icon';
import { subscriptionStyle } from '../../../styles/subscriptionStyles';
import { ImageLogo } from '../../Home/Menu/MenuLogo';
import AnimOpacity from '../Anim/AnimOpacity';
import DotsEnding from '../DotsEnding';

const SubExistsContainer = ({ children }: ComponentChildren) => {
  const { width } = useWindowSize();
  const subscriptionType = useTypedSelector(state =>
    state.user.subscriptionType);
  return (
    <AnimOpacity>
      <ScreenLayout>
        <FullRow justify="center">
          <Wrapper>
            <RowExt
              fullWidth
              paddingTop="md"
              paddingBottom={width >= GRID_MD ? 72 : 32}
            >
              <Col
                xs={{
                  offset: 2,
                  span:20,
                }}
              >
                <Link
                  to="/"
                >
                  <ImageLogo
                    src={logo}
                  />
                </Link>
              </Col>
            </RowExt>
            <RowExt
              fullWidth
              style={{ flex:1 }}
            >
              <Col
                xs={{
                  offset: 2,
                  span:20,
                }}
              >
                <RowExt justify="center">
                  <IconStyled
                    iconType={subscriptionStyle(subscriptionType).icon}
                    height="32"
                    width="32"
                    fill={subscriptionStyle(subscriptionType).color}
                  />
                </RowExt>
                <RowExt justify="center">
                  <TitleStyled
                    level={3}
                  >
                    {subscriptionStyle(subscriptionType).name}
                  </TitleStyled>
                </RowExt>
                {children}
              </Col>
            </RowExt>
            <DotsEnding />
          </Wrapper>
        </FullRow>
      </ScreenLayout>
    </AnimOpacity>
  );
};

export default SubExistsContainer;

const Wrapper = styled.div`
  width: 425px;
  text-align: start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const FullRow = styled(RowExt)`
  height: 100vh;
`;

const IconStyled = styled(Icon)`
  margin: 0px 0 24px 0;
`;

const TitleStyled = styled(Typography.Title)`
   letter-spacing: 1.2;
`;