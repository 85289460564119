import { Col } from 'antd';
import React from 'react';
import { useParams } from 'react-router';
import ResultActions from '../components/ResultActions/ResultLsActions';
import SumUpData from '../components/SumUp/SumUpData';
import AnimOpacity from '../components/UI/Anim/AnimOpacity';
import ResultContainer from '../components/UI/Containers/ResultContainer';
import DotsEnding from '../components/UI/DotsEnding';
import NavPrivate from '../components/UI/Nav/NavPrivate';
import useGetResultLs from '../utils/hooks/useGetResultLs';
import { RowExt } from '../styles';

const ResultLs = () => {
  const params = useParams<{
    id?: string
  }>();
  const {  recordName, editDate } = useGetResultLs(params?.id ?? '');

  return (
    <ResultContainer>
      <NavPrivate />
      <AnimOpacity>
        <RowExt marginTop="bg">
          <Col
            lg={{
              offset:0,
              span:24,
            }}
            xs={{
              span: 20, offset:2
            }}
          >
            <SumUpData
              title={recordName}
              desc={`Ostatnia edycja: ${editDate}`}
            >
              <ResultActions
                {...{
                  recordName,
                  saveId: params?.id,
                }}
              />
            </SumUpData>
            <DotsEnding />
          </Col>
        </RowExt>
      </AnimOpacity>
    </ResultContainer>
  );
};

export default ResultLs;


