import { DeleteOutlined, EditOutlined, MergeCellsOutlined } from '@ant-design/icons';
import { Button, Checkbox, Divider, List, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React from 'react';
import styled from 'styled-components';

import { BLACK, GREY_BORDER } from '../../styles';

export const EvidListItem = ({
  onSelect,
  onDelete,
  onMerge,
  onEdit,
  pages = 1,
  description,
  title,
  children,
  checked,
  mergeDisabled,
}:{
  checked: boolean,
  mergeDisabled: boolean,
  onSelect: (e: CheckboxChangeEvent) => void,
  onDelete: () => void,
  onMerge: () => void,
  onEdit: () => void,
  pages?: number
  description: React.ReactNode | string
  title: React.ReactNode | string,
  children?: React.ReactNode,
}) => {
  return (
    <ListItemStyled
      actions={[
        <div key="0">
          <Typography.Text type="secondary">Ilość stron</Typography.Text>
          <NumberStyled>
            {pages}
          </NumberStyled>
        </div>,
        <Button
          key="2"
          icon={<MergeCellsOutlined />}
          type="text"
          onClick={onMerge}
          disabled={mergeDisabled}
        />,
        <Button
          key="1"
          icon={<EditOutlined />}
          type="primary"
          onClick={onEdit}
        />,
        <Button
          key="3"
          icon={<DeleteOutlined />}
          type="text"
          onClick={onDelete}
        /> ]}
    >
      <Checkbox
        checked={checked}
        onChange={onSelect}
      />
      <Divider
        type='vertical'
      />
      {children}
      <List.Item.Meta
        title={title}
        description={description}
      />
    </ListItemStyled>
  );
};

const NumberStyled = styled.span`
   font-size: 14px;
   padding-left:12px;
   font-weight: 500;
   color:${BLACK};
`;

const ListItemStyled = styled(List.Item)`
   padding: 12px;
   margin-bottom: 12px;
   border-bottom: 1px ${GREY_BORDER} dashed;
`;