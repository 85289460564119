/* eslint-disable react/jsx-props-no-multi-spaces */
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Divider, Popconfirm, Table } from 'antd';
import { AnimatePresence, motion } from 'framer-motion';
import update from 'immutability-helper';
import _ from 'lodash';
import React, { memo, useCallback, useRef } from 'react';
import { createDndContext, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { shallowEqual } from 'react-redux';
import styled from 'styled-components';

import { useAppDispatch, useTypedSelector } from '../../../../store';
import { editKeyUpdated } from '../../../../store/edit';
import { interestAllDataChanged, InterestItem, paymentDataChanged } from '../../../../store/interestStorage';
import { GRID_SM } from '../../../../styles';
import { toUnit } from '../../../../utils/helpers/money';
import useWindowSize from '../../../../utils/hooks/useWindowSize';
import DragableBodyRow from '../../../UI/Table/DragableBodyRow';


export type InterestTableData = InterestItem & {
  startingDate: string,
  endDate: string,
  maturityDate: string | null,
  partialSum: number,
};

const RNDContext = createDndContext(HTML5Backend);

export const shortenString = (str?: string | null | number) => {
  if (str && _.isString(str)){
    if (str.length > 14) {
      return `${str.substring(0, 14)  }...`;
    }
    return str;
  }
  return str;
};


const InterestFormTable = () => {
  const  interestDataFormatted =  useTypedSelector(state => {
    const { interestData } = state.interestStorage;
    return interestData.map(interestItem => {
      return {
        ...interestItem,
        startingDate: interestItem.startingDate.format('DD-MM-YYYY'),
        endDate:interestItem.endDate.format('DD-MM-YYYY'),
        maturityDate: interestItem.maturityDate ? interestItem.maturityDate.format('DD-MM-YYYY') : null,
        partialSum: toUnit(interestItem.partialSum),
      };
    });
  });

  const { width } = useWindowSize();

  const  { interestData, paymentData } = useTypedSelector(state =>
    state.interestStorage, shallowEqual);

  const dispatch = useAppDispatch();
  const components = { body: { row: DragableBodyRow } };

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = interestData[dragIndex];
      const data = [...interestData];
      const updateData = () => {
        const a = update(data, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        });
        return a;
      };
      const updatedData = updateData();
      dispatch(interestAllDataChanged(updatedData));
    },
    [dispatch, interestData]
  );

  const manager = useRef(RNDContext);

  const onDelete = ( key:string | undefined) => {
    const data = [...interestData];
    data.splice(data.findIndex((i) => {
      return i.key === key;
    }), 1);
    const paymentsWithoutAssignments = paymentData.map(paymentItem => {
      const assignmentsWoItem = paymentItem.assignedTo.filter(paymentAssignment =>
        key !== paymentAssignment.key);
      return {
        ...paymentItem,
        assignedTo: assignmentsWoItem,
      };
    });
    dispatch(paymentDataChanged(paymentsWithoutAssignments));
    dispatch(interestAllDataChanged(data));
  };


  const onEdit = (keyToFind:string | undefined) => {
    const interestItemToEdit = interestData.find(interestItem =>
      keyToFind !== undefined && keyToFind === interestItem.key);
    if (interestItemToEdit){
      dispatch(editKeyUpdated(interestItemToEdit.key));
    }
  };

  const columns = [
    {
      title: 'Data początkowa',
      dataIndex: 'startingDate',
      key: 'startingDate',
    },
    {
      title: 'Data końcowa',
      dataIndex: 'endDate',
      key: 'endDate',
    },
    {
      title: 'Kwota główna',
      dataIndex: 'partialSum',
      key: 'partialSum',
    },
    {
      title: '',
      dataIndex: '',
      key: 'x',
      render: (record:InterestTableData) => {
        return (
          <>
            <Popconfirm
              title="Usunąć, jesteś pewna/pewny?"
              onConfirm={() =>
                onDelete(record.key)}
            >
              <DeleteOutlined />
            </Popconfirm>
            <EditOutlined
              onClick={() =>
                onEdit(record.key)}
              style={{ marginLeft:18 }}
            />
          </>
        );
      },
    },
  ];

  const columnsXS = [
    {
      title: 'Kwota główna',
      dataIndex: 'partialSum',
      key: 'partialSum',
    },
    {
      title: '',
      dataIndex: '',
      key: 'x',
      render: (record:InterestTableData) => {
        return (
          <>
            <Popconfirm
              title="Usunąć, jesteś pewna/pewny?"
              onConfirm={() =>
                onDelete(record.key)}
            >
              <DeleteOutlined />
            </Popconfirm>
          </>
        );
      },
    },
    {
      title: '',
      dataIndex: '',
      key: 'x',
      render: (record:InterestTableData) => {
        return (
          <>
            <EditOutlined
              onClick={() =>
                onEdit(record.key)}
              style={{ marginLeft:18 }}
            />
          </>
        );
      },
    },
  ];

  const expandableRender = (record: InterestTableData) => {
    const createColumns = () => {
      const columns2nd = [];
      if (record.maturityDate){
        columns2nd.push({
          title: () => {
            return (
              <SecondColumn>
                Data wymagalności
              </SecondColumn>
            );
          },
          dataIndex: 'maturityDate',
          key: 'maturityDate',
          render: () => {
            return (
              <SecondColumn>
                {record.maturityDate}
              </SecondColumn>
            );
          },
        });
      }
      if (record.factualBasis){
        columns2nd.push({
          title: 'Podstawa faktyczna',
          dataIndex: 'factualBasis',
          key: 'factualBasis',
          render: () =>
            shortenString(record.factualBasis)
        });
      }
      if (record.accountingDoc){
        columns2nd.push({
          title: 'Dokument',
          dataIndex: 'accountingDoc',
          key: 'accountingDoc',
          render: () =>
            shortenString(record.accountingDoc)
        });
      }
      if (record.legalBasis){
        columns2nd.push({
          title: 'Podstawa prawna',
          dataIndex: 'legalBasis',
          key: 'legalBasis',
          render: () =>
            shortenString(record.legalBasis)
        });
      }
      return columns2nd;
    };


    if ( record.legalBasis
      || record.maturityDate){
      const records = [{ ...record }];
      return (
        <Table columns={createColumns()} dataSource={records} pagination={false} />
      );
    }
    return null;
  };

  const isExpandable = (record: InterestTableData) => {
    if (_.isString(record.legalBasis)
    || _.isString(record.maturityDate)){
      return true;
    } return false;
  };

  const renderTable = () => {
    return (
      // @ts-ignore
      <DndProvider manager={manager.current.dragDropManager}>
        <Table
        // @ts-ignore
          dataSource={interestDataFormatted}
          columns={width >= GRID_SM ? columns : columnsXS}
          size="small"
          className="components-table-demo-nested"
          expandable={width >= GRID_SM ? {
            expandedRowRender: expandableRender,
            rowExpandable: isExpandable,
          } : undefined}
          components={components}
    // @ts-ignore
          onRow={(record, index) =>

            ({
              index,
              moveRow,
            })}
        />
      </DndProvider>
    );
  };


  return (
    <>
      {!_.isEmpty(interestDataFormatted) ? (
        <AnimatePresence>
          <motion.div
            transition={{
              ease: 'easeInOut', duration: 1,
            }}
            initial={{ opacity: !_.isEmpty(interestDataFormatted) ? 1 : 0 }}
            animate={{ opacity: !_.isEmpty(interestDataFormatted) ? 1 : 0 }}
            exit={{ opacity: 0 }}
          >
            <Divider />
            { renderTable()}
          </motion.div>

        </AnimatePresence>
      ) : (
        <>
        </>
      )}
      <Divider />
    </>
  );
};



export default memo(InterestFormTable);


const SecondColumn = styled.span`
  margin-left: 16px;
`;