/* eslint-disable @typescript-eslint/no-explicit-any */
import { DocPage } from '../../store/impOcr';
import { classifyDocBinary } from './classifyDoc';
import { extractDoc } from './DataExtraction/extractDoc';
import { RGX_SPACES_MORE } from './DataExtraction/regexExtraction';

type Docs = {
  doc: string,
  pages: DocPage[]
}[];


export const createOcrItems =  (ocrData: Docs) =>
  Promise.all(ocrData.map(async ({ doc, pages }) => {

    // 1. check AI result - only first time

    const docType = await classifyDocBinary(doc);

    // 2. get data from algorithms for doc

    const extractedData =  extractDoc(doc.replace(RGX_SPACES_MORE, docType), docType);

    return ({
      doc,
      docType,
      correctedDocType:docType,
      extractedData,
      docResults: extractedData,
      correctedDocResults: extractedData,
      pages,
    });
  }));
