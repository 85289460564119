import { Col, Statistic } from 'antd';
import _ from 'lodash';
import React from 'react';

export const EvidCardItem = ({
  title,
  value,
  style,
}:{
  title: string,
  value: string,
  style?: React.CSSProperties,
}) => {
  return (
    <Col span={10}>
      <Statistic
        title={title}
        value={_.truncate(value, {
          'length': 14,
          'separator': ' '
        })}
        style={style}
        prefix=""
        decimalSeparator=""
        groupSeparator=""
      />
    </Col>
  );
};
