import { Col, Row, Typography } from 'antd';
import React, { ReactNode } from 'react';
import { TitleAndDesc, ComponentChildren } from '../../../@types';
import { RowExt } from '../../../styles';

const FaqParagraph = ({
  title,
  desc,
  color,
  children,
}: TitleAndDesc & ComponentChildren & {
  color: string;
  children?: ReactNode;
}) => {
  return (
    <>
      <Row>
        <Col span={2} style={{ borderLeft: `1px solid${color}` }} />
        <Col span={20}>
          <Typography.Title level={5}>{title}</Typography.Title>
        </Col>
      </Row>
      <RowExt marginTop="sm" marginBottom="md">
        <Col span={2} />
        <Col span={20}>
          <Typography.Text>{desc}</Typography.Text>
        </Col>
      </RowExt>
      {children ? (
        <RowExt marginTop="sm" marginBottom="md">
          <Col span={2} />
          <Col span={20}>{children}</Col>
        </RowExt>
      ) : null}
    </>
  );
};

export default FaqParagraph;
