import { Col } from 'antd';
import _ from 'lodash';
import React from 'react';
import { shallowEqual } from 'react-redux';

import EvidEdit from '../components/Evidence/EvidEdit';
import ImpOcrCommOpts from '../components/Import/ImportOcr/ImpOcrCommOpts';
import { ImpOcrEndDate } from '../components/Import/ImportOcr/ImpOcrEndDate';
import ImpOcrEvidences from '../components/Import/ImportOcr/ImpOcrEvidences';
import ImpOcrFiles from '../components/Import/ImportOcr/ImpOcrFiles/ImpOcrFiles';
import ImpOcrSteps from '../components/Import/ImportOcr/ImpOcrSteps';
import ImpOcrSumUp from '../components/Import/ImportOcr/ImpOcrSumUp';
import AnimOpacity from '../components/UI/Anim/AnimOpacity';
import PrivateContainer from '../components/UI/Containers/PrivateContainer';
import DotsEnding from '../components/UI/DotsEnding';
import NavPrivate from '../components/UI/Nav/NavPrivate';
import { useTypedSelector } from '../store';
import { RowExt } from '../styles';


const ImportPdf = () => {
  const {  editedIndex } = useTypedSelector(state =>
    state.impOcr, shallowEqual);

  if (_.isNumber(editedIndex)){
    return <EvidEdit />;
  }
  return (
    <PrivateContainer>
      <AnimOpacity>
        <NavPrivate />
        <RowExt marginTop="md" fullWidth>
          <Col
            lg={{
              offset:0,
              span:24,
            }}
            xs={{
              offset:2,
              span: 20,
            }}
          >
            <ImpOcrSteps />
            <ImportPdfActions />
          </Col>
        </RowExt>
      </AnimOpacity>
      <DotsEnding />
    </PrivateContainer>
  );
};

export default ImportPdf;

const ImportPdfActions = () => {
  const step = useTypedSelector(state =>
    state.impOcr.step);
  if (step === 0) return <ImpOcrFiles />;
  if (step === 1) return <ImpOcrEvidences />;
  if (step === 2) return <ImpOcrEndDate />;
  if (step === 3) return <ImpOcrCommOpts />;
  return <ImpOcrSumUp />;
};

