import React from 'react';
import { useHistory } from 'react-router';
import LoginForm from '../components/Login/LoginForm';
import AuthContainer from '../components/UI/Containers/AuthContainer';


const Login = () => {
  const history = useHistory();
  return (
    <AuthContainer
      title="Zaloguj się"
      desc="Wybierz metodę logowania"
    >
      <LoginForm
        goToSignUp={() =>
          history.push('/signUp')}
      />
    </AuthContainer>
  );
};

export default Login;

