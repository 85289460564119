import { DollarOutlined, EuroOutlined, PoundOutlined } from '@ant-design/icons';
import { Alert, Button, Descriptions, Typography } from 'antd';
import moment from 'moment';
import React from 'react';
import { shallowEqual } from 'react-redux';
import { useLocation } from 'react-router';
import { LocationState } from '../../../@types';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { currencyReset } from '../../../store/currency';
import { partialSumUpdated } from '../../../store/interestForm';
import { GRID_SM, RowExt } from '../../../styles';
import { DescFullWidth } from '../../../styles/ui';
import { formatPLN } from '../../../utils/helpers/formatNumber';
import { toUnit } from '../../../utils/helpers/money';
import useWindowSize from '../../../utils/hooks/useWindowSize';
import { ResultText } from '../../SumUp/Details/DetailsPartialComp';
import { WpsTitle } from '../../SumUp/WpsFees/WpsTitle';
import FormAlert from '../../UI/Alerts/Alerts';

const { Item: DescItem } = Descriptions;

const { Text } = Typography;

const CurrencyTypeRender = () => {
  const result = useTypedSelector(state =>
    state.currency.result);
  switch (result.currencyType) {
    case 'eur':
      return (
        <Text>
          EURO
          {' '}
          <EuroOutlined />
        </Text>
      );
    case 'usd':
      return (
        <Text>
          USD
          {' '}
          <DollarOutlined />
        </Text>
      );
    case 'gbp':
      return (
        <Text>
          GBP
          {' '}
          <PoundOutlined />
        </Text>
      );
    default:
      return (
        <Text>
          CHf
        </Text>
      );
  }
};


const CurrencyResult = () => {
  const { width } = useWindowSize();
  const dispatch = useAppDispatch();
  const location = useLocation<LocationState>();
  const isScreen = location.pathname === '/currency';
  const {
    result,
    isResultVisible,
    error,
    errorMessage,
  } = useTypedSelector(state =>
    state.currency, shallowEqual);

  const add = () => {
    dispatch(partialSumUpdated(result.convertedSum));
    dispatch(currencyReset());
  };

  if (error){
    return (
      <FormAlert
        isOn={error}
        message={errorMessage}
        type="error"
      />
    );
  }

  if (isResultVisible){
    return (
      <>
        <WpsTitle title="Wynik" />
        {result.isWeekendOrHoliday ? (
          <>
            <Alert
              message={`Wybrana przez Ciebie data tj. ${moment(result.convertDate).format('DD-MM-YYYY')} jest sobotą/niedzielą albo świętem. W tym dniu NBP nie publikował kursu. Został przyjęty pierwszy wcześniejszy dzień, w którym dostępny był kurs tzn. ${moment(result.exchangeDate).format('DD-MM-YYYY')}.`}
              type="info"
            />
            <RowExt marginTop="sm" />
          </>
        ) : null}
        <DescFullWidth
          layout={width > GRID_SM ? 'horizontal' : 'vertical'}
          bordered
        >
          <DescItem
            span={3}
            label={(
              <>
                <Text>
                  Wartość w
                  {' '}
                </Text>
                <CurrencyTypeRender />
                {' '}
              </>
            )}
          >
            {`${toUnit(result.convertingSum)}`}
          </DescItem>
          <DescItem
            label="Dzień ustalenia kursu"
            span={3}
          >
            {moment(result.exchangeDate).format('DD-MM-YYYY')}
          </DescItem>
          <DescItem
            label={(
              <>
                <Text>
                  Średnik kurs NBP dla
                  {' '}
                </Text>
                <CurrencyTypeRender />
                {' '}
              </>
            )}
            span={3}
          >
            {result.exchangeRate}
          </DescItem>
          <DescItem
            span={3}
            label={(
              <>
                <Text>
                  Wartość w PLN
                </Text>
                {isScreen ? null : (
                  <Button
                    onClick={add}
                    type="link"
                  >
                    dodaj jako kwotę składową
                  </Button>
                )}
              </>
            )}
          >
            <ResultText>
              {`${formatPLN(result.convertedSum)}`}
            </ResultText>
          </DescItem>
        </DescFullWidth>
      </>
    );
  }
  return null;
};

export default CurrencyResult;
