/* eslint-disable max-len */
import { EuroOutlined } from '@ant-design/icons';
import { Divider, Typography } from 'antd';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import { LocationState } from '../../../@types';


const { Title, Text, Paragraph } = Typography;

type Props = {
  children: React.ReactNode;
};

const CurrencyCard = (
  { children }: Props
) => {
  const location = useLocation<LocationState>();
  const isScreen = location.pathname === '/currency';

  return (
    <AnimatePresence>
      <motion.div
        transition={{
          ease: 'easeInOut',
          duration: 1,
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Title
          style={{
            marginTop: isScreen ? 48 : 0, marginBottom: isScreen ? 24 : 12
          }}
          level={2}
        >
          <EuroOutlined />
          {' '}
          Obliczenia walutowe
        </Title>
        <Text>Przelicz równowartość kwoty w innej walucie na złotówki w oparciu o średni kurs NBP we wskazanej dacie. Wybierz walutę i datę przeliczenia.</Text>
        <StyledParagraph
          type="secondary"
          style={{
            fontStyle: 'italic', marginTop: 12
          }}
        >
          Sytuacja często spotykana w zapisach umownych statuujących wartość roszczenia w złotówkach w oparciu o kurs waluty obcej. UWAGA: roszczenie ustalone w walucie obcej powinno być dochodzone, co do zasady, w tej walucie(por. art. 358 KC).
        </StyledParagraph>
        <Divider />
        {children}
      </motion.div>
    </AnimatePresence>
  );
};


export default CurrencyCard;


const StyledParagraph = styled(Paragraph)`
  font-style: italic;
  margin-top: 12px;
`;