import { Col, Row, Typography } from 'antd';
import parse from 'html-react-parser';
import React from 'react';
import styled from 'styled-components';
import LawBorders from '../components/Law/LawBorders';
import LawContainer from '../components/UI/Containers/LawContainer';
import { privacyIntro } from '../utils/law/privacy';
import { DashedColLeft, GREY_WHITE } from '../styles';

const PrivacyIntro = () => {
  return (
    <LawContainer>
      <LawBorders />
      <Row>
        <DashedColLeft span={1} />
        <IntroContainer span={21}>
          <IntroParagraph>
            {privacyIntro.p1}
          </IntroParagraph>
          <IntroParagraph>
            {parse(privacyIntro.p2)}
          </IntroParagraph>
          <IntroParagraph>
            {parse(privacyIntro.p3)}
          </IntroParagraph>
        </IntroContainer>
      </Row>
    </LawContainer>
  );
};

export default PrivacyIntro;

const IntroContainer = styled(Col)`
   background: ${GREY_WHITE};
   padding: 24px;
`;
const IntroParagraph = styled(Typography.Paragraph)`
   font-family: Fira Mono, monospace;
`;