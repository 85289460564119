export const SUB_49_TEST = 'price_1JEaxCA1d6mqSuKeyxAXaXfo';
export const SUB_49_LIVE = 'price_1JEaxFA1d6mqSuKeu6I8nA62';

export const SUB_50_TEST = 'price_1JEUu6A1d6mqSuKed0WLpIno';
export const SUB_50_LIVE = 'price_1JEUuZA1d6mqSuKeBms3MhP2';

export const SUB_99_TEST = 'price_1JEazrA1d6mqSuKeCkfeugIL';
export const SUB_99_LIVE = 'price_1JEb00A1d6mqSuKehP88QxGW';

export const SUB_100_TEST = 'price_1JEVJtA1d6mqSuKeyS7BiBMW';
export const SUB_100_LIVE = 'price_1JEVKCA1d6mqSuKeLo0GPluU';

export const SUB_199_TEST = 'price_1JEb4lA1d6mqSuKekCwU8a4x';
export const SUB_199_LIVE = 'price_1JEb3QA1d6mqSuKe1tsTIWVT';

export const SUB_200_TEST = 'price_1JEVOOA1d6mqSuKeElyDgrv9';
export const SUB_200_LIVE = 'price_1JEVPgA1d6mqSuKeRVMfa6J7';

export const  TAX_RATE_TEST = 'txr_1JDRGDA1d6mqSuKeXbOzAsJZ';
export const  TAX_RATE_LIVE = 'txr_1JEWPPA1d6mqSuKe93fIJjR0';

export const SUB_599_TEST = 'price_1JnnUjA1d6mqSuKe0xlkETai';
export const SUB_599_LIVE = 'price_1JoLU6A1d6mqSuKekKjjaFa3';

export const SUB_1199_TEST = 'price_1JoLFyA1d6mqSuKenjHXUt70';
export const SUB_1199_LIVE = 'price_1JoLTyA1d6mqSuKelaraA4iY';


// only for testing

// premium
export const SUB_2_5_LIVE = 'price_1JK3KsA1d6mqSuKehjdzB5JG';

export const SUB_2_5_TRIAL_1_BASIC_DAILY_TEST = 'price_1JpBmjA1d6mqSuKepKBULGxH';
export const SUB_2_5_TRIAL_1_BASIC_DAILY_LIVE = 'price_1JpBnEA1d6mqSuKerCP2NOR2';

export const SUB_2_5_TRIAL_1_STANDARD_DAILY_TEST = 'price_1JoLmjA1d6mqSuKeuvHK7jUF';
export const SUB_2_5_TRIAL_1_STANDARD_DAILY_LIVE = 'price_1JoMneA1d6mqSuKeff91jlMU';

export const SUB_2_5_TRIAL_1_STANDARD_MONTHLY_LIVE = 'price_1JpBuvA1d6mqSuKer8Ea2Ui3';
