import _ from 'lodash';
import { Moment } from 'moment';
import { InterestData, PaymentItem } from '../../../store/interestStorage';
import { RGX_MOUTHS } from '../../ML/DataExtraction/regexExtraction';
import { AllocatedItems, AUTO_ASSIGN_DATE, isAutoAssignDateValid } from '../autoAssignCommon';

const checkDateFormat = ({ transferDescription }:PaymentItem) => {
  if (_.isNull(transferDescription)) return null;
  const index = _.findIndex(RGX_MOUTHS, value =>
    value.test(transferDescription));

  const year = transferDescription.match(/2\d{3}/gi);

  if (_.isNull(year) && index === -1) return null;

  return {
    month: index === -1 ? null : index,
    year: year ? year[0] : null
  };
};


const filterDate = ({ paymentDate }:PaymentItem,
  interestData: InterestData, checks: (dueDate: Moment) => boolean) =>
  interestData.filter(interestItem =>
    isAutoAssignDateValid(paymentDate, interestItem) &&
      interestItem.dueDate && checks(interestItem.dueDate)).map(({ key }) =>
    key);


export const allocateFromDate = (paymentItem: PaymentItem,
  interestData: InterestData): AllocatedItems => {
  const dateResults = checkDateFormat(paymentItem);

  if (_.isNull(dateResults))   return {
    interestItemKeys: null,
    allocationMechanism: AUTO_ASSIGN_DATE
  };

  const {
    month,
    year
  } = dateResults;


  const curriedFilterDate = (checks:(dueDate: Moment) => boolean) =>
    filterDate(paymentItem, interestData, checks);

  if (!_.isNull(month) && !_.isNull(year)){
    const interestItemKeys = curriedFilterDate((dueDate: Moment) =>
      dueDate.month() === month &&
      dueDate.year() === _.toNumber(year));

    return {
      interestItemKeys: _.isEmpty(interestItemKeys) ? null : interestItemKeys,
      allocationMechanism: AUTO_ASSIGN_DATE
    };
  }
  if (!_.isNull(month)){
    const interestItemKeys = curriedFilterDate((dueDate: Moment) =>
      dueDate.month() === month);
    return {
      interestItemKeys: _.isEmpty(interestItemKeys) ? null : interestItemKeys,
      allocationMechanism: AUTO_ASSIGN_DATE
    };
  }

  if (!_.isNull(year)) {
    const interestItemKeys = curriedFilterDate((dueDate: Moment) =>
      dueDate.year() === _.toNumber(year));
    return {
      interestItemKeys,
      allocationMechanism: AUTO_ASSIGN_DATE
    };
  }

  return {
    interestItemKeys: null,
    allocationMechanism: AUTO_ASSIGN_DATE
  };

};
