/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Moment } from 'moment';

import { DocTypes, InvcVat, PaymentConfirmation } from '../services/ML/docTypes';
import { MATURITY_LATE_DAY, MaturityDateOpts } from './interestFormOptions';

interface ImpOcr {
  ocrData: OcrItem[],
  isListVisible: boolean,
  editedIndex: null | number,
  step: number,
  endDate: null | Moment,
  factualBasis: string | null,
  legalBasis: string | null,
  maturityDateOpts: MaturityDateOpts,
  shouldAutoAssign: boolean,
}

export type DocPage = {
  image?: string,
  input:string,
  pageNumber: number | null,
};

export interface OcrItem {
  doc: string,
  docType: DocTypes,
  correctedDocType: DocTypes,
  // TODO: seems like unnecessary
  extractedData: InvcVat | PaymentConfirmation,
  // smaller scope no need for all data
  docResults:  InvcVat | PaymentConfirmation,
  correctedDocResults: InvcVat | PaymentConfirmation,
  pages: DocPage[],
}

export type OcrData = OcrItem[];

const initialState:ImpOcr = {
  ocrData: [],
  isListVisible: false,
  editedIndex: null,
  step: 0,
  endDate: null,
  factualBasis: null,
  legalBasis: null,
  maturityDateOpts: MATURITY_LATE_DAY,
  shouldAutoAssign: false,
};

export type StartLoadingText = undefined | string;


export const ocrSlice = createSlice({
  name: 'impOcr',
  initialState,
  reducers: {
    ocrDataUpdated: (state:ImpOcr, action: PayloadAction<OcrItem[]>) => {
      state.ocrData = action.payload;
    },
    ocrItemUpdated: (state:ImpOcr, action: PayloadAction<{
      ocrItem: OcrItem,
      index: number,
    }>) => {
      state.ocrData[action.payload.index] = action.payload.ocrItem;
    },
    ocrItemRemoved: (state:ImpOcr, action: PayloadAction<number>) => {
      state.ocrData = state.ocrData.filter((value, index) =>
        index !== action.payload);
    },
    ocrItemSelected:(state:ImpOcr, action: PayloadAction<number | null>) => {
      state.editedIndex = action.payload;
    },
    evidListToggled: (state:ImpOcr) => {
      state.isListVisible = !state.isListVisible;
    },
    ocrStepUpdated: (state:ImpOcr, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
    ocrEndDateUpdated: (state:ImpOcr, action: PayloadAction<Moment | null>) => {
      state.endDate = action.payload;
    },
    ocrFactualBasisUpdated: (state:ImpOcr, action: PayloadAction<string | null>) => {
      state.factualBasis = action.payload;
    },
    ocrLegalBasisUpdated: (state:ImpOcr, action: PayloadAction<string | null>) => {
      state.legalBasis = action.payload;
    },
    ocrMaturityOptsUpdated: (state:ImpOcr, action: PayloadAction<MaturityDateOpts>) => {
      state.maturityDateOpts = action.payload;
    },
    ocrShouldAutoAssignUpdated: (state:ImpOcr, action: PayloadAction<boolean>) => {
      state.shouldAutoAssign = action.payload;
    },
    impOcrRested: () =>
      initialState
  }
});

export const {
  ocrDataUpdated,
  ocrItemUpdated,
  ocrItemRemoved,
  ocrItemSelected,
  evidListToggled,
  ocrStepUpdated,
  ocrEndDateUpdated,
  ocrFactualBasisUpdated,
  ocrLegalBasisUpdated,
  ocrMaturityOptsUpdated,
  ocrShouldAutoAssignUpdated,
  impOcrRested,
} = ocrSlice.actions;

export default ocrSlice.reducer;


