import React from 'react';
import { shallowEqual } from 'react-redux';
import styled from 'styled-components';
import { useTypedSelector } from '../../../../store';
import { GRID_SM_PX } from '../../../../styles';
import FormAlert from '../../../UI/Alerts/Alerts';


const InterestFormAlert = () => {
  const { info, infoMsg } = useTypedSelector(state =>
    state.interestFormOptions, shallowEqual);

  return (
    <>
      <FormAlert
        isOn={info}
        message={infoMsg}
      />
      {info ? <Gap /> : null}
    </>
  );
};

export default InterestFormAlert;

const Gap = styled.div`
   margin-bottom: 12px;
   @media only screen and (max-width: ${GRID_SM_PX}) {
    margin-bottom: 48px;
	}
`;
