/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash';
import { shallowEqual } from 'react-redux';
import { PeriodResult } from '../../../services/calc/interestsCalc';

import { useTypedSelector } from '../../../store';
import { JUST_WO_CAP } from '../../../store/justOpts';
import { InterestTypes, LawsuitOpts } from '../../../store/lawsuitOpts';
import { PartialResult } from '../../../store/sumUp';
import { ENT } from '../../helpers/arrStrFormat';
import { textIf } from '../../helpers/textIf';
import { setMaturityBasisText, setNoCapInt } from './justBasis';
import { periodText } from './justInterestPartials';
import { coveredInterestCalc, justAssignedPayments, justPaymentOutro, justPaymentsSumUp, paymentInterestCalcBasis, paymentsIntro } from './justPayments';

const useJustBasisPayments = () => {
  const { partialResults } = useTypedSelector(state =>
    state.sumUp, shallowEqual);
  const { justIntCap, justToPaymentDay } = useTypedSelector(state =>
    state.justOpts, shallowEqual);
  const  principalAmountOpt  = useTypedSelector(state =>
    state.sumUp.lawsuitOpts.principalAmountOpt);
  const  typeOfInterest  = useTypedSelector(state =>
    state.sumUp.lawsuitOpts.typeOfInterest);
  const  lawsuitOpts  = useTypedSelector(state =>
    state.sumUp.lawsuitOpts);

  if (principalAmountOpt === 'PRINCIPAL_APP'){
    const partialBasisText = partialResults.reduce((prev:string, result, index) => {
      if (result.maturityDate){
        const enterIfPaymentsList = textIf(ENT, !_.isEmpty(result.assignedPayments));
        return (
          prev +
          [
            /* -------------------------------- 1. intro -------------------------------- */
            basisPaymentsIntro({
              result,
              justToPaymentDay,
              typeOfInterest,
              noCap: justIntCap === JUST_WO_CAP,
              index,
              isSingle: partialResults.length === 1,
            }),

            // next points only if there are assigned payments

            /* ------------------------ 2. assigned payments list ----------------------- */
            enterIfPaymentsList,
            justAssignedPayments(result).join(ENT),

            /* --------------------------- 3. payments sum up --------------------------- */
            enterIfPaymentsList,
            justPaymentsSumUp(result, lawsuitOpts),

            /* --------------------------- 4. covered interest --------------------------- */
            justCoveredInterests(result, lawsuitOpts),

            /* ---------------------------- 5. payments outro --------------------------- */
            justPaymentOutro(result, lawsuitOpts, index),

            ENT,
          ].join('')
        );
      }
      return prev;
    }, '');
    return partialBasisText;
  }
  return '';
};

export default useJustBasisPayments;


export const basisPaymentsIntro = ({
  result,
  justToPaymentDay,
  typeOfInterest,
  noCap,
  index,
  isSingle
}:{
  result: PartialResult,
  justToPaymentDay: boolean,
  typeOfInterest: InterestTypes,
  noCap: boolean
  index: number,
  isSingle: boolean,
}) => {
  return [
    setMaturityBasisText({
      ...result,
      index,
      isSingle
    }),
    paymentsIntro(result),
    setNoCapInt({
      partialSum: result.partialSum,
      startingDate: result.startingDate,
      endDate: result.endDate,
      justToPaymentDay,
      typeOfInterest,
      noCap,
    })
  ].join('');
};

/*
   justCoveredInterest are rendered in JustCoveredInterests
*/

const justCoveredInterests = (result: PartialResult, lawsuitOpts: LawsuitOpts) => {
  const coveredResults = coveredInterestCalc(lawsuitOpts, result);

  const intrNotFulfilled = result.interestsSumCovered > 0 ?
    coveredResults.periodResults.map((periodResult: PeriodResult) =>
      periodText(
        periodResult,
        'odsetki',
        coveredResults.periodResults.length - 1 === 0
      )).join(ENT) : '';

  const intrFulFilled = result.interestsSumFulfilledCovered > 0 ?
    result.interestResultsFulfilled.map(({ interestSum, periodResults }, index) => {
      return [
        ENT,
        periodResults.map(periodResult =>
          periodText(
            periodResult,
            'odsetki',
            periodResults.length - 1 === 0
          )).join(ENT),
        ENT,
        paymentInterestCalcBasis(result, interestSum, index)
      ].join('');
    }) : '';

  return intrNotFulfilled + intrFulFilled;
};


