import { Divider } from 'antd';
import React from 'react';

import OptionsSameDate from './OptionsSameDate';


const InterestFormOptions = () =>
  (
    <>
      <Divider>{texts.options}</Divider>
      <OptionsSameDate />
    </>
  );

export default InterestFormOptions;


const texts = { options: 'Opcje' };

