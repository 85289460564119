import _ from 'lodash';

import { InterestItem, PaymentData } from '../../../store/interestStorage';
import { LawsuitOpts } from '../../../store/lawsuitOpts';
import { getIndexFromKey, getNotAssignedSum, getNotCoveredSum } from '../../assignPaymentByKey';
import { claimCalc, isPaymentFullyAssigned } from '../../calc/claim';
import { AllocationMechanism, AssignHistoryRecord, isClaimFullyCovered } from '../autoAssignCommon';

export type PayDataWithHistory = {
  paymentData: PaymentData,
  areLeftovers: boolean,
  assignHistoryRecord: AssignHistoryRecord | null
};

export type AutoAssignPaymentDirect = {
  paymentItemKey: string,
  interestItemKey: string,
  isInterestFirst: boolean,
  lawsuitOpts: LawsuitOpts,
  interestData: InterestItem[],
  paymentData: PaymentData,
  allocationMechanism: AllocationMechanism
};

export const autoAssignPaymentDirect = ({
  paymentItemKey,
  interestItemKey,
  isInterestFirst,
  lawsuitOpts,
  interestData,
  paymentData,
  allocationMechanism
}:AutoAssignPaymentDirect): PayDataWithHistory => {
  const interestItemIndex = getIndexFromKey(interestData, interestItemKey);

  const paymentItemIndex = getIndexFromKey(paymentData, paymentItemKey);

  if (isPaymentFullyAssigned(paymentData, paymentItemIndex)) {
    return {
      paymentData,
      areLeftovers: false,
      assignHistoryRecord: null
    };
  }

  const claim = claimCalc({
    interestItem: interestData[interestItemIndex],
    paymentData,
    lawsuitOpts,
  });


  if (isClaimFullyCovered(claim, isInterestFirst)) {
    return {
      paymentData,
      areLeftovers: true,
      assignHistoryRecord: {
        interestItemKey,
        allocationMechanism,
        note: 'full'
      }
    };
  }


  const notCoveredSum = getNotCoveredSum({
    interestItem: interestData[interestItemIndex],
    paymentItem: paymentData[paymentItemIndex],
    paymentData,
    lawsuitOpts,
    isInterestFirst
  });

  const notAssignedSum = getNotAssignedSum(paymentData, paymentItemIndex);

  const paymentDataCopy = _.cloneDeep(paymentData);

  const fullAssignmentSum =  notCoveredSum <  notAssignedSum
    ? notCoveredSum : notAssignedSum;

  paymentDataCopy[paymentItemIndex].assignedTo.push(
    {
      key:interestItemKey,
      sum: fullAssignmentSum,
      isInterestFirst,
    });

  if ((notCoveredSum <  notAssignedSum)) {
    return {
      paymentData: paymentDataCopy,
      areLeftovers: true,
      assignHistoryRecord: {
        interestItemKey,
        allocationMechanism,
        note: 'partial'
      }
    };
  }

  return {
    paymentData: paymentDataCopy,
    areLeftovers: false,
    assignHistoryRecord: {
      interestItemKey,
      allocationMechanism,
      note: null
    }
  };
};
