/* eslint-disable no-plusplus */
import _ from 'lodash';
import moment from 'moment';
import { uniq } from 'ramda';

import { RGX_4_DIGITS,
  RGX_CURR_EURO_STYLE,
  RGX_CURR_US_STYLE,
  RGX_DATES,
  RGX_DATES_NUM,
  RGX_DOTS_SLASHES_EMPTY,
  RGX_HTTPS_LINK,
  RGX_LINE_BRAKES,
  RGX_MMMM_PL,
  RGX_MOUTHS, RGX_SPACES_MORE } from './regexExtraction';

export const extendRelevantNames = (strArr: string[]) =>
  strArr.reduce(
    (prev: string[], curr) =>
      [
        ...prev,
        `${curr.toUpperCase()}:`,
        `${capitalize(curr)}:`,
        curr.toUpperCase(),
        capitalize(curr),
        `${curr}:`,
        curr
      ],
    []
  );

export const checkNextEl = (
  strArr: string[],
  cb: (str: string) => boolean,
  numNextEl = 4
) => {
  let counter = 0;
  while (counter < numNextEl)
    if (cb(strArr[counter])) return strArr[counter];
    else counter++;

  return null;
};

export const isDate = (str: string) =>
  RGX_DATES.test(str);

export const highestNumInNextEl = ({
  strArr,
  startingSum,
  numNextEl = 6
}: {
  strArr: string[];
  startingSum: string;
  numNextEl?: number;
}) => {
  const startingSumIndex = strArr.findIndex(value =>
    value === startingSum);

  let counter = startingSumIndex + 1;

  const sums = [];

  const max = numNextEl + startingSumIndex;

  while (counter < max)
    if (isNumeric(strArr[counter])) {
      sums.push(strArr[counter]);
      counter++;
    } else counter++;

  sums.push(startingSum);

  const arrayOfNumbers = sums.map(value =>
    convertNumeric(value));

  return Math.max(...arrayOfNumbers);
};

export const splitOnRelevantName = (str: string, relevantNames: string[]) => {
  const extendedNames = extendRelevantNames(relevantNames);

  const relevantName = extendedNames.find(value =>
    str.includes(value));

  if (_.isUndefined(relevantName)) return null;

  const docAfterName = str
    .replace(RGX_LINE_BRAKES, ' ')
    .replace(RGX_SPACES_MORE, '')
    .split(relevantName)[1];

  const arrayAfterName = docAfterName
    .replace(RGX_SPACES_MORE, '')
    .split(' ')
    .reduce(
      (prev: string[], curr) =>
        (curr === '' ? prev : [...prev, curr]),
      []
    );

  return {
    arrayAfterName,
    docAfterName,
    elAfterName: arrayAfterName[0]
  };
};

export function isNumeric(str: string) {
  if (!_.isString(str)) return false; // we only process strings!
  const formattedStr = str
    .replace(/,/g, '.')
    .replace(/\s/g, '')
    .replace(/zł/i, '')
    .replace(/pln/i, '');
  return !_.isNaN(formattedStr) && !_.isNaN(parseFloat(formattedStr));
}

export const convertNumeric = (importedSum: string) => {
  if (_.isString(importedSum) && isNumeric(importedSum)) {
    const formattedStr = importedSum
      .trim()
      .replace(/,/g, '.')
      .replace(/\s/g, '')
      .replace(/zł/i, '')
      .replace(/pln/i, '');
    return _.toNumber(formattedStr);
  }
  return 0;
};

export const capitalize = (str: string) =>
  str && str.charAt(0).toUpperCase() + str.slice(1);


export const convertStrToDay = (str: string) => {

  const formattedStr = str.trim().replace(',', '');

  // 1. replace words

  const strWoWords = RGX_MOUTHS.reduce((prev, rgx, index) => {
    if (rgx.test(formattedStr)) return formattedStr.replace(rgx, ` ${index < 9 ? '0' : ''}${index + 1}`);
    return prev;
  }, formattedStr).replace(RGX_SPACES_MORE, '');

  // 2. replace dots && slashes && empty
  const strWoDots = strWoWords.replace(RGX_DOTS_SLASHES_EMPTY, '-');

  // 3. make YYYY-MM-DD format

  if (!RGX_4_DIGITS.test(strWoDots)) return strWoDots.split('-').reverse().join('-');

  return strWoDots;
};

export const extractAllDocDates = (doc:string) => {
  const numberDates = doc
    .replace(RGX_LINE_BRAKES, ' ')
    .split(' ')
    .filter((value) =>
      RGX_DATES_NUM.test(value));

  const wordDates = doc.match(RGX_MMMM_PL) ?? [];

  const formattedDates = [...numberDates, ...wordDates]
    .map(value =>
      convertStrToDay(value)).filter(value =>
      moment(value).isValid());

  const sortedDates = _.cloneDeep(formattedDates).sort((itemA, itemB) =>
    moment(itemA).toDate().getTime() - moment(itemB).toDate().getTime());

  return uniq(sortedDates).filter(value =>
    value.length >= 9);
};



export const extractAllSums = (doc:string) => {

  const textCurr = doc.replace(RGX_LINE_BRAKES, ' ')
    .replace(/((\d{1,3}[ ](\d{3}[ ])*\d{3}|\d+)([,]\d{1,2}))/g, (x) =>
      x.replace(' ', ''))
    .split(' ')
    .join(' ')
    .replace(RGX_HTTPS_LINK, '')
    .replace(/[:]/g, ' ')
    .replace(RGX_SPACES_MORE, ' ')
    .trim();

  const currEu = textCurr.match(RGX_CURR_US_STYLE);
  const currUs  = textCurr.match(RGX_CURR_EURO_STYLE);

  const allSums = [
    ..._.isNull(currEu) ? [] : currEu.map(value =>
      convertNumeric(value)),
    ..._.isNull(currUs) ? [] : currUs.map(value =>
      convertNumeric(value)),
  ];

  return uniq(allSums).sort((a, b) =>
    b - a);
};


export const revertTextFormatting = (result: string, doc: string) => {
  const toUpper = doc.match(new RegExp(result, 'gi'));

  return toUpper ? toUpper[0] : result;
};