import { CopyOutlined, PlusOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import _ from 'lodash';
import moment, { isMoment, Moment } from 'moment';
import React, { memo, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { AppDispatch, RootState, useTypedSelector } from '../../../../store';
import { interestFormAllDataUpdated, interestFormReset } from '../../../../store/interestForm';
import { infoUpdated } from '../../../../store/interestFormOptions';
import { interestItemAdded } from '../../../../store/interestStorage';
import { ButtonFullWidth } from '../../../../styles/ui';


const copyLastItem = () =>
  (dispatch:AppDispatch, getState: () => RootState) => {
    const state = getState();
    const { interestData } = state.interestStorage;
    const [lastItem] = interestData.slice(-1);
    if (!_.isEmpty(lastItem)){
      dispatch(interestFormAllDataUpdated({
        ...lastItem, maturityDate: lastItem.maturityDate ?? null
      }));
      dispatch(infoUpdated({
        info: true,
        infoMsg: 'Dane skopiowane'
      }));
      setTimeout(() => {
        dispatch(infoUpdated({
          info: false,
          infoMsg: ''
        }));
      }, 3000);
    }
  };

const handleEndDate = ({
  isSameEndDate,
  sameEndDate,
  endDate,
}:{
  isSameEndDate: boolean,
  sameEndDate: Moment | null,
  endDate: Moment | null
}) =>  {
  if (isSameEndDate && sameEndDate) return sameEndDate;
  if (!isSameEndDate && endDate) return endDate;
  return moment();
};

const addInterestItem = () =>
  (dispatch:AppDispatch, getState: () => RootState) => {
    const state = getState();
    const { startingDate, endDate, partialSum, maturityDate, dueDate } = state.interestForm;
    const {  sameEndDate, isSameEndDate } = state.interestFormOptions;
    const { compensationOn } = state.lawsuitOpts;
    if (startingDate
    && (endDate || sameEndDate)
    && partialSum
    && dueDate
    && (compensationOn ? isMoment(maturityDate) : true)){
      const interestItem = {
        ...state.interestForm,
        key:uuidv4(),
        partialSum,
        startingDate,
        dueDate,
        endDate: handleEndDate({
          isSameEndDate,
          sameEndDate,
          endDate,
        }),
        isClaimFromFulfilled: false,
      };
      dispatch(interestItemAdded(interestItem));
      dispatch(interestFormReset());
    } else {
      dispatch(infoUpdated({
        info: true,
        infoMsg: 'Aby dodać, wpisz co najmniej: termin spełnienia świadczenia, okres naliczania odsetek i kwotę.'
      }));
      setTimeout(() => {
        dispatch(infoUpdated({
          info: false,
          infoMsg: ''
        }));
      }, 5000);
    }
  };


const InterestFormAddButton = () => {
  const dispatch = useDispatch();

  const interestData
      = useTypedSelector(state =>
        state.interestStorage.interestData);

  const {
    startingDate,
    endDate,
    dueDate,
  } =  useTypedSelector(state =>
    state.interestForm);

  const disabled = useMemo(() => {
    const mdError = dueDate
      ? moment(startingDate).toDate() < moment(dueDate).toDate()
      : false;
    const edError = moment(startingDate).isSameOrAfter(moment(endDate).toDate());
    return edError || mdError;
  }
  , [endDate, dueDate, startingDate]);


  return (
    <>
      <Row
        align="middle"
      >
        <ColRes
          sm={{
            span:12,
            order:1
          }}
          xs={{
            span:24, order:2,
          }}
        >
          <Row>
            <ButtonFullWidth
              icon={<CopyOutlined />}
              disabled={_.isEmpty(interestData)}
              onClick={() =>
                dispatch(copyLastItem())}
            >
              {texts.next}
            </ButtonFullWidth>
          </Row>
        </ColRes>
        <Col
          sm={{
            span:2,
            order:2
          }}
          xs={{
            span:0, order:2
          }}
        />
        <ColRes
          sm={{
            span:10, order:3
          }}
          xs={{
            span:24, order:1
          }}
        >
          <ButtonFullWidth
            type="primary"
            icon={<PlusOutlined />}
            onClick={() =>
              dispatch(addInterestItem())}
            disabled={disabled}
          >
            Dodaj dane
          </ButtonFullWidth>
        </ColRes>
      </Row>
    </>
  );
};



export default memo(InterestFormAddButton);


const texts = { next: 'Skopiuj dane z poprzedniego' };

export const ColRes = styled(Col)`
  	@media only screen and (max-width: 575px) {
    margin-top: 24px;
	}
`;