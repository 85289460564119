import { Typography } from 'antd';
import React from 'react';
import connectedThumb from '../../../assets/connectedThumb.png';
import { YT_MENAGE_USERS } from '../../../utils/helpers/links';
import { RED } from '../../../styles/colors';
import GuidePost from '../GuidePost';

const { Paragraph } = Typography;

const GuideConnectedUsers = () => {
  return (
    <GuidePost
      title="Dodawanie użytkowników subskrypcji"
      color={RED}
      desc={(
        <>
          <Paragraph>
            Zobacz jak zarządzać użytkownikami subskrypcji Standard i Premium
          </Paragraph>
        </>
      )}
      src={connectedThumb}
      url={YT_MENAGE_USERS}
    />
  );
};

export default GuideConnectedUsers;
