import { FullscreenExitOutlined } from '@ant-design/icons';
import { Button, Divider, Typography } from 'antd';
import _ from 'lodash';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import { claimCalc } from '../../../../services/calc/claim';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import { claimDetailsKeyUpdated } from '../../../../store/lawsuitUI';
import { RowExt } from '../../../../styles';
import { GREY_WHITE } from '../../../../styles/colors';
import { formatDate } from '../../../../utils/helpers/date';
import usePaymentsColors from '../../../../utils/hooks/usePaymentsColors';
import DetailsClaimSum from '../../../SumUp/Details/DetailsClaimSum';
import DetailsFulfilledInterestPeriods from '../../../SumUp/Details/DetailsFulfilledInterestPeriods';
import DetailsInterestsIntro from '../../../SumUp/Details/DetailsInterestsIntro';
import DetailsInterestsPeriods from '../../../SumUp/Details/DetailsInterestsPeriods';
import DetailsPeriodInterestSum from '../../../SumUp/Details/DetailsInterestsPeriodsSum';
import DetailsPartialComp from '../../../SumUp/Details/DetailsPartialComp';
import DetailsPartialHead from '../../../SumUp/Details/DetailsPartialHead';
import DetailsPayments from '../../../SumUp/Details/DetailsPayments';
import StepCard from '../../StepCard/StepCard';
import ClaimGraph from './ClaimGraph';

const DetailsClaim = () => {
  const dispatch = useAppDispatch();

  const paymentsColors = usePaymentsColors();

  const claim = useTypedSelector((state) => {
    const { lawsuitOpts } = state;
    const { paymentData } = state.interestStorage;
    const { claimDetailsKey } = state.lawsuitUI;
    const interestItem = state.interestStorage.interestData.find(({ key }) =>
      key === claimDetailsKey);
    if (interestItem){
      return   claimCalc({
        lawsuitOpts,
        paymentData,
        interestItem,
      });
    }
    return null;
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (_.isNull(claim)) return null;

  return (
    <StepCard
      title={(
        <RowExt justify="end">
          <Button
            type='dashed'
            icon={<FullscreenExitOutlined />}
            onClick={() =>
              dispatch(claimDetailsKeyUpdated(null))}
          />
        </RowExt>
)}
      desc={(
        <Typography.Title level={4}>
          Roszczenie z dnia
          {' '}
          {formatDate(claim.maturityDate ?? claim.startingDate)}
        </Typography.Title>
      )}
      dividerDisabled
    >
      <div>
        <DetailsPartialHead
          {...{ partialResult: claim }}
        />
        <RowExt marginTop="md">
          <Container>
            <ClaimGraph
              {...{
                paymentsColors,
                interestItemKey: claim.key,
                partialSum: claim.interestsSumFulfilledCovered + claim.partialSum
              }}
              horizontal
            />
          </Container>
        </RowExt>
        <DetailsPayments
          {...{
            ...claim,
            claimKey: claim.key
          }}
        />
        <DetailsClaimSum
          {...{ ...claim }}
        />
        <DetailsInterestsIntro
          {...{ ...claim }}
        />
        <DetailsFulfilledInterestPeriods
          claim={claim}
        />
        <DetailsInterestsPeriods
          {...{ partialResult: claim }}
        />
        <DetailsPeriodInterestSum
          {...{ partialResult: claim }}
        />
        <DetailsPartialComp
          {...{ partialResult: claim }}
        />
        <Divider />
      </div>
      <RowExt justify="center" marginTop="bg">
        <Button
          type='dashed'
          icon={<FullscreenExitOutlined />}
          onClick={() =>
            dispatch(claimDetailsKeyUpdated(null))}
        />
      </RowExt>
    </StepCard>
  );
};

export default DetailsClaim;


const Container = styled.div`
    display: flex;
    height: 3px;
    width: 100%;
    background: ${GREY_WHITE};
`;