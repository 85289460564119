import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { useHistory } from 'react-router';
import { AboutContainer, RowBg } from '../../styles';
import DotsEnding from './DotsEnding';


export const ScreenBackButton = (
  {
    full,
    icon,
    onClick
  }: {
    full?: boolean,
    icon?: React.ReactNode,
    onClick?: () => void
  }
) => {
  const history = useHistory();
  const goBack = () => {
    history.goBack();
  };
  return (
    <Button
      type="default"
      onClick={onClick ?? goBack}
      style={full ? { width: '100%' } : {}}
      icon={icon ?? <ArrowLeftOutlined />}
    />
  );
};

const DescScreenFooter = () => {
  return (
    <AboutContainer>
      <RowBg align="middle" justify="center">
        <ScreenBackButton />
      </RowBg>
      <DotsEnding />
    </AboutContainer>
  );
};

export default DescScreenFooter;
