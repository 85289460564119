import { Card, Col, Row, Statistic } from 'antd';
import _ from 'lodash';
import React from 'react';

import { BLUE, RowMd } from '../../../../styles';
import { RED } from '../../../../styles/colors';
import { ImportWarning } from '../../../../utils/hooks/useImpOrcValidate';

const ImpStats = (
  {
    warningData,
    numberOfImports
  }:{
    numberOfImports: number,
    warningData: {
      warnings: ImportWarning[]
    }[],
  }) => {

  const numberOfFatal = warningData.filter(value =>
    value.warnings.some(v =>
      v.isFatal === true)).length;

  const numberOfOnlyModded = warningData.length - numberOfFatal;

  if (_.isEmpty(warningData)) return null;

  return (
    <Card
      title="Importowane dokumenty - podsumowanie"
      bordered={false}
    >
      <RowMd justify='space-around'>
        <Col span={8}>
          <Row justify="center">
            <Statistic
              title="Importowane"
              value={numberOfImports}
            />
          </Row>
        </Col>
        <Col span={8}>
          <Row justify="center">
            <Statistic
              title="Modyfikacja"
              value={numberOfOnlyModded}
              valueStyle={{ color: BLUE }}
            />
          </Row>
        </Col>
        <Col span={8}>
          <Row justify="center">
            <Statistic
              title="Odmowa"
              value={numberOfFatal}
              valueStyle={{ color: RED }}
            />
          </Row>
        </Col>
      </RowMd>
    </Card>
  );
};

export default ImpStats;