/* -------------------------------------------------------------------------- */
/*                                  ML DOCS                                   */
/* -------------------------------------------------------------------------- */

export const INVC_VAT = 'INVC_VAT';
export const INVC_VAT_NEXT = 'INVC_VAT_NEXT';
export const PAYMENT_CONFIRMATION = 'PAYMENT_CONFIRMATION';


export const GENERAL_DOC = 'GENERAL_DOC';
export const INVC = 'INVC';
export const AGREEMENT = 'AGREEMENT';
export const KRS = 'KRS';
export const KW = 'KW';
export const PAYMENTS_LIST = 'PAYMENTS_LIST';
export const DEMAND_FOR_PAYMENT = 'DEMAND_FOR_PAYMENT';
export const CONFIRMATION_MAIL = 'CONFIRMATION_MAIL';
export const PROXY = 'PROXY';

export type DocTypes =
    typeof INVC_VAT
  | typeof INVC_VAT_NEXT
  | typeof PAYMENT_CONFIRMATION;

export const isInvcVat = (doc: InvcVat | PaymentConfirmation): doc is InvcVat =>
  true;
export const isPaymentConfirmation = (doc: InvcVat | PaymentConfirmation): doc is PaymentConfirmation =>
  true;

export const  docNamesMap = new Map<DocTypes, string>([
  [INVC_VAT, 'Faktura VAT'],
  [PAYMENT_CONFIRMATION, 'Potwierdzenie wpłaty']
]);

export type DocTypeTrainData = {
  input: string;
  output: DocTypes;
}[];

export type DocTypeTrainDataImage = {
  input: string;
  output: DocTypes;
  image: string;
}[];

export type DocTypeTrainItem = {
  input: string;
  output: DocTypes;
};

export interface GeneralDoc {
  createDate: string | null;
  allDocDates: string[];
}

export interface InvcVat extends GeneralDoc {
  dueDate: string | null;
  partialSum: number | null;
  accountingDoc: string | null;
  factualBasis: string | null;
  accountingDocOpts: string[];
  allSums: number[];
}

export interface PaymentConfirmation extends GeneralDoc {
  paymentDate: string | null;
  paymentTitle: string | null;
  paymentSum: number | null;
  allSums: number[];
}

