export const act1 = {
  title: 'OBWIESZCZENIE MINISTRA SPRAWIEDLIWOŚCI W SPRAWIE WYSOKOŚCI ODSETEK USTAWOWYCH ZA OPÓŹNIENIE',
  desc: 'z dnia 7 stycznia 2016 r. (M.P. z 2016 r. poz. 47)',
  pars:[
    { text: 'Na podstawie art. 481 § 24 ustawy z dnia 23 kwietnia 1964 r. - Kodeks cywilny (Dz.U. z 2014 r. poz. 121, z późn. zm.1)) ogłasza się, że poczynając od dnia 1 stycznia 2016 r. wysokość odsetek ustawowych za opóźnienie wynosi 7% w stosunku rocznym.' },
  ]
};
export const act2 = {
  title: 'OBWIESZCZENIE MINISTRA SPRAWIEDLIWOŚCI W SPRAWIE WYSOKOŚCI ODSETEK USTAWOWYCH ZA OPÓŹNIENIE',
  desc: 'z dnia 14 lipca 2020 r. (M.P. z 2020 r. poz. 627)',
  pars1:[
    { text: 'Na podstawie art. 481 § 24 ustawy z dnia 23 kwietnia 1964 r. - Kodeks cywilny (Dz.U. z 2019 r. poz. 1145 i 1495 oraz z 2020 r. poz. 875) ogłasza się, co następuje:' },
    { text: '1) poczynając od:' },
  ],
  usts: [
    { text: 'a) dnia 9 kwietnia 2020 r. do dnia 28 maja 2020 r. wysokość odsetek ustawowych za opóźnienie wynosiła 6% w stosunku rocznym,' },
    { text: 'b) dnia 18 marca 2020 r. do dnia 8 kwietnia 2020 r. wysokość odsetek ustawowych za opóźnienie wynosiła 6,5% w stosunku rocznym;' },
  ],
  pars2:[
    { text: '2) poczynając od dnia 29 maja 2020 r. wysokość odsetek ustawowych za opóźnienie wynosi 5,6% w stosunku rocznym.' },
  ],
};
export const act3 = {
  title: 'OBWIESZCZENIE MINISTRA SPRAWIEDLIWOŚCI W SPRAWIE WYSOKOŚCI ODSETEK USTAWOWYCH ZA OPÓŹNIENIE',
  desc: 'z dnia 18 października 2021 r (M.P. z 2021 r.Poz. 953)',
  pars:[
    { text: 'Na podstawie art. 481 § 2 ustawy z dnia 23 kwietnia 1964 r. – Kodeks cywilny (Dz. U. z 2020 r. poz. 1740 i 2320oraz z 2021 r. poz. 1509) ogłasza się, że poczynając od dnia 7 października 2021 r. wysokość odsetek ustawowych zaopóźnienie wynosi 6% w stosunku roczny' },
  ]
};
export const act4 = {
  title: 'OBWIESZCZENIE MINISTRA SPRAWIEDLIWOŚCI W SPRAWIE WYSOKOŚCI ODSETEK USTAWOWYCH ZA OPÓŹNIENIE',
  desc: 'z dnia 29 listopada 2021 r (M.P. z 2021 r.Poz. 1097)',
  pars:[
    { text: 'Na podstawie art. 481 § 24 ustawy z dnia 23 kwietnia 1964 r. – Kodeks cywilny (Dz. U. z 2020 r. poz. 1740 i 2320oraz z 2021 r. poz. 1509) ogłasza się, że poczynając od dnia 4 listopada 2021 r. wysokość odsetek ustawowych za opóźnienie wynosi 6,75% w stosunku rocznym.' },
  ]
};
export const act5 = {
  title: 'OBWIESZCZENIE MINISTRA SPRAWIEDLIWOŚCI W SPRAWIE WYSOKOŚCI ODSETEK USTAWOWYCH ZA OPÓŹNIENIE',
  desc: 'z dnia 28 grudnia 2021 r (M.P. z 2021 r.Poz. 1202)',
  pars:[
    { text: 'Na podstawie art. 481 § 24 ustawy z dnia 23 kwietnia 1964 r. – Kodeks cywilny (Dz. U. z 2020 r. poz. 1740 i 2320 oraz z 2021 r. poz. 1509 i 2459) ogłasza się, że poczynając od dnia 9 grudnia 2021 r. wysokość odsetek ustawowych za opóźnienie wynosi 7,25% w stosunku rocznym.' },
  ]
};
export const act6 = {
  title: 'OBWIESZCZENIE MINISTRA SPRAWIEDLIWOŚCI W SPRAWIE WYSOKOŚCI ODSETEK USTAWOWYCH ZA OPÓŹNIENIE',
  desc: 'z dnia 24 stycznia 2022 r (M.P. z 2022 r.Poz. 109)',
  pars:[
    { text: 'Na podstawie art. 481 § 2 ustawy z dnia 23 kwietnia 1964 r. – Kodeks cywilny (Dz. U. z 2020 r. poz. 1740 i 2320 oraz z 2021 r. poz. 1509 i 2459) ogłasza się, że poczynając od dnia 5 stycznia 2022 r. wysokość odsetek ustawowych za opóźnienie wynosi 7,75% w stosunku rocznym.' },
  ]
};

export const act7 = {
  title: 'OBWIESZCZENIE MINISTRA SPRAWIEDLIWOŚCI W SPRAWIE WYSOKOŚCI ODSETEK USTAWOWYCH ZA OPÓŹNIENIE',
  desc: 'z dnia 23 lutego 2022 r (M.P. z 2022 r.Poz. 285)',
  pars:[
    { text: 'Na podstawie art. 481 § 2 ustawy z dnia 23 kwietnia 1964 r. – Kodeks cywilny (Dz. U. z 2020 r. poz. 1740 i 2320 oraz z 2021 r. poz. 1509 i 2459) ogłasza się, że poczynając od dnia 9 lutego 2022 r. wysokość odsetek ustawowych za opóźnienie wynosi 8,25% w stosunku rocznym. ' },
  ]
};

export const act8 = {
  title: 'OBWIESZCZENIE MINISTRA SPRAWIEDLIWOŚCI W SPRAWIE WYSOKOŚCI ODSETEK USTAWOWYCH ZA OPÓŹNIENIE',
  desc: 'z dnia 28 marca 2022 r (M.P. z 2022 r.Poz. 375)',
  pars:[
    { text: 'Na podstawie art. 481 § 2 ustawy z dnia 23 kwietnia 1964 r. – Kodeks cywilny (Dz. U. z 2020 r. poz. 1740 i 2320 oraz z 2021 r. poz. 1509 i 2459) ogłasza się, że poczynając od dnia 9 marca 2022 r. wysokość odsetek ustawowych za opóźnienie wynosi 9% w stosunku rocznym. ' },
  ]
};