import { EditOutlined } from '@ant-design/icons';
import { Modal, Row, Space, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { updateSaveRecordName } from '../../services/lawsuitSave';
import EditRecordNameInput from './EditRecordNameInput';

const EditRecordName = (
  {
    recordName,
    closeModal,
    isModalVisible,
    saveId
  }: {
    recordName: string,
    closeModal: ()=>void,
    isModalVisible:boolean,
    saveId: string
  }
) => {
  const [recordNameLocal, setRecordNameLocal] = useState(recordName);

  const onFinish = () => {
    closeModal();
    setRecordNameLocal('');
  };

  useEffect(() => {
    setRecordNameLocal(recordName);
    return () => {
      setRecordNameLocal('');
    };
  }, [recordName]);


  return (
    <Modal
      destroyOnClose={true}
      title="Zmień nazwę zapisu"
      visible={isModalVisible}
      onCancel={closeModal}
      onOk={() =>
        updateSaveRecordName({
          recordName,
          onFinish,
          recordNameLocal,
          saveId
        })}
      okText={(
        <Row align="middle">
          <Space>
            <EditOutlined />
            <Typography.Text style={{ color: 'white' }}>
              Edytuj nazwę
            </Typography.Text>
          </Space>
        </Row>
      )}
    >
      <EditRecordNameInput
        {...{
          recordNameLocal,
          setRecordNameLocal,
        }}
      />
    </Modal>
  );
};


export default EditRecordName;
