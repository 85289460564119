import { Typography, Button, Card } from 'antd';
import Meta from 'antd/lib/card/Meta';
import React from 'react';
import styled from 'styled-components';
import { LINK_EXCEL_EXAMPLE } from '../../../../utils/helpers/links';
import useWindowSize from '../../../../utils/hooks/useWindowSize';
import CopyButton from '../../../UI/Button/CopyButton';


const { Text, Title } = Typography;

const ImpExcelFileInfo = () => {
  const accountantText = 'Bardzo proszę o plik w formacie xlsx.\n Dwie pierwsze kolumny w pierwszym arkuszu powinny zostać uzupełnione następująco:\n1.pierwsza kolumna powinna zawierać terminy zapłaty (czyli terminy spełnienia świadczenia); \n2.druga skorelowane z terminami kwoty składowe (np. kwoty z dochodzonych faktur);  \n3.trzecia kolumna może zawierać nazwy dokumentów księgowych(opcjonalnie). \n4.kolumny nie powinny mieć tytułów. \n W załączeniu przesyłam przykładowy plik.';

  const { width } = useWindowSize();

  const actions = width >  425 ? [
    <Button type="link" key="example" href={LINK_EXCEL_EXAMPLE}>
      Pobierz przykładowy plik excel
    </Button>,
    <>
      <CopyButton key="copy" womargin value={accountantText} />
      <MonoText type="secondary">
        i wyślij wymagania
        <strong> mailem</strong>
      </MonoText>
    </>,
  ] : [
    <>
      <CopyButton key="copy" womargin value={accountantText} />
      <Button
        type="link"
        key="example"
        href={LINK_EXCEL_EXAMPLE}
        style={{ marginTop: 24 }}
      >
        Pobierz przykładowy plik excel
      </Button>
    </>
  ];
  return (
    <Card
      title="O jaki plik excel poprosić księgowość ?"
      style={{ marginTop: 24 }}
      actions={actions}
    >
      <Meta
        description={(
          <>
            Plik w formacie
            {' '}
            <strong>xlsx (Excel)</strong>
            . Dla
            {' '}
            <strong>rozwiania wątpliwości</strong>
            {' '}
            sugerujemy przesłać księgowej/księgowemu przykładowy plik i poniższą instrukcję.
            {' '}
            <br />
            Kolumny w
            {' '}
            <strong>pierwszym arkuszu</strong>
            {' '}
            powinny zostać uzupełnione następująco:
            <ul>
              <li>
                <strong>pierwsza</strong>
                {' '}
                kolumna powinna zawierać
                {' '}
                <strong>terminy zapłaty </strong>
                (czyli terminy spełnienia świadczenia);
              </li>
              <li>
                <strong>druga</strong>
                {' '}
                skorelowane z terminami
                {' '}
                <strong>kwoty składowe </strong>
                {' '}
                (np. kwoty z dochodzonych faktur);
              </li>
              <li>kolumny nie powinny mieć tytułów</li>
            </ul>
            <Title level={5}>Opcjonalnie kolumny</Title>
            <ul>
              <li>
                <strong>trzecia</strong>
                {' '}
                kolumna powinna zawierać
                {' '}
                <strong>numery faktur vat - aby wygenerować nazwy dokumentów w tekście uzasadnienia oraz w liście dowodów</strong>
              </li>
            </ul>
          </>
      )}
      />
    </Card>
  );
};

export default ImpExcelFileInfo;


const MonoText = styled(Text)`
    font-size: 12px;
    margin-left: 10px;
`;