/* eslint-disable @typescript-eslint/no-explicit-any */
import { EuroOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, InputNumber, Row } from 'antd';
import moment, { Moment } from 'moment';
import React, { memo, useMemo } from 'react';
import styled from 'styled-components';

import { KWOTA_SKŁDAOWA, OKRES_ODSETEK } from '../../../assets/tooltips';
import { BLACK, GRID_SM_PX, RowExt } from '../../../styles';
import Icon from '../../../styles/Icon';
import { disabledDate } from '../../../utils/helpers/date';
import usePartialSumHelp from '../../../utils/hooks/usePartialSumHelp';
import { CalendarFormIcon } from './DueDate';


const StarEndPartialSum = (
  {
    startingDate,
    endDate,
    dueDate,
    onDateChange,
    onSumChange,
    partialSum,
    disableSecond = false,
    onKeyDown,
    onCurrencyClick
  }: {
    startingDate: Moment | null,
    endDate: Moment | null,
    dueDate: Moment | null,
    onDateChange: (value: any, dateString: any) => void,
    onSumChange: (value: string | number | null | undefined) => void,
    partialSum: number | null,
    disableSecond?: boolean,
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void,
    onCurrencyClick: () => void
  }
) => {
  const [form] = Form.useForm();
  const { RangePicker } = DatePicker;

  const validateStatus = useMemo(() => {
    const mdError = dueDate
      ? moment(startingDate).toDate() < moment(dueDate).toDate()
      : false;
    const edError = moment(startingDate).toDate() >= moment(endDate).toDate();
    return edError || mdError ? 'error' : '';
  }
  , [endDate, dueDate, startingDate]);

  const help = usePartialSumHelp({
    endDate, dueDate, startingDate,
  });

  return (
    <Form
      form={form}
      layout="vertical"
    >
      <RowExt>
        <Col sm={{ span: 12 }} xs={{ span:24 }}>
          <FormItem
            label={(
              <>
                <CalendarFormIcon />
                {texts.startingData}
              </>
            )}
            tooltip={OKRES_ODSETEK}
            validateStatus={validateStatus}
            help={help}
          >
            <RangePicker
              allowClear
              separator
              style={{ width: '100%' }}
              onChange={onDateChange}
              format="DD-MM-YYYY"
              disabledDate={disabledDate}
              value={[startingDate, endDate]}
              disabled={[false, disableSecond]}
            />
          </FormItem>
        </Col>
        <Col sm={{ span: 2 }} xs={{ span:0 }} />
        <Col sm={{ span:  8 }} xs={{ span: 20  }}>
          <FormItem
            label={(
              <>
                <IconFormIcon
                  height="18"
                  width="18"
                  fill={BLACK}
                  stroke={BLACK}
                  iconType="MONEY_PACK"
                />
                {texts.partialSum}
              </>
            )}
            tooltip={KWOTA_SKŁDAOWA}
          >
            <InputNumber
              placeholder="0 zł"
              min={0}
              max={10000000000000}
              precision={2}
              onChange={onSumChange}
              style={{ width: '100%' }}
              decimalSeparator=","
              value={partialSum === null ? undefined : partialSum}
              onKeyDown={onKeyDown}
            />
          </FormItem>
        </Col>
        <Col
          sm={{ span: 0 }}
          xs={{ span: 2 }}
        />
        <ColStyled
          sm={{ span: 2 }}
          xs={{ span: 2 }}
        >
          <Row justify="end">
            <Button
              type="dashed"
              size="middle"
              icon={<EuroOutlined />}
              onClick={onCurrencyClick}
            />
          </Row>
        </ColStyled>
      </RowExt>
    </Form>
  );
};

export default memo(StarEndPartialSum);

const texts = {
  startingData: 'Okres naliczania odsetek',
  partialSum: 'Kwota główna',
};

const IconFormIcon = styled(Icon)`
    margin-right: 4px;
`;

const FormItem = styled(Form.Item)`
   /* margin-bottom: 0; */
`;

const ColStyled = styled(Col)`
   padding-top: 33px;
   @media only screen and (max-width: ${GRID_SM_PX}) {
    padding-top: 34px;
	}
`;