const pozewapp = '<span class="dark-blue">pozew.app</span>';

export const par1 = {
  title: 'Co to są "cookies" i jak je używamy?',
  desc: '§1. Definicja cookies',
  pars:[
    { text: `Cookies to dane informatyczne zapisywane w plikach i przechowywane na urządzeniu końcowym w przeglądarce urządzenia, z którego korzystasz używając ${pozewapp} i inne strony w Internecie.` },
    { text: 'Pozew.app używa cookies aby przeprowadzać analizę zachodwań użytkowników przy użyciu Google Analitics i połączonego z nim Google Analictis Firebase. Używamy Google Analytics(Google Analictis Firebase) do zagregowanej, anonimowej analizy ruchu w witrynie. Aby śledzić wykorzystanie sesji, Google umieszcza plik cookie (_ga) z losowo generowanym identyfikatorem klienta w przeglądarce. Ten identyfikator jest anonimowy i nie zawiera żadnych informacji umożliwiających identyfikację, takich jak adres e-mail, numer telefonu, imię i nazwisko itp. Google Analitcs Twój może natomiast zachowywać Twój adres IP. Używamy GA do śledzenia zagregowanych interakcji względem pozew.app.' }
  ],
  usts:[],
};
export const par2 = {
  title: 'Czy używamy cookies osób trzecich?',
  desc: '§2. Cookies osób trzecich',
  pars:[
    { text: 'Tak.  Plik cookie (_ga) pochodzi od Google.' },
  ],
};
export const par3 = {
  title: 'W jaki sposób mogę zmienić swoje ustawienia cookies albo je usunąć cookies? ',
  desc: '§3. Ustawienia cookies',
  pars:[
    { text: 'W ustawieniach przeglądarki możesz ustawić sposób zbierania cookies i ich zbieranie.' },
    { text: `Wyłączenie zbierania cookies przez ${pozewapp} możesz zmienić tutaj:` }
  ],
};