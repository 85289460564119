import { SaveOutlined } from '@ant-design/icons';
import { Col, Input, Row, Space } from 'antd';
import React, { useState } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { updateLawsuitSave } from '../../../../services/lawsuitSave';
import { useTypedSelector } from '../../../../store';
import { RowExt } from '../../../../styles';
import SaveButton from '../../../UI/Button/SaveButton';


const SaveBar = () => {
  const dispatch = useDispatch();
  const { saveId, recordName } = useTypedSelector(state =>
    state.lawsuitUI, shallowEqual);

  const [loading, setLoading] = useState(false);


  if (saveId){
    return (
      <RowExt marginTop="md">
        <Col
          lg={{
            offset:0,
            span:4,
          }}
          md={{
            offset:2,
            span:4,
          }}
          sm={{
            offset:2,
            span:4,
          }}
          xs={{
            offset:2,
            span: 4,
          }}
        >
          <Row>
            <Space>
              <SaveButton
                loading={loading}
                onClick={() =>
                  dispatch(updateLawsuitSave({
                    saveId,
                    recordName: recordName ?? 'Nowe wyliczenia',
                    onLoadingStart: () =>
                      setLoading(true),
                    onFinish: () =>
                      setLoading(false),
                    isSaveFromResult: false,
                  }))}
              />
            </Space>
          </Row>
        </Col>
        <Col
          lg={{
            offset:0,
            span:20,
          }}
          md={{
            offset:0,
            span:16,
          }}
          sm={{
            offset:0,
            span:16,
          }}
          xs={{
            offset:2,
            span: 14,
          }}
        >
          <Input
            addonBefore={<SaveOutlined />}
            disabled
            value={recordName ?? ''}
          />
        </Col>
      </RowExt>
    );
  }
  return null;

};

export default SaveBar;
