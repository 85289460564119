import React from 'react';
import SloganContainer from './SloganContainer';
import SloganFooter from './SloganFooter';
import SloganHeader from './SloganHeader';

const Slogan = () => {
  return (
    <SloganContainer>
      <SloganHeader />
      <SloganFooter />
    </SloganContainer>
  );
};

export default Slogan;

