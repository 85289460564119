import { Col, Typography } from 'antd';
import parse from 'html-react-parser';
import React from 'react';
import { DashedColLeft, RowExt } from '../../styles';

const LawParText = (
  { text }: {
    text:string
  }
) => {
  return (
    <RowExt>
      <DashedColLeft
        lg={{
          offset:0,
          span:1,
        }}
        xs={{
          offset: 1,
          span: 1,
        }}
      />
      <Col
        lg={{ span: 21 }}
        xs={{ span: 20 }}
      >
        <Typography.Paragraph>
          {parse(text)}
        </Typography.Paragraph>
      </Col>
    </RowExt>
  );
};

export default LawParText;
