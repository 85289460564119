import _ from 'lodash';
import moment, { Moment } from 'moment';

import { AppDispatch } from '../store';
import { InterestData,
  InterestItem,
  PaymentAssignment,
  PaymentData,
  paymentDataChanged,
  PaymentItem } from '../store/interestStorage';
import { LawsuitOpts } from '../store/lawsuitOpts';
import { getNotAssignedSum, getNotCoveredSum } from './assignPaymentByKey';
import { isClaimFullyCovered } from './autoAssign/autoAssignCommon';
import { claimCalc, isPaymentFullyAssigned } from './calc/claim';

export type ToOldestReducedData = {
  indexToStartFrom: number;
  paymentData: PaymentItem [],
};

export const getInterestUntilPayment = (paymentDate: Moment, interestData: InterestData) =>
  _.cloneDeep(interestData)
    .filter(value =>
      value.startingDate.isSameOrBefore(paymentDate))
    .sort((itemA, itemB) =>
      moment(itemA.startingDate).toDate().getTime() - moment(itemB.startingDate).toDate().getTime());

export const assignToOldestNew = ({
  dispatch,
  paymentData,
  interestData,
  lawsuitOpts,
  isInterestFirst,
}:{
  dispatch: AppDispatch
  paymentData: PaymentItem[],
  interestData: InterestItem[],
  lawsuitOpts: LawsuitOpts,
  isInterestFirst: boolean,
}) => {
  const paymentDataWoAssigns = _.cloneDeep(paymentData).map(paymentItem => {
    return {
      ...paymentItem,
      assignedTo:[],
    };
  });
  dispatch(paymentDataChanged(paymentDataWoAssigns));

  const sortedPaymentData:PaymentItem[] = _.isEmpty(paymentDataWoAssigns) ? [] : _.cloneDeep(paymentDataWoAssigns).sort((itemA, itemB) =>
    moment(itemA.paymentDate).toDate().getTime() - moment(itemB.paymentDate).toDate().getTime()
  );

  const assignedPaymentData = sortedPaymentData.reduce((prev: PaymentItem[], curr) => {
    const interestDataUntilPayment = getInterestUntilPayment(curr.paymentDate, interestData);
    return interestDataUntilPayment.reduce((pr, interestItem, index) => {

      if (isPaymentFullyAssigned(pr, index)) return prev;

      const claim = claimCalc({
        interestItem,
        paymentData,
        lawsuitOpts,
      });

      if (isClaimFullyCovered(claim, isInterestFirst)) return prev;

      const notCoveredSum = getNotCoveredSum({
        interestItem,
        paymentItem: prev[index],
        paymentData: prev,
        lawsuitOpts,
        isInterestFirst
      });

      const notAssignedSum = getNotAssignedSum(prev, index);

      const paymentDataCopy = _.cloneDeep(prev);

      const fullAssignmentSum =  notCoveredSum <  notAssignedSum
        ? notCoveredSum : notAssignedSum;

      paymentDataCopy[index].assignedTo.push(
        {
          key:interestItem.key,
          sum: fullAssignmentSum,
          isInterestFirst,
        });
      return paymentDataCopy;
    }, prev);
  }, sortedPaymentData);

  dispatch(paymentDataChanged(assignedPaymentData));
  return assignedPaymentData;
};

type DataAssignHelper = {
  paymentSumLeft: number,
  assignedTo: PaymentAssignment[],
  indexToStartFrom: number,
};

export const createAssignTo = ({
  interestData,
  paymentItem,
  indexToStartFrom,
  lawsuitOpts,
  paymentData,
  isInterestFirst,
}: { interestData: InterestData,
  paymentItem: PaymentItem,
  indexToStartFrom: number,
  lawsuitOpts: LawsuitOpts,
  paymentData: PaymentData,
  isInterestFirst: boolean,
}) =>
  _.cloneDeep(interestData).slice(indexToStartFrom).reduce((
    prev: DataAssignHelper
    , interestItem, index) => {
    const  { key:interestDataKey, startingDate, endDate } = interestItem;

    const isPaymentViable =
      moment(startingDate).endOf('day').isBefore(paymentItem.paymentDate) &&
      moment(endDate).isAfter(paymentItem.paymentDate);

    if (!isPaymentViable) return prev;

    const notCoveredSum = getNotCoveredSum({
      interestItem,
      paymentItem,
      paymentData,
      lawsuitOpts,
      isInterestFirst,
    });

    const { paymentSumLeft } = prev;

    if (prev.paymentSumLeft === 0) return prev;

    if (notCoveredSum === 0) return prev;

    return {
      assignedTo:[...prev.assignedTo, {
        isInterestFirst,
        key: interestDataKey,
        sum: paymentSumLeft > notCoveredSum ? notCoveredSum : paymentSumLeft,
      } ],
      paymentSumLeft: calcPaymentSumLeft(paymentSumLeft, notCoveredSum),
      indexToStartFrom: paymentSumLeft > notCoveredSum ? index + 1 : index,
    };
  }, {
    paymentSumLeft: paymentItem.paymentSum,
    assignedTo:paymentItem.assignedTo,
    indexToStartFrom,
  });

const calcPaymentSumLeft = (paymentSum: number, partialSum:number) =>
  paymentSum > partialSum ? paymentSum - partialSum : 0;