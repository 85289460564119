import React, { memo } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';

import { useTypedSelector } from '../../../store';
import useInitEditState, { importFromInterestItem } from '../../../utils/hooks/useInitEditState';
import Basis from '../Basis/Basis';
import CurrencyForm from '../Currency/CurrencyForm';
import EditActions from './EditActions';
import EditAlert from './EditAlert';
import EditCard from './EditCard';
import EditMaturity from './EditMaturity';
import EditDueDateBasis from './EditDueDateBasis';
import EditPartialSum from './EditPartialSum';


const Edit = () => {
  const { isCurrVisible, isBasisVisible, isMaturityVisible } = useTypedSelector(state =>
    state.edit, shallowEqual);

  const dispatch = useDispatch();

  useInitEditState();

  const onCancel = () => {
    dispatch(importFromInterestItem());
  };

  if (isMaturityVisible){
    return (
      <EditMaturity />
    );
  }

  if (isBasisVisible){
    return (
      <Basis />
    );
  }

  if (isCurrVisible){
    return (
      <CurrencyForm />
    );
  }


  return (
    <EditCard
      title={texts.title}
      desc={texts.desc}
    >
      <EditAlert />
      <EditDueDateBasis />
      <EditPartialSum />
      <EditActions
        onCancel={onCancel}
      />
    </EditCard>

  );
};


export default memo(Edit);

const texts = {
  title: 'Edycja kwoty składowej',
  desc: 'Zmień dane według uznania',
};
