import moment, { Moment } from 'moment';
import { InterestItem, PaymentItem } from '../../store/interestStorage';
import { ClaimItem } from '../calc/claim';

export const AUTO_ASSIGN_EXACT = 'AUTO_ASSIGN_EXACT';
export const AUTO_ASSIGN_SIMILAR = 'AUTO_ASSIGN_SIMILAR';
export const AUTO_ASSIGN_DATE = 'AUTO_ASSIGN_DATE';
export const AUTO_ASSIGN_SUM = 'AUTO_ASSIGN_SUM';
export const AUTO_ASSIGN_AI = 'AUTO_ASSIGN_AI';
export const AUTO_ASSIGN_DEFAULT = 'AUTO_ASSIGN_DEFAULT';

export type AllocationMechanism =
  typeof AUTO_ASSIGN_EXACT |
  typeof AUTO_ASSIGN_SIMILAR |
  typeof AUTO_ASSIGN_DATE |
  typeof AUTO_ASSIGN_SUM |
  typeof AUTO_ASSIGN_AI |
  typeof AUTO_ASSIGN_DEFAULT;

export type AllocationNote = 'partial' | 'full' | null;

export const CREDITOR_STATEMENT = 'CREDITOR_STATEMENT';
export const NO_STATEMENT = 'NO_STATEMENT';

export type AutoAssignMethod =
typeof CREDITOR_STATEMENT |
typeof NO_STATEMENT;

export type AllocatedItems = {
  interestItemKeys: string[] | null,
  allocationMechanism: AllocationMechanism,
};

export type AllocatedPayment = {
  paymentItem: PaymentItem
  interestItemKeys: string[] | null,
  allocationMechanism: AllocationMechanism
};

export type AssignHistory = AssignHistoryItem[];

export type AssignHistoryItem = {
  paymentItemKey: string
  historyRecords: AssignHistoryRecord[]
};

export type AssignHistoryRecord = {
  interestItemKey: string,
  allocationMechanism: AllocationMechanism,
  note: AllocationNote
};

export const isAutoAssignDateValid = (paymentDate: Moment, interestItem: InterestItem) =>
  moment(paymentDate).isBefore(interestItem.endDate)
  &&  moment(paymentDate).isAfter(interestItem.startingDate);

export const isClaimFullyCovered = (claim: ClaimItem, isInterestFirst: boolean) => {
  const {
    claimSum,
    assignedPaymentsSum,
    partialSum,
    interestsSumFulfilledOverall,
    interestsSum,
  } = claim;

  return isInterestFirst ?
    assignedPaymentsSum  ===  (partialSum + interestsSumFulfilledOverall + interestsSum) :
    claimSum  === 0;
};


export const irrelevantNames = [
  'tytuł',
  'przelew',
  '',
];