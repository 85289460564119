import { FirebaseError } from '@firebase/util';
import { signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import _ from 'lodash';
import { AppDispatch, RootState } from '../../store';
import { appLoadingStopped, appLoadingStarted } from '../../store/appLoading';
import { loginFailed, loginFailedOff, loginLoadingStarted, loginReset } from '../../store/logInReducer';
import { saveModalOpened } from '../../store/saveLawsuitModal';
import { errMsgGeneral, errMsgWithCode } from '../../utils/helpers/errorTexts';
import { emailRegex } from '../../utils/helpers/regexChecks';
import { auth } from '../firebase/auth';
import { userExistCheck } from './signUp';

const handleSuccess = ({
  dispatch,
  history,
  returnTo
}:{
  dispatch: AppDispatch;
  history: {
    push: (location: string) => void;
    goBack: () => void;
  },
  returnTo?: string | '/resultLsLocal' | '/lawsuit'
}) => {
  if (returnTo ===  'stay'){
    dispatch(appLoadingStopped());
  } else if (returnTo ===  'goBack') {
    dispatch(appLoadingStopped());
    history.goBack();
  } else {
    dispatch(loginReset());
    if (returnTo === '/resultLsLocal') history.goBack();
    if (returnTo === '/lawsuit') history.push('/lawsuit');
    else history.push('/dashboard');
    dispatch(appLoadingStopped());
    if (returnTo){
      dispatch(saveModalOpened({
        isSaveModalVisible:true,
        isSaveFromResult: returnTo === '/resultLsLocal' || false,
      }));
    }
  }
};

const handleError = (dispatch: AppDispatch, errorMsg: string) => {
  dispatch(appLoadingStopped());
  dispatch(loginFailed(errorMsg));
  setTimeout(() => {
    dispatch(loginFailedOff());
  }, 4000);
};

export const loginEmail = ({
  history,
  returnTo,
}: {
  history: {
    push: (location: string) => void;
    goBack: () => void;
  },
  returnTo?: string | '/resultLsLocal' | '/lawsuit'
}) =>
  async (dispatch: AppDispatch,
    getState: () => RootState) => {
    const state = getState();
    const { email: unformattedEmail, password } = state.login;
    const email = unformattedEmail.trim().toLocaleLowerCase();
    if (!email.match(emailRegex)) {
      handleError(dispatch, texts.enterValid);
    } else if (password.length === 0 || _.isEmpty(password)) {
      handleError(dispatch, texts.passReq);
    } else {
      dispatch(appLoadingStarted('logowanie'));
      dispatch(loginLoadingStarted('email'));
      try {
        await signInWithEmailAndPassword(auth, email, password);
        handleSuccess({
          dispatch,
          history,
          returnTo,
        });
      } catch (error) {
        if (error instanceof FirebaseError){
          dispatch(appLoadingStopped());
          if (error.code === 'auth/wrong-password'){
            handleError(dispatch, 'Podałaś/eś niewłaściwe hasło lub używasz emaila do logowania przez zewnętrzengo providera (np. Google)');
          } else if (error.code === 'auth/user-not-found'){
            handleError(dispatch, 'Brak zarejestrowanego używtkownika pod tym adresem email');
          } else {
            handleError(dispatch, errMsgWithCode(error.code));
          }
        }
      }
    }
  };

const google = new firebase.auth.GoogleAuthProvider;
const facebook = new firebase.auth.FacebookAuthProvider;

export const handleLogInExternalProvider =  ({
  provider,
  history,
  returnTo,
}: {
  provider: 'facebook' | 'google';
  history: {
    push: (location: string) => void;
    goBack: () => void;
  },
  returnTo?: string | '/resultLsLocal' | '/lawsuit'
}) =>
  async (dispatch: AppDispatch) =>   {
    try {
      dispatch(loginLoadingStarted(provider));
      const userCredential = await signInWithPopup(auth, provider === 'facebook' ? facebook : google);
      try {
        dispatch(appLoadingStarted('logowanie'));
        const { user } = userCredential;
        if (user){
          const userExists = await userExistCheck(user.uid);
          if (userExists) {
            handleSuccess({
              dispatch,
              history,
              returnTo,
            });
          } else {
            await user.delete();
            handleError(dispatch, texts.notExists);
          }
        } else {
          handleError(dispatch, errMsgWithCode('Ups, coś poszło nie tak'));
        }
      } catch (error) {
        dispatch(loginFailed(error instanceof FirebaseError ? errMsgWithCode( error.code) : errMsgGeneral));
      }
    } catch (error) {
      dispatch(appLoadingStopped());
      if (error instanceof FirebaseError){
        if (error.code === 'auth/popup-closed-by-user'){
          dispatch(loginReset());
        } else if (error.code === 'auth/cancelled-popup-request'){
          dispatch(loginReset());
        } else {
          dispatch(loginFailed(errMsgWithCode(error.code)));
        }
      }
    }
  };



const texts = {
  enterValid: 'Wpisz właściwie sformatowany email',
  passReq: 'Hasło jest wymagane',
  notExists: 'Konto pozew.app nie istnieje. Utwórz je proszę najpierw.',
  validCode: 'Invalid code, try again',
  enterNumber: 'Enter phone number',
  enterValidPhone: 'Enter valid phone number',
  warningTitle: 'Problem occurred',
};


