import { COMPENSATION_BASIS } from '../helpers/lawBasis';

export const act1 = {
  title: 'Brzmienie od 01-01-2016 roku do 31-12-2019',
  desc: COMPENSATION_BASIS,
  pars:[
    { text: '1. Wierzycielowi, od dnia nabycia uprawnienia do odsetek, o których mowa w art. 7 ust. 1 lub art. 8 ust. 1, przysługuje od dłużnika, bez wezwania, równowartość kwoty 40 euro przeliczonej na złote według średniego kursu euro ogłoszonego przez Narodowy Bank Polski ostatniego dnia roboczego miesiąca poprzedzającego miesiąc, w którym świadczenie pieniężne stało się wymagalne, stanowiącej rekompensatę za koszty odzyskiwania należności.' },
  ]
};
export const act2 = {
  title: 'Brzmienie od 01-01-2020',
  desc: COMPENSATION_BASIS,
  pars1:[
    { text: '1. Wierzycielowi, od dnia nabycia uprawnienia do odsetek, o których mowa w art. 7 ust. 1 lub art. 8 ust. 1, przysługuje od dłużnika, bez wezwania, rekompensata za koszty odzyskiwania należności, stanowiąca równowartość kwoty:' },
  ],
  usts: [
    { text: '1) 40 euro – gdy wartość świadczenia pieniężnego nie przekracza 5000 złotych;' },
    { text: '2) 70 euro – gdy wartość świadczenia pieniężnego jest wyższa niż 5000 złotych, ale niższa niż 50 000 złotych;' },
    { text: '3) 100 euro – gdy wartość świadczenia pieniężnego jest równa lub wyższa od 50 000 złotych.' },
  ],
  pars2:[
    { text: '1a. Równowartość kwoty rekompensaty, o której mowa w ust. 1, jest ustalana przy zastosowaniu średniego kursu euro ogłoszonego przez Narodowy Bank Polski ostatniego dnia roboczego miesiąca poprzedzającego miesiąc, w którym świadczenie pieniężne stało się wymagalne' },
  ],
};