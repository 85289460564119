import React from 'react';
import { shallowEqual } from 'react-redux';
import { getCouthFees } from '../../../services/calc/couthFeesCalc';
import { getWps } from '../../../services/calc/wpsCalc';
import { useTypedSelector } from '../../../store';
import ResultRow from '../SumUpHead/ResultRow';
import { WpsTitle } from './WpsTitle';



const CourtPayment = () => {
  const {
    overallInterestsSum,
    compensationSum,
    finalSum,
  } = useTypedSelector(state =>
    state.sumUp, shallowEqual);

  return (
    <>
      <WpsTitle title={texts.title} />
      <ResultRow
        sum1={getCouthFees(getWps(finalSum))}
        sum2={getCouthFees(getWps(overallInterestsSum + finalSum))}
        sum3={getCouthFees(getWps(finalSum + compensationSum + overallInterestsSum))}
        legal={texts.legal}
      />
    </>
  );
};



export default CourtPayment;

const texts = {
  title: 'Wysokość opłaty sądowej liczonej od wps ustalonego w oparciu o',
  legal: 'Podstawa prawna: art. 13 Ustawy o kosztach sądowych w sprawach cywilnych Zaokrąglone do 1 zł w górę art. 21 UoKSWSC',
};