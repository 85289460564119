import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Col, Typography } from 'antd';
import _ from 'lodash';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { BLUE, RowExt } from '../../../styles';
import { RED } from '../../../styles/colors';

const {  Paragraph } = Typography;

const SubOptListItem = (
  {
    text,
    notAvailable
  }: {
    text:string | ReactNode,
    notAvailable?: boolean
  }
) => {
  return (
    <RowExt>
      <Col span={20} offset={2}>
        <Paragraph
          type="secondary"
        >
          {notAvailable ? <CancelGrey /> : <CheckBlue />}
          {_.isString(text) ? ` ${text}` : text}
        </Paragraph>
      </Col>
    </RowExt>
  );
};

export default SubOptListItem;

const CheckBlue = styled(CheckOutlined)`
    color:  ${BLUE};
`;
const CancelGrey = styled(CloseOutlined)`
    color:  ${RED};
`;
