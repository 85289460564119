import _ from 'lodash';
import { useTypedSelector } from '../../store';
import { InterestData, PaymentData } from '../../store/interestStorage';



const getListOfFullAssignedPayments = (interestData: InterestData, paymentData: PaymentData) =>
  paymentData.filter((paymentItem) => {
    const { assignedTo, paymentSum } = paymentItem;
    const assignedSum = assignedTo.reduce((prev, { sum }) =>
      sum + prev, 0);
    return paymentSum === assignedSum;
  });

const getListOfPaymentsWoAssignments = (paymentData: PaymentData) =>
  paymentData.filter((paymentItem) => {
    const { assignedTo } = paymentItem;
    return _.isEmpty(assignedTo);
  });

const useListOfAssignedPayments = () => {
  const listOfFullAssignedPayments = useTypedSelector(state => {
    const { paymentData, interestData } = state.interestStorage;
    return getListOfFullAssignedPayments(interestData, paymentData);
  });

  const listOfPaymentsWoAssignments = useTypedSelector(state => {
    const { paymentData } = state.interestStorage;
    return getListOfPaymentsWoAssignments(paymentData);
  });

  return {
    listOfFullAssignedPayments,
    areSomePaymentsFullyAssigned: listOfFullAssignedPayments.length > 0,
    listOfPaymentsWoAssignments,
    areSomePaymentsWoAssignments: listOfPaymentsWoAssignments.length > 0,
  };
};

export default useListOfAssignedPayments;