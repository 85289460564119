import { Descriptions } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { formatPLN } from '../../../utils/helpers/formatNumber';
import useWindowSize from '../../../utils/hooks/useWindowSize';
import { PartialResult } from '../../../store/sumUp';
import { GRID_SM } from '../../../styles';
import { DescFullWidth } from '../../../styles/ui';

const DetailsPeriodInterestSum = ({ partialResult }: { partialResult: PartialResult }) => {
  const { width } = useWindowSize();
  return (
    <DescFullWidth
      layout={width > GRID_SM ? 'horizontal' : 'vertical'}
      column={2}
      bordered
      key={partialResult.key}
    >
      <Descriptions.Item
        label={`Łącznie skapitalizowane odsetki od kwoty ${formatPLN(partialResult.claimSum)}`}
      >
        <Sum>{`${formatPLN(partialResult.interestsSum)}`}</Sum>
      </Descriptions.Item>
    </DescFullWidth>
  );
};

export default DetailsPeriodInterestSum;

const Sum = styled.div`
	font-size: 15px;
	font-weight: 600;
`;
