import _ from 'lodash';

import { notification } from 'antd';
import { AppDispatch } from '../../store';
import { InterestData, InterestItem, PaymentData, paymentDataChanged } from '../../store/interestStorage';
import { LawsuitOpts } from '../../store/lawsuitOpts';
import { autoAssignHistoryUpdated } from '../../store/lawsuitUI';
import { sortInterestData, sortPaymentData } from '../calc/claim';
import { getAllocatedPayments } from './allocateMechanisms/getAllocatedPayments';
import { autoAssignPaymentDirect } from './autoAssignMethods/autoAssignDirect';
import { AllocatedPayment, AssignHistory, AssignHistoryRecord } from './autoAssignCommon';
import { autoAssignPaymentIndirect } from './autoAssignMethods/autoAssignIndirect';


export const autoAssign =  ({
  interestData,
  paymentData,
  lawsuitOpts,
  dispatch,
  shouldShowHistory,
}:{
  interestData: InterestData,
  paymentData: PaymentData,
  lawsuitOpts: LawsuitOpts
  dispatch: AppDispatch,
  shouldShowHistory: boolean,
}) => {
  if (interestData && paymentData){

    const sortedInterestData = sortInterestData(interestData);

    const paymentDataWoAssigns = _.cloneDeep(sortPaymentData(paymentData)).map(paymentItem => {
      return {
        ...paymentItem,
        assignedTo:[],
      };
    });

    const paysAfterFirstStartingDate = paymentDataWoAssigns.filter(paymentItem => {
      const oldestInterestItem = _.first(sortedInterestData) as InterestItem;
      return oldestInterestItem.startingDate.isBefore(paymentItem.paymentDate);
    });

    if (_.isEmpty(paysAfterFirstStartingDate)) {
      notification.info({
        duration: 10000, message: 'Wszytkie wpłaty były dokonane przed powstaniem roszczenia.Zaliczenie wpłaty na nieistniejące w danym momencie roszczenie nie jest możliwe. W tym przypadku możemy mieć do czynienia z potrąceniem. '
      });
    } else {
      if (paysAfterFirstStartingDate.length !== paymentDataWoAssigns.length) {
        notification.info({
          duration: 10000, message: 'Część wpłat zostało dokonanych przed powstaniem roszczenia. Zaliczenie wpłaty na nieistniejące w danym momencie roszczenie nie jest możliwe. W tym przypadku możemy mieć do czynienia z potrąceniem. '
        });
      }

      dispatch(paymentDataChanged(paymentDataWoAssigns));

      const allocatedPayments = getAllocatedPayments(interestData, paysAfterFirstStartingDate);

      const paymentsWithHistory = assignPayments({
        allocatedPayments,
        paymentData: paymentDataWoAssigns,
        interestData: sortedInterestData,
        isInterestFirst: true,
        lawsuitOpts,
      });

      dispatch(paymentDataChanged(paymentsWithHistory.paymentData));

      if (shouldShowHistory) dispatch(autoAssignHistoryUpdated(paymentsWithHistory.autoAssignHistory));
    }

  }

};


type AssignPaymentsAcc = {
  paymentData: PaymentData,
  autoAssignHistory: AssignHistory,
};

type DirectAcc = {
  paymentData: PaymentData,
  areLeftovers: boolean,
  historyRecords: AssignHistoryRecord[],
};

const assignPayments = ({
  allocatedPayments,
  paymentData,
  interestData,
  isInterestFirst,
  lawsuitOpts,
}:{
  allocatedPayments: AllocatedPayment[],
  isInterestFirst: boolean,
  lawsuitOpts: LawsuitOpts,
  interestData: InterestItem[],
  paymentData: PaymentData,
}) =>
  allocatedPayments.reduce((prev:AssignPaymentsAcc, curr) => {
    /*
      No allocation mechanism found - try to assign it to oldest available
    */
    if (_.isNull(curr.interestItemKeys) ) {
      const assignedIndirectly = autoAssignPaymentIndirect({
        isInterestFirst,
        lawsuitOpts,
        interestData,
        paymentData: prev.paymentData,
        paymentItemKey: curr.paymentItem.key,
      });

      return {
        paymentData: assignedIndirectly.paymentData,
        autoAssignHistory: [
          ...prev.autoAssignHistory,
          {
            paymentItemKey: curr.paymentItem.key,
            historyRecords: assignedIndirectly.historyRecords
          }
        ]
      };
    }

    /*
      Allocation mechanism was selected
      1. Try to assign payment to interest items acquired by allocation mechanism
      2. If payments sum is not fully assigned this way will try assign it to the oldest available
    */

    const assignedDirectly = curr.interestItemKeys
      .reduce((previous: DirectAcc, interestItemKey) => {
        const result = autoAssignPaymentDirect({
          paymentItemKey: curr.paymentItem.key,
          interestItemKey,
          lawsuitOpts,
          interestData,
          paymentData: previous.paymentData,
          isInterestFirst,
          allocationMechanism: curr.allocationMechanism,
        });

        const historyRecords =  _.isNull(result.assignHistoryRecord) ?
          previous.historyRecords :  [
            ...previous.historyRecords,
            result.assignHistoryRecord
          ];

        return {
          paymentData: result.paymentData,
          areLeftovers: result.areLeftovers,
          historyRecords,
        };

      }, {
        paymentData: prev.paymentData,
        areLeftovers: false,
        historyRecords: [],
      });

    if (assignedDirectly.areLeftovers) {
      const assignedIndirectly = autoAssignPaymentIndirect({
        isInterestFirst,
        lawsuitOpts,
        interestData,
        paymentData: assignedDirectly.paymentData,
        paymentItemKey: curr.paymentItem.key,
      });

      return {
        paymentData: assignedIndirectly.paymentData,
        autoAssignHistory: [
          ...prev.autoAssignHistory,
          {
            paymentItemKey: curr.paymentItem.key,
            historyRecords: [
              ...assignedDirectly.historyRecords,
              ...assignedIndirectly.historyRecords
            ]
          }
        ]
      };
    }

    return {
      paymentData: assignedDirectly.paymentData,
      autoAssignHistory: [
        ...prev.autoAssignHistory,
        {
          paymentItemKey: curr.paymentItem.key,
          historyRecords: assignedDirectly.historyRecords
        }
      ]
    };
  }, {
    paymentData,
    autoAssignHistory: []
  });







