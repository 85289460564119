import React, { ReactNode } from 'react';
import SumUpNav from '../../SumUp/SumUpNav';
import PrivateContainer from './PrivateContainer';

const ResultContainer = ({ children }:{ children:ReactNode }) => {
  return (
    <>
      <SumUpNav />
      <PrivateContainer>
        {children}
      </PrivateContainer>
    </>
  );
};

export default ResultContainer;

