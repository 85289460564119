import _ from 'lodash';
import toRegex from 'to-regex';
import { AllocatedItems, AUTO_ASSIGN_EXACT, isAutoAssignDateValid } from '../autoAssignCommon';
import { PaymentItem, InterestData } from '../../../store/interestStorage';

export const allocateFromExact = ((
  { paymentDate, transferDescription }:PaymentItem,
  interestData: InterestData): AllocatedItems => {
  const ids = interestData.filter((interestItem) =>
    isAutoAssignDateValid(paymentDate, interestItem)
    && interestItem.accountingDoc
    && transferDescription
    && toRegex(interestItem.accountingDoc.trim(), { flags:'i' })
      .test(transferDescription))
    .map(({ key }) =>
      key);

  return {
    interestItemKeys: _.isEmpty(ids) ? null : ids,
    allocationMechanism: AUTO_ASSIGN_EXACT
  };
});