import { RobotOutlined } from '@ant-design/icons';
import { Card, Radio, RadioChangeEvent } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';

import { useTypedSelector } from '../../../store';
import { impExternalShouldAutoAssignUpdated } from '../../../store/impExternal';
import { ocrShouldAutoAssignUpdated } from '../../../store/impOcr';
import { GRID_LG } from '../../../styles';
import useWindowSize from '../../../utils/hooks/useWindowSize';
import { Button2Lines } from '../../Subscribe/SubscribeActions';
import { ImpTypes, IMP_OCR } from './impCommonData';

const ImpCommAutoAssign = ({ impType }:{ impType: ImpTypes }) => {

  const dispatch = useDispatch();

  const { width } = useWindowSize();

  const shouldAutoAssign = useTypedSelector(state =>
    impType === IMP_OCR ?
      state.impOcr.shouldAutoAssign : state.impExternal.shouldAutoAssign);

  const onChange = (e: RadioChangeEvent) => {
    if (impType === IMP_OCR) {
      dispatch(ocrShouldAutoAssignUpdated(e.target.value));
    } else {
      dispatch(impExternalShouldAutoAssignUpdated(e.target.value));
    }
  };

  return (
    <Card
      type="inner"
      title="Automatycznie zalicz wpłaty"
      extra={(
        <>
          <RobotOutlined />
          {' '}
          <strong>AI</strong>
        </>
)}
    >
      { width >= GRID_LG ? (
        <Radio.Group
          onChange={onChange}
          value={shouldAutoAssign}
        >
          <Radio style={radioStyle} value={false}>
            <strong> sam/a </strong>
            {' '}
            przypiszę wpłaty do roszczeń
          </Radio>
          <Radio style={radioStyle} value={true}>
            niech
            {' '}
            <strong> algorytm z AI</strong>
            {' '}
            przypisze wpłaty do roszczeń (można poprawić)
          </Radio>
        </Radio.Group>
      ) : (
        <Radio.Group
          onChange={onChange}
          value={shouldAutoAssign}
          style={{ width: '100%' }}
        >
          <Button2Lines value={false}>
            <strong> sam/a </strong>
            {' '}
            przypiszę wpłaty do roszczeń
          </Button2Lines>
          <Button2Lines value={true}>
            niech
            {' '}
            <strong> algorytm z AI</strong>
            {' '}
            przypisze wpłaty do roszczeń (można poprawić)
          </Button2Lines>
        </Radio.Group>
      )}
    </Card>

  );
};

export default ImpCommAutoAssign;

const radioStyle = {
  display: 'block',
  lineHeight: '24px',
};