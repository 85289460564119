import { toUnit } from '../../utils/helpers/money';

// 1. W sprawach o prawa majątkowe pobiera się od pisma opłatę stałą ustaloną według wartości przedmiotu sporu lub wartości przedmiotu zaskarżenia wynoszącej:
// 1) do 500 złotych – w kwocie 30 złotych;
// 2) ponad 500 złotych do 1500 złotych – w kwocie 100 złotych;
// 3) ponad 1500 złotych do 4000 złotych – w kwocie 200 złotych;
// 4) ponad 4000 złotych do 7500 złotych – w kwocie 400 złotych;
// 5) ponad 7500 złotych do 10 000 złotych – w kwocie 500 złotych;
// 6) ponad 10 000 złotych do 15 000 złotych – w kwocie 750 złotych;
// 7) ponad 15 000 złotych do 20 000 złotych – w kwocie 1000 złotych.
// 2. W sprawach o prawa majątkowe przy wartości przedmiotu sporu lub wartości przedmiotu zaskarżenia ponad 20 000 złotych pobiera się od pisma opłatę stosunkową wynoszącą 5% tej wartości, nie więcej jednak niż 200 000 złotych.

export const getCouthFees = (WpS: number) => {
  const wps = toUnit(WpS);
  if (wps <= 500) return 30;
  if (wps > 500 && wps <= 1500) return 100;
  if (wps > 1500 && wps <= 4000) return 200;
  if (wps > 4000 && wps <= 7500) return 400;
  if (wps > 7500 && wps <= 10000) return 500;
  if (wps > 10000 && wps <= 15000) return 750;
  if (wps > 15000 && wps <= 20000) return 1000;
  const feeCalc = wps * 0.05;
  const roundUpFee = Math.ceil(feeCalc);
  if (roundUpFee >= 200000) return 200000;
  return roundUpFee;
};