import { Divider, Form, Input } from 'antd';
import 'firebase/auth';
import firebase from 'firebase/compat/app';
import * as R from 'ramda';
import React, { useState } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { LocationState } from '../../@types';
import { handleSignUpEmail, handleSignUpExternalProvider } from '../../services/user/signUp';
import { AppDispatch, useTypedSelector } from '../../store';
import { privacyToggled, signUpEmailUpdated, signUpEmailValidated, signUpPasswordUpdated, signUpPasswordValidated, termsToggled } from '../../store/signUpReducer';
import { LabelForm, RowBg } from '../../styles';
import { emailRegex, passwordRegex } from '../../utils/helpers/regexChecks';
import FormAlert from '../UI/Alerts/Alerts';
import AuthButton from '../UI/Button/AuthButton';
import SignUpCheckbox from './SignUpCheckbox';

export function validateEmail(email: string, dispatch: AppDispatch) {
  if (email.match(emailRegex)) {
    dispatch(signUpEmailValidated({ emailValidated: true }));
  } else if (email === '') {
    dispatch(signUpEmailValidated({
      emailValidated: false,
      emailErrorMessage: 'Wpisz proszę adres email'
    }));
  } else {
    dispatch(signUpEmailValidated({
      emailValidated: false,
      emailErrorMessage: 'Wpisz proszę właściwie sformatowany email',
    }));
  }
}


export const strongPassText = 'Min 8 znaków: 1 specjalny, duża & mała litera, cyfra';


export function validatePassword(
  password: string,
  dispatch: AppDispatch
) {
  if (password === '') {
    dispatch(signUpPasswordValidated({
      passwordValidated: false,
      passwordErrorMessage: 'Wpisz hasło',
    }));
  } else if (!password.match(passwordRegex)) {
    dispatch(signUpPasswordValidated({
      passwordValidated: false,
      passwordErrorMessage: strongPassText,
    }));
  } else {
    dispatch(signUpPasswordValidated({ passwordValidated: true }));
  }
}


export const handleReturnTo = (state: LocationState, payment?: boolean) => {
  if (payment) return 'stay';
  return state?.returnTo;
};

const allTrue = (value:boolean | null) =>
  value === true;

const SignUpForm = ({ payment }: { payment?:boolean }) => {
  const {
    email,
    emailValidated,
    emailError,
    emailErrorMessage,
    password,
    passwordValidated,
    passwordError,
    passwordErrorMessage,
    error,
    errorMessage,
    termsAccepted,
    privacyAccepted,
    loading,
    provider,
  } = useTypedSelector((state) =>
    state.signUp, shallowEqual);

  const location = useLocation<LocationState>();

  const history = useHistory();
  const dispatch = useDispatch();
  const [termsWarning, setTermsWarning] = useState(false);
  const [privacyWarning, setPrivacyWarning] = useState(false);
  const [isPassInfo, setIsPassInfo] = useState(false);

  const signUpProvider = (prov: 'google' | 'facebook') => {
    setTermsWarning(!termsAccepted);
    setPrivacyWarning(!privacyAccepted);
    dispatch(signUpEmailUpdated(''));
    dispatch(signUpPasswordUpdated(''));
    if (termsAccepted  && privacyAccepted
    ) {
      dispatch(handleSignUpExternalProvider({
        provider: prov,
        history,
        returnTo: handleReturnTo(location.state, payment),
      }));
    }
  };

  const onTermsPress = () => {
    setTermsWarning(false);
    dispatch(termsToggled());
  };

  const onPrivacyPress = () => {
    setPrivacyWarning(false);
    dispatch(privacyToggled());
  };

  const signUpEmail = () => {
    setTermsWarning(!termsAccepted);
    setPrivacyWarning(!privacyAccepted);
    validateEmail(email.toLocaleLowerCase(), dispatch);
    validatePassword(password, dispatch);
    firebase.auth().languageCode = 'pl';
    const conditions = [emailValidated, passwordValidated, termsAccepted, privacyAccepted];
    if (R.all(allTrue, conditions)) {
      dispatch(handleSignUpEmail({
        history,
        returnTo: handleReturnTo(location.state, payment),
      }));
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      signUpEmail();
    }
  };

  return (
    <>
      <FormAlert
        message={errorMessage}
        isOn={error}
        type="error"
      />
      {error ?
        <RowBg /> : null}
      <Form style={{ width: '100%' }}>
        <LabelForm>
          Email
        </LabelForm>
        <Form.Item
          validateStatus={emailError ? 'error' : ''}
          help={emailErrorMessage ?? ''}
          style={{ marginBottom:0 }}
          initialValue={email}
          preserve={false}
        >
          <Input
            style={{ width:'100%' }}
            value={email}
            onChange={(event) =>
              dispatch(signUpEmailUpdated(event.target.value))}
            onBlur={(event) =>
              validateEmail(event.target.value.toLocaleLowerCase(), dispatch)}
          />
        </Form.Item>
        <LabelForm>
          Hasło
        </LabelForm>
        <Form.Item
          name="password"
          // eslint-disable-next-line no-nested-ternary
          validateStatus={passwordError ? 'error' :  (isPassInfo && password.length > 0) ? 'warning' :  ''}
          help={passwordErrorMessage ||
            (isPassInfo && password.length > 0 ? strongPassText : '')}
          preserve={false}
          style={{ marginBottom: 24 }}
        >
          <Input.Password
            value={password}
            onChange={(event) => {
              dispatch(signUpPasswordUpdated(event.target.value));
            }}
            onFocus={() =>
              setIsPassInfo(true)}
            onBlur={(event) => {
              setIsPassInfo(false);
              validatePassword(event.target.value, dispatch);
            }}
            onKeyDown={handleKeyDown}
          />
        </Form.Item>
        <AuthButton
          type="email"
          text={texts.button1}
          onClick={signUpEmail}
          loading={loading && provider === 'email'}
        />
        <Divider>LUB</Divider>
        <AuthButton
          type="google"
          text={texts.button2}
          onClick={() =>
            signUpProvider('google')}
          loading={loading && provider === 'google'}
          disabled={loading && provider === 'google'}
        />
        <AuthButton
          type="facebook"
          text={texts.button3}
          onClick={() =>
            signUpProvider('facebook')}
          loading={loading && provider === 'facebook'}
          disabled={loading && provider === 'facebook'}
        />
        <SignUpCheckbox
          text={texts.checkboxText}
          buttonText={texts.checkboxButton2}
          onChange={onPrivacyPress}
          navPath="/privacy"
          value={privacyAccepted}
          error={privacyWarning}
        />
        <SignUpCheckbox
          text={texts.checkboxText}
          buttonText={texts.checkboxButton1}
          onChange={onTermsPress}
          navPath="/terms"
          value={termsAccepted}
          error={termsWarning}
        />
      </Form>
    </>
  );
};


export default SignUpForm;

const texts = {
  button1: 'Zapisz się przez email',
  button2: 'Zapisz się przez Google',
  button3: 'Zapisz się przez Facebook',
  already: 'Mam już konto',
  checkboxText: 'Przeczytałam/em i akceptuję',
  checkboxButton1: 'regulamin',
  checkboxButton2: 'politykę prywatności',
};

