import { Col, Radio, RadioChangeEvent, Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { currencyTypeChanged } from '../../../store/currency';
import { GRID_MD_PX, RowExt } from '../../../styles';


const { Title, Paragraph } = Typography;

const CurrencyType = () => {
  const dispatch = useAppDispatch();

  const isSubscriber = useTypedSelector(state =>
    state.user.isSubscriber);

  const currencyType = useTypedSelector(state =>
    state.currency.currencyType);

  const onChange = (event:RadioChangeEvent) => {
    dispatch(currencyTypeChanged(event.target.value));
  };

  return (
    <Col sm={{ span:14 }} xs={{ span:24 }}>
      <Title level={4}>Rodzaj waluty</Title>
      <Paragraph>Wybierz jedną opcję</Paragraph>
      <RowExt marginTop="sm" marginBottom="md">
        <Radio.Group
          disabled={!isSubscriber}
          defaultValue="a"
          value={currencyType}
          onChange={onChange}
        >
          <CurrencyTypeButton value="eur">
            Euro
          </CurrencyTypeButton>
          <CurrencyTypeButton value="usd">
            USD
          </CurrencyTypeButton>
          <CurrencyTypeButton value="gbp">
            GBP
          </CurrencyTypeButton>
          <CurrencyTypeButton value="chf">
            CHf
          </CurrencyTypeButton>
        </Radio.Group>
      </RowExt>
    </Col>
  );
};

export default CurrencyType;


export const CurrencyTypeButton = styled(Radio.Button)`
  margin-right: 24px;
  @media only screen and (max-width: ${GRID_MD_PX}){
      margin-right:12px;
  }
`;