import { BankOutlined } from '@ant-design/icons';
import { Button, Tag } from 'antd';
import React from 'react';
import { goToStripe } from '../../../services/payment';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { GRID_MD } from '../../../styles';
import { BLUE } from '../../../styles/colors';
import useWindowSize from '../../../utils/hooks/useWindowSize';
import UserSubscriptionCard, { TagWrapper } from './UserSubscriptionCard';

const UserSubBasic = () => {
  const dispatch = useAppDispatch();
  const  { width } = useWindowSize();
  const subscriptionType = useTypedSelector(state =>
    state.user.subscriptionType);
  return (
    <UserSubscriptionCard
      subscriptionType={subscriptionType}
      actions={[
        <TagWrapper
          key="tag"
        >
          <Tag color={BLUE}>
            {width < GRID_MD ? 'Admin' : 'Administrator subskrypcji'}
          </Tag>
        </TagWrapper>,
        <Button
          type="link"
          key="button"
          icon={<BankOutlined />}
          onClick={() =>
            goToStripe(dispatch)}
        >
          {width >= GRID_MD ? 'Zarządzaj subskrypcją' : ''}
        </Button>
      ]}
      metaTitle="SUBSKRYPCJA PODSTAWOWA"
      metaDesc="Dziękujemy bardzo za wsparcie finansowe. Bez niego istnienie pozew.app jest niemożliwe. W razie jakichkolwiek wątpliwości prosimy o kontakt."
    />
  );

};

export default UserSubBasic;