import { Col, Typography } from 'antd';
import React from 'react';

import { ComponentChildren } from '../../../@types';
import useWindowSize from '../../../utils/hooks/useWindowSize';
import { GRID_MD, RowExt } from '../../../styles';
import AnimOpacity from '../../UI/Anim/AnimOpacity';


const { Title, Text } = Typography;

export const HomeMenuWrapper = ({ children }: ComponentChildren) => {
  const { height, width } = useWindowSize();
  return (
    <RowExt
      marginTop={width >= 425 && height <= 850 ? 12 : 24}
    >
      <Col
        lg={{
          span: 20, offset: 2
        }}
        md={{
          span:10, offset: 2
        }}
        sm={{
          span: 20, offset: 2
        }}
        xs={{
          span: 20, offset: 2
        }}
      >
        {children}
      </Col>
    </RowExt>
  );
};

export const HomeDescWrapper = ({ children }: ComponentChildren) => {
  return (
    <RowExt>
      <Col
        lg={{
          span: 20, offset: 2
        }}
        md={{
          span:10, offset: 2
        }}
        sm={{
          span: 20, offset: 2
        }}
        xs={{
          span: 20, offset: 2
        }}
      >
        <Text type="secondary">
          {children}
          {' '}
        </Text>
      </Col>
    </RowExt>
  );
};

export const HomeTitleWrapper = (
  {
    title,
    borderColor,
    level
  }: {
    borderColor: string,
    level: 2 | 1 | 5 | 3 | 4 | undefined,
    title: string
  }
) => {
  const { width } = useWindowSize();
  return (
    <RowExt>
      <Col
        span={2}
        style={{ borderLeft: width > GRID_MD ? `1px solid${borderColor}` : 'none' }}
      />
      <Col
        span={22}
      >
        <Title level={level}>{title}</Title>
      </Col>
    </RowExt>
  );
};

export const HomeAnimWrapper = ({ children }: ComponentChildren) => {
  const { height, width } = useWindowSize();
  if (width > 992 && height <= 850){
    return (
      <AnimOpacity>
        {children}
      </AnimOpacity>
    );
  }
  return (
    <>
      {children}
    </>
  );
};

