import _ from 'lodash';
import { find } from 'ramda';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { editAllDataUpdated, editReset } from '../../store/edit';

export const importFromInterestItem = () =>
  (dispatch:AppDispatch, getState: () => RootState) => {
    const state = getState();

    const { interestData } = state.interestStorage;

    const editKey = state.edit.key;

    if (_.isEmpty(interestData)) return;

    const editItem = find(interestItem =>
      interestItem.key === editKey, interestData);

    if (editItem){
      dispatch(editAllDataUpdated({
        ...editItem,
        maturityDate: editItem.maturityDate ?? null,
      }));
    }
  };

const useInitEditState = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(importFromInterestItem());

    return () => {
      dispatch(editReset());
    };
  },
  [dispatch]);
};

export default useInitEditState;