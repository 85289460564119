import _ from 'lodash';
import stringSimilarity from 'string-similarity';

import { InterestData, InterestItem, PaymentItem } from '../../../store/interestStorage';
import { RGX_FV_PREFIX, RGX_HAS_DIGIT_PREFIX } from '../../ML/DataExtraction/regexExtraction';
import { AUTO_ASSIGN_SIMILAR, AllocatedItems, isAutoAssignDateValid } from '../autoAssignCommon';


const digitsFvNameFormatting = [
  RGX_FV_PREFIX,
  RGX_HAS_DIGIT_PREFIX,
];

type Prob = {
  interestItemKey: string,
  relevantText: string,
  probability: number,
}[];


const checkAccDigitFormat = ({ accountingDoc }:InterestItem) => {
  if (_.isNull(accountingDoc)) return null;
  return digitsFvNameFormatting.find(value =>
    value.test(accountingDoc)) ?? null;
};

export const allocateFromSimilar = (paymentItem: PaymentItem,
  interestData: InterestData): AllocatedItems => {
  const { transferDescription } = paymentItem;
  if (transferDescription){
    const checkDigitsProb = _.cloneDeep(interestData)
      .reduce((prev: Prob, interestItem) => {
        const regex = checkAccDigitFormat(interestItem);
        if (_.isNull(regex)) return prev;
        const schemeMatches = transferDescription.match(regex);
        if (schemeMatches && _.isString(interestItem.accountingDoc)){
          const partsOfDescWithScheme = _.cloneDeep(schemeMatches).map(relevantText => {
            return {
              interestItemKey: interestItem.key,
              relevantText,
              probability:stringSimilarity.compareTwoStrings(relevantText, interestItem.accountingDoc as string)
            };
          });
          return isAutoAssignDateValid(paymentItem.paymentDate, interestItem) ? [
            ...prev,
            ...partsOfDescWithScheme
          ] : prev;
        }
        return prev;
      }, [])
      .find(value =>
        value.probability > 0.9);
    return {
      interestItemKeys:checkDigitsProb ? [checkDigitsProb.interestItemKey] : null,
      allocationMechanism: AUTO_ASSIGN_SIMILAR
    };
  }
  return {
    interestItemKeys: null,
    allocationMechanism: AUTO_ASSIGN_SIMILAR
  };
};