import { Button, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { EuroCircleOutlined } from '@ant-design/icons';
import { RED } from '../../../styles/colors';
import GuidePost from '../GuidePost';
import currencyThumb from '../../../assets/currencyThumb.png';
import { YT_CURRENCY } from '../../../utils/helpers/links';

const { Paragraph } = Typography;

const GuideCurrency = () => {
  return (
    <GuidePost
      title="Obliczenia walutowe bez poszukiwania kursów"
      color={RED}
      desc={(
        <>
          <Paragraph>
            Przelicz równowartość kwoty w innej walucie na złotówki w oparciu o średni kurs NBP we wskazanej dacie.
          </Paragraph>
          <Paragraph>
            Sytuacja często spotykana w zapisach umownych statuujących wartość roszczenia w złotówkach w oparciu o kurs waluty obcej.
          </Paragraph>
          <Link to="/subscribe">
            <Button
              type="link"
              icon={<EuroCircleOutlined />}
            >
              Zacznij używać
            </Button>
          </Link>
        </>
      )}
      src={currencyThumb}
      url={YT_CURRENCY}
    />
  );
};

export default GuideCurrency;
