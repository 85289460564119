import { Button, Space, Typography } from 'antd';
import React from 'react';
import { Img } from 'react-image';
import styled from 'styled-components';
import stripe from '../../assets/Stripe.svg';
import { RowExt } from '../../styles';

const { Paragraph, Title } = Typography;

const SubscribePremiumText = () => {
  return (
    <>
      <RowExt
        justify="center"
        marginBottom={32}
        style={{ paddingTop: 18 }}
      >
        <a type="link" href="https://stripe.com" target="_blank" rel="noreferrer">
          <StripeImg
            src={stripe}
          />
        </a>
      </RowExt>
      <Title level={5}>Potrzebujesz subskrypcji dla kilku kont łącznie?</Title>
      <RowExt>
        <ContactButton
          href="mailto:contact@pozew.app"
          type="link"
          style={{ marginBottom: 12 }}
        >
          Skontaktuj się z nami bezpośrednio
        </ContactButton>
      </RowExt>
      <Title level={5}>Czy otrzymam fakturę VAT?</Title>
      <Paragraph>
        W podanych kwotach wskazany jest podatek VAT. Co miesiąc na Twojego maila zostanie przesłana
        {' '}
        <strong>faktura VAT</strong>
        {' '}
        obciążająca wskazany przez Ciebie podmiot.
      </Paragraph>
      <Paragraph>
        Płatność jest obsługiwana przez
        <a type="link" href="https://stripe.com" target="_blank" rel="noreferrer"> Stripe </a>
        światowego lidera bezpieczeństwa w tej dziedzinie, obsługującego takie podmioty jak Booking.com, czy Saleforce.
      </Paragraph>
      <Space />
    </>
  );
};

export default SubscribePremiumText;

export const StripeImg = styled(Img)`
    height: 26px;
`;

const ContactButton = styled(Button)`
   margin-top: 18px;
`;

