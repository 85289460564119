import React from 'react';
import LawBorders from '../components/Law/LawBorders';
import RoadMapIntro from '../components/RoadMap/RoadMapIntro';
import RoadMapPar from '../components/RoadMap/RoadMapPar';
import AnimOpacity from '../components/UI/Anim/AnimOpacity';
import LawContainer from '../components/UI/Containers/LawContainer';
import { ScreenBackButton } from '../components/UI/DescScreenFooter';
import DotsEnding from '../components/UI/DotsEnding';
import { RowBg } from '../styles';
import { BLUE_DARK, RED } from '../styles/colors';
import { DashedDivider } from './About';

const RoadMap = () => {
  return (
    <AnimOpacity>
      <LawContainer title="Mapa drogowa rozwoju" />
      <RoadMapIntro />
      <DashedDivider />
      <LawBorders />
      <RoadMapPar
        bColor={RED}
        done
        title="START PROJEKTU"
        desc="17-06-2021 roku"
        par="Podstawowe funkcje aplikacji zostały wprowadzone. Życzymy dobrej pracy!"
      />
      <RoadMapPar
        bColor={BLUE_DARK}
        done
        title="KOD WYLICZEŃ"
        desc="02-08-2021 roku"
        par="Możliwość przesyłania wyliczeń za pomocą kodu. Wygenerowany kod zawierający dane wyliczeń jest tworzony w tekście uzasadniającym wyliczenia. Z wyliczeń może skorzystać sędzia lub referendarz w prowadzonej przez Ciebie sprawie, aby ją szybciej rozstrzygnąć. Ułatwi pracę pracownikom wymiaru sprawiedliwości. Możesz też przesłać kod przełożonemu/współpracownikowi, żeby mógł przeanalizować/zmienić Twoje wyliczenia."
      />
      <RoadMapPar
        done
        title="IMPORT DANYCH Z PLIKU EXCEL"
        desc="02-08-2021 roku"
        par="Importuj dane z księgowości bezpośrednio do formularza. Oszczędź jeszcze więcej czasu, dodatkowo zmniejsz ryzyko pomyłki."
        sub
      />
      <RoadMapPar
        title="WEZWANIA DO ZAPŁATY I UZASADNIENIA ORZECZEŃ"
        desc="11-08-2021 roku"
        par="Możliwość generowania innych tekstów, niż tylko uzasadnienia pozwów."
        done
      />
      <RoadMapPar
        bColor={RED}
        title="WYLICZENIA WALUTOWE"
        desc="Ukończono: 20-09-2021"
        par="Automatycznie przeliczaj roszczenia stanowiące równowartość euro/usd/gbp/chf na złotówki."
        sub
        done
      />
      <RoadMapPar
        title="PODSTAWA FAKTYCZNA I IMPORT Dokumentów KSIĘGOWYCH"
        desc="Ukończono: 21-10-2021"
        par="Masowo generuj podstawę faktyczną wraz z listą dowodów."
        sub
        done
      />
      <RoadMapPar
        title="ZAAWANSOWANE FUNKCJE WYLICZEŃ ROSZCZENIA GŁÓWNEGO POZWU "
        desc="do końca lutego 2022"
        par='Dodatkowe funkcje w opcji "roszczenie główne".'
      />
      <RoadMapPar
        bColor={RED}
        title="DODATKOWE FUNKCJE IMPORTU Z EXCELA"
        desc="do końca marca 2022"
        par="Jeszcze szybsze sporządzenie pozwu o zapłatę!"
        sub
      />
      <RoadMapPar
        bColor={BLUE_DARK}
        title="DARK MODE"
        desc="do końca kwietnia 2022"
        par="Ciemny motyw pozew.app - wygodniejszy do pracy w nocy."
      />
      <RoadMapPar
        title="ZAWANSOWANE FUNKCJE WYLICZANIA KOSZTÓW SĄDOWYCH"
        desc="do końca pierwszego maja 2022"
        par="Zautomatyzujmy wyliczenia kosztów sądowych!"
        sub
      />
      <DashedDivider />
      <LawContainer>
        <RowBg align="middle" justify="center">
          <ScreenBackButton />
        </RowBg>
        <DotsEnding />
      </LawContainer>
    </AnimOpacity>
  );
};

export default RoadMap;
