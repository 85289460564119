import { COMPENSATION_BASIS_ACT } from '../../utils/helpers/lawBasis';
import * as interestTypes from '../../store/lawsuitOpts';
import { InterestTypes } from '../../store/lawsuitOpts';

export const ANACYZM_RULE = 'art. 482 Kodeksu cywilnego';

export const INTEREST_LATE_DESC = {
  name: 'odsetki ustawowe za opóźnienie',
  legalBasis: 'art. 481 §1 i §2 Kodeksu cywilnego',
  declination: 'odsetek ustawowych za opóźnienie',
  declinationList: 'odesetkami ustawowymi za opóźnienie'
};

export const INTEREST_TRANSACTION_DESC = {
  name: 'odsetki ustawowe za opóźnienie w transakcjach handlowych, w których dłużnikiem nie jest podmiot publiczny będący podmiotem leczniczym',
  legalBasis: `art. 2, 4 ust 3 pkt b), 11b i 11c ${COMPENSATION_BASIS_ACT}`,
  declination: 'odsetek ustawowych za opóźnienie w transakcjach handlowych, w których dłużnikiem nie jest podmiot publiczny będący podmiotem leczniczym',
  declinationList: 'odsetkami ustawowymi za opóźnienie w transakcjach handlowych, w których dłużnikiem nie jest podmiot publiczny będący podmiotem leczniczym',
};

export const INTEREST_MED_PUBLIC_DESC = {
  name: 'odsetki ustawowe za opóźnienie w transakcjach handlowych, w których dłużnikiem jest podmiot publiczny będący podmiotem leczniczym',
  legalBasis: `art. 2, 4 ust 3 pkt a), 11b i 11c ${COMPENSATION_BASIS_ACT}`,
  declination: 'odsetek ustawowych za opóźnienie w transakcjach handlowych, w których dłużnikiem jest podmiot publiczny będący podmiotem leczniczym',
  declinationList: 'odsetkami ustawowymi za opóźnienie w transakcjach handlowych, w których dłużnikiem jest podmiot publiczny będący podmiotem leczniczym',
};

export const INTEREST_CAPITAL_DESC = {
  name: 'odsetki ustawowe(kapitałowe)',
  legalBasis: 'art. 359 §1 i §2 Kodeksu cywilnego',
  declination: 'odsetek ustawowych(kapitałowych)',
  declinationList: 'odsetkami ustawowymi(kapitałowymi)',
};

export const INTEREST_AGREE_DESC = {
  name: 'odsetki umowne',
  legalBasis: 'art. 359 §1 Kodeksu cywilnego',
  declination: 'odsetek umownych',
  declinationList: 'odsetkami umownymi',
};

export const getInterestDesc = (interestType: InterestTypes) => {
  switch (interestType) {
    case interestTypes.INTEREST_LATE:
      return INTEREST_LATE_DESC;
    case interestTypes.INTEREST_TRANSACTION:
      return INTEREST_TRANSACTION_DESC;
    case interestTypes.INTEREST_MED_PUBLIC:
      return INTEREST_MED_PUBLIC_DESC;
    case interestTypes.INTEREST_CAPITAL:
      return INTEREST_CAPITAL_DESC;
    case interestTypes.INTEREST_AGREE:
      return INTEREST_AGREE_DESC;
    default:
      return INTEREST_LATE_DESC;
  }
};