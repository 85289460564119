/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment, { Moment } from 'moment';
import { MaturityDateOpts, MATURITY_LATE_DAY } from './interestFormOptions';

export type ImportedFormExcelRow = string | number[];
interface ImportExcel {
  isImportExVisible: boolean;
  data: ImportedFormExcelRow[] | null;
  endDate: Moment | null;
  legalBasis: string | null;
  factualBasis: string | null;
  maturityDateOpts: MaturityDateOpts;
  step: number;
}

const initialState: ImportExcel = {
  isImportExVisible: false,
  data: null,
  endDate: null,
  legalBasis: null,
  factualBasis: null,
  maturityDateOpts: MATURITY_LATE_DAY,
  step: 0,
};

export const importExcelSlice = createSlice({
  name: 'importExcel',
  initialState,
  reducers: {
    importExcelIsVisible: (state: ImportExcel, action: PayloadAction<boolean>) => {
      state.isImportExVisible = action.payload;
    },
    importExcelDataUpdated: (state: ImportExcel, action: PayloadAction<ImportedFormExcelRow[] | null>) => {
      state.data = action.payload;
      state.step = 1;
    },
    importExcelEndDateUpdated: (state: ImportExcel, action: PayloadAction<Moment | null>) => {
      state.endDate = action.payload;
    },
    importExcelLegalBasisUpdated: (state: ImportExcel, action: PayloadAction<string | null>) => {
      state.legalBasis = action.payload;
    },
    importExcelFactualBasisUpdated: (state: ImportExcel, action: PayloadAction<string | null>) => {
      state.factualBasis = action.payload;
    },
    importExcelReUpdated: (state: ImportExcel) => {
      state.endDate = moment().endOf('day');
      state.legalBasis = null;
    },
    importExcelMaturityOptsUpdated: (state: ImportExcel, action: PayloadAction<MaturityDateOpts>) => {
      state.maturityDateOpts = action.payload;
    },
    importExcelStepUpdated: (state: ImportExcel, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
    importExcelReset: () =>
      initialState,
  },
});

export const {
  importExcelIsVisible,
  importExcelDataUpdated,
  importExcelEndDateUpdated,
  importExcelLegalBasisUpdated,
  importExcelFactualBasisUpdated,
  importExcelReUpdated,
  importExcelMaturityOptsUpdated,
  importExcelStepUpdated,
  importExcelReset,
} = importExcelSlice.actions;

export default importExcelSlice.reducer;
