import { YoutubeOutlined } from '@ant-design/icons';
import { Col } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { AboutContainer, DashedColLeft, DashedColTop, EmptyButton, GREY_WHITE, RowExt } from '../../styles';
import { RED } from '../../styles/colors';
import AbtTitle from '../About/AbtTitle';

const GuideIntro = () =>
  (
    <AboutContainer>
      <RowExt>
        <DashedColTop
          lg={{
            span: 7,
            offset: 2
          }}
          md={{
            offset: 2,
            span: 9
          }}
          xs={{
            offset: 2,
            span: 20,
          }}
        >
          <AbtTitle
            title={(
              <>
                <YoutubeOutlined />
                {' '}
                Instrukcje
              </>
          )}
            desc={(
              <>
                Omówienie poszczególnych
                <br />
                możliwości, modułów i opcji pozew.app.
                <br />
              </>
          )}
          />
        </DashedColTop>
        <TextCol
          lg={{
            offset: 0,
            span: 11,
          }}
          md={{
            offset: 0,
            span: 13,
          }}
          xs={{ span: 24 }}
        >
          <TextContainer>
            <TextMono>
              Zapoznaj się z aplikacją. Jeśli
              {' '}
              <strong>nie masz czasu</strong>
              {' '}
              aby samemu wprowadzać się w działanie pozew.app
              {' '}
              <strong>skontaktuj się</strong>
              {' '}
              z nami.
              <br />
              <br />
              Pierwsze szkolenie dla Twojej kancelarii / instytucji przeprowadzimy
              {' '}
              <strong>darmowo</strong>
              . Szkolenie jest zdalne i trwa około 30 minut.
              {' '}
              <br />
            </TextMono>
          </TextContainer>
          <Link to="intro">
            <StyledButton
              color={RED}
              bgColor={GREY_WHITE}
              hoverBg={RED}
              hoverColor={GREY_WHITE}
            >
              Umów się na DARMOWE WPROWADZENIE
            </StyledButton>
          </Link>
        </TextCol>
        <DashedColLeft
          xl={{ span: 2 }}
          lg={{ span: 1 }}
          xs={0}
        />
        <DashedColLeft
          xl={{ span: 2 }}
          lg={{ span: 3 }}
          xs={0}
        />
      </RowExt>
    </AboutContainer>
  );

export default GuideIntro;


const TextCol = styled(Col)`
  background-color: ${GREY_WHITE};
  @media only screen and (max-width: 767px) {
    margin-top: 48px;
	}
`;

const TextContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding:48px 48px 0 48px;
    width: 100%;
`;

const TextMono = styled.span`
   font-family: 'Fira Mono';
   font-size: 14px;
   font-weight: 500;
   letter-spacing: 2px;
`;

const StyledButton = styled(EmptyButton)`
   margin: 24px 0px 48px 48px;
`;
