/* eslint-disable @typescript-eslint/no-explicit-any */
export const textIfPadConvert = ({
  text,
  textEnd = '',
  arg,
  alt = '',
  convertFunc
}: {
  text: string,
  textEnd?: string,
  arg: any,
  alt?: string,
  convertFunc: (arg:any) => any;
}) =>
  arg ? ` ${text} ${convertFunc(arg)}${textEnd}` : (alt ?? '');

export const textIf = (text: string, prop: any, alt: string | undefined = '') =>
  prop ? text : (alt ?? '');

export const textIfPad = (text: string, prop: any, alt: string | undefined = '') =>
  prop ? ` ${text}` : (`${ alt}` ?? '');

export const textPad = (text:string) =>
  ` ${text}`;
