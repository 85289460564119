import { CalendarOutlined } from '@ant-design/icons';
import { Card, Radio, RadioChangeEvent } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';

import { useTypedSelector } from '../../../store';
import { impExternalMaturityOptsUpdated } from '../../../store/impExternal';
import { ocrMaturityOptsUpdated } from '../../../store/impOcr';
import { importExcelMaturityOptsUpdated } from '../../../store/importExcel';
import { MATURITY_DUE_DAY, MATURITY_LATE_DAY } from '../../../store/interestFormOptions';
import { GRID_LG } from '../../../styles';
import useWindowSize from '../../../utils/hooks/useWindowSize';
import { Button2Lines } from '../../Subscribe/SubscribeActions';
import { IMP_OCR, ImpTypes, IMP_EXCEL } from './impCommonData';

const ImpCommMaturityOpts = ({ impType }:{ impType: ImpTypes }) => {

  const dispatch = useDispatch();

  const { width } = useWindowSize();

  const maturityDateOpts = useTypedSelector(state => {
    switch (impType) {
      case IMP_OCR:
        return state.impOcr.maturityDateOpts;
      case IMP_EXCEL:
        return state.importEx.maturityDateOpts;
      default:
        return state.impExternal.maturityDateOpts;
    }
  });

  const onChange = (e: RadioChangeEvent) => {
    switch (impType) {
      case IMP_OCR:
        dispatch(ocrMaturityOptsUpdated(e.target.value));
        break;
      case IMP_EXCEL:
        dispatch(importExcelMaturityOptsUpdated(e.target.value));
        break;
      default:
        dispatch(impExternalMaturityOptsUpdated(e.target.value));
    }
  };

  return (

    <Card type="inner" title="Data wymagalności" extra={<CalendarOutlined />}>
      { width >= GRID_LG ? (
        <Radio.Group
          onChange={onChange}
          value={maturityDateOpts}
        >
          <Radio style={radioStyle} value={MATURITY_LATE_DAY}>
            data wymagalności równa
            {' '}
            <strong>dacie opóźnienia (początkowej)</strong>
          </Radio>
          <Radio style={radioStyle} value={MATURITY_DUE_DAY}>
            data wymagalności
            {' '}
            równa
            {' '}
            <strong>terminowi spełnienia świadczenia</strong>
          </Radio>
        </Radio.Group>
      ) : (
        <Radio.Group
          onChange={onChange}
          value={maturityDateOpts}
          style={{ width: '100%' }}
        >
          <Button2Lines value={MATURITY_LATE_DAY}>
            data wymagalności równa
            {' '}
            <strong>dacie opóźnienia (początkowej)</strong>
          </Button2Lines>
          <Button2Lines value={MATURITY_DUE_DAY}>
            data wymagalności
            {' '}
            równa
            {' '}
            <strong>terminowi spełnienia świadczenia</strong>
          </Button2Lines>
        </Radio.Group>
      )}
    </Card>

  );
};

export default ImpCommMaturityOpts;

const radioStyle = {
  display: 'block',
  lineHeight: '24px',
};