/* eslint-disable max-len */
import { Typography } from 'antd';
import React from 'react';
import { BLACK } from '../../../styles/colors';
import Icon from '../../../styles/Icon';
import { USER } from '../../../styles/iconTypes';
import { TitleWrapper } from './SubExcel';


const { Paragraph } = Typography;

const SubTraining = () => {
  return (
    <>
      <TitleWrapper
        color={BLACK}
        align="middle"
        marginTop={12}
        marginBottom={12}
      >
        <Icon
          iconType={USER}
          height="20"
          width="20"
          fill={BLACK}
          style={{ marginRight:12 }}
        />
        {' '}
        <strong>
          SZYBSZY ONBOARDING - SZKOLENIA PRACOWNIKÓW
        </strong>
      </TitleWrapper>
      <Paragraph>
        Dla podmiotów, które nie mają czasu na wprowadzenie nowych członków zespołu w działanie aplikacji np. "Fajnie, żeby nowy aplikant też zaczął używać pozew.app, ale nikt w kancelarii nie ma czasu, żeby go nauczyć."
      </Paragraph>
      <Paragraph>Szkolenia są zdalne i trwają ok. 30 min.</Paragraph>
    </>
  );
};

export default SubTraining;
