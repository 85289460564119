import React from 'react';
import SaveLawsuitModal from '../../components/Save/SaveLawsuitModal';

const SaveProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      {children}
      <SaveLawsuitModal />
    </>
  );
};

export default React.memo(SaveProvider);
