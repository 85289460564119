import { Divider } from 'antd';
import Descriptions from 'antd/lib/descriptions';
import _ from 'lodash';
import React from 'react';
import { PaymentData } from '../../../store/interestStorage';
import { RowMd, DescFullWidth } from '../../../styles';
import { formatPLN } from '../../../utils/helpers/formatNumber';

const { Item: DescItem } = Descriptions;

const DetailsInterestsIntro = ({
  assignedPayments,
  isClaimFromFulfilled,
  interestsSum,
  interestsSumCovered,
  interestsSumFulfilledCovered,
  interestsSumFulfilledOverall,
  interestsSumFulfilledUncovered,
  interestsSumUncovered,
}:{
  assignedPayments: PaymentData,
  isClaimFromFulfilled:boolean,
  interestsSum: number,
  interestsSumCovered: number,
  interestsSumFulfilledCovered: number,
  interestsSumFulfilledOverall: number,
  interestsSumFulfilledUncovered: number,
  interestsSumUncovered: number,
}) => {
  if (_.isEmpty(assignedPayments)) return null;
  return (
    <>
      <Divider>Roszczenie - odsetki</Divider>
      <RowMd>
        <DescFullWidth layout="horizontal" bordered key="d">
          <DescItem label="Łącznie po zaliczeniu wpłat i uwzgledniając sposób dochodzenia" span={3} labelStyle={{ fontWeight: 700 }}>
            <div style={{ fontSize: 16 }}>
              <strong>
                {`${formatPLN(isClaimFromFulfilled ? (interestsSumUncovered + interestsSumFulfilledUncovered) : interestsSumUncovered)}`}
              </strong>
            </div>
          </DescItem>
          <DescItem
            label={(
              <>
                Nieuiszczone odsetki od zaspokojonej części kwoty głównej są
                {' '}
                <strong>dochodzone</strong>
              </>
)}
            span={3}
          >
            {isClaimFromFulfilled ? 'tak' : 'nie'}
          </DescItem>
        </DescFullWidth>
      </RowMd>
      <RowMd>
        <DescFullWidth layout="horizontal" bordered key="d">
          <DescItem
            label={(
              <>
                Wysokość roszczenia o odsetki od
                {' '}
                <strong>zaspokojonej</strong>
                {' '}
                części kwoty głównej
              </>
)}
            span={3}
          >
            <strong>
              {`${formatPLN(interestsSumFulfilledOverall)}`}
            </strong>
          </DescItem>
          <DescItem
            label={(
              <>
                Wpłaty na roszczenie o odsetki od
                {' '}
                <strong>zaspokojonej</strong>
                {' '}
                części kwoty głównej
              </>
)}
            span={3}
          >
            <strong>
              {`${formatPLN(interestsSumFulfilledCovered)}`}
            </strong>
          </DescItem>
          <DescItem
            label={(
              <>
                Nieuiszczone odsetki od
                {' '}
                <strong>zaspokojonej</strong>
                {' '}
                części kwoty głównej
              </>
)}
            span={3}
          >
            <strong>
              {`${formatPLN(interestsSumFulfilledUncovered)}`}
            </strong>
          </DescItem>
        </DescFullWidth>
      </RowMd>
      <RowMd>
        <DescFullWidth layout="horizontal" bordered key="d">
          <DescItem
            label={(
              <>
                Wysokość roszczenia o odsetki od
                {' '}
                <strong>niezaspokojonej</strong>
                {' '}
                części kwoty głównej
              </>
)}
            span={3}
          >
            <strong>
              {`${formatPLN(interestsSum)}`}
            </strong>
          </DescItem>
          <DescItem
            label={(
              <>
                Wpłaty na roszczenie o odsetki od
                {' '}
                <strong>niezaspokojonej</strong>
                {' '}
                części kwoty głównej
              </>
)}
            span={3}
          >
            <strong>
              {`${formatPLN(interestsSumCovered)}`}
            </strong>
          </DescItem>
          <DescItem
            label={(
              <>
                Nieuiszczone odsetki od
                {' '}
                <strong>niezaspokojonej</strong>
                {' '}
                części kwoty głównej
              </>
)}
            span={3}
          >
            <strong>
              {`${formatPLN(interestsSumUncovered)}`}
            </strong>
          </DescItem>
        </DescFullWidth>
      </RowMd>
    </>
  );
};

export default DetailsInterestsIntro;