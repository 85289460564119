import { useTypedSelector } from '../../store';
import { InterestTypes, INTEREST_AGREE, INTEREST_MED_PUBLIC, INTEREST_TRANSACTION } from '../../store/lawsuitOpts';

export const conditionalStep = (typeOfInterest: InterestTypes, stepIfTransacOrAgree: number) =>
  checkIsTransacOrAgree(typeOfInterest) ? stepIfTransacOrAgree : stepIfTransacOrAgree - 1;

export const checkIsTransacOrAgree = (interestType:InterestTypes) =>
  interestType === INTEREST_TRANSACTION ||
interestType === INTEREST_MED_PUBLIC ||
interestType === INTEREST_AGREE;

const useIsTransacOrAgree = () => {
  const isTransacOrAgree = useTypedSelector((state) =>
    checkIsTransacOrAgree(state.lawsuitOpts.typeOfInterest));
  return isTransacOrAgree;
};

export default useIsTransacOrAgree;
