import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Popconfirm, Row, Space } from 'antd';
import _ from 'lodash';
import React, { useState } from 'react';
import { shallowEqual } from 'react-redux';

import { handleAddStep } from '../../../../services/steps';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import { lsDecreaseStep } from '../../../../store/lawsuitUI';
import { saveModalOpened } from '../../../../store/saveLawsuitModal';
import { GRID_MD, RowExt } from '../../../../styles';
import { resetLawsuit } from '../../../../utils/helpers/resetLawsuit';
import useGetNumberOfSteps from '../../../../utils/hooks/useGetNumberOfSteps';
import useIsPaymentAssignmentOn from '../../../../utils/hooks/useIsPaymentAssignmentOn';
import useIsTransacOrAgree from '../../../../utils/hooks/useIsTransacOrAgree';
import useListOfAssignedPayments from '../../../../utils/hooks/useListOfAssignedPayments';
import useListOfFullCoveredClaims from '../../../../utils/hooks/useListOffFullCoveredClaims';
import useWindowSize from '../../../../utils/hooks/useWindowSize';
import StepsWarningModal from '../../StepsWarningModal';
import BackButton from '../BackButton';
import NextButton from '../NextButton';


const ActionsBar = () => {
  const { width } = useWindowSize();
  const dispatch = useAppDispatch();
  const [isModalVisible, setModalVisible] = useState(false);

  const  {
    typeOfInterest,
    agreementInterestRate,
    principalAmountOpt,
    principalAmount
  } = useTypedSelector((state) =>
    state.lawsuitOpts, shallowEqual);

  const interestData = useTypedSelector((state) =>
    state.interestStorage.interestData);

  const { areSomeClaimsCovered } = useListOfFullCoveredClaims();

  const { areSomePaymentsWoAssignments } = useListOfAssignedPayments();

  const isTransacOrAgree = useIsTransacOrAgree();

  const isPaymentAssignmentOn = useIsPaymentAssignmentOn();

  const numberOfSteps = useGetNumberOfSteps();

  const {
    step,
    isStepIncreaseDisabled,
    saveId,
    interestDataProvider
  } = useTypedSelector((state) =>
    state.lawsuitUI, shallowEqual);

  const isAssignPaymentsVisible = isPaymentAssignmentOn
  && (isTransacOrAgree ?  step === 4 : step === 3);

  const coverWarningOn = isAssignPaymentsVisible
  && areSomeClaimsCovered;

  const isProviderSelectVisible = isTransacOrAgree ?  step === 2 : step === 1;

  const paymentsExists = useTypedSelector(state =>
    state.interestStorage.paymentData.length > 0, shallowEqual
  );

  const paymentWaringOn = isProviderSelectVisible
  && paymentsExists
  && interestDataProvider === 'MANUAL_STANDARD';

  const isInterestFormVisible = isTransacOrAgree && step === 3 || !isTransacOrAgree && step === 2;

  const noPaymentSum = (isAssignPaymentsVisible || isInterestFormVisible)
  && _.isEmpty(interestData);

  const assignmentWarningOn = isAssignPaymentsVisible
  && areSomePaymentsWoAssignments;

  const showCount = numberOfSteps - 1  === step;

  const onNextPress = () => {
    if (paymentWaringOn){
      setModalVisible(true);
    } else if (coverWarningOn || assignmentWarningOn){
      setModalVisible(true);
    } else {
      handleAddStep({
        dispatch,
        step,
        typeOfInterest,
        agreementInterestRate,
        principalAmountOpt,
        principalAmount,
      });
    }
  };

  return (
    <>
      <RowExt marginTop="md">
        <Col
          lg={{
            offset:0,
            span:12,
          }}
          xs={{
            offset:2,
            span: 10,
          }}
        >
          <Row align="middle">
            <Space>
              <BackButton
                onBack={() =>
                  dispatch(lsDecreaseStep())}
                showHome={step === 0}
              />
              {width >= GRID_MD ?
                <Divider type="vertical" /> : null}
              <Popconfirm
                title="Wszystkie dane z formularz zostaną usunięte"
                onConfirm={() =>
                  resetLawsuit(dispatch)}
              >
                <Button
                  icon={<CloseOutlined />}
                >
                  {width >= GRID_MD ?  'Wyczyść formularz' : ''}
                </Button>
              </Popconfirm>
            </Space>
          </Row>
        </Col>
        <Col
          lg={{ span:12 }}
          xs={{ span: 10 }}
        >
          <Row justify="end">
            <Space>
              <Button
                type="primary"
                icon={<SaveOutlined />}
                onClick={() =>
                  dispatch(saveModalOpened({
                    isSaveModalVisible: true,
                    isSaveFromResult: false,
                  }))}
              >
                {width >= GRID_MD ?  `Zapisz${saveId ? ' jako...' : ''}` : ''}
              </Button>
              {width >= GRID_MD ?
                <Divider type="vertical" /> : null}
              <NextButton
                showCount={showCount}
                noPartialSum={noPaymentSum}
                isStepIncreaseDisabled={isStepIncreaseDisabled}
                handleStep={onNextPress}
              />
            </Space>
          </Row>
        </Col>
      </RowExt>
      <StepsWarningModal {...{
        isModalVisible,
        setModalVisible,
        paymentWaringOn,
        addStep: () =>
          handleAddStep({
            dispatch,
            step,
            typeOfInterest,
            agreementInterestRate,
            principalAmountOpt,
            principalAmount,
          })
      }}
      />
    </>
  );
};

export default ActionsBar;
