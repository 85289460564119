import { Typography } from 'antd';
import React from 'react';
import { shallowEqual } from 'react-redux';

import { useTypedSelector } from '../../../store';
import { RowMd } from '../../../styles';
import ResultCard, { ResultComp, ResultInterest } from './ResultCard';



const {  Text } = Typography;

const ResultRow = (
  {
    sum1,
    sum2,
    sum3,
    legal
  }: {
    sum1: number,
    sum2: number,
    sum3: number,
    legal: string | React.ReactNode
  }
) => {
  const {
    compensationOn,
    principalAmountOpt
  } = useTypedSelector(state =>
    state.sumUp.lawsuitOpts, shallowEqual);
  return (
    <>
      <RowMd gutter={16}>
        {principalAmountOpt !== 'PRINCIPAL_INTEREST' ? (
          <ResultCard
            title={texts.main}
            value={sum1}
            compensationOn={compensationOn}
          />
        ) : null}
        <ResultCard
          title={<ResultInterest />}
          value={sum2}
          compensationOn={compensationOn}
        />
        {compensationOn ? (
          <ResultCard
            title={<ResultComp />}
            value={sum3}
            compensationOn={compensationOn}
          />
        ) : null}
      </RowMd>
      <RowMd>
        <Text type="secondary">{legal}</Text>
      </RowMd>
    </>
  );
};

export default ResultRow;

const texts = { main: 'Sumę kwot składowych' };