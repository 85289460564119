/* eslint-disable no-await-in-loop */
import { createWorker } from 'tesseract.js';

import { AppDispatch } from '../../../store';
import { appLoadingTextChanged, appProgressUpdated } from '../../../store/appLoading';
import { DocTypes, PAYMENT_CONFIRMATION } from '../docTypes';


export const ocrCategorize = async ({
  images,
  dispatch,
}:{
  images: {
    imageData: string,
    page: number,
    imageUrl: string,
  }[],
  dispatch: AppDispatch,
}) => {

  const worker = await prepareOcrWorker(dispatch);

  const docsWithCategory = [];

  for (const value of images) {
    const contents = await ocrPng({
      page: value.page,
      image: value.imageData,
      worker,
      overallPages: images.length,
    });

    docsWithCategory.push({
      input: contents.data.text,
      output: PAYMENT_CONFIRMATION as DocTypes,
      image: value.imageUrl,
    });
  }

  return docsWithCategory;
};


export const prepareOcrWorker = async (dispatch:AppDispatch) => {
  const worker = createWorker({
    logger: m => {
      if (m.status !== 'recognizing text')
        dispatch(appLoadingTextChanged('przygotowuję rozpoznawanie tekstu'));
      if (m.status === 'recognizing text' && m.progress === 0) {
        dispatch(appProgressUpdated({
          progress: m.progress,
          loadingText: `zaczynam rozpoznawać stronę numer: ${  m.userJobId}`
        }));
      }
      if (m.status === 'recognizing text' && m.progress > 0)
        dispatch(appProgressUpdated({
          progress: Math.floor(m.progress * 100),
          loadingText: `rozpoznawanie strony numer ${  m.userJobId}`
        }));
      if (m.status === 'recognizing text' && m.progress === 1) {
        dispatch(appProgressUpdated({
          progress: Math.floor(m.progress * 100),
          loadingText: 'rozpoznawanie strony ukończono'
        }));
      }
    }
  });
  await worker.load();
  await worker.loadLanguage('pol');
  await worker.initialize('pol');
  return worker;
};

export const ocrPng = ({
  worker,
  image,
  page,
  overallPages
}: {
  worker: Tesseract.Worker;
  image: string;
  page: number;
  overallPages: number
}) => {
  return worker.recognize(image, {}, `${page.toFixed(0)}/${overallPages.toFixed(0)}`);
};