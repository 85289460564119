import { Descriptions, Divider } from 'antd';
import _ from 'lodash';
import React from 'react';
import nextId from 'react-id-generator';
import { ClaimItem } from '../../../services/calc/claim';
import { PartialResult } from '../../../store/sumUp';
import { RowExt, DescFullWidth } from '../../../styles';
import { formatDate } from '../../../utils/helpers/date';
import { formatPLN } from '../../../utils/helpers/formatNumber';
import { DetailsPeriodResult } from './DetailsInterestsPeriods';

const { Item: DescItem } = Descriptions;

const DetailsFulfilledInterestPeriods = ({ claim }: {
  claim: ClaimItem | PartialResult,
}) => {

  if (_.isEmpty(claim.interestResultsFulfilled)) return null;
  return (
    <>
      <Divider>
        Odsetki od zaspokojonej części kwoty głównej
      </Divider>
      {claim.interestResultsFulfilled.map((result, index) => {
        return (
          <>
            <RowExt marginBottom="md">
              <DescFullWidth layout="horizontal" bordered key="d">
                <DescItem label="Podstawa naliczania" span={3}><strong>{`${formatPLN(claim.interestDataFulfilled[index]?.partialSum)}`}</strong></DescItem>
              </DescFullWidth>
            </RowExt>
            {result.periodResults.map((periodResult) => {
              return (
                <DetailsPeriodResult
                  key={nextId()}
                  periodResult={periodResult}
                />
              );
            })}
            <RowExt marginTop="md">
              <DescFullWidth layout="horizontal" bordered key="d">
                <DescItem label={`Łącznie ${`za okres do dnia ${formatDate(_.last(result.periodResults)?.periodEnd ?? '')}`}`} span={3}><strong>{`${formatPLN(result.interestSum)}`}</strong></DescItem>
              </DescFullWidth>
            </RowExt>
            <Divider />
          </>
        );
      })}
    </>
  );
};

export default DetailsFulfilledInterestPeriods;