import { Button, Modal, Typography } from 'antd';
import React, { useState } from 'react';
import { RowExt } from '../../../styles';

const SupportPayModal = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  return (
    <>
      <Button
        type="link"
        onClick={() =>
          setIsModalVisible(true)}
      >
        Wesprzyj jednorazowo
      </Button>
      <Modal
        onCancel={() =>
          setIsModalVisible(false)}
        visible={isModalVisible}
        footer={false}
        destroyOnClose
        title="JEDNORAZOWA WPŁATA"
        style={{ background: 'none !important' }}
      >
        <>
          <Typography.Paragraph>
            <strong>
              Polecamy subskrypcję jako
              {' '}
              najlepszy sposób wspierania projektu.
            </strong>
          </Typography.Paragraph>
          <Typography.Paragraph>
            Jeśli jednak chcesz wsprzeć autora jednorazowo, możesz to zrobić przez PayPal.
          </Typography.Paragraph>
          <Typography.Paragraph>
            Darowizna nie wiąże się z wystawieniem faktury VAT i odblokowaniem subskrypcji.
          </Typography.Paragraph>
          <Typography.Paragraph>Kliknij na banner poniżej i dokonaj wpłaty. Dziękujemy bardzo!</Typography.Paragraph>
          <RowExt>
            <form action="https://www.paypal.com/donate" method="post" target="_top">
              <input type="hidden" name="hosted_button_id" value="3K554EERXU76L" />
              <input
                type="image"
                style={{ height: 112 }}
                src="https://raw.githubusercontent.com/stefan-niedermann/paypal-donate-button/master/paypal-donate-button.png"
                name="submit"
                title="PayPal - The safer, easier way to pay online!"
                alt="Donate with PayPal button"
              />
              <img alt="" src="https://www.paypal.com/pl_PL/i/scr/pixel.gif" width="1" height="1" />
            </form>
          </RowExt>
        </>
      </Modal>
    </>
  );
};

export default SupportPayModal;
