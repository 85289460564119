import { ToolOutlined } from '@ant-design/icons';
import { Col, Space } from 'antd';
import React from 'react';
import styled from 'styled-components';

import { AboutContainer, BetaTag, DashedColLeft, DashedColTop, GREY_WHITE, RowExt } from '../../styles';
import AbtTitle from '../About/AbtTitle';

const DeveloperIntro = () =>
  (
    <AboutContainer>
      <RowExt>
        <DashedColTop
          lg={{
            span: 7,
            offset: 2
          }}
          md={{
            offset: 2,
            span: 9
          }}
          xs={{
            offset: 2,
            span: 20,
          }}
        >
          <AbtTitle
            title={(
              <Space>
                <ToolOutlined />
                Pozew API
                <BetaTag>
                  BETA
                </BetaTag>
              </Space>
          )}
            desc={(
              <>
                v0.1 | Wyjaśnienia dla developerów jak korzystać
                <br />
                z publicznego REST API pozew.app.
                <br />
              </>
          )}
          />
        </DashedColTop>
        <TextCol
          lg={{
            offset: 0,
            span: 11,
          }}
          md={{
            offset: 0,
            span: 13,
          }}
          xs={{ span: 24 }}
        >
          <TextContainer>
            <TextMono>
              Dane w pozew.app są przechowywane przy użyciu
              {' '}
              <strong>Google Cloud (Firestore)</strong>
              .
              {' '}
              API jest w trakcie rozwoju. W tym momencie naszym głównym celem jest zapewnienie
              {' '}
              <strong>możliwości przesyłania danych z programów księgowych bezpośrednio do pozew.app.</strong>
              <br />
              <br />
              <br />
            </TextMono>
          </TextContainer>
        </TextCol>
        <DashedColLeft
          xl={{ span: 2 }}
          lg={{ span: 1 }}
          xs={0}
        />
        <DashedColLeft
          xl={{ span: 2 }}
          lg={{ span: 3 }}
          xs={0}
        />
      </RowExt>
    </AboutContainer>
  );

export default DeveloperIntro;


const TextCol = styled(Col)`
  background-color: ${GREY_WHITE};
  @media only screen and (max-width: 767px) {
    margin-top: 48px;
	}
`;

const TextContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding:48px 48px 0 48px;
    width: 100%;
`;

const TextMono = styled.span`
   font-family: 'Fira Mono';
   font-size: 14px;
   font-weight: 500;
   letter-spacing: 2px;
`;