import { ArrowLeftOutlined, CalculatorOutlined, EuroOutlined, FileExcelOutlined, FilePdfOutlined, NumberOutlined, UploadOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Col } from 'antd';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { ComponentChildren } from '../../@types';
import { loadJson } from '../../services/handleJson';
import { useAppDispatch, useTypedSelector } from '../../store';
import { GRID_LG, GRID_MD_PX, RowExt } from '../../styles';
import { ButtonFullWidth, EmptyButton, LinkFullWidth } from '../../styles/ui';
import { resetLawsuit } from '../../utils/helpers/resetLawsuit';
import useWindowSize from '../../utils/hooks/useWindowSize';
import NewLawsuit from '../NewLawsuit/NewLawsuit';


const ButtonWrapper = ({ children }: ComponentChildren) =>
  (
    <Col lg={8} md={8} sm={24} xs={24}>
      {children}
    </Col>
  );

const ButtonToolsWrapper = ({ children }: ComponentChildren) =>
  (
    <Col lg={8} md={8} sm={24} xs={24}>
      {children}
    </Col>
  );

const DashboardActions = () => {
  const { width } = useWindowSize();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);
  const handleClick = () => {
    if (hiddenFileInput.current && hiddenFileInput) {
      hiddenFileInput.current.click();
    }
  };

  const [isModalVisible, setModalVisible] = useState(false);

  const step = useTypedSelector(state =>
    state.lawsuitUI.step);
  const interestData = useTypedSelector(state =>
    state.interestStorage.interestData);

  const isLawsuitEdited = step > 0 || interestData.length > 0;

  const onNew = () => {
    if (interestData.length > 0){
      setModalVisible(true);
    } else {
      resetLawsuit(dispatch);
      history.push('/lawsuit');
    }
  };

  return (
    <>
      <RowExt gutter={[16, 0]} justify="space-between" fullWidth>
        <Col lg={8} md={8} sm={24} xs={24}>
          <LinkFullWidth
            to={{ pathname: '/lawsuit' }}
          >
            <ButtonFullWidth
              icon={<ArrowLeftOutlined />}
              disabled={!isLawsuitEdited}
            >
              {width > GRID_LG ? 'Obliczenia' : ''}
            </ButtonFullWidth>
          </LinkFullWidth>
        </Col>
        <Col lg={16} md={16} sm={24} xs={24}>
          <ButtonRespTop
            type="primary"
            icon={<CalculatorOutlined />}
            onClick={onNew}
          >
            Nowe obliczenia
          </ButtonRespTop>
        </Col>
      </RowExt>
      <RowExt gutter={[16, 0]} fullWidth>
        <ButtonToolsWrapper>
          <LinkFullWidth
            to={{ pathname: '/importExcel' }}
          >
            <EmptyButtonResp
              style={{ width: '100%' }}
              icon={<FileExcelOutlined />}
              dotted
            >
              Zmień excel w tekst pozwu
            </EmptyButtonResp>
          </LinkFullWidth>
        </ButtonToolsWrapper>
        <ButtonToolsWrapper>
          <LinkFullWidth
            to={{ pathname: '/ocr' }}
          >
            <EmptyButtonResp
              style={{ width: '100%' }}
              icon={<FilePdfOutlined />}
              dotted
            >
              Zmień skany w tekst pozwu
            </EmptyButtonResp>
          </LinkFullWidth>
        </ButtonToolsWrapper>
        <ButtonToolsWrapper>
          <LinkFullWidth
            to={{ pathname: '/currency' }}
          >
            <EmptyButtonResp
              style={{ width: '100%' }}
              icon={<EuroOutlined />}
              dotted
            >
              Przelicz walutę do pozwu
            </EmptyButtonResp>
          </LinkFullWidth>
        </ButtonToolsWrapper>
      </RowExt>
      <RowExt gutter={[16, 0]} fullWidth>
        <ButtonWrapper>
          <LinkFullWidth
            to={{ pathname: '/user' }}
          >
            <ButtonResp
              icon={<UserOutlined />}
            >
              Konto & kontakt
            </ButtonResp>
          </LinkFullWidth>
        </ButtonWrapper>
        <ButtonWrapper>
          <input
            type="file"
            ref={hiddenFileInput}
            onChange={(event) =>
              loadJson(event, history)}
            style={{ display: 'none' }}
            accept="application/json"
          />
          <ButtonResp
            icon={<UploadOutlined />}
            onClick={handleClick}
            type="dashed"
          >
            Plik z obliczeniami
          </ButtonResp>
        </ButtonWrapper>
        <ButtonWrapper>
          <LinkFullWidth
            to={{ pathname: '/lsCode' }}
          >
            <ButtonResp
              icon={<NumberOutlined />}
              type="dashed"
            >
              Wpisz KOD WYLICZEŃ
            </ButtonResp>
          </LinkFullWidth>
        </ButtonWrapper>
      </RowExt>
      <NewLawsuit
        {...{
          isModalVisible,
          setModalVisible,
        }}
      />
    </>
  );
};

export default DashboardActions;


const ButtonResp = styled(Button)`
   margin-top:16px;
    width: 100%;
    @media only screen and (max-width: ${GRID_MD_PX}) {
    display: block;
    margin-top: 24px;
    width: 100%;
	}
`;
const EmptyButtonResp = styled(EmptyButton)`
    margin-top:16px;
    @media only screen and (max-width: ${GRID_MD_PX}) {
    display: block;
    margin-top: 24px;
    width: 100%;
	}
`;

const ButtonRespTop = styled(ButtonResp)`
    margin-top: 0;
    @media only screen and (max-width: ${GRID_MD_PX}) {
    display: block;
    margin-top: 24px;
    width: 100%;
    }
`;