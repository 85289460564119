import { Col } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { ComponentChildren } from '../../../@types';
import { GREY_WHITE } from '../../../styles';

const AssignGraphContainer = ({ children }: ComponentChildren) => {
  return (
    <Col span={2}>
      <Container>
        {children}
      </Container>
    </Col>
  );
};

export default AssignGraphContainer;

const Container = styled.div`
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
    width: 3px;
    background: ${GREY_WHITE};
`;