import { ControlOutlined } from '@ant-design/icons';
import { Divider, Typography } from 'antd';
import { AnimatePresence, motion } from 'framer-motion';
import _ from 'lodash';
import React from 'react';
import { shallowEqual } from 'react-redux';
import { useTypedSelector } from '../../../store';
import { formatDate } from '../../../utils/helpers/date';


const { Title, Text } = Typography;

type Props = {
  children: React.ReactNode;
};

const DateOptionsCard = (
  { children }: Props
) => {

  const maturityDate = useTypedSelector(state =>
    _.isNull(state.edit.key)  ?
      state.interestForm.maturityDate :
      state.edit.maturityDate
  , shallowEqual);

  return (
    <AnimatePresence>
      <motion.div
        transition={{
          ease: 'easeInOut',
          duration: 1,
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Title
          level={2}
        >
          <ControlOutlined />
          {' '}
          Data wymagalności i opóźnienia - opcje
        </Title>
        <Text>
          {maturityDate ? (
            <>
              Aktualnie przyjęta data wymagalności:
              {' '}
              <strong>{formatDate(maturityDate || '')}</strong>
              .
            </>
          ) : null}
          {' '}
          Zmień opcje według uznania.
        </Text>
        <Divider />
        {children}
      </motion.div>
    </AnimatePresence>
  );
};


export default DateOptionsCard;