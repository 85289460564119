import { NumberOutlined, StopOutlined, WarningOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import React from 'react';

import { BLUE } from '../../../../styles';
import { RED } from '../../../../styles/colors';
import { RowWithWarnings } from '../../../../utils/hooks/useImpExcelValidate';
import ImpExcelField from './ImpExcelField';

const ImpExcelItem = ({
  row,
  index
}:{
  row:RowWithWarnings,
  index: number
}) => {
  const isFatalError = Object
    .values(row).some(value =>
      value.warnings.some(({ isFatal }) =>
        isFatal === true));
  const isModded = Object
    .values(row).some(value =>
      value.warnings.some(({ isFatal }) =>
        isFatal === false));

  return (
    <Card
      type="inner"
      style={{ marginBottom: 12 }}
      extra={(
        <>
          {
          isFatalError ? (
            <StopOutlined style={{
              color: RED, margin: '0 2px'
            }}
            />
          ) : null
  }
          {isModded ? (
            <WarningOutlined style={{
              color: BLUE, margin: '0 2px'
            }}
            />
          ) : null}
        </>
  )}
      title={(
        <>
          Wiersz
          {' '}
          {isFatalError ? ' - odmowa importu' : ''}
          {' '}
          <NumberOutlined />
          {' '}
          {index + 1}
        </>
  )}
    >
      <ImpExcelField
        {...{
          row,
          fieldKey:'dueDate',
          label: 'Termin spełnienia świadczenia'
        }}
      />
      <ImpExcelField
        {...{
          row,
          fieldKey:'partialSum',
          label: 'Kwota główna długu'
        }}
      />
      <ImpExcelField
        {...{
          row,
          fieldKey:'accountingDoc',
          label: 'Numer dokumentu księgowego'
        }}
      />
    </Card>
  );
};


export default ImpExcelItem;