import { notification } from 'antd';
import { getDoc } from 'firebase/firestore';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { auth } from '../../services/firebase/auth';
import { lsDocRef } from '../../services/firebase/firestoreRefs';
import { LawsuitSaveCode } from '../../services/lsCode';
import { setSumUp } from '../../services/setSumUp';
import { useAppDispatch } from '../../store';
import { InterestItem } from '../../store/interestStorage';
import { justOptsReset } from '../../store/justOpts';
import { InterestTypes, PrincipalAmountOpt } from '../../store/lawsuitOpts';
import { sumUpReset } from '../../store/sumUp';
import { checkSaveData } from '../helpers/checkSaveData';
import { errDescGeneral, errMsgGeneral } from '../helpers/errorTexts';
import { formatInterestDataFromSave } from '../helpers/formatInterestDataFromSave';
import { formatPaymentsFromSave } from '../helpers/formatPaymentsFromSave';
import { withAppLoadingAndUser } from '../helpers/withAppLoading';


export interface LawsuitResult{
  typeOfInterest:InterestTypes,
  compensationOn: boolean,
  agreementInterestRate: number,
  principalAmountOpt: PrincipalAmountOpt,
  principalAmount: number,
  interestData: InterestItem[],
}

const useGetResultLsCode = (id:string) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [createDate, setCreateDate] = useState('');

  useEffect(() => {
    const fetchDataAndSetSumUp = () =>
      withAppLoadingAndUser({
        dispatch,
        startLoadingText: 'pobieranie danych z serwera',
        user: auth.currentUser,
        userCanBeAnonymous: true,
      }, async () => {
        try {
          const lcCodeData = await getDoc(lsDocRef(id));

          if (!lcCodeData.exists) {
            throw new Error('Nie ma takiego zapisu na serwerze');
          }

          dispatch(sumUpReset());

          const lawsuitSaveCode = lcCodeData.data() as LawsuitSaveCode;

          const {
            interestData,
            lawsuitOpts,
            paymentData,
            createDate: createDateLocal
          } = formatFetchedData(lawsuitSaveCode);
          setCreateDate(createDateLocal);

          const isCalculationDataCorrect = checkSaveData({
            ...lawsuitOpts,
            interestData,
          }, false);

          isCalculationDataCorrect ?
            await setSumUp({
              dispatch,
              interestData,
              paymentData,
              lawsuitOpts,
            }) :  history.goBack();

        } catch (error){
          history.goBack();
          notification.error({
            message: errMsgGeneral,
            description: error instanceof Error ? error.message : errDescGeneral,
          });
        } finally {
          dispatch(justOptsReset());
        }
      });
    _.isString(id) ?
      fetchDataAndSetSumUp() :
      history.push('/');
  }, [dispatch, history, id]);


  return { createDate };
};

export default useGetResultLsCode;

const formatFetchedData = (lawsuitSaveCode: LawsuitSaveCode | undefined) => {
  if (lawsuitSaveCode){
    const { lawsuitOpts } = lawsuitSaveCode;

    return {
      createDate: moment(lawsuitSaveCode.createDate.toDate()).format('YYYY-MM-DD hh:mm') ?? '',
      interestData: formatInterestDataFromSave(lawsuitSaveCode.interestData),
      paymentData: formatPaymentsFromSave(lawsuitSaveCode.paymentData),
      lawsuitOpts,
    };
  }
  throw new Error('Nie ma takiego zapisu na serwerze');
};
