import _ from 'lodash';
import { Moment } from 'moment';
import { shallowEqual } from 'react-redux';

import { CompensationResult } from '../../../services/calc/compensationCalc';
import { useTypedSelector } from '../../../store';
import { JustIntType } from '../../../store/justOpts';
import { PartialResult } from '../../../store/sumUp';
import { arrStrFormat, ENT } from '../../helpers/arrStrFormat';
import { formatDate } from '../../helpers/date';
import { formatPLN } from '../../helpers/formatNumber';
import { COMPENSATION_BASIS, COMPENSATION_BASIS_ACT } from '../../helpers/lawBasis';
import { toUnit } from '../../helpers/money';
import { textIf } from '../../helpers/textIf';
import useCompSum from './useCompSum';


export const compIntroText = (
  justIntCap: JustIntType,
  isSingleResult: boolean
) => {
  return [
    textIf(
      textIf('3. ', justIntCap === 'JUST_WITH_CAP', '2. '),
      !isSingleResult),
    `Rekompensaty na podstawie ${COMPENSATION_BASIS} zostały wyliczone w sposób następujący:`
  ].join('');
};

export const compensationText = ({
  compensationResult,
  partialSum,
  maturityDate,
  index
}:{
  compensationResult?:CompensationResult,
  partialSum:number,
  maturityDate: Moment | string | null,
  index: number | null
}) => {
  if (compensationResult){
    // ustalana przy zastosowaniu średniego kursu euro ogłoszonego przez Narodowy Bank Polski ostatniego dnia roboczego miesiąca poprzedzającego miesiąc, w którym świadczenie pieniężne stało się wymagalne
    return arrStrFormat([
      textIf(`${_.isNumber(index) ? index + 1 : 0}) `, _.isNumber(index)),

      textIf(`Roszczenie nr ${_.isNumber(index) ? index + 1 : 0} `,
        _.isNumber(index),
        'Roszczenie '),

      `stanowiące postawę 
      naliczania rekompensaty stało się wymagalne w dniu ${formatDate(maturityDate ?? '')}
      i miało wartość ${formatPLN(partialSum)}.`,

      ` Średni kurs euro ogłoszony przez NBP ostatniego dnia roboczego 
      w miesiącu poprzedzającym dzień wymagalności 
      (${formatDate(compensationResult.lastWorkingDay)}) wynosił ${compensationResult.exchangeRate}. `,

      `Rekompensata wynosiła ${toUnit(compensationResult.valueEuro).toFixed(2)} euro, 
      a po przeliczeniu na złote ${formatPLN(compensationResult.valuePLN)}.`,
    ]);
  }
  return '';
};

export const compensationBasis = (partialResults:PartialResult[]) =>
  `Podstawę prawną dochodzenia rekompensat${partialResults.length > 1 ? '' : 'y'} stanowi art. 10 ust. 1 i 2 ${COMPENSATION_BASIS_ACT}.`;


const useCompText = () => {
  const { partialResults } = useTypedSelector(state =>
    state.sumUp, shallowEqual);

  const { compensationOn } = useTypedSelector(state =>
    state.sumUp.lawsuitOpts, shallowEqual);

  const justIntCap  = useTypedSelector(state =>
    state.justOpts.justIntCap);


  const { withComp } = useTypedSelector(state =>
    state.justOpts, shallowEqual);

  const compSum = useCompSum();

  if (compensationOn && withComp){
    const partialResultTexts = partialResults.reduce((prev, curr, index) =>
      prev +
      compensationText({
        ...curr,
        index: partialResults.length > 1 ? index : null,
      }), '');
    return [
      ENT,
      compIntroText(
        justIntCap,
        partialResults.length === 1
      ),
      ENT,
      partialResultTexts,
      ENT,
      compSum,
      ENT,
      compensationBasis(partialResults),
    ].join('');
  }
  return '';
};

export default useCompText;
