import { ArrowLeftOutlined, BankOutlined, ContainerOutlined, UserAddOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import React from 'react';
import { useHistory } from 'react-router';
import SubExistsContainer from '../components/UI/Containers/SubExistsContainer';
import { goToStripe } from '../services/payment';
import { useAppDispatch, useTypedSelector } from '../store';
import { BLACK, ButtonFullWidth, EmptyButton, RowExt } from '../styles';

const PaymentSuccess = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const subscriptionType = useTypedSelector(state =>
    state.user.subscriptionType);
  return (
    <SubExistsContainer>
      <RowExt justify="center" marginTop={12}>
        <Typography.Title level={5}>
          Dodatkowe funkcje zostały uruchomione
        </Typography.Title>
        <Typography.Paragraph>
          Dziękujemy bardzo za wsparcie !
        </Typography.Paragraph>
      </RowExt>
      <RowExt marginTop={10} justify="space-between" align="middle">
        <EmptyButton
          style={{ width: '100%' }}
          color={BLACK}
          hoverColor="white"
          hoverBg={BLACK}
          icon={<ContainerOutlined />}
          onClick={() =>
            history.push('/dashboard')}
        >
          Panel użytkownika
        </EmptyButton>
      </RowExt>
      {subscriptionType === 'premium' || subscriptionType === 'standard' ? (
        <RowExt marginTop={24} justify="space-between" align="middle">
          <EmptyButton
            full
            onClick={() =>
              history.push('/connectedSubscriptionUsers')}
            icon={<UserAddOutlined />}
          >
            Dodaj użytkowników subskrypcji
          </EmptyButton>
        </RowExt>
      ) : null}
      {subscriptionType === 'basic' || subscriptionType === 'standard' ? (
        <RowExt marginTop={24} justify="space-between" align="middle">
          <EmptyButton
            full
            onClick={() =>
              goToStripe(dispatch)}
            icon={<BankOutlined />}
          >
            Zarządzaj subskrypcją
          </EmptyButton>
        </RowExt>
      ) : null}
      <RowExt marginTop={24} justify="space-between" align="middle">
        <ButtonFullWidth
          onClick={() =>
            history.push('/subscribe')}
          icon={<ArrowLeftOutlined />}
          type="dashed"
        >
          Wróć
        </ButtonFullWidth>
      </RowExt>
    </SubExistsContainer>
  );
};

export default PaymentSuccess;
