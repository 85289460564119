import { ArrowLeftOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import React from 'react';
import { ButtonFullWidth } from '../../styles/ui';
import MenuItemWrapper from '../Home/Menu/MenuItemWrapper';


const SignUpNav = (
  {
    onBack,
    goToLogin
  }: {
    onBack:() => void,
    goToLogin: () => void
  }
) => {
  return (
    <MenuItemWrapper>
      <Row>
        <Col span={12}>
          <ButtonFullWidth
            icon={<ArrowLeftOutlined />}
            onClick={onBack}
          >
            {texts.back}
          </ButtonFullWidth>
        </Col>
        <Col span={12}>
          <ButtonFullWidth
            type="link"
            onClick={goToLogin}
          >
            {texts.already}
          </ButtonFullWidth>
        </Col>
      </Row>
    </MenuItemWrapper>
  );
};

export default SignUpNav;


const texts = {
  already: 'Mam już konto',
  back: 'Wróć'
};