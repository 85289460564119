import { AppDispatch } from '../../store';
import { editInfoUpdated } from '../../store/edit';
import { infoUpdated } from '../../store/interestFormOptions';

export const displayEditInfo = ({
  dispatch,
  infoMsg,
  infoOut,
}:{
  dispatch: AppDispatch
  infoMsg: string,
  infoOut: boolean,
}) => {
  dispatch(editInfoUpdated({
    info: true,
    infoMsg,
  }));
  setTimeout(() => {
    dispatch(editInfoUpdated({
      info: infoOut,
      infoMsg: '',
    }));
  }, 5000);
};

export const displayFormInfo = ({
  dispatch,
  infoMsg,
  infoOut,
}:{
  dispatch: AppDispatch
  infoMsg: string,
  infoOut: boolean,
}) => {
  dispatch(infoUpdated({
    info: true,
    infoMsg,
  }));
  setTimeout(() => {
    dispatch(infoUpdated({
      info: infoOut,
      infoMsg: '',
    }));
  }, 5000);
};