/* eslint-disable no-param-reassign */
import { notification } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import Papa from 'papaparse';
import { v4 as uuidv4 } from 'uuid';

import { AppDispatch } from '../store';
import { PaymentData, paymentDataChanged, PaymentItem } from '../store/interestStorage';
import { isImportPaymentVisibleUpdated } from '../store/lawsuitUI';
import { errDescGeneral, errMsgGeneral } from '../utils/helpers/errorTexts';
import { getPartialSum } from './importEx';

const CSV_PAY_DATE =
  [
    // Alior
    '#data operacji',
    // MBank
    'data księgowania',
    // PKO BP
    'Data operacji',
    // Default
    'data'
  ];

const CSV_PAY_DESC =
[
  // Alior
  '#opis operacji',
  // MBank
  'szczegóły transakcji',
  // PKO BP
  'opis transakcji',
  // Default
  'tytuł',
  'opis',
  'szczegóły'
];

const CSV_PAY_SUM =
  [
    // Alior
    '#kwota',
    // MBank
    'Kwota operacji',
    // PKO BP Default
    'kwota'
  ];


export const BANK_ALIOR = 'BANK_ALIOR';
export const BANK_M_BANK = 'BANK_M_BANK';
export const BANK_PKO_BP = 'BANK_PKO_BP';
export const BANK_PKO_SA = 'BANK_PKO_SA';
export const BANK_MILLENNIUM = 'BANK_MILLENNIUM';
export const BANK_CITY_BANK = 'BANK_CITY_BANK';


export const BANK_NAMES = [
  BANK_ALIOR,
  BANK_M_BANK,
  BANK_PKO_BP,
  BANK_PKO_SA,
  BANK_MILLENNIUM,
  BANK_CITY_BANK,
];

export type BankNames =
  typeof BANK_ALIOR |
  typeof BANK_M_BANK |
  typeof BANK_PKO_BP |
  typeof BANK_PKO_SA |
  typeof BANK_MILLENNIUM |
  typeof BANK_CITY_BANK;

export const ENCODING_UTF_8 = 'utf-8';
export const ENCODING_POLISH = 'iso-8859-2';

const DELIMITER_DEFAULT = '';
const DELIMITER_TAB = '\t';


const createEncodingMap = () => {
  const encodingMap = new Map();
  const delimiterMap = new Map();
  BANK_NAMES.forEach(name => {
    switch (name) {
      case BANK_ALIOR:
        encodingMap.set(name, ENCODING_POLISH);
        delimiterMap.set(name, DELIMITER_TAB);
        break;
      case BANK_M_BANK:
        encodingMap.set(name, ENCODING_POLISH);
        delimiterMap.set(name, DELIMITER_TAB);
        break;
      case BANK_PKO_BP:
        encodingMap.set(name, ENCODING_POLISH);
        delimiterMap.set(name, DELIMITER_DEFAULT);
        break;
      case BANK_PKO_SA:
        encodingMap.set(name, ENCODING_POLISH);
        delimiterMap.set(name, DELIMITER_DEFAULT);
        break;
      default:
        encodingMap.set(name, ENCODING_UTF_8);
        delimiterMap.set(name, DELIMITER_DEFAULT);
        break;
    }
  });
  return {
    encodingMap,
    delimiterMap,
  };
};

const checkIfCvsFile = (
  event:React.ChangeEvent<HTMLInputElement>
) => {
  if (event.target &&
    event.target.files &&
    event.target.files[0].type === 'text/csv'
  ){
    return event.target.files[0];
  }
  throw new Error('Nie mogę znaleźć pliku lub nieprawidłowy plik');
};


export const readCvs = (
  event:React.ChangeEvent<HTMLInputElement>,
  bankName: BankNames,
  dispatch: AppDispatch
) => {
  event.preventDefault();
  // @ts-ignore
  const file = event.target.files[0];
  // @ts-ignore
  const reader = new FileReader();
  const { encodingMap, delimiterMap } = createEncodingMap();
  reader.onload = (evt) => {
    const res = evt.target?.result as string | null | undefined;
    try {
      if (res){
        checkIfCvsFile(event);
        // @ts-ignore
        const cvsResult = Papa.parse(fixEncoding(res), {
          delimiter: delimiterMap.get(bankName),
          dynamicTyping: true
        });

        const cvsDataWithCol = (delimiterMap.get(bankName) === DELIMITER_TAB) ?
          createColumns(cvsResult.data) as string[][]
          : cvsResult.data as string[][];

        const noHeaderData = bankName === BANK_PKO_SA;

        const {
          startRow,
          columnIndexes
        } = findRelevantFields(cvsDataWithCol, noHeaderData);


        const paymentData = _.cloneDeep(cvsDataWithCol)
          .slice(startRow)
          .reduce((prev: PaymentData, row) => {
            if (_.isEmpty(row)) return prev;
            return [
              ...prev,
              createPaymentItem(row, columnIndexes, bankName)
            ];
          }, [])
          .filter(paymentItem =>
            moment(paymentItem.paymentDate).isValid() &&
            _.isNumber(paymentItem.paymentSum));

        if (_.isEmpty(paymentData)){
          throw new Error('Brak danych');
        }

        dispatch(paymentDataChanged(paymentData));
        dispatch(isImportPaymentVisibleUpdated(false));
      } else {
        throw new Error('Nie mogę oodczytać pliku');
      }
    } catch (e) {
      reader.abort();
      if (e instanceof Error){
        notification.error({
          message:errMsgGeneral,
          description: `${errorInfo} (${e.message})`,
          duration: 5000,
        });
      } else {
        notification.error({
          message:errMsgGeneral, description:  errDescGeneral
        });
      }
    }
  };
  reader.readAsText(file, encodingMap.get(bankName));

};

const errorInfo = 'Plik nie posiada danych, lub nie jest właściwie sformatowany/uszkodzony. Sprawdź, czy wybrałaś/wybrałeś właściwy bank. Jeśli błąd będzie się powtarzał możesz go zapisać jako excel zgodnie z wymogami poniżej podanymi i dodać ponownie. Prosimy też o informację o braku możliwości importu - postaramy się rozwiązać problem.';

const fixEncoding = (str: string) => {
  const fixedStr = encodeURI(str)
    .replace(/%C3%AA/g, '%C4%99')
    .replace(/%C2%B3/g, '%C5%82' )
    .replace(/%C2%B9/g, '%C4%85' )
    .replace(/%C2%BF/g, '%C5%BC');
  return decodeURI(fixedStr).normalize();
};

const isWithCvsName = (csvData: string[], csvNames: string[]) =>
  (csvData.some(columnValue => {
    const foundName = csvNames.find(name => {
      return name.toLowerCase().trim() === columnValue.replace(/"/g, ' ').toLowerCase().trim();
    });
    return _.isString(foundName);
  }));

const findIndexWithNames = (csvData: string[], csvNames: string[]) =>
  csvData.findIndex(columnValue =>
    csvNames.some(name =>
      name.toLowerCase().trim() === columnValue.replace(/"/g, ' ').toLowerCase().trim()
    ));

const createDataIndxMap = (csvData: string[][]) => {
  const headerRowIndex = csvData.findIndex((row) =>
    isWithCvsName(row, CSV_PAY_DATE));

  const geColNameIndex = (csvNames: string[]) =>
    findIndexWithNames(csvData[headerRowIndex], csvNames);

  const paymentDataIdxMap = new Map();

  paymentDataIdxMap
    .set('paymentDate', geColNameIndex(CSV_PAY_DATE))
    .set('paymentSum', geColNameIndex(CSV_PAY_SUM))
    .set('transferDescription', geColNameIndex(CSV_PAY_DESC));

  return {
    paymentDataIdxMap,
    headerRowIndex
  };
};

const findRelevantFields = (
  cvsDataWithCol: string[][],
  noHeaderData: boolean
) => {
  if (noHeaderData) {
    return {
      startRow: 0,
      columnIndexes: {
        paymentDate:0,
        paymentSum: 1,
        transferDescription: 3
      }
    };
  }

  const {
    paymentDataIdxMap,
    headerRowIndex
  } = createDataIndxMap(cvsDataWithCol);

  return {
    startRow: headerRowIndex + 1,
    columnIndexes: {
      paymentDate:paymentDataIdxMap.get('paymentDate'),
      paymentSum: paymentDataIdxMap.get('paymentSum'),
      transferDescription: paymentDataIdxMap.get('transferDescription')
    }
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const createColumns = (csvData: any) =>
  csvData.map((row:string) =>
    _.isString(row[0]) ?
      row[0].split(';') : []);


const createPaymentItem = (row: string[], columnIndexes: {
  paymentDate: number,
  paymentSum: number,
  transferDescription: number,
}, bankName: BankNames) =>
  row.reduce((prv: PaymentItem, col, index) => {
    if (index === columnIndexes.paymentDate) {
      const paymentDate = bankName === BANK_ALIOR ?
        moment(col, 'DD-MM-YYYY').endOf('day') : moment(col).endOf('day');
      return {
        ...prv,
        paymentDate,
      };
    }
    if (index === columnIndexes.paymentSum) {
      return {
        ...prv,
        paymentSum: Math.abs(getPartialSum(col)),
      };
    }
    if (index === columnIndexes.transferDescription) return {
      ...prv,
      transferDescription: _.isString(col) ? col.replace(/ +(?= )/g, '').replace(/"/g, ' ') : null,
    };

    return prv;
  }, {
    key:uuidv4(),
    paymentDate: moment(),
    paymentSum: 0,
    transferDescription: null,
    assignedTo: [],
    statementDate: null,
  });

