import { Divider, Typography } from 'antd';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { TitleAndDesc, ComponentChildren } from '../../../@types';


const { Title, Text } = Typography;

type Props = TitleAndDesc & ComponentChildren;

const EditCard = (
  {
    title,
    desc,
    children
  }: Props
) =>
  (
    <AnimatePresence>
      <motion.div
        transition={{
          ease: 'easeInOut',
          duration: 1,
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Title
          level={2}
          style={{ marginTop: 48 }}
        >
          {title}
        </Title>
        <Text>{desc}</Text>
        <Divider />
        {children}
      </motion.div>
    </AnimatePresence>
  );


export default EditCard;