import { ArrowDownOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Card, List, Typography } from 'antd';
import React from 'react';
import nextId from 'react-id-generator';
import { shallowEqual } from 'react-redux';
import styled from 'styled-components';

import { AllocationMechanism,
  AssignHistoryItem,
  AUTO_ASSIGN_DATE,
  AUTO_ASSIGN_DEFAULT,
  AUTO_ASSIGN_EXACT,
  AUTO_ASSIGN_SIMILAR,
  AUTO_ASSIGN_SUM } from '../../../../services/autoAssign/autoAssignCommon';
import { useTypedSelector } from '../../../../store';
import { BLUE } from '../../../../styles';
import { formatDate } from '../../../../utils/helpers/date';
import { formatPLN } from '../../../../utils/helpers/formatNumber';
import HistoryDescription from './HistoryDescription';


const mechanismTitleMap = new Map<AllocationMechanism, string>([
  [AUTO_ASSIGN_EXACT, 'Numer dokumentu księgowego i wpłaty są tożsame.'],
  [AUTO_ASSIGN_SIMILAR, 'Nazwa dokumentu księgowego jest bardzo zbliżona do nazwy tytułu wpłaty'],
  [AUTO_ASSIGN_DATE, 'Wpłata zawierała adnotację o dacie, która odpowiada dacie powstania roszczeania.'],
  [AUTO_ASSIGN_SUM, 'Wpłata pomimo braku odczytywalnego tytułu ma wysokość odpowidającą kwocie na którą została zaliczona.'],
  [AUTO_ASSIGN_DEFAULT, 'Nie udało się przyporządkować wpłaty korzystając z tytułu wpłaty. Została zaliczona na najstarsze niezapokojone roszczenia.']
]);


const HistoryCard = ({ historyItem, index }: { historyItem: AssignHistoryItem, index: number }) => {

  const { paymentItemKey, historyRecords } = historyItem;

  const { paymentItem, historyExt } = useTypedSelector(state =>  {
    return {
      paymentItem: state.interestStorage.paymentData.find(value =>
        value.key === paymentItemKey),
      historyExt: historyRecords.map(value => {
        return {
          allocationMechanism: value.allocationMechanism,
          interestItem:     state.interestStorage.interestData.find(v =>
            value.interestItemKey === v.key),
          note: value.note,
        };
      })
    };
  }, shallowEqual);

  if (!paymentItem) return null;

  return (
    <Card
      title={(
        <>
          {index + 1}
          {' . '}
          Wpłata
          {' '}
          {formatPLN(paymentItem.paymentSum)}
          {' '}
          z dnia
          {' '}
          {formatDate(paymentItem.paymentDate)}
          {' '}
          roku
        </>
  )}
      bordered={false}
    >
      {historyExt.map(value =>
        (
          <CardStyled
            key={nextId()}
            title={(
              <>
                <ArrowDownOutlined />
                {' '}
                {value.note === 'full' ? 'Została podjęta próba zaliczenia' : 'Została zaliczona'}
                na roszczenie w wysokości
                {' '}
                {formatPLN(value.interestItem?.partialSum)}
                {' z dnia '}
                {value.interestItem?.dueDate ? formatDate(value.interestItem?.dueDate) : ''}
                {' roku'}
              </>
  )}
            type="inner"
          >
            <List.Item
              key={nextId()}
            >
              <List.Item.Meta
                key={nextId()}
                avatar={(
                  <QuestionCircleOutlined />

  )}
                title={mechanismTitleMap.get(value.allocationMechanism)}
                description={(
                  <>
                    <HistoryDescription
                      interestItem={value.interestItem}
                      paymentItem={paymentItem}
                      allocationMechanism={value.allocationMechanism}
                    />
                    {value.note === 'full' || value.note === 'partial' ? (
                      <Typography.Paragraph style={{
                        paddingTop: 12,
                        color: BLUE
                      }}
                      >
                        {value.note === 'full' ? 'Wcześniejsze zaliczenia spowodowały, że nie było możliwe przypisanie na tą kwotę.' :
                          'Kwota wpłaty była wyższa niż możliwa do przypisania kwota roszczenia, podjęta została próba zaliczenia pozostałej części wpłaty na inną kwotę.'}
                      </Typography.Paragraph>
                    ) : null}
                  </>
  )}
              />
            </List.Item>
          </CardStyled>
        )
      )}
    </Card>
  );
};

export default HistoryCard;

const CardStyled = styled(Card)`
   margin-bottom: 24px;
`;
