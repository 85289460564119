import { notification } from 'antd';
import { User } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import { Timestamp, updateDoc, writeBatch } from 'firebase/firestore';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { db } from '../_firebase';
import { AppDispatch, RootState } from '../store';
import { InterestItem, PaymentItem } from '../store/interestStorage';
import { LawsuitOpts } from '../store/lawsuitOpts';
import { lsSetSave } from '../store/lawsuitUI';
import { formatInterestDataToSave, formatPartialResultsToSave } from '../utils/helpers/formatInterestData';
import { withUser } from '../utils/helpers/withAppLoading';
import { auth } from './firebase/auth';
import { calculationRef, counterForCalculationsRef } from './firebase/firestoreRefs';
import { notifyFirebaseError } from './firebase/notifyFirebaseError';
import { formatPaymentDataToSave } from '../utils/helpers/formatPaymentData';


export type InterestItemSaved = Omit<InterestItem, 'startingDate' | 'endDate' | 'maturityDate' | 'dueDate'> & {
  startingDate: string,
  endDate: string,
  maturityDate: string | null,
  dueDate: string,
};

export type PaymentItemSaved = Omit<PaymentItem, 'paymentDate' | 'statementDate'> & {
  paymentDate: string,
  statementDate: string | null,
};

export type InterestDataSaved = InterestItemSaved[];

export type PaymentDataSaved = PaymentItemSaved[];

export interface LawsuitSave{
  lawsuitOpts: LawsuitOpts,
  interestData: InterestDataSaved,
  paymentData?: PaymentDataSaved,
  createDate: Timestamp,
  editDate: Timestamp,
  recordName: string,
  type: 'lawsuit',
  ver: string | undefined,
}

const prepareSaveData = ({
  getState,
  isSaveFromResult
}:{
  getState: () => RootState,
  currentUser: User | null,
  isSaveFromResult: boolean
}) => {
  const state = getState();

  const { isNonAnonymousUserLoggedIn } = state.user;

  const lawsuitOpts = isSaveFromResult ? state.sumUp.lawsuitOpts : state.lawsuitOpts;

  const interestData = isSaveFromResult ?
    formatPartialResultsToSave(state.sumUp.partialResults) :
    formatInterestDataToSave(state.interestStorage.interestData);

  const paymentData = formatPaymentDataToSave(isSaveFromResult ?
    state.sumUp.paymentData :
    state.interestStorage.paymentData);

  return ({
    lawsuitOpts,
    interestData,
    paymentData,
    isNonAnonymousUserLoggedIn,
  });
};

/* -------------------------------------------------------------------------- */
/*                                   UPDATE                                   */
/* -------------------------------------------------------------------------- */


export const updateLawsuitSave = ({
  recordName,
  onLoadingStart,
  onFinish,
  saveId,
  isSaveFromResult,
}:{
  saveId?: string | null,
  recordName: string,
  onLoadingStart: () => void;
  onFinish: () => void,
  isSaveFromResult:boolean,
}) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const { currentUser } = auth;

    const {
      lawsuitOpts,
      interestData,
      paymentData,
      isNonAnonymousUserLoggedIn
    } = prepareSaveData({
      getState,
      currentUser,
      isSaveFromResult
    });

    if (isNonAnonymousUserLoggedIn && saveId && currentUser){
      try {
        onLoadingStart();
        await updateDoc(calculationRef(currentUser.uid, saveId), {
          lawsuitOpts,
          interestData,
          paymentData,
          editDate: firebase.firestore.Timestamp.now(),
          recordName: recordName || `Wyliczenia ${moment(Date.now()).format('YYYY-MM-DD hhhh:mm')}`,
          type: 'lawsuit',
          ver: process.env.REACT_APP_VERSION
        });
        onFinish();
        notification.info({ message: `Wyliczenia: "${recordName || 'Nowe wyliczenia'}" zapisane.` });
        if (!isSaveFromResult){
          dispatch(lsSetSave({
            saveId,
            recordName: recordName || 'Nowe wyliczenia',
          }));
        }
      } catch (error) {
        onFinish();
        notifyFirebaseError(error);
      }
    } else {
      onFinish();
      notification.info({ message: 'Zaloguj się żeby zapisać wyliczenia' });
    }
  };


/* -------------------------------------------------------------------------- */
/*                             UPDATE RECORD NAME                             */
/* -------------------------------------------------------------------------- */

const isUpdateAvailable = ({
  recordNameLocal,
  recordName,
  saveId,
}:{
  recordNameLocal: string,
  recordName: string,
  saveId: string,
}) =>
  recordNameLocal !== recordName
    && recordNameLocal
    && recordNameLocal !== ''
    && saveId;


export const updateSaveRecordName = ({
  recordNameLocal,
  recordName,
  saveId,
  onFinish,
}:{
  recordNameLocal: string,
  recordName: string,
  saveId:string,
  onFinish: () => void }) =>
  withUser(auth.currentUser, async (user) => {
    if (isUpdateAvailable({
      recordNameLocal,
      recordName,
      saveId,
    })) {
      try {
        await updateDoc(calculationRef(user.uid, saveId), {
          editDate: firebase.firestore.Timestamp.now(),
          recordName: recordNameLocal,
        });
        notification.info({ message: `Nazwa została zmieniona z  "${recordName}" na "${recordNameLocal}"` });
        onFinish();
      } catch (error) {
        onFinish();
        notifyFirebaseError(error);
      }
    } else {
      onFinish();
    }
  });

/* -------------------------------------------------------------------------- */
/*                                     SET                                    */
/* -------------------------------------------------------------------------- */


export const setLawsuitSave = ({
  recordName,
  onLoadingStart,
  onFinish,
  isSaveFromResult,
}:{
  recordName: string,
  onLoadingStart: () => void;
  onFinish: () => void;
  isSaveFromResult: boolean;
}) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {

    const increment = firebase.firestore.FieldValue.increment(1);

    const { currentUser } = auth;

    const {
      lawsuitOpts,
      interestData,
      paymentData,
      isNonAnonymousUserLoggedIn
    } = prepareSaveData({
      getState,
      currentUser,
      isSaveFromResult
    });

    const batch = writeBatch(db);

    if (currentUser && isNonAnonymousUserLoggedIn){
      try {
        onLoadingStart();

        const saveId = uuidv4();

        batch.set(calculationRef(currentUser.uid, saveId), {
          lawsuitOpts,
          interestData,
          paymentData,
          createDate: firebase.firestore.Timestamp.now(),
          editDate: firebase.firestore.Timestamp.now(),
          recordName: recordName || `Wyliczenia ${moment(Date.now()).format('YYYY-MM-DD hhhh:mm')}`,
          type: 'lawsuit',
          ver: process.env.REACT_APP_VERSION
        });

        batch.set(counterForCalculationsRef(currentUser.uid), { count: increment }, { merge: true });

        await batch.commit();

        notification.info({ message: `Wyliczenia: "${recordName || 'Nowe wyliczenia'}" zapisane.` });

        dispatch(lsSetSave({
          saveId,
          recordName: recordName || 'Nowe wyliczenia',
        }));

      } catch (error){
        notifyFirebaseError(error);
      } finally {
        onFinish();
      }
    } else {
      onFinish();
      notification.info({ message: 'Zaloguj się żeby zapisać wyliczenia' });
    }
  };


/* -------------------------------------------------------------------------- */
/*                                   DELETE                                   */
/* -------------------------------------------------------------------------- */


export const saveDelete = async (saveId:string) => {
  const { currentUser } = auth;

  const increment = firebase.firestore.FieldValue.increment(-1);

  const batch = writeBatch(db);

  if (currentUser){
    try {
      batch.delete(calculationRef(currentUser.uid, saveId));
      batch.set(counterForCalculationsRef(currentUser.uid), { count: increment }, { merge: true });

      await batch.commit();

      notification.info({ message: 'Wyliczenia usunięte' });

    } catch (error){
      notifyFirebaseError(error);
    }
  } else {
    notification.info({ message: 'Zaloguj się żeby usunąć' });
  }
};