import { Typography } from 'antd';
import _ from 'lodash';
import React from 'react';
import ReactCodeInput from 'react-code-input';
import useWindowSize from '../../utils/hooks/useWindowSize';
import { BLACK, BLUE, RowExt } from '../../styles';

const LsCodeInput = (
  { setCode }: {
    setCode: (code:string) => void
  }
) => {

  const { width } = useWindowSize();

  return (
    <>
      <RowExt marginTop={48} justify="center">
        <ReactCodeInput
          type='text'
          fields={12}
          name="dafasf"
          inputMode="latin"
          inputStyle={{
            fontFamily: 'Fira mono',
            borderRadius: 3,
            border: `1px solid${  BLUE}`,
            margin: width >= 376 ? 3 : 2,
            paddingLeft: 6,
            paddingTop: 2,
            width: 24,
            height: 28,
            fontSize: 14,
            color: BLACK,
            backgroundColor: 'white',
            outline: 'none'
          }}
          onChange={(value) =>
            setCode(value)}
        />
      </RowExt>
      <RowExt marginTop={6} justify="center">
        {_.times(12, (i) =>
          (
            <Typography.Text
              key={i}
              style={{
                margin: width >= 376 ? 3 : 2,
                paddingLeft: 6,
                paddingTop: 2,
                width: 24,
                height: 28,
              }}
            >
              {i + 1}
            </Typography.Text>
          ))}
      </RowExt>
    </>
  );
};

export default LsCodeInput;
