import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { ScreenLayout } from '../../../styles';

const PrivateContainer = ({ children }: { children: ReactNode }) => {
  return (
    <ScreenLayout>
      <MDContainer>
        {children}
      </MDContainer>
    </ScreenLayout>
  );
};

export default PrivateContainer;

export const MDContainer = styled.div`
  width: 768px;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  height: 100vh;
  @media only screen and (max-width: 768px) {
  width: 100%;
  } 
`;