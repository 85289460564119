import { Typography } from 'antd';
import React from 'react';
import { par1, par2, par3 } from '../../utils/law/cookies';
import LawContainer from '../UI/Containers/LawContainer';
import LawPars from './LawPars';
import LawUsts from './LawUsts';
import PrivacyPar from './PrivacyPar';

const CookieLaw = () => {
  return (
    <>
      <LawContainer>
        <Typography.Title level={2} id="cookies">Polityka cookies</Typography.Title>
      </LawContainer>
      <PrivacyPar
        title={par1.title}
        desc={par1.desc}
      >
        <LawPars texts={par1.pars} />
        <LawUsts texts={par1.usts} />
      </PrivacyPar>
      <PrivacyPar
        title={par2.title}
        desc={par2.desc}
      >
        <LawPars texts={par2.pars} />
      </PrivacyPar>
      <PrivacyPar
        title={par3.title}
        desc={par3.desc}
      >
        <LawPars texts={par3.pars} />
      </PrivacyPar>
    </>
  );
};

export default CookieLaw;
