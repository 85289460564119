/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Moment } from 'moment';


export type CurrencyType = 'eur' | 'usd' | 'gbp' | 'chf';

export type CurrencyResult = {
  convertingSum: number;
  currencyType: CurrencyType,
  convertDate: Moment | null,
  convertedSum: number,
  exchangeRate: number,
  isWeekendOrHoliday: boolean,
  exchangeDate: Moment | null,
};

interface CurrencyState {
  isCurrencyVisible: boolean;
  convertingSum: number;
  currencyType: CurrencyType,
  convertDate: null | Moment,
  isResultVisible: boolean,
  result: CurrencyResult,
  error: boolean,
  errorMessage: string,
}

const initialState:CurrencyState = {
  isCurrencyVisible: false,
  convertingSum: 0,
  currencyType: 'eur',
  convertDate: null,
  isResultVisible: false,
  result:{
    convertingSum: 0,
    currencyType: 'eur',
    convertDate: null,
    convertedSum: 0,
    exchangeRate: 0,
    isWeekendOrHoliday: false,
    exchangeDate: null,
  },
  error: false,
  errorMessage: '',
};

export const currencySlice = createSlice({
  name: 'currency',
  initialState,
  reducers: {
    currencyIsVisibleChanged: (state:CurrencyState, action: PayloadAction<boolean>) => {
      state.isCurrencyVisible = action.payload;
    },
    currencySumChanged: (state:CurrencyState, action: PayloadAction<number>) => {
      state.convertingSum = action.payload;
    },
    currencyTypeChanged:(state:CurrencyState, action: PayloadAction<CurrencyType>) => {
      state.currencyType = action.payload;
    },
    currencyDateChanged:(state:CurrencyState, action: PayloadAction<null | Moment>) => {
      state.convertDate = action.payload;
    },
    currencyResultChanged:(state:CurrencyState, action:PayloadAction<CurrencyResult>) => {
      state.result = action.payload;
      state.isResultVisible = true;
      state.error = false;
      state.errorMessage = '';
    },
    currencyErrorOccurred: (state:CurrencyState, action:PayloadAction<{
      error: boolean,
      errorMessage: string,
    }>) => {
      state.result = { ...initialState.result };
      state.isResultVisible = false;
      state.error = action.payload.error;
      state.errorMessage = action.payload.errorMessage;
    },
    currencyReset:() =>
      initialState
  },
});


export const {
  currencyIsVisibleChanged,
  currencySumChanged,
  currencyTypeChanged,
  currencyDateChanged,
  currencyResultChanged,
  currencyErrorOccurred,
  currencyReset
} = currencySlice.actions;

export default currencySlice.reducer;
