import { Col } from 'antd';
import React from 'react';
import { useHistory } from 'react-router';
import LawBorders from '../components/Law/LawBorders';
import LawPars from '../components/Law/LawPars';
import LawUsts from '../components/Law/LawUsts';
import PrivacyPar from '../components/Law/PrivacyPar';
import BackButton from '../components/Lawsuit/StepCard/BackButton';
import AnimOpacity from '../components/UI/Anim/AnimOpacity';
import LawContainer from '../components/UI/Containers/LawContainer';
import DotsEnding from '../components/UI/DotsEnding';
import { act1, act2 } from '../utils/law/compBasis';
import { RowBg } from '../styles';
import { DashedDivider } from './About';

const CompensationBasis = () => {
  const history = useHistory();
  return (
    <AnimOpacity>
      <LawContainer title="Rekompensaty - podstawa prawna" />
      <DashedDivider />
      <LawBorders />
      <PrivacyPar
        title={act1.title}
        desc={act1.desc}
      >
        <LawPars texts={act1.pars} />
      </PrivacyPar>
      <PrivacyPar
        title={act2.title}
        desc={act2.desc}
      >
        <LawPars texts={act2.pars1} />
        <LawUsts texts={act2.usts} />
        <LawPars texts={act2.pars2} />
      </PrivacyPar>
      <DashedDivider />
      <LawContainer>
        <RowBg>
          <Col
            lg={0}
            md={{
              offset: 1,
              span: 1,
            }}
          />
          <Col>
            <BackButton
              onBack={() =>
                history.goBack()}
              showHome={false}
            />
          </Col>
        </RowBg>
      </LawContainer>
      <LawContainer>
        <DotsEnding />
      </LawContainer>
    </AnimOpacity>
  );
};

export default CompensationBasis;
