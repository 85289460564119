import _ from 'lodash';
import { InterestDataSaved, InterestItemSaved } from '../../services/lawsuitSave';
import { InterestItem } from '../../store/interestStorage';
import { convertToMoment } from './date';

export const formatInterestDataFromSave = (interestDataSaved: InterestDataSaved) => {
  const interestData: InterestItem[] = interestDataSaved.map((
    savedInterestItem:InterestItemSaved
  ) => {
    const {
      startingDate,
      endDate,
      maturityDate,
      accountingDoc,
      dueDate,
      isClaimFromFulfilled
    } = savedInterestItem;
    return {
      ...savedInterestItem,
      startingDate: convertToMoment(startingDate, true),
      endDate: convertToMoment(endDate),
      dueDate: _.isUndefined(dueDate) ? null : convertToMoment(dueDate),
      maturityDate: maturityDate ? convertToMoment(maturityDate) : null,
      accountingDoc: _.isUndefined(accountingDoc) ? null : accountingDoc,
      isClaimFromFulfilled: _.isUndefined(isClaimFromFulfilled) ? false : isClaimFromFulfilled,
    };
  });
  return interestData;
};
