/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Modal, Space } from 'antd';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { useAppDispatch } from '../../../store';
import { subTypeUpdated } from '../../../store/subscribe';
import { RowExt } from '../../../styles';
import { BLUE } from '../../../styles/colors';
import Icon from '../../../styles/Icon';
import { AT, DIAMOND, LINKED_IN, LINKED_IN_SOLID } from '../../../styles/iconTypes';
import { subscriptionStyle } from '../../../styles/subscriptionStyles';
import { LINK_LI } from '../../../utils/helpers/links';
import * as subCodes from '../../../utils/helpers/subCodes';
import IconButton from '../../UI/Button/IconButton';
import SubOptCard from './SubOptCard';
import SubOptListItem from './SubOptListItem';
import SubPremiumHead from './SubPremiumHead';

const SubPremium = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const oneDayTestProd = () => {
    dispatch(subTypeUpdated(subCodes.SUB_2_5_TRIAL_1_STANDARD_DAILY_LIVE));
    history.push('/payment');
  };

  return (
    <>
      <SubPremiumHead
        inCard
      />
      <SubOptCard
        color={subscriptionStyle('premium').color}
        title="PREMIUM"
        iconType={subscriptionStyle('premium').icon}
        isPremium
        bestFor="instytucji"
        bestForDesc="lub dużej kancelarii"
        onClick={() =>
          setIsModalVisible(true)}
        // onClick={oneDayTestProd}
      >
        <SubOptListItem
          text={(
            <>
              <strong> Dowolna ilość kont </strong>
              <span style={{ paddingBottom: 12 }}>
                z dodatkowymi funkcjami
              </span>
            </>
          )}
        />
        <SubOptListItem
          text={(
            <>
              {' '}
              Zdalne
              <strong> szkolenia pracowników</strong>
              {' '}
              z działania aplikacji, w ustalonych okresach.
            </>
          )}
        />
      </SubOptCard>
      <Modal
        onCancel={() =>
          setIsModalVisible(false)}
        visible={isModalVisible}
        footer={false}
        destroyOnClose
        title={(
          <IconStyled
            iconType={DIAMOND}
            height="24"
            width="24"
            fill={BLUE}
          />
)}
        style={{ background: 'none !important' }}
      >
        <>
          <Space>
            <IconButton
              href="mailto:contact@pozew.app"
              iconType={AT}
              iconHoover={AT}
              stroke
              style={{ marginTop:3 }}
            />
            <IconButton
              href={LINK_LI}
              iconType={LINKED_IN}
              iconHoover={LINKED_IN_SOLID}
              stroke
              style={{ marginLeft:24 }}
            />
            <PhoneNumber>+48 795 430 450</PhoneNumber>
          </Space>
          <Desc>
            Zapraszamy do kontaku.
          </Desc>
          <Desc>
            Z chęcią odpowiemy na wszelkie pytania i skonstruujemy ofertę uwzględniając Państwa indywidualne potrzeby.
          </Desc>
        </>
      </Modal>
    </>
  );
};

export default SubPremium;

const PhoneNumber = styled.span`
  margin-left: 24px;
  font-weight: 700;
`;

const IconStyled = styled(Icon)`
  padding-top: 4px;
`;

const Desc = styled(RowExt)`
  margin-top: 12px;
`;
