/* eslint-disable max-len */
import { Typography } from 'antd';
import { getDocs } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { Img } from 'react-image';
import styled from 'styled-components';
import stripe from '../../assets/Stripe.svg';
import { ENV_TYPE } from '../../env.json';
import { auth } from '../../services/firebase/auth';
import { subscriptionsRef } from '../../services/firebase/firestoreRefs';
import { handleSubscribe } from '../../services/payment';
import { useAppDispatch, useTypedSelector } from '../../store';
import { ButtonFullWidth, RowExt } from '../../styles';
import Icon from '../../styles/Icon';
import { subscriptionStyle } from '../../styles/subscriptionStyles';
import * as subCodes from '../../utils/helpers/subCodes';
import { withAppLoadingAndUser } from '../../utils/helpers/withAppLoading';
import { StripeImg } from '../Subscribe/SubscribePremiumText';

const PaymentFinish = () => {
  const dispatch = useAppDispatch();

  const [wasSubscriber, setWasSubscriber] = useState(false);

  const subType = useTypedSelector(state =>
    state.subscribe.subType);

  const isStandard = ENV_TYPE === 'PRODUCTION' ? subCodes.SUB_1199_LIVE === subType : subCodes.SUB_1199_TEST === subType;

  useEffect(() => {
    const checkIfWasSubscriber = () =>
      withAppLoadingAndUser({
        dispatch,
        user: auth.currentUser,
      }, async (user) => {
        try {
          const subscriptions = await getDocs(subscriptionsRef(user.uid));
          if (!subscriptions.empty){
            setWasSubscriber(true);
          }
        } catch (error){
          // eslint-disable-next-line no-console
          console.log(error);
        }
      });
    checkIfWasSubscriber();
  }, [dispatch]);

  if (wasSubscriber){
    return (
      <>
        <RowExt justify="center">
          <IconStyled
            iconType={subscriptionStyle(isStandard ? 'standard' :  'basic').icon}
            height="32"
            width="32"
            fill={subscriptionStyle(isStandard ? 'standard' :  'basic').color}
          />
        </RowExt>
        <RowExt justify="center">
          <TitleStyled
            level={3}
          >
            {subscriptionStyle(isStandard ? 'standard' :  'basic').name}
          </TitleStyled>
        </RowExt>
        <RowExt justify="center">
          <Typography.Title
            level={4}
            style={{ textAlign: 'center' }}
          >
            Korzystałeś/aś już z
            <br />
            subskrypcji lub okresu próbnego
          </Typography.Title>
          <Typography.Title level={4} />
        </RowExt>
        <RowExt marginBottom="sm">
          <Typography.Text>
            Znaleźliśmy informację, że korzystałeś/aś już z subskrypcji lub z okresu próbnego. Z okresu próbnego można skorzystać
            {' '}
            <strong> tylko raz, </strong>
            {' '}
            aby zapoznać się z dodatkowymi funkcjami aplikacji. Prosimy o zrozumienie, że inny system prowadziłby do nadużyć.
          </Typography.Text>
          <RowExt marginTop={24} marginBottom={24} justify="space-between" align="middle">
            <ButtonFullWidth
              type="primary"
              onClick={() =>
                handleSubscribe(dispatch, subType, wasSubscriber)}
            >
              Zacznij subskrypcję
            </ButtonFullWidth>
          </RowExt>
          <RowExt marginBottom={12} justify="center" align="middle">
            <a type="link" href="https://stripe.com" target="_blank" rel="noreferrer">
              <StripeImg
                src={stripe}
              />
            </a>
          </RowExt>
        </RowExt>
      </>
    );
  }


  return (
    <>
      <RowExt justify="center">
        <IconStyled
          iconType={subscriptionStyle(isStandard ? 'standard' :  'basic').icon}
          height="32"
          width="32"
          fill={subscriptionStyle(isStandard ? 'standard' :  'basic').color}
        />
      </RowExt>
      <RowExt justify="center">
        <TitleStyled
          level={3}
        >
          {subscriptionStyle(isStandard ? 'standard' :  'basic').name}
        </TitleStyled>
      </RowExt>
      <RowExt justify="center">
        <TitleStyled
          level={3}
        >
          <Typography.Title level={4}>14-dniowy okres próbny</Typography.Title>
        </TitleStyled>
      </RowExt>
      <RowExt marginBottom="sm">
        <Typography.Text>
          W następnym kroku uzupełnisz dane płatności i zaczniesz okres próbny.
          {' '}
          <strong style={{ textDecoration:'underline' }}>
            Jeśli przed upływem okresu próbnego anulujesz subskrypcję, opłata nie zostanie pobrana.
          </strong>
          {' '}
          Po upływie okresu próbnego zostaniesz obciążona/y kosztami subskrypcji za cały okres z góry a na Twój mail zostanie przesłana faktura VAT zgodna z podanymi danymi.
          Subskrypcja odnawia się automatycznie co roku. W każdej chwili możesz z niej zrezygnować, wtedy dodatkowe funkcje pozostaną dostępne do końca opłaconego okresu.
        </Typography.Text>
        <RowExt marginTop={24} marginBottom={24} justify="space-between" align="middle">
          <ButtonFullWidth
            type="primary"
            onClick={() =>
              handleSubscribe(dispatch, subType, wasSubscriber)}
          >
            Zacznij okres próbny | uzupełnij dane płatności
          </ButtonFullWidth>
        </RowExt>
        <RowExt marginBottom={12} justify="center" align="middle">
          <a type="link" href="https://stripe.com" target="_blank" rel="noreferrer">
            <StripeImg
              src={stripe}
            />
          </a>
        </RowExt>
      </RowExt>
    </>
  );
};

export default PaymentFinish;

export const Vat = styled(Img)<{ grey?: boolean }>`
    width: 100%;
    border-radius: 4px;
`;

const IconStyled = styled(Icon)`
  margin: 0px 0 24px 0;
`;

const TitleStyled = styled(Typography.Title)`
   letter-spacing: 1.2;
`;