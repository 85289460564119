import { Col, Steps } from 'antd';
import React from 'react';

import { useTypedSelector } from '../../../store';
import { GRID_MD, RowExt } from '../../../styles';
import useWindowSize from '../../../utils/hooks/useWindowSize';

const { Step } = Steps;

const ImpExternalSteps = () => {
  const step = useTypedSelector(state =>
    state.impExternal.step);

  const { width } = useWindowSize();

  if (width < GRID_MD) return null;


  return (
    <RowExt
      justify="center"
      fullWidth
      marginTop="bg"
    >
      <Col
        lg={{
          offset:0,
          span:24,
        }}
        xs={{ span: 20 }}
      >
        <Steps
          current={step}
          size='small'
          direction="horizontal"
        >
          <Step />
          <Step />
          <Step />
        </Steps>
      </Col>
    </RowExt>
  );
};

export default ImpExternalSteps;