import { Avatar, Card, Col, Row, Typography } from 'antd';
import React from 'react';
import { Img } from 'react-image';
import styled from 'styled-components';
import author from '../../../assets/author.jpg';
import { LINK_FB, LINK_LI } from '../../../utils/helpers/links';
import useWindowSize from '../../../utils/hooks/useWindowSize';
import { AboutContainer, DashedColLeft, GREY_WHITE, GRID_MD, GRID_MD_PX, GRID_SM_PX, RowExt, RowMd, RowSm } from '../../../styles';
import { AT, FACEBOOK, FACEBOOK_SOLID, LINKED_IN, LINKED_IN_SOLID } from '../../../styles/iconTypes';
import IconButton from '../../UI/Button/IconButton';
import AbtTitle from '../AbtTitle';

const Contact = () => {
  return (
    <RowMd>
      <Col
        sm={24}
        xs={{
          offset: 1,
          span: 21,
        }}
      >
        <IconButton
          href={LINK_LI}
          iconType={LINKED_IN}
          iconHoover={LINKED_IN_SOLID}
          stroke
          style={{
            marginTop:3, marginLeft: 6
          }}
        />
        <IconButton
          href="mailto:contact@pozew.app"
          iconType={AT}
          iconHoover={AT}
          stroke
          style={{ marginLeft:24 }}
        />
        <IconButton
          href={LINK_FB}
          iconType={FACEBOOK}
          iconHoover={FACEBOOK_SOLID}
          stroke
          style={{ marginLeft:24 }}
        />
      </Col>
    </RowMd>
  );
};

const AuthorAvatar = () => {
  const { width } = useWindowSize();
  return (
    <>
      {width >= GRID_MD ? (
        <RowMd>
          <Card
            style={{
              width: '100%', borderStyle: 'dashed'
            }}
          >
            <Card.Meta
              avatar={(
                <Avatar
                  src={author}
                  style={{
                    height: 112, width: 112
                  }}
                />
                            )}
              title="r. pr. Tadeusz Mięsowicz"
              description="pomysł i wykonanie"
            />
          </Card>
        </RowMd>
      )
        : (
          <>
            <RowExt justify="center">
              <Img
                src={author}
                style={{
                  height: 140,
                  width: 140,
                  borderRadius: '50%'
                }}
              />
            </RowExt>
            <RowMd justify="center">
              <Col>
                <Typography.Title level={5}>
                  r. pr. Tadeusz Mięsowicz
                </Typography.Title>
              </Col>
            </RowMd>
            <RowSm justify="center">
              <Col>
                <Typography.Text>
                  pomysł i wykonanie
                </Typography.Text>
              </Col>
            </RowSm>
          </>
        )}
    </>
  );
};


const Author = () => {

  return (
    <AboutContainer>
      <RowExt id="author">
        <Col
          lg={{
            span: 7, offset:1
          }}
          md={{ span: 8 }}
          xs={{
            offset: 2,
            span: 20,
          }}
          style={{ background: GREY_WHITE }}
        >
          <Row justify="center">
            <TextWrapper>
              <TextMono>
                Stworzona przez
                <br />
                radcę prawnego
                <br />
                z myślą
                <br />
                o innych
                <br />
                pełnomocnikach
              </TextMono>
            </TextWrapper>
          </Row>
        </Col>
        <TextCol
          xl={{
            span: 14, offset: 0
          }}
          lg={{
            span: 13, offset: 0
          }}
          md={{
            span: 14, offset:0
          }}
          xs={{
            offset: 1,
            span: 21,
          }}
        >
          <DashedDivider />
          <Row>
            <Col offset={2} span={20}>
              <AbtTitle
                title="Autor"
                desc={(
                  <>
                    <AuthorAvatar />
                    <Contact />
                  </>
               )}
              />
            </Col>
          </Row>
        </TextCol>
        <DashedColLeft
          xl={{ span: 2 }}
          lg={{ span: 3 }}
          xs={0}
        />
      </RowExt>
    </AboutContainer>
  );
};

export default Author;

const TextWrapper = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   height: 384px;
   @media only screen and (max-width: ${GRID_MD_PX}) {
    height: 234px;
	}
`;

const DashedDivider = styled.div<{ color?: string }>`
    width: 100%;
    border-bottom: 1px dashed ${props =>
    props.color || 'rgba(66,71,112,0.09)'};
`;


const TextCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media only screen and (max-width: ${GRID_MD_PX}) {
    font-size: 12px;
	}
  @media only screen and (max-width: 767px) {
    margin-top: 48px;
	}

`;

const TextMono = styled.div`
   font-family: 'Fira Mono';
   font-size: 18px;
   text-align: center;
   font-weight: 700;
   letter-spacing: 2px;
   @media only screen and (max-width: ${GRID_MD_PX}) {
    font-size: 12px;
	}
  @media only screen and (max-width: ${GRID_SM_PX}) {
    font-size: 16px;
	}
`;