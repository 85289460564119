import React, { useEffect } from 'react';
import useGetSumUpData from '../../../utils/hooks/useGetSumUpData';
import { logToAnalytics } from '../../../services/firebase/logToAnalytics';
import SumUpData from '../../SumUp/SumUpData';
import SumUpActions from './SumUpActions';

const SumUpLs = () => {

  useGetSumUpData();

  useEffect(() => {
    logToAnalytics('SumUpLs_loaded', { name: 'SumUpLs_loaded' });
  }, []);

  return (
    <>
      <SumUpData
        title={texts.title}
        desc={texts.desc}
      >
        <SumUpActions />
      </SumUpData>
    </>
  );
};

export default SumUpLs;

const texts = {
  title: 'Wynik',
  desc: 'Gotowe! Skopiuj potrzebne dane, pobierz pliki na potrzeby archiwalne. \nJeśli nie zapiszesz danych na swoim koncie zostaną one utracone.',
};