import React, { memo } from 'react';
import { shallowEqual } from 'react-redux';

import { useTypedSelector } from '../../../store';
import Basis from '../Basis/Basis';
import CurrencyForm from '../Currency/CurrencyForm';
import DateOptions from '../DateOptions/DateOptions';
import Edit from '../Edit/Edit';
import StepCard from '../StepCard/StepCard';
import InterestFormAddButton from './InterestForm/InterestFormAddButton';
import InterestFormAlert from './InterestForm/InterestFormAlert';
import InterestFormDueBasis from './InterestForm/InterestFormDueBasis';
import InterestFormOptions from './InterestForm/InterestFormOptions';
import InterestFormPartialSum from './InterestForm/InterestFormPartialSum';
import InterestFormTable from './InterestForm/InterestFormTable';

const PartialData = () => {
  const  editKey = useTypedSelector(state =>
    state.edit.key);

  const isCurrencyVisible = useTypedSelector(state =>
    state.currency.isCurrencyVisible);

  const { isBasisVisible, areDateOptionsVisible } = useTypedSelector(state =>
    state.interestFormOptions, shallowEqual);

  if (isCurrencyVisible){
    return (
      <CurrencyForm />
    );
  }

  if (areDateOptionsVisible){
    return (
      <DateOptions />
    );
  }

  if (isBasisVisible){
    return (
      <Basis />
    );
  }

  if (editKey !== null){
    return (
      <Edit />
    );
  }

  return (
    <StepCard title={texts.title} desc={texts.desc} dividerDisabled>
      <InterestFormTable />
      <InterestFormAlert />
      <InterestFormDueBasis />
      <InterestFormPartialSum />
      <InterestFormAddButton />
      <InterestFormOptions />
    </StepCard>
  );
};


const texts = {
  title: 'Kwoty i okresy naliczania odsetek',
  desc: 'Uzupełnij kwoty, okresy naliczania odsetek, termin spełnienia świadczenia i podstawę (opcjonalnie)',
};

export default memo(PartialData);
