import { CalendarOutlined, FieldNumberOutlined } from '@ant-design/icons';
import React from 'react';

import { InvcVat } from '../../../../services/ML/docTypes';
import { InvVatFields, PayConfFields } from '../../../../services/ML/DataExtraction/fieldInputMap';
import { BLACK } from '../../../../styles';
import Icon from '../../../../styles/Icon';
import EvidEditPanel from '../EvidEditPanel';


const EditEviInvcVat = ({ results, onEdit, onReset, onChangeValue }:{
  results: InvcVat,
  onEdit: (field: InvVatFields) => void,
  onReset: (field: InvVatFields) => void,
  onChangeValue: ({
    key,
    value
  }:{
    key: InvVatFields | PayConfFields,
    value: number | string
  }) => void
  // docResults?: InvcVat,
}) => {
  const {
    allDocDates,
    allSums,
    accountingDocOpts,
    accountingDoc,
    partialSum,
    dueDate
  } = results;

  return (
    <>
      <EvidEditPanel
        icon={<FieldNumberOutlined />}
        {...{
          title: 'Numer faktury',
          similarValues: accountingDocOpts,
          onEdit:() =>
            onEdit('accountingDoc'),
          onReset: () =>
            onReset('accountingDoc'),
          chosenValue: accountingDoc ?? '',
          selectValue: (v: string | number) =>
            onChangeValue({
              key: 'accountingDoc',
              value: v
            })
        }}
      />
      <EvidEditPanel
        icon={(
          <Icon
            height="16"
            width="16"
            fill={BLACK}
            stroke={BLACK}
            iconType="MONEY_PACK"
          />
)}
        {...{
          title: 'Dochodzona kwota',
          similarValues: allSums,
          // aiValue: docResults?.partialSum,
          areSums: true,
          onEdit:() =>
            onEdit('partialSum'),
          onReset: () =>
            onReset('partialSum'),
          chosenValue: partialSum ?? 0,
          selectValue: (v: string | number) =>
            onChangeValue({
              key: 'partialSum',
              value: v
            })
        }}
      />
      <EvidEditPanel
        icon={<CalendarOutlined />}
        {...{
          title: 'Spełnienie świadczenia',
          similarValues: allDocDates,
          onEdit:() =>
            onEdit('dueDate'),
          onReset: () =>
            onReset('dueDate'),
          chosenValue: dueDate ?? '',
          selectValue: (v: string | number) =>
            onChangeValue({
              key: 'dueDate',
              value: v
            })
        }}
      />
    </>
  );
};

export default EditEviInvcVat;