/* eslint-disable @typescript-eslint/no-explicit-any */
import moment, { Moment } from 'moment';
import { AppDispatch } from '../store';
import { MaturityDateOpts, MATURITY_DUE_DAY, MATURITY_LATE_DAY } from '../store/interestFormOptions';
import { checkDueDate } from '../utils/helpers/date';
import { editActions, interestFormActions } from './setDates/setDatesActions';

export const setDueDate = ({
  value,
  dispatch,
  isStartingFromDue,
  endDate,
  maturityDateOpts,
  isEdit,
}:
{
  value:moment.Moment | null,
  dispatch: AppDispatch,
  endDate: Moment | null,
  isStartingFromDue: boolean,
  maturityDateOpts: MaturityDateOpts,
  isEdit: boolean,
}) => {
  const {
    dueDateUpdated,
    startingDateUpdated,
    displayFormInfo,
  } = isEdit ? editActions : interestFormActions;

  const { date:checkedDate, alert } = checkDueDate(value);

  const dueDate = value === null ? null : checkedDate.endOf('day');

  const startingDate = value === null ? null : moment(checkedDate).add(1, 'day').startOf('day');

  if (alert && value !== null && !(isStartingFromDue && moment(checkedDate).add(1, 'day').isSameOrAfter(endDate))) {
    displayFormInfo({
      infoMsg:  `${alert} - ustalam datę spełnienia świadczenia na najbliższy możliwy dzień: ${moment(checkedDate).format('DD-MM-YYYY')}.`,
      dispatch,
      infoOut: false,
    });
  }
  if (isStartingFromDue){
    if (moment(checkedDate).add(1, 'day').isSameOrAfter(endDate)){
      displayFormInfo({
        infoMsg: 'Uwaga: próbujesz ustawić datę początkową jako późniejszą lub równą dacie końcowej',
        dispatch,
        infoOut: true,
      });
    } else {
      dispatch(dueDateUpdated(dueDate));
      dispatch(startingDateUpdated(startingDate));
    }
  } else {
    dispatch(dueDateUpdated(dueDate));
  }

  setMaturityDate({
    maturityDateOpts,
    dueDate,
    startingDate,
    isStartingFromDue,
    dispatch,
    isEdit,
  });
};


export const setStartingEnd = ({
  value,
  dispatch,
  isSameEndDate,
  isStartingFromDue,
  maturityDateOpts,
  isEdit
}:{
  value: any,
  dispatch: AppDispatch,
  isSameEndDate: boolean,
  isStartingFromDue: boolean,
  maturityDateOpts: MaturityDateOpts,
  isEdit: boolean,
}) => {
  const {
    dueDateUpdated,
    startingDateUpdated,
    displayFormInfo,
    maturityDateUpdated,
    endDateUpdated
  } = isEdit ? editActions : interestFormActions;

  if (value === null){
    dispatch(startingDateUpdated(null));
    if (!isSameEndDate){
      dispatch(endDateUpdated(null));
    }
    if (isStartingFromDue){
      dispatch(dueDateUpdated(null));
      dispatch(maturityDateUpdated(null));
    }
    if (maturityDateOpts === MATURITY_LATE_DAY && !isStartingFromDue) {
      dispatch(maturityDateUpdated(null));
    }
  } else if (isStartingFromDue && value !== null && value[0] !== null){

    const { date:checkedDate, alert } = checkDueDate(moment(value[0]).subtract(1, 'day'));
    dispatch(startingDateUpdated(moment(checkedDate).add(1, 'day').startOf('day')));
    dispatch(dueDateUpdated(checkedDate.endOf('day')));
    if (maturityDateOpts === MATURITY_LATE_DAY) {
      dispatch(maturityDateUpdated(moment(checkedDate).add(1, 'day').startOf('day')));
    }
    if (maturityDateOpts === MATURITY_DUE_DAY) {
      dispatch(maturityDateUpdated(checkedDate.endOf('day')));
    }
    if (!isSameEndDate){
      dispatch(endDateUpdated(value[1].endOf('day')));
    }
    if (alert && value !== null){
      displayFormInfo({
        dispatch,
        infoMsg: `${alert} - ustalam termin spełnienia świadczenia na najbliższy możliwy dzień: ${moment(checkedDate).format('DD-MM-YYYY')}.`,
        infoOut: false,
      });
    }
  } else {
    dispatch(startingDateUpdated(value[0].startOf('day')));
    if (maturityDateOpts === MATURITY_LATE_DAY) {
      dispatch(maturityDateUpdated(moment(value[0].startOf('day'))));
    }
    if (!isSameEndDate){
      dispatch(endDateUpdated(value[1].endOf('day')));

    }
    if (value[0] === null && isStartingFromDue){
      dispatch(dueDateUpdated(null));
      if (maturityDateOpts === MATURITY_DUE_DAY) {
        dispatch(maturityDateUpdated(null));
      }
    }
  }
};


const setMaturityDate = ({
  maturityDateOpts,
  dueDate,
  startingDate,
  isStartingFromDue,
  dispatch,
  isEdit,
}:{
  maturityDateOpts: MaturityDateOpts,
  dueDate: Moment | null
  startingDate: Moment | null
  isStartingFromDue: boolean,
  dispatch: AppDispatch,
  isEdit: boolean,
}) => {
  const { maturityDateUpdated } = isEdit ? editActions : interestFormActions;
  if (maturityDateOpts === MATURITY_DUE_DAY) dispatch(maturityDateUpdated(dueDate));
  else if (maturityDateOpts === MATURITY_LATE_DAY && isStartingFromDue) dispatch(maturityDateUpdated(startingDate));
};



