import React from 'react';
import { LINK_FB, LINK_LI, LINK_YT } from '../../../../utils/helpers/links';
import useWindowSize from '../../../../utils/hooks/useWindowSize';
import { GRID_SM } from '../../../../styles';
import { FACEBOOK, FACEBOOK_SOLID, LINKED_IN, LINKED_IN_SOLID, YOU_TUBE, YOU_TUBE_SOLID } from '../../../../styles/iconTypes';
import IconButton from '../../../UI/Button/IconButton';

const SloganSocialMedia = () => {
  const { width } = useWindowSize();
  return (
    <>
      {width > GRID_SM ? (
        <>

          <IconButton
            href={LINK_LI}
            iconType={LINKED_IN}
            iconHoover={LINKED_IN_SOLID}
            stroke
            style={{
              marginLeft:12,  marginTop:4
            }}
          />
          <IconButton
            href={LINK_FB}
            iconType={FACEBOOK}
            iconHoover={FACEBOOK_SOLID}
            stroke
            style={{
              marginTop:6, marginLeft:24
            }}
          />
          <IconButton
            href={LINK_YT}
            iconType={YOU_TUBE}
            iconHoover={YOU_TUBE_SOLID}
            stroke
            style={{
              marginLeft:24,  marginRight: 12,  marginTop:10
            }}
          />
        </>
      ) :  null}
    </>
  );
};

export default SloganSocialMedia;
