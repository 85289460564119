import { Col, Typography } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { ArcherContainer } from 'react-archer';
import styled from 'styled-components';

import { GRID_MD, RowExt } from '../../../styles';
import { GREY_WHITE } from '../../../styles/colors';
import { ABOUT_DASH, MiddleBorder } from '../../../styles/ui';
import { romanize } from '../../../utils/helpers/romanize';
import useAssignmentPeriods from '../../../utils/hooks/useAssignmentPeriods';
import usePaymentsColors from '../../../utils/hooks/usePaymentsColors';
import useWindowSize from '../../../utils/hooks/useWindowSize';
import { Claim } from './Claim/Claim';
import PaymentAssignment from './PaymentAssignment/PaymentAssignment';

const { Title } = Typography;

const AssignPaymentsList = () => {
  const { width } = useWindowSize();

  const [paymentKey, selectPayment] = useState<null | string>(null);

  const assignmentPeriods = useAssignmentPeriods();

  const paymentsColors = usePaymentsColors();


  if (width < GRID_MD) {
    return (
      <ArcherContainer>
        <RowExt justify="center" marginTop="md">
          <Title level={3}>
            Wpłaty
          </Title>
        </RowExt>
        {assignmentPeriods.map(({ data }) =>
          (
            <>
              {(data).map((paymentItem, index) => {
                const isMarginTopReq = data.length > 1 && index !== 0;
                if ('paymentDate' in paymentItem){
                  return (
                    <PaymentAssignment
                      {...{ paymentItem }}
                      paymentKey={paymentKey}
                      selectPayment={selectPayment}
                      key={paymentItem.key}
                      isMarginTopReq={isMarginTopReq}
                      paymentsColors={paymentsColors}
                    />
                  );
                }
                return null;
              }
              )}
            </>
          ))}
        <RowExt justify="center" marginTop="bg">
          <Title level={3}>
            Roszczenia
          </Title>
        </RowExt>
        <RowExt marginTop="md">
          {assignmentPeriods.map(({ data }) =>
            (
              <>
                {data.map((interestItem, index) => {
                  const isMarginTopReq = data.length > 1 && index !== 0;
                  if ('startingDate' in interestItem){
                    return (
                      <Claim
                        {...{ interestItem }}
                        paymentsColors={paymentsColors}
                        paymentItemKey={paymentKey}
                        selectPayment={selectPayment}
                        key={interestItem.key}
                        isMarginTopReq={isMarginTopReq}
                      />
                    );
                  }
                  return null;
                }
                )}
              </>
            ))}
        </RowExt>
      </ArcherContainer>
    );
  }

  return (
    <ArcherContainer>
      {assignmentPeriods.map(({ data, startOfPeriod }) => {
        return (
          <>
            <RowExt align="middle" marginTop={12} marginBot={12}>
              <Col xs={{
                offset: 1, span: 8
              }}
              />
              <Col xs={{
                offset:1, span:4
              }}
              >
                <RowExt
                  justify="center"
                >
                  <MonthNumber>
                    <TitleStyled
                      level={5}
                      style={{ textAlign:'center' }}
                    >
                      {romanize(moment(startOfPeriod).month() + 1)}
                      <br />
                      {moment(startOfPeriod).year()}
                    </TitleStyled>
                  </MonthNumber>
                </RowExt>
              </Col>
            </RowExt>
            <RowExt marginTop={12}>
              <Col
                xs={10}
              >
                {data.map((interestItem, index) => {
                  const isMarginTopReq = data.length > 1 && index !== 0;
                  if ('startingDate' in interestItem){
                    return (
                      <Claim
                        {...{ interestItem }}
                        paymentsColors={paymentsColors}
                        paymentItemKey={paymentKey}
                        selectPayment={selectPayment}
                        key={interestItem.key}
                        isMarginTopReq={isMarginTopReq}
                      />
                    );
                  }
                  return null;
                }
                )}
              </Col>
              <Col xs={4}>
                <RowExt
                  justify="center"
                  style={{ height: '100%' }}
                >
                  <Col xs={4}>
                    <MiddleBorder />
                  </Col>
                </RowExt>
              </Col>
              <Col
                xs={10}
              >
                {(data).map((paymentItem, index) => {
                  const isMarginTopReq = data.length > 1 && index !== 0;
                  if ('paymentDate' in paymentItem){
                    return (
                      <PaymentAssignment
                        {...{ paymentItem }}
                        paymentKey={paymentKey}
                        selectPayment={selectPayment}
                        key={paymentItem.key}
                        isMarginTopReq={isMarginTopReq}
                        paymentsColors={paymentsColors}
                      />
                    );
                  }
                  return null;
                }
                )}
              </Col>
            </RowExt>
          </>
        );
      })}
    </ArcherContainer>
  );
};

export default AssignPaymentsList;

const MonthNumber = styled.div`
    box-shadow: 0px  8px 8px rgba(0, 0, 0, 0.11);
    border-radius: 8px;
    padding: 10px;
    z-index: 2;
    background-color: white;
    border: ${ABOUT_DASH};
    background: ${GREY_WHITE};
`;

const TitleStyled = styled(Title)`
    margin-bottom: 0 !important;
    font-size: 10px !important;
    font-family: 'Fira Sans';
    line-height: 1.3 !important;
`;
