import { CalendarOutlined, ControlOutlined, RobotOutlined } from '@ant-design/icons';
import { Card, Col } from 'antd';
import moment from 'moment';
import React from 'react';
import { shallowEqual } from 'react-redux';

import { useTypedSelector } from '../../../../store';
import { MATURITY_LATE_DAY } from '../../../../store/interestFormOptions';
import { BLACK, RowMd } from '../../../../styles';
import Icon from '../../../../styles/Icon';
import { ANNOUNCEMENT, BALANCE } from '../../../../styles/iconTypes';
import { formatDate } from '../../../../utils/helpers/date';
import { ImpTypes, IMP_OCR } from '../impCommonData';

const ImpSumUpOpts = ({ impType }:{
  impType: ImpTypes
}) => {
  const {
    legalBasis,
    factualBasis,
    endDate,
    maturityDateOpts,
    shouldAutoAssign,
  } = useTypedSelector(state =>
    impType === IMP_OCR ? state.impOcr : state.impExternal, shallowEqual);

  return (
    <RowMd
      style={{ width: '100%' }}
    >
      <Col span={24}>
        <Card
          style={{ width: '100%' }}
          title="Podsumowanie opcji importu"
          bordered={false}
          extra={
            <ControlOutlined />
        }
        >
          <Card type="inner" title="Wspólna data końcowa" extra={<CalendarOutlined />}>
            {formatDate(endDate ?? moment())}
          </Card>
          <Card
            type="inner"
            title="Data wymagalności"
            extra={<CalendarOutlined />}
            style={{ marginTop:12 }}
          >
            {maturityDateOpts === MATURITY_LATE_DAY ?
              'data wymagalności równa dacie opóźnienia (początkowej)' :
              'data wymagalności równa terminowi spełnienia świadczenia'}
          </Card>
          <Card
            type="inner"
            title="Wspólna podstawa prawna"
            style={{ marginTop:12 }}
            extra={(
              <Icon
                iconType={BALANCE}
                height="18"
                width="18"
                fill={BLACK}
              />
)}
          >
            {legalBasis ?? 'BRAK'}
          </Card>
          <Card
            type="inner"
            title="Wspólna podstawa faktyczna"
            style={{ marginTop:12 }}
            extra={(
              <Icon
                iconType={ANNOUNCEMENT}
                height="18"
                width="18"
                fill={BLACK}
              />
)}
          >
            {factualBasis ?? 'BRAK'}
          </Card>
          <Card
            type="inner"
            title="Automatycznie zalicz wpłaty"
            style={{ marginTop:12 }}
            extra={(
              <>
                <RobotOutlined />
                {' '}
                <strong>AI</strong>
              </>
)}
          >
            {shouldAutoAssign ? 'TAK' : 'NIE'}
          </Card>
        </Card>
      </Col>
    </RowMd>
  );
};

export default ImpSumUpOpts;