import { setAnalyticsCollectionEnabled } from 'firebase/analytics';
import React from 'react';
import CookieConsent from 'react-cookie-consent';
import styled from 'styled-components';
import { analytics } from '../../services/firebase/logToAnalytics';
import { BLACK, BLUE } from '../../styles';

const CookiesModal = () => {
  const text =  'Czy akceptujesz pliki cookies używane aby wspomóc funkcjonalność i wydajnośc pozew.app? ';
  return (
    <CookieConsent
      location="bottom"
      buttonText="Akceputję pliki cookies"
      declineButtonText="Odrzucam plik cookies"
      enableDeclineButton
      declineCookieValue="_ga"
      style={{
        background: 'white', opacity: 0.85
      }}
      buttonStyle={{
        background:BLUE, fontSize: '13px', color: 'white'
      }}
      declineButtonStyle={{
        background:'white', fontSize: '13px', color: BLACK, border: `1px dashed ${BLACK}`
      }}
      expires={150}
      onDecline={
          () =>
            setAnalyticsCollectionEnabled(analytics, false)
}
    >
      <Wrapper>
        {text}
        <a
          style={{ marginLeft:12 }}
          href="https://pozewa.app/privacy"
        >
          Dowiedz się więcej
        </a>
      </Wrapper>
    </CookieConsent>
  );
};

export default CookiesModal;


const Wrapper = styled.div`
  display: flex;
  color: ${BLACK};
  justify-content: center;
`;

