import { HomeOutlined } from '@ant-design/icons';
import { Badge, Button, Col, Row, Space } from 'antd';
import { DocumentData, onSnapshot, QuerySnapshot } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { Img } from 'react-image';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { LocationState } from '../../../@types';

import logo from '../../../assets/logo.svg';
import { auth } from '../../../services/firebase/auth';
import { inboxQuery } from '../../../services/firebase/firestoreRefs';
import { useTypedSelector } from '../../../store';
import { BLACK, BLUE, GRID_MD, RowExt } from '../../../styles';
import { LinkFullWidth } from '../../../styles/ui';
import { YT_INTR_FORM_GUIDE, YT_PAYMENTS_GUIDE } from '../../../utils/helpers/links';
import useIsPaymentAssignmentOn from '../../../utils/hooks/useIsPaymentAssignmentOn';
import useIsTransacOrAgree from '../../../utils/hooks/useIsTransacOrAgree';
import useWindowSize from '../../../utils/hooks/useWindowSize';
import CheckVideoButton from '../Button/CheckVideoButton';
import LogOutButton from '../Button/LogOutButton';

const NavPrivate = () => {
  const { width, height } = useWindowSize();
  const [inboxAlert, setInboxAlert] = useState(false);
  const userExists = useTypedSelector(state =>
    state.user.isNonAnonymousUserLoggedIn);
  const step = useTypedSelector((state) =>
    state.lawsuitUI.step);

  const location = useLocation<LocationState>();
  const isLawsuit = location.pathname === '/lawsuit';

  const isTransacOrAgree = useIsTransacOrAgree();

  const isPaymentAssignmentOn = useIsPaymentAssignmentOn();

  const isInterestFormVisible = isTransacOrAgree && step === 3 || !isTransacOrAgree && step === 2;

  const isAssignPaymentsVisible = isPaymentAssignmentOn

  && (isTransacOrAgree ?  step === 4 : step === 3);

  useEffect(() => {
    const { currentUser } = auth;
    if (userExists && currentUser) {
      const resetComponent = () => {
        setInboxAlert(false);
      };
      const unsubscribe = onSnapshot(inboxQuery(currentUser.uid), (snapshot:QuerySnapshot<DocumentData>) => {
        if (!snapshot.empty){
          setInboxAlert(true);
        } else {
          resetComponent();
        }
      }, err => {
        // eslint-disable-next-line no-console
        console.log(err);
        resetComponent();
      });
      return () => {
        unsubscribe();
        resetComponent();
      };
    }
    return undefined;
  }, [userExists]);
  return (
    <>
      <RowExt align="middle" fullWidth paddingTop="md" id="main" paddingBottom={width >= 1024 && height <= 900 ? 0 : 12}>
        <Col
          lg={{
            offset:0,
            span:4,
          }}
          xs={{
            offset:2,
            span: 6,
          }}
        >
          <LinkFullWidth
            to={{ pathname: '/' }}
          >
            <ImageLogo
              src={logo}
            />
          </LinkFullWidth>
        </Col>
        <Col
          lg={{ span: 20 }}
          xs={{ span: 14 }}
        >
          <Row justify="end">
            <Space>
              {(isInterestFormVisible || isAssignPaymentsVisible) && width >= GRID_MD  && isLawsuit ? (
                <CheckVideoButton
                  url={isInterestFormVisible ? YT_INTR_FORM_GUIDE : YT_PAYMENTS_GUIDE}
                  text="VIDEO instrukcja"
                  flat
                  emptyButtonProps={{
                    color: BLACK, hoverBg: BLACK, hoverColor: 'white', dotted: false
                  }}
                />
              ) : null}
              {userExists && width >= 425 ? (
                <Badge dot={inboxAlert}>
                  <Link
                    to={{ pathname: '/dashboard' }}
                  >

                    <Button
                      type="dashed"
                      icon={(
                        <HomeStyled
                          style={{ color:BLUE }}
                        />
)}
                    />
                  </Link>
                </Badge>
              ) : null}
              <LogOutButton />
            </Space>
          </Row>
        </Col>
      </RowExt>
    </>
  );
};

export default NavPrivate;

export const ImageLogo = styled(Img)<{ grey?: boolean }>`
    width: 124px;
`;

const HomeStyled = styled(HomeOutlined)`
  color: ${BLUE};
`;

