import React from 'react';
import AbtOneLineBot from '../components/About/AbtOneLineBot';
import GuideIntro from '../components/Guides/GuideIntro';
import GuideAssignPayments from '../components/Guides/GuidePosts/GuideAssignPayments';
import GuideCode from '../components/Guides/GuidePosts/GuideCode';
import GuideConnectedUsers from '../components/Guides/GuidePosts/GuideConnectedUsers';
import GuideCurrency from '../components/Guides/GuidePosts/GuideCurrency';
import GuideImport from '../components/Guides/GuidePosts/GuideImport';
import GuideIntrForm from '../components/Guides/GuidePosts/GuideIntrForm';
import GuidesHeader from '../components/Guides/GuidesHeader';
import AnimOpacity from '../components/UI/Anim/AnimOpacity';
import DescScreenFooter from '../components/UI/DescScreenFooter';
import { ScreenLayout } from '../styles';
import { DashedDivider } from './About';

const Guides = () => {
  return (
    <AnimOpacity>
      <ScreenLayout>
        <GuidesHeader />
        <DashedDivider />
        <AbtOneLineBot />
        <GuideIntro />
        <GuideIntrForm />
        <GuideAssignPayments />
        <GuideCode />
        <GuideCurrency />
        <GuideImport />
        <GuideConnectedUsers />
        <DescScreenFooter />
      </ScreenLayout>
    </AnimOpacity>
  );
};

export default Guides;
