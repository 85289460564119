import React from 'react';

import useInterestBasis from '../../../utils/hooks/justification/useInterestBasis';
import useInterestIntro from '../../../utils/hooks/justification/useInterestIntro';
import useSumInterests from '../../../utils/hooks/justification/useSumInterests';
import JustInterestPartials from './JustInterestsPartials';
import JustParagraph from './JustParagraph';

const JustInterests = () => {
  const interestPartialIntro = useInterestIntro();
  const interestSum = useSumInterests();
  const interestBasis = useInterestBasis();

  return (
    <>

      {/* ---------------------------- 1. interest intro --------------------------- */}
      <JustParagraph
        text={interestPartialIntro}
      />

      {/* --------------------------- 2. interest partials -------------------------- */}
      <JustInterestPartials />

      {/* ---------------------------- 3. interests sum ---------------------------- */}
      <JustParagraph
        text={interestSum}
      />

      {/* ----------------------------- interests basis ---------------------------- */}
      <JustParagraph
        text={interestBasis}
      />

    </>
  );
};


export default JustInterests;
