import React from 'react';
import { shallowEqual } from 'react-redux';
import { toUnit } from '../../../utils/helpers/money';
import { getWps } from '../../../services/calc/wpsCalc';
import { useTypedSelector } from '../../../store';
import ResultRow from '../SumUpHead/ResultRow';
import { WpsTitle } from './WpsTitle';


const Wps = () => {
  const { overallInterestsSum, compensationSum, finalSum } = useTypedSelector(
    (state) =>
      state.sumUp,
    shallowEqual
  );

  return (
    <>
      <WpsTitle title={texts.title} />
      <ResultRow
        sum1={getWps(toUnit( finalSum))}
        sum2={getWps(toUnit(overallInterestsSum + finalSum))}
        sum3={getWps(toUnit(finalSum + compensationSum + overallInterestsSum))}
        legal={(
          <span>
            Podstawa prawna: art. 19 kpc. Zaokrąglone do 1 zł w górę - art. 126
            <sup>1</sup>
            Kodeksu postępowania cywilnego
          </span>
        )}
      />
    </>
  );
};

export default Wps;
const texts = {
  title: 'Wartość przedmiotu sporu liczona w oparciu o',
  legal: 'Podstawa prawna: art. 19 kpc. Zaokrąglone do 1 zł w górę - art. 126(1) kpc',
};
