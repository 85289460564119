import { Col, Row, Space } from 'antd';
import React from 'react';

import { RowExt } from '../../../../styles';
import useJustification from '../../../../utils/hooks/justification/useJustification';
import CopyButtonJust from '../../../UI/Button/CopyButtonJust';


const JustCopy = () => {

  const justification = useJustification();

  return (
    <RowExt marginTop="bg">
      <Col span={18} />
      <Col
        span={6}
      >
        <Row justify="end">
          <Space>
            <CopyButtonJust
              womargin
              value={justification}
            />
          </Space>
        </Row>
      </Col>
    </RowExt>
  );
};

export default JustCopy;
