import { FilePdfOutlined, FileOutlined, CloseOutlined } from '@ant-design/icons';
import { Col, Row, Typography, Button } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { BLUE, RowSm } from '../../../../styles';

const FilePreview = ({ file, removeFile }:{
  file: File
  removeFile: () => void
}) => {
  const [loading, setLoading] = useState(true);
  const [image, setImage] = useState('');

  useEffect(() => {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      if (_.isString(reader.result)){
        setImage(reader.result);
      }
      setLoading(false);
    });
    reader.readAsDataURL(file);
    return () => {
      reader.abort();
      setImage('');
    };
  }, [file]);


  const ButtonIcon = () => {
    if (file.type === 'application/pdf') return <FilePdfOutlined />;
    if (!loading && (
      file.type === 'image/png'
      || file.type === 'image/jpeg'
      || file.type === 'image/jpg'
    )) return (
      <img
        src={image}
        alt=""
        style={{ width: '100%' }}
      />
    ) ;
    return <FileOutlined />;
  };
  return (
    <Col>
      <Row>
        <FileButton>
          <ButtonIcon />
        </FileButton>
      </Row>
      <RowSm
        justify='center'
        style={{ paddingBottom: 6 }}
      >
        <Typography.Text
          strong
        >
          {_.truncate(file.name, { length: 10 })}
        </Typography.Text>
      </RowSm>
      <Row justify='center' onClick={removeFile}>
        <Button type="text" icon={<CloseOutlined />} />
      </Row>
    </Col>
  );
};

export default FilePreview;


export const PlusButton = styled.button`
    width: 105px;
    height: 148px;
    margin-right: 8px;
    margin-bottom: 8px;
    text-align: center;
    vertical-align: top;
    background-color: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 2px;
    cursor: pointer;
    transition: border-color 0.3s;
    box-sizing: border-box;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    outline: 0;
`;


const FileButton = styled(PlusButton)`
    font-size: 32px;
    border: 1px solid ${BLUE};
`;