import { MailOutlined } from '@ant-design/icons';
import { Button, Card, Input, Tag } from 'antd';
import React, { useState } from 'react';
import nextId from 'react-id-generator';
import ReactJson from 'react-json-view';

import { BLUE, GREY_BORDER } from '../../styles/colors';
import DeveloperSection from './DeveloperSection';

const tabList = [
  {
    key: 'tab1',
    tab: 'schema',
  },
  {
    key: 'tab2',
    tab: 'example',
  },
];

const DeveloperInbox = () => {

  const [activeTabKey1, setActiveTabKey1] = useState<string>('tab1');

  const onTab1Change = (key: string) => {
    setActiveTabKey1(key);
  };


  return (
    <DeveloperSection
      {...{
        desc: 'Jak uzyskać możliowść przesyłania danych',
        color: BLUE,
        id: 'auth'
      }}
      title="Wysyłanie danych na skrzynkę obiorczą"
    >
      <Card
        title={(
          <>
            <Tag
              style={{
                fontSize: 18, padding: 12,
              }}
              color="blue"
            >
              POST
            </Tag>
            {' '}
            wysyłanie danych na skrzynkę odbiorczą innego użytkownika
          </>
        )}
        bordered={false}
        style={{
          width: '100%', border: 'none'
        }}
        headStyle={{
          border: 'none', paddingTop: '0 !important'
        }}
      >
        <Input
          style={{ background: GREY_BORDER }}
          value="https://us-central1-pozewapp.cloudfunctions.net/lawsuitInbox"
        />
        <Card
          type='inner'
          title="Authorization"
          style={{
            marginBottom: 24, marginTop: 24
          }}
        >
          Bearer Token
        </Card>
        <Card
          type='inner'
          title="Request body Json"
          style={{
            marginBottom: 24, marginTop: 24
          }}
          bodyStyle={{
            padding:0, paddingTop: 1
          }}
          tabList={tabList}
          activeTabKey={activeTabKey1}
          onTabChange={key => {
            onTab1Change(key);
          }}
        >
          {activeTabKey1 === 'tab1' ? (
            <ReactJson
              shouldCollapse={false}
              displayDataTypes={false}
              displayObjectSize={false}
              style={{ padding: 12 }}
              indentWidth={2}
              src={{
                type: 'object',
                properties: {
                  receiverEmail: {
                    type: 'string',
                    description: 'adres email odbiorcy danych'
                  },
                  recordName: {
                    type: 'string',
                    description: 'nazwa przesłanych danych (opcjonalnie)'
                  },
                  debts: {
                    type: 'array',
                    items: [
                      {
                        type: 'object',
                        properties: {
                          partialSum: {
                            type: 'number',
                            description: 'kwota długu',
                          },
                          dueDate: {
                            type: 'string ($date-time)',
                            description: 'termin spełnienia świadczenia',
                          },
                          accountingDoc: {
                            type: 'string',
                            description: 'numer dokumentu księgowego (opcjonalne)',
                          },
                        },
                        required: ['partialSum', 'dueDate'],
                      },
                    ],
                  },
                  payments: {
                    type: 'array',
                    items: [
                      {
                        type: 'object',
                        properties: {
                          paymentSum: {
                            type: 'number',
                            description: 'kwota wpłaty',
                          },
                          paymentDate: {
                            type: 'string ($date-time)',
                            description: 'data wpłaty',
                          },
                          transferDescription: {
                            type: 'string',
                            description: 'opis przelewu (opcjonalnie)',
                          },
                        },
                        required: ['paymentSum', 'paymentDate'],
                      },
                    ],
                  },
                },
                required: ['receiverEmail', 'debts'],
                additionalProperties: false,
              }}
              theme="solarized"
            />
          ) :  (
            <ReactJson
              shouldCollapse={false}
              displayDataTypes={false}
              displayObjectSize={false}
              style={{ padding: 12 }}
              indentWidth={2}
              src={{
                'receiverEmail':'contact@pozew.app',
                'recordName':'Sprawa nr 123',
                'debts':[
                  {
                    'dueDate':'2022-01-01T18:25:43.511Z',
                    'partialSum':33023.21,
                    'accountingDoc':'fv/2/22'
                  },
                  {
                    'dueDate':'2021-08-23T18:25:43.511Z',
                    'partialSum':24300.88,
                    'accountingDoc':'fv/52/21'
                  }
                ],
                'payments':[
                  {
                    'paymentDate':'2022-04-23T18:25:43.511Z',
                    'paymentSum':5000.32,
                    'transferDescription':'fv/2/22'
                  }
                ]
              }}
              theme="solarized"
            />
          )}
        </Card>
        <Card
          type='inner'
          title="Responses"
          actions={[
            <a
              key={nextId()}
              href='mailto:contact@pozew.app'
            >
              <Button
                icon={<MailOutlined />}
                type="link"
              >
                Support
              </Button>
            </a>,
          ]}
          style={{ marginBottom: 24 }}
        >
          <Card.Meta
            title="201"
            description="item created"
            style={{ marginBottom: 12 }}
          />
          <Card.Meta
            title="400"
            description="invalid input, object invalid, receiver does not exist"
            style={{ marginBottom: 12 }}
          />
          <Card.Meta
            title="5xx"
            description="error"
            style={{ marginBottom: 12 }}
          />
        </Card>
      </Card>
    </DeveloperSection>
  );
};

export default DeveloperInbox;
