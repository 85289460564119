import { Tag } from 'antd';
import React from 'react';
import styled from 'styled-components';

export const EvidTextTag = ({
  onClick,
  text,
  active,
}: {
  onClick?: ()=> void,
  text: string | number
  active?: boolean
}) => {
  return (
    <StyledTag
      onClick={onClick}
      color={active ? 'blue' : 'default'}
    >
      {text}
    </StyledTag>
  );
};

const StyledTag = styled(Tag)`
  margin: 6px;
  cursor: pointer !important;
`;
