import { Card } from 'antd';
import Meta from 'antd/lib/card/Meta';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { SubscriptionTypes } from '../../../store/user';
import { GRID_SM_PX } from '../../../styles';
import Icon from '../../../styles/Icon';
import { subscriptionStyle } from '../../../styles/subscriptionStyles';

const UserSubscriptionCard = (
  {
    subscriptionType,
    actions,
    metaTitle,
    metaDesc
  }: {
    subscriptionType: SubscriptionTypes,
    actions: ReactNode[],
    metaTitle: string,
    metaDesc: string
  }
) => {

  return (
    <UserSubCardStyle
      title="Subskrypcja"
      style={{
        width: '100%', marginTop: 16
      }}
      actions={[...actions]}
    >
      <Meta
        avatar={(
          <Icon
            height="24"
            width="24"
            fill={subscriptionStyle(subscriptionType).color}
            iconType={subscriptionStyle(subscriptionType).icon}
          />
)}
        title={metaTitle}
        description={<UserSubDesc>{metaDesc}</UserSubDesc>}
      />
    </UserSubCardStyle>
  );
};

export default UserSubscriptionCard;

export const TagWrapper = styled.div`
   margin-top: 6px;
`;

export const UserSubDesc = styled.span`
  	@media only screen and (max-width: ${GRID_SM_PX}) {
    font-size: 12px;
	}
`;

export const UserSubCardStyle = styled(Card)`
    width: 100%;
    margin-top:12px;
`;