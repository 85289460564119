import { Radio, RadioChangeEvent } from 'antd';
import React from 'react';
import { shallowEqual } from 'react-redux';

import { useAppDispatch, useTypedSelector } from '../../../store';
import { interestDataProviderUpdated, MANUAL_PAYMENTS_ASSIGNMENTS, MANUAL_STANDARD } from '../../../store/lawsuitUI';
import { BetaTag, GRID_LG } from '../../../styles';
import useWindowSize from '../../../utils/hooks/useWindowSize';
import StepCard from '../StepCard/StepCard';
import { Button2Lines } from './TypeOfInterest';

const InterestDataProviderSelect = () => {
  const { width } = useWindowSize();

  const  { interestDataProvider } =  useTypedSelector(state =>
    state.lawsuitUI, shallowEqual);

  const dispatch = useAppDispatch();

  const onPress = (event:RadioChangeEvent) => {
    dispatch(interestDataProviderUpdated(event.target.value));
  };
  const radioStyle = {
    display: 'block',
    height: '32px',
    lineHeight: '32px',
  };

  if (width < GRID_LG) {
    return (
      <StepCard title={texts.title} desc={texts.desc}>
        <Radio.Group
          onChange={onPress}
          value={interestDataProvider}
          style={{ width: '100%' }}
        >
          <Button2Lines value={MANUAL_STANDARD}>
            {texts.radio1}
          </Button2Lines>
          <Button2Lines value={MANUAL_PAYMENTS_ASSIGNMENTS}>
            {texts.radio2}
            <BetaTag>BETA</BetaTag>
          </Button2Lines>
        </Radio.Group>
      </StepCard>
    );
  }
  return (
    <StepCard title={texts.title} desc={texts.desc}>
      <Radio.Group
        onChange={onPress}
        value={interestDataProvider}
      >
        <Radio style={radioStyle} value={MANUAL_STANDARD}>
          {texts.radio1}
        </Radio>
        <Radio style={radioStyle} value={MANUAL_PAYMENTS_ASSIGNMENTS}>
          {texts.radio2}
          <BetaTag>BETA</BetaTag>
        </Radio>
      </Radio.Group>
    </StepCard>
  );
};

const texts = {
  title: 'Kwoty główne - wprowadzanie',
  desc: 'Jak wprowadzamy kwoty główne roszczeń?',
  radio1: 'wpisz tylko kwoty główne roszczeń (standard)',
  radio2: 'ustal wysokość roszczeń przy zaliczeniu wpłat',
  radio3: 'importuj z pliku excel'
};


export default InterestDataProviderSelect;