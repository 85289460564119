import React from 'react';
import { AllocationMechanism, AUTO_ASSIGN_EXACT, AUTO_ASSIGN_SIMILAR, AUTO_ASSIGN_DATE, AUTO_ASSIGN_SUM, AUTO_ASSIGN_DEFAULT } from '../../../../services/autoAssign/autoAssignCommon';
import { InterestItem, PaymentItem } from '../../../../store/interestStorage';
import { formatDate } from '../../../../utils/helpers/date';
import { formatPLN } from '../../../../utils/helpers/formatNumber';

const HistoryDescription = ({
  allocationMechanism,
  paymentItem,
  interestItem
}: {
  paymentItem: PaymentItem
  allocationMechanism: AllocationMechanism
  interestItem?: InterestItem
}) => {
  if (!interestItem) return null;
  switch (allocationMechanism) {
    case AUTO_ASSIGN_EXACT:
      return (
        <>
          Numer dokumentu księgowego to
          {' '}
          <strong>{interestItem.accountingDoc}</strong>
          {' '}
          podczas gdy tytuł wpłaty to
          {' '}
          <strong>{paymentItem.transferDescription}</strong>
          .
          Tytuł wpłaty stanowi
          {' '}
          <strong>wyraźne oświadczneie dłużnika </strong>
          {' '}
          co do sposobu zaliczenia. Zgodnie z art. 451 § 1 Kodeksu Cywilnego, takiemu oświadczeniu należy dać pierwszeństwo.
        </>
      );
    case AUTO_ASSIGN_SIMILAR:
      return (
        <>
          Numer dokumentu księgowego to
          {' '}
          <strong>{interestItem.accountingDoc}</strong>
          {', '}
          podczas gdy tytuł wpłaty to
          {' '}
          <strong>{paymentItem.transferDescription}</strong>
          .
          {' '}
          <strong>Nazwy są bardzo zbliżone i dlatego przyjęto, że tytuł wpłaty stanowi wyraźne oświadczenie dłużnika o zaliczeniu.</strong>
          {' '}
          Zgodnie z art. 451 § 1 Kodeksu Cywilnego, takiemu oświadczeniu należy dać pierwszeństwo.
        </>
      );
    case AUTO_ASSIGN_DATE:
      return (
        <>
          Termin spełnienia świadczenia to
          {' '}
          <strong>{interestItem?.dueDate ? formatDate(interestItem?.dueDate) : ''}</strong>
          {' '}
          tytuł wpłaty brzmi
          {' '}
          <strong>{paymentItem.transferDescription}</strong>
          .
          {' '}
          <strong>To wskazanie zostało potraktowane jako oświadczenie o zaliczeniu.</strong>
          {' '}
          Zgodnie z art. 451 § 1 Kodeksu Cywilnego, takiemu oświadczeniu należy dać pierwszeństwo.
        </>
      );
    case AUTO_ASSIGN_SUM:
      return (
        <>
          Opis wpłaty nie był wystrczający aby zaliczyć wpłatę na któreś z roszczeń.
          Natomiast kwota roszczenia:
          {' '}
          (
          <strong>{formatPLN(interestItem.partialSum)}</strong>
          )

          i kwota wpłaty
          {' '}
          (
          <strong>{formatPLN(paymentItem.paymentSum)}</strong>
          )
          {' są tożsame.'}
          <strong> Ta sytuacja została potraktowana jako dorozumiane oświadczenie dłużnika o zaliczeniu.</strong>
          {' '}
          Zgodnie z art. 451 § 1 Kodeksu Cywilnego, takiemu oświadczeniu należy dać pierwszeństwo.
        </>
      );
    case AUTO_ASSIGN_DEFAULT:
      return (
        <>
          Opis wpłaty  nie był wystrczający aby zinterpretować oświadczenie woli dłużnika o zaliczeniu.
          Zgodnie z art. 451 § 3 Kodeksu Cywilnego została zaliczona na na poczet najdawniej wymagalnego.
        </>
      );
    default:
      return null;
  }
};

export default HistoryDescription;