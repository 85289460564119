/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthProviders } from './logInReducer';

interface SignUpState {
  email: string;
  emailValidated: boolean | null;

  // signUpEmail
  emailError: boolean;
  emailErrorMessage: string;
  password: string;
  passwordValidated: boolean | null;
  passwordError: boolean;
  passwordErrorMessage: string;

  termsAccepted: boolean;
  privacyAccepted: boolean;

  // signUpProcess
  loading: boolean;
  success: boolean;
  error: boolean;
  errorMessage: string;
  provider:AuthProviders;
}

const initialState: SignUpState = {
  email: '',
  password: '',

  // signUpEmail
  emailValidated: false,
  emailError: false,
  emailErrorMessage: '',
  passwordValidated: false,
  passwordError: false,
  passwordErrorMessage: '',

  termsAccepted: false,
  privacyAccepted: false,

  // signUpProcess
  loading: false,
  success: false,
  error: false,
  errorMessage: '',
  provider: null,
};

export const sumUpSlice = createSlice({
  name: 'signUp',
  initialState,
  reducers: {
    signUpEmailUpdated:(state: SignUpState, action: PayloadAction<string>) => {
      return {
        ...state,
        email: action.payload,
        emailValidated: null,
        emailError: false,
        emailErrorMessage: '',
      };
    },
    signUpEmailValidated:(state: SignUpState, action: PayloadAction<{
      emailValidated: boolean | null,
      emailErrorMessage?: string,
    }>) => {
      return {
        ...state,
        emailValidated: action.payload.emailValidated,
        emailError: !action.payload.emailValidated,
        emailErrorMessage: action.payload.emailErrorMessage || '',
      };
    },
    signUpPasswordUpdated:(state: SignUpState, action: PayloadAction<string>) => {
      return {
        ...state,
        password: action.payload,
        passwordValidated: null,
        passwordError: false,
        passwordErrorMessage: '',
      };
    },
    signUpPasswordValidated:(state: SignUpState, action: PayloadAction<{
      passwordValidated: boolean | null,
      passwordErrorMessage?: string,
    }>) => {
      return {
        ...state,
        passwordValidated: action.payload.passwordValidated,
        passwordError: !action.payload.passwordValidated,
        passwordErrorMessage: action.payload.passwordErrorMessage || '',
      };
    },
    termsToggled:(state: SignUpState) => {
      state.termsAccepted = !state.termsAccepted;
    },
    privacyToggled:(state: SignUpState) => {
      state.privacyAccepted = !state.privacyAccepted;
    },
    signUpLoadingStarted:(state: SignUpState, action: PayloadAction<AuthProviders>) => {
      state.loading = true;
      state.provider = action.payload;
    },
    signUpSucceeded: () => {
      return {
        ...initialState,
        success: true,
      };
    },
    signUpFailed:(state: SignUpState, action: PayloadAction<string>) => {
      return {
        ...state,
        success: false,
        error: true,
        errorMessage: action.payload,
        loading: false,
      };
    },
    signUpErrorReset:(state: SignUpState) => {
      state.error = false;
      state.errorMessage = '';
    },
    signUpReset: () =>
      initialState
  },
});

export const {
  signUpEmailUpdated,
  signUpEmailValidated,
  signUpPasswordUpdated,
  signUpPasswordValidated,
  termsToggled,
  privacyToggled,
  signUpLoadingStarted,
  signUpSucceeded,
  signUpFailed,
  signUpErrorReset,
  signUpReset
} = sumUpSlice.actions;

export default sumUpSlice.reducer;

