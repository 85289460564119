import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Button, Divider, Space, Typography } from 'antd';
import React from 'react';
import { RowMd } from '../../../styles';
import AnimOpacity from '../../UI/Anim/AnimOpacity';
import CourtPayment from './CourtPayment';
import Fee from './Fee';
import Wps from './Wps';

const { Title, Text } = Typography;

const WpsFee = ({  wpsVisible, setWpsVisible  }:{
  setWpsVisible:(wpsVisible:boolean)=>void,
  wpsVisible: boolean,
}) => {
  return (
    <div id="wps">
      <Divider />
      <Title level={3}>{texts.title}</Title>
      <Text type="secondary">{texts.desc}</Text>
      <RowMd>
        <Space>
          <Button
            type='primary'
            icon={wpsVisible ? <DownOutlined /> : <UpOutlined />}
            onClick={() =>
              setWpsVisible(!wpsVisible)}
          >
            {texts.check}
          </Button>
        </Space>
      </RowMd>
      {wpsVisible ? (
        <AnimOpacity>
          <Wps />
          <CourtPayment />
          <Fee />
        </AnimOpacity>
      )
        : null}
    </div>
  );
};


export default WpsFee;

const texts = {
  title: 'Wartość przedmiotu sporu i opłata sądowa',
  desc: 'Typowe przypadki ustalania wps opłat i wynagrodzeń UWAGA: przeanalizuj przepisy w Twojej sprawie',
  check: 'zobacz'
};