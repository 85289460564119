import React from 'react';
import AbtVideoBot from '../components/About/Video/AbtVideoBot';
import IntroContact from '../components/Intro/IntroContact';
import IntroContactTop from '../components/Intro/IntroContactTop';
import IntroHeader from '../components/Intro/IntroHeader';
import IntroVideo from '../components/Intro/IntroVideo';
import IntroWhy from '../components/Intro/IntroWhy';
import AnimOpacity from '../components/UI/Anim/AnimOpacity';
import DescScreenFooter from '../components/UI/DescScreenFooter';
import { BLUE, BLUE_MEDIUM, ScreenLayout } from '../styles';
import { DashedDivider } from './About';

const Intro = () => {
  return (
    <AnimOpacity>
      <ScreenLayout>
        <IntroHeader />
        <IntroVideo />
        <AbtVideoBot />
        <DashedDivider color={BLUE} />
        <AbtVideoBot />
        <DashedDivider />
        <IntroWhy />
        <DashedDivider color={BLUE_MEDIUM} />
        <IntroContactTop />
        <DashedDivider />
        <IntroContact />
        <DashedDivider />
        <DescScreenFooter />
      </ScreenLayout>
    </AnimOpacity>
  );
};

export default Intro;
