import { FullscreenExitOutlined } from '@ant-design/icons';
import { Button, Descriptions, Divider, Typography } from 'antd';
import _ from 'lodash';
import React, { useEffect } from 'react';
import nextId from 'react-id-generator';

import { getIndexFromKey } from '../../../../services/assignPaymentByKey';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import { paymentDetailsKeyUpdated } from '../../../../store/lawsuitUI';
import { DescFullWidth, RowExt } from '../../../../styles';
import { formatDate } from '../../../../utils/helpers/date';
import { formatPLN } from '../../../../utils/helpers/formatNumber';
import { textIf } from '../../../../utils/helpers/textIf';
import StepCard from '../../StepCard/StepCard';
import { shortenString } from '../../StepCards/InterestForm/InterestFormTable';

const { Item: DescItem } = Descriptions;

const DetailsPayment = () => {
  const dispatch = useAppDispatch();

  const paymentItem = useTypedSelector((state) => {
    const { paymentDetailKey } = state.lawsuitUI;
    const payment = state.interestStorage.paymentData.find(({ key }) =>
      key === paymentDetailKey);
    return payment ?? null;
  });

  const interestData = useTypedSelector(state =>
    state.interestStorage.interestData);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (_.isNull(paymentItem)) return null;

  return (
    <StepCard
      title={(
        <RowExt justify="end">
          <Button
            type='dashed'
            icon={<FullscreenExitOutlined />}
            onClick={() =>
              dispatch(paymentDetailsKeyUpdated(null))}
          />
        </RowExt>
)}
      desc={(
        <Typography.Title level={4}>
          Wpłata z dnia
          {' '}
          {formatDate(paymentItem.paymentDate)}
        </Typography.Title>
      )}
      dividerDisabled
    >
      <div>
        <RowExt marginTop="md" fullWidth key={nextId()}>
          <DescFullWidth layout="horizontal" bordered key="d">
            {_.isNull(paymentItem.transferDescription) ? null : (
              <DescItem label="Tytuł wpłaty" span={3}>
                <span style={{
                  fontStyle: 'italic',
                  fontWeight: 700,
                }}
                >
                  {shortenString(paymentItem.transferDescription)}
                </span>
              </DescItem>
            )}
            <DescItem label="Dzień wpłaty" span={3}>{formatDate(paymentItem.paymentDate)}</DescItem>
            {_.isNull(paymentItem.statementDate) ? null :
            <DescItem label="Tytuł wpłaty" span={3}>{formatDate(paymentItem.statementDate)}</DescItem>}
            <DescItem label="Wysokość wpłaty" span={3}>
              <strong>{`${formatPLN(paymentItem.paymentSum)}`}</strong>
            </DescItem>

          </DescFullWidth>
        </RowExt>
        {paymentItem.assignedTo.length >= 1 ? (
          <Divider>
            Zaliczenia wpłaty na
            {' '}
            {textIf('dług', paymentItem.assignedTo.length <= 1, 'długi')}
          </Divider>
        ) : null}
        {paymentItem.assignedTo.map(paymentAssignment =>
          (
            <RowExt marginTop="md" fullWidth key={nextId()}>
              <DescFullWidth layout="horizontal" bordered key="d">
                {interestData[getIndexFromKey(interestData, paymentAssignment.key)].accountingDoc ? (
                  <DescItem label="Dokument stwierdzający dług" span={3}>
                    <span style={{
                      fontStyle: 'italic',
                      fontWeight: 700,
                    }}
                    >
                      {interestData[getIndexFromKey(interestData, paymentAssignment.key)].accountingDoc}
                    </span>
                  </DescItem>
                ) : null}
                <DescItem label="Data wymagalności długu" span={3}>
                  {formatDate(interestData[getIndexFromKey(interestData, paymentAssignment.key)].maturityDate ??
                    interestData[getIndexFromKey(interestData, paymentAssignment.key)].startingDate
                  )}
                </DescItem>
                <DescItem label="Kwoty główna długu przed zaliczeniami" span={3}>
                  {formatPLN(interestData[getIndexFromKey(interestData, paymentAssignment.key)].partialSum)}
                </DescItem>
                <DescItem
                  label={(
                    <>
                      Kwota zaliczona na roszczenie
                    </>
)}
                  span={3}
                >
                  <strong>{formatPLN(paymentAssignment.sum)}</strong>
                </DescItem>
              </DescFullWidth>
            </RowExt>
          ))}
        <Divider />
      </div>
      <RowExt justify="center" marginTop="bg">
        <Button
          type='dashed'
          icon={<FullscreenExitOutlined />}
          onClick={() =>
            dispatch(paymentDetailsKeyUpdated(null))}
        />
      </RowExt>
    </StepCard>
  );
};

export default DetailsPayment;
