import React, { useState } from 'react';
import { TitleAndDesc, ComponentChildren } from '../../@types';
import { useTypedSelector } from '../../store';
import Details from './Details/Details';
import Justification from './Justification/Justification';
import SumUpHeadMain from './SumUpHead/SumUpHeadMain';
import SumUpHeadResults from './SumUpHead/SumUpHeadResults';
import SumUpNavBar from './SumUpNavBar';
import SumUpTitle from './SumUpTitle';
import WpsFees from './WpsFees/WpsFee';


type Props = TitleAndDesc & ComponentChildren;

const SumUpData = (
  {
    children,
    title,
    desc
  }: Props
) => {

  const [detailsVisible, setDetailsVisible] = useState(true);
  const [wpsVisible, setWpsVisible] = useState(true);
  const [justificationVisible, setJustificationVisible] = useState(true);
  const loading = useTypedSelector(state =>
    state.appLoading.loading);

  if (loading){
    return null;
  }

  return (
    <>
      <SumUpTitle
        {...{
          title, desc
        }}
      />
      <SumUpHeadMain />
      <SumUpHeadResults />
      <SumUpNavBar
        showDetails={() =>
          setDetailsVisible(true)}
        showWps={() =>
          setWpsVisible(true)}
        showJustification={() =>
          setJustificationVisible(true)}
      />
      {children}
      <WpsFees
        {...{
          wpsVisible,
          setWpsVisible
        }}
      />
      <Justification
        {...{
          justificationVisible,
          setJustificationVisible
        }}
      />
      <Details
        {...{
          detailsVisible,
          setDetailsVisible
        }}
      />
    </>
  );
};

export default SumUpData;
