import { Descriptions } from 'antd';
import moment from 'moment';
import React from 'react';

import { getInterestDesc } from '../../../services/calc/interestDesc';
import { useTypedSelector } from '../../../store';
import { InterestItem } from '../../../store/interestStorage';
import { InterestTypes } from '../../../store/lawsuitOpts';
import { PartialResult } from '../../../store/sumUp';
import { RowExt } from '../../../styles';
import { DescFullWidth } from '../../../styles/ui';
import { formatDate } from '../../../utils/helpers/date';
import { formatPLN } from '../../../utils/helpers/formatNumber';

const { Item: DescItem } = Descriptions;

export const DetailsInterestItem = ({
  interestItem,
  typeOfInterest,
  partialSumLabel = 'Kwota główna'
}:{
  interestItem: InterestItem,
  typeOfInterest: InterestTypes,
  partialSumLabel?: string,
}) => {
  return (
    <RowExt marginTop="bg" fullWidth>
      <DescFullWidth layout="horizontal" bordered key={interestItem.key}>
        <DescItem label={partialSumLabel} span={3}>
          <div style={{ fontSize: 16 }}>
            <strong>{`${formatPLN(interestItem.partialSum)}`}</strong>
          </div>
        </DescItem>
        {interestItem.factualBasis ? (
          <DescItem label="Z tytułu" span={3}>
            {interestItem.factualBasis}
          </DescItem>
        ) : null}
        {interestItem.accountingDoc ? (
          <DescItem label="Dokument księgowy" span={3}>
            {interestItem.accountingDoc}
          </DescItem>
        ) : null}
        {interestItem.legalBasis ? (
          <DescItem label="Podstawa prawna" span={3}>
            {interestItem.legalBasis}
          </DescItem>
        ) : null}
        <DescItem label="Rodzaj odsetek" span={3}>
          {getInterestDesc(typeOfInterest).name}
        </DescItem>
        {interestItem.maturityDate ? (
          <DescItem label="Data wymagalności" span={3}>
            {moment(interestItem.maturityDate).format('DD-MM-YYYY')}
          </DescItem>
        ) : null}
        <DescItem label="Data początkowa">{formatDate(interestItem.startingDate)}</DescItem>
        <DescItem label="Data końcowa">{formatDate(interestItem.endDate)}</DescItem>
      </DescFullWidth>
    </RowExt>
  );
};

const DetailsPartialHead = ({
  partialResult,
  isResult
}: {
  partialResult: PartialResult,
  isResult?: boolean,
}) => {
  const typeOfInterest = useTypedSelector((state) => {
    return isResult ? state.sumUp.lawsuitOpts.typeOfInterest :
      state.lawsuitOpts.typeOfInterest;
  });
  return (
    <DetailsInterestItem
      interestItem={partialResult}
      typeOfInterest={typeOfInterest}
    />
  );
};

export default DetailsPartialHead;
