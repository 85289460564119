import { Tag } from 'antd';
import React from 'react';
import { shallowEqual } from 'react-redux';
import { getPaymentsG, getPaymentU } from '../../../services/calc/paymentCalc';
import { getWps } from '../../../services/calc/wpsCalc';
import { useTypedSelector } from '../../../store';
import { BLUE } from '../../../styles';
import ResultRow from '../SumUpHead/ResultRow';
import { WpsTitle } from './WpsTitle';


const Fee = () => {
  const {
    overallInterestsSum,
    compensationSum,
    finalSum,
  } = useTypedSelector(state =>
    state.sumUp, shallowEqual);

  return (
    <>
      <WpsTitle title={texts.title} />
      <ResultRow
        sum1={getPaymentsG(getWps(finalSum))}
        sum2={getPaymentsG(getWps(overallInterestsSum + finalSum))}
        sum3={getPaymentsG(getWps(finalSum + compensationSum + overallInterestsSum))}
        legal={texts.legal}
      />

      <WpsTitle title={texts.title} />
      <Tag color={BLUE}>{texts.badge}</Tag>
      <ResultRow
        sum1={getPaymentU(getWps(finalSum))}
        sum2={getPaymentU(getWps(overallInterestsSum + finalSum))}
        sum3={getPaymentU(getWps(finalSum + compensationSum + overallInterestsSum))}
        legal={texts.legal2}
      />
    </>
  );
};

export default Fee;

const texts = {
  title: 'Stawka minimalna opłat za czynności pełnomocnika przy WPS ustalonym w oparciu o',
  badge: 'Postępowanie upominawcze',
  legal: 'Podstawa prawna: §2 Rozporządzenia Ministra Sprawiedliwości z dnia 22 października 2015 r. w sprawie opłat za czynności radców prawnych lub §2 Rozporządzenia Ministra Sprawiedliwości z dnia 22 października 2015 r. w sprawie opłat za czynności adwokackie',
  legal2: 'Podstawa prawna: §3 Rozporządzenia Ministra Sprawiedliwości z dnia 22 października 2015 r. w sprawie opłat za czynności radców prawnych lub §3 Rozporządzenia Ministra Sprawiedliwości z dnia 22 października 2015 r. w sprawie opłat za czynności adwokackie'
};