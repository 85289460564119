import { Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { YT_EXCEL } from '../../../utils/helpers/links';
import { RowExt } from '../../../styles';
import { BLACK, RED } from '../../../styles/colors';
import Icon from '../../../styles/Icon';
import { EXCEL } from '../../../styles/iconTypes';
import CheckVideoButton from '../../UI/Button/CheckVideoButton';

const { Paragraph } = Typography;

const SubExcel = () => {
  return (
    <>
      <TitleWrapper
        color={RED}
        align="middle"
        marginTop={12}
        marginBottom={12}
      >

        <Icon
          iconType={EXCEL}
          height="20"
          width="20"
          fill={BLACK}
          style={{ marginRight:12 }}
        />
        {' '}
        <strong>
          ZMIEŃ PLIK EXCEL W TEKST POZWU / WEZWANIA DO ZAPŁATY
        </strong>
      </TitleWrapper>
      <Paragraph>
        Importuj dane z księgowości bezpośrednio do formularza. Oszczędź jeszcze więcej czasu, dodatkowo
        {' '}
        <strong>zmniejsz ryzyko pomyłki</strong>
        . Stwórz
        {' '}
        <strong>listę dowodów</strong>
        {' '}
        na bazie danych księgowych.
      </Paragraph>
      <CheckVideoButton
        url={YT_EXCEL}
        emptyButtonProps={{
          color: RED, hoverBg: RED, hoverColor: 'white'
        }}
      />
    </>
  );
};

export default SubExcel;

export const TitleWrapper = styled(RowExt)<{ color?: string }>`
    border-left: 1px solid ${({ color }) =>
    color};
    padding-left: 12px;
`;
