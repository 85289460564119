import { AppDispatch } from '../store';
import { appLoadingStarted, appLoadingTextChanged } from '../store/appLoading';
import { InterestItem, PaymentData } from '../store/interestStorage';
import { LawsuitOpts, PRINCIPAL_INTEREST, PRINCIPAL_USER, PrincipalAmountOpt } from '../store/lawsuitOpts';
import { sumUpCreated } from '../store/sumUp';
import { claimCalc, ClaimItem } from './calc/claim';
import { calcCompensation } from './calc/compensationCalc';


export const setSumUp = async ({
  lawsuitOpts,
  interestData,
  paymentData,
  dispatch,
}:{
  lawsuitOpts: LawsuitOpts,
  interestData: InterestItem[],
  paymentData?: PaymentData,
  dispatch: AppDispatch,
}) => {
  const {
    compensationOn,
    principalAmountOpt,
    principalAmount,
  } = lawsuitOpts;
  dispatch(appLoadingStarted('obliczanie'));

  if (compensationOn) dispatch(appLoadingTextChanged('ściągnie danych z serwera NBP'));

  const { compensationSum, compensationResults } = compensationOn ?
    await calcCompensation(interestData) :
    {
      compensationSum: 0,
      compensationResults: []
    };

  dispatch(appLoadingTextChanged('obliczanie'));

  const partialResults = interestData.map(interestItem => {
    return {
      ...claimCalc({
        interestItem,
        paymentData: paymentData ?? [],
        lawsuitOpts,
      }),
      compensationResult: compensationResults.find(({ key }) =>
        key === interestItem.key),
    };
  });

  dispatch(sumUpCreated({
    partialResults,
    overallInterestsSum: partialResults.reduce((prev, curr) =>
      curr.isClaimFromFulfilled ?
        (curr.interestsSumFulfilledUncovered + curr.interestsSumUncovered) + prev :
        (curr.interestsSumUncovered) + prev, 0),
    compensationSum,
    finalSum: getFinalSum(principalAmountOpt, partialResults, principalAmount),
    lawsuitOpts: { ...lawsuitOpts },
    paymentData: paymentData ?? [],
  }));
};


const getFinalSum = (
  principalAmountOpt: PrincipalAmountOpt,
  partialResults: ClaimItem[],
  principalAmount:number
) => {
  switch (principalAmountOpt) {
    case PRINCIPAL_USER:
      return principalAmount;
    case PRINCIPAL_INTEREST:
      return 0;
    default:
      return partialResults.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.claimSum;
      }, 0);
  }
};