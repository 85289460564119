import { useTypedSelector } from '../../store';
import { InterestDataProvider } from '../../store/lawsuitUI';

export const checkIsTransacOrAgree = (interestDataProvider:InterestDataProvider) =>
  interestDataProvider === 'MANUAL_PAYMENTS_ASSIGNMENTS';

const useIsPaymentAssignmentOn = () => {
  const isTransacOrAgree = useTypedSelector((state) =>
    checkIsTransacOrAgree(state.lawsuitUI.interestDataProvider));
  return isTransacOrAgree;
};

export default useIsPaymentAssignmentOn;
