import { Moment } from 'moment';
import React from 'react';
import styled from 'styled-components';
import { formatDate } from '../../../utils/helpers/date';
import { formatPLN } from '../../../utils/helpers/formatNumber';
import { setMaturityBasisText } from '../../../utils/hooks/justification/justBasis';
import { useTypedSelector } from '../../../store';
import { GreyArea } from '../../../styles/ui';


const setPreviewText = ({
  partialSum,
  maturityDate,
  legalBasis,
  factualBasis,
  accountingDoc,
}:{
  partialSum: number,
  maturityDate: Moment | string | Date | null,
  legalBasis?: string | null,
  factualBasis: string | null,
  accountingDoc: string | null,
}) => {
  if (legalBasis || factualBasis || accountingDoc){
    return setMaturityBasisText({
      legalBasis,
      factualBasis,
      accountingDoc,
      partialSum: partialSum ?? 0,
      maturityDate,
      index: 0,
      isSingle: true,
    });
  }
  const claimText = `Roszczenie w wysokości ${formatPLN(partialSum)} `;
  const maturityText = maturityDate ? ` stało się wymagalne dnia ${formatDate(maturityDate)}.`  : ' ...';
  return `${claimText + maturityText}`;
};


const BasisPreview = () => {
  const  editKey = useTypedSelector(state =>
    state.edit.key);
  const interestForm = useTypedSelector(state =>
    state.interestForm);
  const edit = useTypedSelector(state =>
    state.edit);
  return (
    <>
      <Area>
        {setPreviewText(editKey ? {
          ...edit,
          partialSum: edit.partialSum ?? 0,
        } : {
          ...{
            ...interestForm,
            partialSum: interestForm.partialSum ?? 0,
          }
        })}
      </Area>
    </>
  );
};

export default BasisPreview;

const Area = styled(GreyArea)`
  margin: 24px 0;
`;
