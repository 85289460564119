import { EditOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button, Card } from 'antd';
import _ from 'lodash';
import React from 'react';
import nextId from 'react-id-generator';
import styled from 'styled-components';

import { RowSm } from '../../../styles';
import { EvidTextTag } from '../EvidTextTag';

const { Meta } = Card;

const EvidEditPanel = ({
  icon,
  title,
  similarValues,
  onEdit,
  chosenValue,
  selectValue,
  onReset,
  areSums,
  aiValue
}:{
  icon: React.ReactNode,
  title: string
  similarValues: number[] | string[]
  onEdit: () => void
  chosenValue: string | number
  selectValue: (v: string | number) => void,
  onReset: () => void;
  areSums?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  aiValue?: any;
}) => {
  return (
    <CardStyled
      title={(
        <>
          <strong>{icon}</strong>
          <strong style={{ paddingLeft: 12 }}>{title}</strong>
        </>
      )}
      actions={[
        <Button
          key={nextId()}
          icon={<EditOutlined />}
          type="text"
          onClick={onEdit}
        />,
        <Button
          key={nextId()}
          icon={<ReloadOutlined />}
          type="text"
          onClick={onReset}
        />,
      ]}
    >
      <Meta
        title={areSums ? ` ${_.toNumber(chosenValue).toFixed(2)} zł` : chosenValue}
        description={(
          <>
            aktualna wartość
          </>
)}
      />
      <RowSm>
        {similarValues ?
          similarValues.map(value =>
            (
            // <Badge
            //   key={nextId()}
            //   color={BLUE}
            //   count={aiValue === value ? 'AI' : undefined}
            //   offset={[-10, 0]}
            //   style={{
            //     fontSize: 11,
            //     padding: '0 6px'
            //   }}
            // >
              <EvidTextTag
                key={nextId()}
                text={areSums ? _.toNumber(value).toFixed(2) : value + (aiValue ? ' - AI' : '')}
                active={value === chosenValue}
                onClick={() =>
                  selectValue(value)}
              />
            // </Badge>
            )) : null}
      </RowSm>
    </CardStyled>
  );
};

export default EvidEditPanel;

const CardStyled = styled(Card)`
    margin-bottom: 24px;
`;