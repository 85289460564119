import { Radio, RadioChangeEvent } from 'antd';
import React, { memo } from 'react';
import styled from 'styled-components';
import { INTEREST_CAPITAL_DESC,
  INTEREST_LATE_DESC,
  INTEREST_MED_PUBLIC_DESC,
  INTEREST_TRANSACTION_DESC } from '../../../services/calc/interestDesc';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { interestTypeUpdated, INTEREST_AGREE, INTEREST_CAPITAL, INTEREST_LATE, INTEREST_MED_PUBLIC, INTEREST_TRANSACTION } from '../../../store/lawsuitOpts';
import { lsIncreaseStepDisabled } from '../../../store/lawsuitUI';
import { CardPlaceholder, CardRadio, CardRadioButton, GRID_LG } from '../../../styles';
import useWindowSize from '../../../utils/hooks/useWindowSize';
import InfoRadio from '../../UI/InfoRadio';
import StepCard from '../StepCard/StepCard';
import StepOneInput from './StepOne/StepOneInput';

const TypeOfInterest = () => {

  const { width } = useWindowSize();

  const  typeOfInterest = useTypedSelector(state =>
    state.lawsuitOpts.typeOfInterest);

  const dispatch = useAppDispatch();

  const onPress = (event:RadioChangeEvent) => {
    dispatch(interestTypeUpdated(event.target.value));
    dispatch(lsIncreaseStepDisabled(false));
  };

  if (width < GRID_LG) {
    return (
      <StepCard title={texts.title} desc={texts.desc}>
        <Radio.Group
          onChange={onPress}
          value={typeOfInterest}
          style={{ width: '100%' }}
        >
          <Button2Lines
            value={INTEREST_TRANSACTION}
          >
            {texts.radio3}
          </Button2Lines>
          <CardRadioButton
            value={INTEREST_LATE}
          >
            {texts.radio1}
          </CardRadioButton>
          <CardRadioButton value={INTEREST_CAPITAL}>
            {texts.radio2}
          </CardRadioButton>
          <Button3Lines
            value={INTEREST_MED_PUBLIC}
          >
            {texts.radio5}
          </Button3Lines>
          <CardRadioButton value={INTEREST_AGREE}>
            {texts.radio6}
          </CardRadioButton>
        </Radio.Group>
        <StepOneInput />
        <CardPlaceholder />
      </StepCard>
    );
  }
  return (
    <StepCard title={texts.title} desc={texts.desc}>
      <Radio.Group
        onChange={onPress}
        value={typeOfInterest}
        style={{ width: '100%' }}
      >
        <CardRadio
          value={INTEREST_TRANSACTION}
        >
          <InfoRadio
            text={texts.radio3}
            tooltip={INTEREST_TRANSACTION_DESC.legalBasis}
          />
        </CardRadio>
        <CardRadio
          value={INTEREST_LATE}
        >
          <InfoRadio
            text={texts.radio1}
            tooltip={INTEREST_LATE_DESC.legalBasis}
          />
        </CardRadio>
        <CardRadio value={INTEREST_CAPITAL}>
          <InfoRadio
            text={texts.radio2}
            tooltip={INTEREST_CAPITAL_DESC.legalBasis}
          />
        </CardRadio>
        <CardRadio
          value={INTEREST_MED_PUBLIC}
        >
          <InfoRadio
            text={width < GRID_LG ? texts.radio5 : texts.radio4}
            tooltip={INTEREST_MED_PUBLIC_DESC.legalBasis}
          />
        </CardRadio>
        <CardRadio value={INTEREST_AGREE}>
          {texts.radio6}
        </CardRadio>
      </Radio.Group>
      <StepOneInput />
      <CardPlaceholder />
    </StepCard>
  );
};


const texts = {
  title: 'Rodzaj odsetek',
  desc: 'Wybierz rodzaj dochodzonych odsetek',
  radio1: 'Odsetki ustawowe za opóźnienie',
  radio2: 'Odsetki ustawowe (kapitałowe)',
  radio3: 'Odsetki ustawowe za opóźnienie w transakcjach handlowych',
  radio4: 'Odsetki ustawowe za opóźnienie w transakcjach handlowych - dłużnikiem jest publiczny podmiot leczniczy',
  radio5: 'Odsetki u. w transakcjach handlowych - podmiot publiczny/leczniczy',
  radio7: 'Odsetki u. w transakcjach handlowych',
  radio6: 'Odsetki umowne',
};


export default memo(TypeOfInterest);


export const Button3Lines = styled(CardRadioButton)`
  	@media only screen and (max-width: 580px) {
     padding-top: 34px;
     padding-bottom: 34px;
	}
  	@media only screen and (max-width: 355px) {
     padding-top: 52px;
     padding-bottom: 52px;
	}
`;

export const Button2Lines = styled(CardRadioButton)`
  	@media only screen and (max-width: 520px) {
      padding-top: 34px;
     padding-bottom: 34px;
	}
`;