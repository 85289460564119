import React from 'react';
import LawsuitDisplay from '../components/Lawsuit/LawsuitDisplay';
import LawsuitSteps from '../components/Lawsuit/LawsuitSteps';
import LsGuideModal from '../components/Lawsuit/Modals/LsGuideModal';
import StepActions from '../components/Lawsuit/StepCard/StepActions';
import LawsuitContainer from '../components/UI/Containers/LawsuitContainer';
import DotsEnding from '../components/UI/DotsEnding';
import NavPrivate from '../components/UI/Nav/NavPrivate';

const Lawsuit = () => {
  return (
    <LawsuitContainer>
      <LsGuideModal />
      <div>
        <NavPrivate />
        <LawsuitSteps />
        <LawsuitDisplay />
      </div>
      <div>
        <StepActions />
        <DotsEnding />
      </div>
    </LawsuitContainer>
  );
};

export default Lawsuit;
