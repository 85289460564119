import React, { useState } from 'react';
import styled from 'styled-components';
import { BLUE, BLUE_HOVER } from '../../../styles';
import Icon from '../../../styles/Icon';

const IconButton = (
  {
    height = '16',
    width = '16',
    iconType,
    iconHoover,
    fill = BLUE,
    fillHover = BLUE_HOVER,
    stroke,
    style,
    href
  }: {
    height?: string,
    width?: string,
    iconType: string,
    iconHoover?: string,
    style?: object,
    fill?: string,
    fillHover?: string,
    stroke: boolean,
    href: string
  }
) => {
  const [hoverOn, setHover] = useState(false);
  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer"
    >
      <span
        onMouseEnter={() =>
          setHover(true)}
        onMouseLeave={() =>
          setHover(false)}
      >
        <HoverSpace
          height={height}
          width={width}
          iconType={!hoverOn ? iconType : (iconHoover ?? iconType)}
          fill={!hoverOn ? fill : (fillHover ?? fill)}
          stroke={stroke ? fill : undefined}
          style={style}
        />
      </span>
    </a>
  );
};

export default IconButton;

const HoverSpace = styled(Icon)`
   cursor: pointer;
`;
