import { CalendarOutlined, CheckSquareOutlined, CloseSquareOutlined, FullscreenOutlined } from '@ant-design/icons';
import { Col, Divider, Space } from 'antd';
import moment from 'moment';
import React, { useMemo } from 'react';
import { ArcherElement } from 'react-archer';
import { shallowEqual, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { claimCalc } from '../../../../services/calc/claim';
import { useTypedSelector } from '../../../../store';
import { InterestItem } from '../../../../store/interestStorage';
import { claimDetailsKeyUpdated } from '../../../../store/lawsuitUI';
import { BLACK, ButtonFullWidth, RowExt } from '../../../../styles';
import { BLUE } from '../../../../styles/colors';
import Icon from '../../../../styles/Icon';
import { formatPLN } from '../../../../utils/helpers/formatNumber';
import AssignGraphContainer from '../AssignGraphContainer';
import { AssignmentDesc, AssignmentText } from '../AssignmentText';
import ClaimGraph from './ClaimGraph';

export const ClaimMain = ({
  interestItem,
  isMarginTopReq,
  paymentsColors,
  onClaimClick,
  setFulfilledModalVisible,
}:{
  interestItem: InterestItem
  isMarginTopReq?: boolean,
  paymentsColors: Map<string, string>,
  onClaimClick: () => void,
  setFulfilledModalVisible:(bool: boolean)=> void,
}) => {
  const {
    maturityDate,
    startingDate,
    accountingDoc,
    key: interestItemKey,
    isClaimFromFulfilled,
  } = interestItem;

  const dispatch = useDispatch();

  const { paymentData } = useTypedSelector(state =>
    state.interestStorage, shallowEqual);


  const lawsuitOpts = useTypedSelector(state =>
    state.lawsuitOpts, shallowEqual);

  const onShowDetails = () => {
    dispatch(claimDetailsKeyUpdated(interestItemKey));
  };

  const {
    interestsSumFulfilledUncovered,
    interestsSumUncovered,
    factualBasis,
  } = useMemo(() =>
    claimCalc({
      lawsuitOpts,
      paymentData,
      interestItem,
    }), [
    lawsuitOpts,
    paymentData,
    interestItem
  ]);

  return (
    <ArcherElement
      id={interestItemKey}
    >
      <Container
        role="document"
      >
        <RowExt
          marginTop={isMarginTopReq ? 12 : 0}
          onClick={onClaimClick}
        >
          <ClaimItemContainer>
            <RowExt marginTop="sm">
              <AssignGraphContainer>
                <ClaimGraph
                  {...{
                    paymentsColors,
                    interestItemKey,
                    partialSum: interestItem.partialSum + interestsSumFulfilledUncovered + interestsSumUncovered,
                  }}
                />
              </AssignGraphContainer>
              <Col span={22}>
                <AssignmentDesc
                  text={accountingDoc || factualBasis}
                />
                <AssignmentText
                  title={maturityDate ?
                    moment(maturityDate).format('YYYY-MM-DD') :
                    moment(startingDate).format('YYYY-MM-DD')}
                  marginTop={0}
                  icon={(
                    <CalendarOutlined
                      style={{
                        marginRight: 6, fontWeight: 8, paddingTop: 3
                      }}
                    />
  )}
                  icon2={(
                    <IconStyled
                      height="16"
                      width="16"
                      fill={BLACK}
                      stroke={BLACK}
                      iconType="MONEY_PACK"
                      style={{ marginRight: 6 }}
                    />
)}
                  title2={formatPLN(interestItem.partialSum)}
                />
                <DividerStyled />
                <RowExt align="middle">
                  <Col span={11}>
                    <ButtonFullWidth
                      icon={(
                        <IntrBtn
                          isOn={isClaimFromFulfilled}
                        />
)}
                      type="text"
                      onClick={() =>
                        setFulfilledModalVisible(true)}
                    />
                  </Col>
                  <Col span={2}>
                    <Divider
                      type='vertical'
                    />
                  </Col>
                  <Col span={11}>
                    <ButtonFullWidth
                      icon={<FullscreenOutlined />}
                      type="text"
                      onClick={onShowDetails}
                    />
                  </Col>
                </RowExt>
              </Col>
            </RowExt>
          </ClaimItemContainer>
        </RowExt>
      </Container>
    </ArcherElement>
  );
};

export const IntrBtn = ({ isOn }:{
  isOn:boolean
}) =>
  ((
    <SpaceStyled
      isOn={isOn}
    >
      {isOn ? <CheckSquareOutlined /> : <CloseSquareOutlined  />}
      %
    </SpaceStyled>
  ));

const ClaimItemContainer = styled.div`
   width: 100%;
   border: 1px solid rgba(66,71,112,0.09);
   padding: 12px 12px 12px 12px;
   border-radius: 4;
`;

export const IconStyled = styled(Icon)`
    margin-right: 2px;
    margin-bottom: -2px;
`;

export const DividerStyled = styled(Divider)`
    margin-top: 12px;
    margin-bottom: 12px;
`;

const Container = styled.div`
    border-radius: 2px; 
    width: 100%;
`;

const SpaceStyled = styled(Space)<{ isOn: boolean }>`
  color: ${props =>
    props.isOn ? BLUE : 'inherit'};

`;