import { Col, Row } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { ComponentChildren } from '../../../@types';
import { GREY_WHITE } from '../../../styles';

const SloganContainer = ({ children }: ComponentChildren) => {
  return (
    <ColAllPage
      span={14}
    >
      <RowAllPage
        justify="center"
      >
        <Container>
          <ContainerColumn>
            {children}
          </ContainerColumn>
        </Container>
      </RowAllPage>
    </ColAllPage>
  );
};

export default SloganContainer;

const ColAllPage = styled(Col)`
    background: ${GREY_WHITE};
    height: 100vh;
`;

const RowAllPage = styled(Row)`
   height: 100%
`;

const Container = styled.div`
  width: 840px;
`;

const ContainerColumn = styled.div`
    height: 100%;
    justify-content: space-between;
    width: 100%;
    display: flex;
    flex-direction: column;
`;