export function isNumeric(str: string) {
  if (typeof str != 'string') return false; // we only process strings!
  const formattedStr = str
    .replace(/,/g, '.')
    .replace(/\s/g, '')
    .replace('zł', '')
    .replace('PLN', '')
    .replace('ZŁ', '');
  // @ts-ignore
  return !isNaN(formattedStr) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
         !isNaN(parseFloat(formattedStr)); // ...and ensure strings of whitespace fail
}