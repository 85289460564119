import React from 'react';
import { BLUE_MEDIUM } from '../../../styles';
import SubOptsHeader from './SubOptsHeader';

const StandardHeadText = () =>
  (
    <>
      Wiesz, że aplikacja byłaby pomocna, a
      {' '}
      <strong>nie masz czasu,</strong>
      {' '}
      aby wprowadzać w nią
      pracowników Twojej kancelarii.
    </>
  );

const SubStandardHead = ({ inCard }: { inCard?: boolean }) => {
  return (
    <SubOptsHeader color={BLUE_MEDIUM} inCard={inCard}>
      <StandardHeadText />
    </SubOptsHeader>
  );
};

export default SubStandardHead;
