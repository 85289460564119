import { ArrowDownOutlined,
  CalendarOutlined,
  CloseCircleTwoTone,
  DeleteOutlined,
  FullscreenOutlined,
  LeftOutlined,
  RightOutlined } from '@ant-design/icons';
import { Col, Divider, Popconfirm } from 'antd';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

import { useAppDispatch } from '../../../../store';
import { PaymentItem } from '../../../../store/interestStorage';
import { paymentDetailsKeyUpdated } from '../../../../store/lawsuitUI';
import { BLACK, ButtonFullWidth, RowExt } from '../../../../styles';
import Icon from '../../../../styles/Icon';
import { formatPLN } from '../../../../utils/helpers/formatNumber';
import AssignGraphContainer from '../AssignGraphContainer';
import { AssignmentDesc, AssignmentText } from '../AssignmentText';
import { DividerStyled } from '../Claim/ClaimMain';

const PaymentAssignmentMain = ({
  paymentItem,
  paymentKey,
  paymentsColors,
  togglePayment,
  removeAssignment,
  removePayment,
}:{
  togglePayment: ()=>void,
  removeAssignment: () => void,
  removePayment: () => void,
  paymentItem: PaymentItem
  paymentKey: string | null,
  paymentsColors: Map<string, string>
}) => {
  const dispatch = useAppDispatch();

  const {
    key,
    paymentDate,
    paymentSum,
    assignedTo,
    transferDescription,
  } = paymentItem;

  const amountOfAssigned = assignedTo.reduce((prev, { sum }) => {
    return prev + sum;
  }, 0);

  const percentOfAssigned = amountOfAssigned !== 0 ? amountOfAssigned / paymentSum * 100 : 0;

  return (
    <>
      <RowExt>
        <ItemContainer isShadowEnabled={paymentKey === key}>
          <RowExt marginTop="sm">
            <AssignGraphContainer>
              <div
                key={key}
                style={{
                  width: 3,
                  background:paymentsColors.get(key),
                  height: `${percentOfAssigned}%`,
                }}
              />
            </AssignGraphContainer>
            <Col span={22}>
              <AssignmentDesc
                text={transferDescription}
              />
              <AssignmentText
                title={moment(paymentDate).format('YYYY-MM-DD')}
                marginTop={0}
                icon={(
                  <CalendarOutlined
                    style={{
                      marginRight: 6, fontWeight: 8, paddingTop: 3
                    }}
                  />
  )}
                icon2={(
                  <IconStyled
                    height="16"
                    width="16"
                    fill={BLACK}
                    stroke={BLACK}
                    iconType="MONEY_PACK"
                    style={{ marginRight: 6 }}
                  />
)}
                title2={formatPLN(paymentSum)}
              />
              <AssignmentText
                title={formatPLN(amountOfAssigned)}
                marginTop={12}
                icon={(
                  <ArrowDownOutlined
                    style={{
                      marginRight: 6, fontWeight: 8, paddingTop: 3
                    }}
                  />
  )}
                icon2={<span style={{ marginRight: 6 }}>%</span>}
                title2={`${percentOfAssigned ? percentOfAssigned.toFixed(2) : 0} %`}
              />
              <DividerStyled />
              <RowExt align="middle">
                <Col span={11}>
                  <ButtonFullWidth
                    icon={<LeftOutlined />}
                    onClick={togglePayment}
                    type="link"
                    style={{
                      paddingRight: 0,
                      paddingLeft: 0,
                    }}
                  >
                    <span>
                      Zalicz wpłatę
                      {' '}
                    </span>
                    {paymentKey === key ? (
                      <CloseCircleTwoTone style={{ marginLeft: 12 }} />
                    ) : null}
                  </ButtonFullWidth>
                </Col>
                <Col span={1}>
                  <Divider
                    type='vertical'
                  />
                </Col>
                <Col span={4}>
                  <ButtonFullWidth
                    icon={<RightOutlined />}
                    onClick={removeAssignment}
                    type="text"
                  />
                </Col>
                <Col span={4}>
                  <ButtonFullWidth
                    icon={<FullscreenOutlined />}
                    onClick={() =>
                      dispatch(paymentDetailsKeyUpdated(key))}
                    type="text"
                  />
                </Col>
                <Col span={4}>
                  <Popconfirm
                    title="Usunąć, jesteś pewna/pewny?"
                    onConfirm={removePayment}
                  >
                    <ButtonFullWidth
                      icon={<DeleteOutlined />}
                      type="text"
                    />
                  </Popconfirm>
                </Col>
              </RowExt>
            </Col>
          </RowExt>
        </ItemContainer>
      </RowExt>
    </>
  );
};

export default PaymentAssignmentMain;


const ItemContainer = styled.div<{ isShadowEnabled: boolean }>`
   width: 100%;
   border: 1px solid rgba(66,71,112,0.09);
   box-shadow: ${(props) =>
    props.isShadowEnabled ?  '0px  8px 8px rgba(0, 0, 0, 0.11)' : 'none'};
   padding: 12px;
   border-radius: 4;
`;

const IconStyled = styled(Icon)`
    margin-right: 2px;
    margin-bottom: -2px;
`;