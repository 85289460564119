import useIsPaymentAssignmentOn from './useIsPaymentAssignmentOn';
import useIsTransacOrAgree from './useIsTransacOrAgree';

const useGetNumberOfSteps = () => {
  const isTransacOrAgree = useIsTransacOrAgree();
  const isPaymentAssignmentOn = useIsPaymentAssignmentOn();
  // counted from 0
  if (!isTransacOrAgree && !isPaymentAssignmentOn) return 5;
  if (isTransacOrAgree && !isPaymentAssignmentOn) return 6;
  if (!isTransacOrAgree && isPaymentAssignmentOn) return 6;
  return 7;
};

export default useGetNumberOfSteps;