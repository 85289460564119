import { Col, Row, Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';
import AboutHeader from '../components/About/AboutHeader';
import AbtTitle from '../components/About/AbtTitle';
import AnimOpacity from '../components/UI/Anim/AnimOpacity';
import IconButton from '../components/UI/Button/IconButton';
import DotsEnding from '../components/UI/DotsEnding';
import { LINK_FB, LINK_LI } from '../utils/helpers/links';
import useWindowSize from '../utils/hooks/useWindowSize';
import { AboutContainer, BLUE, DashedColLeft, GREY_WHITE, GRID_MD_PX, GRID_SM_PX, RowExt, RowMd, ScreenLayout } from '../styles';
import { RED } from '../styles/colors';
import { FACEBOOK, FACEBOOK_SOLID, LINKED_IN, LINKED_IN_SOLID } from '../styles/iconTypes';


const Author = () => {
  const { width } = useWindowSize();
  return (
    <AnimOpacity>
      <ScreenLayout>
        <AboutHeader />
        <DashedDivider />
        <AboutContainer>

          <RowExt id="author">
            <Col
              lg={{
                span: 7, offset:1
              }}
              md={{ span: 8 }}
              xs={{
                offset: 2,
                span: 20,
              }}
              style={{ background: GREY_WHITE }}
            >
              <Row justify="center">
                <TextWrapper>
                  <TextMono>
                    Dziękuję bardzo za uwagę.
                    <br />
                    Wszelki feedback jest mile
                    <br />
                    widziany. Chętnie wyjaśnię
                    <br />
                    wszystkie wątpliwości.
                  </TextMono>
                </TextWrapper>
              </Row>
            </Col>
            <TextCol
              xl={{
                span: 14, offset: 0
              }}
              lg={{
                span: 13, offset: 0
              }}
              md={{
                span: 14, offset:0
              }}
              xs={{
                offset: 1,
                span: 21,
              }}
            >
              <DashedDivider />
              <Row>
                <Col offset={2} span={20}>
                  <AbtTitle
                    title="Autor"
                    desc={(
                      <>
                        <RowMd>
                          <Col span={12}>
                            <Typography.Text>
                              Dane:
                            </Typography.Text>
                          </Col>
                          <Col span={12}>
                            <BoldText>
                              radca prawny Tadeusz Mięsowicz
                            </BoldText>
                          </Col>
                        </RowMd>
                        <RowMd>
                          <Col span={12}>
                            <Typography.Text>
                              Social Media i kontakt:
                            </Typography.Text>
                          </Col>
                          <Col span={12}>
                            <IconButton
                              href={LINK_FB}
                              iconType={FACEBOOK}
                              iconHoover={FACEBOOK_SOLID}
                              stroke
                              style={{ marginTop:3 }}
                            />
                            <IconButton
                              href={LINK_LI}
                              iconType={LINKED_IN}
                              iconHoover={LINKED_IN_SOLID}
                              stroke
                              style={{
                                marginLeft:24, marginRight: 24
                              }}
                            />
                            <Typography.Text>
                              795 430 450
                            </Typography.Text>
                          </Col>
                        </RowMd>
                      </>
               )}
                  />
                </Col>
              </Row>
            </TextCol>
            <DashedColLeft
              xl={{ span: 2 }}
              lg={{ span: 3 }}
              xs={0}
            />
          </RowExt>
        </AboutContainer>
        <DashedDivider color={BLUE} />
        <AboutContainer>
          <RowExt id="author">
            <Col
              lg={{
                span: 7, offset:1
              }}
              md={{ span: 8 }}
              xs={{
                offset: 2,
                span: 20,
              }}
            />
            <TextCol
              xl={{
                span: 14, offset: 0
              }}
              lg={{
                span: 13, offset: 0
              }}
              md={{
                span: 14, offset:0
              }}
              xs={{
                offset: 1,
                span: 21,
              }}
              style={{ background: GREY_WHITE }}
            >
              <Row justify="center">
                <TextWrapper>
                  <TextMono>
                    Jeśli Państwu podoba się
                    <br />
                    pozew.app zapraszam do śledzenia
                    <br />
                    i kontaktu przez social media.
                    <br />
                    Na potrzeby dyskusji o projekcie
                    <br />
                    planuję także uruchomienie serwera Discord.
                  </TextMono>
                </TextWrapper>
              </Row>
            </TextCol>
            <DashedColLeft
              xl={{ span: 2 }}
              lg={{ span: 3 }}
              xs={0}
            />
          </RowExt>
        </AboutContainer>
        <DashedDivider color={RED}  />
        <DashedDivider style={{ marginTop: 48 }} />

        {width < 992 ? (
          <DashedDivider style={{ marginTop:48 }} />
        ) : null}
        <DashedDivider />
        <AboutContainer style={{ marginTop: 48 }}>
          <DotsEnding />
        </AboutContainer>
      </ScreenLayout>
    </AnimOpacity>
  );
};

export default Author;

const TextWrapper = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   height: 384px;
   @media only screen and (max-width: ${GRID_MD_PX}) {
    height: 234px;
	}
`;

const DashedDivider = styled.div<{ color?: string }>`
    width: 100%;
    border-bottom: 1px dashed ${props =>
    props.color || 'rgba(66,71,112,0.09)'};
`;

const BoldText = styled(Typography.Text)`
    font-weight: 700;
`;

const TextCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media only screen and (max-width: ${GRID_MD_PX}) {
    font-size: 12px;
	}
  @media only screen and (max-width: 767px) {
    margin-top: 48px;
	}

`;

const TextMono = styled.div`
   font-family: 'Fira Mono';
   font-size: 18px;
   text-align: center;
   font-weight: 700;
   letter-spacing: 2px;
   @media only screen and (max-width: ${GRID_MD_PX}) {
    font-size: 12px;
	}
  @media only screen and (max-width: ${GRID_SM_PX}) {
    font-size: 16px;
	}
`;