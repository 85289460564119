import { FirebaseError } from '@firebase/util';
import { notification } from 'antd';
import { deleteUser as authDeleteUser, EmailAuthProvider, reauthenticateWithCredential, reauthenticateWithPopup, User } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import { AppDispatch } from '../../store';
import { errMsgGeneral, errMsgWithCode } from '../../utils/helpers/errorTexts';
import { withAppLoading } from '../../utils/helpers/withAppLoading';
import { auth } from '../firebase/auth';

const google = new firebase.auth.GoogleAuthProvider;
const facebook = new firebase.auth.FacebookAuthProvider;

export const deleteUser = ({
  history,
  closeModal,
  password,
  providerId,
  dispatch,
}:{
  history: {
    push: (location: string) => void;
  },
  closeModal: ()=> void;
  password?:string;
  providerId: string,
  dispatch: AppDispatch
}) =>
  withAppLoading({
    dispatch, startLoadingText: 'usuwanie konta'
  }, async () => {
    const isExtProvider = providerId === 'facebook.com' || providerId  === 'google.com';
    const user = auth.currentUser;
    if (user){
      try {
        isExtProvider ?
          await reauthenticateWithPopup(user, providerId === 'google.com' ? google : facebook) :
          await reauthenticateEmail(user, password);

        await authDeleteUser(user);

        history.push('/');

        notification.info({
          message: 'Konto usunięte',
          description: 'Twoje dane i wyliczenia zostały usunięte z serwerów',
          duration: 8000,
        });

      } catch (error:unknown){
        if (error instanceof FirebaseError){
          if (error.code === 'auth/wrong-password'){
            notification.error({ message: 'Podałaś/eś niewłaściwe hasło' });
          } else if (error.code !== 'auth/popup-closed-by-user' && error.code !== 'auth/cancelled-popup-request'){
            notification.error({
              message: errMsgGeneral,
              description: errMsgWithCode(error.code),
            });
          }
        }
      }
    } else {
      notification.info({
        message: 'Konto nie istnieje',
        description: 'Prawdopodobnie nie jesteś zalogowana/y',
        duration: 5000,
      });
    }
    closeModal();
  });



const reauthenticateEmail = async (user: User, password?: string) => {
  const credentials = EmailAuthProvider.credential(user.email ?? '', password ?? '' );
  await reauthenticateWithCredential(user, credentials);
};
