import { SplitCellsOutlined } from '@ant-design/icons';
import { Button, Col, Image, Row, Typography } from 'antd';
import React from 'react';
import nextId from 'react-id-generator';
import styled from 'styled-components';

import { DocPage } from '../../../store/impOcr';
import { GREY_BORDER, RowBg, RowMd } from '../../../styles';
import AnimOpacity from '../../UI/Anim/AnimOpacity';
import LawContainer from '../../UI/Containers/LawContainer';
import DotsEnding from '../../UI/DotsEnding';
import NavPrivate from '../../UI/Nav/NavPrivate';
import EvidEditActionBar from './EvidEditActionBar';


export const EvidEditForm = ({
  pages,
  title,
  onConfirm,
  onClose,
  children,
  onSplit,
}:{
  pages: DocPage[],
  title: string,
  onConfirm: () => void
  onClose: () => void
  children?: React.ReactNode
  onSplit: (pageNumber: number) => void
}) => {
  return (
    <AnimOpacity>
      <LawContainer>
        <NavPrivate />
        <EvidEditActionBar
          {...{
            title,
            onConfirm,
            onClose
          }}
        />
        <RowBg>
          <Col span={15}>
            {pages.map(value =>
              (
                <>

                  <ImageStyled
                    key={nextId()}
                    src={value.image}
                  />
                  {pages.length > 1 ? (
                    <RowMd justify='center'>
                      <Col offset={2} span={20}>
                        <Row justify="center">
                          <PageNumber>
                            strona
                            {' '}
                            {value.pageNumber}
                          </PageNumber>
                        </Row>
                      </Col>
                      <Col span={2}>
                        <Button
                          icon={<SplitCellsOutlined />}
                          type="dashed"
                          onClick={value.pageNumber ? () =>
                            onSplit(value.pageNumber as number) : () =>
                            null}
                        />
                      </Col>
                    </RowMd>
                  ) : null}
                </>
              ))}
            <RowBg />
          </Col>
          <Col offset={1} span={8}>
            {children}
          </Col>
        </RowBg>
        <DotsEnding />
      </LawContainer>
    </AnimOpacity>
  );
};

const ImageStyled = styled(Image)`
  border: 1px solid ${GREY_BORDER};
  border-radius: 2px;  
`;

export const PageNumber = styled(Typography.Paragraph)`
    font-family: Fira Mono;
    font-weight: 500;
`;