/* ----------------------------------- SM ----------------------------------- */

export const LINK_TWITTER = 'https://twitter.com/tadeuszmie';
export const LINK_LI = 'https://www.linkedin.com/in/miesowicz/';
export const LINK_FB = 'https://www.facebook.com/pozew.app/';
export const LINK_YT = 'https://www.youtube.com/channel/UCUQEQi7L3zkGHlnbLMf8-xQ';
export const LINK_VIDEO = 'https://firebasestorage.googleapis.com/v0/b/pozewapp.appspot.com/o/PozewOutroTraining_2.mp4?alt=media&token=96c6edd6-de06-4ade-9c1f-af4626ccad3f';
export const LINK_TRAINING = 'https://firebasestorage.googleapis.com/v0/b/pozewapp.appspot.com/o/Training.mp4?alt=media&token=f95bfd79-8666-46d0-a5fc-420b8d06051e';

/* --------------------------------- STRIPE --------------------------------- */

export const SUCCESS_URL_PROD = 'https://pozew.app/paymentSuccess';
export const SUCCESS_URL_STAGING = 'https://stagingpozew.web.app/paymentSuccess';
export const SUCCESS_URL_DEV = 'http://localhost:3000/paymentSuccess';
export const CANCEL_URL_PROD = 'https://pozew.app/subscribe';
export const CANCEL_URL_STAGING = 'https://stagingpozew.web.app/subscribe';
export const CANCEL_URL_DEV = 'http://localhost:3000/subscribe';

/* -------------------------------- YOU TUBE -------------------------------- */

export const YT_CODES = 'https://youtu.be/YkohiD4PPK4';
export const YT_EXCEL = 'https://youtu.be/-z9K0grgpDA';
export const YT_CURRENCY = 'https://youtu.be/BbGvuHHDvmA';
export const YT_MENAGE_USERS = 'https://youtu.be/XBt0ng3ZfXk';
export const YT_CODES_EXPLAIN = 'https://youtu.be/ObOz01OOkpY';
export const YT_INTR_FORM_GUIDE = 'https://youtu.be/FEjObR8OscI';
export const YT_PAYMENTS_GUIDE = 'https://youtu.be/TSPAMWoSYik';

export const LINK_FOLDER_GENERAL = 'https://firebasestorage.googleapis.com/v0/b/pozewapp.appspot.com/o/pozew-folder.pdf?alt=media&token=8545f1ed-4d81-41e4-a227-67ac55307e31';
export const LINK_FOLDER_CODES = 'https://firebasestorage.googleapis.com/v0/b/pozewapp.appspot.com/o/pozew-kody-wyliczen%CC%81.pdf?alt=media&token=315790b7-d636-47d0-b628-65d2c7c68d37';

export const LINK_EXCEL_EXAMPLE = 'https://firebasestorage.googleapis.com/v0/b/pozewapp.appspot.com/o/pozew_import_example%20-more.xlsx?alt=media&token=96c26b2a-79a0-4053-b12d-5a15a7977bca';