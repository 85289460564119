import { ArrowDownOutlined, ArrowRightOutlined, ArrowUpOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Popconfirm, Row, Space, Typography } from 'antd';
import moment from 'moment';
import React, { memo } from 'react';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import { endDateUpdated } from '../../../../store/interestForm';
import { isSameDateUpdated, sameEndDateUpdated } from '../../../../store/interestFormOptions';
import { interestAllDataChanged } from '../../../../store/interestStorage';
import { BLUE, GRID_LG, GRID_SM, RowExt } from '../../../../styles';
import useWindowSize from '../../../../utils/hooks/useWindowSize';
import { ColRes } from './InterestFormAddButton';

const { Text } = Typography;

const OptionsSameDate = () => {
  const { width } = useWindowSize();
  const  { sameEndDate, isSameEndDate }
  = useTypedSelector(state =>
    state.interestFormOptions);

  const  interestData = useTypedSelector(state =>
    state.interestStorage.interestData);

  const datePickerStyle = {
    width:'100%', borderColor:  isSameEndDate ? BLUE : '#d9d9d9',
  };

  const dispatch = useAppDispatch();


  const resetSameEndDate = () => {
    dispatch(isSameDateUpdated(false));
    dispatch(sameEndDateUpdated(null));
    dispatch(endDateUpdated(null));
  };

  const setToday = () => {
    dispatch(isSameDateUpdated(true));
    dispatch(sameEndDateUpdated(moment().endOf('day')));
    dispatch(endDateUpdated(moment().endOf('day')));
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setSameEndDate = (value:any) => {
    if (value === null){
      dispatch(isSameDateUpdated(false));
      dispatch(sameEndDateUpdated(value.endOf('day')));
      dispatch(endDateUpdated(value.endOf('day')));
    } else {
      dispatch(isSameDateUpdated(true));
      dispatch(sameEndDateUpdated(value.endOf('day')));
      dispatch(endDateUpdated(moment().endOf('day')));
    }
  };
  // TODO: check for all starting dates
  const onRefactorOldDates = () => {
    if (interestData && isSameEndDate && sameEndDate){
      const interestDataWithSameDates = interestData.map((interestItem) => {
        return  {
          ...interestItem,
          endDate: sameEndDate
        };
      });
      dispatch(interestAllDataChanged(interestDataWithSameDates));
    }
  };

  const refactorDisabled = (interestData === null || !isSameEndDate);

  return (
    <RowExt align="middle">
      <Col
        lg={{
          offset:0,
          span:6,
        }}
        sm={{ span: 8 }}
        xs={{ span: 24 }}
      >
        <Row justify={width < GRID_SM ? 'center' : 'start'}>
          <Space>
            <Text>
              {texts.opt1}
            </Text>
            {width < GRID_SM ? <ArrowDownOutlined /> :
            <ArrowRightOutlined />}
          </Space>
        </Row>
      </Col>
      <ColRes
        lg={{
          offset:0,
          span:6,
        }}
        sm={{ span: 8 }}
        xs={{ span: 24 }}
      >
        <DatePicker
          allowClear
          style={datePickerStyle}
          format="DD-MM-YYYY"
          value={isSameEndDate ? sameEndDate : null}
          onChange={setSameEndDate}
        />
      </ColRes>
      <ColRes
        lg={{
          offset:0,
          span:12,
        }}
        sm={{ span: 8 }}
        xs={{ span: 24 }}
      >
        <Row justify={width < GRID_SM ? 'space-around' : 'end'}>
          <Space>
            <Button type='dashed' onClick={setToday}>
              {texts.today}
            </Button>
            <Button type='dashed' onClick={resetSameEndDate} icon={<CloseOutlined />} />
            <Popconfirm
              placement="top"
              title={texts.sure}
              onConfirm={onRefactorOldDates}
              okText="Tak"
              cancelText="Nie"
            >
              <Button
                type='dashed'
                disabled={refactorDisabled}
                icon={<ArrowUpOutlined />}
              >
                {width >= GRID_LG ? texts.up : null}
              </Button>
            </Popconfirm>
          </Space>
        </Row>
      </ColRes>
    </RowExt>
  );
};


export default memo(OptionsSameDate);


const texts = {
  opt1: 'Ustal jedną datę końcową',
  today: 'Dzisiaj',
  up: 'Zastosuj do dotychczasowych',
  sure: 'Zastosuj wybraną datę do istniejących wpisów.Jesteś pewna/pewien ?',
};
