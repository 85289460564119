import { Col } from 'antd';
import React from 'react';
import { AboutContainer, AbtSpaceRow, DashedColLeft, DashedColRight } from '../../styles';

const AbtTwoLinesBot = () => {
  return (
    <AboutContainer>
      <AbtSpaceRow>
        <DashedColRight
          xl={{ span: 20 }}
          lg={{ span: 20 }}
          xs={24}
        />
        <Col
          xl={{ span: 2 }}
          lg={{ span: 1 }}
          xs={0}
        />
        <DashedColLeft
          xl={{ span: 2 }}
          lg={{ span: 3 }}
          xs={0}
        />
      </AbtSpaceRow>
    </AboutContainer>
  );
};

export default AbtTwoLinesBot;
