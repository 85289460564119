import { notification } from 'antd';
import { sendEmailVerification } from 'firebase/auth';
import { auth } from '../firebase/auth';
import { errMsgGeneral } from '../../utils/helpers/errorTexts';
import { withUser } from '../../utils/helpers/withAppLoading';


export const sendConfirmationEmail =  (setLoading: (loading:boolean)=> void) => {
  const { currentUser } = auth;
  withUser(currentUser, async (user) => {
    try {
      setLoading(true);
      await sendEmailVerification(user);
      setLoading(false);
      notification.info({
        message: 'Email wysłany',
        description: 'Proszę sprawdź skrzynkę pocztową. Gdyby pojawił się problem - sprawdź proszę folder spamu.',
        duration: 10000,
      });
    } catch (error) {
      setLoading(false);
      notification.error({
        message: errMsgGeneral,
        description: 'Zbyt wiele prób zostało podjęte w zbyt krótkim czasie'
      });
    }
  });
};
