const pozewapp = '<span class="dark-blue">pozew.app</span>';

export const par1 = {
  title: '§1 Postanowienia wstępne',
  pars: [
    { text: `1.Aplikacja webowa ${pozewapp} (zwana także POZEW) pod adresem https://pozew.app jest udostępniana Użytkownikom przez EoD Code Sp. z o.o. adres: Chodkiewicza 6/15, 31-523, Kraków, Polska, KRS: 0000800125 (zwana dalej Usługodawcą). Założycielem EoD Code Sp. z o.o. i prezesem zarządu spółki jest autor pozew.app Tadeusz Mięsowicz.` },
    { text: '2.Każda osoba korzystająca z usług dostarczanych przez Usługodawcę w ramach pozew.app jest Usługobiorcą (zwanym też Użytkownikiem).' },
    { text: '3.Pozew.app działa na zasadach określonych w niniejszym regulaminie a Użytkownik korzystający z aplikacji jest obowiązany do przestrzegania jego postanowień. Regulamin reguluje podstawy prawne korzystania z pozew.app.' },
    { text: '4.Przed rozpoczęciem korzystania z pozew.app Użytkownik jest zobowiązany zapoznać się z treścią Regulaminu. Korzystając z pozew.app Użytkownik oświadcza, że zapoznał się z treścią Regulaminu oraz akceptuje zawarte w nim postanowienia, a także zobowiązuje się do ich przestrzegania.' },
  ],
};

export const par2 = {
  title: '§2 Usługi',
  pars1: [
    { text: '1.Poprzez pozew.app Użytkownik może korzystać z następujących usług (zwanych dalej Usługami):' },
  ],
  usts:[
    { text: '1) wykonywania wyliczeń związanych z tworzeniem pozwów między innymi takich jak: kapitalizacja odsetek czy obliczanie rekompensat przysługujących na podstawie Ustawy o przeciwdziałaniu nadmiernym opóźnieniom w transakcjach handlowych;' },
    { text: '2) przechowywania wyników wyliczeń na serwerze pozew.app w oparciu o indywidualne konto Użytkownika;' },
    { text: '3) zapisywania i odczytywania wyników wyliczeń z urządzenia lokalnego (komputer, telefon).' },
  ],
  pars2: [
    { text: '2.Usługi są świadczone w oparciu o umowę o świadczenie usług zawartą pomiędzy Użytkownikiem a Usługodawcą. Zasady składania oświadczeń woli dotyczących powstania i wygaśnięcia umowy reguluje ust. 3 i 4 poniżej.' },
    { text: 'Z zastrzeżeniem ust. 4 poniżej do zawarcia umowy o świadczenie usług pomiędzy Usługodawcą a Użytkownikiem dochodzi z momentem wpisania https://pozew.app w przeglądarce internetowej. Do jej rozwiązania z momentem zamknięcia strony https://pozew.app.' },
    { text: '4.Do zawarcia umowy o świadczenie usług dochodzi także w momencie założenia konta pozew.app przez Użytkownika. Usunięcie konta pozew.app przez którąkolwiek ze stron jest równoznaczne z rozwiązaniem umowy.' },
    { text: '5. Konto pozew.app może być założone przez osobę mająca 13 lub więcej lat. Utworzenie konta pozew.app jest równoznaczne z oświadczeniem, że Użytkownik ma <strong>co najmniej 13 lat.</strong>. W momencie wykrycia, że konto zostało utworzone przez osobę młodszą niż 13 lat usuniemy takie konto.' },
  ],
};


export const par3 = {
  title: '§3 Prawa i obowiązki Użytkownika',
  pars1: [
    { text: '1. Użytkownik zobowiązuje się do korzystania z pozew.app zgodnie z obowiązującymi przepisami prawa i postanowieniami niniejszego Regulaminu. W szczególności zobowiązuje się do:' },
  ],
  usts1:[
    { text: '1) nieużywania danych do logowania, do których nie przysługują mu prawa;' },
    { text: '2) niedokonywania jakichkolwiek testów penetracyjnych i testów bezpieczeństwa względem pozew.app; ' },
    { text: '3) nienaruszania praw autorskich autora/ Usługodawcy, ani jakichkolwiek innych osób trzecich;' },
    { text: '4) niezamieszczania w opisach formularzy i zapisach wyliczeń sformułowań radykalnie naruszających postawy społeczne lub głoszących takie poglądy, w szczególności obejmujące dyskryminację rasową, etniczną, ze względu na płeć, wyznanie, orientację seksualną;' },
    { text: '5) niezamieszczania w opisach formularzy sformułowań i zapisach wyliczeń sprzecznych z prawem, lub wulgarnych, zawierających wulgaryzmy;' },
    { text: '6) nieużywania pozew.app do działalności reklamowej bez zgody Usługodawcy;' },
    { text: '7) zachowywania się podczas używania pozew.app w sposób wspierający bezpieczeństwo, wydajność i efektywność aplikacji, w tym biernie poprzez zautomatyzowane dostarczanie Usługodawcy stosownych danych odnoszących się do przytoczonych zakresów.' },
  ],
  pars2: [
    { text: '2.Użytkownik jest odpowiedzialny za używanie haseł do konta pozew.app spełniających wymogi bezpieczeństwa Usługodawcy (co najmniej 8 znaków w tym jedna duża, jedna mała litera, 1 cyfra, 1 znak specjalny), w tym także użytkownik jest odpowiedzialny za poufne przechowywanie nadanego przez siebie hasła, niezależnie od formy logowania do systemu. Użytkownik oświadcza, że jest świadomy zagrożeń występujących w Internecie związanych z włamaniami do systemu, utratą haseł w wyniku działania osób trzecich.' },
    { text: '3. Użytkownik ponosi <strong> pełną odpowiedzialność </strong> za to, jak używa pozew.app i za skutki tego korzystania. W szczególności za to, czy ma kompetencje do korzystania z aplikacji oraz czy rozumie system działania aplikacji.' },
    { text: '4. W przypadku wykupienia subskrypcji przez Użytkownika stosuje się następujące zasady:' },
  ],
  usts2:[
    { text: '1) subskrypcja stanowi formę finansowego wsparcia funkcjonowania i trwania pozew.app przez Użytkownika;' },
    { text: '2) opłacenie subskrypcji następuje przy użyciu serwisu stripe.com według stawek i na czas określony w ramach przeprowadzania tranzakcji;' },
    { text: '3) subskrypcja pozwala na korzystanie z dodatkowych funkcji programu takich jak: przekształcanie danych z pliku excel na dokument oraz obliczenia walutowe;' },
    { text: '4) subskrypcja rozliczana jest w okresach rocznych lub miesięcznych, a opłata pobierana z góry;' },
    { text: '5) subskrypcja ulega automatycznemu przedłużeniu na następny okres rozliczeniowy (roczny lub miesięczny) po zakończeniu aktualnego okresu. Opłata za następny okres rozliczeniowy jest pobierana automatycznie wraz z jego nadejściem. Użytkownik w każdym momencie może zrezygnować z subskrypcji. W przypadku rezygnacji w trakcie trwania okresu subskrypcji Usługodawca zachowuje całość wpłaconych kwot. Użytkownik w takiej sytuacji posiada status subskrybenta do końca opłaconego okresu. Zarządzanie czasem subskrypcji następuje przez system stripe.com.' },
    { text: '6) istnieją 3 rodzaje subskrypcji: PODSTAWOWA, STANDARD i PREMIUM. Każdy z typów subskrypcji umożliwia korzystanie ze wszystkich dodatkowych funkcji o których mowa w punkcie 3) powyżej. Subskrypcje STANDARD i PREMIUM umożliwiają także na korzystanie z uprawnień wskazanych w ust. 6-8 poniżej.' },
  ],
  pars3: [
    { text: '5. W okresie obowiązywania subskrypcji prawa z niej wynikające przynależą osobiście osobie, która ją nabyła. Z wyłączeniem sytuacji opisanej w ust. 6 i 7 poniżej niedopuszczalnym jest użyczanie konta osobom trzecim w celu korzystania z dodatkowych funkcji programu. Podmiot nabywający uprawnienia wynikające z subskrypcji jest wskazywany przez użytkownika w trakcie jej opłacania.' },
  ],
  pars4: [
    { text: '6. W ramach subskrypcji STANDARD lub PREMIUM użytkownik ma prawo umożliwić korzystanie z dodatkowych funkcji innym osobom. W przypadku subskrypcji STANDARD są to 4 dodatkowe osoby, natomiast w przypadku subskrypcji PREMIUM liczba osób jest ustalana indywidualnie. Usługodawca zapewnia odpowiednie narzędzia do zarządzania użytkownikami.' },
  ],
  pars5: [
    { text: '7. Usługodawca zobowiązuje się do przeprowadzenia zdalnych szkoleń z działania pozew.app dla podmiotów uprawnionych z tytułu subskrypcji STANDARD lub PREMIUM. Ustalenie terminu szkolenia następuje po wystąpieniu przez Usługobiorcę z potrzebą jego organizacji. W przypadku subskrypcji STANDARD Usługobiorca nie może wystąpć o organizację szkolenia cześciej niż raz na kwartał. W ramach subskrypcji PREMIUM częstotliwość organizacji szkoleń jest ustalana indywidualnie.   ' },
  ],
  pars6: [
    { text: '8. Opłacenie subskrypcji może być poprzedzone okresem próbnym. Długość okresu próbnego trwa 14 dni. W przpadku skorzystania z takiej możliwości użytkownik ma dostęp do wszyskich uprawnień objętych danym typem subskrypcji. Po zakończeniu 14 dniowego okresu subskrypcji opłata za subskrypcję zostanie pobrana, a okres subskrypcji w wybranej przez użytkownika typie zacznie biec. Jeśli Użytkownik zrezygnuje z kontynuowania subskrypcji w trakcie okresu próbnego nie zostanie obciążony opłatą.' },
  ],
  pars7: [
    { text: '9. W przypadku korzystania przez użytkownika z logowania do konta poprzez Google oraz Facebook jest on zobowiązany do stosowania się do reguł zewnętrznego dostawcy uwierzytelniania. Więcej na ten temat można znaleźć tutaj:' },
  ],
};

export const par4 = {
  title: '§4 Prawa i obowiązki Usługodawcy',
  pars: [
    { text: '1.Usługodawca dołoży należytej staranności, aby pozew.app działał w sposób pozbawiony błędów. Użytkownik oświadcza, że rozumie, iż <strong>każde oprogramowanie może zawierać błędy</strong> (także pomimo testów oraz wzmożonych starań Usługodawcy w tym względzie). Z tego powodu oraz ze względu na darmowy (w dominującej części) charakter  aplikacji Użytkownik zobowiązuje się nie zgłaszać jakichkolwiek roszczeń w stosunku do Usługodawcy z tytułu wadliwego działania pozew.app, w szczególności z tytułu wad w wyliczeniach, czy uszkodzenia danych przechowywanych na serwerach.' },
    { text: '2.Usługodawca będzie dążył do nieprzerwanej dostawy usług przez pozew.app. Natomiast ze względu na niezależne od Usługodawcy właściwości Internetu czy urządzenia użytkownika Usługodawca nie gwarantuje nieprzerwanego i niezakłóconego dostępu do Usług. W szczególności mogą nastąpić przerwy w dostawach usług związanych np. z serwisowymi operacjami na serwerze, problemami sieci itp. Użytkownik może też mieć problemy w użytkowaniu pozew.app ze względu na parametry sprzętu, oprogramowania czy zachowania niezgodne z niniejszym regulaminem.' },
    { text: '3.Usługodawca może ograniczyć lub wyłączyć prawo korzystania z pozew.app wybranym użytkownikom, w tym zawiesić lub usunąć ich konto w przypadku naruszania zasad niniejszego regulaminu. Usługodawca podejmie próbę uprzedniego poinformowania Użytkownika o tym fakcie.' },

  ],

};

export const par5 = {
  title: '§5 Własność intelektualna',
  pars: [
    { text: 'Usługodawca oświadcza, że przysługują mu prawa majątkowe autorskie do utworów umieszczonych na https://pozew.app w tym elementów graficznych, kodu aplikacji, sposobu zaprojektowania UX (interface-u) oraz tekstów (w tym dokumentów prawnych), a także baz danych wykorzystywanych przy świadczeniu usług. Jeśli Usługodawca korzysta z praw osób trzecich dokonuje tego na podstawie stosownych umów, licencji.' },
    { text: '2.Bez uprzedniej zgody Usługodawcy korzystanie z praw opisanych w ust. 1 powyżej jest zabornione. W szczególności Użytkownicy poprzez korzystanie z pozew.app nie nabywają żadnych praw majątkowych autorskich do utworów zamieszczonych na https://pozew.app, czy też baz danych. ' }
  ],
};

export const par6 = {
  title: '§6 Ochrona prywatności w tym danych osobowych',
  pars: [
    { text: 'Problematykę ochrony danych osobowych Użytkownika, ochrony prywatności w tym politykę cookies reguluje polityka prywatności pozew.app dostępna tutaj:' },
  ],
};

export const par7 = {
  title: '§7 Postępowanie reklamacyjne',
  pars: [
    { text: '1.Każdemu Użytkownikowi przysługuje prawo do złożenia reklamacji związanej z funkcjonowaniem pozew.app. Reklamacja zostanie rozpatrzona przez Usługodawcę w ciągu 14 dni od dnia jej otrzymania. W przypadku gdy rozpatrzenie reklamacji wymaga szczególnego nakładu pracy, termin może ulec przedłużeniu przez Usługodawcę.' },
    { text: '2.Reklamacje powinny być składane na adres email: contact@pozew.com.' },
  ],
};

export const par8 = {
  title: '§8 Przepisy końcowe',
  pars: [
    { text: '1.W sprawach nieuregulowanych niniejszym Regulaminem mają zastosowanie obowiązujące w tym zakresie przepisy prawa polskiego, a wszelkie spory rozstrzygane będą przez właściwe sądy polskie.' },
    { text: '2. Usługodawca, w zakresie w jakim dopuszczalne jest to zgodnie z obowiązującymi przepisami, ma prawo do dokonywania zmian i modyfikacji niniejszego Regulaminu. Zmiany regulaminu będą komunikowane Użytkownikom pod adresem: https://pozew.app/terms.' },
  ],
};