import { shallowEqual } from 'react-redux';
import { getInterestDesc } from '../../../services/calc/interestDesc';
import { useTypedSelector } from '../../../store';
import { JUST_WITH_CAP } from '../../../store/justOpts';
import { textIf } from '../../helpers/textIf';

const useInterestIntro = () => {
  const { partialResults } = useTypedSelector(state =>
    state.sumUp, shallowEqual);
  const { justIntCap } = useTypedSelector(state =>
    state.justOpts, shallowEqual);

  const { typeOfInterest } = useTypedSelector(state =>
    state.sumUp.lawsuitOpts, shallowEqual);
  return justIntCap === JUST_WITH_CAP ?
    `${textIf('2. Łączna k', partialResults.length > 1, 'K')}wota skapitalizowanych 
    ${getInterestDesc(typeOfInterest).declination} wynika z następujących wyliczeń:`.replace(/(\r\n|\n|\r)/gm, '').replace(/ +(?= )/g, '')
    : '';
};

export default useInterestIntro;
