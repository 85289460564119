import { Card, Col, Statistic, Typography } from 'antd';
import React from 'react';
import { shallowEqual } from 'react-redux';
import { useTypedSelector } from '../../../store';
import CopyButton from '../../UI/Button/CopyButton';

const { Text } = Typography;

type Props = {
  title: string | React.ReactNode;
  value: number;
  compensationOn: boolean;
};

export const ResultInterest = () => {
  const { principalAmountOpt } = useTypedSelector(state =>
    state.sumUp.lawsuitOpts, shallowEqual);
  return (
    <>
      {principalAmountOpt !== 'PRINCIPAL_INTEREST' ? (
        <>
          <Text type="secondary">Sumę kwot składowych</Text>
          <br />
        </>
      ) : null }
      <Text type="secondary">{principalAmountOpt !== 'PRINCIPAL_INTEREST' ? 'skapitalizowane odsetki' : 'Skapitalizowane odsetki'}</Text>
    </>
  );
};
export const ResultComp = () => {
  const { principalAmountOpt } = useTypedSelector(state =>
    state.sumUp.lawsuitOpts, shallowEqual);
  return (
    <>
      {principalAmountOpt !== 'PRINCIPAL_INTEREST' ? (
        <>
          <Text type="secondary">Sumę kwot składowych</Text>
          <br />
        </>
      ) : null }
      <Text type="secondary">{principalAmountOpt !== 'PRINCIPAL_INTEREST' ? 'skapitalizowane odsetki' : 'Skapitalizowane odsetki'}</Text>
      <br />
      <Text type="secondary">zsumowane rekompensaty</Text>
    </>
  );
};

const ResultCard = (
  {
    title,
    value,
    compensationOn
  }: Props
) => {
  return (
    <Col
      md={{ span: compensationOn ? 8 : 12 }}
      xs={{ span: 24 }}
    >
      <Card>
        <div style={{
          height: 160,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',

        }}
        >
          <Statistic
            title={title}
            value={value}
            precision={2}
            decimalSeparator=","
            groupSeparator=" "
          />
          <CopyButton
            value={value ? value.toFixed(2).replace(/\./g, ',') : ''}
          />
        </div>
      </Card>
    </Col>
  );
};



export default ResultCard;
