import { Col, Row, Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { AboutContainer, DashedColLeft } from '../../styles';


const { Title } = Typography;

const SubTitle = () => {
  return (
    <AboutContainer>
      <Row>
        <Col
          span={2}
        />
        <Col
          xs={{ span: 20 }}
        >
          <Row
            justify="center"
            style={{ paddingTop: 24 }}
          >
            <TitleExt level={2}>Subskrypcja</TitleExt>
          </Row>
          <Row
            justify="center"
            style={{
              fontFamily: 'Fira mono',
              paddingTop: 12,
              color: 'grey',
            }}
          >
            pomóż sobie wspierając projekt
          </Row>
        </Col>
        <Col
          xl={1}
          xs={0}
        />
        <DashedColLeft
          xl={1}
          xs={0}
        />
      </Row>
    </AboutContainer>
  );
};

export default SubTitle;


const TitleExt = styled(Title)`
    padding-top: 24px;
    padding-bottom: 0;
    line-height: 32px !important;
`;