import _ from 'lodash';
import { useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';

import { useTypedSelector } from '../../store';
import { BLUE } from '../../styles';



type PaymentAssignmentsWithColor = { key: string, sum: number, color: string, percentSum: number }[];

export const useAssignmentsDisplay = ({
  interestItemKey,
  paymentsColors,
  partialSum,
}:{
  interestItemKey: string,
  paymentsColors: Map<string, string>,
  partialSum: number,
}) => {
  const [assignmentsDisplayData, setAssignmentsDisplayData] = useState<{
    assignmentDisplayItems: null | PaymentAssignmentsWithColor,
    assignmentsSum:number,
    assignmentsPercent:number,
  }>({
    assignmentDisplayItems: null,
    assignmentsSum:0,
    assignmentsPercent:0,
  });

  const { paymentData } = useTypedSelector(state =>
    state.interestStorage, shallowEqual);

  useEffect(() => {
    if (!_.isEmpty(paymentData)){
      const assignmentDisplayItems = paymentData.reduce((prev: PaymentAssignmentsWithColor | [], paymentItem) => {
        const { assignedTo, key: pk } = paymentItem;
        const assignedToIndex = assignedTo.findIndex(( assignedToItem ) =>
          interestItemKey === assignedToItem.key);
        if (assignedToIndex > -1){
          return [...prev, {
            key: assignedTo[assignedToIndex].key,
            sum: assignedTo[assignedToIndex].sum,
            color: paymentsColors.get(pk) ?? BLUE,
            percentSum: assignedTo[assignedToIndex].sum ?   assignedTo[assignedToIndex].sum / partialSum * 100 : 0,
          }];
        } return prev;
      }, []);

      const assignmentsSum = assignmentDisplayItems.reduce((prev, curr) => {
        return curr.sum + prev;
      }, 0);
      const assignmentsPercent = assignmentsSum / partialSum  * 100;
      setAssignmentsDisplayData({
        assignmentDisplayItems,
        assignmentsSum,
        assignmentsPercent,
      });
    }
  }, [interestItemKey, partialSum, paymentData, paymentsColors]);

  return assignmentsDisplayData;
};
