import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Button, Divider, Row, Space, Typography } from 'antd';
import React from 'react';

const ImpOrcInboxActions = ({
  onBack,
  numberOfFiles,
  onAccept,
}:{
  onBack: () => void,
  numberOfFiles: number,
  onAccept: () =>void,
}) => {
  return (
    <Row
      justify='space-between'
      align='middle'
    >
      <Button
        type="dashed"
        icon={<ArrowLeftOutlined />}
        onClick={onBack}
      >
        Wróć
      </Button>
      <Space>
        <Typography.Text type='secondary'>
          {' '}
          Pliki:
        </Typography.Text>
        <Typography.Text strong>
          {' '}
          {numberOfFiles}
        </Typography.Text>
        <Divider type="vertical" />
        <Button
          type="primary"
          icon={<ArrowRightOutlined />}
          disabled={numberOfFiles === 0}
          onClick={onAccept}
        />
      </Space>
      <Divider />
    </Row>
  );
};

export default ImpOrcInboxActions;

