import useCompText from './useCompText';
import useJustBasisPayments from './useJustBasisPayments';
import useJustFinalSum from './useJustFinalSum';
import useJustHead from './useJustHead';
import useJustInterests from './useJustInterests';
import useLsCodeText from './useLsCodeText';

const useJustification = () => {
  const head = useJustHead();
  const partialsSums = useJustFinalSum();
  const basisPayments = useJustBasisPayments();
  const interests = useJustInterests();
  const compText = useCompText();
  const lsCodeText = useLsCodeText();

  return [
    head,
    partialsSums,
    basisPayments,
    ...interests,
    compText,
    lsCodeText,
  ].join('\n').replace(/ +(?= )/g, '');

};

export default useJustification;
