import { Divider, Typography } from 'antd';
import { AnimatePresence, motion } from 'framer-motion';
import React, { memo } from 'react';
import styled from 'styled-components';
import { ComponentChildren, TitleAndDesc } from '../../../@types';


const { Title, Text } = Typography;

type Props = TitleAndDesc & ComponentChildren & {
  dividerDisabled?: boolean;
};

const StepCard = (
  {
    title,
    desc,
    dividerDisabled,
    children
  }: Props
) => {

  return (
    <>
      <AnimatePresence>
        <motion.div
          transition={{
							  ease: 'easeInOut',
							  duration: 1,
          }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <motion.div
            transition={{
								  ease: 'easeInOut',
								  duration: 0.3,
            }}
            initial={{ x: -24 }}
            animate={{ x: 0 }}
          >
            <Title level={2}>{title}</Title>
            <Text>{desc}</Text>
          </motion.div>
        </motion.div>
      </AnimatePresence>
      {dividerDisabled ? null : <Divider />}

      <AnimatePresence>
        <motion.div
          transition={{
							  ease: 'easeInOut',
							  duration: 1,
          }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {children}
        </motion.div>
      </AnimatePresence>
    </>
  );
};

export default memo(StepCard);



export const StepCardWrapper = styled.div`
	width: 768px;
	text-align: start;
`;
