import { Layout, Row } from 'antd';
import React from 'react';
import styled from 'styled-components';
import MainMenu from '../components/Home/Menu/MainMenu';
import MenuDividerMobile from '../components/Home/Menu/MenuDividerMobile';
import MenuLogo from '../components/Home/Menu/MenuLogo';
import MenuStart from '../components/Home/Menu/MenuStart';
import MenuTools from '../components/Home/Menu/MenuTools';
import Slogan from '../components/Home/Slogan/Slogan';
import SloganFooter from '../components/Home/Slogan/SloganFooter';
import SloganHeader from '../components/Home/Slogan/SloganHeader';
import SloganHeaderMobile from '../components/Home/Slogan/SloganHeaderMobile';
import AnimOpacity from '../components/UI/Anim/AnimOpacity';
import useWindowSize from '../utils/hooks/useWindowSize';
import { GRID_LG, GRID_MD, GRID_SM_PX } from '../styles';
import { RED } from '../styles/colors';

const Home = () => {
  const { width } = useWindowSize();

  if (width >= GRID_LG){
    return (
      <AnimOpacity>
        <LayoutWhite>
          <FullRow>
            <MainMenu />
            <Slogan />
          </FullRow>
        </LayoutWhite>
      </AnimOpacity>
    );
  }

  return (
    <AnimOpacity>
      <LayoutWhite>
        <MenuLogo />
        <MenuDividerMobile />
        <MenuStart />
        {width < GRID_MD ?
          <SloganHeaderMobile /> : <SloganHeader />}
        <MenuDividerMobile color={RED} />
        <MenuTools />
        <SloganFooter />
      </LayoutWhite>
    </AnimOpacity>
  );

};

export default Home;


const FullRow = styled(Row)`
	@media only screen and (min-width: ${GRID_SM_PX}) {
    height: 100vh;
	}
`;

const LayoutWhite = styled(Layout)`
   background: white;
`;



