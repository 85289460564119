import { Row } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { ComponentChildren } from '../../@types';

const SignUpMainCont = ({ children }: ComponentChildren) => {
  return (
    <FullRow>
      {children}
    </FullRow>
  );
};

export default SignUpMainCont;


const FullRow = styled(Row)`
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
