/* eslint-disable @typescript-eslint/no-explicit-any */
import { CalendarOutlined, ControlOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Row } from 'antd';
import _ from 'lodash';
import { Moment } from 'moment';
import React, { memo } from 'react';
import styled from 'styled-components';
import { WYMAGALNOSC } from '../../../assets/tooltips';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { editMaturityIsVisible } from '../../../store/edit';
import { areDateOptionsVisibleUpdated } from '../../../store/interestFormOptions';
import { disabledDate } from '../../../utils/helpers/date';



const DueDate = (
  {
    error,
    dueDate,
    handleDueDate,
  }: {
    error: boolean,
    dueDate: Moment | null | undefined,
    handleDueDate:(value: any)=>void,
  }
) => {
  const dispatch = useAppDispatch();
  const isIntrForm = useTypedSelector(state =>
    _.isNull(state.edit.key));

  const onButtonClick = () => {
    dispatch( isIntrForm ?
      areDateOptionsVisibleUpdated(true) :
      editMaturityIsVisible(true)
    );
  };

  return (
    <>
      <Col
        sm={10}
        xs={20}
      >
        <Form.Item
          label={(
            <>
              <CalendarFormIcon />
              {texts.dueDate}
            </>
          )}
          tooltip={WYMAGALNOSC}
          validateStatus={error ? 'error' : ''}
          style={{ marginBottom: 0 }}
        >
          <Row>
            <DatePicker
              allowClear
              format="DD-MM-YYYY"
              style={{ width:'100%' }}
              value={dueDate}
              onChange={handleDueDate}
              placeholder={texts.dueDate}
              disabledDate={disabledDate}
            />
          </Row>
        </Form.Item>
      </Col>
      <Col sm={1} xs={2} />
      <ColForButton
        sm={1}
        xs={2}
      >
        <Row justify="end">
          <Button
            type="dashed"
            icon={isIntrForm ? <ControlOutlined /> : <CalendarOutlined />}
            size="middle"
            onClick={onButtonClick}
          />
        </Row>
      </ColForButton>
    </>
  );
};


export default memo(DueDate);

const texts = {
  dueDate: 'Termin spełnienia świadczenia',
  add: 'Dodaj termin zapłaty',
};

export const ColForButton = styled(Col)`
   padding-top: 32px;
`;

export const CalendarFormIcon  = styled(CalendarOutlined)`
   margin-right: 4px;
`;
