import { LinkOutlined, MailOutlined } from '@ant-design/icons';
import { Button, Card } from 'antd';
import React from 'react';
import nextId from 'react-id-generator';
import styled from 'styled-components';

import addUser from '../../assets/addUser.png';
import { BLUE, RED } from '../../styles/colors';
import DeveloperSection from './DeveloperSection';

const DeveloperAuth = () => {
  return (
    <DeveloperSection
      {...{
        color: RED,
        id: 'auth'
      }}
      title={(
        <>
          <span style={{ lineHeight: 2 }}>
            Uwierzytelnienie
          </span>
        </>
        )}
    >
      <Card
        title="Utwórz konto, uzyskaj zgodę i token"
        bordered={false}
        style={{
          width: '100%', border: 'none'
        }}
        headStyle={{
          border: 'none', paddingTop: '0 !important'
        }}
      >
        <DeveloperCardInner
          type='inner'
          title="1. Utwórz konto pozew.app nadawcy"
          actions={[
            <a
              key="1"
              href='http://pozew.app/signUp'
            >
              <Button
                icon={<LinkOutlined />}
                type="link"
              >
                Utwórz konto
              </Button>
            </a>
          ]}
        >
          Za pomocą danych logowania
          {' '}
          <strong> nadawcy </strong>
          {' '}
          prześlesz dane odbiorcy.
        </DeveloperCardInner>
        <DeveloperCardInner
          type='inner'
          title="2. Uzyskaj zgodę odbiorcy danych"
          cover={(
            <img
              alt="example"
              src={addUser}
              style={{
                borderBottom: `${BLUE} 0.5px solid`,
                borderTop: `${BLUE} 0.5px solid`,
                margin: '12px 0'
              }}
            />
)}
          actions={[
            <a
              key="1"
              href='http://pozew.app/user'
            >
              <Button
                icon={<LinkOutlined />}
                type="link"
              >
                {'Panel Użytkownika > Konto & Kontakt'}
              </Button>
            </a>
          ]}
        >
          <strong>Odbiorca</strong>
          {' '}
          powinien zezwolić nadawcy na przesyłanie mu danych na jego skrzynkę odbiorczą. Może to zrobić w zakładce Konto & kontakt.
        </DeveloperCardInner>
        <DeveloperCardInner
          type='inner'
          title="3. Uwierzytelnienie w ramach Firebase REST API"
          actions={[
            <a
              key={nextId()}
              href='https://firebase.google.com/docs/reference/rest/auth/#section-create-email-password'
            >
              <Button
                icon={<LinkOutlined />}
                type="link"
              >
                Instrukcja - token
              </Button>
            </a>,
            <a
              key={nextId()}
              href='mailto:contact@pozew.app'
            >
              <Button
                icon={<MailOutlined />}
                type="link"
              >
                Kontakt
              </Button>
            </a>,
          ]}
        >
          Za każdym razem, aby nadawca mógł przesłać dane na serwer pozew.app musi uzyskać
          {' '}
          <strong> bearer token </strong>
          .
          Kliknij na link poniżej, aby zobaczyć instrukcję uzyskania tokenu z Google Cloud.
          Do uzyskania tokenu nadawca musi uwierzytelnić się danymi z pozew.app i podać
          {' '}
          <strong>WEB API key</strong>
          .
          Klucz służy do określenia projektu w ramach Google Cloud.
          Jego ewentualne ujawnienie jest bezpieczne dla systemu pozew.app.
          Napisz do nas, aby otrzymać klucz.
        </DeveloperCardInner>
      </Card>
    </DeveloperSection>
  );
};

export default DeveloperAuth;


export const DeveloperCardInner = styled(Card)`
  margin-bottom: 24px;
`;